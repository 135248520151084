import React, { Component } from 'react'
import DynamicTableComponentNew from '../Components/DynamicTableComponentsNew'
import constants from '../Utils/constants'
import CustomBreadcrumb from '../Components/CustomBreadcrumb'
import { formatRoute } from 'react-router-named-routes'
import { activeMenuTab } from '../Store/Actions/User'
import DocumentTitle from '../Utils/DocumentTitle'
import queryString from 'query-string'
import { connect } from 'react-redux'
import CustomReactTooltip from '../Components/CustomReactTooltip'
import Slugify from '../Utils/Slugify';
import { SearchServices } from '../Services/Search'
import Toast from '../Utils/Toast';
import CustomDatePicker from '../Components/CustomDatePicker';
import moment from "moment";

const tableHeader = [
  { id: 'searched_key', title: 'Searched Keyword', sortable: false, headerStyle: { textTransform: 'capitalize', textAlign: 'left' }, rowStyle: { textTransform: 'capitalize', textAlign: 'left', color: "#0E0E0D" }, visibility: true },
  { id: 'no_of_times', title: 'Number of times', headerStyle: { textAlign: 'left' }, rowStyle: { textAlign: 'left', position: 'inherit' }, visibility: true },
];

class ListSearchTrends extends Component {
  constructor(props) {
    super(props)
    this.state = {
      start_date: null,
      end_date: null,
      count: 0,
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: '',
      disableStatus: false,
      fromDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      presentDate: moment().format("YYYY-MM-DD")
    }
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    DocumentTitle(constants.APPLICATION_ROUTE.SEARCH_TREND.LIST.HEADER_TITLE)
    this.setState({
      pageNo: params.page ? parseInt(params.page) : constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: params.page_size ? parseInt(params.page_size) : constants.PAGINATION.DEFAULT_PAGE_SIZE,
      sortColumn: params.sort_column ? params.sort_column : constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: params.sort_order ? params.sort_order : constants.PAGINATION.DEFAULT_SORT_ORDER,
      name: params.search_value ? params.search_value : '',
      fromDate: this.props.location.state && this.props.location.state.fromDate ? this.props.location.state.fromDate : moment().subtract(60, "days").format("YYYY-MM-DD"),
      endDate: this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : moment().format("YYYY-MM-DD"),
      presentDate: this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : moment().format("YYYY-MM-DD"),
      start_date: this.props.location.state && this.props.location.state.fromDate ? this.props.location.state.fromDate : moment().subtract(60, "days").format("YYYY-MM-DD"),
      end_date: this.props.location.state && this.props.location.state.endDate ? this.props.location.state.endDate : moment().format('YYYY-MM-DD'),
    }, () => {
      this.getTableData();
    })
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.SEARCH_TREND.LIST.ROUTE)
  }

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.SEARCH_TREND.LIST.NAME,
        link: null,
        is_active: false
      }

    ];
    return breadcrumbs;
  }

  getDateDifference = (fromDate, endDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(endDate);
    const diffTime = (date2 - date1);
    if (diffTime < 0) {
      //Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      return false
    }
    return true;
  }

  changeFromDate = (e, value) => {
    if (value !== null) {
      let bool = (e === 'start') ? this.getDateDifference(value, this.state.end_date) : this.getDateDifference(this.state.start_date, value)
      if (bool && e === 'start') {
        this.setState({ start_date: moment(value).format("YYYY-MM-DD"), hasMore: true, pageCount: 1, loginData: [], pageNo: 1 }, () => {
          this.getTableData();

        })
      }
      else if (bool) {
        this.setState({ end_date: moment(value).format("YYYY-MM-DD"), hasMore: true, pageCount: 1, loginData: [], pageNo: 1 }, () => {
          this.getTableData();
        })
      }

    }
  }

  handleSort = (clickedColumn, key) => {
    this.setState({ sortColumn: clickedColumn, segmentSort: false, sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc', }, () =>
        this.getTableData())
}

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData())
  }

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
        this.getTableData()
    })
}

handleInput = (e) => {
  this.setState({ name: e.target.value })
}

pagePrevious = () => {
  this.setState((prevState) => ({
      pageNo: prevState.pageNo - 1
  }), () => this.getTableData())
}
pageNext = () => {
  this.setState((prevState) => ({
      pageNo: prevState.pageNo + 1
  }), () => this.getTableData())
}

  getTableData = () => {
    let queryParams = ''
    if (this.state.sortOrder) {
      queryParams = '&sort_order=' +
        this.state.sortOrder + '&sort_column=' + this.state.sortColumn
    }
    if (this.state.name !== '') {
      queryParams += '&search_column=name&search_value=' + this.state.name
    }
    let body = {
      "start_date": this.state.start_date,
      "end_date": this.state.end_date,
      "page": this.state.pageNo,
      "page_size": this.state.pageSize
    }
    SearchServices.searchTrends(body).then((res) => {
      let searchTrends = res.data.data
      searchTrends = searchTrends.map((data) => {
        return {
          searched_key: data.keyword,
          no_of_times: data.records,
        }
      })
      this.setState({ rowData: searchTrends, count: res.data.meta.total_count })

    })
      .catch((error) => {
        this.setState({ rowData: [] })
        console.log(error);
        Toast(error, 'error');
      })
  };

  render() {
    return (
      <aside className="main_content">
        <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>
          <div className="comapnay_tbl">
            <div className="">
              <div className="d-flex">
                <div className="clients_dropdown mb-3">
                  <div className='d-flex'>
                    <CustomDatePicker className="custom_dates custom-combobox-list with-noborder with-shadow-sm" id='start' date={this.state.start_date} onChangeHandler={this.changeFromDate} min={this.state.userData && this.state.userData?.created_at ? moment(this.state.userData?.created_at).format('YYYY-MM-DD') : null} max={this.state.end_date} />
                    <CustomDatePicker className="custom_dates custom-combobox-list with-noborder with-shadow-sm" id='end' date={this.state.end_date} onChangeHandler={this.changeFromDate} min={this.state.start_date} max={moment().format("YYYY-MM-DD")} />
                  </div>
                </div>
              </div>
              <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
                <DynamicTableComponentNew
                  columnData={tableHeader}
                  rowData={this.state.rowData}
                  pagePrevious={this.pagePrevious}
                  pageNext={this.pageNext}
                  pageSize={this.state.pageSize}
                  pageNo={this.state.pageNo}
                  handlePage={this.handlePage}
                  handleSort={this.handleSort}
                  sortColumn={this.state.sortColumn}
                  sortOrder={this.state.sortOrder}
                  count={this.state.count}
                  rowAction={this.rowAction}
                  colSpan={tableHeader.filter((h) => h.visibility).length}
                  // showActions={showActions}
                  showPagination={true}
                  paginationClass={"center-pagination custom-pagination-new"}
                  handlePageSize={this.handlePageSize}
                />
              </div>
              {/* Tooltips for icons in table, don't delete */}
              {/* {
                  this.state.rowData.map((data) => {
                    return (
                      <>
                        <CustomReactTooltip
                          id={`tooltip-${Slugify(constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP)}-${data.id}`}
                          multiline={true}
                        />
                      </>
                    )
                  })
                } */}
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab))
    }
  }
}
const mapStateToProps = state => {
  return {
    home: state.home,
    client: state.client
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSearchTrends);