import React, { useEffect, useState, useRef } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import constants from "../Utils/constants";
import moment from "moment";
import {
  ChartInit,
  createXYAxis,
  makeSeries,
  createCursor,
} from "../Utils/Amcharts";
import { ReportsServices } from "../Services/Reports";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import { countUsersBasedOnDate } from "../Utils/dateFormater";

const tableHeader = [
  { id: "first_name", title: "Name", sortable: true, visibility: true },
  { id: "email", title: "Email", sortable: true, visibility: true },
  { id: "role", title: "Role", sortable: true, visibility: true },
  { id: "client", title: "Client", sortable: true, visibility: true },
  { id: "created_by", title: "Created By", sortable: true, visibility: true },
  { id: "created_at", title: "Created On", sortable: true, visibility: true },
];
// Define data
var data = [
  {
    date: new Date(2021, 0, 1).getTime(),
    value: 1,
    value2: 2.5,
  },
  {
    date: new Date(2021, 0, 2).getTime(),
    value: 3,
    value2: 2.1,
  },
  {
    date: new Date(2021, 0, 3).getTime(),
    value: 2,
    value2: 3,
  },
  {
    date: new Date(2021, 0, 4).getTime(),
    value: 1,
    value2: 2,
  },
  {
    date: new Date(2021, 0, 5).getTime(),
    value: 1.5,
    value2: 0.5,
  },
];

function Reports4(props) {
  let defaultEndDate = moment().format("YYYY-MM-DD");
  let defaultStartDate = moment(defaultEndDate)
    .subtract(1, "W")
    .format("YYYY-MM-DD");
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("created_at");
  const [searchData, setSearchData] = useState("");
  const [rowData, setRowData] = useState([]);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [DateRangeValue, setDateRangeValue] = useState("1");
  const [count, setCount] = useState(0);
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef();
  const rootRef = useRef();
  const [active, setInactive] = useState(true);
  const [status, setStatus] = useState(1);
  const [dropdownplaceholder, setplaceholder] = useState("Status");

  useEffect(() => {
    if (chartData && chartData.length) {
      var { root, chart } = ChartInit("SalesChart");
      root.dateFormatter._settings.dateFormat = "MM-dd-yyyy";
      var { xAxis, yAxis } = createXYAxis(root, chart);
      makeSeries("Series #1", "value", root, chart, xAxis, yAxis, chartData);
      createCursor(root, chart, xAxis, yAxis);

      chartRef.current = chart;
      rootRef.current = root;

      return () => {
        if (chart && root) {
          chart.dispose();
          root.dispose();
        }
      };
    }
  }, [chartData]);

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE,
        is_active: false,
      },
      {
        title: active
          ? constants.APPLICATION_ROUTE.REPORTS.LIST4.NAME
          : constants.APPLICATION_ROUTE.REPORTS.LIST5.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  useEffect(() => {
    props.activeMenuTab(constants.APPLICATION_ROUTE.REPORTS.LIST4.ROUTE);
  }, []);

  const handleDateRange = (e) => {
    let value = e.target.value;
    setDateRangeValue(value);
    let endDate = moment().format("MM-DD-YYYY");
    let startDate;
    switch (value) {
      case "1":
        startDate = moment(endDate).subtract(1, "W").format("MM-DD-YYYY");
        break;
      case "2":
        startDate = moment(endDate).subtract(1, "M").format("MM-DD-YYYY");
        break;
      case "3":
        startDate = moment(endDate).subtract(3, "M").format("MM-DD-YYYY");
        break;
      case "4":
        startDate = moment(endDate).subtract(6, "M").format("MM-DD-YYYY");
        break;
      case "5":
        startDate = moment(endDate).subtract(1, "Y").format("MM-DD-YYYY");
        break;
      default:
        startDate = moment(endDate).subtract(1, "W").format("MM-DD-YYYY");
        break;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    getTableData();
  }, [startDate, sortOrder, sortColumn, searchData, active, status]);

  const getTableData = () => {
    let queryParams = "";
    if (startDate && endDate) {
      queryParams = `&start_date=${startDate}&end_date=${endDate}`;
    }
    if (sortOrder) {
      queryParams += "&sort_order=" + sortOrder + "&sort_column=" + sortColumn;
    }
    if (searchData !== "") {
      queryParams += "&search_column=name_or_email&search_value=" + searchData;
    }
    queryParams += `&status=${status}`;
    let url = `/reports/userslist?page=${pageNo}&page_size=${pageSize}${queryParams}`;
    ReportsServices.getAddedUsers(url).then((res) => {
      let userData = res.data.data;
      let count = res.data.meta.total_count;
      setCount(count);
      userData = userData.map((data) => {
        return {
          id: data.id,
          first_name: data.first_name,
          email: data.email,
          role: data.role.name,
          client: data.client.name,
          created_at: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
          created_by:
            data.created_by_user && data.created_by_user.id
              ? `${
                  data.created_by_user.first_name
                    ? data.created_by_user.first_name
                    : ""
                } ${
                  data.created_by_user.last_name
                    ? data.created_by_user.last_name
                    : ""
                }`
              : "-",
        };
      });
      setRowData(userData);

      let chartD = countUsersBasedOnDate(userData);
      setChartData(chartD);
    });
  };

  const pagePrevious = () => {
    setPageNumber(pageNo - 1);
  };
  const pageNext = () => {
    setPageNumber(pageNo + 1);
  };

  const handlePage = (input) => {
    setPageNumber(input);
  };

  const handlePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNumber(1);
  };
  const handleSort = (clickedColumn, key) => {
    setSortColumn(clickedColumn);
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length == 0) {
      setPageNumber(1);
      setSearchData(e.target.value);
    }
  };

  const handleStateChange = (e) => {
    setStatus(e.target.value);
    setplaceholder(e.name);
    setInactive(active ? false : true);
  };

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center w-100 mb-18px">
              <h3 className="text-black2 font-weight-semibold mb-0">
                Chart Preview
              </h3>
            </div>
            <div className="d-flex align-items-center">
              <div className="">
                <div className="custom-select-new custom-select-dropdown">
                  <select
                    value={dropdownplaceholder}
                    onChange={(e) => handleStateChange(e)}
                  >
                    <option value="1">TBRI Active Client Users</option>
                    <option value="0">TBRI In-Active Client Users</option>
                  </select>
                </div>
              </div>

              <div className="ml-4 pl-2">
                <div className="custom-select-new custom-select-dropdown">
                  <select value={DateRangeValue} onChange={handleDateRange}>
                    <option value="1">Last 1 Week</option>
                    <option value="2">Last 1 Month</option>
                    <option value="3">Last 3 Months</option>
                    <option value="4">Last 6 Months</option>
                    <option value="5">Last 1 Year</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="chartpreview-content text-center">
              <div className="mt-4 mb-4">
                {!chartData?.length ? (
                  <h4>No Data Available</h4>
                ) : (
                  <>
                    <div
                      id="SalesChart"
                      style={{ width: "100%", height: "300px" }}
                    />
                    <div id="legend" />
                  </>
                )}
              </div>
            </div>

            <div className="d-flex">
              <div className="client-list-filter-block min-width-266 border-search search-box-shadow-2 mt-4">
                <form className="form-inline justify-content-center search-form mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search with Name/Email"
                    aria-label="Search"
                    onChange={handleSearch}
                    name="name"
                  />
                  <button
                    className="btn btn-primary-success my-sm-0"
                    type="button"
                  >
                    <span className="material-icons-outlined">search</span>
                  </button>
                </form>
              </div>
            </div>
            <div className="custom-table-block custom-table-block-2 nowrap-table-block sort-table-block center-th-align">
              <DynamicTableComponentNew
                columnData={tableHeader}
                columns={tableHeader}
                rowData={rowData.slice(
                  (pageNo - 1) * pageSize,
                  pageNo * pageSize
                )}
                pagePrevious={pagePrevious}
                pageNext={pageNext}
                pageSize={pageSize}
                pageNo={pageNo}
                handlePage={handlePage}
                handlePageSize={handlePageSize}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                handleSort={handleSort}
                count={count}
                colSpan={6}
                showPagination={true}
                paginationClass={"center-pagination custom-pagination-new"}
              />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
export default connect(null, mapDispatchToProps)(Reports4);
