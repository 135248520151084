import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import Search from "../Components/Search";
import queryString from "query-string";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import { formatRoute } from "react-router-named-routes";
import DocumentTitle from "../Utils/DocumentTitle";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import Slugify from "../Utils/Slugify";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import { EmailTemplateServices } from "../Services/EmailTemplate";
import Toast from "../Utils/Toast";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "name",
    title: "name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      color: "#0E0E0D",
    },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created At",
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", position: "inherit" },
    visibility: true,
  },
  {
    id: "updated_by",
    title: "Updated By",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    headerStyle: { textAlign: "center" },
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
];

const showActions = false;

class ListEmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      disableStatus: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.ROUTE
    );
    let index = tableHeader.findIndex((element) => element.id === "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  getActions = (data) => {
    return (
      <button
        tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
        id={`tooltip-${Slugify(
          constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
        )}-${data.id}`}
        onClick={(e) => {
          this.rowAction(
            constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE,
            data,
            e
          );
        }}
        className={
          "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
        }
        data-for={`tooltip-${Slugify(
          constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
        )}-${data.id}`}
        data-tip={
          constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
        }
        data-iscapture="true"
      >
        <span className="material-icons-outlined text-blue-clr icon-fs-20">
          edit
        </span>
      </button>
    );
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = "/emailTemplates/all";
    EmailTemplateServices.getEmailTemplate(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let templateData = res.data.TemplatesData;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;

        templateData = templateData.map((data) => {
          return {
            id: data.id,
            templatekey: data.key,
            name: data.name,
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_by: `${
              data.updated_by_user && data.updated_by_user.id
                ? `${data.updated_by_user.first_name} ${data.updated_by_user.last_name}`
                : "-"
            }`,
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: templateData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.EDIT.ROUTE,
            { templateKey: data.key }
          ),
        });
        break;
      default:
        break;
    }
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.ROUTE,
        {}
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      // {
      //   title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
      //   link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
      //   is_active: false
      // },
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>
          <div className="comapnay_tbl">
            <div className="">
              <div className="d-flex">
                <div className="min-width-266 border-search search-box-shadow-2 mb-3">
                  <Search
                    handleSearch={this.handleSearch}
                    handleInput={this.handleInput}
                    value={this.state.name}
                    placeHolder={"Filter Name"}
                  />
                </div>
              </div>
              <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
                <DynamicTableComponentNew
                  columnData={tableHeader}
                  rowData={this.state.rowData}
                  pagePrevious={this.pagePrevious}
                  pageNext={this.pageNext}
                  pageSize={this.state.pageSize}
                  pageNo={this.state.pageNo}
                  handlePage={this.handlePage}
                  handleSort={this.handleSort}
                  sortColumn={this.state.sortColumn}
                  sortOrder={this.state.sortOrder}
                  count={this.state.count}
                  rowAction={this.rowAction}
                  colSpan={tableHeader.filter((h) => h.visibility).length}
                  showActions={showActions}
                  showPagination={true}
                  paginationClass={"center-pagination custom-pagination-new"}
                  handlePageSize={this.handlePageSize}
                />
              </div>
              {/* Tooltips for icons in table, don't delete */}
              {this.state.rowData.map((data) => {
                return (
                  <>
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(
                        constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST
                          .EDIT_TEMPLATE_TOOLTIP
                      )}-${data.id}`}
                      multiline={true}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEmailTemplates);
