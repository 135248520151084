import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { TemplateServices } from "../Services/Template";
import queryString from "query-string";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import $ from "jquery";
import { ClientServices } from "../Services/Client";
import ManageMasterData from "../Containers/ManageMasterData";
import CustomDropdownList from "../Components/RulesCustomDropdown";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import { MasterServices } from "../Services/Master";
import _ from "lodash";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "lob_name",
    title: "Line of Business",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "lob_subcategory1_name",
    title: "LOB Subsegment 1",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w150",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "lob_subcategory2_name",
    title: "LOB Subsegment 2",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w150",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "lob_subcategory3_name",
    title: "LOB Subsegment 3",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w150",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "lob_subcategory4_name",
    title: "LOB Subsegment 4",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w150",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "lob_subcategory5_name",
    title: "LOB Subsegment 5",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w150",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "geo_name",
    title: "Geography",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "geosubcategory_name",
    title: "Geo Category",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "country_name",
    title: "Geo Sub-category",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "vertical_name",
    title: "Vertical",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "verticalcategory_name",
    title: "Vertical Category",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "verticalsubcategory_name",
    title: "Vertical Sub-category",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "metrictype_name",
    title: "Metric Type",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "metric_name",
    title: "Metric",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    headerClass: "w100",
    rowClass: "w100",
    visibility: true,
  },
  {
    id: "threshold",
    title: "Threshold",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    rowClass: "w100",
    visibility: true,
  },
];

const showActions = true;
class ListMetricThresholds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lob: [],
      geography: [],
      vertical: [],
      metric_type: [],
      metric_name: [],
      metrics: [],
      rowData: [],
      entireData: [],
      thersholdData: [],
      filteredThresholdData: [],
      filtersEnabled: false,
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      count: 0,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      isOpen: false,
      segmentList: [],
      documentModal: false,
      threshold: 10,
      templateRecordId: null,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.TEMPLATE.LIST.HEADER_TITLE);
    this.getMasters();
    let queryParams = "";
    let url = "/templaterecords";
    TemplateServices.getTemplates(`${url}` + queryParams)
      .then((res) => {
        if (res.data) {
          let tempData = res.data.data.filter(
            (item, index) => index < this.state.pageSize
          );
          this.setState({
            entireData: res.data.data,
            thersholdData: tempData,
            count: res.data.meta.total_count,
          });
          this.getTableData();
        }
      })
      .catch((error) => {
        this.setState({ thersholdData: [], rowData: [], count: 0 });
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() => {
        //removing second loading indicator
        document.body.classList.remove("second-loading-indicator");
      });

    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let lobIdArray =
      this.state.lob.filter((val) => val.is_selected === true)[0] !== undefined
        ? this.state.lob
            .filter((val) => val.is_selected === true)
            .map((data) => data.id)
        : [];
    let lobFilteredData = this.state.entireData.filter(
      (val) => val.lob && val.lob.id && lobIdArray?.includes(val.lob.id)
    );
    let geographyIdArray =
      this.state.geography.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.geography
            .filter((val) => val.is_selected === true)
            .map((data) => data.geographyid)
        : [];
    let geographyFilteredData = this.state.entireData.filter(
      (val) =>
        val.geography &&
        val.geography.geographyid &&
        geographyIdArray?.includes(val.geography.geographyid)
    );
    let verticalIdArray =
      this.state.vertical.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.vertical
            .filter((val) => val.is_selected === true)
            .map((data) => data.verticalid)
        : [];
    let verticalFilteredData = this.state.entireData.filter(
      (val) =>
        val.vertical &&
        val.vertical.verticalid &&
        verticalIdArray?.includes(val.vertical.verticalid)
    );
    let metrictypeIdArray =
      this.state.metric_type.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.metric_type
            .filter((val) => val.is_selected === true)
            .map((data) => data.metrictypeid)
        : [];
    let metrictypeFilteredData = this.state.entireData.filter(
      (val) =>
        val.metrictype &&
        val.metrictype.metrictypeid &&
        metrictypeIdArray?.includes(val.metrictype.metrictypeid)
    );
    let metricIdArray =
      this.state.metrics.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.metrics
            .filter((val) => val.is_selected === true)
            .map((data) => data.metricid)
        : [];
    let metricFilteredData = this.state.entireData.filter(
      (val) =>
        val.metric &&
        val.metric.metricid &&
        metricIdArray?.includes(val.metric.metricid)
    );

    if (
      prevState.lob !== this.state.lob ||
      prevState.geography !== this.state.geography ||
      prevState.vertical !== this.state.vertical ||
      prevState.metric_type !== this.state.metric_type ||
      prevState.metrics !== this.state.metrics ||
      prevState.entireData !== this.state.entireData
    ) {
      let result = [...this.state.entireData];
      let filtersFlag = false;
      if (lobIdArray.length > 0) {
        result = _.intersection(lobFilteredData, result);
        filtersFlag = true;
      }
      if (geographyIdArray.length > 0) {
        result = _.intersection(geographyFilteredData, result);
        filtersFlag = true;
      }
      if (verticalIdArray.length > 0) {
        result = _.intersection(verticalFilteredData, result);
        filtersFlag = true;
      }
      if (metrictypeIdArray.length > 0) {
        result = _.intersection(metrictypeFilteredData, result);
        filtersFlag = true;
      }
      if (metricIdArray.length > 0) {
        result = _.intersection(metricFilteredData, result);
        filtersFlag = true;
      }
      this.setState(
        {
          thersholdData: result,
          filteredThresholdData: result,
          filtersEnabled: filtersFlag,
          pageNo: 1,
          count: result.length,
        },
        () => {
          this.getTableData();
        }
      );
    }
  }

  getMasters = () => {
    MasterServices.getMasters()
      .then((res) => {
        let result = res && res.data;
        this.setState({
          lob: CustomCloneDeep(
            result.lobs ? this.sortOptions(result.lobs) : []
          ),
          geography: CustomCloneDeep(
            result.geography ? this.sortOptions(result.geography) : []
          ),
          vertical: CustomCloneDeep(
            result.vertical ? this.sortOptions(result.vertical) : []
          ),
          metric_type: CustomCloneDeep(
            result.metric_type ? this.sortOptions(result.metric_type) : []
          ),
          metric_name: CustomCloneDeep(
            result.metric ? this.sortOptions(result.metric) : []
          ),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  sortOptions = (rows) => {
    return rows.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );
  };
  handleMasterChange = (e, props) => {
    let selected = e.map((a) => a[props.valueField]);
    let selectors = [...this.state[props.selector]];
    selectors.forEach((c) => {
      c.is_selected = selected.includes(c[props.valueField]);
    });
    this.setState(
      {
        ...this.state,
        [props.selector]: selectors,
      },
      () => {
        if (props.selector === "metric_type") {
          this.setMetrics(selectors, props);
        }
      }
    );
  };

  setMetrics = (selectors, props) => {
    let result = [];
    let metrics = CustomCloneDeep(this.state.metric_name);
    selectors.forEach((c) => {
      if (c.is_selected === true) {
        let filteredMetrics = metrics.filter(
          (m) => m.metrictypeid === c[props.valueField]
        );
        result.push(...filteredMetrics);
      }
    });
    this.setState({ metrics: result });
  };

  removeTemplateConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.TEMPLATE.MANAGE_METRIC_RULES_AND_THRESHOLDS
                .REMOVE_THRESHOLD_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_template", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeTemplate = (templateId) => {
    TemplateServices.removeTemplate(templateId)
      .then((res) => {
        Toast(constants.TEMPLATE.REMOVE.SUCCESS, "success");
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleTemplate = (templateId, isActive) => {
    TemplateServices.toggleTemplate(templateId, { is_active: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.TEMPLATE.ACTIVATE.SUCCESS
            : constants.TEMPLATE.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    TemplateServices.updateThreshold(config.id, { threshold_value: null })
      .then((res) => {
        let url = "/templaterecords";
        TemplateServices.getTemplates(`${url}`)
          .then((res) => {
            if (res.data) {
              let tempData = res.data.data.filter(
                (item, index) => index < this.state.pageSize
              );
              this.setState(
                {
                  entireData: res.data.data,
                  thersholdData: tempData,
                  count: res.data.meta.total_count,
                },
                () => {
                  this.getTableData();
                }
              );
            }
          })
          .catch((error) => {
            this.setState({ thersholdData: [], rowData: [], count: 0 });
            this.getTableData();
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          })
          .finally(() => {
            //removing second loading indicator
            document.body.classList.remove("second-loading-indicator");
          });
        Toast(constants.MANAGE_METRIC_RULES_AND_THRESHOLDS.REMOVED, "Success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.DELETE}
        >
          <div
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-grey icon-fs-20">
              edit
            </span>
          </div>
        </AclAction>
      </>
    );
  };

  getTableData = () => {
    let queryParams = "";
    // if (this.state.sortOrder) {
    //   queryParams = '&sort_order=' +
    //     this.state.sortOrder + '&sort_column=' + this.state.sortColumn
    // }

    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryParams,
    });
    var templatesData;
    let data;
    if (this.state.name === "") {
      data = this.state.thersholdData.slice(0, this.state.pageSize);
    } else {
      let dataToBeSearched = this.getData();
      let filteredData = dataToBeSearched.filter(
        (val) =>
          (val.metric &&
            val.metric.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.lob &&
            val.lob.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.lob_subcategory1 &&
            val.lob_subcategory1.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.lob_subcategory2 &&
            val.lob_subcategory2.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.lob_subcategory3 &&
            val.lob_subcategory3.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.lob_subcategory4 &&
            val.lob_subcategory4.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.lob_subcategory5 &&
            val.lob_subcategory5.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.vertical &&
            val.vertical.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.verticalcategory &&
            val.verticalcategory.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.verticalsubcategory &&
            val.verticalsubcategory.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.geography &&
            val.geography.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.geosubcategory &&
            val.geosubcategory.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase())) ||
          (val.country &&
            val.country.name
              .toLowerCase()
              .includes(this.state.name.toLowerCase()))
      );
      data = filteredData;
    }
    if (data.length > 0) {
      templatesData = data?.map((data) => {
        // data.threshold = Math.floor(Math.random() * 100) + 1
        return {
          id: data.id,
          lob_name: data.lob ? data.lob.name : "N/A",
          lob_subcategory1_name: data.lob_subcategory1
            ? data.lob_subcategory1.name
            : "N/A",
          lob_subcategory2_name: data.lob_subcategory2
            ? data.lob_subcategory2.name
            : "N/A",
          lob_subcategory3_name: data.lob_subcategory3
            ? data.lob_subcategory3.name
            : "N/A",
          lob_subcategory4_name: data.lob_subcategory4
            ? data.lob_subcategory4.name
            : "N/A",
          lob_subcategory5_name: data.lob_subcategory5
            ? data.lob_subcategory5.name
            : "N/A",
          geo_name: data.geography ? data.geography.name : "N/A",
          geosubcategory_name: data.geosubcategory
            ? data.geosubcategory.name
            : "N/A",
          country_name: data.country ? data.country.name : "N/A",
          vertical_name: data.vertical ? data.vertical.name : "N/A",
          verticalcategory_name: data.verticalcategory
            ? data.verticalcategory.name
            : "N/A",
          verticalsubcategory_name: data.verticalsubcategory
            ? data.verticalsubcategory.name
            : "N/A",
          metrictype_name: data.metrictype ? data.metrictype.name : "N/A",
          metric_name: data.metric ? data.metric.name : "N/A",
          created_by:
            data.created_by_user && data.created_by_user.id
              ? `${
                  data.created_by_user.first_name
                    ? data.created_by_user.first_name
                    : ""
                } ${
                  data.created_by_user.last_name
                    ? data.created_by_user.last_name
                    : ""
                }`
              : "-",
          created_at: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
          // updated_at: data.updated_at ? ComapnyDetailsDateTimeFormatter(data.updated_at, false) : '-',
          threshold: (
            <div>
              <span className="valign-middle font-weight-semibold ml-2">
                {data.threshold_value ? data.threshold_value + "%" : null}
              </span>
              {!isRole_Acc_mgr_or_salesAdmin(
                this.props.home.loginUserSuccess.role_id
              ) && (
                <>
                  <b
                    className="dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                    onClick={(e) => this.manageThreshold(data)}
                  >
                    {data.threshold_value ? (
                      <span className="material-icons-outlined text-grey icon-fs-20">
                        edit
                      </span>
                    ) : (
                      <span className="material-icons-outlined text-grey icon-fs-22">
                        add
                      </span>
                    )}
                  </b>
                  {data.threshold_value ? (
                    <b
                      className="dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                      onClick={(e) => this.removeThreshold(data)}
                    >
                      <span className="material-icons-outlined text-grey icon-fs-20">
                        delete
                      </span>
                    </b>
                  ) : null}
                </>
              )}
            </div>
          ),
        };
      });

      this.setState({ rowData: templatesData, count: this.state.count }, () => {
        $(".comapany_table").css(
          "min-width",
          $(".comapany_table").find("thead").innerWidth()
        );
      });
    } else {
      this.setState({ rowData: [], count: this.state.count }, () => {
        $(".comapany_table").css(
          "min-width",
          $(".comapany_table").find("thead").innerWidth()
        );
      });
    }
  };

  exportList = () => {};

  getData = () => {
    if (this.state.filtersEnabled) {
      return this.state.filteredThresholdData;
    } else {
      return this.state.entireData;
    }
  };

  pagePrevious = () => {
    let data = this.getData();
    let tempData = data.filter(
      (item, index) =>
        index >= this.state.pageSize * (this.state.pageNo - 2) &&
        index < this.state.pageSize * (this.state.pageNo - 1)
    );
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
        thersholdData: tempData,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    let data = this.getData();
    let tempData = data.filter(
      (item, index) =>
        index >= this.state.pageSize * this.state.pageNo &&
        index < this.state.pageSize * (this.state.pageNo + 1)
    );
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
        thersholdData: tempData,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    let data = this.getData();
    let tempData = data.filter(
      (item, index) =>
        index >= this.state.pageSize * (input - 1) &&
        index < this.state.pageSize * input
    );
    this.setState({ thersholdData: tempData, pageNo: input }, () =>
      this.getTableData()
    );
  };
  handlePageSize = (e) => {
    let data = this.getData();
    let tempData = data.filter((item, index) => index < e.target.value);
    this.setState(
      { thersholdData: tempData, pageSize: e.target.value, pageNo: 1 },
      () => {
        this.getTableData();
      }
    );
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  handleSort = (clickedColumn, key) => {
    let column = "";
    if (clickedColumn === "lob_name") {
      column = "lob.name";
    } else if (clickedColumn === "lob_subcategory1_name") {
      column = "lob_subcategory1.name";
    } else if (clickedColumn === "lob_subcategory2_name") {
      column = "lob_subcategory2.name";
    } else if (clickedColumn === "lob_subcategory3_name") {
      column = "lob_subcategory3.name";
    } else if (clickedColumn === "lob_subcategory4_name") {
      column = "lob_subcategory4.name";
    } else if (clickedColumn === "lob_subcategory5_name") {
      column = "lob_subcategory5.name";
    } else if (clickedColumn === "geo_name") {
      column = "geography.name";
    } else if (clickedColumn === "geosubcategory_name") {
      column = "geosubcategory.name";
    } else if (clickedColumn === "country_name") {
      column = "country.name";
    } else if (clickedColumn === "vertical_name") {
      column = "vertical.name";
    } else if (clickedColumn === "verticalcategory_name") {
      column = "verticalcategory.name";
    } else if (clickedColumn === "verticalsubcategory_name") {
      column = "verticalsubcategory.name";
    } else if (clickedColumn === "metrictype_name") {
      column = "metrictype.name";
    } else if (clickedColumn === "metric_name") {
      column = "metric.name";
    } else if (clickedColumn === "threshold") {
      column = "threshold_value";
    } else {
      column = clickedColumn;
    }

    let data = this.getData();
    let tempSortedData = [];
    if (this.state.sortOrder === "asc") {
      tempSortedData = _.sortBy(data, column);
    } else if (this.state.sortOrder === "desc") {
      tempSortedData = _.sortBy(data, column).reverse();
    }
    if (this.state.filtersEnabled) {
      this.setState({ filteredThresholdData: tempSortedData });
    } else {
      this.setState({ entireData: tempSortedData });
    }
    let tempData = data.filter((item, index) => index < this.state.pageSize);
    this.setState(
      {
        pageNo: 1,
        thersholdData: tempData,
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE:
        this.removeTemplateConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  closeDocumentModal = () => {
    this.setState({
      documentModal: false,
      threshold: null,
      templateRecordId: null,
    });
  };

  manageThreshold = (data) => {
    this.setState({
      documentModal: true,
      threshold: data.threshold_value ? data.threshold_value : 0,
      templateRecordId: data.id ? data.id : 0,
    });
  };

  handleSubmit = (data) => {
    if (data.name % 1 === 0) {
      TemplateServices.updateThreshold(data.id, { threshold_value: data.name })
        .then((res) => {
          this.setState({
            documentModal: false,
          });
          let url = "/templaterecords";
          TemplateServices.getTemplates(`${url}`)
            .then((res) => {
              if (res.data) {
                let tempData = res.data.data.filter(
                  (item, index) => index < this.state.pageSize
                );
                this.setState(
                  {
                    entireData: res.data.data,
                    thersholdData: tempData,
                    count: res.data.meta.total_count,
                  },
                  () => {
                    this.getTableData();
                  }
                );
              }
            })
            .catch((error) => {
              this.setState({ thersholdData: [], rowData: [], count: 0 });
              this.getTableData();
            })
            .finally(() => {
              //removing second loading indicator
              document.body.classList.remove("second-loading-indicator");
            });
          Toast(
            constants.MANAGE_METRIC_RULES_AND_THRESHOLDS.UPDATED,
            "Success"
          );
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      Toast(constants.MANAGE_METRIC_RULES_AND_THRESHOLDS.INVALID, "error");
    }
  };

  removeThreshold = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_METRIC_RULES_AND_THRESHOLDS
                .REMOVE_THRESHOLD_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_template", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };

  render() {
    return (
      <>
        <aside className="row">
          <div className="col-lg-2 col-sm-6 mb-3 position-relative">
            <div className="custom-dropdown-list">
              <CustomDropdownList
                data={this.state.lob}
                handleChange={this.handleMasterChange}
                placeholder={"Line of Business"}
                valueField={"id"}
                textField={"name"}
                selector="lob"
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3 position-relative">
            <div className="custom-dropdown-list">
              <CustomDropdownList
                data={this.state.geography}
                handleChange={this.handleMasterChange}
                placeholder={"Geography"}
                valueField={"geographyid"}
                textField={"name"}
                selector="geography"
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3 position-relative">
            <div className="custom-dropdown-list">
              <CustomDropdownList
                data={this.state.vertical}
                handleChange={this.handleMasterChange}
                placeholder={"Vertical"}
                valueField={"verticalid"}
                textField={"name"}
                selector="vertical"
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3 position-relative">
            <div className="custom-dropdown-list">
              <CustomDropdownList
                data={this.state.metric_type}
                handleChange={this.handleMasterChange}
                placeholder={"Metric Type"}
                valueField={"metrictypeid"}
                textField={"name"}
                selector="metric_type"
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-3 position-relative">
            <div className="custom-dropdown-list">
              <CustomDropdownList
                data={this.state.metrics}
                handleChange={this.handleMasterChange}
                placeholder={"Metric"}
                valueField={"metricid"}
                textField={"name"}
                selector="metrics"
              />
            </div>
          </div>
        </aside>
        <div className="d-flex">
          <div className="min-width-266 border-search search-box-shadow-2 mb-3">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Search"}
            />
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-iconcol-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            // className={'threshold-grid'}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>
        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.TEMPLATE.LIST
                    .REMOVE_TEMPLATE_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <ManageMasterData
          documentModal={this.state.documentModal}
          closeDocumentModal={this.closeDocumentModal}
          masterName={this.state.threshold}
          id={this.state.templateRecordId}
          addMasterData={this.handleSubmit}
          title={"Threshold (%)"}
          type={"number"}
        />
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListMetricThresholds);
