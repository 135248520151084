import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { NavLink as RRNavLink } from 'react-router-dom'
import {
  NavLink
} from 'reactstrap'
const CustomBreadcrumb = (props) => {

  return (
    props.breadcrumbs && props.breadcrumbs.length > 0 ?
      <div>
        <Breadcrumb className='new-breadcrumb'>
          {props.breadcrumbs.map((b, i) =>
            <BreadcrumbItem key={`breacrumb-item-${i}`} active={b.is_active ? b.is_active : false}>
              {b.link ? <NavLink tag={RRNavLink} exact to={b.link}>{b.title}</NavLink> : b.title}
            </BreadcrumbItem>)
          }
        </Breadcrumb>
      </div> : null
  )
}

export default CustomBreadcrumb
