import momentWithZone from 'moment-timezone'
import moment from 'moment'
function ComapnyDetailsDateTimeFormatter(date, showTimeZone = true,showTime = true) {
  
  let userTimezone = moment.tz.guess()
  let userTimezoneShortCode = moment().tz(userTimezone).zoneAbbr()
  if (showTime == false)
    return `${momentWithZone(date).tz(userTimezone).format('MMMM DD, YYYY')} ${showTimeZone ? userTimezoneShortCode : ''}`
  return `${momentWithZone(date).tz(userTimezone).format('MMMM DD, YYYY HH:mm ')} ${showTimeZone ? userTimezoneShortCode : ''}`
}

export default ComapnyDetailsDateTimeFormatter
