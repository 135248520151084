import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { TemplateServices } from "../Services/Template";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import Search from "../Components/Search";
import { connect } from "react-redux";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import DocumentTitle from "../Utils/DocumentTitle";
import $ from "jquery";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Toast from "../Utils/Toast";

const tableHeader = [
  {
    id: "lob_name",
    title: "Line of Business",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory1_name",
    title: "LOB Segment Group",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory2_name",
    title: "LOB Segment",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory3_name",
    title: "LOB Subsegment",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory4_name",
    title: "LOB Subsegment4",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory5_name",
    title: "LOB Subsegment5",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "geo_name",
    title: "Geography",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "geosubcategory_name",
    title: "Geo Category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "country_name",
    title: "Geo Sub-category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "vertical_name",
    title: "Vertical",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "verticalcategory_name",
    title: "Vertical Category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "verticalsubcategory_name",
    title: "Vertical Sub-category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "metrictype_name",
    title: "Metric Type",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "metric_name",
    title: "Metric",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "actions",
    title: "Select",
    sortable: false,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "w80 text-center",
    rowClass: "w80",
    visibility: true,
  },
];

const showActions = true;
class TemplateRecordPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: null,
      sortOrder: null,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      isOpen: false,
      documentModal: this.props.documentModal
        ? this.props.documentModal
        : false,
      masterName: this.props.masterName ? this.props.masterName : 0,
      formErrors: {},
      apiError: null,
      showCreate: false,
      isFormSubmitted: false,
      selectedTemplateRecordId: null,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.TEMPLATE.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column ? params.sort_column : null,
        sortOrder: params.sort_order ? params.sort_order : null,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
  }

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };

  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  closeDocumentModal = () => {
    this.setState(
      {
        documentModal: false,
      },
      () => {
        this.props.closeDocumentModal();
      }
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.documentModal !== prevState.documentModal) {
      return {
        documentModal: nextProps.documentModal,
        masterName: nextProps.masterName ? nextProps.masterName : "",
      };
    } else return null;
  }

  getActions = (data) => {
    return (
      <>
        <input
          className="form-check-input templateRecordRadio"
          type="radio"
          name="templateRecordRadio"
          id={`templateRecordRadio${data.id}`}
          value={data.id}
          onChange={this.handleTemplateRecordRadio}
        />
      </>
    );
  };

  handleTemplateRecordRadio = (e) => {
    this.setState({
      selectedTemplateRecordId: e.target.value,
    });
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    let url = "/templaterecords";
    TemplateServices.getTemplates(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let templatesData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        templatesData = templatesData.map((data) => {
          return {
            id: data.id,
            lob_name: data.lob ? data.lob.name : "N/A",
            lob_subcategory1_name: data.lob_subcategory1
              ? data.lob_subcategory1.name
              : "N/A",
            lob_subcategory2_name: data.lob_subcategory2
              ? data.lob_subcategory2.name
              : "N/A",
            lob_subcategory3_name: data.lob_subcategory3
              ? data.lob_subcategory3.name
              : "N/A",
            lob_subcategory4_name: data.lob_subcategory4
              ? data.lob_subcategory4.name
              : "N/A",
            lob_subcategory5_name: data.lob_subcategory5
              ? data.lob_subcategory5.name
              : "N/A",
            geo_name: data.geography ? data.geography.name : "N/A",
            geosubcategory_name: data.geosubcategory
              ? data.geosubcategory.name
              : "N/A",
            country_name: data.country ? data.country.name : "N/A",
            vertical_name: data.vertical ? data.vertical.name : "N/A",
            verticalcategory_name: data.verticalcategory
              ? data.verticalcategory.name
              : "N/A",
            verticalsubcategory_name: data.verticalsubcategory
              ? data.verticalsubcategory.name
              : "N/A",
            metrictype_name: data.metrictype ? data.metrictype.name : "N/A",
            metric_name: data.metric ? data.metric.name : "N/A",
            lob_group: [
              data.lob ? data.lob.name : null,
              data.lob_subcategory1 ? data.lob_subcategory1.name : null,
              data.lob_subcategory2 ? data.lob_subcategory2.name : null,
              data.lob_subcategory3 ? data.lob_subcategory3.name : null,
              data.lob_subcategory4 ? data.lob_subcategory4.name : null,
              data.lob_subcategory5 ? data.lob_subcategory5.name : null,
            ]
              .filter((n) => n)
              .join(" - "),
            geo_group: [
              data.geography ? data.geography.name : null,
              data.geosubcategory ? data.geosubcategory.name : null,
              data.country ? data.country.name : null,
            ]
              .filter((n) => n)
              .join(" - "),
            vertical_group: [
              data.vertical ? data.vertical.name : null,
              data.verticalcategory ? data.verticalcategory.name : null,
              data.verticalsubcategory ? data.verticalsubcategory.name : null,
            ]
              .filter((n) => n)
              .join(" - "),
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: templatesData, count: count }, () => {
          $(".comapany_table").css(
            "min-width",
            $(".comapany_table").find("thead").innerWidth()
          );
        });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() => {
        //removing second loading indicator
        document.body.classList.remove("second-loading-indicator");
      });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TEMPLATE.EDIT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.STATUS:
        this.toggleCompanyConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE:
        this.removeTemplateConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  isFormValid = () => {
    let formError = false;
    let masterNameError = false;
    if (this.state.selectedTemplateRecordId === null) {
      masterNameError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        masterNameError: masterNameError,
      },
      apiError: null,
    });
    return formError;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          this.props.addTemplateRecord(
            this.state.rowData.find(
              (r) => r.id == this.state.selectedTemplateRecordId
            )
          );
        }
      }
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.state.documentModal}
        toggle={this.closeDocumentModal}
        className={"modal-xl modal-w-header custom-modal"}
      >
        <form
          className={"col-lg-12 col-sm-12 form-wrapper p-0"}
          onSubmit={this.handleSubmit}
        >
          <ModalHeader toggle={this.closeDocumentModal}>
            <h3 className="text-darkblue2 font-weight-bold">Metrics</h3>
          </ModalHeader>
          <ModalBody>
            <div className="">
              <div className="d-flex">
                <div className="min-width-266 mb-3">
                  <Search
                    handleSearch={this.handleSearch}
                    handleInput={this.handleInput}
                    value={this.state.name}
                    placeHolder={"Filter Name"}
                  />
                </div>
              </div>
              <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-iconcol-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
                <DynamicTableComponentNew
                  columnData={tableHeader}
                  rowData={this.state.rowData}
                  pagePrevious={this.pagePrevious}
                  pageNext={this.pageNext}
                  pageSize={this.state.pageSize}
                  pageNo={this.state.pageNo}
                  handlePage={this.handlePage}
                  handleSort={this.handleSort}
                  sortColumn={this.state.sortColumn}
                  sortOrder={this.state.sortOrder}
                  count={this.state.count}
                  rowAction={this.rowAction}
                  colSpan={tableHeader.filter((h) => h.visibility).length}
                  showActions={showActions}
                  showPagination={true}
                  paginationClass={"center-pagination custom-pagination-new"}
                  handlePageSize={this.handlePageSize}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-outline-primary modal-btn"
              color="secondary"
              onClick={this.closeDocumentModal}
            >
              Cancel
            </Button>
            <Button
              className="modal-right-btn modal-btn"
              color="primary"
              type="submit"
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateRecordPicker);
