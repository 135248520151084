import React, { Component, useState } from "react";
import constants from "../Utils/constants";
import queryString from "query-string";
import { connect, useDispatch } from "react-redux";
import { activeMenuTab } from "../Store/Actions/User";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import HelpModal from "./HelpModal";
import { Axios } from "../lib/axios";
import RuleView from "./Metrics/RuleView";
import TableView from "./Metrics/TableView";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import DownloadModal from "./Metrics/DownloadModal";

export default function MetricsRulesTableView(props) {
  const [name, setName] = useState("");
  const [selectedTab, setSelectedTab] = useState();
  const [totalTabs, setTotalTabs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useState(() => {
    let params = queryString.parse(props.location.search);
    DocumentTitle(
      constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.HEADER_TITLE
    );
    let tabs = constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.TABS;
    tabs = tabs.filter((tab) => tab.HIDE_TAB === false);
    setSelectedTab(params.selected_tab ? params.selected_tab : tabs[0].KEY);
    setTotalTabs(tabs);

    dispatch(
      activeMenuTab(constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE)
    );
  }, []);

  const onContentChangeTypeHandler = (s) => {
    setSelectedTab(s);
  };

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.HEADER_TITLE,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  const downloadFile = async () => {
    let metricRuleId = props.match.params.id ? props.match.params.id : null;
    try {
      let url = `metric-rules/download/${metricRuleId}`;
      const response = await Axios({
        url,
        method: "GET",
        responseType: "blob", // Set the responseType to 'blob' for binary data
      });

      // Create a Blob from the response data
      const fileBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const fileUrl = window.URL.createObjectURL(fileBlob);

      // Create a link element
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", `${name}.xlsx` || "data.xlsx"); // Set desired file name

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the link and revoke the blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("File download failed:", error);
    }
  };

  const handleEdit = () => {
    history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.TEMPLATE.EDIT.ROUTE, {
        id: props.match.params.id,
      }),
    });
  };
  const handleModelClose = () => {
    setOpenModal(false);
  };

  return (
    <aside className='main_content'>
      <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
        <div className='d-flex align-items-center flex-wrap title-section breadcrumb-mb-0'>
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>

        <div>
          <span className='text-black block-title font-weight-normal mb-0'>
            Name:
          </span>
          <span className='block-title font-weight-semibold mb-0'>
            {" "}
            {name.length > 25 ? (
              <label
                className='text rct-title mb-0'
                key={name}
                data-for={`tooltip-${name}-active`}
                data-tip={name}
              >
                {name.substring(0, 25) + "..."}
                <CustomReactTooltip
                  id={`tooltip-${name}-active`}
                  multiline={true}
                />
              </label>
            ) : (
              name
            )}
          </span>
        </div>

        <div className='d-flex align-items-center flex-wrap breadcrumb-mb-0'>
          <div className='btn-items-card-wrapper'>
            <div className='d-flex'>
              <Nav tabs className='custom-nav-tabs'>
                {totalTabs?.map((tab) => (
                  <NavItem key={tab.KEY}>
                    <NavLink
                      className={classnames({
                        active: selectedTab === tab.KEY,
                      })}
                      onClick={() => {
                        onContentChangeTypeHandler(tab.KEY);
                      }}
                    >
                      {tab.TAB_TITLE}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
          </div>
          <div className='ml-auto d-flex align-items-center justify-content-end title-right-block'>
            <button
              className={
                "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mr-2"
              }
              type='button'
              onClick={handleEdit}
            >
              Edit
            </button>
            <div className='ml-auto mr-2'>
              <HelpModal category='Taxonomy List' />
            </div>
            <button
              className='icon-btn-primary icon-btn d-flex align-items-center justify-content-center'
              onClick={() => setOpenModal(true)}
              data-for={`tooltip-${Slugify(
                constants.APPLICATION_ROUTE.COMPANY.LIST.EXPORT_EXCEL_TOOLTIP
              )}`}
              data-tip={
                constants.APPLICATION_ROUTE.COMPANY.LIST.EXPORT_EXCEL_TOOLTIP
              }
              data-iscapture='true'
            >
              <span className='material-icons-round icon'>download</span>
            </button>
          </div>
        </div>

        <div className='d-flex align-items-center flex-wrap'></div>
        <div className='custom-table-block custom-table-block-2 nowrap-table-block th-nowrap sort-table-block center-th-align'>
          {selectedTab === "rule_view" && (
            <RuleView {...props} setName={setName} />
          )}
          {selectedTab === "table_view" && <TableView {...props} />}
        </div>
      </div>

      <DownloadModal
        open={openModal}
        id={props.match.params.id}
        handleModelClose={handleModelClose}
      />
    </aside>
  );
}
