import React from "react";
import { connect } from "react-redux";
import CommonListMetricRecoredsV2 from "./CommonListMetricRecordsV2";

export const ListMetricRecordsV2 = (props) => {
  return (
    <CommonListMetricRecoredsV2 {...props} isPreviewer={false} company='All' />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListMetricRecordsV2);
