import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import constants from '../Utils/constants'
import Notification from '../Components/Notification'

class ManageMasterData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentModal: this.props.documentModal ? this.props.documentModal : false,
      masterName: this.props.masterName ? this.props.masterName : 0,
      formErrors: {

      },
      apiError: null,
      showCreate: false,
      isFormSubmitted: false
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isFormSubmitted: true
    }, () => {
      if (!this.isFormValid()) {
        this.addMasterData(this.state.masterName)
      }
    })
  }

  addMasterData = (name) => {
    let prepareObject = {
      name: name,
      id: this.props.id ? this.props.id : null
    }
    this.props.addMasterData(prepareObject)
  }

  closeDocumentModal = () => {
    this.setState({
      documentModal: false
    }, () => {
      this.props.closeDocumentModal()
      this.setState({
        ...this.state,
        showCreate: true,
        formErrors: {
          ...this.state.formErrors,
          masterNameError: null,
        },
        apiError: null
      })
    })
  }

  isFormValid = () => {
    let formError = false
    let masterNameError = false
    if (this.props.type) {
      if (this.state.masterName === '' || isNaN(this.state.masterName)) {
        masterNameError = true
        formError = true
      }
    }
    else {
      if (this.state.masterName.trim() === '') {
        masterNameError = true
        formError = true
      }
    }
    this.setState({
      ...this.state,
      showCreate: true,
      formErrors: {
        ...this.state.formErrors,
        masterNameError: masterNameError
      },
      apiError: null
    })
    return formError
  }

  onMasterNameChangeHandler = (e) => {
    this.setState({
      masterName: e.target.value,
    }, () => {
      if (this.state.isFormSubmitted === true)
        this.isFormValid()
    })
  }

  handleCreate = (e) => {
    this.addToBoard(e)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.documentModal !== prevState.documentModal) {
      return { documentModal: nextProps.documentModal, masterName: nextProps.masterName ? nextProps.masterName : '' }
    }
    else return null
  }

  render() {
    return (
      <Modal isOpen={this.state.documentModal} className={'modal-sm modal-w-header custom-modal modal-without-header primary-modal'}>
        <form className={'col-lg-12 col-sm-12 form-wrapper p-0'} onSubmit={this.handleSubmit}>
          <ModalBody>
            {this.state.apiError !== null && <Notification color={'danger'} message={this.state.apiError} />}
            <div className={'row'}>
              <div className="col-sm-12 form-group position-relative m-auto">
                <label for="master-name">{this.props.title} <span className={'mandatory'}>* </span></label>
                <input type="text" className={`form-control ${(this.state.formErrors.masterNameError === true ? 'is-invalid' : '')} ${(this.state.formErrors.masterNameError === false ? '' : '')}`} id="master-name" placeholder={this.props.title} value={this.state.masterName} onChange={this.onMasterNameChangeHandler} />
                {this.state.formErrors.masterNameError && <div className="invalid-tooltip d-block">{constants.FORM.MANDATORY_FIELD}</div>}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className={'btn-outline-primary modal-btn'} onClick={this.closeDocumentModal}>Cancel</Button>{' '}
            <Button className={'modal-right-btn modal-btn'} type='submit'>Save</Button>
          </ModalFooter>
        </form>
      </Modal>
    )
  }

}

export default ManageMasterData
