import React from 'react'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import { DateTimePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'
Moment.locale('en')
momentLocalizer()

const CustomDateTimePicker = (props) => {
  return (
    <DateTimePicker
      defaultValue={props.date ? new Date(props.date) : new Date()}
      onChange={(val) => props.onChangeHandler(props.id, val)}
      disabled={props.isDisabled}
      timeFormat='HH:mm'
      format='MMMM DD, YYYY HH:mm'
    />
  )
}

export default CustomDateTimePicker
