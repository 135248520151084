import React, { useEffect, useRef } from 'react';
import ColumnChart from '../Utils/amcharts/ColumnChart';
import LineChart from '../Utils/amcharts/LineChart';
import AM_Charts from '../Utils/amcharts/AM_Charts';
import constants from '../Utils/constants';

export default function ReportsChart(props) {

    const chartRef = useRef(null);

    useEffect(() => {
        initializeChart();

        return () => {
            if (chartRef.current) {                           // dispose the previous chart
                chartRef.current.root &&
                    chartRef.current.root.dispose &&
                    chartRef.current.root.dispose();
            }
        }
    }, [props.chartData, props.series])

    const initializeChart = () => {
        const chartData = props.chartData;
        const divId = props.id;
        const series = props.series || [];
        const seriesType = props.series_type || constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.LINE;
        const axis_type = props.axis_type || constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE;

        if (chartData && chartData.length && series.length) {
            const args = {
                divId,
                chartData,
                seriesType
            }

            // dispose the previous chart
            if (chartRef.current) {
                chartRef.current.root &&
                    chartRef.current.root.dispose &&
                    chartRef.current.root.dispose();
            }
            
            let chart = new AM_Charts(args);
            createAxes(axis_type, chart);
            series.forEach((obj) => {
                let seriesObj = {
                    name: obj.name,
                    field_name: obj.field_name,
                    isStacked: props.isStacked,
                    isFill: props.isFill,
                    showBullets: props.showBullets
                }
                chart.createSeries(seriesObj);
            })
            chart.createCursor();
            chart.addLegend(props.legend_id);
            chartRef.current = chart;
        }
    }

    const createAxes = (type, chart) => {
        switch (type) {
            case constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE: {
                chart.createDateAxis();
                chart.createValueAxis();
                break;
            }
            case constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.CATEGORY: {
                chart.createCategoryAxis();
                chart.createValueAxis();
                break;
            }
        }
    }

    return (
        <>
            <div
                id={props.id}
                style={{
                    width: "100%",
                    height: "250px"
                }}
            />
            {
                props.legend_id ?
                    <div
                        id={props.legend_id}
                        style={{
                            width: "100%",
                            height: "160px",
                        }}
                    />
                    :
                    <></>
            }
        </>
    )
}