import { Axios } from '../lib/axios'
export const EcosystemServices = {
  getTotalEcosystem,
  getEcosystem,
  getEcosystemDetails,
  getEcosystemContent,
  saveEcosystemContent,
  addAuthor,
  removeAuthor,
  addEcosystem,
  editEcosystem,
  toggleEcosystem,
  removeEcosystem,
  getCategories,
  getEcosystemTemplates,
  saveEcosystemTransactionContent,
  getManageContentTransactionContent
}
function getTotalEcosystem(url) {
  return Axios.get(url)
}
function getEcosystem(url) {
  return Axios.get(url)
}
function getEcosystemDetails(id) {
  return Axios.get(`ecosystem/${id}`)
}
function getEcosystemContent(id) {
  return Axios.get(`ecosystem/${id}/content`)
}
function saveEcosystemContent(ecosystemId, content, action) {
  return Axios.post(`ecosystem/${ecosystemId}/content/${action}`, content)
}
function addAuthor(userId, body) {
  return Axios.post(`author/${userId}`, body)
}
function removeAuthor(ecosystemId, id) {
  return Axios.delete(`author/${id}`)
}
function addEcosystem(body) {
  return Axios.post(`ecosystem`, body)
}
function editEcosystem(ecosystemId, body) {
  return Axios.patch(`ecosystem/${ecosystemId}`, body)
}
function toggleEcosystem(ecosystemId, body) {
  return Axios.patch(`ecosystem/${ecosystemId}/status/${body.is_active}`)
}
function removeEcosystem(ecosystemId) {
  return Axios.delete(`ecosystem/${ecosystemId}`)
}
function getCategories(ecosystemId) {
  return Axios.get(`categories?type=ecosystem`)
}
function getEcosystemTemplates() {
  return Axios.get('templates')
}
function saveEcosystemTransactionContent(transaction_id, content, action = '') {
  return Axios.post(`transaction/${transaction_id}/type/ecosystem/state/${action}`, content)
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/ecosystem`)
}
