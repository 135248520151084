import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/specialReportDetailReference'

const specialReportCategories = DETAILS_REF.specialReportCategories
const specialReportSegments = DETAILS_REF.specialReportSegments
const specialReportDetails = DETAILS_REF.specialReportDetails
const categories = DETAILS_REF.categories
const specialReportContent = DETAILS_REF.specialReportContent
const specialReportTemplates = DETAILS_REF.specialReportTemplates
const specialReportTransactionDetails = DETAILS_REF.specialReportTransactionDetails

const specialReportDetailsReducer = combineReducers({
    specialReportCategories,
    specialReportSegments,
    specialReportDetails,
    categories,
    specialReportContent,
    specialReportTemplates,
    specialReportTransactionDetails
})

export default specialReportDetailsReducer
