import React, { Component } from "react";
import { Collapser, Trigger, Panel } from "react-collapser";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import { UserAnalyticLogServices } from "../Services/UserAnalyticLog";
import constants from "../Utils/constants";
import moment from "moment";
import queryString, { parse } from "query-string";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import DocumentTitle from "../Utils/DocumentTitle";
import { formatRoute } from "react-router-named-routes";
import { clearConfigCache } from "prettier";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import CustomDropdownList from "../Components/CustomDropdownList";
import { ClientServices } from "../Services/Client";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Toast from "../Utils/Toast";
import { clientRoles, clients } from "../Store/Actions/Client";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomDatePicker from "../Components/CustomDatePicker";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import Search from "../Components/Search";

const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    visibility: true,
    headerClass: "text-left",
    rowStyle: { whiteSpace: "nowrap" },
  },
  {
    id: "add_date",
    title: "Add Date",
    sortable: false,
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
  {
    id: "logins",
    title: "Logins",
    sortable: false,
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
  {
    id: "downloads",
    title: "Downloads",
    sortable: false,
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
  {
    id: "last_date",
    title: "Last Login Date",
    sortable: true,
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center", whiteSpace: "nowrap" },
  },
];

const data = [
  {
    name: "Abbot, Cutt (Abbot.cutt@dell.com)",
    add_date: "17/11//2021",
    logins: "8",
    downloads: 40,
    last_date: "06/12/2021 (+3 Mo.)",
    distribution: "Opt In",
  },
  {
    name: "Abdelgawad, Sherif ( sherif.abelgawad@dell.com)",
    add_date: "17/11//2021",
    logins: "8",
    downloads: 40,
    last_date: "06/12/2021 (+3 Mo.)",
    distribution: "Opt In",
  },
  {
    name: "Abbot, Cutt (Abbot.cutt@dell.com)",
    add_date: "17/11//2021",
    logins: "8",
    downloads: 40,
    last_date: "06/12/2021 (+3 Mo.)",
    distribution: "Opt In",
  },
  {
    name: "Abdelgawad, Sherif ( sherif.abelgawad@dell.com)",
    add_date: "17/11//2021",
    logins: "8",
    downloads: 40,
    last_date: "06/12/2021 (+3 Mo.)",
    distribution: "Opt In",
  },
  {
    name: "Abbot, Cutt (Abbot.cutt@dell.com)",
    add_date: "17/11//2021",
    logins: "8",
    downloads: 40,
    last_date: "06/12/2021 (+3 Mo.)",
    distribution: "Opt In",
  },
  {
    name: "Abdelgawad, Sherif ( sherif.abelgawad@dell.com)",
    add_date: "17/11//2021",
    logins: "8",
    downloads: 40,
    last_date: "06/12/2021 (+3 Mo.)",
    distribution: "Opt In",
  },
];

class Reports2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedValues: [],
      topfiveDownloaddata: [],
      topfiveLogindata: [],
      fetchclientUser: [],
      userData: [],
      count: 0,
      start_date: null,
      end_date: null,
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      rowdata: [],
      segmentSpecific: [],
      client_Id: "",
      fromDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      presentDate: moment().format("YYYY-MM-DD"),
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let client_Id =
      this.props.match.params && this.props.match.params.id
        ? parseInt(this.props.match.params.id)
        : null;
    DocumentTitle(constants.APPLICATION_ROUTE.REPORTS.LIST2.HEADER_TITLE);

    ClientServices.getAllClients()
      .then((res) => {
        let clients = [];
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        let totalClients = clients.concat(response);
        totalClients.forEach((r) => {
          r.is_selected = r.id === client_Id ? true : false;
        });
        let clientid = totalClients.find((c) => c.is_selected === true)
          ? totalClients.find((c) => c.is_selected === true)
          : "";
        let companyName = clientid && clientid.name;
        clientid = clientid && clientid.id;
        // Select the first client by default if no client is selected.
        if (!clientid && totalClients[0]) {
          totalClients[0].is_selected = true;
          clientid = totalClients[0].id;
          companyName = totalClients[0].name;
        }
        this.setState(
          {
            client_Id: clientid,
            pageNo: params.page
              ? parseInt(params.page)
              : constants.PAGINATION.DEFAULT_PAGE_NO,
            pageSize: params.page_size
              ? parseInt(params.page_size)
              : constants.PAGINATION.DEFAULT_PAGE_SIZE,
            sortColumn: "last_date",
            sortOrder: "desc",
            name: params.search_value ? params.search_value : "",
            companyName: companyName,
            fromDate:
              this.props.location.state && this.props.location.state.fromDate
                ? this.props.location.state.fromDate
                : moment().subtract(60, "days").format("YYYY-MM-DD"),
            endDate:
              this.props.location.state && this.props.location.state.endDate
                ? this.props.location.state.endDate
                : moment().format("YYYY-MM-DD"),
            presentDate:
              this.props.location.state && this.props.location.state.endDate
                ? this.props.location.state.endDate
                : moment().format("YYYY-MM-DD"),
            start_date:
              this.props.location.state && this.props.location.state.fromDate
                ? this.props.location.state.fromDate
                : moment().subtract(60, "days").format("YYYY-MM-DD"),
            end_date:
              this.props.location.state && this.props.location.state.endDate
                ? this.props.location.state.endDate
                : moment().format("YYYY-MM-DD"),
          },
          () => {
            this.loadFetchUserClient();
            this.getTableData();
            this.loadClientLastLogin();
            this.loadsegmentSpecific();
          }
        );
        this.props.setClients(totalClients);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE);
  }

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.REPORTS.LIST2.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  loadFetchUserClient = () => {
    let url = `/topfiveData/${this.state.client_Id}/data/${this.state.start_date}/start/${this.state.end_date}/end`;
    UserAnalyticLogServices.topFiveData(url)
      .then((res) => {
        this.setState({
          topfiveDownloaddata: res.data.data.topfiveDownloaddata,
          topfiveLogindata: res.data.data.topfiveLogindata,
        });
      })
      .catch((error) => {});
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  loadClientLastLogin = () => {
    UserAnalyticLogServices.clientLastlogin(this.state.client_Id)
      .then((res) => {
        this.setState({
          Sevendays: res.data.sevenDaysCount,
          Thirtydays: res.data.thirtyDayCount,
          Sixtydays: res.data.sixtyDayCount,
          Nintydays: res.data.nintyDayCount,
        });
      })
      .catch((error) => {});
  };

  loadsegmentSpecific = () => {
    let url = `/reports/client/${this.state.client_Id}/${this.state.start_date}/start/${this.state.end_date}/end`;
    UserAnalyticLogServices.segmentSpecificdownload(url)
      .then((res) => {
        this.setState({
          segmentSpecific: res.data.data,
        });
      })
      .catch((error) => {});
  };

  generatelogincountTableData() {
    let res = [];
    let arr = this.state.topfiveLogindata;
    for (var i = 0; i < arr.length && i < 5; i++) {
      let data = arr[i];
      const { Counting, user, id } = data;
      if (user == null) {
        res.push();
      } else {
        res.push(
          <tr key={id}>
            <td>
              <NavLink
                tag={RRNavLink}
                style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
                exact
                to={{
                  pathname: formatRoute(
                    constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                  ),
                  state: {
                    id: user.id,
                    clientId: this.state.client_Id,
                    name: this.state.companyName,
                    fromDate: this.state.fromDate,
                    endDate: this.state.endDate,
                  },
                }}
              >
                <label
                  className={`mb-0`}
                >{`${user.first_name} ${user.last_name} (${user.email})`}</label>
              </NavLink>
            </td>
            <td className="text-right">{Counting}</td>
          </tr>
        );
      }
    }
    if (!res.length) {
      res.push(
        <tr key={0}>
          <td colSpan={2} className={`no-records text-center`}>
            {constants.PAGINATION.NO_RECORDS_FOUND}
          </td>
        </tr>
      );
    }
    return res;
  }
  generatedownloadcountTableData() {
    let res = [];
    let arr = this.state.topfiveDownloaddata;
    for (var i = 0; i < arr.length && i < 5; i++) {
      let data = arr[i];
      const { Counting, user, id } = data;
      if (user == null) {
        res.push();
      } else {
        res.push(
          <tr key={id}>
            <td>
              <NavLink
                tag={RRNavLink}
                style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
                exact
                to={{
                  pathname: formatRoute(
                    constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                  ),
                  state: {
                    id: user.id,
                    clientId: this.state.client_Id,
                    name: this.state.companyName,
                    fromDate: this.state.fromDate,
                    endDate: this.state.endDate,
                  },
                }}
              >
                <label
                  className={`mb-0`}
                >{`${user.first_name} ${user.last_name} (${user.email})`}</label>
              </NavLink>
            </td>
            <td className="text-right">{Counting}</td>
          </tr>
        );
      }
    }
    if (!res.length) {
      res.push(
        <tr key={0}>
          <td colSpan={2} className={`no-records text-center`}>
            {constants.PAGINATION.NO_RECORDS_FOUND}
          </td>
        </tr>
      );
    }
    return res;
  }
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }

    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }

    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE, {
        id: this.state.client_Id,
      }),
      state: {
        id: this.state.client_Id,
        name: this.state.companyName,
        fromDate: this.state.fromDate,
        endDate: this.state.endDate,
      },
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  dateformatChanger = (input) => {
    let dateobj = new Date(input);
    let momentobj = new moment(dateobj);
    let old_date = momentobj.format("MMMM DD, YYYY");
    return old_date;
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }

    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }

    this.changeRoute();
    let url = `/fetchUser/${this.state.client_Id}/data/${this.state.start_date}/start/${this.state.end_date}/end`;
    UserAnalyticLogServices.fetchclientUser(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;
        let meta_data = res.data.meta;
        clientData = clientData.map((data) => {
          let current = this.dateformatChanger(moment().format());
          let last_date = data.user.last_login_datetime
            ? this.dateformatChanger(data.user.last_login_datetime)
            : "-";
          let add_date = ComapnyDetailsDateTimeFormatter(
            data.user.created_at,
            false
          );
          var startDate;
          var end = moment(current, "MM-DD-YYYY");
          let days_count, months_count, last_date_description, css;
          let dateCaption = "",
            daystextStyle = "",
            endDateStyle = "";
          if (last_date !== "-") {
            let current = moment().startOf("day");
            let old_date = moment(data.user.last_login_datetime, "YYYY-MM-DD");
            let months_count = parseInt(
              moment.duration(old_date.diff(current)).asMonths()
            );
            let days_count = parseInt(
              moment.duration(old_date.diff(current)).asDays()
            );
            endDateStyle = days_count > 0 ? "text-green" : "text-red";
            daystextStyle = months_count >= -2 ? "text-green" : "text-red";
            dateCaption =
              months_count >= 2 || months_count <= -2
                ? "( " + months_count + " Months)"
                : "(" + days_count + " Days)";
            last_date = data.user.last_login_datetime
              ? ComapnyDetailsDateTimeFormatter(
                  data.user.last_login_datetime,
                  false
                )
              : "-";
          } else {
            last_date_description = "-";
          }
          return {
            name: (
              <NavLink
                tag={RRNavLink}
                className={`${
                  last_date_description === "-" ? "text-red" : "text-green"
                }`}
                style={{ padding: 0, cursor: "pointer" }}
                exact
                to={{
                  pathname: formatRoute(
                    constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                  ),
                  state: {
                    id: data.user.id,
                    clientId: this.state.client_Id,
                    name: this.state.companyName,
                    fromDate: this.state.fromDate,
                    endDate: this.state.endDate,
                  },
                }}
              >
                <label
                  className={`mb-0 ${
                    last_date_description == "-" ? "text-red" : "text-green"
                  }`}
                >{`${data.user == null ? " " : data.user.first_name} ${
                  data.user === null ? " " : data.user.last_name
                } (${data.user === null ? " " : data.user.email})`}</label>
              </NavLink>
            ),
            add_date: `${add_date}`,
            downloads: `${data.downloadCount ? data.downloadCount : "-"}`,
            logins: `${data.loginCount ? data.loginCount : "-"}`,
            last_date: (
              <span
                className={`${daystextStyle} font-weight-semibold`}
              >{`${last_date} ${dateCaption}`}</span>
            ),
          };
        });
        this.setState({
          rowdata: clientData,
          count: meta_data.total_count,
        });
      })

      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
      });
  };

  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  getDateDifference = (fromDate, endDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(endDate);
    const diffTime = date2 - date1;
    if (diffTime < 0) {
      return false;
    }
    return true;
  };

  changeFromDate = (e, value) => {
    if (value !== null) {
      let bool =
        e === "start"
          ? this.getDateDifference(value, this.state.end_date)
          : this.getDateDifference(this.state.start_date, value);
      if (bool && e === "start") {
        this.setState(
          {
            start_date: moment(value).format("YYYY-MM-DD"),
            hasMore: true,
            pageCount: 1,
            loginData: [],
            pageNo: 1,
          },
          () => {
            this.loadFetchUserClient();
            this.getTableData();
            this.loadClientLastLogin();
            this.loadsegmentSpecific();
          }
        );
      } else if (bool) {
        this.setState(
          {
            end_date: moment(value).format("YYYY-MM-DD"),
            hasMore: true,
            pageCount: 1,
            loginData: [],
            pageNo: 1,
          },
          () => {
            this.loadFetchUserClient();
            this.getTableData();
            this.loadClientLastLogin();
            this.loadsegmentSpecific();
          }
        );
      }
    }
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true)
      : "";
    let companyName = clientId.name;
    clientId = clientId.id;
    this.setState(
      {
        client_Id: clientId,
        companyName: companyName,
        pageNo: 1,
      },
      () => {
        this.loadFetchUserClient();
        this.getTableData();
        this.loadClientLastLogin();
        this.loadsegmentSpecific();
      }
    );
  };

  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    return (
      <>
        <aside className="main_content">
          <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            <div className="tbr-client-list">
              <div className="row gutter-8">
                <div className="col-xl-3">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            Client Last Logins
                          </h5>
                          <div className="stats-group d-flex">
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.Sevendays}
                              </h5>
                              <label>7 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.Thirtydays}
                              </h5>
                              <label>30 days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.Sixtydays}
                              </h5>
                              <label>60 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.Nintydays}
                              </h5>
                              <label>90 Days</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                      <div className="card services-card">
                        <div className="card-body">
                          {this.state.segmentSpecific.length > 0 ? (
                            <div className="row">
                              {this.state.segmentSpecific.map(
                                (parent, index) => {
                                  return (
                                    <div
                                      className="col-12 col-md-6 col-lg-6 col-xl-12 custom-collapser-wrapper"
                                      key={index}
                                    >
                                      <div className="list-custom-collapser mb-3">
                                        <Collapser>
                                          <Trigger>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <h5 className="font-weight-bold mb-0">
                                                {parent.segment_name}
                                              </h5>
                                              <div className="d-flex align-items-center">
                                                <h5 className="mr-2 mb-0">
                                                  {parent.downloads}
                                                </h5>
                                                <span className="material-icons material-icon">
                                                  arrow_drop_up
                                                </span>
                                              </div>
                                            </div>
                                          </Trigger>
                                          <Panel>
                                            {parent.reportsCount.map(
                                              (element) => {
                                                return (
                                                  <div className="collapse-panel-content">
                                                    <div className="item d-flex align-items-center justify-content-between">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="name font-weight-semibold"
                                                      >
                                                        {element.name}
                                                      </a>
                                                      <span className="count">
                                                        {element.download_count}
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </Panel>
                                        </Collapser>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <div className={`no-records text-center`}>
                              {constants.PAGINATION.NO_RECORDS_FOUND}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9">
                  <div className="reports-right-block">
                    <div className="d-flex flex-wrap client-list-filter-block row">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                        <Search
                          handleSearch={this.handleSearch}
                          handleInput={this.handleInput}
                          value={this.state.name}
                          placeHolder={"Search with Name/Email"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                        <div className="custom-combobox-list with-noborder with-shadow-sm">
                          <CustomDropdownList
                            data={clients}
                            handleChange={this.handleClientChange}
                            className="reports_drop_down"
                          />
                        </div>
                      </div>
                      <div className="col clients_dropdown">
                        <div className="d-flex">
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="start"
                            date={this.state.start_date}
                            onChangeHandler={this.changeFromDate}
                            min={
                              this.state.userData &&
                              this.state.userData?.created_at
                                ? moment(
                                    this.state.userData?.created_at
                                  ).format("YYYY-MM-DD")
                                : null
                            }
                            max={this.state.end_date}
                          />
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="end"
                            date={this.state.end_date}
                            onChangeHandler={this.changeFromDate}
                            min={this.state.start_date}
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-black2 main-h3 mb-0 icon-fs-17 caps sections-card-wrapper p-2">
                      {this.state.companyName ? this.state.companyName : "User"}{" "}
                      (
                      {this.state.start_date
                        ? ComapnyDetailsDateFormatter(
                            this.state.start_date,
                            false
                          )
                        : "-"}{" "}
                      to{" "}
                      {this.state.end_date
                        ? ComapnyDetailsDateFormatter(
                            this.state.end_date,
                            false
                          )
                        : "-"}
                      )
                    </h3>
                    <div className="d-flex top-downloads-logins-block mb-3">
                      <div className="mr-0 mr-md-3 flex-grow-1 responsive-table mt-3">
                        <table className="h-100 w-100 normal-table">
                          <thead>
                            <tr>
                              <th>Top 5</th>
                              <th>
                                <div className="d-flex align-items-center table-icon-text justify-content-end">
                                  <span className="material-icons-round material-icon">
                                    login
                                  </span>
                                  <span className="ml-2 text-right">
                                    Logins
                                  </span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.generatelogincountTableData()}</tbody>
                        </table>
                      </div>
                      <div className="mr-0  flex-grow-1 responsive-table mt-3">
                        <table className="h-100 w-100 normal-table">
                          <thead>
                            <tr>
                              <th>Top 5</th>
                              <th>
                                <div className="d-flex align-items-center table-icon-text justify-content-end">
                                  <span className="material-icons-round material-icon">
                                    download
                                  </span>
                                  <span className="ml-2 text-right">
                                    Downloads
                                  </span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.generatedownloadcountTableData()}</tbody>
                        </table>
                      </div>
                    </div>

                    <div className="custom-table-block user-list-table sort-table-block nowrap-table-block center-th-align">
                      <DynamicTableComponentNew
                        columnData={tableHeader}
                        rowData={this.state.rowdata}
                        pagePrevious={this.pagePrevious}
                        pageNext={this.pageNext}
                        pageSize={this.state.pageSize}
                        pageNo={this.state.pageNo}
                        handlePage={this.handlePage}
                        handleSort={this.handleSort}
                        handlePageSize={this.handlePageSize}
                        sortColumn={this.state.sortColumn}
                        sortOrder={this.state.sortOrder}
                        count={this.state.count}
                        rowAction={this.rowAction}
                        colSpan={tableHeader.filter((h) => h.visibility).length}
                        showPagination={true}
                        paginationClass={
                          "center-pagination custom-pagination-new"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports2);
