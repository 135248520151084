import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { ClientServices } from "../Services/Client";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import Slugify from "../Utils/Slugify";
import { activeMenuTab } from "../Store/Actions/User";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import permissionCheck from "../lib/permissionCheck";
import IsTBRIClient from "../Utils/IsTBRIClient";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";

const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "contact_email",
    title: "Email",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "total_users",
    title: "Allowed Users",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "total_users_registered",
    title: "Reg. Users",
    sortable: false,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "subscription_duration",
    title: "Valid Upto",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;
class AssignedClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      currentPageDataCount: 0,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.CLIENT.LIST.HEADER_TITLE);
    this.getTableData();
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE);
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleClientConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT.INACTIVATE_CLIENT_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT.ACTIVATE_CLIENT_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_client" : "activate_client",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  removeClientConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Client</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT.REMOVE_CLIENT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_client", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeClient = (clientId) => {
    ClientServices.removeClient(clientId)
      .then((res) => {
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
        Toast(constants.CLIENT.REMOVE.SUCCESS, "success");
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleClient = (clientId, isActive) => {
    ClientServices.toggleClient(clientId, { is_active: isActive })
      .then((res) => {
        this.getTableData();
        Toast(
          isActive
            ? constants.CLIENT.ACTIVATE.SUCCESS
            : constants.CLIENT.IN_ACTIVATE.SUCCESS,
          "success"
        );
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_client":
        this.removeClient(config.id);
        break;
      case "de-activate_client":
        this.toggleClient(config.id, 0);
        break;
      case "activate_client":
        this.toggleClient(config.id, 1);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.CLIENT.LIST.MANAGE_USERS_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.CLIENT.LIST.MANAGE_USERS,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.CLIENT.LIST.MANAGE_USERS_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              people_alt
            </span>
          </button>
        </AclAction>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.CLIENT.LIST.REMOVE_CLIENT_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.CLIENT.LIST.REMOVE_CLIENT,
                data,
                e
              );
            }}
            className={"dynamicTableActionButton btn btn-danger"}
            data-tip={
              constants.APPLICATION_ROUTE.CLIENT.LIST.REMOVE_CLIENT_TOOLTIP
            }
            data-iscapture="true"
            disabled={IsTBRIClient(data)}
          >
            <span className="material-icons-outlined text-grey icon-fs-20">
              delete
            </span>
          </button>
        </AclAction>
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
    ClientServices.getAssignedClients(
      "/getAssignedClients?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;
        this.setState({
          currentPageDataCount: clientData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        let checkContentPageAccess = permissionCheck(
          constants.ACL.PERMISSION_KEY,
          constants.ACL.CLIENT_RESOURCE.TITLE,
          constants.ACL.CLIENT_RESOURCE.ACTIONS.EDIT_DETAILS
        );
        clientData = clientData.map((data) => {
          return {
            id: data.id,
            name: checkContentPageAccess && (
              <NavLink
                tag={RRNavLink}
                style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
                exact
                to={formatRoute(constants.APPLICATION_ROUTE.CLIENT.EDIT.ROUTE, {
                  id: data.id,
                })}
              >
                <label className={`mb-0`}>{data.name}</label>
              </NavLink>
            ),
            contact_email: data.contact_email ? data.contact_email : "-",
            total_users: data.total_users ? data.total_users : "Unlimited",
            no_of_leads_registered: data.no_of_leads_registered
              ? data.no_of_leads_registered
              : "-",
            total_users_registered: data.total_users_registered,
            created_by: data.created_by_user
              ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
              : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            subscription_duration: `${
              data.end_date
                ? ComapnyDetailsDateFormatter(data.end_date, false)
                : "-"
            }`,
            is_active: (
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.CLIENT_RESOURCE.TITLE}
                action={constants.ACL.CLIENT_RESOURCE.ACTIONS.STATUS}
              >
                <div className="custom-control custom-switch success-toggle">
                  <input
                    type="checkbox"
                    disabled={IsTBRIClient(data)}
                    className="custom-control-input"
                    id={`customSwitch${data.id}`}
                    checked={data.is_active === 1}
                    onChange={(e) => {
                      e.stopPropagation();
                      this.rowAction(
                        constants.APPLICATION_ROUTE.CLIENT.LIST.STATUS,
                        data,
                        e
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    for={`customSwitch${data.id}`}
                  ></label>
                </div>
              </AclAction>
            ),
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: clientData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT.LIST.EDIT_CLIENT:
        this.props.history.push({
          pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT.EDIT.ROUTE, {
            id: data.id,
          }),
        });
        break;
      case constants.APPLICATION_ROUTE.CLIENT.LIST.STATUS:
        this.toggleClientConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT.LIST.REMOVE_CLIENT:
        this.removeClientConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT.LIST.MANAGE_USERS:
        if (IsTBRIClient(data))
          this.props.history.push({
            pathname: formatRoute(
              constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE
            ),
          });
        else
          this.props.history.push({
            pathname: formatRoute(
              constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
              { clientId: data.id }
            ),
            search: "?selected_tab=assigned-users",
          });
        break;
      default:
        break;
    }
  };
  addClient = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT.CREATE.ROUTE,
        {}
      ),
    });
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  render() {
    return (
      <>
        <div className="d-flex align-items-center flex-wrap">
          <div className="min-width-266 border-search search-box-shadow-2 mb-3">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Filter Client Name"}
            />
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block assigned-clients-table-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>

        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT.LIST.REMOVE_CLIENT_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT.LIST.MANAGE_USERS_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedClients);
