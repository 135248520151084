import { Axios } from "../lib/axios";
export const FileUploadServices = {
  getFiles,
  addCompanySegmentFile,
  getFileById,
  downloadExcel,
  uploadCompanyMetrics,
  getMetricTransactionRecords,
  approveTransaction,
  rejectTransaction,
  reportContent,
  addFileThroughBuildVu,
};
function getFiles(url) {
  return Axios.get(url);
}
function addCompanySegmentFile(companyId, segmentId, body) {
  return Axios.post(
    `company/${companyId}/segment/${segmentId}/groupdocs`,
    body
  );
}
function addFileThroughBuildVu(companyId, segmentId, body) {
  return Axios.post(`company/${companyId}/segment/${segmentId}/buildvu`, body);
}
function reportContent(service, body) {
  return Axios.post(`report/${service}`, body);
}
function getFileById(fileId) {
  return Axios.get(`file/${fileId}`);
}
function downloadExcel(lob_ids = []) {
  let api = `export?type=metricstandardtemplate&extension=xlsx${
    lob_ids.length > 0 ? "&lob_ids=" + lob_ids.join(",") : ""
  }`;
  return Axios.get(api, { responseType: "arraybuffer" });
}
function uploadCompanyMetrics(companyId, body) {
  return Axios.post(`company/${companyId}/file`, body);
}
function getMetricTransactionRecords(url) {
  document.body.classList.add("second-loading-indicator");
  return Axios.get(url, {
    timeout: 30000,
  });
}
function approveTransaction(transactionId) {
  return Axios.post(
    `transaction/${transactionId}/type/company_metric/state/approve`
  );
}
function rejectTransaction(transactionId) {
  return Axios.post(
    `transaction/${transactionId}/type/company_metric/state/reject`
  );
}
