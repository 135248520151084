import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import constants from "../Utils/constants";

const PreferenceCenterSidebar = ({
  notificationItems,
  selectedNotificationItem,
}) => {
  const history = useHistory();
  const [collapseIsOpen1, setCollapseIsOpen1] = useState(true);
  const [collapseIsOpen2, setCollapseIsOpen2] = useState(false);
  return (
    <div className="preference-sidebar">
      <div
        className="d-flex ml-4 mt-4 cursor-pointer"
        onClick={() =>
          history.push(
            formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {})
          )
        }
      >
        <span className="material-icons icon-fs-24">arrow_back</span>
        <h3 className="preference-head">Preference Center</h3>
      </div>
      <div className="pref-collapser-item">
        <Collapsible
          open={collapseIsOpen1}
          trigger={
            <div
              onClick={() => setCollapseIsOpen1(!collapseIsOpen1)}
              className="preference-collapse"
            >
              <h2 className="pref-collapse-header">Notification</h2>
              <span
                style={{ color: "#fff" }}
                className="material-icons-outlined material-icon mr-2"
              >
                {collapseIsOpen1 ? "expand_less" : "expand_more"}
              </span>
            </div>
          }
        >
          <div className="pref-collapse-list">
            {notificationItems.map((item) => (
              <div
                onClick={() =>
                  selectedNotificationItem(item.slug, item.id, item.name)
                }
                key={item.slug}
                className={
                  item.is_selected
                    ? `pref-list-item-selected `
                    : `pref-list-item`
                }
              >
                <span
                  style={item.is_selected ? { color: "#5B87C4" } : {}}
                  className="material-icons-outlined pl-0 icon-fs-20 align-middle"
                >
                  {item.icon}
                </span>
                <p
                  className={
                    item.is_selected
                      ? "pref-list-name-selected"
                      : "pref-list-name"
                  }
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </Collapsible>
      </div>
      <div className="pref-collapser-item">
        <Collapsible
          open={collapseIsOpen2}
          trigger={
            <div
              onClick={() => setCollapseIsOpen2(!collapseIsOpen2)}
              className="preference-collapse-2"
            >
              <h2 className="pref-collapse-header-2">General</h2>
              <span
                style={{ color: "#555555" }}
                className="material-icons-outlined material-icon mr-2"
              >
                {collapseIsOpen2 ? "expand_less" : "expand_more"}
              </span>
            </div>
          }
        >
          <div className="pref-collapse-list"></div>
        </Collapsible>
      </div>
    </div>
  );
};

export default PreferenceCenterSidebar;
