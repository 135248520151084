import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { NavLink } from "reactstrap";

import AclAction from "../../Components/ACL/AclAction";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import Dailog from "../../Components/DailogNew";
import DynamicTableWithSelectAllComponentNew from "../../Components/DynamicTableWithSelectAllNew";
import Search from "../../Components/Search";
import { ApiCredentialServices } from "../../Services/ApiCredentialServices";
import { activeMenuTab } from "../../Store/Actions/User";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../../Utils/constants";
import Toast from "../../Utils/Toast";
import { ClientServices } from "../../Services/Client";
import IsTBRIClient from "../../Utils/IsTBRIClient";
import CustomDropdownList from "../../Components/CustomDropdownList";

const tableHeader = [
  {
    id: "user_name",
    title: "User Name",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "client_name",
    title: "Client Name",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
  },
  {
    id: "logged_by",
    title: "Logged By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "active_apis",
    title: "Active API's",
    sortable: false,
    headerStyle: {},
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
  {
    id: "api_status",
    title: "API Status",
    sortable: false,
    headerStyle: {},
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
];

const ApiClientTracker = () => {
  const dispatch = useDispatch();
  const [rowData, setrowData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(constants.PAGINATION.DEFAULT_PAGE_NO);
  const [pageSize, setPageSize] = useState(
    constants.PAGINATION.DEFAULT_PAGE_SIZE
  );
  const [sortOrder, setSortOrder] = useState(
    constants.PAGINATION.COMPANY_DEFAULT_SORT_ORDER
  );
  const [sortField, setsortField] = useState(
    constants.PAGINATION.COMPANIES_DEFAULT_SORT_COLUMN
  );
  const [searchNameEmail, setSearchNameEmail] = useState(null);
  const [clients, setClients] = useState([
    { id: "all", name: "All Clients", is_selected: true },
  ]);
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dailogModalContent, setdailogModalContent] = useState(null);
  const [dailogModalHeader, setdailogModalHeader] = useState(null);
  const [dailogModalConfig, setdailogModalConfig] = useState({});
  const [dailogModalStyleType, setdailogModalStyleType] = useState(null);

  const getTableData = () => {
    let body = {
      is_active: true,
    };
    let queryParams = "";
    if (sortOrder) {
      queryParams = "?sortField=" + sortField + "&sortOrder=" + sortOrder;
    }
    // Check if searchNameEmail has a value and add it to the queryParams
    if (searchNameEmail && searchNameEmail.trim() !== "") {
      queryParams += `&user_name=${encodeURIComponent(searchNameEmail)}`;
    }
    // Add client_id to query params if available
    if (selectedClientId && selectedClientId !== "all") {
      queryParams += `&client_id=${encodeURIComponent(selectedClientId)}`;
    }

    ApiCredentialServices.getAllApiClients(queryParams, body)
      .then((res) => {
        let clientApiData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        clientApiData = clientApiData.map((data) => {
          return {
            id: data?.id,
            user_name: data?.owner?.first_name,
            client_name: data?.client?.name ? (
              <NavLink
                tag={RRNavLink}
                style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
                exact
                to={formatRoute(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE, {
                  id: data.id,
                })}
              >
                <label className={`mb-0`}>{data?.client?.name}</label>
              </NavLink>
            ) : (
              <span>{data?.client?.name}</span>
            ),
            logged_by: data?.sso_type ?? "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            active_apis: (
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.CLIENT_RESOURCE.TITLE}
                action={constants.ACL.CLIENT_RESOURCE.ACTIONS.STATUS}
              >
                <div className="custom-control custom-switch success-toggle">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`customSwitch${data.id}`}
                    checked={data.is_active === true}
                    disabled={data?.client?.is_open_api_active === 0}
                    onChange={(e) => {
                      e.stopPropagation();
                      updateUserApiStatus(data);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    for={`customSwitch${data.id}`}
                  />
                </div>
              </AclAction>
            ),
            api_status: data?.key_status ?? "-",
          };
        });
        setrowData(clientApiData);
        setCount(count);
      })
      .catch((error) => {
        setrowData([]);
        setCount(0);
        console.error(
          "Getting error while fetching get all client api data",
          error
        );
      });
  };

  const updateUserApiStatus = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === true ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === true ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.APPLICATION_ROUTE.API_CLIENT_TRACER
                  .INACTIVATE_API_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.APPLICATION_ROUTE.API_CLIENT_TRACER
                  .ACTIVATE_COMPANY_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        )}
      </>
    );
    setIsModalOpen(true);
    setdailogModalHeader(dailogModalHeader);
    setdailogModalContent(dailogModalContent);
    setdailogModalConfig({
      type:
        data?.is_active === true ? "de-activate_API_USER" : "activate_API_USER",
      id: data.id,
    });
    setdailogModalStyleType(
      data.is_active === true ? "danger-modal" : "success-modal"
    );
  };
  const dailogModalAccept = (config) => {
    setIsModalOpen(false);
    setdailogModalHeader("");
    setdailogModalContent("");
    setdailogModalConfig({});

    switch (config.type) {
      case "de-activate_API_USER":
        toggleUserApiSwitch(config.id, false);
        break;
      case "activate_API_USER":
        toggleUserApiSwitch(config.id, true);
        break;
      default:
        break;
    }
  };

  const dailogModalDecline = () => {
    setIsModalOpen(false);
    setdailogModalHeader("");
    setdailogModalContent("");
    setdailogModalConfig({});
  };

  const toggleUserApiSwitch = (user_id, isActive) => {
    let body = {
      is_active: isActive,
    };
    ApiCredentialServices.updateUserApiStatus(user_id, body)
      .then((res) => {
        getTableData();
        Toast(
          isActive
            ? constants.APPLICATION_ROUTE.API_CLIENT_TRACER.ACTIVATE_USER
            : constants.APPLICATION_ROUTE.API_CLIENT_TRACER.IN_ACTIVATE_USER,
          "success"
        );
      })
      .catch((error) => {
        getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const pagePrevious = () => {
    setPageNo((prevPageNo) => prevPageNo - 1);
  };

  const pageNext = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
  };
  const handlePage = (input) => {
    setPageNo(input);
  };
  const handleSort = (clickedColumn, key) => {
    setsortField(clickedColumn);
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };
  const handleSearch = () => {
    getTableData();
    setPageNo(1);
  };
  const handlePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
  };
  const handleInput = (e) => {
    setSearchNameEmail(e.target.value);
  };
  // handler for client name filtering dropdown
  const handleClientChange = (selectedClient) => {
    const updatedClients = clients.map((client) => ({
      ...client,
      is_selected: client.id === selectedClient.id,
    }));
    setClients(updatedClients);
    const clientId =
      updatedClients.find((c) => c.is_selected === true)?.id || null;
    setSelectedClientId(clientId);
    setPageNo(1);
  };

  // Fetching All the clients details
  const getAllClients = () => {
    ClientServices.getAllClients()
      .then((res) => {
        let response = res?.data?.data || [];
        response = response
          .filter((client) => !IsTBRIClient(client))
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
        // Add the "All Clients" option at the beginning
        const allClientsOption = {
          id: "all",
          name: "All Clients",
          is_selected: true,
        };
        const updatedClients = [
          allClientsOption,
          ...response.map((client) => ({ ...client, is_selected: false })),
        ];
        setClients(updatedClients);
      })
      .catch((error) => {
        Toast(error?.data?.error || "Something went wrong", "error");
      });
  };

  useEffect(() => {
    getTableData();
    dispatch(
      activeMenuTab(constants.APPLICATION_ROUTE.API_CLIENT_TRACER.LIST.ROUTE)
    );
  }, [pageNo, sortOrder, pageSize, selectedClientId]);

  // Fetch all clients when the component mounts
  useEffect(() => {
    getAllClients();
  }, []);

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.API_CLIENT_TRACER.LIST.NAME,
        link: null,
        is_active: false,
      },
    ];
    return breadcrumbs;
  };
  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white br_8 main_content_height d-flex p-20 flex-d">
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>
        <div className={"d-flex align-items-center mb-3"}>
          <div
            className="min-width-266 border-search search-box-shadow-2"
            style={{ paddingRight: "15px" }}
          >
            <Search
              handleSearch={handleSearch}
              handleInput={handleInput}
              value={searchNameEmail}
              placeHolder={"Filter User Name"}
            />
          </div>
          <div
            className="min-width-266 border-search search-box-shadow-2"
            style={{ padding: "0px 15px" }}
          >
            <CustomDropdownList
              data={clients}
              handleChange={handleClientChange}
            />
          </div>
        </div>
        <div className="row">
          <div className={"col-lg-12 col-sm-12 col-md-12 col-12 "}>
            <div
              className={`Account manager-list-table-block colored-th-table-block custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align company-table`}
            >
              <DynamicTableWithSelectAllComponentNew
                columnData={tableHeader}
                rowData={rowData}
                showPagination={true}
                pageNext={pageNext}
                pagePrevious={pagePrevious}
                pageSize={pageSize}
                pageNo={pageNo}
                count={count}
                paginationClass={"center-pagination custom-pagination-new"}
                handlePageSize={handlePageSize}
                handleSort={handleSort}
                sortColumn={sortField}
                sortOrder={sortOrder}
                colSpan={tableHeader.length}
              />
            </div>
          </div>
        </div>
        <Dailog
          isOpen={isModalOpen}
          accept={dailogModalAccept}
          decline={dailogModalDecline}
          header={dailogModalHeader}
          content={dailogModalContent}
          config={dailogModalConfig}
          modalStyleType={dailogModalStyleType}
        />
      </div>
    </aside>
  );
};

export default ApiClientTracker;
