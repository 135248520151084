import React from 'react'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import { DatePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'
Moment.locale('en')
momentLocalizer()

const CustomDatePicker = (props) => {
  return (
    <DatePicker
      defaultValue={props.date && props.date !== null && props.date !== '' ? new Date(props.date) : null}
      onChange={(val) =>{props.onChangeHandler(props.id, val)}}
      disabled={props.isDisabled}
      className={props.className ? props.className : ''}
      min={props.min && new Date(props.min)}
      max={props.max && new Date(props.max)}
      value={props.date && props.date !== null && props.date !== '' ? new Date(props.date) : null}
      placeholder={'mm/dd/yyyy'}
    />
  )
}

export default CustomDatePicker
