import React, { Component } from "react";
import { debounce } from "lodash";
class Search extends Component {
  state = {
    value: this.props.value ? this.props.value : "",
  };
  onSearchChange = (e) => {
    e.persist();
    this.setState({
      value: e.target.value,
    });
    if (e.target.value.trim().length > 2 || e.target.value.length === 0) {
      this.handleSearch(e);
    }
  };
  handleSearch = debounce((e) => {
    this.props.handleInput(e);
    this.props.handleSearch();
  }, 1000);
  componentDidMount() {
    this.setState({
      value: this.props.value ? this.props.value : "",
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value ? this.props.value : "",
      });
    }
  }

  render() {
    return (
      <div className="search-form">
        <input
          className="form-control"
          type="text"
          onChange={(e) => this.onSearchChange(e)}
          placeholder={
            this.props.placeHolder
              ? this.props.placeHolder
              : "Filter Company Name"
          }
          aria-label={
            this.props.placeHolder
              ? this.props.placeHolder
              : "Filter Company Name"
          }
          aria-describedby="basic-addon2"
          value={this.state.value}
          disabled={this.props.disabled ? this.props.disabled : false}
        />
        <button className="btn my-sm-0" type="button">
          <span className="material-icons-outlined">search</span>
        </button>
      </div>
    );
  }
}
export default Search;
