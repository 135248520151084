import React from "react";
import Collapsible from "react-collapsible";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number.toString();
};


const FaqsList = React.memo((props) => {
  return (
    <div style={{ height: "70vh", overflowY: "scroll" }}>
      {props.faqList.map((item, index) => (
        <div
          className="faq-collapser-item"
          style={{
            borderTop: index > 0 ? "1px solid #d9d9d9" : "none",
            padding: "15px 10px",
          }}
        >
          <Collapsible
            open={props.collapseId === index}
            trigger={
              <div
                onClick={() =>
                  props.setCollapseId(props.collapseId === index ? null : index)
                }
                className="faq-collapse cursor-pointer"
              >
                <h2 className="faq-collapse-header d-flex align-item-center">
                  <span className="span_1">{formatNumber(index + 1)}</span>
                  <span className="span_2">{item.question}</span>
                </h2>
                <div className="d-flex g-10 align-item-center">
                  <div
                    className="grid-switch"
                    data-for={`tooltip-${index}-active-inactive-resource`}
                    data-tip={item.is_active ? "Active FAQ" : "Deactive FAQ"}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.setActiveModal(true);
                      props.setForm(item);
                    }}
                  >
                    <input
                      type="checkbox"
                      id={`grid-switch-${index}`}
                      checked={item.is_active ? true : false}
                    />
                    <label htmlFor={`grid-switch-${index}`}>
                      <span
                        style={{
                          outline: item.is_active
                            ? "2px solid #2196f3"
                            : "2px solid #ADB5BD",
                        }}
                        className={`${
                          item.is_active
                            ? "grid-slider active-grid-slider"
                            : "grid-slider deactive-grid-slider"
                        }`}
                      ></span>
                    </label>
                  </div>
                  <span
                    className="material-icons-outlined text-blue-clr icon-fs-20 cursor-pointer"
                    data-for={`tooltip-${index}-edit-resource`}
                    data-tip={"Edit FAQ"} // onClick={() => handleEditResource(item)}
                    onClick={() => props.handleEditFaq(item)}
                  >
                    edit
                  </span>
                  <span
                    className="material-icons-outlined text-red icon-fs-20 cursor-pointer"
                    data-for={`tooltip-${index}-delete-resource`}
                    data-tip={"Delete FAQ"}
                    onClick={() => {
                      props.setIsDelete(true);
                      props.setForm(item);
                    }}
                  >
                    delete
                  </span>

                  <CustomReactTooltip
                    id={`tooltip-${index}-edit-resource`}
                    multiline
                  />
                  <CustomReactTooltip
                    id={`tooltip-${index}-delete-resource`}
                    multiline
                  />
                  <CustomReactTooltip
                    id={`tooltip-${index}-active-inactive-resource`}
                    multiline
                  />
                  <span className="faq-add-icon material-icons-outlined material-icon mr-2">
                    {props.collapseId === index ? "expand_less" : "expand_more"}
                  </span>
                </div>
              </div>
            }
          >
            <div className="faq-collapse-list curser-pointer">
              {item.answer}
            </div>
          </Collapsible>
        </div>
      ))}
    </div>
  );
});

export default FaqsList;
