import axios from 'axios'
import { formatRoute } from 'react-router-named-routes'
import constants from '../Utils/constants'
import Toast from '../Utils/Toast'

let baseURL
baseURL = process.env.REACT_APP_TBRI_API_URL

let Axios_Reports

const init = () => {
  Axios_Reports = axios.create({
    baseURL: baseURL,
    timeout: 60000
  })
  Axios_Reports.interceptors.request.use(handleSuccessRequest, handleErrorRequest)
  Axios_Reports.interceptors.response.use(handleSuccess, handleError)
}

const handleSuccessRequest = (request) => {
  if (localStorage.getItem('token'))
    request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  return request
}

const handleErrorRequest = (error) => {
  return Promise.reject(error)
}

const handleSuccess = (response) => {
  return response
}

const handleError = (error) => {
  if (error.message === 'Network Error') {
    // The user doesn't have internet
    return Promise.reject(error.response)
  }
  try {
    switch (error.response.status) {
      case 400:
        let error_msg = (error && error.response && error.response.data && error.response.data.error) || ''
        if (error_msg === constants.TOKEN.INVALID_MESSAGE) {
          document.body.classList.remove('loading-indicator')
          localStorage.removeItem('token')
          localStorage.removeItem('refresh_token')
          window.location.href = formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        }
        break
      case 401:
        //Un authorized   
        if (localStorage.getItem('refresh_token')) {
          document.body.classList.add('loading-indicator')
          return axios.post(baseURL + 'token', { "refresh_token": localStorage.getItem('refresh_token') }, {})
            .then(responseNew => {
              localStorage.setItem('token', responseNew.data.token.access_token)
              localStorage.setItem('refresh_token', responseNew.data.token.refresh_token)
              error.response.config.__isRetryRequest = true
              error.response.config.headers.Authorization = 'Bearer ' + responseNew.data.token.access_token
              document.body.classList.remove('loading-indicator')
              return axios(error.response.config)
            })
            .catch(res => {
              document.body.classList.remove('loading-indicator')
              localStorage.removeItem('token')
              localStorage.removeItem('refresh_token')
              window.location.href = formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
            })
        }
        break
      case 404:
        // Show 404 page
        break
      case 422:
        Toast(error.response.data.error,'error')
      case 500:
        // Serveur Error redirect to 500
        break
      default:
        Promise.reject(error.response)
        break
    }
  } catch (e) {
  }
  return Promise.reject(error.response)
}

init()

export {
  Axios_Reports,
  baseURL,
  init,
}

