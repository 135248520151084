import { Axios } from '../lib/axios'
export const TemplateServices = {
  getTotalTemplates,
  getTemplates,
  getTemplateDetails,
  addTemplate,
  toggleTemplate,
  removeTemplate,
  updateThreshold
}
function getTotalTemplates(url) {
  return Axios.get(url)
}
function getTemplates(url) {
  //this api is called within an Api. so loading indicator is being removed if fst API call is completed
  //so adding its own loading indicator.
  document.body.classList.add('second-loading-indicator');  
  return Axios.get(url)
}
function getTemplateDetails(templateRecordId) {
  return Axios.get(`templaterecord/${templateRecordId}`)
}
function addTemplate(body) {
  return Axios.post(`templaterecord`, body)
}
function toggleTemplate(templateRecordId, body) {
  return Axios.patch(`templaterecord/${templateRecordId}/status/${body.is_active}`)
}
function removeTemplate(templateRecordId) {
  return Axios.delete(`templaterecord/${templateRecordId}`)
}
function updateThreshold(templateRecordId, body) {
  return Axios.patch(`templaterecord/${templateRecordId}`,body)
}