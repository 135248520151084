import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { MasterServices } from "../Services/Master";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import AclAction from "../Components/ACL/AclAction";
import Slugify from "../Utils/Slugify";
import ManageMasterData from "../Containers/ManageMasterData";
import Toast from "../Utils/Toast";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "name",
    title: "name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    rowClass: "",
    visibility: true,
  },
];
const showActions = false;
class ListVerticalSubCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: this.props.pageNo,
      pageSize: this.props.pageSize,
      name: "",
      documentModal: false,
      apiError: null,
      formErrors: {},
      isFormSubmitted: false,
      disableStatus: false,
    };
  }
  componentDidMount() {
    this.getTableData();
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.VERTICAL_SUB_CATEGORY_RESOURCE.TITLE}
          action={constants.ACL.VERTICAL_SUB_CATEGORY_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.MASTER_DATA_VERTICAL_SUB_CATEGORY.LIST
                .REMOVE_VERTICAL_SUB_CATEGORY_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.MASTER_DATA_VERTICAL_SUB_CATEGORY
                  .LIST.REMOVE_VERTICAL_SUB_CATEGORY,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.MASTER_DATA_VERTICAL_SUB_CATEGORY.LIST
                .REMOVE_VERTICAL_SUB_CATEGORY_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-red icon-fs-20">
              delete
            </span>
            <CustomReactTooltip
              id={`tooltip-${Slugify(
                constants.APPLICATION_ROUTE.MASTER_DATA_VERTICAL_SUB_CATEGORY
                  .LIST.REMOVE_VERTICAL_SUB_CATEGORY_TOOLTIP
              )}-${data.id}`}
              multiline={true}
            />
          </button>
        </AclAction>
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
    MasterServices.getTotalLobs(
      "lobs?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let lobData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        lobData = lobData.map((data) => {
          return {
            id: data.id,
            name: data.name ? data.name : "-",
            created_by: data.created_by_user
              ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
              : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: lobData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  addVerticalSubCategory = () => {
    this.setState({
      documentModal: true,
    });
  };

  closeDocumentModal = () => {
    this.setState({
      documentModal: false,
    });
  };

  handleSubmit = (data) => {
    MasterServices.addVertical(data)
      .then((res) => {
        Toast(constants.TBRI_USER.REMOVE.SUCCESS, "success");
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.MASTER_DATA_VERTICAL_SUB_CATEGORY.LIST
        .REMOVE_VERTICAL_SUB_CATEGORY:
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <>
        <div className="d-flex">
          <div className="min-width-266 border-search search-box-shadow-2 mb-3">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Filter Vertical Sub-Category"}
            />
          </div>
          <div className="d-flex justify-content-end align-self-end flex-wrap ml-auto">
            <AclAction
              type="permissions"
              entity={constants.ACL.VERTICAL_SUB_CATEGORY_RESOURCE.TITLE}
              action={
                constants.ACL.VERTICAL_SUB_CATEGORY_RESOURCE.ACTIONS.CREATE
              }
            >
              <aside tbrentitytype={constants.ACL.ENTITY_TYPE.DIV} className="">
                <button
                  className={
                    "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                  }
                  type="button"
                  onClick={this.addVerticalSubCategory}
                >
                  <span className="material-icons-round icon-fs-16 mr-1">
                    add
                  </span>
                  {" " +
                    constants.APPLICATION_ROUTE
                      .MASTER_DATA_VERTICAL_SUB_CATEGORY.LIST.ADD_BUTTON_TITLE}
                </button>
              </aside>
            </AclAction>
          </div>
        </div>

        <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>
        <ManageMasterData
          documentModal={this.state.documentModal}
          closeDocumentModal={this.closeDocumentModal}
          masterName={this.state.name}
          addMasterData={this.handleSubmit}
          title={"Vertical Sub Category"}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps, null)(ListVerticalSubCategories);
