import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

const CustomDigestButton = ({
  frequencyType,
  setTheEmailFrequency,
  openModal,
  closeModal,
  frequency,
  setTheFrequency,
  saveModalFrequency,
}) => {
  return (
    <div className="d-flex flex-column align-items-end">
      <div
        className={frequencyType === "digest" ? "digest-btn" : "immediate-btn"}
      >
        <div
          onClick={() => setTheEmailFrequency("digest")}
          className={
            frequencyType === "digest" ? "select-digest" : "select-immediate"
          }
        >
          Digest
        </div>
        <div
          onClick={() => setTheEmailFrequency("immediate")}
          className={
            frequencyType === "immediate" ? "select-digest" : "select-immediate"
          }
        >
          Immediate
        </div>
      </div>
      {frequencyType === "digest" && frequency !== 0 && (
        <p onClick={() => setTheEmailFrequency("digest")} className="freq-text">
          Email frequency has been set {frequency === 1 ? "daily" : "weekly"}
        </p>
      )}
      <div>
        <Modal
          isOpen={openModal}
          className={"modal-sm modal-w-header custom-modal primary-modal"}
        >
          <div className="">
            <span
              onClick={closeModal}
              style={{ color: "#555555", float: "right", cursor: "Pointer" }}
              className="material-icons"
            >
              close
            </span>
          </div>
          <div className="pref-modal-content">
            <h3>Email Frequency</h3>
            <p>Recieve email {frequency === 1 ? "daily" : "weekly"}.</p>
            <div className="d-flex mt-1">
              <div className="d-flex">
                <input
                  style={{ width: "18px", height: "18px" }}
                  type="radio"
                  value={1}
                  checked={frequency == 1 ? true : false}
                  onChange={() => setTheFrequency(1)}
                />
                <h4 className="pref-radio-text">Daily</h4>
              </div>
              <div className="d-flex ml-5">
                <input
                  style={{ width: "18px", height: "18px" }}
                  type="radio"
                  value={7}
                  checked={frequency == 7 ? true : false}
                  onClick={() => setTheFrequency(7)}
                />
                <h4 className="pref-radio-text">Weekly</h4>
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <ModalFooter>
              <Button
                className="modal-btn btn-outline-primary"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                onClick={saveModalFrequency}
                color="primary"
                className="modal-btn"
                type="submit"
              >
                Save
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CustomDigestButton;
