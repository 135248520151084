import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { CompanyServices } from "../Services/Company";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import permissionCheck from "../lib/permissionCheck";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Toast from "../Utils/Toast";
import CustomDropdownList from "../Components/CustomDropdownList";
import { segments } from "../Store/Actions/User";
import Notification from "../Components/Notification";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import CustomPPTUploader from "../Components/CustomPPTUploader";
import { FileUploadServices } from "../Services/FileUpload";
import CustomMultiSelectCheckbox from "../Components/CustomMultiSelectCheckbox";
import { ClientServices } from "../Services/Client";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";
import DownloadModal from "./Metrics/DownloadModal";
import { MasterServices } from "../Services/Master";
import ReactTooltip from "react-tooltip";

const tableHeader = [
  {
    id: "name",
    title: "File Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "companyName",
    title: "Company",
    sortable: false,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    visibility: true,
  },
  // {
  //   id: "status",
  //   title: "File Status",
  //   sortable: true,
  //   headerStyle: { textAlign: "left" },
  //   rowStyle: { textTransform: "capitalize", textAlign: "left" },
  //   visibility: true,
  // },
  {
    id: "txn_status",
    title: "Txn. Status",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "uploadedBy",
    title: "Uploaded By",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Uploaded On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;

const permissionObj = {
  to_verify: "To Verify",
  accepted: "Accepted",
  rejected: "Rejected",
};

const tooltipLegend = {
  to_verify: (
    <span
      className='tooltip-permission-legend'
      style={{ background: "#C3BC05" }}
    >
      To Verify
    </span>
  ),
  accepted: (
    <span
      className='tooltip-permission-legend'
      style={{ background: "#1DAC74" }}
    >
      Accepted
    </span>
  ),
  rejected: (
    <span
      className='tooltip-permission-legend'
      style={{ background: "#D63864" }}
    >
      Rejected
    </span>
  ),
};
class ListMetricV2Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.FILE_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      companyId: "",
      segmentId: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      documentModal: false,
      apiError: null,
      backendError: false,
      backendErrorContent: "",
      uploadSegments: [],
      uploadCompanies: [],
      formErrors: {},
      isFormSubmitted: false,
      segmentList: [],
      disableStatus: false,
      open: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.FILE_DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
        companyId: params.companyId ? params.companyId : "all",
        segmentId: params.segmentId ? params.segmentId : "all",
      },
      () => {
        this.getCompanies();
        this.getTableData();
      }
    );
    ClientServices.getSegmentDetails()
      .then((res) => {
        let segmentsResponse = res && res.data && res.data.data;
        let segmentList = [...segmentsResponse];
        segmentList.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        segmentList = segmentList.map((s) => {
          s.is_selected = true;
          return s;
        });
        this.setState({ segmentList: segmentList });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE);
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.documentModal !== this.state.documentModal) {
      this.getTableData();
    }
  }

  handlerefresh = () => {
    this.getTableData();
  };

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };

  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleSegmentChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    segments.forEach((s) => {
      s.is_selected = selectedSegments.includes(s.id);
    });
    this.setState({ segmentList: segments });
  };

  getCompanies = () => {
    CompanyServices.getTotalCompanies("companies")
      .then((res) => {
        let response = res && res.data && res.data.data;
        let companies = [];
        companies.push({ id: "all", name: "All Companies" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let totalCompanies = companies.concat(response);
        totalCompanies.forEach((r) => {
          r.is_selected = r.id == this.state.companyId ? true : false;
        });
        this.setState({
          companies: totalCompanies,
          uploadCompanies: CustomCloneDeep(response),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      ); //removes the loading indicator
  };

  download = (downloadId) => {};

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    switch (config.type) {
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  handleDownloadTemplate = () => {
    let segment_ids = this.state.segmentList
      .filter((f) => f.is_selected)
      .map((s) => s.id);
    FileUploadServices.downloadExcel(segment_ids)
      .then((response) => {
        // let response = res && res.data
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "standardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          let segmentList = this.state.segmentList.map((s) => {
            s.is_selected = true;
            return s;
          });
          this.setState({ segmentList: segmentList });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.DOWNLOAD}
        >
          <button
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD,
                data,
                e
              );
            }}
            className={"dynamicTableActionButton btn btn-primary"}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
            }
            data-iscapture='true'
          >
            <i className={"fa fa-download"} />
            <CustomReactTooltip
              id={`tooltip-${Slugify(
                constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
              )}-${data.id}`}
              multiline={true}
            />
          </button>
        </AclAction>
      </>
    );
  };

  fetchCompanies = (data) => {
    return data.map((c) => c.company_data.name);
  };

  baseName = (str) => {
    let base = new String(str).substring(str.lastIndexOf("\\") + 1);
    base = new String(base).substring(base.lastIndexOf("/") + 1);
    return decodeURI(base);
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.state.companyId !== "" && this.state.companyId !== "all") {
      queryParams += "&companyId=" + this.state.companyId;
    }
    if (this.state.segmentId !== "" && this.state.segmentId !== "all") {
      queryParams += "&segmentId=" + this.state.segmentId;
    }
    this.changeRoute();
    let url = "/metric_template/list";
    MasterServices.getFiles(
      `${url}?source=admin_upload&type=metrics_upload&page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        clientData = clientData.map((data) => {
          let state = data.currentRole + data.currentState;
          let companiesArray = this.fetchCompanies(data.transactions_data);
          return {
            id: data.id,
            name: data.name ? (
              <NavLink
                tag={RRNavLink}
                style={{
                  padding: 0,
                  color: "#1085c6",
                  cursor: "pointer",
                  textAlign: "left",
                }}
                exact
                to={formatRoute(
                  constants.APPLICATION_ROUTE.METRIC_RECORD_V2.LIST.ROUTE,
                  { id: data.id }
                )}
              >
                <label className={`mb-0`}>
                  <span>{this.baseName(decodeURIComponent(data.s3_url))}</span>{" "}
                  <span>
                    <span className='material-icons-round icon-fs-14 valign-middle'>
                      open_in_new
                    </span>
                  </span>
                </label>
              </NavLink>
            ) : (
              "-"
            ),
            companyName: companiesArray.toString(),
            txn_status: (
              <div className='custom-link'>
                <NavLink
                  tag={RRNavLink}
                  style={{
                    padding: 0,
                    color: "#1085c6",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                  exact
                  to={formatRoute(
                    constants.APPLICATION_ROUTE.METRIC_RECORD_V2.VIEW.ROUTE,
                    { id: data.id }
                  )}
                >
                  <label className={`mb-0`}>
                    <span>
                      {permissionObj[data.transactions_data[0].state]}{" "}
                    </span>{" "}
                    {/* <span>
                      <span className='material-icons-round icon-fs-14 valign-middle'>
                        open_in_new
                      </span>
                    </span> */}
                  </label>
                </NavLink>
                {data.transactions_data.length > 1 && (
                  <span
                    id={`tooltip-status-${data.id}`}
                    style={{
                      background: "#046CD9",
                      borderRadius: "50%",
                      fontSize: "10px",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#ffffff",
                      cursor: "pointer",
                    }}
                    data-for={`tooltip-status-${data.id}`}
                    data-tip={`tooltip-status-${data.id}`}
                    data-iscapture='true'
                  >
                    {data.transactions_data.length - 1}+
                    <ReactTooltip
                      id={`tooltip-status-${data.id}`}
                      place='right'
                      effect='solid'
                      className='custom-tooltip-status'
                      multiline={true}
                      getContent={() => {
                        return (
                          <div className='main-div'>
                            {data.transactions_data.map((item) => {
                              return (
                                <div className='map-div'>
                                  <span>{item.company_data.name}</span>
                                  <span>{tooltipLegend[item.state]}</span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    />
                  </span>
                )}
              </div>
            ),
            uploadedBy:
              data.created_by_user.first_name +
              " " +
              data.created_by_user.last_name,
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: clientData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD:
        this.downloadFile(data.s3_url);
        break;
      default:
        break;
    }
  };

  upload = () => {
    this.setState({
      open: true,
    });
  };

  downloadFile = (url) => {
    const newWindow = window.open(url, "_self", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.state.companyId !== "") {
      queryParams += "&companyId=" + this.state.companyId;
    }
    if (this.state.segmentId !== "") {
      queryParams += "&segmentId=" + this.state.segmentId;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE,
        {}
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  handleCompanyChange = (e) => {
    let companies = [...this.state.companies];
    companies.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.setState(
      {
        companies: companies,
        companyId: e.id,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleUploadCompanyChange = (e) => {
    let companies = [...this.state.uploadCompanies];
    companies.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.setState(
      {
        uploadCompanies: companies,
        backendError: false,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  closeDocumentModal = () => {
    let companies = [...this.state.uploadCompanies];
    companies.forEach((c) => {
      c.is_selected = false;
    });
    this.setState({
      backendError: false,
      documentModal: false,
      doc: {},
      uploadCompanies: companies,
      formErrors: {},
      isFormSubmitted: false,
    });
  };

  onChangeHandlerFile = (name, content) => {
    this.setState(
      {
        backendError: false,
        doc: { name, content },
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      isFormSubmitted: true,
    });

    if (!this.isFormValid()) {
      let companyId = this.state.uploadCompanies.find(
        (f) => f.is_selected === true
      );
      let base64_data = this.state.doc.content.split(";base64,")[1];
      let body = {
        name: this.state.doc.name,
        type: "metrics_upload",
        base64_data: base64_data,
      };
      await FileUploadServices.uploadCompanyMetrics(companyId.id, body)
        .then((res) => {
          Toast(constants.FILE_UPLOAD.CREATE.SUCCESS, "success");
          let companies = [...this.state.uploadCompanies];
          const uploadCompanies = companies.map((c) => {
            c.is_selected = false;
            return c;
          });
          this.setState({
            documentModal: false,
            doc: {},
            uploadCompanies,
            formErrors: {},
            isFormSubmitted: false,
          });
        })
        .catch((error) => {
          this.setState({
            backendError: true,
            backendErrorContent:
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  isFormValid = () => {
    let formError = false;
    let companyError = false;
    let docError = false;
    let companyId = this.state.uploadCompanies.find(
      (f) => f.is_selected === true
    );

    if (!companyId) {
      companyError = true;
      formError = true;
    }
    if (!this.state.doc || (this.state.doc && !this.state.doc.content)) {
      docError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        companyError: companyError,
        docError: docError,
      },
      apiError: null,
    });
    return formError;
  };

  handleDownload = () => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_SHAREPOINT_STANDARD_TEMPLATE_LINK;
    document.body.appendChild(link);
    link.click();
  };

  handleModelClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    let companies = this.state.companies ? this.state.companies : [];
    return (
      <>
        {/* <div className='col-lg-12 col-xl-9'> */}
        <div className='w-100-search border-search search-box-shadow-2 d-flex com_seg custom-padding'>
          <Search
            handleSearch={this.handleSearch}
            handleInput={this.handleInput}
            value={this.state.name}
            placeHolder={"Filter Name"}
          />
          <button
            className={
              "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center my-2 my-sm-0"
            }
            type='button'
            onClick={this.upload}
          >
            <span className='material-icons-round icon-fs-16 mr-1'>upload</span>
            {" " + constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ADD_BUTTON_TITLE}
          </button>
        </div>

        <div className='custom-table-block custom-table-block-2 first-col-fixed-block th-nowrap sort-table-block center-th-align'>
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>
        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <DownloadModal
          open={this.state.open}
          handleModelClose={this.handleModelClose}
          upload={true}
          fetchUploadList={this.getTableData}
        />

        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListMetricV2Uploads);
