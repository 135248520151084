export function parseCssToObj(cssText) {
  const cssObj = {};
  const rules = cssText.split(";");

  rules.forEach((rule) => {
    const [property, value] = rule.split(":");
    if (property && value) {
      cssObj[property.trim()] = value.trim();
    }
  });

  return cssObj;
}
