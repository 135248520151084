import { Axios } from '../lib/axios';

export const EmailTemplateServices = {

    getEmailTemplate,
    getEmailTemplateById,
    updateEmailTemplate,
}

function getEmailTemplate(url) {
    return Axios.get(url);
}

function getEmailTemplateById(templateKey) {
    return Axios.get(`emailTemplate?templateKey=${templateKey}`);
}

function updateEmailTemplate(templateKey, body) {
    return Axios.put(`emailTemplate?templateKey=${templateKey}`, body);
}