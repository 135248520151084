import React, { useState } from "react";
import Select, { components } from "react-select";

const CommonCheckBoxSelectComponent = ({
  options,
  title,
  setSelected,
  width,
  selected,
}) => {
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#EBF3FF";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
      zIndex: "9",
    };

    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input className='mr-2' type='checkbox' checked={isSelected} readOnly />
        <label className='pt-2'>{children}</label>
      </components.Option>
    );
  };

  const multiStyles = {
    control: (styles) => ({
      ...styles,
      minWidth: width,
      maxWidth: width,
      marginBottom: "6px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#EBF3FF",
        color: "#2E6AD2",
      };
    },
  };

  const handleFilterDataByQuarter = (e) => {
    setSelected(e);
  };

  return (
    <Select
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleFilterDataByQuarter}
      styles={multiStyles}
      className='custom-select-checkbox-dropdown'
      value={selected}
      // label
      placeholder={`Select ${title}`}
      components={{
        Option: InputOption,
      }}
    />
  );
};

export default CommonCheckBoxSelectComponent;
