import { Axios } from '../lib/axios'
export const CustomerStudyServices = {
  getTotalCustomerStudies,
  getCustomerStudies,
  getCustomerStudyDetails,
  getCustomerStudyContent,
  saveCustomerStudyContent,
  addAuthor,
  removeAuthor,
  addCustomerStudy,
  editCustomerStudy,
  toggleCustomerStudy,
  removeCustomerStudy,
  getCategories,
  getCustomerStudyTemplates,
  saveCustomerStudyTransactionContent,
  getManageContentTransactionContent
}
function getTotalCustomerStudies(url) {
  return Axios.get(url)
}
function getCustomerStudies(url) {
  return Axios.get(url)
}
function getCustomerStudyDetails(id) {
  return Axios.get(`customer-study/${id}`)
}
function getCustomerStudyContent(id) {
  return Axios.get(`customer-study/${id}/content`)
}
function saveCustomerStudyContent(customerStudyId, content, action) {
  return Axios.post(`customer-study/${customerStudyId}/content/${action}`, content)
}
function addAuthor(userId, body) {
  return Axios.post(`author/${userId}`, body)
}
function removeAuthor(customerStudyId, id) {
  return Axios.delete(`author/${id}`)
}
function addCustomerStudy(body) {
  return Axios.post(`customer-study`, body)
}
function editCustomerStudy(customerStudyId, body) {
  return Axios.patch(`customer-study/${customerStudyId}`, body)
}
function toggleCustomerStudy(customerStudyId, body) {
  return Axios.patch(`customer-study/${customerStudyId}/status/${body.is_active}`)
}
function removeCustomerStudy(customerStudyId) {
  return Axios.delete(`customer-study/${customerStudyId}`)
}
function getCategories(customerStudyId) {
  return Axios.get(`categories?type=customer_st`)
}
function getCustomerStudyTemplates() {
  return Axios.get('templates')
}
function saveCustomerStudyTransactionContent(transaction_id, content, action = '') {
  return Axios.post(`transaction/${transaction_id}/type/customer_study/state/${action}`, content)
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/customer_study`)
}
