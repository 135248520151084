import React, { useEffect, useState } from "react";
import { Collapser, Panel, Trigger } from "react-collapser";
import "react-collapser/dist/default-styles.css";
import { FaqServices } from "../Services/FaqServices";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import Notification from "../Components/Notification";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";

function FaqsDetail(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    apiError: "",
    answerError: false,
    questionError: false,
    isFormSubmitted: false,
  });
  const [dailogState, setDailogState] = useState({
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
  });

  const [showmore, setshowmore] = useState({
    showmore: false,
  });

  const [faqFormValues, setFaqFormValues] = useState({
    category_id: "",
    question: "",
    answer: "",
    question_id: "",
    isEdit: false,
  });

  useEffect(
    () => {
      if (formState.isFormSubmitted) {
        // to clear error messages while entering.
        isFormValid();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [faqFormValues, formState.isFormSubmitted]
  );

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.FAQS.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  const toggleModal = (e, bool) => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(!isModalOpen); // toggle the open state
    setFormState({
      apiError: "",
      answerError: false,
      questionError: false,
      isFormSubmitted: false,
    });
    if (bool) {
      setFaqFormValues({
        category_id: props.selectedCategory?.id,
        question: "",
        answer: "",
        isEdit: false,
      });
    }
  };

  const handleSubmit = (e) => {
    if (!isFormValid()) {
      if (faqFormValues.isEdit) {
        let body = {
          question: faqFormValues.question.trim(),
          answer: faqFormValues.answer.trim(),
        };
        let id = faqFormValues.question_id;
        FaqServices.updateFAQ(id, body)
          .then((res) => {
            toggleModal();
            props.getFaqs();
            Toast(
              constants.APPLICATION_ROUTE.FAQS.SUCCESS.UPDATE_FAQ,
              "success"
            );
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
      } else {
        let body = {
          category_id: faqFormValues.category_id,
          question: faqFormValues.question.trim(),
          answer: faqFormValues.answer.trim(),
        };
        FaqServices.addFaq(body)
          .then((res) => {
            Toast(constants.APPLICATION_ROUTE.FAQS.SUCCESS.ADD_FAQ, "success");
            toggleModal();
            props.getFaqs();
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
      }
    }
  };

  const isFormValid = () => {
    let formError = false;
    let questionError = false;
    let categoryError = false;
    let answerError = false;
    let apiError = "";
    if (!faqFormValues.category_id) {
      categoryError = true;
      formError = true;
    }
    if (!faqFormValues.question) {
      questionError = true;
      formError = true;
    }
    if (!faqFormValues.answer) {
      answerError = true;
      formError = true;
    }

    // check for duplicate questions
    if (faqFormValues.question) {
      let isDuplicate = props.selectedCategory.faqs?.find((faq) => {
        let existingFAQ_Question = faq.question.trim().toLowerCase();
        let newFAQ_Question = faqFormValues.question.trim().toLowerCase();
        return (
          existingFAQ_Question === newFAQ_Question &&
          faq.id !== faqFormValues.question_id
        );
      });
      if (isDuplicate) {
        apiError = constants.APPLICATION_ROUTE.FAQS.MESSAGES.DUPLICATE.FAQ;
        formError = true;
      }
    }

    setFormState({
      answerError,
      questionError,
      categoryError,
      formError,
      isFormSubmitted: true,
      apiError,
    });
    return formError;
  };

  const handleInputChange = (e) => {
    e.stopPropagation();
    setFaqFormValues({
      ...faqFormValues,
      [e.target.id]: e.target.value,
    });
  };

  const toggle_FAQ_Active_State = (id, state) => {
    FaqServices.toggleState(id, { is_active: state })
      .then((res) => {
        Toast(
          state
            ? constants.APPLICATION_ROUTE.FAQS.SUCCESS.ACTIVATED_FAQ
            : constants.APPLICATION_ROUTE.FAQS.SUCCESS.INACTIVATED_FAQ,
          "success"
        );
        props.getFaqs();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const remove_FAQ_By_Id = (id) => {
    FaqServices.deleteFAQ(id)
      .then((res) => {
        Toast(constants.APPLICATION_ROUTE.FAQS.SUCCESS.DELETE_FAQ, "success");
        props.getFaqs();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const dailogModalAccept = (config) => {
    debugger;
    setDailogState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    switch (config.type) {
      case "delete_FAQ":
        remove_FAQ_By_Id(config.id);
        break;
      case "de-activate_FAQ":
        toggle_FAQ_Active_State(config.id, 0);
        break;
      case "activate_FAQ":
        toggle_FAQ_Active_State(config.id, 1);
        break;
      default:
        break;
    }
  };

  const dailogModalDecline = () => {
    setDailogState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  const openDeleteDailog = (e, id) => {
    debugger;
    e.stopPropagation();

    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete FAQ</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.APPLICATION_ROUTE.FAQS.ACTIONS.DELETE_FAQ_MESSAGE}
          </h2>
        </div>
      </>
    );
    setDailogState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "delete_FAQ", id: id },
      dailogModalStyleType: "danger-modal",
    });
  };

  const openTogglecategoryActiveStateHandler = (e, selectedCategory) => {
    e.stopPropagation();
    let body = {
      is_active: !props.selectedCategory.is_active,
    };
    let id = selectedCategory.id;
    FaqServices.updateFAQCategoryStatus(id, body)
      .then((res) => {
        Toast(constants.APPLICATION_ROUTE.FAQS.SUCCESS.UPDATE_FAQ, "success");
        let updatedselectedCategory = props.selectedCategory;
        updatedselectedCategory.is_active =
          updatedselectedCategory.is_active === 1 ? 0 : 1;
        let count = 0;
        props.setCategories(
          props.categories.map((category) => {
            if (category.id === updatedselectedCategory.id) {
              category = updatedselectedCategory;
            }
            count = category.is_active === 1 ? count + 1 : count - 1;
            return category;
          })
        );

        props.setSelectedCategory({
          categorystate:
            props.categories.length === Math.abs(count)
              ? "all"
              : props.categorystate,
          selectedCategory: updatedselectedCategory,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const openToggleActiveStateHandler = (e, FAQ) => {
    e.stopPropagation();

    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {FAQ.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {FAQ.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.APPLICATION_ROUTE.FAQS.ACTIONS.DE_ACTIVATE_FAQ_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.APPLICATION_ROUTE.FAQS.ACTIONS.ACTIVATE_FAQ_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    setDailogState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: FAQ.is_active === 1 ? "de-activate_FAQ" : "activate_FAQ",
        id: FAQ.id,
      },
      dailogModalStyleType:
        FAQ.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };

  const editHandler = (e, FAQ) => {
    e.stopPropagation();
    setFaqFormValues({
      category_id: props.selectedCategory?.id,
      question: FAQ.question,
      answer: FAQ.answer,
      question_id: FAQ.id,
      isEdit: true,
    });
    toggleModal();
  };
  const changeshow = () => {
    setshowmore({
      showmore: !showmore.showmore,
    });
  };

  return (
    <aside className="dash_board_content inner-sidebar-right-content faq_content">
      <div className="d-flex align-items-center justify-content-center faq-page-title-sec">
        <span className="material-icons-round chat-icon text-black">
          question_answer
        </span>
        <h1 className="mb-0 text-black2 font-weight-bold">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="faq-right-content">
        <div className="card faqs-card">
          <div className="card-body">
            <div className="d-flex align-items-start">
              <div className="w-75">
                <h2 className="font-weight-bold text-black2 font-family-source-sans">
                  {props.selectedCategory
                    ? props.selectedCategory.category_name
                    : ""}{" "}
                  FAQs
                </h2>
                <div className="see-more">
                  <p className="text-blue-clr mb-0">
                    {props.selectedCategory.description
                      ? !showmore.showmore &&
                        props.selectedCategory.description.length > 270
                        ? props.selectedCategory.description
                            .substring(0, 180)
                            .concat("...")
                        : props.selectedCategory.description
                      : ""}
                  </p>
                  {props.selectedCategory.description &&
                  props.selectedCategory.description.length > 270 ? (
                    <button
                      className="show-more-less-btn btn d-flex align-items-center"
                      onClick={changeshow}
                    >
                      {!showmore.showmore ? (
                        <>
                          show more
                          <span className="material-icons arrow-down ml-1 icon-fs-20">
                            keyboard_double_arrow_down
                          </span>
                        </>
                      ) : (
                        <>
                          show less
                          <span className="material-icons arrow-down ml-1 icon-fs-20">
                            keyboard_double_arrow_up
                          </span>
                        </>
                      )}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center w-25 justify-content-end title-right-block">
                <div className="custom-control custom-switch success-toggle custom-switch-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="topcustomSwitch"
                    checked={props.selectedCategory.is_active}
                    onChange={(e) =>
                      openTogglecategoryActiveStateHandler(
                        e,
                        props.selectedCategory
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="topcustomSwitch"
                  ></label>
                </div>
                <div className="faqs ml-3">
                  <AclAction
                    tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                    type="permissions"
                    entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
                    action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.CREATE}
                  >
                    <div className="d-flex justify-content-end">
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center"
                        }
                        type="button"
                        onClick={(e) => toggleModal(e, true)}
                        disabled={props.selectedCategory.id ? false : true}
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          add
                        </span>
                        {" " +
                          constants.APPLICATION_ROUTE.FAQS.LIST
                            .ADD_BUTTON_TITLE}
                      </button>
                    </div>
                  </AclAction>
                </div>
              </div>
            </div>

            <div className="faqs-collapse-wrapper">
              {props.selectedCategory.faqs?.map((faq) => (
                <div className="custom-collapser custom-collapser2 custom-collapser2-2">
                  <Collapser key={faq.id}>
                    <Trigger>
                      <div className={"d-flex align-items-center"}>
                        <h5 className="font-weight-bold mb-0 title">
                          {faq.question}
                        </h5>
                        <div
                          className={
                            "d-flex align-items-center faq_action_icons ml-auto mr-4"
                          }
                        >
                          <div className="mr-1">
                            <AclAction
                              type={constants.ACL.PERMISSION_KEY}
                              entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
                              action={
                                constants.ACL.TBRI_USER_RESOURCE.ACTIONS.STATUS
                              }
                            >
                              <div className="custom-control custom-switch success-toggle">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch${faq.id}`}
                                  checked={faq.is_active === 1}
                                  onChange={(e) =>
                                    openToggleActiveStateHandler(e, faq)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  style={{ cursor: "pointer" }}
                                  htmlFor={`customSwitch${faq.id}`}
                                ></label>
                              </div>
                            </AclAction>
                          </div>
                          <button
                            className={
                              "btn btn-md d-inline-flex align-items-center px-2"
                            }
                            type="button"
                            onClick={(e) => editHandler(e, faq)}
                          >
                            <span className="material-icons-outlined text-blue-clr icon-fs-18">
                              edit
                            </span>
                          </button>
                          <button
                            className={
                              "btn btn-md d-inline-flex align-items-center px-2"
                            }
                            type="button"
                            onClick={(e) => openDeleteDailog(e, faq.id)}
                          >
                            <span className="material-icons-outlined text-red icon-fs-18">
                              delete
                            </span>
                          </button>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="collapse-icon-wrapper">
                            <span className="material-icons-round add icon">
                              add
                            </span>
                            <span className="material-icons-round remove icon">
                              remove
                            </span>
                          </div>
                        </div>
                      </div>
                    </Trigger>
                    <Panel>{faq.answer}</Panel>
                  </Collapser>
                </div>
              ))}
              {!props.selectedCategory.faqs ? <h4>No FAQs</h4> : ""}
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Modal */}
      <Modal
        isOpen={isModalOpen}
        className={"modal-md modal-w-header custom-modal primary-modal"}
      >
        <ModalHeader toggle={toggleModal}>
          {faqFormValues.isEdit
            ? constants.APPLICATION_ROUTE.FAQS.HEADER.EDIT_TITLE
            : constants.APPLICATION_ROUTE.FAQS.HEADER.ADD_TITLE}
        </ModalHeader>
        <ModalBody>
          <form className={"form-wrapper"}>
            <div className="row">
              {formState.apiError && (
                <Notification color={"danger"} message={formState.apiError} />
              )}
              <div className="col-sm-12 form-group position-relative">
                <FormGroup>
                  <Label htmlFor="category">
                    Topic <span className={"mandatory"}>* </span>
                  </Label>
                  <Input
                    disabled={true}
                    className={`${formState.categoryError ? "is-invalid" : ""}`}
                    type="text"
                    placeholder=""
                    id="category"
                    name="category"
                    value={props.selectedCategory.category_name}
                  />
                  {formState.categoryError && (
                    <div className="invalid-tooltip">
                      Topic is a required field
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="question">
                    Question <span className={"mandatory"}>* </span>
                  </Label>
                  <Input
                    className={`${formState.questionError ? "is-invalid" : ""}`}
                    type="text"
                    placeholder=""
                    id="question"
                    name="question"
                    defaultValue={faqFormValues.question}
                    onChange={handleInputChange}
                  />
                  {formState.questionError && (
                    <div className="invalid-tooltip">
                      Question is a required field
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="answer">
                    Answer <span className={"mandatory"}>* </span>
                  </Label>
                  <textarea
                    className={`${
                      formState.answerError
                        ? "form-control is-invalid"
                        : "form-control"
                    }`}
                    style={{ width: "100%" }}
                    rows={4}
                    id="answer"
                    name="answer"
                    defaultValue={faqFormValues.answer}
                    onChange={handleInputChange}
                  />
                  {formState.answerError && (
                    <div className="invalid-tooltip">
                      Answer is a required field
                    </div>
                  )}
                </FormGroup>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={"btn-outline-primary modal-btn"}
            onClick={toggleModal}
          >
            Cancel
          </Button>{" "}
          <Button
            className={"modal-right-btn modal-btn"}
            onClick={handleSubmit}
          >
            {faqFormValues.isEdit ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dailog Modals for User Confirmation */}
      <DailogNew
        isOpen={dailogState.dailogModal}
        accept={dailogModalAccept}
        decline={dailogModalDecline}
        header={dailogState.dailogModalHeader}
        content={dailogState.dailogModalContent}
        config={dailogState.dailogModalConfig}
        modalStyleType={dailogState.dailogModalStyleType}
      />
    </aside>
  );
}

export default FaqsDetail;
