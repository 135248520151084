import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { MasterServices } from "../Services/Master";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import AclAction from "../Components/ACL/AclAction";
import Slugify from "../Utils/Slugify";
import ManageMasterData from "../Containers/ManageMasterData";
import Toast from "../Utils/Toast";
import DailogNew from "../Components/DailogNew";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "name",
    title: "name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    rowClass: "",
    visibility: true,
  },
];
const showActions = false;
class ListRegions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.SORT_COLUMN_NAME,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: this.props.pageNo,
      pageSize: this.props.pageSize,
      name: "",
      documentModal: false,
      apiError: null,
      formErrors: {},
      isFormSubmitted: false,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      currentPageDataCount: 0,
      disableStatus: false,
    };
  }
  componentDidMount() {
    this.getTableData();
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  toggleGeoSubCategoryConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === true || data.is_active === 1
            ? "De-Activation"
            : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === true || data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.MASTER_DATA_GEO_SUB_CATEGORY
                  .INACTIVATE_GEO_SUB_CATEGORY_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.MASTER_DATA_GEO_SUB_CATEGORY
                  .ACTIVATE_GEO_SUB_CATEGORY_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          data.is_active === true || data.is_active === 1
            ? "de-activate_geo_sub_category"
            : "activate_geo_sub_category",
        id: data.geosubcategoryid,
      },
      dailogModalStyleType:
        data.is_active === true || data.is_active === 1
          ? "danger-modal"
          : "success-modal",
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.GEO_SUBCATEGORY_RESOURCE.TITLE}
          action={constants.ACL.GEO_SUBCATEGORY_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY.LIST
                .REMOVE_GEO_SUB_CATEGORY_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY.LIST
                  .REMOVE_GEO_SUB_CATEGORY,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY.LIST
                .REMOVE_GEO_SUB_CATEGORY_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-red icon-fs-20">
              delete
            </span>
          </button>
        </AclAction>
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
    MasterServices.getTotalGeoSubCategories(
      "geosubcategories?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let geoSubCategoryData = res.data.data;
        this.setState({
          currentPageDataCount: geoSubCategoryData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        geoSubCategoryData = geoSubCategoryData.map((data) => {
          return {
            id: data.id,
            name: data.name ? data.name : "-",
            created_by: data.created_by_user
              ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
              : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            is_active:
              data.is_active === false ||
              data.is_active === true ||
              data.is_active === 0 ||
              data.is_active === 1 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.GEO_SUBCATEGORY_RESOURCE.TITLE}
                  action={constants.ACL.GEO_SUBCATEGORY_RESOURCE.ACTIONS.STATUS}
                >
                  <div className="custom-control custom-switch success-toggle">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customSwitch${data.geosubcategoryid}`}
                      checked={data.is_active === true || data.is_active === 1}
                      onChange={(e) => {
                        e.stopPropagation();
                        this.rowAction(
                          constants.APPLICATION_ROUTE
                            .MASTER_DATA_GEO_SUB_CATEGORY.LIST.STATUS,
                          data,
                          e
                        );
                      }}
                      disabled={this.state.disableStatus}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitch${data.geosubcategoryid}`}
                    ></label>
                  </div>
                </AclAction>
              ) : null,
            actions: this.getActions(data),
          };
        });
        this.setState({
          rowData: geoSubCategoryData,
          count: count,
          documentModal: false,
        });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0, documentModal: false });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  addGeoSubCategory = () => {
    this.setState({
      documentModal: true,
    });
  };

  closeDocumentModal = () => {
    this.setState({
      documentModal: false,
    });
  };

  removeGeoSubCategoryConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Remove Geo Sub-category
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MASTER_DATA_GEO_SUB_CATEGORY
                .REMOVE_GEO_SUB_CATEGORY_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "remove_geo_sub_category",
        id: data.geosubcategoryid,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeGeoSubCategory = (id) => {
    MasterServices.removeGeoSubCategory(id)
      .then((res) => {
        Toast(constants.MASTER_DATA_GEO_SUB_CATEGORY.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggleGeoSubCategory = (id, isActive) => {
    MasterServices.toggleGeoSubCategory(id, { is_active: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.MASTER_DATA_GEO_SUB_CATEGORY.ACTIVATE.SUCCESS
            : constants.MASTER_DATA_GEO_SUB_CATEGORY.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_geo_sub_category":
        this.removeGeoSubCategory(config.id);
        break;
      case "de-activate_geo_sub_category":
        this.toggleGeoSubCategory(config.id, 0);
        break;
      case "activate_geo_sub_category":
        this.toggleGeoSubCategory(config.id, 1);
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY.LIST
        .REMOVE_GEO_SUB_CATEGORY:
        this.removeGeoSubCategoryConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY.LIST.STATUS:
        this.toggleGeoSubCategoryConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  handleSubmit = (data) => {
    MasterServices.addGeoSubCategory(data)
      .then((res) => {
        Toast(constants.MASTER_DATA_GEO_SUB_CATEGORY.CREATE.SUCCESS, "success");
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  render() {
    return (
      <>
        <div className="d-flex">
          <div className="min-width-266 border-search search-box-shadow-2 mb-3">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Filter Geo Sub-category"}
            />
          </div>
          <div className="d-flex justify-content-end align-self-end flex-wrap ml-auto">
            <AclAction
              type="permissions"
              entity={constants.ACL.GEO_SUBCATEGORY_RESOURCE.TITLE}
              action={constants.ACL.GEO_SUBCATEGORY_RESOURCE.ACTIONS.CREATE}
            >
              <aside tbrentitytype={constants.ACL.ENTITY_TYPE.DIV} className="">
                <button
                  className={
                    "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                  }
                  type="button"
                  onClick={this.addGeoSubCategory}
                >
                  <span className="material-icons-round icon-fs-16 mr-1">
                    add
                  </span>
                  {" " +
                    constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY
                      .LIST.ADD_BUTTON_TITLE}
                </button>
              </aside>
            </AclAction>
          </div>
        </div>

        <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>
        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.MASTER_DATA_GEO_SUB_CATEGORY.LIST
                    .REMOVE_GEO_SUB_CATEGORY_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <ManageMasterData
          documentModal={this.state.documentModal}
          closeDocumentModal={this.closeDocumentModal}
          masterName={this.state.name}
          addMasterData={this.handleSubmit}
          title={"Geo Sub-category"}
        />
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps, null)(ListRegions);
