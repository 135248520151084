import React, { Component } from "react";
import TransactionDetailsCategories from "../Containers/TransactionDetailsCategories";
import TransactionDetailsContent from "../Containers/TransactionDetailsContent";
import { CompanyServices } from "../Services/Company";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import { ManageContentServices } from "../Services/ManageContent";
import { connect } from "react-redux";
import {
  categories,
  companyDetails,
  companyContent,
  companies,
  templates,
  transactionDetails,
} from "../Store/Actions/CompanyDetails";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import { last, isEqual } from "lodash";
import DailogNew from "../Components/DailogNew";
import { Prompt } from "react-router-dom";
import permissionCheck from "../lib/permissionCheck";
import decodeJWT from "../lib/decodeJWT";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import { QuoteImageServices } from "../Services/QuoteImage";
import QuoteImageDialog from "../Components/QuoteImageDialog";

class TransactionDetails extends Component {
  state = {
    masterContent: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    changesMade: false,
    transaction_id: this.props.match.params.id,
    transaction_comment: null,
    innerSidebarOpen: true,
    selectedSlide: "",
    notification: false,
    quoteImageDailogModal: false,
    mapping_id: "",
    mapping_type: null,
    quoteId: null,
    quoteContent: null,
    quoteImageUrl: null,
    quoteImageName: null,
    image_delete: false,
    base64_data: null,
    currentState: null,
    companyId: null,
    labelquoteContent: null,
  };

  toggleInnerSidebar = () => {
    this.setState({ innerSidebarOpen: !this.state.innerSidebarOpen });
  };

  turnOffNotification = () => {
    this.setState({
      notification: !this.state.notification,
    });
  };

  detectChanges = (companyContent) => {
    let c = 0,
      e = 0,
      d = 0;
    companyContent.forEach((ea) => {
      if (ea.id && ea.id !== null) {
        if (ea.is_deleted === true) d = d + 1;
        else {
          if (this.findUpdates(ea.uid)) e = e + 1;
        }
      } else if (ea.is_deleted !== true) c = c + 1;
    });
    window.removeEventListener("beforeunload", this.onUnload);
    if (c > 0 || d > 0 || e > 0)
      window.addEventListener("beforeunload", this.onUnload);
    this.setState({
      changesMade: c > 0 || d > 0 || e > 0 ? true : false,
    });
  };

  onUnload = (ev) => {
    ev.preventDefault();
    ev.returnValue = "";
  };

  componentDidMount() {
    CompanyServices.getTemplates()
      .then((res) => {
        let response = res && res.data;
        this.props.templates(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    CompanyServices.getCategories()
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((res) => {
          res.is_selected = true;
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              res_sub.is_selected = true;
            });
          }
        });
        this.props.categories(response);
        this.getCompanySegmentContent();
      })
      .catch((error) => {
        this.props.categories([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  //API For getting Image and Quote
  getImageAndQuote = (mapping_id, transaction_id) => {
    QuoteImageServices.showImageandQuote(mapping_id, transaction_id)
      .then((res) => {
        const { content, image, id } = res.data?.data ?? "";
        let lastPart = "";
        if (image) {
          const urlParts = image.split("/");
          lastPart = last(urlParts);
        }
        this.setState({
          quoteId: id,
          quoteContent: content ?? "",
          labelquoteContent: content ?? "",
          quoteImageUrl: image ?? null,
          quoteImageName: lastPart,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getCompanySegmentContent = () => {
    ManageContentServices.getManageContentTransactionContent(
      this.state.transaction_id
    )
      .then((res) => {
        let totalResponse =
          res && res.data && res.data.data ? res.data.data : {};
        totalResponse = { ...totalResponse };
        let content_role_state_histories =
          res && res.data && res.data.data && res.data.data.role_state_histories
            ? res.data.data.role_state_histories
            : [];
        let transaction_comments = [];
        content_role_state_histories.forEach((res) => {
          transaction_comments.push(...res.comments);
        });
        let transactionDetails = {
          comment: null,
          openCommentsFlag: false,
          id: totalResponse.id,
          comments: transaction_comments,
          next_states: totalResponse.next_states
            ? totalResponse.next_states
            : [],
          role_state: totalResponse.role_state ? totalResponse.role_state : {},
          company_segment: totalResponse.company_segment
            ? totalResponse.company_segment
            : {},
        };
        let response =
          res && res.data && res.data.data && res.data.data.contents
            ? res.data.data.contents
            : [];
        response.forEach((res) => {
          res.uid = Math.random();
          res.is_deleted = res.is_deleted
            ? res.is_deleted === 1
              ? true
              : false
            : false;
          res.comment = null;
          res.openCommentsFlag = false;
        });
        this.props.companyContent(response);
        this.props.transactionDetails(transactionDetails);
        this.unCheckOnNoContent(this.props.company_details.companyContent);
        const currentState = totalResponse.role_state?.state ?? null;
        if (currentState && !currentState?.short_name?.includes("draft")) {
          this.getImageAndQuote(
            totalResponse.type === "company"
              ? totalResponse.company_segment?.company_id
              : totalResponse.mapping_id ?? null,
            totalResponse.id ?? null
          );
        }
        this.setState(
          {
            masterContent: JSON.parse(JSON.stringify(response)),
            changesMade: false,
            transaction_comment: null,
            mapping_id: totalResponse.mapping_id
              ? totalResponse.mapping_id
              : null,
            mapping_type: totalResponse.type,
            currentState: totalResponse.role_state?.state ?? null,
            companyId: totalResponse.company_segment?.company_id ?? null,
          },
          () => {
            window.removeEventListener("beforeunload", this.onUnload);
          }
        );
      })
      .catch((error) => {
        this.props.companyContent([]);
        this.props.categories([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  unCheckOnNoContent = (content) => {
    let original_categories = [...this.props.company_details.categories];
    original_categories.forEach((res) => {
      if (res.subcategories) {
        res.subcategories.forEach((res_sub) => {
          let content_found = content.filter(
            (row) => row.subcategory_id === res_sub.id
          ).length;
          if (content_found > 0) {
            res_sub.is_selected = true;
          } else {
            res_sub.is_selected = false;
          }
        });
        let total = res.subcategories.length;
        let total_selected = res.subcategories.filter(
          (row) => row.is_selected === true
        ).length;
        if (total_selected === total && total > 0) {
          res.is_selected = true;
        } else {
          res.is_selected = false;
        }
      }
    });
    this.props.categories(original_categories);
  };

  toggleCheckboxParent = (e, type, category_id, sub_category_id) => {
    let checkbox_selection = e.target.checked;
    if (checkbox_selection === true)
      this.toggleCheckbox(
        checkbox_selection,
        type,
        category_id,
        sub_category_id
      );
    else {
      this.confirmDailog({
        checkbox_selection,
        type,
        category_id,
        sub_category_id,
      });
    }
  };

  toggleCheckbox = (checkbox_selection, type, category_id, sub_category_id) => {
    let original_categories = [...this.props.company_details.categories];
    if (type === "category") {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          res.is_selected = checkbox_selection;
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              res_sub.is_selected = checkbox_selection;
            });
          }
          this.handleAddRemoveTemplateCategory(category_id, checkbox_selection);
        }
      });
    } else if (type === "sub_category") {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.id === sub_category_id) {
                res_sub.is_selected = checkbox_selection;
                this.handleAddRemoveTemplateSubCategory(
                  res_sub.id,
                  checkbox_selection
                );
              }
            });
            let total = res.subcategories.length;
            let total_selected = res.subcategories.filter(
              (row) => row.is_selected === true
            ).length;
            if (total_selected === total && total > 0) {
              res.is_selected = true;
            } else {
              res.is_selected = false;
            }
          }
        }
      });
    }
    this.props.categories(original_categories);
  };

  getEmptyContent = (sub_category_id) => {
    let template_found = this.props.company_details.templates.filter(
      (row) => row.subcategory_id === sub_category_id
    );
    let emptyContent = {};
    if (template_found && template_found.length > 0) {
      emptyContent.id = null;
      emptyContent.uid = Math.random();
      emptyContent.data = "{}";
      emptyContent.subcategory_title = "";
      emptyContent.published_date = new Date();
      emptyContent.template_id = template_found[0].id;
      emptyContent.subcategory_id = sub_category_id;
      emptyContent.template = template_found[0];
      emptyContent.is_deleted = false;
      let data = {};
      let extractParams = emptyContent.template.content.match(/[^{}]+(?=})/g);
      if (extractParams && extractParams.length > 0) {
        extractParams.forEach((param) => {
          data[param] = { type: "empty", value: "" };
        });
      }
      emptyContent.data = JSON.stringify(data);
    }
    return emptyContent;
  };

  addEmptyContent = (sub_category_id) => {
    let original_content = [...this.props.company_details.companyContent];
    let getEmptyContent = this.getEmptyContent(sub_category_id);
    original_content.push(getEmptyContent);
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  handleAddRemoveTemplateCategory = (category_id, is_selected) => {
    let original_categories = [...this.props.company_details.categories];
    let checkbox_selection = is_selected;
    let original_content = [...this.props.company_details.companyContent];
    original_categories.forEach((res) => {
      if (res.id === category_id) {
        res.is_selected = checkbox_selection;
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (checkbox_selection === true) {
              let content_found =
                this.props.company_details.companyContent.filter(
                  (row) => row.subcategory_id === res_sub.id
                ).length;
              if (content_found > 0) {
              } else {
                original_content.push(this.getEmptyContent(res_sub.id));
              }
            } else {
              original_content.forEach((item) => {
                if (item.subcategory_id === res_sub.id) item.is_deleted = true;
              });
            }
          });
        }
      }
    });
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  handleAddRemoveTemplateSubCategory = (sub_category_id, is_selected) => {
    let content_found = this.props.company_details.companyContent.filter(
      (row) => row.subcategory_id === sub_category_id
    ).length;
    if (is_selected === true) {
      if (content_found > 0) {
      } else {
        this.addEmptyContent(sub_category_id);
      }
    } else {
      this.removeSubCategoryContent(sub_category_id);
    }
  };

  removeSubCategoryContent = (sub_category_id) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((item) => {
      if (item.subcategory_id === sub_category_id) item.is_deleted = true;
    });
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  removeContent = (uid) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((item) => {
      if (item.uid === uid) item.is_deleted = true;
    });
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  undoRemoveContent = (uid) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((item) => {
      if (item.uid === uid) item.is_deleted = false;
    });
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
    this.setState({ selectedSlide: "" });
  };

  dateOnChangeHandler = (key, val) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === key);
    original_content[index].published_date = val;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };
  subcateogoryOnChangeHandler = (key, val) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((row) => {
      if (row.subcategory_id === key) {
        row.subcategory_id = val.id;
      }
    });
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
    this.unCheckOnNoContent(this.props.company_details.companyContent);
  };

  onContentChangeHandler = (uid, placeholder, value, valueType) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    let jsonData = JSON.parse(original_content[index].data);
    jsonData[placeholder].type = valueType;
    if (valueType === "image" && value.startsWith("data:")) {
      let splitValue = value.split("|");
      jsonData[placeholder].value = splitValue[0] ? splitValue[0] : "";
      jsonData[placeholder].imageName = splitValue[1] ? splitValue[1] : "";
    } else {
      jsonData[placeholder].value = value;
    }
    let stringData = JSON.stringify(jsonData);
    original_content[index].data = stringData;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  onSectionTitleChangeHandler = (uid, value) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].subcategory_title = value;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  onCommentChangeHandler = (uid, comment) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].comment = comment;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  onTransactionCommentChangeHandler = (uid, comment) => {
    let original_content = { ...this.props.company_details.transactionDetails };
    original_content.comment = comment;
    this.props.transactionDetails(original_content);
  };

  toggleCommentsHandler = (uid) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].openCommentsFlag =
      !original_content[index].openCommentsFlag;
    this.props.companyContent(original_content);
  };

  toggleCommentsHandlerTransaction = (uid) => {
    let original_content = JSON.parse(
      JSON.stringify(this.props.company_details.transactionDetails)
    );
    original_content.openCommentsFlag = !original_content.openCommentsFlag;
    this.props.transactionDetails(original_content);
  };

  saveContent = (type) => {
    let original_categories = [...this.props.company_details.categories];
    let original_contents = [...this.props.company_details.companyContent];
    let unmatched_contents_count = 0;
    let unmatched_categories = original_categories.find(
      (c) => c.is_default === 1
    );
    if (unmatched_categories && unmatched_categories.subcategories) {
      unmatched_categories.subcategories.forEach((us) => {
        if (
          original_contents.find(
            (o) => o.subcategory_id === us.id && o.is_deleted === false
          )
        )
          unmatched_contents_count = unmatched_contents_count + 1;
      });
    }
    if (
      unmatched_contents_count > 0 &&
      (type === constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG ||
        type === constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG)
    ) {
      Toast("Please map or remove un-matched contents.", "error");
      return false;
    }
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Save</h3>
      </>
    );
    let dailogModalContent;
    let dailogModalStyleType;
    switch (type) {
      case constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG:
        this.setState({
          quoteImageDailogModal: true,
          dailogModalConfig: {
            type: type,
            id: null,
            transaction_id: this.state.transaction_id,
            mapping_id: this.state.mapping_id,
            mapping_type: this.state.mapping_type,
          },
          apiError: null,
        });
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.REJECT.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="remove-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.REJECT.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "danger-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH.CONFIRM_MESSAGE}
              </h2>
            </div>
            <div className="model-notification">
              <input
                type="checkbox"
                onClick={this.turnOffNotification}
                name="select"
              />
              <h3>Don't send notifications</h3>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      default:
        break;
    }
    if (type !== constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG) {
      this.setState({
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: { type: type, id: null },
        dailogModalStyleType: dailogModalStyleType,
      });
    }
  };

  resetContent = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Warning</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .RESET_CHANGES_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_content", id: null },
      dailogModalStyleType: "inactive-modal",
    });
  };

  saveContentSuccess = (action) => {
    let message = "";
    switch (action) {
      case constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG:
        message =
          constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.SUCCESS_MESSAGE;
        this.getCompanySegmentContent();
        break;
      default:
        break;
    }
    Toast(message, "success");
    this.setState(
      {
        changesMade: false,
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      },
      () => {
        window.removeEventListener("beforeunload", this.onUnload);
        if (action === constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG)
          this.props.history.push({
            pathname: formatRoute(
              constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE
            ),
          });
        else if (action !== constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.SLUG) {
          this.props.history.goBack();
        }
      }
    );
  };

  saveContent2 = async (type, toastType, isPublished) => {
    let c = [],
      e = [],
      d = [],
      content_ids = [];
    this.props.company_details.companyContent.forEach((ea) => {
      let save = {
        template_id: ea.template_id,
        category_id: ea.category_id ? ea.category_id : null,
        transaction_id: this.state.transaction_id,
        subcategory_id: ea.subcategory_id,
        data: [
          {
            content_id: ea.id,
            parent_content_id: ea.parent_content_id,
            subcategory_title: ea.subcategory_title,
            published_date: ea.published_date,
            content: JSON.parse(ea.data),
            is_deleted: ea.is_deleted ? 1 : 0,
            comment: ea.comment,
          },
        ],
      };
      if (isPublished) content_ids.push(ea.id);
      if (ea.id && ea.id !== null) {
        if (ea.is_deleted === true && ea.parent_content_id !== null) {
          e.push(ea);
        } else if (ea.is_deleted === true && ea.parent_content_id === null)
          d.push(ea.id);
        else {
          if (this.findUpdates(ea.uid)) e.push(save);
        }
      } else if (ea.is_deleted !== true) e.push(save);
    });
    await ManageContentServices.saveTransactionContent(
      this.state.transaction_id,
      {
        comment: this.props.company_details.transactionDetails.comment,
        operations: { create: c, update: e, delete: d },
        is_for_publish: isPublished,
        content_ids,
        silent_notification: this.state.notification,
      },
      type
    )
      .then(async (res) => {
        if (
          (type === constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG &&
            this.state.quoteContent) ||
          (this.state.base64_data && this.state.quoteImageName)
        ) {
          const body = {
            mapping_id: this.state.mapping_id ?? "",
            transaction_id: res.data.transaction_id ?? "",
            mapping_type: this.state.mapping_type ?? "",
            content: this.state.quoteContent ?? "",
            base64_data: this.state.base64_data ?? "",
            imageName: this.state.quoteImageName ?? "",
          };
          await QuoteImageServices.addImageandQuote(body)
            .then((res) => {
              this.setState({
                base64_data: null,
              });
            })
            .catch((error) => {
              Toast(
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
                "error"
              );
            });
        }
        this.saveContentSuccess(toastType);
        this.setState({
          notification: false,
          quoteImageDailogModal: false,
          config: {},
        });
      })
      .catch((error) => {
        this.setState({ notification: false });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  resetContent2 = () => {
    this.getCompanySegmentContent();
  };

  findUpdates = (uid) => {
    let newContent = this.props.company_details.companyContent.filter(
      (comp) => comp.uid === uid
    )[0];
    let oldContent = this.state.masterContent.filter(
      (comp) => comp.uid === uid
    )[0];
    if (
      isEqual(newContent.subcategory_title, oldContent.subcategory_title) ===
        true &&
      isEqual(newContent.data, oldContent.data) === true &&
      isEqual(newContent.published_date, oldContent.published_date) === true &&
      newContent.comment === null &&
      isEqual(newContent.subcategory_id, oldContent.subcategory_id) === true &&
      newContent.is_deleted === oldContent.is_deleted
    )
      return false;
    return true;
  };

  confirmDailog = (config) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_CONTENT_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: config,
      dailogModalStyleType: "danger-modal",
    });
  };

  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  quoteImageDailogModalStoreAccept = (config) => {
    const body = {
      mapping_id: this.state.companyId ?? "",
      transaction_id: config.transaction_id ?? "",
      mapping_type: this.state.mapping_type ?? "",
      content: this.state.quoteContent ?? "",
      base64_data: this.state.base64_data ?? "",
      imageName: this.state.quoteImageName ?? "",
    };
    QuoteImageServices.addImageandQuote(body)
      .then((res) => {
        const { content, image} = res.data.data
        this.setState({
          base64_data: null,
          labelquoteContent: content ?? "",
          quoteImageUrl: image ?? null,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.setState(
      {
        quoteImageDailogModal: false,
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  matchEvent = (config) => {
    switch (config.type) {
      case "category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "sub_category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "switch_segment":
        this.setState({ changesMade: false }, () => {
          this.switchSegment2(config.id);
        });
        break;
      case "switch_company":
        this.setState({ changesMade: false }, () => {
          this.handleCompanyChange2(config.id);
        });
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG,
          config.type
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.PUBLISH.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG,
          config.type,
          "is_for_publish"
        );
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.SLUG:
        this.saveContent2(
          constants.MANAGE_CONTENT_PERMISSIONS.UPDATE.SLUG,
          config.type
        );
        break;
      case "reset_content":
        this.resetContent2();
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  quoteImageDailogModalAccept = (config) => {
    let transaction_id = this.state.transaction_id ?? "";
    if (this.state.quoteImageUrl) {
      this.setState({
        image_delete: false,
      });
    }
    const body = {
      content: this.state.quoteContent ?? "",
      base64_data: this.state.base64_data ?? "",
      imageName: this.state.quoteImageName ?? "",
      image_delete: !this.state.base64_data ? this.state.image_delete : false,
    };
    // API For Updating Image and Quote
    QuoteImageServices.updateImageandQuote(
      this.state.quoteId,
      transaction_id,
      body
    )
      .then((res) => {
        const { content, image, id } = res.data.data;
        let lastPart = "";
        if (image) {
          const urlParts = image.split("/");
          lastPart = last(urlParts);
        }
        this.setState({
          quoteId: id,
          labelquoteContent: content ?? "",
          quoteImageUrl: image ?? null,
          quoteImageName: lastPart,
          base64_data: null,
          image_delete: false,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.setState(
      {
        quoteImageDailogModal: false,
      },
      () => {
        this.matchEvent(config);
      }
    );
  };
  quoteImageDailogModalDecline = () => {
    this.setState({
      quoteImageDailogModal: false,
      config: {},
    });
    setTimeout(() => {
      this.setState({
        quoteContent: this.state.labelquoteContent,
      });
    }, 500);
  };
  onEditClicked = () => {
    this.setState({
      quoteImageDailogModal: true,
      dailogModalConfig: {
        type: null,
        id: null,
        transaction_id: this.state.transaction_id,
      },
      apiError: null,
    });
  };
  handleQuoteContent = (e) => {
    this.setState({
      quoteContent: e.target.value,
    });
  };
  handleQuoteImage = (base64Url, imageName) => {
    let filteredBase64Url = base64Url.split(";base64,")[1];
    this.setState({
      base64_data: filteredBase64Url,
      quoteImageName: imageName,
    });
  };

  componentWillUnmount = () => {
    this.props.companies([]);
    this.props.companyDetails({});
    this.props.transactionDetails({});
    this.props.companyContent({});
    this.props.categories([]);
    this.props.templates([]);
  };

  breadcrumbs = () => {
    let nextStates =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.next_states
        ? this.props.company_details.transactionDetails.next_states
        : [];
    let companyDetails =
      this.props.company_details &&
      this.props.company_details.transactionDetails
        ? this.props.company_details.transactionDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
        )
          ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.NAME
          : constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS.NAME,
        link: formatRoute(
          nextStates.includes(constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG)
            ? constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.ROUTE
            : constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS
                .ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: `${
          companyDetails.company_segment &&
          companyDetails.company_segment.company &&
          companyDetails.company_segment.company.id &&
          companyDetails.company_segment.segment &&
          companyDetails.company_segment.segment.id
            ? `${companyDetails.company_segment.company.name} - ${companyDetails.company_segment.segment.name}`
            : ""
        }`,
        link: null,
        is_active: true,
      },
    ];
    DocumentTitle(
      `${
        companyDetails.company_segment &&
        companyDetails.company_segment.company &&
        companyDetails.company_segment.company.id &&
        companyDetails.company_segment.segment &&
        companyDetails.company_segment.segment.id
          ? `${companyDetails.company_segment.company.name} - ${companyDetails.company_segment.segment.name}`
          : ""
      }`
    );
    return breadcrumbs;
  };

  // on mouse scroll the left sidebar element will change color
  mouseScroll = (id) => {
    this.setState({ selectedSlide: id });
  };

  // method to scroll the element
  handleClickScroll = (name, id) => {
    let elementID = `${name}-${id}`;
    let element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    this.setState({ selectedSlide: id });
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let is_others_content_edit_allowed =
      tokenDetails &&
      tokenDetails.role &&
      tokenDetails.role.is_others_content_edit_allowed;
    let nextStates =
      this.props.company_details &&
      this.props.company_details.transactionDetails &&
      this.props.company_details.transactionDetails.next_states
        ? this.props.company_details.transactionDetails.next_states
        : [];
    const isEditEnabled =
      ((nextStates.includes(constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
        )) ||
        (nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
        ) &&
          permissionCheck(
            constants.MANAGE_CONTENT_PERMISSIONS.KEY,
            constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
            constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
          )) ||
        (nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG
        ) &&
          permissionCheck(
            constants.MANAGE_CONTENT_PERMISSIONS.KEY,
            constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
            constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG
          )) ||
        is_others_content_edit_allowed === 1) &&
      nextStates.length > 0;
    let showComments =
      (nextStates.includes(
        constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG
      ) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG
        )) ||
      (nextStates.includes(constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG
        )) ||
      (nextStates.includes(constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG) &&
        permissionCheck(
          constants.MANAGE_CONTENT_PERMISSIONS.KEY,
          constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG
        ));
    return (
      <div
        // data-spy="scroll" className="scrollspy-example"
        onScroll={this.listenScrollEvent}
        className={`${
          this.state.innerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="d-flex align-items-center transaction-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>
          </div>
          <div className="transaction_details">
            <Prompt
              when={this.state.changesMade}
              message={(location) => `Changes you made may not be saved.`}
            />
            <TransactionDetailsCategories
              addEmptyContent={this.addEmptyContent}
              handleAddRemoveTemplateSubCategory={
                this.handleAddRemoveTemplateSubCategory
              }
              handleAddRemoveTemplateCategory={
                this.handleAddRemoveTemplateCategory
              }
              toggleCheckbox={this.toggleCheckboxParent}
              is_editable={isEditEnabled}
              handleClickScroll={this.handleClickScroll}
              toggleInnerSidebar={this.toggleInnerSidebar}
              selectedSlide={this.state.selectedSlide}
            />

            <TransactionDetailsContent
              switchSegmentHandler={this.switchSegment}
              addEmptyContent={this.addEmptyContent}
              removeContent={this.removeContent}
              dateOnChangeHandler={this.dateOnChangeHandler}
              subcateogoryOnChangeHandler={this.subcateogoryOnChangeHandler}
              onContentChangeHandler={this.onContentChangeHandler}
              onSectionTitleChangeHandler={this.onSectionTitleChangeHandler}
              saveContent={this.saveContent}
              handleCompanyChange={this.handleCompanyChange}
              changesMade={this.state.changesMade}
              undoRemoveContent={this.undoRemoveContent}
              resetContent={this.resetContent}
              is_editable={isEditEnabled}
              showComments={showComments}
              onCommentChangeHandler={this.onCommentChangeHandler}
              toggleCommentsHandlerTransaction={
                this.toggleCommentsHandlerTransaction
              }
              onTransactionCommentChangeHandler={
                this.onTransactionCommentChangeHandler
              }
              toggleCommentsHandler={this.toggleCommentsHandler}
              writeComments={showComments}
              selectedSlide={this.state.selectedSlide}
              mouseScroll={this.mouseScroll}
              setImage={this.state.quoteImageUrl}
              setQuote={this.state.labelquoteContent}
              setQuoteImageName={this.state.quoteImageName}
              quoteId={this.state.quoteId}
              onEditClicked={this.onEditClicked}
              currentState={this.state.currentState}
              {...this.props}
            />
            <QuoteImageDialog
              isOpen={this.state.quoteImageDailogModal}
              header={
                this.state.quoteId
                  ? constants.QUOTE_IMAGE.MODAL_HEADER.EDITING_QUOTE
                  : constants.QUOTE_IMAGE.MODAL_HEADER.ADDING_QUOTE
              }
              config={this.state.dailogModalConfig}
              accept={
                this.state.quoteId
                  ? this.quoteImageDailogModalAccept
                  : this.quoteImageDailogModalStoreAccept
              }
              decline={this.quoteImageDailogModalDecline}
              state={this.state}
              handleTitleChange={this.handleQuoteContent}
              onChangeHandlerImage={this.handleQuoteImage}
              saveButtonName={constants.QUOTE_IMAGE.UPLOAD_BUTTON.UPDATE}
              handleQuoteImageRemove={(e) => {
                this.setState({
                  image_delete: e,
                });
              }}
            />
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companyDetails: (payload) => {
      dispatch(companyDetails(payload));
    },
    companyContent: (payload) => {
      dispatch(companyContent(payload));
    },
    companies: (payload) => {
      dispatch(companies(payload));
    },
    templates: (payload) => {
      dispatch(templates(payload));
    },
    transactionDetails: (payload) => {
      dispatch(transactionDetails(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
