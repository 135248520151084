import React, { Component } from "react";
import { Collapse } from "reactstrap";
import profile_image from "../Images/profile-image.png";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
class TransactionDetailsComments extends Component {
  state = {
    openCommentsFlag: false,
  };

  handleCommentChange = (e) => {
    this.props.onCommentChangeHandler(this.props.uid, e.target.value);
  };

  toggleComments = () => {
    this.props.toggleCommentsHandler(this.props.uid);
  };

  render() {
    return (
      <div className="row bootstrap snippets comment-widget">
        <div className="col-md-12 col-md-offset-2 col-sm-12">
          <div className="comment-wrapper">
            <div className="panel panel-info">
              <div className="panel-heading" onClick={this.toggleComments}>
                <div className="d-flex align-items-center">
                  {this.props.openCommentsFlag && (
                    <span className="material-icons-outlined material-icon icon-fs-21 text-black2">
                      expand_less
                    </span>
                  )}
                  {!this.props.openCommentsFlag && (
                    <span className="material-icons-outlined material-icon icon-fs-21 text-black2">
                      expand_more
                    </span>
                  )}
                  <h5 className="text-black2 font-weight-semibold ml-1 mb-0">
                    Comments{" "}
                    {this.props.comments.length > 0 && (
                      <>({this.props.comments.length})</>
                    )}
                  </h5>
                  <div className="ml-auto d-inline-flex">
                    <span className="material-icons-round text-black2 icon-fs-18">
                      question_answer
                    </span>
                  </div>
                </div>
              </div>
              <Collapse isOpen={this.props.openCommentsFlag}>
                {this.props.openCommentsFlag && (
                  <div className="panel-body">
                    {this.props.writeComments && (
                      <>
                        <textarea
                          className="form-control"
                          placeholder="Your comments"
                          rows="2"
                          onChange={this.handleCommentChange}
                        >
                          {this.props.comment}
                        </textarea>
                      </>
                    )}
                    {this.props.comments.length > 0 && (
                      <>
                        <br />
                        <div className="clearfix"></div>
                        <ul className="media-list">
                          {this.props.comments
                            .sort((a, b) => {
                              return (
                                new Date(b.created_at).getTime() -
                                new Date(a.created_at).getTime()
                              );
                            })
                            .map((comment, key) => (
                              <li className="media" key={comment.id}>
                                <a href="# " className="pull-left">
                                  <img
                                    src={profile_image}
                                    alt=""
                                    className="img-circle"
                                  />
                                </a>
                                <div className="media-body">
                                  <span className="text-muted pull-right">
                                    <small className="text-muted">
                                      {comment.created_at
                                        ? ComapnyDetailsDateTimeFormatter(
                                            comment.created_at
                                          )
                                        : "-"}
                                    </small>
                                  </span>
                                  <strong className="text-success">
                                    @
                                    {comment.created_by_user &&
                                    comment.created_by_user.id
                                      ? `${comment.created_by_user.first_name} ${comment.created_by_user.last_name}`
                                      : "-"}
                                  </strong>
                                  <p>{comment.comment}</p>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                )}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransactionDetailsComments;
