import React, { useEffect, useState } from "react";
import { FaqServices } from "../Services/FaqServices";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";

import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Notification from "../Components/Notification";
import DailogNew from "../Components/DailogNew";

function FaqCategories(props) {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [formState, setFormState] = useState({
    apiError: "",
    categoryError: false,
    descriptionError: false,
    isFormSubmitted: false,
  });

  const [faqCategoryFormValues, setFaqCategoryFormValues] = useState({
    id: "",
    category_name: "",
    description: "",
    isEdit: false,
  });

  const [dailogState, setDailogState] = useState({
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
  });

  useEffect(() => {
    if (formState.isFormSubmitted) {
      // to clear error messages while entering.
      isFormValid();
    }
  }, [faqCategoryFormValues]);

  const toggleCategoryModal = (e, bool) => {
    if (e) {
      e.stopPropagation();
    }
    setIsCategoryModalOpen(!isCategoryModalOpen); // toggle the open state
    setFormState({
      apiError: "",
      categoryError: false,
      descriptionError: false,
      isFormSubmitted: false,
    });
    if (bool) {
      setFaqCategoryFormValues({
        category_name: "",
        description: "",
        isEdit: false,
      });
    }
  };

  const isFormValid = () => {
    let formError = false;
    let categoryError = false;
    let descriptionError = false;
    let apiError = "";
    if (!faqCategoryFormValues.category_name) {
      categoryError = true;
      formError = true;
    }
    if (!faqCategoryFormValues.description) {
      descriptionError = true;
      formError = true;
    }

    // if there is category ame check for duplicates
    if (faqCategoryFormValues.category_name) {
      let isDuplicate = props.categories.find((category) => {
        let existingCategoryName = category.category_name.trim().toLowerCase();
        let newCategoryName = faqCategoryFormValues.category_name
          .trim()
          .toLowerCase();
        return (
          existingCategoryName === newCategoryName &&
          category.id !== faqCategoryFormValues.category_id
        );
      });
      if (isDuplicate) {
        apiError = constants.APPLICATION_ROUTE.FAQS.MESSAGES.DUPLICATE.TOPIC;
        formError = true;
      }
    }

    setFormState({
      categoryError,
      descriptionError,
      formError,
      isFormSubmitted: true,
      apiError,
    });
    return formError;
  };

  const dailogModalAccept = (config) => {
    setDailogState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    switch (config.type) {
      case "delete_FAQ_Category":
        remove_FAQ_Category_By_Id(config.id);
        break;
      default:
        break;
    }
  };

  const remove_FAQ_Category_By_Id = (id) => {
    FaqServices.deleteFAQCategory(id)
      .then((res) => {
        Toast(
          constants.APPLICATION_ROUTE.FAQS.SUCCESS.DELETE_FAQ_CATEGORY,
          "success"
        );
        props.getFaqs();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const handleSubmit = (e) => {
    if (!isFormValid()) {
      if (faqCategoryFormValues.isEdit) {
        let body = {
          category_name: faqCategoryFormValues.category_name.trim(),
          description: faqCategoryFormValues.description.trim(),
        };
        let id = faqCategoryFormValues.category_id;
        FaqServices.updateFAQCategory(id, body)
          .then((res) => {
            toggleCategoryModal();
            props.getFaqs();
            Toast(
              constants.APPLICATION_ROUTE.FAQS.SUCCESS.UPDATE_FAQ_CATEGORY,
              "success"
            );
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
      } else {
        let body = {
          category_name: faqCategoryFormValues.category_name.trim(),
          description: faqCategoryFormValues.description.trim(),
        };
        FaqServices.addFaqCategory(body)
          .then((res) => {
            Toast(
              constants.APPLICATION_ROUTE.FAQS.SUCCESS.ADD_FAQ_CATEGORY,
              "success"
            );
            toggleCategoryModal();
            props.getFaqs();
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
      }
    }
  };

  const handleInputChange = (e) => {
    setFaqCategoryFormValues({
      ...faqCategoryFormValues,
      [e.target.id]: e.target.value,
    });
  };

  const dailogModalDecline = () => {
    setDailogState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  const openDeleteDailog = (e, id) => {
    e.stopPropagation();

    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete FAQ Topic</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.APPLICATION_ROUTE.FAQS.ACTIONS
                .DELETE_FAQ_CATEGORY_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    setDailogState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "delete_FAQ_Category", id: id },
      dailogModalStyleType: "danger-modal",
    });
  };

  const editHandler = (e, Category) => {
    e.stopPropagation();
    setFaqCategoryFormValues({
      category_id: Category.id,
      category_name: Category.category_name,
      description: Category.description,
      isEdit: true,
    });
    toggleCategoryModal();
  };

  const handlecategories = (event) => {
    let selectCategory;
    switch (event.target.value) {
      case "1":
        selectCategory = props.categories.find(
          (category) => category.is_active === 1
        );
        props.setcategorystate({
          categorystate: 1,
          selectedCategory: selectCategory,
        });
        break;
      case "0":
        selectCategory = props.categories.find(
          (category) => category.is_active === 0
        );
        props.setcategorystate({
          categorystate: 0,
          selectedCategory: selectCategory,
        });
        break;
      case "all":
        selectCategory = props.categories[0];
        props.setcategorystate({
          categorystate: "all",
          selectedCategory: selectCategory,
        });
        break;
      default:
        props.setcategorystate("all");
        break;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center top-block-wrapper">
        <div className="top-block">
          <div className="d-flex align-items-center">
            {props.categories.length !=
              props.categories.filter((category) => category.is_active === 1)
                .length && (
              <div className="custom-select-new custom-select-dropdown faq-type-dropdown ml-0">
                <select onChange={handlecategories} value={props.categorystate}>
                  <option value="all">All</option>
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </select>
              </div>
            )}
            <div
              className={`${
                !(
                  props.categories.length !=
                  props.categories.filter(
                    (category) => category.is_active === 1
                  ).length
                )
                  ? "w-100"
                  : ""
              } add-faq-topic-btn-wrapper`}
            >
              <button
                type="button"
                className="btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center justify-content-center mb-0 w-100"
                onClick={(e) => toggleCategoryModal(e, true)}
              >
                <span className="material-icons-round icon-fs-16 mr-1">
                  add
                </span>
                <span>Add a Topic</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-sidebar">
        <div className="faq-inner-sidebar-content h-100">
          <div className="">
            <div className="faq-categories-list">
              {props.categories.map((category) =>
                props.categorystate === "all" ||
                category.is_active === props.categorystate ? (
                  <div
                    key={category.id}
                    className={`faqCategories_action_icons faq-item ${
                      category.id === props.selectedCategory?.id
                        ? "selected"
                        : ""
                    } ${category.is_active ? "" : "inactive"}
                    }`}
                    onClick={() => props.categoryClicked(category.id)}
                  >
                    <div className="d-flex align-items-start">
                      <span className="flex-grow-1 ellipsis faq-item-text mb-6px">
                        {category.category_name}
                      </span>
                      <div className="icons-wrapper d-flex mt-2px ml-2">
                        <span
                          className="material-icons-outlined text-blue icon icon-fs-16 mr-2"
                          onClick={(e) => editHandler(e, category)}
                        >
                          edit
                        </span>
                        <span
                          className="material-icons-outlined text-red icon icon-fs-16"
                          onClick={(e) => openDeleteDailog(e, category.id)}
                        >
                          delete
                        </span>
                      </div>
                    </div>
                    <p className="mb-0 text-grey">
                      {`${
                        category.description.length > 60
                          ? `${category.description.substring(0, 60)}...`
                          : `${category.description.substring(0, 60)}`
                      }`}
                    </p>
                  </div>
                ) : (
                  ""
                )
              )}
              {!props.categories.length ? (
                <h4 className="no-faq-topic-msg">No Topics</h4>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={isCategoryModalOpen}
          className={"modal-md modal-w-header custom-modal primary-modal"}
        >
          <ModalHeader toggle={toggleCategoryModal}>
            {faqCategoryFormValues.isEdit
              ? constants.APPLICATION_ROUTE.FAQS.HEADER.EDIT_CATEGORY_TITLE
              : constants.APPLICATION_ROUTE.FAQS.HEADER.ADD_CATEGORY_TITLE}
          </ModalHeader>
          <ModalBody>
            <form className={"form-wrapper"}>
              <div className="row">
                {formState.apiError && (
                  <Notification color={"danger"} message={formState.apiError} />
                )}
                <div className="col-sm-12 form-group position-relative">
                  <FormGroup>
                    <Label htmlFor="category">
                      Topic <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      type={"text"}
                      className={`form-control ${
                        formState.categoryError ? "is-invalid" : ""
                      }`}
                      style={{ width: "100%", resize: "none" }}
                      id="category_name"
                      name="category_name"
                      defaultValue={faqCategoryFormValues.category_name}
                      onChange={handleInputChange}
                    />
                    {formState.categoryError && (
                      <div className="invalid-tooltip">
                        Topic is a required field
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="description">
                      Description <span className={"mandatory"}>* </span>
                    </Label>
                    <textarea
                      className={`form-control ${
                        formState.descriptionError ? "is-invalid" : ""
                      }`}
                      style={{ width: "100%" }}
                      rows={4}
                      id="description"
                      name="description"
                      defaultValue={faqCategoryFormValues.description}
                      onChange={handleInputChange}
                    />
                    {formState.descriptionError && (
                      <div className="invalid-tooltip">
                        Description is a required field
                      </div>
                    )}
                  </FormGroup>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              className={"btn-outline-primary modal-btn"}
              onClick={toggleCategoryModal}
            >
              Cancel
            </Button>{" "}
            <Button
              className={"modal-right-btn modal-btn"}
              onClick={handleSubmit}
            >
              {faqCategoryFormValues.isEdit ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </Modal>
        {/* Dailog Modals for User Confirmation */}
        <DailogNew
          isOpen={dailogState.dailogModal}
          accept={dailogModalAccept}
          decline={dailogModalDecline}
          header={dailogState.dailogModalHeader}
          content={dailogState.dailogModalContent}
          config={dailogState.dailogModalConfig}
          modalStyleType={dailogState.dailogModalStyleType}
        />
      </div>
    </>
  );
}

export default FaqCategories;
