import React from 'react'
import { Alert } from 'reactstrap'

const Notification = (props) => {
  return (
    <div className={'row'}>
      <Alert color={props.color} className={props.className ? props.className : 'col-sm-12'}>
        {props.message}
      </Alert>
    </div>
  )
}

export default Notification
