import { Axios } from '../lib/axios'
import { Axios_Reports } from '../lib/axios_reports'

export const ReportsServices = {
    getLoginCount,
    getUserLoginDetails,
    getUserDownloadData,
    getAddedUsers,
    getActiveInactiveUser,
    getSessionPageViewCountData,
    getModuleWiseCountData,
    getTimeSpentOnPage,
    pageWiseTimeSpent,
    getVisualizationCreatedData,
    getModuleViewandCreateCount,
    getEventSpecificLogData,
    getBoardCreatedData,
    getModuleScrollAndTimeSpent,
    groupByBoardData,
    groupByBoardAccordianData,
    boardAccordianData,
    getCreatedByBoardsData,
    getGroupByTemplateData,
    getGroupByVisualizationAccordianData,
    getTemplateCreatedByData,
    getTemplateCreatedByAccordianData,
    getTbrTemplateData,
    getTotalBoardDaysWise,
    getTotalChartDaysWise
}

function getLoginCount(id, start_date, end_date) {
    return Axios.get(`/loginCount/${id}?start_date=${start_date}&end_date=${end_date}`)
}
function getUserLoginDetails(url) {
    return Axios_Reports.get(url)
}
function getUserDownloadData(body) {
    return Axios.post('/pageDownloads',body)
}
function getAddedUsers(url) {
    return Axios.get(url)
}
function getActiveInactiveUser(url) {
    return Axios.get(url)
}
function getSessionPageViewCountData(body) {
    return Axios.post(`/pageViewed`,body)
}
function getModuleWiseCountData(body) {
    return Axios.post(`/moduleWiseReport`,body)
}
function   getModuleViewandCreateCount(body) {
    return Axios.post(`/visitedCreatedLogs`,body)
}
function getEventSpecificLogData(body)
{
    return Axios.post(`/getDaysData`,body)
}
function getTimeSpentOnPage(body) {
    return Axios.post(`/timeSpentOnPage`,body)
}
function pageWiseTimeSpent(body) {
    return Axios.post(`/pageWiseTimeSpent`,body)
}
function getVisualizationCreatedData(body){
    return Axios.post(`/getTotalChartDaysWise`,body)
}
function getBoardCreatedData(body)
{
  return Axios.post(`/getTotalBoardDaysWise`,body)
}
function getModuleScrollAndTimeSpent(body)
{
    return Axios.post('/getScrollEventData',body)
}
function groupByBoardData(body)
{
    return Axios.post('/getGroupByBoardData',body)
}
function groupByBoardAccordianData(body)
{
    return Axios.post('/getAccordianData',body)
}
function  boardAccordianData(body)
{
    return Axios.post('/getBoardAccordian',body)
}
function  getCreatedByBoardsData(body)
{
    return Axios.post('/getCreatedByBoardsData',body)
}
function getGroupByTemplateData(body)
{
    return Axios.post('/getGroupByTemplateData',body)
}
function getGroupByVisualizationAccordianData(body)
{
    return Axios.post('/getGroupByTemplateAccordianData',body)
}
function getTotalBoardDaysWise(body)
{
    return Axios.post('/getTotalBoardDaysWise',body)
}
function getTotalChartDaysWise(body)
{
    return Axios.post('/getTotalChartDaysWise',body)
}
function getTemplateCreatedByData(body)
{
    return Axios.post('/getTemplateCreatedByData',body)
}
function getTemplateCreatedByAccordianData(body)
{
    return Axios.post('/getTemplateCreatedByAccordianData',body)
}
function getTbrTemplateData(body)
{
    return Axios.post('/getTbrTemplateData',body)
}