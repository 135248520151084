let constants = {
  SITE: {
    TITLE: "TBR - Admin",
  },
  PAGE: {
    LOGIN: {
      LOGO_TEXT: "Technology Business Research, INC.",
    },
  },
  TITLE_LIMIT_300: "Title connot contain more than 300 characters.",
  TITLE_LIMIT_150: "Title connot contain more than 150 characters.",
  TITLE_LIMIT_100: "Title connot contain more than 100 characters.",
  MANAGE_CONTENT_PERMISSIONS: {
    KEY: "actions",
    ENTITY: "content",
    PROJECT_ENTITY: "projectcontent",
    BENCHMARK_ENTITY: "benchmark_content",
    SPECIAL_REPORT_ENTITY: "special_report_content",
    MARKET_FORECAST_ENTITY: "market_forecast_content",
    MARKET_LANDSCAPE_ENTITY: "market_landscape_content",
    CUSTOMER_STUDY_ENTITY: "customer_study_content",
    ECOSYSTEM_ENTITY: "ecosystem_content",
    CALENDAR_ENTITY: "calendar_content",
    MANAGE: {
      SLUG: "manage",
    },
    ALL: {
      SLUG: "nondrafted",
      BUTTON_TITLE: "All",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "all",
    },
    DRAFT: {
      SLUG: "draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    SUBMIT: {
      SLUG: "submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    RE_SUBMIT: {
      SLUG: "re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    REJECT: {
      SLUG: "reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    APPROVE: {
      SLUG: "approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    REVIEW: {
      SLUG: "review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    APPROVED: {
      SLUG: "approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    REJECTED: {
      SLUG: "rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    PUBLISHED: {
      SLUG: "publish",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "published",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    PUBLISH: {
      SLUG: "publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    UPDATE: {
      SLUG: "update",
      BUTTON_TITLE: "Update",
      CONFIRM_MESSAGE: "Are you sure want to update the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content updated successfully",
    },
    PROJECT_DRAFT: {
      SLUG: "project_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to Save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    PROJECT_SUBMIT: {
      SLUG: "project_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    PROJECT_RE_SUBMIT: {
      SLUG: "project_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    PROJECT_REJECT: {
      SLUG: "project_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    PROJECT_APPROVE: {
      SLUG: "project_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    PROJECT_REVIEW: {
      SLUG: "project_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    PROJECT_APPROVED: {
      SLUG: "project_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    PROJECT_REJECTED: {
      SLUG: "project_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    PROJECT_PUBLISH: {
      SLUG: "project_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    BENCHMARK_DRAFT: {
      SLUG: "benchmark_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    BENCHMARK_SUBMIT: {
      SLUG: "benchmark_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    BENCHMARK_RE_SUBMIT: {
      SLUG: "benchmark_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    BENCHMARK_REJECT: {
      SLUG: "benchmark_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    BENCHMARK_APPROVE: {
      SLUG: "benchmark_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    BENCHMARK_REVIEW: {
      SLUG: "benchmark_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    BENCHMARK_APPROVED: {
      SLUG: "benchmark_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    BENCHMARK_REJECTED: {
      SLUG: "benchmark_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    BENCHMARK_PUBLISH: {
      SLUG: "benchmark_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },

    ECOSYSTEM_DRAFT: {
      SLUG: "ecosystem_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    ECOSYSTEM_SUBMIT: {
      SLUG: "ecosystem_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    ECOSYSTEM_RE_SUBMIT: {
      SLUG: "ecosystem_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    ECOSYSTEM_REJECT: {
      SLUG: "ecosystem_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    ECOSYSTEM_APPROVE: {
      SLUG: "ecosystem_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    ECOSYSTEM_REVIEW: {
      SLUG: "ecosystem_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    ECOSYSTEM_APPROVED: {
      SLUG: "ecosystem_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    ECOSYSTEM_REJECTED: {
      SLUG: "ecosystem_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    ECOSYSTEM_PUBLISH: {
      SLUG: "ecosystem_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },

    CUSTOMER_STUDY_DRAFT: {
      SLUG: "customer_study_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    CUSTOMER_STUDY_SUBMIT: {
      SLUG: "customer_study_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    CUSTOMER_STUDY_RE_SUBMIT: {
      SLUG: "customer_study_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    CUSTOMER_STUDY_REJECT: {
      SLUG: "customer_study_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    CUSTOMER_STUDY_APPROVE: {
      SLUG: "customer_study_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    CUSTOMER_STUDY_REVIEW: {
      SLUG: "customer_study_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    CUSTOMER_STUDY_APPROVED: {
      SLUG: "customer_study_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    CUSTOMER_STUDY_REJECTED: {
      SLUG: "customer_study_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    CUSTOMER_STUDY_PUBLISH: {
      SLUG: "customer_study_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },

    SPECIAL_REPORT_DRAFT: {
      SLUG: "special_report_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    SPECIAL_REPORT_SUBMIT: {
      SLUG: "special_report_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    SPECIAL_REPORT_RE_SUBMIT: {
      SLUG: "special_report_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    SPECIAL_REPORT_REJECT: {
      SLUG: "special_report_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    SPECIAL_REPORT_APPROVE: {
      SLUG: "special_report_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    SPECIAL_REPORT_REVIEW: {
      SLUG: "special_report_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    SPECIAL_REPORT_APPROVED: {
      SLUG: "special_report_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    SPECIAL_REPORT_REJECTED: {
      SLUG: "special_report_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    SPECIAL_REPORT_PUBLISH: {
      SLUG: "special_report_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },

    MARKET_FORECAST_DRAFT: {
      SLUG: "market_forecast_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    MARKET_FORECAST_SUBMIT: {
      SLUG: "market_forecast_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    MARKET_FORECAST_RE_SUBMIT: {
      SLUG: "market_forecast_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    MARKET_FORECAST_REJECT: {
      SLUG: "market_forecast_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    MARKET_FORECAST_APPROVE: {
      SLUG: "market_forecast_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    MARKET_FORECAST_REVIEW: {
      SLUG: "market_forecast_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    MARKET_FORECAST_APPROVED: {
      SLUG: "market_forecast_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    MARKET_FORECAST_REJECTED: {
      SLUG: "market_forecast_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    MARKET_FORECAST_PUBLISH: {
      SLUG: "market_forecast_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    MARKET_LANDSCAPE_DRAFT: {
      SLUG: "market_landscape_draft",
      BUTTON_TITLE: "Save",
      CONFIRM_MESSAGE: "Are you sure want to save the Content?",
      BUTTON_CLASS: "draft",
      SUCCESS_MESSAGE: "Draft saved successfully",
    },
    MARKET_LANDSCAPE_SUBMIT: {
      SLUG: "market_landscape_submit",
      BUTTON_TITLE: "Submit",
      CONFIRM_MESSAGE: "Are you sure want to submit the Content?",
      BUTTON_CLASS: "submit",
      SUCCESS_MESSAGE: "Content submitted successfully",
    },
    MARKET_LANDSCAPE_RE_SUBMIT: {
      SLUG: "market_landscape_re-submit",
      BUTTON_TITLE: "Re-Submit",
      CONFIRM_MESSAGE: "Are you sure want to re-submit the Content?",
      BUTTON_CLASS: "re-submit",
      SUCCESS_MESSAGE: "Content re-submitted successfully",
    },
    MARKET_LANDSCAPE_REJECT: {
      SLUG: "market_landscape_reject",
      BUTTON_TITLE: "Reject",
      CONFIRM_MESSAGE: "Are you sure want to reject the Content?",
      BUTTON_CLASS: "reject",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    MARKET_LANDSCAPE_APPROVE: {
      SLUG: "market_landscape_approve",
      BUTTON_TITLE: "Approve",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approve",
      SUCCESS_MESSAGE: "Content approved successfully",
    },
    MARKET_LANDSCAPE_REVIEW: {
      SLUG: "market_landscape_review",
      BUTTON_TITLE: "Review",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "review",
      SUCCESS_MESSAGE: "Content reviewed successfully",
    },
    MARKET_LANDSCAPE_APPROVED: {
      SLUG: "market_landscape_approved",
      BUTTON_TITLE: "Published",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    MARKET_LANDSCAPE_REJECTED: {
      SLUG: "market_landscape_rejected",
      BUTTON_TITLE: "Rejected",
      CONFIRM_MESSAGE: "Are you sure want to approve the Content?",
      BUTTON_CLASS: "approved",
      SUCCESS_MESSAGE: "Content rejected successfully",
    },
    MARKET_LANDSCAPE_PUBLISH: {
      SLUG: "market_landscape_publish",
      BUTTON_TITLE: "Publish",
      CONFIRM_MESSAGE: "Are you sure want to publish the Content?",
      BUTTON_CLASS: "publish",
      SUCCESS_MESSAGE: "Content published successfully",
    },
    COMPANY_METRIC: {
      APPROVE: "company_metric_approve",
      REJECT: "company_metric_reject",
      APPROVED: "Transaction approved",
      REJECTED: "Transaction Rejected",
    },
  },
  EMAIL_BOUNCE_LIST_TABS: {
    ADMIN: [
      {
        KEY: "all",
        STATE: "all",
        TAB_TITLE: "All",
        ROLE: "admin",
        HIDE_TAB: false,
      },
      {
        KEY: "in_valid",
        STATE: "in_valid",
        TAB_TITLE: "Invalid",
        ROLE: "admin",
        HIDE_TAB: false,
      },
      {
        KEY: "others",
        STATE: "others",
        TAB_TITLE: "Others",
        ROLE: "admin",
        HIDE_TAB: false,
      },
    ],
  },
  TRANSACTIONS_LIST_TABS: {
    ANALYST: [
      {
        KEY: "all",
        STATE: "nondrafted",
        TAB_TITLE: "All",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "review",
        STATE: "review",
        TAB_TITLE: "Review",
        ROLE: "analyst",
        HIDE_TAB: false,
      },
      {
        KEY: "my_submissions",
        STATE: "my_submissions",
        TAB_TITLE: "My Submissions",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_reviewed",
        STATE: "my_reviewed",
        TAB_TITLE: "My Reviewed",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_rejections",
        STATE: "rejections",
        TAB_TITLE: "Re-Submit",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "published",
        STATE: "published",
        TAB_TITLE: "Published",
        ROLE: null,
        HIDE_TAB: false,
      },
    ],
    SENIOR_ANALYST: [
      {
        KEY: "all",
        STATE: "nondrafted",
        TAB_TITLE: "All",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "review",
        STATE: "review",
        TAB_TITLE: "Review",
        ROLE: "senior_analyst",
        HIDE_TAB: false,
      },
      {
        KEY: "my_submissions",
        STATE: "my_submissions",
        TAB_TITLE: "My Submissions",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_reviewed",
        STATE: "my_reviewed",
        TAB_TITLE: "My Reviewed",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_rejections",
        STATE: "rejections",
        TAB_TITLE: "Re-Submit",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "published",
        STATE: "published",
        TAB_TITLE: "Published",
        ROLE: null,
        HIDE_TAB: false,
      },
    ],
    PRACTICE_MANAGER: [
      {
        KEY: "all",
        STATE: "nondrafted",
        TAB_TITLE: "All",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "review",
        STATE: "review",
        TAB_TITLE: "Review",
        ROLE: "practice_manager",
        HIDE_TAB: false,
      },
      {
        KEY: "my_submissions",
        STATE: "my_submissions",
        TAB_TITLE: "My Submissions",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_reviewed",
        STATE: "my_reviewed",
        TAB_TITLE: "My Reviewed",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_rejections",
        STATE: "rejections",
        TAB_TITLE: "Re-Submit",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "published",
        STATE: "published",
        TAB_TITLE: "Published",
        ROLE: null,
        HIDE_TAB: false,
      },
    ],
    EDITOR: [
      {
        KEY: "all",
        STATE: "nondrafted",
        TAB_TITLE: "All",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "review",
        STATE: "review",
        TAB_TITLE: "Review",
        ROLE: "editor",
        HIDE_TAB: false,
      },
      {
        KEY: "my_reviewed",
        STATE: "my_reviewed",
        TAB_TITLE: "Rejected",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "published",
        STATE: "published",
        TAB_TITLE: "Published",
        ROLE: null,
        HIDE_TAB: false,
      },
    ],
    ADMIN: [
      {
        KEY: "all",
        STATE: "nondrafted",
        TAB_TITLE: "All",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "review",
        STATE: "review",
        TAB_TITLE: "Review",
        ROLE: "admin",
        HIDE_TAB: false,
      },
      {
        KEY: "my_submissions",
        STATE: "my_submissions",
        TAB_TITLE: "My Submissions",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_reviewed",
        STATE: "my_reviewed",
        TAB_TITLE: "My Reviewed",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_rejections",
        STATE: "rejections",
        TAB_TITLE: "Re-Submit",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "published",
        STATE: "published",
        TAB_TITLE: "Published",
        ROLE: null,
        HIDE_TAB: false,
      },
    ],
    RESEARCH_ANALYST: [
      {
        KEY: "all",
        STATE: "nondrafted",
        TAB_TITLE: "All",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_submissions",
        STATE: "my_submissions",
        TAB_TITLE: "My Submissions",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "my_rejections",
        STATE: "rejections",
        TAB_TITLE: "Re-Submit",
        ROLE: null,
        HIDE_TAB: false,
      },
      {
        KEY: "published",
        STATE: "published",
        TAB_TITLE: "Published",
        ROLE: null,
        HIDE_TAB: false,
      },
    ],
  },
  MANAGE_CONTENT_MY_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_CONTENT_TRACK_SUBMISSIONS: {
    LIST: {
      HEADER_TITLE: "Track Submissions",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_CONTENT_TRANSACTION_DETAILS: {
    HEADER_TITLE: "Transaction Details",
    DELETE_CONTENT_CONFIRM_MESSAGE: "Are you sure want to delete the Content?",
    DELETE_AUTHOR_CONFIRM_MESSAGE: "Are you sure want to remove the Author?",
    ADD_AUTHOR_SUCCESS_MESSAGE: "Author added successfully",
    DELETE_AUTHOR_SUCCESS_MESSAGE: "Author deleted successfully",
    UNDO_DELETE_ACTION_CONFIRM_MESSAGE:
      "Are you sure want to undo the delete action?",
    DISCARD_CHANGES_CONFIRM_MESSAGE:
      "Are you sure want to discard the changes?",
    RESET_CHANGES_CONFIRM_MESSAGE: "Are you sure want to reset the changes?",
    DELETE_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to delete the Category?",
    DELETE_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to delete the Sub-category?",
  },
  MANAGE_CONTENT_PROJECT_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Project Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS: {
    LIST: {
      HEADER_TITLE: "Track Submissions",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_CONTENT_PROJECT_TRANSACTION_DETAILS: {
    HEADER_TITLE: "Transaction Details",
  },
  MANAGE_CONTENT_BENCHMARK_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Benchmark Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS: {
    LIST: {
      HEADER_TITLE: "Track Submissions",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_CONTENT_BENCHMARK_TRANSACTION_DETAILS: {
    HEADER_TITLE: "Transaction Details",
  },
  MANAGE_CONTENT_MARKET_FORECAST_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Market Forecast Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS: {
    LIST: {
      HEADER_TITLE: "Track Submissions",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_CONTENT_MARKET_FORECAST_TRANSACTION_DETAILS: {
    HEADER_TITLE: "Transaction Details",
  },
  MANAGE_CONTENT_MARKET_LANDSCAPE_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Market Landscape Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS: {
    LIST: {
      HEADER_TITLE: "Track Submissions",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_CONTENT_MARKET_LANDSCAPE_TRANSACTION_DETAILS: {
    HEADER_TITLE: "Transaction Details",
  },
  MANAGE_CONTENT_CUSTOMER_STUDIES_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Customer Study Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_CONTENT_ECOSYSTEM_DRAFTS: {
    LIST: {
      HEADER_TITLE: "My Ecosystem Drafts",
      VIEW_DETAILS: "Transaction Details",
    },
    CREATE: {
      HEADER_TITLE: "Create Drafts",
    },
    EDIT: {
      HEADER_TITLE: "Edit Drafts",
    },
    DELETE: {
      HEADER_TITLE: "Delete Drafts",
    },
  },
  MANAGE_METRIC_TYPE_AND_DATA: {
    LIST: {
      HEADER_TITLE: "Metric Types,Metric Units, Metrics",
      VIEW_DETAILS: "Metric Types,Metric Units, Metrics",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_LOB_AND_SUB_CATEGORIES: {
    LIST: {
      HEADER_TITLE: "LOBs, LOB Sub-categories",
      VIEW_DETAILS: "LOBs, LOB Sub-categories",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_VERTICAL_AND_CATEGORIES: {
    LIST: {
      HEADER_TITLE: "Verticals, Vertical Sub-categories",
      VIEW_DETAILS: "Verticals, Vertical Sub-categories",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_GEOGRAPHY_REGION_COUNTRY: {
    LIST: {
      HEADER_TITLE: "Geographies, Geo Sub-categories, Countries",
      VIEW_DETAILS: "Geographies, Geo Sub-categories, Countries",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_FILE_UPLOADS: {
    LIST: {
      HEADER_TITLE: "File Uploads",
      VIEW_DETAILS: "File Uploads",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  MANAGE_METRIC_RULES_AND_THRESHOLDS: {
    UPDATED: "Threshold Updated",
    REMOVED: "Threshold Removed",
    INVALID: "Threshold must be an integer",
    INACTIVATE_THRESHOLD_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Template Record?",
    ACTIVATE_THRESHOLD_CONFIRM_MESSAGE:
      "Are you sure want to activate the Template Record?",
    INACTIVATE_METRIC_RULE_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Metric rule Record?",
    ACTIVATE_METRIC_RULE_CONFIRM_MESSAGE:
      "Are you sure want to activate the Metric rule Record?",
    REMOVE_THRESHOLD_CONFIRM_MESSAGE:
      "Are you sure you want to remove the Threshold? This will delete the rules associated with the Template Record",
    LIST: {
      HEADER_TITLE: "Metric Rules, Thresholds",
      VIEW_DETAILS: "Metric Rules, Thresholds",
    },
    CREATE: {
      HEADER_TITLE: "",
    },
    EDIT: {
      HEADER_TITLE: "",
    },
    DELETE: {
      HEADER_TITLE: "",
    },
  },
  APPLICATION_ROUTE: {
    RESOURCE_AND_FAQ: {
      ROUTE: "/resources",
      TAB: ["Resources", "FAQs"],
      NAME: "Resource and FAQ",
    },
    FAQS: {
      MESSAGES: {
        DUPLICATE: {
          TOPIC: "Topic already exists",
          FAQ: "FAQ already exists",
        },
      },
      HEADER: {
        TITLE: "FAQs",
        ADD_TITLE: "Add FAQ",
        EDIT_TITLE: "Edit FAQ",
        ADD_CATEGORY_TITLE: "Add Topic",
        EDIT_CATEGORY_TITLE: "Edit Topic",
      },
      SUCCESS: {
        ADD_FAQ: "FAQ is added successfully",
        UPDATE_FAQ: "FAQ is updated successfully",
        DELETE_FAQ: "FAQ is deleted successfully",
        ADD_FAQ_CATEGORY: "Topic is added successfully",
        UPDATE_FAQ_CATEGORY: "Topic is updated successfully",
        DELETE_FAQ_CATEGORY: "Topic is deleted successfully",
        ACTIVATED_FAQ: "FAQ activated successfully",
        INACTIVATED_FAQ: "FAQ inactivated successfully",
      },
      LIST: {
        NAME: "FAQs",
        ROUTE: "/FAQs",
        ADD_BUTTON_TITLE: "Add FAQ",
      },
      ACTIONS: {
        DELETE_FAQ_MESSAGE: "Are you sure you want to delete FAQ?",
        DELETE_FAQ_CATEGORY_MESSAGE:
          "Are you sure you want to delete FAQ Topic?",
        ACTIVATE_FAQ_MESSAGE: "Are you sure you want to activate FAQ?",
        DE_ACTIVATE_FAQ_MESSAGE: "Are you sure you want to de-activate FAQ?",
      },
    },
    HELP: {
      HEADER: {
        TITLE: "Help",
        ADD_TITLE: "Add Help",
        EDIT_TITLE: "Edit Help",
        CATEGORY_TITLE: "Category",
      },
      SUCCESS: {
        WARNING_HELP: "Are you sure you want to remove the Help?",
        ADD_HELP: "Help is added successfully",
        UPDATE_HELP: "Help is updated successfully",
        DELETE_HELP: "Help is deleted successfully",
      },
      LIST: {
        NAME: "Help",
        ROUTE: "/Help",
        ADD_BUTTON_TITLE: "Add Help",
      },
    },
    LOGIN: {
      NAME: "Login",
      ROUTE: "/login",
    },
    VERIFY_EMAIL: {
      NAME: "Verify Email",
      ROUTE: "/email-verify/:code",
    },
    RESET_PASSWORD: {
      NAME: "Reset Password",
      ROUTE: "/reset-password/:code",
    },
    FORGOT_PASSWORD: {
      NAME: "Reset Password",
      ROUTE: "/forgot-password",
    },
    COMPANY_GROUP: {
      NAME: "Company Research",
      ROUTE: null,
    },
    CLIENT_GROUP: {
      NAME: "Clients",
      ROUTE: null,
    },
    PROJECT_GROUP: {
      NAME: "Projects",
      ROUTE: null,
    },
    BENCHMARK_GROUP: {
      NAME: "Benchmarks",
      ROUTE: null,
    },
    CALENDAR_GROUP: {
      NAME: "Calendars",
      ROUTE: null,
    },
    SPECIAL_REPORT_GROUP: {
      NAME: "Special Reports",
      ROUTE: null,
    },
    CUSTOMER_STUDY_GROUP: {
      NAME: "Customer Study",
      ROUTE: null,
    },
    CUSTOMER_STUDIES_GROUP: {
      NAME: "Customer Studies",
      ROUTE: null,
    },
    ECOSYSTEM_GROUP: {
      NAME: "Ecosystems",
      ROUTE: null,
    },
    MARKET_FORECAST_GROUP: {
      NAME: "Market Forecasts",
      ROUTE: null,
    },
    MARKET_LANDSCAPE_GROUP: {
      NAME: "Market Landscapes",
      ROUTE: null,
    },
    MASTER_DATA_GROUP: {
      NAME: "Configurations",
      ROUTE: null,
    },
    TEMPLATE_GROUP: {
      NAME: "Metrics",
      ROUTE: null,
    },
    ARCHIVE_GROUP: {
      NAME: "Archive",
      ROUTE: null,
    },
    TAXONOMY_GROUP: {
      NAME: "Taxonomy",
      ROUTE: null,
    },
    USER_GROUP: {
      NAME: "User",
      ROUTE: null,
    },
    REPORTS_GROUP: {
      NAME: "Reports",
      ROUTE: null,
    },
    MANAGE_CONTENT: {
      NAME: "Manage Content",
      ROUTE: "/manage-companies",
    },
    MANAGE_CONTENT_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/my-drafts",
    },
    MANAGE_CONTENT_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/track-submissions",
    },
    MANAGE_CONTENT_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/transaction/:id/details",
    },
    MANAGE_CONTENT_PROJECT_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/project-drafts",
    },
    MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/project-track-submissions",
    },
    MANAGE_CONTENT_PROJECT_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/project/transaction/:id/details",
    },
    COMPANY: {
      LIST: {
        NAME: "List",
        ROUTE: "/company/list",
        HEADER_TITLE: "Company Research",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_COMPANY: "Edit Company",
        REMOVE_COMPANY: "Remove Company",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_COMPANY_TOOLTIP: "Edit",
        REMOVE_COMPANY_TOOLTIP: "Remove",
        ACTIVATE_COMPANY_TOOLTIP: "Activate",
        INACTIVATE_COMPANY_TOOLTIP: "De-Activate",
        EXPORT_EXCEL_TOOLTIP: "Export",
      },
      VIEW: {
        NAME: "Company Details",
        ROUTE: "/company/:id/details",
      },
      CREATE: {
        NAME: "Add Company",
        ROUTE: "/company/add",
        HEADER_TITLE: "Add Company",
      },
      EDIT: {
        NAME: "Edit Company",
        ROUTE: "/company/:id/edit",
        HEADER_TITLE: "Edit Company",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/company/:id/manage",
      },
    },
    CALENDAR_WEBINAR: {
      LIST: {
        NAME: "Webinar",
        ROUTE: "/calendar-webinar",
      },
    },
    CALENDAR_PUBLISHED: {
      LIST: {
        NAME: "Published",
        ROUTE: "/calendar-published",
      },
    },
    BENCHMARK: {
      LIST: {
        NAME: "List",
        ROUTE: "/benchmark/list",
        HEADER_TITLE: "Benchmarks",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_BENCHMARK: "Edit Benchmark",
        REMOVE_BENCHMARK: "Remove Benchmark",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_BENCHMARK_TOOLTIP: "Edit",
        REMOVE_BENCHMARK_TOOLTIP: "Remove",
        ACTIVATE_BENCHMARK_TOOLTIP: "Activate",
        INACTIVATE_BENCHMARK_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Benchmark Details",
        ROUTE: "/benchmark/:id/details",
      },
      CREATE: {
        NAME: "Add Benchmark",
        ROUTE: "/benchmark/add",
        HEADER_TITLE: "Add Benchmark",
      },
      EDIT: {
        NAME: "Edit Benchmark",
        ROUTE: "/benchmark/:id/edit",
        HEADER_TITLE: "Edit Benchmark",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/benchmark/:id/manage",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/benchmark/:id/manage",
        HEADER_TITLE: "Manage Content",
      },
    },
    MANAGE_PREFERENCES: {
      NAME: "Manage Preferences",
      ROUTE: "/manage-preferences",
    },
    MANAGE_CONTENT_BENCHMARK_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/benchmark-drafts",
    },
    MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/benchmark-track-submissions",
    },
    MANAGE_CONTENT_BENCHMARK_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/benchmark-transaction/:id/details",
    },
    SPECIAL_REPORT: {
      LIST: {
        NAME: "List",
        ROUTE: "/specialreport/list",
        HEADER_TITLE: "Special Reports",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_SPECIAL_REPORT: "Edit Special Report",
        REMOVE_SPECIAL_REPORT: "Remove Special Report",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_SPECIAL_REPORT_TOOLTIP: "Edit",
        REMOVE_SPECIAL_REPORT_TOOLTIP: "Remove",
        ACTIVATE_SPECIAL_REPORT_TOOLTIP: "Activate",
        INACTIVATE_SPECIAL_REPORT_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Special Report Details",
        ROUTE: "/specialreport/:id/details",
      },
      CREATE: {
        NAME: "Add Special Report",
        ROUTE: "/specialreport/add",
        HEADER_TITLE: "Add Special Report",
      },
      EDIT: {
        NAME: "Edit Special Report",
        ROUTE: "/specialreport/:id/edit",
        HEADER_TITLE: "Edit Special Report",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/specialreport/:id/manage",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/specialreport/:id/manage",
        HEADER_TITLE: "Manage Content",
      },
    },
    MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/specialreport-drafts",
    },
    MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/specialreport-track-submissions",
    },
    MANAGE_CONTENT_SPECIAL_REPORT_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/specialreport-transaction/:id/details",
    },
    CUSTOMER_STUDY: {
      LIST: {
        NAME: "List",
        ROUTE: "/customer-studies/list",
        HEADER_TITLE: "Customer Studies",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_CUSTOMER_STUDY: "Edit Customer Study",
        REMOVE_CUSTOMER_STUDY: "Remove Customer Study",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_CUSTOMER_STUDY_TOOLTIP: "Edit",
        REMOVE_CUSTOMER_STUDY_TOOLTIP: "Remove",
        ACTIVATE_CUSTOMER_STUDY_TOOLTIP: "Activate",
        INACTIVATE_CUSTOMER_STUDY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Customer Study Details",
        ROUTE: "/specialreport/:id/details",
      },
      CREATE: {
        NAME: "Add Customer Study",
        ROUTE: "/customer-studies/add",
        HEADER_TITLE: "Add Customer Study",
      },
      EDIT: {
        NAME: "Edit Customer Study",
        ROUTE: "/customer-studies/:id/edit",
        HEADER_TITLE: "Edit Customer Study",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/customer-studies/:id/manage",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/customer-studies/:id/manage",
        HEADER_TITLE: "Manage Content",
      },
    },
    MARKET_FORECAST: {
      LIST: {
        NAME: "List",
        ROUTE: "/market-forecast/list",
        HEADER_TITLE: "Market Forecasts",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_MARKET_FORECAST: "Edit Market Forecast",
        REMOVE_MARKET_FORECAST: "Remove Market Forecast",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_MARKET_FORECAST_TOOLTIP: "Edit",
        REMOVE_MARKET_FORECAST_TOOLTIP: "Remove",
        ACTIVATE_MARKET_FORECAST_TOOLTIP: "Activate",
        INACTIVATE_MARKET_FORECAST_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Market Forecast Details",
        ROUTE: "/market-forecast/:id/details",
      },
      CREATE: {
        NAME: "Add Market Forecast",
        ROUTE: "/market-forecast/add",
        HEADER_TITLE: "Add Market Forecast",
      },
      EDIT: {
        NAME: "Edit Market Forecast",
        ROUTE: "/market-forecast/:id/edit",
        HEADER_TITLE: "Edit Market Forecast",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/market-forecast/:id/manage",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/market-forecast/:id/manage",
        HEADER_TITLE: "Manage Content",
      },
    },
    MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/market-forecast-drafts",
    },
    MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/market-forecast-track-submissions",
    },
    MANAGE_CONTENT_MARKET_FORECAST_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/market-forecast-transaction/:id/details",
    },
    MARKET_LANDSCAPE: {
      LIST: {
        NAME: "List",
        ROUTE: "/market-landscape/list",
        HEADER_TITLE: "Market Landscapes",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_MARKET_LANDSCAPE: "Edit Market Landscape",
        REMOVE_MARKET_LANDSCAPE: "Remove Market Landscape",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_MARKET_LANDSCAPE_TOOLTIP: "Edit",
        REMOVE_MARKET_LANDSCAPE_TOOLTIP: "Remove",
        ACTIVATE_MARKET_LANDSCAPE_TOOLTIP: "Activate",
        INACTIVATE_MARKET_LANDSCAPE_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Market Landscape Details",
        ROUTE: "/market-landscape/:id/details",
      },
      CREATE: {
        NAME: "Add Market Landscape",
        ROUTE: "/market-landscape/add",
        HEADER_TITLE: "Add Market Landscape",
      },
      EDIT: {
        NAME: "Edit Market Landscape",
        ROUTE: "/market-landscape/:id/edit",
        HEADER_TITLE: "Edit Market Landscape",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/market-landscape/:id/manage",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/market-landscape/:id/manage",
        HEADER_TITLE: "Manage Content",
      },
    },
    MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/market-landscape-drafts",
    },
    MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/market-landscape-track-submissions",
    },
    MANAGE_CONTENT_MARKET_LANDSCAPE_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/market-landscape-transaction/:id/details",
    },
    MANAGE_METRIC_TYPE_AND_DATA: {
      NAME: "Metrics",
      ROUTE: "/metrics",
      TABS: [
        {
          KEY: "metric-types",
          TAB_TITLE: "Metric Types",
          HIDE_TAB: false,
        },
        {
          KEY: "metric-units",
          TAB_TITLE: "Metric Units",
          HIDE_TAB: false,
        },
        {
          KEY: "metrics",
          TAB_TITLE: "Metrics",
          HIDE_TAB: false,
        },
      ],
    },
    MASTER_DATA_METRIC_TYPE: {
      LIST: {
        NAME: "List",
        ROUTE: "/metric-type/list",
        HEADER_TITLE: "Metric Types",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_METRIC_TYPE: "Edit Metric Type",
        REMOVE_METRIC_TYPE: "Remove Metric Type",
        STATUS: "Status",
        EDIT_METRIC_TYPE_TOOLTIP: "Edit",
        REMOVE_METRIC_TYPE_TOOLTIP: "Remove",
        ACTIVATE_METRIC_TYPE_TOOLTIP: "Activate",
        INACTIVATE_METRIC_TYPE_TOOLTIP: "De-Activate",
        MANAGE_METRICS: "Manage Metrics",
        MANAGE_METRICS_TOOLTIP: "Manage Metrics",
      },
      VIEW: {
        NAME: "Metric Type Details",
        ROUTE: "/metric-type/:id/details",
      },
      CREATE: {
        NAME: "Add Metric Type",
        ROUTE: "/metric-type/add",
        HEADER_TITLE: "Add Metric",
      },
      EDIT: {
        NAME: "Edit Metric Type",
        ROUTE: "/metric-type/:id/edit",
        HEADER_TITLE: "Edit Metric",
      },
    },
    MASTER_DATA_METRIC_UNIT: {
      LIST: {
        NAME: "List",
        ROUTE: "/metric-unit/list",
        HEADER_TITLE: "Metric Units",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_METRIC_UNIT: "Edit Metric Unit",
        REMOVE_METRIC_UNIT: "Remove Metric Unit",
        STATUS: "Status",
        EDIT_METRIC_TYPE_TOOLTIP: "Edit",
        REMOVE_METRIC_TYPE_TOOLTIP: "Remove",
        ACTIVATE_METRIC_TYPE_TOOLTIP: "Activate",
        INACTIVATE_METRIC_TYPE_TOOLTIP: "De-Activate",
        MANAGE_METRICS: "Manage Metrics",
        MANAGE_METRICS_TOOLTIP: "Manage Metrics",
      },
      VIEW: {
        NAME: "Metric Type Details",
        ROUTE: "/metric-type/:id/details",
      },
      CREATE: {
        NAME: "Add Metric Type",
        ROUTE: "/metric-type/add",
        HEADER_TITLE: "Add Metric",
      },
      EDIT: {
        NAME: "Edit Metric Type",
        ROUTE: "/metric-type/:id/edit",
        HEADER_TITLE: "Edit Metric",
      },
    },
    MASTER_DATA_METRIC: {
      LIST: {
        NAME: "List",
        ROUTE: "/metric/list",
        HEADER_TITLE: "Metrics",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_METRIC: "Edit Metric",
        REMOVE_METRIC: "Remove Metric",
        STATUS: "Status",
        EDIT_METRIC_TOOLTIP: "Edit",
        REMOVE_METRIC_TOOLTIP: "Remove",
        ACTIVATE_METRIC_TOOLTIP: "Activate",
        INACTIVATE_METRIC_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Metric Details",
        ROUTE: "/metric/:id/details",
      },
      CREATE: {
        NAME: "Add Metric",
        ROUTE: "/metric/add",
        HEADER_TITLE: "Add Metric",
      },
      EDIT: {
        NAME: "Edit Metric",
        ROUTE: "/metric/:id/edit",
        HEADER_TITLE: "Edit Metric",
      },
    },
    MANAGE_LOB_AND_SUB_CATEGORIES: {
      NAME: "LOBs",
      ROUTE: "/lobs",
      ARCHIVE_ROUTE: "/archive/lobs",
      TABS: [
        {
          KEY: "lobs",
          TAB_TITLE: "LOBs",
          HIDE_TAB: false,
        },
        {
          KEY: "sub-categories",
          TAB_TITLE: "LOB Sub-categories",
          HIDE_TAB: false,
        },
      ],
    },
    MASTER_DATA_LOB: {
      LIST: {
        NAME: "List",
        ROUTE: "/lob/list",
        HEADER_TITLE: "LOBs",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_LOB: "Edit LOB",
        REMOVE_LOB: "Remove LOB",
        STATUS: "Status",
        EDIT_LOB_TOOLTIP: "Edit",
        REMOVE_LOB_TOOLTIP: "Remove",
        ACTIVATE_LOB_TOOLTIP: "Activate",
        INACTIVATE_LOB_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "LOB Details",
        ROUTE: "/lob/:id/details",
      },
      CREATE: {
        NAME: "Add LOB",
        ROUTE: "/lob/add",
        HEADER_TITLE: "Add LOB",
      },
      EDIT: {
        NAME: "Edit LOB",
        ROUTE: "/lob/:id/edit",
        HEADER_TITLE: "Edit LOB",
      },
    },
    MASTER_DATA_LOB_SUB_CATEGORY: {
      LIST: {
        NAME: "List",
        ROUTE: "/lob-sub-category/list",
        HEADER_TITLE: "Sub Categories",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_LOB_SUB_CATEGORY: "Edit Sub Category",
        REMOVE_LOB_SUB_CATEGORY: "Remove Sub Category",
        STATUS: "Status",
        EDIT_LOB_SUB_CATEGORY_TOOLTIP: "Edit",
        REMOVE_LOB_SUB_CATEGORY_TOOLTIP: "Remove",
        ACTIVATE_LOB_SUB_CATEGORY_TOOLTIP: "Activate",
        INACTIVATE_LOB_SUB_CATEGORY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Sub Category Details",
        ROUTE: "/lob-sub-category/:id/details",
      },
      CREATE: {
        NAME: "Add Sub Category",
        ROUTE: "/lob-sub-category/add",
        HEADER_TITLE: "Add Sub Category",
      },
      EDIT: {
        NAME: "Edit Sub Category",
        ROUTE: "/lob-sub-category/:id/edit",
        HEADER_TITLE: "Edit Sub Category",
      },
    },
    MANAGE_VERTICAL_AND_CATEGORIES: {
      NAME: "Verticals",
      ROUTE: "/verticals",
      ARCHIVE_ROUTE: "/archive/verticals",
      TABS: [
        {
          KEY: "verticals",
          TAB_TITLE: "Verticals",
          HIDE_TAB: false,
        },
        {
          KEY: "vertical-categories",
          TAB_TITLE: "Vertical Sub-categories",
          HIDE_TAB: false,
        },
        // {
        //   KEY: 'vertical-sub-categories',
        //   TAB_TITLE: 'Vertical Sub Categories',
        //   HIDE_TAB: false
        // }
      ],
    },
    MASTER_DATA_VERTICAL: {
      LIST: {
        NAME: "List",
        ROUTE: "/vertical/list",
        HEADER_TITLE: "Verticals",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_VERTICAL: "Edit Vertical",
        REMOVE_VERTICAL: "Remove Vertical",
        STATUS: "Status",
        EDIT_VERTICAL_TOOLTIP: "Edit",
        REMOVE_VERTICAL_TOOLTIP: "Remove",
        ACTIVATE_VERTICAL_TOOLTIP: "Activate",
        INACTIVATE_VERTICAL_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Vertical Details",
        ROUTE: "/vertical/:id/details",
      },
      CREATE: {
        NAME: "Add Vertical",
        ROUTE: "/vertical/add",
        HEADER_TITLE: "Add Vertical",
      },
      EDIT: {
        NAME: "Edit Vertical",
        ROUTE: "/vertical/:id/edit",
        HEADER_TITLE: "Edit Vertical",
      },
    },
    MASTER_DATA_VERTICAL_CATEGORY: {
      LIST: {
        NAME: "List",
        ROUTE: "/vertical-category/list",
        HEADER_TITLE: "Categories",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_VERTICAL_CATEGORY: "Edit Category",
        REMOVE_VERTICAL_CATEGORY: "Remove Category",
        STATUS: "Status",
        EDIT_VERTICAL_CATEGORY_TOOLTIP: "Edit",
        REMOVE_VERTICAL_CATEGORY_TOOLTIP: "Remove",
        ACTIVATE_VERTICAL_CATEGORY_TOOLTIP: "Activate",
        INACTIVATE_VERTICAL_CATEGORY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Category Details",
        ROUTE: "/vertical-category/:id/details",
      },
      CREATE: {
        NAME: "Add Category",
        ROUTE: "/vertical-category/add",
        HEADER_TITLE: "Add Category",
      },
      EDIT: {
        NAME: "Edit Category",
        ROUTE: "/vertical-category/:id/edit",
        HEADER_TITLE: "Edit Category",
      },
    },
    MASTER_DATA_VERTICAL_SUB_CATEGORY: {
      LIST: {
        NAME: "List",
        ROUTE: "/vertical-sub-category/list",
        HEADER_TITLE: "Sub Categories",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_VERTICAL_SUB_CATEGORY: "Edit Sub Category",
        REMOVE_VERTICAL_SUB_CATEGORY: "Remove Sub Category",
        STATUS: "Status",
        EDIT_VERTICAL_SUB_CATEGORY_TOOLTIP: "Edit",
        REMOVE_VERTICAL_SUB_CATEGORY_TOOLTIP: "Remove",
        ACTIVATE_VERTICAL_SUB_CATEGORY_TOOLTIP: "Activate",
        INACTIVATE_VERTICAL_SUB_CATEGORY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Sub Category Details",
        ROUTE: "/vertical-sub-category/:id/details",
      },
      CREATE: {
        NAME: "Add Sub Category",
        ROUTE: "/vertical-sub-category/add",
        HEADER_TITLE: "Add Sub Category",
      },
      EDIT: {
        NAME: "Edit Sub Category",
        ROUTE: "/vertical-sub-category/:id/edit",
        HEADER_TITLE: "Edit Sub Category",
      },
    },
    MANAGE_GEOGRAPHY_REGION_COUNTRY: {
      NAME: "Geographies",
      ROUTE: "/geographies",
      ARCHIVE_ROUTE: "/archive/geographies",
      TABS: [
        {
          KEY: "geographies",
          TAB_TITLE: "Geographies",
          HIDE_TAB: false,
        },
        {
          KEY: "geo_sub_categories",
          TAB_TITLE: "Geo Sub-Categories",
          HIDE_TAB: false,
        },
        {
          KEY: "countries",
          TAB_TITLE: "Countries",
          HIDE_TAB: false,
        },
      ],
    },
    MASTER_DATA_GEOGRAPHY: {
      LIST: {
        NAME: "List",
        ROUTE: "/geography/list",
        HEADER_TITLE: "Geographies",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_GEOGRAPHY: "Edit Geography",
        REMOVE_GEOGRAPHY: "Remove Geography",
        STATUS: "Status",
        EDIT_GEOGRAPHY_TOOLTIP: "Edit",
        REMOVE_GEOGRAPHY_TOOLTIP: "Remove",
        ACTIVATE_GEOGRAPHY_TOOLTIP: "Activate",
        INACTIVATE_GEOGRAPHY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Geography Details",
        ROUTE: "/geography/:id/details",
      },
      CREATE: {
        NAME: "Add Geography",
        ROUTE: "/geography/add",
        HEADER_TITLE: "Add Geography",
      },
      EDIT: {
        NAME: "Edit Geography",
        ROUTE: "/geography/:id/edit",
        HEADER_TITLE: "Edit Geography",
      },
    },
    MASTER_DATA_GEO_SUB_CATEGORY: {
      LIST: {
        NAME: "List",
        ROUTE: "/geo-subcategory/list",
        HEADER_TITLE: "Geo Sub-Categories",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_GEO_SUB_CATEGORY: "Edit Sub Category",
        REMOVE_GEO_SUB_CATEGORY: "Remove Sub Category",
        STATUS: "Status",
        EDIT_GEO_SUB_CATEGORY_TOOLTIP: "Edit",
        REMOVE_GEO_SUB_CATEGORY_TOOLTIP: "Remove",
        ACTIVATE_GEO_SUB_CATEGORY_TOOLTIP: "Activate",
        INACTIVATE_GEO_SUB_CATEGORY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Sub Category Details",
        ROUTE: "/geo-subcategory/:id/details",
      },
      CREATE: {
        NAME: "Add Sub Category",
        ROUTE: "/geo-subcategory/add",
        HEADER_TITLE: "Add Sub Category",
      },
      EDIT: {
        NAME: "Edit Sub Category",
        ROUTE: "/geo-subcategory/:id/edit",
        HEADER_TITLE: "Edit Sub Category",
      },
    },
    MASTER_DATA_COUNTRY: {
      LIST: {
        NAME: "List",
        ROUTE: "/country/list",
        HEADER_TITLE: "Countries",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_COUNTRY: "Edit Country",
        REMOVE_COUNTRY: "Remove Country",
        STATUS: "Status",
        EDIT_COUNTRY_TOOLTIP: "Edit",
        REMOVE_COUNTRY_TOOLTIP: "Remove",
        ACTIVATE_COUNTRY_TOOLTIP: "Activate",
        INACTIVATE_COUNTRY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Country Details",
        ROUTE: "/country/:id/details",
      },
      CREATE: {
        NAME: "Add Country",
        ROUTE: "/country/add",
        HEADER_TITLE: "Add Country",
      },
      EDIT: {
        NAME: "Edit Country",
        ROUTE: "/country/:id/edit",
        HEADER_TITLE: "Edit Country",
      },
    },
    TEMPLATE_METRICS_VIEW: {
      ROUTE: "/template/:id/metrics_view",
      NAME: "Template table view",
      HEADER_TITLE: "View Rule",
      TABS: [
        {
          KEY: "rule_view",
          TAB_TITLE: "Rule View",
          HIDE_TAB: false,
        },
        {
          KEY: "table_view",
          TAB_TITLE: "Table View",
          HIDE_TAB: false,
        },
      ],
      SUCCESS: "Metrics Rule Created Successfully",
      UPDATE: "Metrics Rule Updated Successfully",
    },
    // TEMPLATE_RULE_VIEW: {
    //   ROUTE: '/template/:id/rule_view',
    //   NAME: 'Template rule view',
    //   HEADER_TITLE: "Create Rule",
    //   TABS: [
    //     {
    //       KEY: "rule_view",
    //       TAB_TITLE: "Rule View",
    //       HIDE_TAB: false,
    //     },
    //     {
    //       KEY: "ta_view",
    //       TAB_TITLE: "Table View",
    //       HIDE_TAB: false,
    //     },
    //   ],
    // },
    ARCHIVE_TEMPLATE: {
      LIST: {
        NAME: "Archive Template",
        ROUTE: "/archive/template",
        HEADER_TITLE: "Template",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_TEMPLATE: "Edit Template",
        REMOVE_TEMPLATE: "Remove Template",
        STATUS: "Status",
        EDIT_TEMPLATE_TOOLTIP: "Edit",
        REMOVE_TEMPLATE_TOOLTIP: "Remove",
        ACTIVATE_TEMPLATE_TOOLTIP: "Activate",
        INACTIVATE_TEMPLATE_TOOLTIP: "De-Activate",
        EXPORT_EXCEL_TOOLTIP: "Export",
      },
      VIEW: {
        NAME: "Template Details",
        ROUTE: "/archive/template/:id/details",
      },
      CREATE: {
        NAME: "Add Template Record",
        ROUTE: "/archive/template/add",
        HEADER_TITLE: "Add Template Record",
      },
      EDIT: {
        NAME: "Edit Template",
        ROUTE: "/archive/template/:id/edit",
        HEADER_TITLE: "Edit Template",
      },
    },
    TEMPLATE: {
      LIST: {
        NAME: "Rules",
        ROUTE: "/template",
        HEADER_TITLE: "Template",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_TEMPLATE: "Edit Template",
        REMOVE_TEMPLATE: "Remove Template",
        STATUS: "Status",
        EDIT_TEMPLATE_TOOLTIP: "Edit",
        REMOVE_TEMPLATE_TOOLTIP: "Remove",
        ACTIVATE_TEMPLATE_TOOLTIP: "Activate",
        INACTIVATE_TEMPLATE_TOOLTIP: "De-Activate",
        EXPORT_EXCEL_TOOLTIP: "Export",
      },
      VIEW: {
        NAME: "Template Details",
        ROUTE: "/template/:id/table_view",
      },
      CREATE: {
        NAME: "Create Rule",
        ROUTE: "/template/add",
        HEADER_TITLE: "Create Rule",
      },
      EDIT: {
        NAME: "Edit Rule",
        ROUTE: "/template/:id/edit",
        HEADER_TITLE: "Edit Rule",
      },
    },
    EMAIL_TEMPLATE: {
      LIST: {
        NAME: "Email Templates",
        ROUTE: "/emailTemplate",
        HEADER_TITLE: "Email Template",
        ADD_BUTTON_TITLE: "Add ",
        EDIT_BUTTON_TITLE: "Edit ",
        REMOVE_BUTTON_TITLE: "Remove",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_TEMPLATE: "Edit Email Template",
        REMOVE_TEMPLATE: "Remove Email Template",
        EDIT_TEMPLATE_TOOLTIP: "Edit",
        REMOVE_TEMPLATE_TOOLTIP: "Remove",
      },
      HEADER: {
        TITLE: "Email Templates",
        ADD_TITLE: "Add Email Template",
        EDIT_TITLE: "Edit Email Template",
      },
      SUCCESS: {
        WARNING_EMAIL_TEMPLATE:
          "Are you sure you want to remove the Email Template?",
        ADD_EMAIL_TEMPLATE: "Email Template is added successfully",
        UPDATE_EMAIL_TEMPLATE: "Email Template is updated successfully",
        DELETE_EMAIL_TEMPLATE: "Email Template is deleted successfully",
      },
      VIEW: {
        NAME: "Email Template Details",
        ROUTE: "/emailTemplate/:id/details",
      },
      CREATE: {
        NAME: "Add Email Template Record",
        ROUTE: "/emailTemplate/add",
        HEADER_TITLE: "Add Email Template Record",
      },
      EDIT: {
        NAME: "Edit Email Template",
        ROUTE: "/emailTemplate/:templateKey/edit",
        HEADER_TITLE: "Edit Email Template",
      },
    },
    SEARCH: {
      LIST: {
        NAME: "Search",
        ROUTE: "/search",
        BREADCRUMB: "Job Listing",
        HEADER_TITLE: "Search",
      },
    },
    SEARCH_TREND: {
      LIST: {
        NAME: "Search Trends",
        ROUTE: "/search-trends",
        BREADCRUMB: "Search Trends",
        HEADER_TITLE: "Search Trends",
      },
    },
    API_CLIENT_TRACER: {
      LIST: {
        NAME: "API Client Tracker",
        ROUTE: "/api-client-tracker",
        BREADCRUMB: "API Client Tracker",
        HEADER_TITLE: "API Client Tracker",
      },
      INACTIVATE_API_CONFIRM_MESSAGE:
        "Are you sure want to de-activate the API for user?",
      ACTIVATE_COMPANY_CONFIRM_MESSAGE:
        "Are you sure want to activate the API for user?",
      ACTIVATE_USER: {
        SUCCESS: "User activated successfully",
      },
      IN_ACTIVATE_USER: {
        SUCCESS: "User inactivated successfully",
      },
      INACTIVATE_CLIENT_API_CONFIRM_MESSAGE:
        "Are you sure want to de-activate the Client API Credentials Access?",
      ACTIVATE_COMPANY_CONFIRM_MESSAGE:
        "Are you sure want to activate the Client API Credentials Access?",
    },
    MANAGE_FILE_UPLOADS: {
      NAME: "File Uploads",
      ROUTE: "/file/list",
      TABS: [
        {
          KEY: "content",
          TAB_TITLE: "Contents (PPT)",
          HIDE_TAB: false,
        },
        // {
        //   KEY: "metric",
        //   TAB_TITLE: "Metrics (Excel)",
        //   HIDE_TAB: false,
        // },
        {
          KEY: "metricV2",
          TAB_TITLE: "MetricsV2 (Excel)",
          HIDE_TAB: false,
        },
      ],
    },
    CHANGE_PASSWORD: {
      NAME: "Change Password",
      ROUTE: "/change-password",
    },
    EDIT_PREFERENCES: {
      NAME: "Edit Preferences",
      ROUTE: "/edit-preferences",
    },
    MY_WATCH_LIST: {
      NAME: "Edit Preferences",
      ROUTE: "/edit-preferences",
    },
    DASHBOARD: {
      NAME: "Dashboard",
      ROUTE: "/company/list",
    },
    SEGMENTS: {
      NAME: "Segments",
      ROUTE: "/segment/list",
    },
    COMAPRISION_ANALYTICS: {
      NAME: "Comparision Analytics",
      ROUTE: "/comparision-analytics",
    },
    CUSTOM_ANALYTICS: {
      NAME: "Custom Analytics",
      ROUTE: "/custom-analytics",
    },
    MY_BOARDS: {
      NAME: "My Boards",
      ROUTE: "/my-boards",
    },
    TBR_CALENDAR: {
      NAME: "TBR Calendar",
      ROUTE: "/tbr-calendar",
    },
    WEBINARS: {
      NAME: "Webinars",
      ROUTE: "/webinars",
    },
    LOGOUT: {
      NAME: "Logout",
      ROUTE: "/logout",
    },
    CLIENT: {
      LIST: {
        NAME: "List",
        ROUTE: "/client/list",
        HEADER_TITLE: "Clients",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_CLIENT: "Edit Client",
        REMOVE_CLIENT: "Remove Client",
        STATUS: "Status",
        MANAGE_USERS: "Manage Users",
        MANAGE_USERS_TOOLTIP: "Manage Users",
        EDIT_CLIENT_TOOLTIP: "Edit",
        REMOVE_CLIENT_TOOLTIP: "Remove",
        ACTIVATE_CLIENT_TOOLTIP: "Activate",
        INACTIVATE_CLIENT_TOOLTIP: "De-Activate",
        UPDATE_CLIENT_API: "Update Client API",
        USER_API_CREDENTIAL_TOOLTIP : "USER_API_CREDENTIAL_TOOLTIP"
      },
      CREATE: {
        NAME: "Add Client",
        ROUTE: "/client/add",
        HEADER_TITLE: "Add Client",
      },
      EDIT: {
        NAME: "Edit Client",
        ROUTE: "/client/:id/edit",
        HEADER_TITLE: "Edit Client",
      },
      TABS: [
        {
          KEY: "all-clients",
          TAB_TITLE: "All Clients",
          HIDE_TAB: false,
        },
        {
          KEY: "assigned-clients",
          TAB_TITLE: "Assigned Clients",
          HIDE_TAB: false,
        },
      ],
    },

    CLIENT_USER: {
      LIST: {
        NAME: "Users",
        ROUTE: "/client/:clientId/user/list",
        HEADER_TITLE: "Users",
        ADD_BUTTON_TITLE: "Add",
        MULTIPLE_USERS: "Upload",
        MULTIPLE_USERS_TEMPLATE: "Download Template",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_USER: "Edit User",
        REMOVE_USER: "Remove User",
        STATUS: "Status",
        RESEND_EMAIL_VERIFICATION: "Resend email verification",
        ACTIVATE_USER_TOOLTIP: "Activate",
        INACTIVATE_USER_TOOLTIP: "De-Activate",
        EDIT_USER_TOOLTIP: "Edit",
        REMOVE_USER_TOOLTIP: "Remove",
        RESEND_EMAIL_VERIFICATION_TOOLTIP: "Resend Email Verification Link",
        COPY_EMAIL_VERIFICATION_TOOLTIP: "Copy Email Verification Link",
        VALIDATE_USERS: "Validate Users",
        VALIDATE_USER_TOOL_TIP: "User Bounce Validation",
        RESET_PASSWORD_TITLE: "Send Reset Password Link",
        API_ACTIVATION_TOGGLE: "Activate User API",
      },

      CREATE: {
        NAME: "Add User",
        ROUTE: "/client/:clientId/user/add",
        HEADER_TITLE: "Add User",
      },
      EDIT: {
        NAME: "Edit User",
        ROUTE: "/client/:clientId/user/:id/edit",
        HEADER_TITLE: "Edit User",
      },
      TABS: [
        {
          KEY: "all-users",
          TAB_TITLE: "All Users",
          HIDE_TAB: false,
        },
        {
          KEY: "assigned-users",
          TAB_TITLE: "Assigned Users",
          HIDE_TAB: false,
        },
      ],
    },

    CLIENT_EMAIL: {
      LIST: {
        NAME: "Email Bounce List",
        ROUTE: "/client/emailBounceUsers",
        HEADER_TITLE: "Email Bounce List",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "Mark as In-Active",
        EDIT_USER: "Edit User",
        REMOVE_USER: "Remove User",
        REMOVE_CLIENT_TOOLTIP: "Remove",
        STATUS: "Status",
        ACTIVATE_USER_TOOLTIP: "Activate",
        INACTIVATE_USER_TOOLTIP: "De-Activate",
        EDIT_USER_TOOLTIP: "Edit",
        REMOVE_USER_TOOLTIP: "Remove",
      },
    },
    CLIENT_SUPPRESSION_EMAIL: {
      LIST: {
        NAME: "Email Suppression List",
        ROUTE: "/client/emailSuppressionList",
        HEADER_TITLE: "Email Suppression List",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Mark as Active",
        EDIT_USER: "Edit User",
        REMOVE_USER: "Remove User",
        REMOVE_CLIENT_TOOLTIP: "Remove",
        STATUS: "Status",
        ACTIVATE_USER_TOOLTIP: "Activate",
        INACTIVATE_USER_TOOLTIP: "De-Activate",
        EDIT_USER_TOOLTIP: "Edit",
        REMOVE_USER_TOOLTIP: "Remove",
      },
    },
    ACCOUNT_MANAGERS: {
      LIST: {
        NAME: "Account Managers List",
        ROUTE: "/client/accountManagers",
        HEADER_TITLE: "Account Managers List",
      },
      CREATE: {
        NAME: "Add Account Manager",
        ROUTE: "/accountManager/add",
        HEADER_TITLE: "Add Account Manager",
      },
      EDIT: {
        NAME: "Edit Account Manager",
        ROUTE: "/accountManager/:id/edit",
        HEADER_TITLE: "Edit Account Manager",
      },
    },
    REPORTS: {
      LIST: {
        ROUTE: "/reports",
        NAME: "Dashboard",
        HEADER_TITLE: "Reports",
      },
      LIST2: {
        ROUTE: "/reports/client/:id",
        NAME: "Client Level",
        HEADER_TITLE: "Reports",
      },
      LIST3: {
        ROUTE: "/reports/user/:id",
        NAME: "User Level",
        HEADER_TITLE: "Reports",
        TABS: [
          {
            KEY: "over-view",
            TAB_TITLE: "Overview",
            HIDE_TAB: false,
          },
          {
            KEY: "time-spent",
            TAB_TITLE: "Time Spent",
            HIDE_TAB: false,
          },
          {
            KEY: "downloads",
            TAB_TITLE: "Downloads",
            HIDE_TAB: false,
          },
        ],
      },
      LIST4: {
        ROUTE: "/reports/activeusers/:id",
        NAME: "Client Users",
        HEADER_TITLE: "Reports",
      },
      LIST5: {
        NAME: "In-Active Users",
      },
      CHARTS: {
        SESSION_PAGE_VIEWS: {
          ID: "SESSION_PAGE_VIEWS",
          TYPE: "STACKED AREA",
          LEGEND_ID: "SESSION_PAGE_VIEWS_LEGEND",
        },
        MODULE_WISE_PAGE_VIEWS: {
          ID: "MODULE_WISE_PAGE_VIEWS",
          TYPE: "COLUMN",
          LEGEND_ID: "MODULE_WISE_PAGE_VIEWS_LEGEND",
        },
        AXIS_TYPES: {
          DATE: "Date",
          CATEGORY: "Category",
        },
        SERIES_TYPES: {
          LINE: "Line",
          COLUMN: "Column",
        },
        BOARDS_CHART: {
          ID: "BOARDS_CHART",
          TYPE: "LINE",
          LEGEND_ID: "BOARDS_CHART_LEGEND",
        },
        TIME_ON_BOARDS_CHART: {
          ID: "TIME_ON_BOARDS_CHART",
          TYPE: "LINE",
          LEGEND_ID: "TIME_ON_BOARDS_CHART_LEGEND",
        },
        VISUALIZATION_CHART: {
          ID: "VISUALIZATION_CHART",
          TYPE: "LINE",
          LEGEND_ID: "VISUALIZATION_CHART_LEGEND",
        },
        TIME_ON_TEMPLATES_CHART: {
          ID: "TIME_ON_TEMPLATES_CHART",
          TYPE: "LINE",
          LEGEND_ID: "TIME_ON_TEMPLATES_CHART_LEGEND",
        },
      },
      BOARDS: {
        ROUTE: "/reports/boards",
        NAME: "Boards",
        HEADER_TITLE: "Reports",
        TABS: [
          {
            KEY: "group-by-boards",
            TAB_TITLE: "Group By Boards",
            HIDE_TAB: false,
          },
          {
            KEY: "user-board-views",
            TAB_TITLE: "User Board Views",
            HIDE_TAB: false,
          },
          {
            KEY: "board-created-by",
            TAB_TITLE: "Board Created By",
            HIDE_TAB: false,
          },
        ],
      },
      VISUALIZATION_TEMPLATES: {
        ROUTE: "/reports/visualizationTemplates",
        NAME: "Visualization Templates",
        NAME1: "Visualizations",
        HEADER_TITLE: "Reports",
        TABS: [
          {
            KEY: "group-by-templates",
            TAB_TITLE: "Group By Visualizations",
            HIDE_TAB: false,
          },
          {
            KEY: "user-template-views",
            TAB_TITLE: "User Visualization Views",
            HIDE_TAB: false,
          },
          {
            KEY: "template-created-by",
            TAB_TITLE: "Visualization Created By",
            HIDE_TAB: false,
          },
        ],
      },
      REPORTS_TBRI: {
        ROUTE: "/reports/tbriReports",
        NAME: "TBRI Boards & Visualizations",
        HEADER_TITLE: "TBRI Boards & Visualizations",
        TABS: [
          {
            KEY: "tbri-boards",
            TAB_TITLE: "TBRI Boards",
            HIDE_TAB: false,
          },
          {
            KEY: "tbri-template",
            TAB_TITLE: "TBRI Visualizations",
            HIDE_TAB: false,
          },
        ],
      },
    },
    TBRI_USER: {
      LIST: {
        NAME: "Users",
        ROUTE: "/user/list",
        HEADER_TITLE: "Users",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_USER: "Edit User",
        REMOVE_USER: "Remove User",
        STATUS: "Status",
        RESEND_EMAIL_VERIFICATION: "Resend email verification",
        ACTIVATE_USER_TOOLTIP: "Activate",
        INACTIVATE_USER_TOOLTIP: "De-Activate",
        EDIT_USER_TOOLTIP: "Edit",
        REMOVE_USER_TOOLTIP: "Remove",
        RESEND_EMAIL_VERIFICATION_TOOLTIP: "Resend Email Verification Link",
      },
      CREATE: {
        NAME: "Add User",
        ROUTE: "/user/add",
        HEADER_TITLE: "Add User",
      },
      EDIT: {
        NAME: "Edit User",
        ROUTE: "/user/:id/edit",
        HEADER_TITLE: "Edit User",
      },
    },
    CLIENT_PROJECT: {
      LIST: {
        NAME: "List",
        ROUTE: "/client/:clientId/project/list",
        HEADER_TITLE: "Projects",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_PROJECT: "Edit Project",
        REMOVE_PROJECT: "Remove Project",
        STATUS: "Status",
        ACTIVATE_PROJECT_TOOLTIP: "Activate",
        INACTIVATE_PROJECT_TOOLTIP: "De-Activate",
        EDIT_PROJECT_TOOLTIP: "Edit",
        REMOVE_PROJECT_TOOLTIP: "Remove",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        COPY_PROJECT_TOOLTIP: "Copy",
        COPY_PROJECT: "Copy Project",
      },
      CREATE: {
        NAME: "Add Project",
        ROUTE: "/client/:clientId/project/add",
        HEADER_TITLE: "Add Project",
      },
      EDIT: {
        NAME: "Edit Project",
        ROUTE: "/client/:clientId/project/:id/edit",
        HEADER_TITLE: "Edit Project",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/client/:clientId/project/:id/manage",
        HEADER_TITLE: "Edit Project",
      },
    },
    TAXONOMY: {
      LIST: {
        NAME: "Taxonomy",
        ROUTE: "/taxonomy/list",
        HEADER_TITLE: "Taxonomy",
      },
    },
    CUSTOMER_STUDIES: {
      LIST: {
        NAME: "List",
        ROUTE: "/customer-studies/list",
        HEADER_TITLE: "Customer Studies",
      },
      CREATE: {
        NAME: "Add Customer Study",
        ROUTE: "/customer-studies/add",
        HEADER_TITLE: "Add Customer Study",
      },
      EDIT: {
        NAME: "Edit Customer Study",
        ROUTE: "/customer-studies/:id/edit",
        HEADER_TITLE: "Edit Customer Study",
      },
      MANAGE: {
        NAME: "Manage Customer Studies",
        ROUTE: "/customer-studies/:id/manage",
        HEADER_TITLE: "Details page",
      },
    },
    MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/customer-studies-drafts",
    },
    MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/customer-studies-track-submissions",
    },
    MANAGE_CONTENT_CUSTOMER_STUDY_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/customer-study-transaction/:id/details",
    },

    ECOSYSTEM: {
      LIST: {
        NAME: "List",
        ROUTE: "/ecosystem/list",
        HEADER_TITLE: "Ecosystems",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_ECOSYSTEM: "Edit Ecosystem",
        REMOVE_ECOSYSTEM: "Remove Ecosystem",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_ECOSYSTEM_TOOLTIP: "Edit",
        REMOVE_ECOSYSTEM_TOOLTIP: "Remove",
        ACTIVATE_ECOSYSTEM_TOOLTIP: "Activate",
        INACTIVATE_ECOSYSTEM_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Ecosystem Details",
        ROUTE: "/ecosystem/:id/details",
      },
      CREATE: {
        NAME: "Add Ecosystem",
        ROUTE: "/ecosystem/add",
        HEADER_TITLE: "Add Ecosystem",
      },
      EDIT: {
        NAME: "Edit Ecosystem",
        ROUTE: "/ecosystem/:id/edit",
        HEADER_TITLE: "Edit Ecosystem",
      },
      MANAGE_CONTENT: {
        NAME: "List",
        ROUTE: "/ecosystem/:id/manage",
      },
      MANAGE: {
        NAME: "Manage Content",
        ROUTE: "/ecosystem/:id/manage",
        HEADER_TITLE: "Manage Content",
      },
    },
    MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/ecosystem-drafts",
    },
    MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/ecosystem-track-submissions",
    },
    MANAGE_CONTENT_ECOSYSTEM_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/ecosystem-transaction/:id/details",
    },

    DOWNLOAD: {
      LIST: {
        NAME: "File Upload",
        ROUTE: "/file/list",
        HEADER_TITLE: "File Upload",
        ADD_BUTTON_TITLE: "Upload",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_DOWNLOAD: "Edit File",
        REMOVE_DOWNLOAD: "Remove File",
        STATUS: "Status",
        EDIT_DOWNLOAD_TOOLTIP: "Edit",
        REMOVE_DOWNLOAD_TOOLTIP: "Remove",
        DOWNLOAD_TOOLTIP: "Download",
        DOWNLOAD: "Document",
      },
      CREATE: {
        NAME: "Upload File",
        ROUTE: "/file/add",
        HEADER_TITLE: "Upload File",
      },
      EDIT: {
        NAME: "Edit File",
        ROUTE: "/file/:id/edit",
        HEADER_TITLE: "Edit File",
      },
    },
    MANAGE_CONTENT_METRIC_DRAFTS: {
      NAME: "My Drafts",
      ROUTE: "/metric-drafts",
    },
    MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS: {
      NAME: "Track Submissions",
      ROUTE: "/metric-track-submissions",
    },
    MANAGE_CONTENT_METRIC_TRANSACTION_DETAILS: {
      NAME: "Transaction Details",
      ROUTE: "/metric/transaction/:id/:state/details",
    },
    METRIC_TRANSACTION: {
      LIST: {
        NAME: "List",
        ROUTE: "/template/list",
        HEADER_TITLE: "Transaction Details",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_TEMPLATE: "Edit Template",
        REMOVE_TEMPLATE: "Remove Template",
        STATUS: "Status",
        EDIT_TEMPLATE_TOOLTIP: "Edit",
        REMOVE_TEMPLATE_TOOLTIP: "Remove",
        ACTIVATE_TEMPLATE_TOOLTIP: "Activate",
        INACTIVATE_TEMPLATE_TOOLTIP: "De-Activate",
        EXPORT_EXCEL_TOOLTIP: "Export",
      },
      VIEW: {
        NAME: "Template Details",
        ROUTE: "/template/:id/details",
      },
      CREATE: {
        NAME: "Add Template Record",
        ROUTE: "/template-record/add",
        HEADER_TITLE: "Add Template Record",
      },
      EDIT: {
        NAME: "Edit Template",
        ROUTE: "/template/:id/edit",
        HEADER_TITLE: "Edit Template",
      },
    },
    METRIC_RECORD: {
      LIST: {
        NAME: "List",
        ROUTE: "/file/:id/metrics",
        HEADER_TITLE: "Metrics",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_TEMPLATE: "Edit Template",
        REMOVE_TEMPLATE: "Remove Template",
        STATUS: "Status",
        EDIT_TEMPLATE_TOOLTIP: "Edit",
        REMOVE_TEMPLATE_TOOLTIP: "Remove",
        ACTIVATE_TEMPLATE_TOOLTIP: "Activate",
        INACTIVATE_TEMPLATE_TOOLTIP: "De-Activate",
        EXPORT_EXCEL_TOOLTIP: "Export",
      },
      VIEW: {
        NAME: "Template Details",
        ROUTE: "/template/:id/details",
      },
      CREATE: {
        NAME: "Add Template Record",
        ROUTE: "/template-record/add",
        HEADER_TITLE: "Add Template Record",
      },
      EDIT: {
        NAME: "Edit Template",
        ROUTE: "/template/:id/edit",
        HEADER_TITLE: "Edit Template",
      },
    },
    METRIC_RECORD_V2: {
      LIST: {
        NAME: "List",
        ROUTE: "/file/:id/metrics_V2",
        HEADER_TITLE: "Transaction Details",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_TEMPLATE: "Edit Template",
        REMOVE_TEMPLATE: "Remove Template",
        STATUS: "Status",
        EDIT_TEMPLATE_TOOLTIP: "Edit",
        REMOVE_TEMPLATE_TOOLTIP: "Remove",
        ACTIVATE_TEMPLATE_TOOLTIP: "Activate",
        INACTIVATE_TEMPLATE_TOOLTIP: "De-Activate",
        EXPORT_EXCEL_TOOLTIP: "Export",
      },
      VIEW: {
        NAME: "Template Details",
        ROUTE: "/template_v2/:id/details",
      },
      CREATE: {
        NAME: "Add Template Record",
        ROUTE: "/template-record/add",
        HEADER_TITLE: "Add Template Record",
      },
      EDIT: {
        NAME: "Edit Template",
        ROUTE: "/template/:id/edit",
        HEADER_TITLE: "Edit Template",
      },
    },
    MANAGE_METRIC_RULES_AND_THRESHOLDS: {
      NAME: "Rules and Thresholds",
      ROUTE: "/rules-thresholds",
      TABS: [
        {
          KEY: "rules",
          TAB_TITLE: "Rules",
          HIDE_TAB: false,
        },
        {
          KEY: "thresholds",
          TAB_TITLE: "Thresholds",
          HIDE_TAB: false,
        },
      ],
    },
    METRIC_RULE: {
      LIST: {
        NAME: "List",
        ROUTE: "/metric-rule/list",
        HEADER_TITLE: "Rules",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_RULE: "Edit Rule",
        REMOVE_RULE: "Remove Rule",
        STATUS: "Status",
        EDIT_RULE_TOOLTIP: "Edit",
        REMOVE_RULE_TOOLTIP: "Remove",
        ACTIVATE_RULE_TOOLTIP: "Activate",
        INACTIVATE_RULE_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Rule Details",
        ROUTE: "/metric-rule/:id/details",
      },
      CREATE: {
        NAME: "Add Rule",
        ROUTE: "/metric-rule/add",
        HEADER_TITLE: "Add Rule",
      },
      EDIT: {
        NAME: "Edit Rule",
        ROUTE: "/metric-rule/:id/edit",
        HEADER_TITLE: "Edit Rule",
      },
    },
    ACCESS_DENIED: {
      NAME: "Access Denied",
      ROUTE: "/403",
    },
  },
  RESET_PASSWORD: {
    HEADER_TITLE: "Reset Password",
    SUCCESS: "Password changed successfully.",
  },
  CHANGE_PASSWORD: {
    HEADER_TITLE: "Change Password",
    SUCCESS:
      "Password changed successfully. You will be logged out in a second",
  },
  SET_PASSWORD: {
    HEADER_TITLE: "Set Password",
    SUCCESS: "Password has set successfully.",
  },
  VERIFY_EMAIL: {
    SUCCESS:
      "E-mail has been verified successfully! \n \n Please reset your password.",
    SUCCESS2:
      "E-mail already verified successfully! \n \n Please reset your password.",
  },
  PAGINATION: {
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_PAGE_NO: 1,
    // PAGE_SIZE_OPTIONS: [10, 25, 50, 100],
    PAGE_SIZE_OPTIONS: [10, 25],
    NO_RECORDS_FOUND: "No Records Found",
    DEFAULT_SORT_COLUMN: "updated_at",
    DEFAULT_SORT_ORDER: "desc",
    COMPANY_DEFAULT_SORT_ORDER: "desc",
    COMPANY_DEFAULT_SORT_COLUMN: "first_name",
    FILE_DEFAULT_SORT_COLUMN: "created_at",
    COMPANIES_DEFAULT_SORT_COLUMN: "updated_at",
    BENCHMARK_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    COMPANY_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    PROJECT_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    MARKETFORECAST_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    MARKETLANDSCAPE_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    CUSTOMERSTUDY_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    ECOSYSTEM_TRACK_DEFAULT_SORT_COLUMN: "updated_at",
    TEMPLATE_DEFAULT_SORT_COLUMN: "created_at",
    EMAILBOUNCELIST_DEFAULT_SORT_COLUMN: "updated_at",
    DEFAULT_REPORTS3_SORT_COLUMN: "created_at",
    SORT_COLUMN_NAME: "name",
    DEFAULT_REPORTS4_SORT_COLUMN: "timeStamp",
    DEFAULT_REPORTS_GROUP_BY_BOARDS_SORT_COLUMN: "timespent",
  },
  TBRI_METRIC_CODES: {
    REVENUE: "REV",
    OPERATING_INCOME: "OPINC",
  },
  COMPANY_DETAILS: {
    NO_CONTENT_FOUND: "No content found",
    CONTENT_PLACE_HOLDER: "[Please add content here]",
    TITLE_PLACE_HOLDER: "[Please add title here]",
    NO_IMAGE_FOUND_PATH: "/images/no-image-found.png",
    NO_VIDEO_FOUND_PATH: "/videos/no-video-found.mp4",
    IMAGE_LOADING: "/images/image-loading.png",
    MAX_IMAGE_SIZE: 1000000,
    MAX_PPT_SIZE: 100000000,
    MAX_VIDEO_SIZE: 1000000000,
    HEADLINE_PLACE_HOLDER: "[Headline]",
    NEWS_PLACE_HOLDER: "[Please add news here]",
  },
  ACL: {
    PERMISSION_KEY: "permissions",
    ENTITY_TYPE: {
      NAVIGATION: "TBR-NAVIGATION",
      BUTTON: "TBR-BUTTON",
      DIV: "TBR-DIV",
      SPAN: "TBR-SPAN",
    },
    COMPANY_RESOURCE: {
      TITLE: "company",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    CLIENT_RESOURCE: {
      TITLE: "client",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE_USERS: "list",
        TABS: "tabs",
        EDIT_DETAILS: "edit",
      },
    },
    CLIENT_USER_RESOURCE: {
      TITLE: "clientuser",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        EDIT_DETAILS: "edit",
        DOWNLOAD: "export",
      },
    },
    CLIENT_EMAIL_RESOURCE: {
      TITLE: "clientemail",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
      },
    },
    TAXONOMY_RESOURCE: {
      TITLE: "taxonomy",
      ACTIONS: {
        LIST: "list",
      },
    },
    TBRI_USER_RESOURCE: {
      TITLE: "user",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        IN_ACTIVE: "inActive",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "add",
      },
    },
    PROJECT_RESOURCE: {
      TITLE: "project",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
        COPY: "copy",
      },
    },
    COMPANY_SEGMENT_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },
    CALENDAR_RESOURCE: {
      TITLE: "calendar",
      ACTIONS: {
        LIST: "list",
      },
    },

    BENCHMARK_RESOURCE: {
      TITLE: "benchmark",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    BENCHMARK_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },
    SPECIAL_REPORT_RESOURCE: {
      TITLE: "special_report",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    CUSTOMER_STUDY_RESOURCE: {
      TITLE: "customer_study",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    SPECIAL_REPORT_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },
    DOWNLOADS_RESOURCE: {
      TITLE: "file",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        DOWNLOAD: "upload",
        UPLOAD: "update",
      },
    },
    MARKET_FORECAST_RESOURCE: {
      TITLE: "market_forecast",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    MARKET_FORECAST_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },
    MARKET_LANDSCAPE_RESOURCE: {
      TITLE: "market_landscape",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    MARKET_LANDSCAPE_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },
    CUSTOMER_STUDIES_RESOURCE: {
      TITLE: "customer_study",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    CUSTOMER_STUDIES_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },

    ECOSYSTEM_RESOURCE: {
      TITLE: "ecosystem",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    ECOSYSTEM_AUTHOR_RESOURCE: {
      TITLE: "author",
      ACTIONS: {
        DELETE: "delete",
        CREATE: "create",
      },
    },

    METRIC_TYPE_RESOURCE: {
      TITLE: "metrictype",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    METRIC_UNIT_RESOURCE: {
      TITLE: "metricunit",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    METRIC_DATA_RESOURCE: {
      TITLE: "metric",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    METRIC_RULE: {
      TITLE: "metricrule",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
        UPDATE_STATUS: "update_status",
      },
    },
    LOB_RESOURCE: {
      TITLE: "segment",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    LOB_SUB_CATEGORY_RESOURCE: {
      TITLE: "lob_subcategory",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    VERTICAL_RESOURCE: {
      TITLE: "vertical",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    VERTICAL_CATEGORY_RESOURCE: {
      TITLE: "vertical_category",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    VERTICAL_SUB_CATEGORY_RESOURCE: {
      TITLE: "vertical_category",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    GEOGRAPHY_RESOURCE: {
      TITLE: "geography",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    GEO_SUBCATEGORY_RESOURCE: {
      TITLE: "geosubcategory",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    COUNTRY_RESOURCE: {
      TITLE: "country",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
      },
    },
    TEMPLATE_RESOURCE: {
      TITLE: "templaterecord",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        EXPORT: "export",
      },
    },
    HELP_PAGE_RESOURCE: {
      TITLE: "Help",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "delete",
        STATUS: "update",
        CREATE: "create",
      },
    },
    EMAIL_TEMPLATE_RESOURCE: {
      TITLE: "emailTemplate",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        EXPORT: "export",
      },
    },
    REPORTS_USER_RESOURCE: {
      TITLE: "reports_user",
      TAB: "actions",
      ACTIONS: {
        DELETE: "delete",
      },
    },
    ACCOUNT_MANAGERS_RESOURCE: {
      TITLE: "account_managers",
      ACTIONS: {
        LIST: "list",
        EDIT: "assignclients",
      },
    },
  },
  CLIENT: {
    CREATE: {
      SUCCESS: "Client created successfully",
      SEGMENTS_MANDATORY: "Please select atleast one Segment",
      NO_COMPANY_SEGMENTS_SELECTED: "No Segments selected",
      BENCHMARKS_MANDATORY: "Please select atleast one Benchmark",
      NO_BENCHMARKS_SELECTED: "No Benchmarks selected",
      MARKET_FORECASTS_MANDATORY: "Please select atleast one Market Forecast",
      NO_MARKET_FORECAST_SELECTED: "No Market Forecasts selected",
      MARKET_LANDSCAPES_MANDATORY: "Please select atleast one Market Landscape",
      NO_MARKET_LANDSCAPE_SELECTED: "No Market Landscape is selected",
      NO_SPECIAL_REPORT_SELECTED: "No Special Report is selected",
      NO_CUSTOMER_STUDIES_SELECTED: "No Customer Studies is selected",
      NO_ECOSYSTEM_SELECTED: "No Ecosystem is selected",
    },
    EDIT: {
      SUCCESS:
        "Client details updated successfully and Client Status is Active",
    },
    REMOVE: {
      SUCCESS: " Client deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: " Client activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Client inactivated successfully",
    },
    INACTIVATE_CLIENT_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Client?",
    ACTIVATE_CLIENT_CONFIRM_MESSAGE:
      "Are you sure want to activate the Client?",
    REMOVE_CLIENT_CONFIRM_MESSAGE: "Are you sure want to remove the Client?",
    REMOVE_SEGMENT_CONFIRM_MESSAGE: "Are you sure want to remove the Segment?",
    REMOVE_BENCHMARK_SEGMENT_CONFIRM_MESSAGE:
      "Are you sure want to remove Segment?",
    REMOVE_BENCHMARK_CONFIRM_MESSAGE: "Are you sure want to remove Benchmark?",
    REMOVE_MARKET_FORECAST_SEGMENT_CONFIRM_MESSAGE:
      "Are you sure want to remove Segment?",
    REMOVE_MARKET_FORECAST_CONFIRM_MESSAGE:
      "Are you sure want to remove Market Forecast?",
    REMOVE_MARKET_LANDSCAPE_SEGMENT_CONFIRM_MESSAGE:
      "Are you sure want to remove Segment?",
    REMOVE_MARKET_LANDSCAPE_CONFIRM_MESSAGE:
      "Are you sure want to remove Market Landscape?",
    REMOVE_SPECIAL_REPORT_SEGMENT_CONFIRM_MESSAGE:
      "Are you sure want to remove Segment?",
    REMOVE_SPECIAL_REPORT_CONFIRM_MESSAGE:
      "Are you sure want to remove Special Report?",
    REMOVE_CUSTOMER_STUDIES_CONFIRM_MESSAGE:
      "Are you sure want to remove Customer Studies?",
    REMOVE_ECOSYSTEM_SEGMENT_CONFIRM_MESSAGE:
      "Are you sure want to remove Segment?",
    REMOVE_ECOSYSTEM_CONFIRM_MESSAGE: "Are you sure want to remove Ecosystem?",
  },
  CLIENT_USER: {
    CREATE: {
      SUCCESS: "User created successfully",
    },
    EDIT: {
      SUCCESS: "User details updated successfully",
    },
    ERROR: {
      SUCCESS: "Email already exists",
    },
    REMOVE: {
      SUCCESS: "User deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "User activated successfully",
    },
    BULK_ACTIVATE: {
      SUCCESS: "User(s) activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "User inactivated successfully",
    },
    BULK_IN_ACTIVATE: {
      SUCCESS: "User(s) de-activated successfully",
    },
    INACTIVATE_USER_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the User?",
    ACTIVATE_USER_CONFIRM_MESSAGE: "Are you sure want to activate the User?",
    REMOVE_USER_CONFIRM_MESSAGE: "Are you sure want to remove the User?",
    REMOVE_BULK_USER_CONFIRM_MESSAGE:
      "Are you sure want to remove the User(s)?",
  },
  CLIENT_EMAIL: {
    CREATE: {
      SUCCESS: "Email Bounce User created successfully",
    },
    EDIT: {
      SUCCESS: "Email Bounce User updated successfully",
    },
    REMOVE: {
      SUCCESS: "Email Bounce User deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Email Bounce User activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Email Bounce User(s) inactivated successfully",
    },
    REMOVE_USER_CONFIRM_MESSAGE: "Are you sure want to remove the User?",
    INACTIVATE_USER_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the User(s)?",
    ACTIVATE_USER_CONFIRM_MESSAGE: "Are you sure want to activate the User?",
  },
  TBRI_USER: {
    CREATE: {
      SUCCESS: "User created successfully",
    },
    EDIT: {
      SUCCESS: "User details updated successfully",
    },
    REMOVE: {
      SUCCESS: "User deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "User activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "User inactivated successfully",
    },
    RESEND_EMAIL_VERIFICATION: {
      SUCCESS: "Verification mail has been sent.",
    },
    INACTIVATE_USER_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the User?",
    ACTIVATE_USER_CONFIRM_MESSAGE: "Are you sure want to activate the User?",
    REMOVE_USER_CONFIRM_MESSAGE: "Are you sure want to remove the User?",
    REMOVE_SEGMENT_CONFIRM_MESSAGE: "Are you sure want to remove the Segment?",
  },
  CLIENT_PROJECT: {
    CREATE: {
      SUCCESS: "Project created successfully",
    },
    EDIT: {
      SUCCESS: "Project details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Project deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Project activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Project inactivated successfully",
    },
    COPY: {
      SUCCESS: "Project copied successfully",
    },
    INACTIVATE_PROJECT_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Project?",
    ACTIVATE_PROJECT_CONFIRM_MESSAGE:
      "Are you sure want to activate the Project?",
    REMOVE_PROJECT_CONFIRM_MESSAGE: "Are you sure want to remove the Project?",
    COPY_PROJECT_CONFIRM_MESSAGE: "Are you sure want to copy the Project?",
  },
  COMPANY: {
    CREATE: {
      SUCCESS: "Company created successfully",
    },
    EDIT: {
      SUCCESS: "Company details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Company deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Company activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Company inactivated successfully",
    },
    INACTIVATE_COMPANY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Company?",
    ACTIVATE_COMPANY_CONFIRM_MESSAGE:
      "Are you sure want to activate the Company?",
    REMOVE_COMPANY_CONFIRM_MESSAGE: "Are you sure want to remove the Company?",
  },
  BENCHMARK: {
    CREATE: {
      SUCCESS: "Benchmark created successfully",
    },
    EDIT: {
      SUCCESS: "Benchmark details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Benchmark deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Benchmark activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Benchmark inactivated successfully",
    },
    INACTIVATE_BENCHMARK_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Benchmark?",
    ACTIVATE_BENCHMARK_CONFIRM_MESSAGE:
      "Are you sure want to activate the Benchmark?",
    REMOVE_BENCHMARK_CONFIRM_MESSAGE:
      "Are you sure want to remove the Benchmark?",
  },
  SPECIAL_REPORT: {
    CREATE: {
      SUCCESS: "Special report created successfully",
    },
    EDIT: {
      SUCCESS: "Special report details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Special report deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Special report activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Special report inactivated successfully",
    },
    INACTIVATE_SPECIAL_REPORT_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Special report?",
    ACTIVATE_SPECIAL_REPORT_CONFIRM_MESSAGE:
      "Are you sure want to activate the Special report?",
    REMOVE_SPECIAL_REPORT_CONFIRM_MESSAGE:
      "Are you sure want to remove the Special report?",
  },
  CUSTOMER_STUDY: {
    CREATE: {
      SUCCESS: "Customer Study created successfully",
    },
    EDIT: {
      SUCCESS: "Customer Study details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Customer Study deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Customer Study activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Customer Study inactivated successfully",
    },
    INACTIVATE_CUSTOMER_STUDY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Customer Study?",
    ACTIVATE_CUSTOMER_STUDY_CONFIRM_MESSAGE:
      "Are you sure want to activate the Customer Study?",
    REMOVE_CUSTOMER_STUDY_CONFIRM_MESSAGE:
      "Are you sure want to remove the Customer Study?",
  },
  ECOSYSTEM: {
    CREATE: {
      SUCCESS: "Ecosystem created successfully",
    },
    EDIT: {
      SUCCESS: "Ecosystem details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Ecosystem deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Ecosystem activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Ecosystem inactivated successfully",
    },
    INACTIVATE_ECOSYSTEM_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Ecosystem?",
    ACTIVATE_ECOSYSTEM_CONFIRM_MESSAGE:
      "Are you sure want to activate the Ecosystem?",
    REMOVE_ECOSYSTEM_CONFIRM_MESSAGE:
      "Are you sure want to remove the Ecosystem?",
  },
  MODULE_NAMES: {
    SPECIAL_REPORT: "SPECIAL_REPORT",
    BENCHMARK: "BENCHMARK",
    ECOSYSTEM: "ECOSYSTEM",
    CUSTOMER_STUDY: "CUSTOMER_STUDY",
    MARKET_FORECAST: "MARKET_FORECAST",
    MARKET_LANDSCAPE: "MARKET_LANDSCAPE",
    PROJECT: "PROJECT",
  },
  FILE_UPLOAD: {
    CREATE: {
      SUCCESS: "File uploaded successfully",
    },
    EDIT: {
      SUCCESS: "File details updated successfully",
    },
    REMOVE: {
      SUCCESS: "File deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "File details activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "File details inactivated successfully",
    },
    REMOVE_FILE_UPLOAD_CONFIRM_MESSAGE: "Are you sure want to remove the File?",
    MAPPING_TYPES: [
      {
        id: "company_segment",
        name: "Company Research",
        is_selected: false,
      },
      {
        id: "benchmark",
        name: "Benchmarks",
        is_selected: false,
      },
      {
        id: "market_forecast",
        name: "Market Forecast",
        is_selected: false,
      },
      {
        id: "market_landscape",
        name: "Market Landscape",
        is_selected: false,
      },
      {
        id: "special_report",
        name: "Special Report",
        is_selected: false,
      },
      {
        id: "customer_study",
        name: "Customer Study",
        is_selected: false,
      },
      {
        id: "ecosystem",
        name: "Ecosystem",
        is_selected: false,
      },
    ],
  },
  MARKET_FORECAST: {
    CREATE: {
      SUCCESS: "Market Forecast created successfully",
    },
    EDIT: {
      SUCCESS: "Market Forecast details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Market Forecast deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Market Forecast activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Market Forecast inactivated successfully",
    },
    INACTIVATE_MARKET_FORECAST_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Market Forecast?",
    ACTIVATE_MARKET_FORECAST_CONFIRM_MESSAGE:
      "Are you sure want to activate the Market Forecast?",
    REMOVE_MARKET_FORECAST_CONFIRM_MESSAGE:
      "Are you sure want to remove the Market Forecast?",
  },
  MARKET_LANDSCAPE: {
    CREATE: {
      SUCCESS: "Market Landscape created successfully",
    },
    EDIT: {
      SUCCESS: "Market Landscape details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Market Landscape deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Market Landscape activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Market Landscape inactivated successfully",
    },
    INACTIVATE_MARKET_LANDSCAPE_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Market Landscape?",
    ACTIVATE_MARKET_LANDSCAPE_CONFIRM_MESSAGE:
      "Are you sure want to activate the Market Landscape?",
    REMOVE_MARKET_LANDSCAPE_CONFIRM_MESSAGE:
      "Are you sure want to remove the Market Landscape?",
  },
  MASTER_DATA_METRIC_TYPE: {
    CREATE: {
      SUCCESS: "Metric Type created successfully",
    },
    EDIT: {
      SUCCESS: "Metric Type updated successfully",
    },
    REMOVE: {
      SUCCESS: "Metric Type deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Metric Type activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Metric Type inactivated successfully",
    },
    REMOVE_METRIC_TYPE_CONFIRM_MESSAGE:
      "Are you sure want to remove the Metric Type and its Metrics?",
    INACTIVATE_METRIC_TYPE_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Metric Type?",
    ACTIVATE_METRIC_TYPE_CONFIRM_MESSAGE:
      "Are you sure want to activate the Metric Type?",
  },
  MASTER_DATA_METRIC_UNIT: {
    CREATE: {
      SUCCESS: "Metric Unit created successfully",
    },
    EDIT: {
      SUCCESS: "Metric Unit updated successfully",
    },
    REMOVE: {
      SUCCESS: "Metric Unit deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Metric Unit activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Metric Unit inactivated successfully",
    },
    REMOVE_METRIC_UNIT_CONFIRM_MESSAGE:
      "Are you sure want to remove the Metric Unit and its Metrics?",
    INACTIVATE_METRIC_UNIT_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Metric Unit?",
    ACTIVATE_METRIC_UNIT_CONFIRM_MESSAGE:
      "Are you sure want to activate the Metric Unit?",
  },
  MASTER_DATA_METRIC: {
    CREATE: {
      SUCCESS: "Metric created successfully",
    },
    EDIT: {
      SUCCESS: "Metric updated successfully",
    },
    REMOVE: {
      SUCCESS: "Metric deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Metric activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Metric inactivated successfully",
    },
    REMOVE_METRIC_CONFIRM_MESSAGE: "Are you sure want to remove the Metric?",
    INACTIVATE_METRIC_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Metric?",
    ACTIVATE_METRIC_CONFIRM_MESSAGE:
      "Are you sure want to activate the Metric?",
  },
  MASTER_DATA_LOB: {
    CREATE: {
      SUCCESS: "LOB created successfully",
    },
    EDIT: {
      SUCCESS: "LOB updated successfully",
    },
    REMOVE: {
      SUCCESS: "LOB deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "LOB activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "LOB inactivated successfully",
    },
    REMOVE_LOB_CONFIRM_MESSAGE: "Are you sure want to remove the LOB?",
    INACTIVATE_LOB_CONFIRM_MESSAGE: "Are you sure want to de-activate the LOB?",
    ACTIVATE_LOB_CONFIRM_MESSAGE: "Are you sure want to activate the LOB?",
  },
  MASTER_DATA_LOB_SUB_CATEGORY: {
    CREATE: {
      SUCCESS: "LOB Sub-category created successfully",
    },
    EDIT: {
      SUCCESS: "LOB Sub-category updated successfully",
    },
    REMOVE: {
      SUCCESS: "LOB Sub-category deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "LOB Sub-category activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "LOB Sub-category inactivated successfully",
    },
    REMOVE_LOB_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to remove the LOB Sub-category?",
    INACTIVATE_LOB_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the LOB Sub-category?",
    ACTIVATE_LOB_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to activate the LOB Sub-category?",
  },
  MASTER_DATA_VERTICAL: {
    CREATE: {
      SUCCESS: "Vertical created successfully",
    },
    EDIT: {
      SUCCESS: "Vertical updated successfully",
    },
    REMOVE: {
      SUCCESS: "Vertical deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Vertical activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Vertical inactivated successfully",
    },
    REMOVE_VERTICAL_CONFIRM_MESSAGE:
      "Are you sure want to remove the Vertical?",
    INACTIVATE_VERTICAL_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Vertical?",
    ACTIVATE_VERTICAL_CONFIRM_MESSAGE:
      "Are you sure want to activate the Vertical?",
  },
  MASTER_DATA_VERTICAL_CATEGORY: {
    CREATE: {
      SUCCESS: "Vertical Sub-category created successfully",
    },
    EDIT: {
      SUCCESS: "Vertical Sub-category updated successfully",
    },
    REMOVE: {
      SUCCESS: "Vertical Sub-category deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Vertical Sub-category activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Vertical Sub-category inactivated successfully",
    },
    REMOVE_VERTICAL_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to remove the Vertical Sub-category?",
    INACTIVATE_VERTICAL_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Vertical Sub-category?",
    ACTIVATE_VERTICAL_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to activate the Vertical Sub-category?",
  },
  MASTER_DATA_GEOGRAPHY: {
    CREATE: {
      SUCCESS: "Geography created successfully",
    },
    EDIT: {
      SUCCESS: "Geography updated successfully",
    },
    REMOVE: {
      SUCCESS: "Geography deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Geography activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Geography inactivated successfully",
    },
    REMOVE_GEOGRAPHY_CONFIRM_MESSAGE:
      "Are you sure want to remove the Geography?",
    INACTIVATE_GEOGRAPHY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Geography?",
    ACTIVATE_GEOGRAPHY_CONFIRM_MESSAGE:
      "Are you sure want to activate the Geography?",
  },
  MASTER_DATA_GEO_SUB_CATEGORY: {
    CREATE: {
      SUCCESS: "Geo Sub-category created successfully",
    },
    EDIT: {
      SUCCESS: "Geo Sub-category updated successfully",
    },
    REMOVE: {
      SUCCESS: "Geo Sub-category deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Geo Sub-category activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Geo Sub-category inactivated successfully",
    },
    REMOVE_GEO_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to remove the Geo Sub-category?",
    INACTIVATE_GEO_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Geo Sub-category?",
    ACTIVATE_GEO_SUB_CATEGORY_CONFIRM_MESSAGE:
      "Are you sure want to activate the Geo Sub-category?",
  },
  MASTER_DATA_COUNTRY: {
    CREATE: {
      SUCCESS: "Country created successfully",
    },
    EDIT: {
      SUCCESS: "Country updated successfully",
    },
    REMOVE: {
      SUCCESS: "Country deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Country activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Country inactivated successfully",
    },
    REMOVE_COUNTRY_CONFIRM_MESSAGE: "Are you sure want to remove the Country?",
    INACTIVATE_COUNTRY_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Country?",
    ACTIVATE_COUNTRY_CONFIRM_MESSAGE:
      "Are you sure want to activate the Country?",
  },

  TEMPLATE: {
    CREATE: {
      SUCCESS: "Template Record created successfully",
    },
    EDIT: {
      SUCCESS: "Template Record updated successfully",
    },
    REMOVE: {
      SUCCESS: "Template Record deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Template Record activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Template Record inactivated successfully",
    },
    INACTIVATE_TEMPLATE_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Template Record?",
    ACTIVATE_TEMPLATE_CONFIRM_MESSAGE:
      "Are you sure want to activate the Template Record?",
    REMOVE_TEMPLATE_CONFIRM_MESSAGE:
      "Are you sure you want to remove the Template Record? This will delete the rules associated with the Template Record",
  },
  METRIC_RULE: {
    CREATE: {
      SUCCESS: "Rule created successfully",
    },
    EDIT: {
      SUCCESS: "Rule details updated successfully",
    },
    REMOVE: {
      SUCCESS: "Rule deleted successfully",
    },
    ACTIVATE: {
      SUCCESS: "Rule activated successfully",
    },
    IN_ACTIVATE: {
      SUCCESS: "Rule inactivated successfully",
    },
    ADD: {
      SUCCESS: "Rule added successfully",
    },
    INACTIVATE_METRIC_RULE_CONFIRM_MESSAGE:
      "Are you sure want to de-activate the Rule?",
    ACTIVATE_METRIC_RULE_CONFIRM_MESSAGE:
      "Are you sure want to activate the Rule?",
    REMOVE_METRIC_RULE_CONFIRM_MESSAGE: "Are you sure want to remove the Rule?",
  },
  ERROR: {
    SOMETHING_WENT_WRONG: "Something Went Wrong! Please Try Again",
    API_ERRORS: "Errors",
    REQUEST_NOT_FOUND: "Request Not Found",
    CODE_EXPIRED:
      "User verification code got expired and Redirecting to forget password page.",
  },
  FORM: {
    MANDATORY_FIELD: "Please fill the field",
    INVALID_EMAIL: "Please fill the valid E-mail",
    INVALID_LINK: "Link is not valid",
    INVALID_CONTACT_NUMBER: "Please fill the valid contact number",
    MANDATORY_FIELD_FILE_UPLOAD: "Please select the file",
    PLACEHOLDERS_CHANGED: "Please do not change the placeholders",
    SELECT_DROPDOWN: "Please Select No of Users",
  },
  REG_EX: {
    PASSWORD_REG_EX: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
    EMAIL_REG_EX:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE_REG_EX: /^[0-9\+]{10}$/,
    WEBINAR_REG_EX:
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/,
  },
  USER_ROLES: {
    ANALYST: { SLUG: "analyst", NAME: "Analyst" },
    SENIOR_ANALYST: { SLUG: "senior_analyst", NAME: "Senior Analyst" },
    EDITOR: { SLUG: "editor", NAME: "Editor" },
    PRACTICE_MANAGER: { SLUG: "practice_manager", NAME: "Practice Manager" },
    ADMIN: { SLUG: "admin", NAME: "Admin" },
    RESEARCH_ANALYST: { SLUG: "research_analyst", NAME: "Research Analyst" },
    SALES_ADMIN: { SLUG: "sales_admin", NAME: "Sales Admin" },
    ACCOUNT_MANAGER: { SLUG: "account_manager", NAME: "Account Manager" },
  },
  ELLIPSIS: {
    TEXT_MIN_LENGTH: 25,
    TEXT_MAX_LENGTH: 75,
  },
  TOKEN: {
    INVALID_MESSAGE: "Token is invalid!",
    INVALID_SSO_ID_TOKEN: "SSO id token is invalid!",
    NOT_FOUND: "Authentication Error,Token not Found!",
  },
  AUTHORS_TYPES: {
    BENCHMARK_DETAILS: "BENCHMARK_DETAILS",
    CUSTOMER_STUDY_DETAILS: "CUSTOMER_STUDY_DETAILS",
    COMPANY_DETAILS: "COMPANY_DETAILS",
    MARKET_FORECAST_DETAILS: "MARKET_FORECAST_DETAILS",
    MARKET_LANDSCAPE_DETAILS: "MARKET_LANDSCAPE_DETAILS",
    SPECIAL_REPORT_DETAILS: "SPECIAL_REPORT_DETAILS",
    ECOSYSTEM_DETAILS: "ECOSYSTEM_DETAILS",
  },
  HTML: {
    TAGS: {
      BUTTON: "button",
      LIST_ITEM: "list-item",
    },
  },
  GA: {
    EVENTS: {
      PAGE_VISIT: "time_on_page",
      LOGIN: "login_event",
    },
    PAGE_TYPE: {
      BOARD: "board",
      DATA_VISUALIZATION: "data-visualization",
    },
  },
  CALENDAR: {
    WEBINAR_TYPE: {
      PUBLISHED: "Published",
      DRAFT: "Drafted",
    },
    SLUG: {
      PUBLISHED: "publish",
      DRAFT: "draft",
    },
    PUBLISHED_TYPE: {
      COMPANY: "Company Research",
      CUSTOMER_STUDIES: "Customer Study",
      BENCHMARK: "Benchmark",
      SPECIAL_REPORT: "Special Report",
      MARKET_FORECAST: "Market Forecast",
      MARKET_LANDSCAPE: "Market Landscape",
      ECOSYSTEM: "Ecosystem",
    },
    PUBLISHED_SLUG: {
      COMPANY: "company_report",
      CUSTOMER_STUDIES: "customer_studies",
      BENCHMARK: "benchmark",
      SPECIAL_REPORT: "special_report",
      MARKET_FORECAST: "market_forecast",
      MARKET_LANDSCAPE: "market_landscape",
      ECOSYSTEM: "ecosystem",
    },
  },
  APP_URLS: {
    ADMIN: {
      LOCAL: "localhost",
      STAGE: "admin.staging.tbrinsightcenter.com",
      TEST: "admin.test.tbrinsightcenter.com",
      PROD: "admin.tbrinsightcenter.com",
    },
    CLIENT: {
      LOCAL: "http://localhost:3000",
      STAGE: "https://staging.tbrinsightcenter.com",
      TEST: "https://test.tbrinsightcenter.com",
      PROD: "https://tbrinsightcenter.com",
    },
  },
  QUOTE_IMAGE: {
    MODAL_HEADER: {
      ADDING_QUOTE: "Add Quote and Image",
      EDITING_QUOTE: "Edit Quote and Image",
    },
    UPLOAD_BUTTON: {
      CREATE: "Save & Submit",
      UPDATE: "Save",
    },
    NO_QUOTE_FOUND: "No quote here",
    NO_QUOTE_FOUND_COLOR: "#8E98A8",
  },
};
export default constants;
