import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { ClientServices } from "../Services/Client";
import { formatRoute } from "react-router-named-routes";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import Slugify from "../Utils/Slugify";
import { activeMenuTab } from "../Store/Actions/User";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Toast from "../Utils/Toast";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import { clients, clientRoles } from "../Store/Actions/Client";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import isRoleAccountManager from "../Utils/isRoleAccountManager";
import isRoleAdmin from "../Utils/isRoleAdmin";

const tableHeader = [
  {
    id: "first_name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
      verticalAlign: "top"
    },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", verticalAlign: "top" },
    visibility: true,
  },
  {
    id: "assigned_clients",
    title: "Assigned  Clients",
    sortable: false,
    headerStyle: { textAlign: "center", paddingRight: "25px" },
    rowStyle: { textAlign: "left", paddingRight: "25px", verticalAlign: "top" },
    visibility: true,
  },
  {
    id: "total_clients",
    title: "Total Clients",
    sortable: false,
    headerStyle: { textAlign: "center", paddingRight: "25px" },
    rowStyle: { textAlign: "center", paddingRight: "25px", verticalAlign: "top" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", verticalAlign: "top"},
    visibility: true,
  },
  {
    id: "updated_by",
    title: "Last Modified By",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", verticalAlign: "top" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    rowStyle: {},
    visibility: true,
  },
];
class ListAccountManagers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      addClientsModal: false,
      userId: null,
      isUpdate: false,
      isnewlyAdded: false,
    };
  }
  componentDidMount() {
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS.LIST.ROUTE
    );
    ClientServices.getAllClients()
      .then((res) => {
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        this.props.setClients(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.getTableData();
  }
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleClientsModal = () => {
    this.setState({
      addClientsModal: !this.state.addClientsModal,
    });
  };

  closeClientsModal = () => {
    this.setSelectedClients([]);
    this.setState({
      userId: null,
      addClientsModal: false,
      isUpdate: false,
      isnewlyAdded: false,
    });
  };
  openModal = (data) => {
    if (data.assigned_clients.length > 0) {
      let selectedClients = data.assigned_clients.map((a) => a.id);
      this.setSelectedClients(selectedClients);
      this.setState({ isUpdate: true });
    }
    this.setState({ userId: data.id, selectedClients: [] });
    this.toggleClientsModal();
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS.LIST.ROUTE,
        {}
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };
  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.ACCOUNT_MANAGERS_RESOURCE.TITLE}
          action={constants.ACL.ACCOUNT_MANAGERS_RESOURCE.ACTIONS.EDIT}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY_TOOLTIP
            )}-${data.id}`}
            onClick={() => this.openModal(data)}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY_TOOLTIP
            )}-${data.id}`}
            data-tip={"Assign Clients"}
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              edit
            </span>
          </button>
        </AclAction>
      </>
    );
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TBRI_USER.LIST.EDIT_USER:
        this.toggleClientsModal();
        break;
    }
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  getTableData = () => {
    let index = tableHeader.findIndex((element) => element.id == "actions");
    let role_id = this.props.home.loginUserSuccess.role_id;
    if (!isRoleAccountManager(role_id) || isRoleAdmin(role_id)) {
      index > -1 && (tableHeader[index].visibility = true);
    } else {
      index > -1 && (tableHeader[index].visibility = false);
    }
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url =
      "/accountmanagerslist?page=" +
      this.state.pageNo +
      "&page_size=" +
      this.state.pageSize +
      queryParams;
    ClientServices.getAllAccountManagers(url)
      .then((res) => {
        let data = res.data.data;
        let count = res.data.meta.total_count;
        data = data.map((element) => {
          let assigned_clients =
            element.assigned_clients.length > 0
              ? element.assigned_clients.map((c) => c.name).join(", ")
              : "-";
          return {
            first_name: element.first_name + " " + element.last_name,
            email: element.email,
            id: element.id,
            assigned_clients: assigned_clients,
            total_clients: element.assigned_clients.length,
            updated_by:
              element.updated_by_manager &&
              element.updated_by_manager.updated_by_details &&
              element.updated_by_manager.updated_by_details.first_name
                ? element.updated_by_manager.updated_by_details.first_name
                : "-",
            updated_at:
              element.updated_by_manager &&
              element.updated_by_manager.last_assigned_at
                ? ComapnyDetailsDateTimeFormatter(
                    element.updated_by_manager.last_assigned_at,
                    false
                  )
                : "-",
            actions: this.getActions(element),
          };
        });
        this.setState({ rowData: data, count });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleClientChange = (e) => {
    this.setState({ isnewlyAdded: true });
    let selectedClients = e.map((a) => a.id);
    this.setSelectedClients(selectedClients);
  };
  setSelectedClients = (selectedClients) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = selectedClients.includes(c.id);
    });
    this.props.setClients(clients);
    this.setState(
      {
        selectedClients: selectedClients,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  saveClientsModal = () => {
    let dataObj = {
      userId: this.state.userId,
      clientIds: this.state.selectedClients,
      isnewlyAdded: true,
    };
    ClientServices.assignClients(dataObj)
      .then((res) => {
        Toast("Assigned Clients Successfully", "success");
        this.setState({ isUpdate: false, isnewlyAdded: false }, () => {
          this.getTableData();
          this.toggleClientsModal();
          this.setSelectedClients([]);
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>
          <div className="comapnay_tbl">
            <div className="">
              <div className="d-flex">
                <div className="min-width-266 border-search search-box-shadow-2 mb-3">
                  <Search
                    handleSearch={this.handleSearch}
                    handleInput={this.handleInput}
                    value={this.state.name}
                    placeHolder={"Filter Name"}
                  />
                </div>
              </div>
              <div className=" list-account-manager-table custom-table-block custom-table-block-2 first-col-fixed-block accountmanagers-table-block th-nowrap sort-table-block center-th-align">
                <DynamicTableComponentNew
                  columnData={tableHeader}
                  rowData={this.state.rowData}
                  pagePrevious={this.pagePrevious}
                  pageNext={this.pageNext}
                  pageSize={this.state.pageSize}
                  pageNo={this.state.pageNo}
                  handlePage={this.handlePage}
                  handleSort={this.handleSort}
                  sortColumn={this.state.sortColumn}
                  sortOrder={this.state.sortOrder}
                  count={this.state.count}
                  rowAction={this.rowAction}
                  colSpan={tableHeader.filter((h) => h.visibility).length}
                  // showActions={showActions}
                  isSelectAll={this.state.isSelectAll}
                  selectedValues={this.state.selectedValues}
                  handleSelectAll={this.handleSelectAll}
                  handleCheckBoxButtonClick={this.onCheckboxBtnClick}
                  showPagination={true}
                  paginationClass={"center-pagination custom-pagination-new"}
                  handlePageSize={this.handlePageSize}
                  checkBoxDisable={this.state.disableStatus}
                  statusId={this.state.statusId}
                  // checkboxpermission={true}
                />
              </div>

              {/* Tooltips for icons in table, don't delete */}
              {this.state.rowData.map((data) => {
                return (
                  <>
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(
                        constants.APPLICATION_ROUTE.COMPANY.LIST
                          .EDIT_COMPANY_TOOLTIP
                      )}-${data.id}`}
                      multiline={true}
                    />
                  </>
                );
              })}

              <Modal
                isOpen={this.state.addClientsModal}
                toggle={this.closeClientsModal}
                className={
                  "modal-lg modal-without-header custom-modal primary-modal"
                }
              >
                <ModalBody>
                  <form className={"form-wrapper"}>
                    <div className={"row"}>
                      <div className="col-lg-12 col-sm-12 position-relative">
                        <label for="companies">
                          Assign Clients <span className={"mandatory"}>* </span>
                        </label>
                        <div className="custom-multiselect">
                          <CustomMultiSelect
                            data={clients}
                            placeholder={"Select Clients"}
                            handleChange={this.handleClientChange}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </ModalBody>
                {
                  <ModalFooter>
                    <Button
                      className="modal-btn btn-outline-primary"
                      onClick={this.closeClientsModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-btn"
                      color="primary"
                      disabled={!this.state.isnewlyAdded}
                      onClick={this.saveClientsModal}
                    >
                      {this.state.isUpdate ? "Update" : "Save"}
                    </Button>{" "}
                  </ModalFooter>
                }
              </Modal>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAccountManagers);
