import { benchmark_details as DETAILS } from '../actionConstants'

const benchmarkCategories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const benchmarkSegments = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const benchmarkDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const benchmarkContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_CONTENT:
      return action.payload
    default:
      return state
  }
}

const benchmarkTemplates = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_TEMPLATES:
      return action.payload
    default:
      return state
  }
}

const benchmarkTransactionDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BENCHMARK_TRANSACTION_DETAILS:
      return action.payload
    default:
      return state
  }
}

export {
  benchmarkCategories,
  benchmarkSegments,
  benchmarkDetails,
  categories,
  benchmarkContent,
  benchmarkTemplates,
  benchmarkTransactionDetails
}
