import React, { Component } from "react";
import { connect } from "react-redux";
import {
  specialReportCategories,
  specialReportContent,
} from "../Store/Actions/SpecialReportDetails";
import Collapsible from "react-collapsible";
import Toast from "../Utils/Toast";
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Popover,
} from "reactstrap";
import Slugify from "../Utils/Slugify";
class SpecialReportTransactionDetailsCategories extends Component {
  constructor(props) {
    super(props);
    this.subcatref = React.createRef();
    this.formRef = React.createRef();
  }
  state = {
    showPopover: false,
    isEdit: false,
    category: [],
    popoverId: "add-edit-category-subcategory",
  };

  componentDidMount() {
    document
    .querySelector(".inner-sidebar")
    .addEventListener("scroll", this.handleScroll);
    document.body.addEventListener("click", this.handleClickOutside);
    this.setState({
      showPopover: false,
    });
  }
  componentWillUnmount() {
    document
      .querySelector(".inner-sidebar")
      .removeEventListener("scroll", this.handleScroll);
    document.body.removeEventListener("click", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    // Check if the clicked element is inside the popover
    const popover = document.querySelector(
      ".add-edit-category-subcategory-popover"
    );
    if (popover && popover.contains(event.target)) {
      return;
    }
    this.setState({
      showPopover: false,
      popoverId: this.state.popoverId,
    });
  };

  handleScroll = () => {
    this.setState({ showPopover: false, category: [] });
  };

  render() {
    let categories =
      this.props.special_report_details &&
      this.props.special_report_details.specialReportCategories
        ? this.props.special_report_details.specialReportCategories
        : [];
    let changecategoryStatus = () => {
      this.setState({
        showPopover: !this.state.showPopover,
        isEdit: false,
      });
    };
    function checkForDuplicates(array) {
      for (let i = 1; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return false;
          }
        }
      }
      return true;
    }
    let handleSubmit = (event) => {
      event.preventDefault();
      let data = [];
      if (event.target[0].value != "") {
        for (let i = 0; i < event.target.length; i++) {
          if (event.target[i].type === "text") {
            event.target[i].value != "" && data.push(event.target[i].value);
          }
        }
        checkForDuplicates(data)
          ? this.props.addCategory(
              data,
              this.state.isEdit,
              this.state.category.name
            ) &&
            this.setState({
              showPopover: false,
              category_name: [],
            })
          : Toast("Same SubCategory names doesnot allowed", "error");
      }
    };
    let addSubCategory = () => {
      let x = document.createElement("div");
      x.className = "input-wrapper";
      x.innerHTML =
        '<input type="text" class="form-control" id="subCategoryName" placeholder="Enter Subcategory Name" />';
      this.subcatref.current.appendChild(x);
    };
    let changestatesStatus = (category,id) => {
      this.setState(
        {
          isEdit: true,
          category: category,
          popoverId: id,
          showPopover: true,
        },
        () => {
          this.formRef.current[0].value = category.name;
          if (category.subcategories) {
            if (
              this.formRef.current.length - 2 <=
              category.subcategories.length
            ) {
              for (let i = 0; i < category.subcategories.length; i++) {
                addSubCategory();
                this.formRef.current[i + 2].value =
                  category.subcategories[i].name;
              }
            }
          }
        }
      );
    };
    return (
      <aside
        className={`inner-sidebar ${
          this.props.isEditable ? "add-cat-subcat" : ""
        }`}
      >
        <div className="sidebar_nav2 d-flex flex-column h-100">
          <div className="d-flex expand-collapse-icon-wrapper">
            <span
              className="material-icons-outlined expand-collapse-icon"
              onClick={this.props.toggleInnerSidebar}
            >
              keyboard_double_arrow_left
            </span>
          </div>
          <div className="opened-innersidebar-content">
            {this.props.isEditable && (
              <>
                <button
                  type="button"
                  className="btn btn-primary content-save-btn mb-2 w-auto float-none add_category d-flex align-items-center pl-1"
                  id="add-edit-category-subcategory"
                  onClick={changecategoryStatus}
                >
                  <span className="material-icons-outlined icon icon-fs-18 text-black2">
                    add
                  </span>
                  Add Category
                </button>
              </>
            )}
            <div className="custom-collapser mt-1 inner-sidebar-collapser">
              <div className="mb-1">
                <div className="custom-collapser-item custom-collapser-2-item">
                  {categories.map((category) => (
                    <Collapsible
                      open={true}
                      trigger={
                        <>
                          <div className="d-flex align-items-center ml-auto collapse-icon">
                            <span className="material-icons-outlined material-icon">
                              chevron_right
                            </span>
                          </div>
                        </>
                      }
                      triggerSibling={() => (
                        <div className="d-flex align-items-center trigger-block add-remove-item add-remove-edit-item">
                          <input
                            type="checkbox"
                            className="custom-checkbox-new"
                            name="Insights"
                            id="tbr_insights"
                            checked=""
                          />
                          <label className="mb-0 title" for="tbr_insights">
                            <span className="">{category.name}</span>
                          </label>
                          {this.props.isEditable && (
                            <span
                              className="material-icons-outlined icon icon-fs-16 text-primary cursor-pointer mr-6px"
                              id={`add-edit-category-subcategory_${category.id}`}
                              onClick={() => {
                                changestatesStatus(
                                  category,
                                  `add-edit-category-subcategory_${category.id}`
                                );
                              }}
                            >
                              edit
                            </span>
                          )}
                          {this.props.isEditable && (
                            <span
                              onClick={(e) => {
                                if (this.props.isEditable) {
                                  this.props.toggleCheckbox(
                                    !category.is_selected,
                                    "category",
                                    category.uid
                                  );
                                }
                              }}
                              className={
                                !category.is_selected
                                  ? "material-icons-outlined icon icon-fs-18 text-primary cursor-pointer mr-1"
                                  : "material-icons-outlined icon icon-fs-18 remove-icon cursor-pointer mr-1"
                              }
                            >
                              {!category.is_selected ? "add" : "close"}
                            </span>
                          )}
                        </div>
                      )}
                    >
                      {category.subcategories.map(
                        (subcategory) =>
                          subcategory.is_selected === true && (
                            <div
                              className="collapse-panel"
                              onClick={() => {
                                this.props.handleClickScroll(
                                  subcategory.name,
                                  subcategory.id
                                );
                              }}
                            >
                              <div
                                className="collapse-panel-content"
                                key={category.id}
                              >
                                <div
                                  className={`${
                                    subcategory.is_selected ? "selected" : ""
                                  } item d-flex align-items-center add-remove-item`}
                                  key={subcategory.id}
                                >
                                  <input
                                    className="custom-checkbox-new"
                                    type="checkbox"
                                    name={subcategory.name}
                                    id={
                                      Slugify(category.name) +
                                      "_" +
                                      Slugify(subcategory.name)
                                    }
                                    checked={subcategory.is_selected}
                                  />
                                  <label
                                    style={
                                      this.props.selectedSlide ===
                                      subcategory.id
                                        ? { color: "#1084C5" }
                                        : {}
                                    }
                                    className={`
                                            ${
                                              category.is_selected
                                                ? "selected"
                                                : "non-selected"
                                            } 
                                            sub-category-label mb-0`}
                                    title={subcategory.name}
                                  >
                                    <span className="ellipsis">
                                      {subcategory.name}
                                    </span>
                                  </label>

                                  {this.props.isEditable && (
                                    <span
                                      onClick={(e) => {
                                        if (this.props.isEditable) {
                                          this.props.toggleCheckbox(
                                            !subcategory.is_selected,
                                            "sub_category",
                                            category.uid,
                                            subcategory.uid
                                          );
                                        }
                                      }}
                                      className={`${
                                        subcategory.is_selected
                                          ? "red-icon"
                                          : "blue-icon"
                                      } material-icons-outlined icon icon-fs-18 cursor-pointer mr-2px`}
                                    >
                                      {subcategory.is_selected
                                        ? "playlist_remove"
                                        : "playlist_add"}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </Collapsible>
                  ))}
                  {this.props.isEditable && this.state.showPopover && (
                    <UncontrolledPopover 
                      className="custom-popover add-edit-category-subcategory-popover"
                      placement="right"
                      trigger="legacy"
                      isOpen={this.state.showPopover}
                      target={this.state?.popoverId}
                    >
                      <PopoverBody>
                        <div className="d-flex align-items-center title-block">
                          <h5 className="mb-0 title font-weight-normal">
                            {this.state.isEdit ? "Edit" : "Add"} Category/
                            Subcategory
                          </h5>
                          <span
                            onClick={changecategoryStatus}
                            className="material-icons-round icon ml-auto icon-fs-18 cursor-pointer"
                          >
                            close
                          </span>
                        </div>
                        <form onSubmit={handleSubmit} ref={this.formRef}>
                          <div className="cat-subcat-inputs-block">
                            <input
                              type="text"
                              className="form-control"
                              id="categoryName"
                              placeholder="Enter Category Name"
                            />
                            <button
                              type="button"
                              className="btn btn-primary content-save-btn mb-2 w-auto float-none add-sub-category d-flex align-items-center pl-1"
                              onClick={addSubCategory}
                            >
                              <span className="material-icons-outlined icon icon-fs-18 text-black2">
                                add
                              </span>
                              Add Sub Category
                            </button>
                            <div
                              className="sub-category-inputs"
                              ref={this.subcatref}
                            ></div>
                          </div>
                          <div className="save-btn-wrapper d-flex">
                            <button
                              type="submit"
                              className={
                                "btn btn-custom btn-primary icon-text-btn d-inline-flex align-items-center ml-auto"
                              }
                            >
                              {this.state.isEdit ? "Update" : "Save"}
                              <span className="material-icons-outlined icon-fs-16 ml-1">
                                {this.state.isEdit ? "sync" : "save"}
                              </span>
                            </button>
                          </div>
                        </form>
                      </PopoverBody>
                    </UncontrolledPopover>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="closed-innersidebar-content d-flex flex-grow-1">
            <div className="content text-center font-weight-semibold">
              CONTENTS
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    special_report_details: state.special_report_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    specialReportCategories: (payload) => {
      dispatch(specialReportCategories(payload));
    },
    specialReportContent: (payload) => {
      dispatch(specialReportContent(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialReportTransactionDetailsCategories);
