import React, { useEffect, useState } from "react";
import { parseCssToObj } from "../Utils/helper";

const CustomTitle = (props) => {
  const [customStyle, setCustomStyle] = useState({ textAlign: "left" });

  useEffect(() => {
    const getCustomStyle = async (htmlStyle) => {
      if (htmlStyle) {
        const htmlStyleObj = parseCssToObj(htmlStyle);
        if (htmlStyleObj) {
          setCustomStyle({
            textAlign: "left",
            // fontFamily: htmlStyleObj["font-family"],
          }); // Set the style in state
        } else {
          setCustomStyle({ textAlign: "left" }); // Set the style in state
        }
      }
    };

    // Call getCustomStyle with the necessary arguments
    if (props.title_html) {
      getCustomStyle(props.title_html);
    }
  }, [props]);

  return React.createElement(
    props.tag ?? "span",
    {
      className: props.className,
      style: customStyle,
    },
    props.title
  );
};

export default CustomTitle;
