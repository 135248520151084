import constants from "../Utils/constants";

function CustomEllipsis(message, maxLength) {
  const minTextLength = maxLength || constants.ELLIPSIS.TEXT_MIN_LENGTH;
  return {
    text:
      message?.length > minTextLength
        ? `${message.substring(0, minTextLength)}...`
        : message,
    ellipsis: message?.length > minTextLength,
    fullText: message,
  };
}

export default CustomEllipsis;
