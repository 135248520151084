import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";

const tableHeader = [
  {
    id: "first_name",
    title: "First Name",
    sortable: false,
    headerStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "last_name",
    title: "Last Name",
    sortable: false,
    headerStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "message",
    title: "Comments",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left", color: "red" },
    visibility: true,
  },
];
const showActions = false;
class ListUserSelectedStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
    };
  }
  componentDidMount() {
    this.getTenRecords(1);
  }

  pagePrevious = () => {
    this.getTenRecords(this.state.pageNo - 1);
  };

  pageNext = () => {
    this.getTenRecords(this.state.pageNo + 1);
  };

  handlePage = (page) => {
    this.getTenRecords(page);
  };

  handleSort = (clickedColumn, key) => {};

  getTenRecords = (page) => {
    let start = (page - 1) * 10;
    let end = start + 10;
    let ten_records = this.props.data.slice(start, end);
    this.setState({
      rowData: ten_records,
      pageNo: page,
    });
  };

  render() {
    return (
      <>
        <h5 className="font-weight-semibold my-0 text-black2 mt-3">
          Successfully {this.props.type}{" "}
          {this.props.count - this.props.data.length} out of {this.props.count}{" "}
          Records
        </h5>
        <div className={"col-lg-12 col-sm-12 col-md-12 col-12"}>
          <div className="custom-table-block custom-table-block-2 sort-table-block nowrap-table-block th-nowrap center-th-align">
            <DynamicTableComponentNew
              columnData={tableHeader}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              count={this.props.data.length}
              rowAction={this.rowAction}
              colSpan={tableHeader.filter((h) => h.visibility).length}
              showPagination={true}
              paginationClass={"center-pagination custom-pagination-new"}
              handlePageSize={this.handlePageSize}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ListUserSelectedStatus;
