import { Axios } from '../lib/axios';
export const FaqServices = {
  getFaqs,
  addFaq,
  addFaqCategory,
  deleteFAQ,
  toggleState,
  updateFAQ,
  updateFAQCategory,
  deleteFAQCategory,
  updateFAQCategoryStatus
}

function getFaqs() {
  return Axios.get('/faqs/getAll');
}

function addFaq(body) {
  return Axios.post('/faqs/add', body)
}

function addFaqCategory(body) {
  return Axios.post('/faqs/addCategory', body)
}

function deleteFAQ(id){
  return Axios.delete(`/faqs/delete/${id}`)
}

function toggleState(id, body){
  return Axios.patch(`/faqs/status/${id}`, body)
}


function updateFAQ(id, body){
  return Axios.patch(`/faqs/update/${id}`, body)
}

function updateFAQCategory(id, body) {
  return Axios.patch(`/faqs/updateCategory/${id}`, body)
}

function deleteFAQCategory(id) {
  return Axios.delete(`/faqs/deleteCategory/${id}`)
}

function updateFAQCategoryStatus(id,body){
  return Axios.patch(`/faqs/updateFAQCategoryStatus/${id}`, body)
}
