import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './StatusModal.css'

function InActivationModal({ inActiveModal, setInActiveModal, handleCategoryStatus, inActivateConfirmMessage }) {
  const toggle = () => setInActiveModal(false)
  return (
    <Modal isOpen={inActiveModal} toggle={toggle} className={'modal-sm modal-w-header custom-modal modal-without-header primary-modal'}>
      <ModalHeader toggle={toggle}>

        <div className="modal-header">
          <h3 className="text-darkblue2 font-weight-bold">
            De-Activation
          </h3>
        </div>
      </ModalHeader>
      <ModalBody style={{ paddingTop: "20px !important" }}>

        <div className='remove-body'>
          <h2 className="font-weight-semibold mb-0">
            {inActivateConfirmMessage}
          </h2>
        </div>
      </ModalBody>
      <ModalFooter className="status-model-footer" style={{ justifyContent: "center !important" }}>
        <Button className={'btn-outline-primary modal-btn'} onClick={toggle}>Cancel</Button>{' '}
        <Button className={'modal-btn btn-danger'} type='submit' onClick={handleCategoryStatus}>Confirm</Button>
      </ModalFooter>
    </Modal>
  )
}

export default InActivationModal