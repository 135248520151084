import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { TemplateServices } from "../../Services/Template";
import "./DownloadModal.css";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import CommonCheckBoxSelectComponent from "./CommonCheckBoxSelectComponent";
import { CompanyServices } from "../../Services/Company";
import SelectedCommonDiv from "./SelectedCommonDiv";
import SuccessfulIcon from "./SuccessfulIcon.svg";
import CustomPPTUploader from "../../Components/CustomPPTUploader";
import _ from "lodash";
import { MasterServices } from "../../Services/Master";
import VectorErrorIcon from "./VectorErrorIcon.svg";
import VectorSuccessfullIcon from "./VectorSuccessfullIcon.svg";
import constants from "../../Utils/constants";
import Toast from "../../Utils/Toast";

function DownloadModal({
  open,
  handleModelClose,
  id,
  upload,
  fetchUploadList,
}) {
  const [allRules, setAllRules] = useState([]);
  const [step, setStep] = useState(1);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedQuaters, setSelectedQuaters] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [downloadedMetricsObj, setDownloadedMetricsObj] = useState({});
  const [fileData, setFileData] = useState({});
  const [wrongData, setWrongData] = useState({});
  const [successFullDataCount, setSuccessFullDataCount] = useState(0);
  const [successFullInsertedCompany, setsuccessFullInsertedCompany] = useState(
    {}
  );
  const [insert, setInsert] = useState(false);

  const defaultQuarterOptions = [
    { value: 1, label: "Q1", isSelected: false },
    { value: 2, label: "Q2", isSelected: false },
    { value: 3, label: "Q3", isSelected: false },
    { value: 4, label: "Q4", isSelected: false },
  ];

  const [quarterOptions, setQuaterOptions] = useState(defaultQuarterOptions);

  const fetchAllRules = () => {
    let url = "/metric-rules/list";
    TemplateServices.getTemplates(url)
      .then((res) => {
        let data = res.data.rows;
        setAllRules(
          data.map((item) => {
            return { ...item, value: item.id, label: item.name };
          })
        );

        document.body.classList.remove("second-loading-indicator");
      })
      .catch((e) => {
        Toast(
          e && e.data && e.data.error
            ? e.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const fetchAllCompany = () => {
    let url = "/companies";
    CompanyServices.getCompanies(`${url}?1=1`).then((res) => {
      let companyRes = res.data.data;

      setAllCompany(
        companyRes
          .filter((i) => i.is_active)
          .map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
      );
    });
  };

  const filterValues = (array) => {
    return array.map((i) => i.value);
  };

  const handleUploadingProcess = () => {
    setStep(3);
    let { name, content } = fileData;
    let base64_data = content.split(";base64,")[1];
    let input = {
      base64_data: base64_data,
      fileName: name.split(".xlsx")[0],
    };

    MasterServices.metricTemplateUpload(input)
      .then((response) => {
        let data = response.data.data;
        setDownloadedMetricsObj({
          ...data,
          file: data.s3_url,
          name: name.split(".xlsx")[0],
        });
        setStep(4);
      })
      .catch((e) => {
        let errorMassage = e.data.error;
        if (errorMassage === "Wrong data uploaded") {
          setStep(5);
          setWrongData(e.data.error_detail);
        } else {
          Toast(
            e && e.data && e.data.error
              ? e.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        }
      });
  };

  const hanleDownloadingPrecess = () => {
    if (upload) {
      try {
        setInsert(true);
        setStep(3);
        let input = {
          years: filterValues(selectedYears),
          quarters: filterValues(selectedQuaters),
          companies: filterValues(selectedCompany),
          file: downloadedMetricsObj.s3_url,
          file_name: downloadedMetricsObj.name,
        };
        MasterServices.AddTransations(input)
          .then((res) => {
            let response = res.data;
            setStep(6);
            setSuccessFullDataCount(response.count);
            setsuccessFullInsertedCompany(response.companyCountObj);
            setInsert(false);
          })
          .catch((e) => {
            let errorMassage = e.data.error;
            if (errorMassage === "Wrong data uploaded") {
              setStep(5);
              setWrongData(e.data.error_detail);
            }
            setInsert(false);
          });
      } catch (e) {
        Toast(
          e && e.data && e.data.error
            ? e.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      }
    } else {
      let token = localStorage.getItem("token");

      let url = `${
        process.env.REACT_APP_TBRI_API_URL
      }metric-rules/download?metrics_rules=[${
        id ? id : filterValues(selectedRules)
      }]&years=[${filterValues(selectedYears)}]&quarters=[${filterValues(
        selectedQuaters
      )}]&companies=[${filterValues(
        selectedCompany
      )}]&access_token=Bearer ${token}`;

      const sse = new EventSource(url);
      sse.addEventListener("progress", (event) => {
        setStep(3);
        const eventData = JSON.parse(event.data);

        setProgress(eventData);
      });

      sse.addEventListener("complete", (e) => {
        const eventData = JSON.parse(e.data);
        setDownloadedMetricsObj(eventData);
        setStep(4);
        sse.close();
      });

      sse.onerror = (error) => {
        sse.close();
      };
    }
  };

  const setYearOption = () => {
    const startYear = 2018;
    const endYear = 2050;

    // Initialize an empty array to store the years
    let yearsArray = [];

    // Generate the array dynamically
    for (let year = startYear; year <= endYear; year++) {
      yearsArray.push({ value: year, label: year, isSelected: false });
    }
    setYearOptions(yearsArray);
  };

  useEffect(() => {
    if (open) {
      fetchAllCompany();
      if (id) {
        setStep(2);
      } else {
        if (upload) {
          setStep(0);
          setFileData({});
        } else {
          fetchAllRules();
        }
      }
      setYearOption();
    }
  }, [open]);

  const onChangeHandlerFile = (name, content, file) => {
    setFileData({ name, content });
  };

  const handleExcelData = () => {
    let jsonData = downloadedMetricsObj.excelData;
    let { companies, years, quarters } = jsonData;

    let filterYears = yearOptions.filter((item) => years.includes(item.value));

    let compnayFilter = allCompany.filter((company) =>
      companies.includes(company.name)
    );
    let quaterFilter = defaultQuarterOptions.filter((item) =>
      quarters.includes(item.value)
    );

    setAllCompany(compnayFilter);
    setYearOptions(filterYears);
    setQuaterOptions(quaterFilter);
    setSelectedCompany(compnayFilter);
    setSelectedQuaters(quaterFilter);
    setSelectedYears(filterYears);
  };

  const handleReUpload = () => {
    setStep(0);
    setFileData();
    // setAllCompany([]);
    // setYearOptions([]);
    setQuaterOptions([]);
    setSelectedCompany([]);
    setSelectedQuaters([]);
    setSelectedYears([]);
    setWrongData({});
    setDownloadedMetricsObj({});
  };

  return (
    <Modal
      isOpen={open}
      // toggle={handleModelClose}
      className={
        "modal-lg modal-w-header custom-modal modal-without-header primary-modal"
      }
    >
      <ModalHeader toggle={handleModelClose}>
        <span className='category-title'>
          {upload ? "Upload Quant Data File" : "Download Files"}
        </span>
      </ModalHeader>
      <ModalBody style={{ padding: "15px 0px 0px" }}>
        <div className='modal-main-div'>
          {step === 0 && (
            <>
              <div className='upload-file-div'>
                <CustomPPTUploader
                  value={fileData}
                  onChangeHandler={onChangeHandlerFile}
                  type={"excel"}
                  fromDownloadModal={true}
                />
                <div className='file-restriction-message text-center'>
                  Note: Only Excel files are allowed
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <Button
                  className={"btn-outline-primary modal-btn add-btn"}
                  onClick={handleModelClose}
                >
                  Cancel
                </Button>
                <Button
                  className={"modal-right-btn modal-btn"}
                  disabled={!fileData?.name?.length}
                  type='submit'
                  onClick={handleUploadingProcess}
                >
                  Process
                </Button>
              </div>
            </>
          )}
          {step === 1 && (
            <>
              <div className='select-main-div'>
                <SelectedCommonDiv
                  selectedData={selectedRules}
                  setSelectedData={setSelectedRules}
                  title='Rules'
                  borderBottom={false}
                />
              </div>

              <span className='selected-span'>
                Select Rules<span className={"mandatory"}>*</span>
              </span>
              <div className='tab-content-div'>
                {allRules.map((item, index) => {
                  return (
                    <div
                      className='content-div cursor-pointer'
                      key={index}
                      style={{
                        background: selectedRules.filter(
                          (i) => i.id === item.id
                        ).length
                          ? "#DCEDFF"
                          : "none",
                      }}
                      onClick={(e) => {
                        let rulesFilter = selectedRules.filter(
                          (i) => i.id === item.id
                        );
                        if (rulesFilter.length) {
                          setSelectedRules(
                            selectedRules.filter((el) => el.id !== item.id)
                          );
                        } else {
                          setSelectedRules([...selectedRules, item]);
                        }
                      }}
                    >
                      <input
                        type='checkbox'
                        className='cursor-pointer'
                        checked={
                          selectedRules.filter((i) => i.id === item.id).length
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSelectedRules([...selectedRules, item]);
                          } else {
                            setSelectedRules(
                              selectedRules.filter((el) => el.id !== item.id)
                            );
                          }
                        }}
                      />

                      {item.name?.length > 50 ? (
                        <label
                          className='select-rule-label mb-0'
                          key={item.name}
                          data-for={`tooltip-${item.name}-active`}
                          data-tip={item.name}
                        >
                          {item.name.substring(0, 50) + "..."}
                          <CustomReactTooltip
                            id={`tooltip-${item.name}-active`}
                            multiline={true}
                          />
                        </label>
                      ) : (
                        <lable className='select-rule-label'>{item.name}</lable>
                      )}
                    </div>
                  );
                })}
              </div>
              <div>
                <Button
                  className={"btn-outline-primary modal-btn add-btn"}
                  onClick={handleModelClose}
                >
                  Cancel
                </Button>
                <Button
                  className={"modal-right-btn modal-btn"}
                  disabled={!selectedRules.length}
                  type='submit'
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className='step-2-select-div'>
                <div>
                  <span className='select-dropdown-label'>
                    Select Years<span className={"mandatory"}>*</span>
                  </span>
                  <CommonCheckBoxSelectComponent
                    options={yearOptions}
                    title={"Year"}
                    setSelected={setSelectedYears}
                    width='250px'
                    selected={selectedYears}
                  />
                </div>
                <div>
                  <span className='select-dropdown-label'>
                    Select Quarter<span className={"mandatory"}>*</span>
                  </span>

                  <CommonCheckBoxSelectComponent
                    options={quarterOptions}
                    title={"Quarter"}
                    setSelected={setSelectedQuaters}
                    width='250px'
                    selected={selectedQuaters}
                  />
                </div>
              </div>
              <div>
                <span className='select-dropdown-label'>
                  Select Company<span className={"mandatory"}>*</span>
                </span>
                <CommonCheckBoxSelectComponent
                  options={allCompany}
                  title={"Company"}
                  setSelected={setSelectedCompany}
                  width='520px'
                  selected={selectedCompany}
                />
              </div>
              <div className='select-main-div'>
                <SelectedCommonDiv
                  selectedData={selectedYears}
                  setSelectedData={setSelectedYears}
                  title='Years'
                  borderBottom={true}
                />
                <SelectedCommonDiv
                  selectedData={selectedQuaters}
                  setSelectedData={setSelectedQuaters}
                  title='Quarters'
                  borderBottom={true}
                />
                <SelectedCommonDiv
                  selectedData={selectedCompany}
                  setSelectedData={setSelectedCompany}
                  title='Companies'
                  borderBottom={false}
                />
              </div>
              <div>
                <Button
                  className={"btn-outline-primary modal-btn add-btn"}
                  onClick={handleModelClose}
                >
                  Cancel
                </Button>
                <Button
                  className={"modal-right-btn modal-btn"}
                  disabled={
                    !selectedYears.length ||
                    !selectedQuaters.length ||
                    !selectedCompany.length
                  }
                  type='submit'
                  onClick={hanleDownloadingPrecess}
                >
                  Process
                </Button>
              </div>
            </>
          )}

          {step === 3 && (
            <div>
              <span className='downloading-loader'></span>

              <span className='downloadding-span'>
                {upload ? (
                  insert ? (
                    "Inserting..."
                  ) : (
                    "Processing...."
                  )
                ) : (
                  <>
                    Download Progress......{" "}
                    <span style={{ color: "#004DC2" }}>{progress}%</span>
                  </>
                )}
              </span>
            </div>
          )}

          {step === 4 && (
            <div className='step4-div'>
              <img src={SuccessfulIcon} alt='success-icon' />
              <span className='downloadding-span'>
                {upload
                  ? "Process Successfull"
                  : "Download Progress Successful"}
              </span>
              <span className='downloadding-span-text'>
                Total
                <span style={{ color: "#2E6AD2", margin: "0px 5px" }}>
                  {" "}
                  {downloadedMetricsObj?.rows}{" "}
                </span>{" "}
                rows and{" "}
                <span style={{ color: "#2E6AD2", margin: "0px 5px" }}>
                  {" "}
                  {downloadedMetricsObj?.size}{" "}
                </span>{" "}
                file size found for this excel sheet.
              </span>
              <div style={{ marginTop: "28px" }}>
                <Button
                  className={"btn-outline-primary modal-btn add-btn"}
                  onClick={handleModelClose}
                >
                  Cancel
                </Button>
                {upload ? (
                  <Button
                    className={"modal-right-btn modal-btn ml-3"}
                    type='submit'
                    onClick={() => {
                      handleExcelData();
                      setStep(2);
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <a
                    href={downloadedMetricsObj?.file}
                    download='Example-PDF-document'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button
                      className={"modal-right-btn modal-btn ml-3"}
                      type='submit'
                      onClick={handleModelClose}
                    >
                      Download
                    </Button>
                  </a>
                )}
              </div>
            </div>
          )}

          {step === 5 && (
            <div>
              <div className='quantdata-inserted-error-header'>
                <img src={VectorErrorIcon} alt='error-icon' />
                <span>Quant Data Validation Failed</span>
              </div>
              <div className='error-div'>
                <span className='error-div-count'>
                  {wrongData?.totalErrorCount}
                </span>
                <span className='error-div-massage'>errors found</span>
              </div>
              {wrongData?.wrongDataObj?.wrongQuartersCount !== 0 && (
                <div className='error-massage-div'>
                  <span>
                    The file contains{" "}
                    <span
                      className='error-div-count'
                      style={{ color: "#EA4335", padding: 0 }}
                    >
                      {wrongData?.wrongDataObj?.wrongQuartersCount}
                    </span>
                    invalid data cells
                  </span>
                </div>
              )}
              {wrongData?.wrongDataObj?.wrongLobCount !== 0 && (
                <div className='error-massage-div'>
                  <span>
                    The file contains{" "}
                    <span
                      className='error-div-count'
                      style={{ color: "#EA4335", padding: 0 }}
                    >
                      {wrongData?.wrongDataObj?.wrongLobCount}{" "}
                    </span>
                    rows containing invalid LOBs.
                  </span>
                </div>
              )}
              {wrongData?.wrongDataObj?.wrongGeoCount !== 0 && (
                <div className='error-massage-div'>
                  <span>
                    The file contains
                    <span
                      className='error-div-count'
                      style={{ color: "#EA4335", padding: 0 }}
                    >
                      {wrongData?.wrongDataObj?.wrongGeoCount}{" "}
                    </span>
                    rows containing invalid Geographical mappings.
                  </span>
                </div>
              )}
              {wrongData?.wrongDataObj?.wrongVerticalCount !== 0 && (
                <div className='error-massage-div'>
                  <span>
                    The file contains{" "}
                    <span
                      className='error-div-count'
                      style={{ color: "#EA4335", padding: 0 }}
                    >
                      {wrongData?.wrongDataObj?.wrongVerticalCount}{" "}
                    </span>
                    rows containing invalid Industrial mappings.
                  </span>
                </div>
              )}
              {wrongData?.wrongDataObj?.wrongMetricsCount !== 0 && (
                <div className='error-massage-div'>
                  <span>
                    The file contains{" "}
                    <span
                      className='error-div-count'
                      style={{ color: "#EA4335", padding: 0 }}
                    >
                      {wrongData?.wrongDataObj?.wrongMetricsCount}{" "}
                    </span>
                    rows containing invalid metrics.
                  </span>
                </div>
              )}
              {wrongData?.wrongDataObj?.wrongCompaniesCount !== 0 && (
                <div className='error-massage-div'>
                  <span>
                    The file contains data for{" "}
                    <span
                      className='error-div-count'
                      style={{ color: "#EA4335", padding: 0 }}
                    >
                      {wrongData?.wrongDataObj?.wrongCompaniesCount}{" "}
                    </span>
                    invalid companies.
                  </span>
                </div>
              )}
              {/* <div className='error-massage-div'>
                <span>
                  The file is invalid and unable to process data from it
                </span>
              </div> */}
              <div style={{ marginTop: "28px" }}>
                <Button
                  className={"btn-outline-primary modal-btn add-btn"}
                  onClick={handleModelClose}
                >
                  Cancel
                </Button>
                <Button
                  className={"modal-right-btn modal-btn ml-3"}
                  type='submit'
                  onClick={handleReUpload}
                >
                  Re-Upload
                </Button>
              </div>
            </div>
          )}

          {step === 6 && (
            <div>
              <div
                className='quantdata-inserted-header'
                style={{ background: "#E0F3E5" }}
              >
                <img src={VectorSuccessfullIcon} alt='successfull-icon' />
                <span>Quant Data Insertion Completed</span>
              </div>

              <div className='error-div'>
                <span className='error-div-count' style={{ color: "#34A853" }}>
                  {successFullDataCount}
                </span>
                <span
                  className='error-div-massage'
                  style={{ color: "#232D42" }}
                >
                  records inserted
                </span>
              </div>

              {Object.entries(successFullInsertedCompany).map(
                ([key, value], index) => {
                  return (
                    <div
                      className='error-massage-div'
                      style={{ borderLeft: "4px solid #34A853" }}
                    >
                      <span>
                        Successfully inserted{" "}
                        <span style={{ color: "#34A853", padding: 0 }}>
                          {value}
                        </span>{" "}
                        rows for{" "}
                        <span style={{ color: "#34A853", padding: 0 }}>
                          {key}
                        </span>
                        .
                      </span>
                    </div>
                  );
                }
              )}

              <div style={{ marginTop: "28px" }}>
                <Button
                  className={"modal-right-btn modal-btn"}
                  type='submit'
                  onClick={() => {
                    fetchUploadList();
                    handleModelClose();
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DownloadModal;
