import { Axios } from "../lib/axios";

export const SearchServices = {

    postReindexContent,
    getJobsList,
    searchTrends
}

function postReindexContent(){
    return Axios.post(`/createReindexJob`)
}

function getJobsList(){
    return Axios.post(`/getQueueJobList`)
}

function searchTrends(body){
    return Axios.post(`/fetchSearchTrends`,body)
}