import moment from 'moment';
var _ = require('lodash');

function dateRange(startDate, endDate) {

    var start      = moment(startDate).format("yyyy-mm-dd").split('-');
    var end        = moment(endDate).format("yyyy-mm-dd").split('-');
    var startYear  = parseInt(start[0]);
    var endYear    = parseInt(end[0]);
    var dates      = [];
  
    for(var i = startYear; i <= endYear; i++) {
      var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1])-1 : 0;
      for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var displayMonth = month < 10 ? '0'+month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    return dates;
  }

function weeksInMonth(startDate,endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    var sDate;
    var eDate;
    var dateArr = [];
    
    while(start <= end){
      if (start.getDay() === 1 || (dateArr.length === 0 && !sDate)){
        sDate = new Date(start.getTime());
      }
    
      if ((sDate && start.getDay() === 0) || start.getTime() === end.getTime()){
            eDate = new Date(start.getTime());
      }
    
      if(sDate && eDate){
        dateArr.push({'startDate': sDate, 'endDate': eDate});
        sDate = undefined;
        eDate = undefined;
      }
    
        start.setDate(start.getDate() + 1);
    }
    
    return dateArr
}

function countUsersBasedOnDate(data) {
  const list = _.groupBy(data, function (obj) {
      let dateFormat = moment(obj.created_at).format('YYYY-MM-DD')
      return new Date(dateFormat).getTime()
  })
  const list2 = Object.keys(list).map(l => {
      return { date: parseInt(l), value: list[l].length }
  }) 
  list2.sort((a,b) => a.date - b.date)
  return list2; // [{"2022-1-1": 9, "2022-1-2": 2}] 
}

export {dateRange, weeksInMonth, countUsersBasedOnDate}