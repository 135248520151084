import React, { useEffect, useState } from 'react';
import { ReportsServices } from '../../Services/Reports';
import constants from '../../Utils/constants';
import ReportsChart from '../ReportsChart';
import Toast from '../../Utils/Toast';


/**
 * Following method is to check the chart behaviour for large amount of data
 * change the value in loop for large data.
 * @returns returns dummy data
 */
function getDummyData() {
    let data = [];
    for (var i = 1; i < 1000; i++) {
        let d = new Date(2018, 0, i)
        data.push({
            date: d,
            page_count: Math.floor((Math.random() * 100) + 1),
            session_count: Math.floor((Math.random() * 100) + 1)
        });
    }
    return data;
}

const series = [
    {
        name: 'Sessions',
        field_name: 'session_count'
    },
    {
        name: 'Page Views',
        field_name: 'page_count'
    }
]

export default function Sessions_PageViews(props) {

    const [chartData, setChartData] = useState([]);
    
    useEffect(() => {
        const {
            userId,
            startDate,
            endDate
        } = props;
        if (userId && startDate && endDate) {
            let body={
                user_id:userId,
                start_date:startDate,
                end_date:endDate,
            }
            ReportsServices.getSessionPageViewCountData(body)
                .then((res) => {
                    let data = res.data && res.data.data;
                    setChartData(data);
                })
                .catch((error) => {
                    Toast(error && error.data && error.data.error
                        ? error.data.error
                        : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
                })
        }
        else
        {
            setChartData([])
        }
    }, [props.userId, props.startDate, props.endDate])

    return (
        chartData && chartData.length ?
            <ReportsChart
                id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SESSION_PAGE_VIEWS.ID}
                legend_id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SESSION_PAGE_VIEWS.LEGEND_ID}
                chartData={chartData}
                series={series}
                axis_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE}
                isStacked={false}
                isFill={true}
                series_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.LINE}
            />
            :
            <h4 className='text-center'>No Data Available</h4>
    )
}