import { Axios } from '../lib/axios';

export const HelpServices = {
    addHelp,
    getHelp,
    removeHelp,
    getHelpById,
    updateHelp,
    getCategory,
    getHelpByCategory
}

function getCategory(){
    return Axios.get('category');
}

function addHelp(body) {
    return Axios.post(`helpPages`, body)
}

function getHelpByCategory(catname){
    return Axios.get(`helpPages/getpage/${catname}`)
}

function getHelp() {
    return Axios.get(`helpPages`)
}

function getHelpById(id) {
    return Axios.get(`helpPages/${id}`)
}

function updateHelp(id, body) {
    return Axios.put(`helpPages/${id}`, body)
}

function removeHelp(id){
      return Axios.delete(`helpPages/${id}`)
}
