import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Dailog from "../Components/Dailog";
import DailogNew from "../Components/DailogNew";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import Search from "../Components/Search";
import { MasterServices } from "../Services/Master";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "name",
    title: "name",
    sortable: true,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "metric_type",
    title: "Metric Type",
    sortable: false,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "metric_unit",
    title: "Metric Unit",
    sortable: false,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Updated On",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
];
const showActions = false;
class ListMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: this.props.pageNo,
      pageSize: this.props.pageSize,
      name: "",
      metricTypeName: "",
      selectedMetricTypeId: null,
      selectedMetricUnitId: null,
      metricTypeId: null,
      metricUnitId: null,
      documentModal: false,
      apiError: null,
      formErrors: {},
      isFormSubmitted: false,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      typeDropdown: [],
      typeDropdown2: [],
      metricTypes: [],
      totalmetricTypes: [],
      metricUnits: [],
      totalMetricUnits: [],
      isEdit: false,
      metricid: null,
      currentPageDataCount: 0,
      disableStatus: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState(
      {
        metricTypeId: params.metrictypeid ? params.metrictypeid : "all",
        metricUnitId: params.metricunitid ? Number(params.metricunitid) : "all",
        selectedMetricTypeId: params.metrictypeid ? params.metrictypeid : null,
        selectedMetricUnitId: params.metricunitid ? params.metricunitid : null,
      },
      () => {
        this.getMetricTypes();
        this.getMetricUnits();
        this.getTableData();
      }
    );
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  toggleMetricConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === true || data.is_active === 1
            ? "De-Activation"
            : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === true || data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.MASTER_DATA_METRIC.INACTIVATE_METRIC_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.MASTER_DATA_METRIC.ACTIVATE_METRIC_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          data.is_active === true || data.is_active === 1
            ? "de-activate_metric"
            : "activate_metric",
        id: data.metricid,
      },
      dailogModalStyleType:
        data.is_active === true || data.is_active === 1
          ? "danger-modal"
          : "success-modal",
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.METRIC_DATA_RESOURCE.TITLE}
          action={constants.ACL.METRIC_DATA_RESOURCE.ACTIONS.EDIT}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                .EDIT_METRIC_TOOLTIP
            )}-${data.metricid}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST.EDIT_METRIC,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                .EDIT_METRIC_TOOLTIP
            )}-${data.metricid}`}
            data-tip={
              constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                .EDIT_METRIC_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              edit
            </span>
          </button>
        </AclAction>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.METRIC_DATA_RESOURCE.TITLE}
          action={constants.ACL.METRIC_DATA_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                .REMOVE_METRIC_TOOLTIP
            )}-${data.metricid}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                  .REMOVE_METRIC,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                .REMOVE_METRIC_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-red icon-fs-20">
              delete
            </span>
          </button>
        </AclAction>
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    if (this.state.metricTypeId !== "" && this.state.metricTypeId !== "all") {
      queryParams += "&metrictypeid=" + this.state.metricTypeId;
    }
    if (this.state.metricUnitId !== "" && this.state.metricUnitId !== "all") {
      queryParams += "&metricunitid=" + this.state.metricUnitId;
    }

    this.props.history.push({
      pathname: this.props.location.pathname,
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
    MasterServices.getTotalMetrics(
      "metrics?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let metricData = res.data.data;
        this.setState({
          currentPageDataCount: metricData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        metricData = metricData.map((data) => {
          return {
            id: data.metricid,
            name: data.name ? data.name : "-",
            metric_type: data.metric_type ? data.metric_type.name : "-",
            metric_unit: data.metric_unit ? data.metric_unit.name : "-",
            created_by: data.created_by_user
              ? `${data.created_by_user.first_name} ${data.created_by_user.last_name}`
              : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            is_active:
              data.is_active === false ||
              data.is_active === true ||
              data.is_active === 0 ||
              data.is_active === 1 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.METRIC_DATA_RESOURCE.TITLE}
                  action={constants.ACL.METRIC_DATA_RESOURCE.ACTIONS.STATUS}
                >
                  <div className="custom-control custom-switch success-toggle">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customSwitch${data.metricid}`}
                      checked={data.is_active === true || data.is_active === 1}
                      onChange={(e) => {
                        e.stopPropagation();
                        this.rowAction(
                          constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                            .STATUS,
                          data,
                          e
                        );
                      }}
                      disabled={this.state.disableStatus}
                    />
                    <label
                      className="custom-control-label"
                      for={`customSwitch${data.metricid}`}
                    ></label>
                  </div>
                </AclAction>
              ) : null,
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: metricData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  addMetric = () => {
    this.setState({
      documentModal: true,
    });
  };

  closeDocumentModal = () => {
    let typeDropdown = this.state.typeDropdown;
    let typeDropdown2 = this.state.typeDropdown2;
    if (this.state.metricTypeId === null || this.state.metricTypeId === "all") {
      typeDropdown.forEach((r) => {
        r.is_selected = false;
      });
    } else {
      typeDropdown.forEach((r) => {
        r.is_selected =
          r.metrictypeid === this.state.metricTypeId ? true : false;
      });
    }

    if (this.state.metricUnitId === null || this.state.metricUnitId === "all") {
      typeDropdown2.forEach((r) => {
        r.is_selected = false;
      });
    } else {
      typeDropdown2.forEach((r) => {
        r.is_selected =
          r.metricunitid === this.state.metricUnitId ? true : false;
      });
    }
    this.setState({
      documentModal: false,
      typeDropdown,
      typeDropdown2,
      metricTypeName: "",
      isEdit: false,
      selectedMetricTypeId:
        this.state.metricTypeId === null || this.state.metricTypeId === "all"
          ? null
          : this.state.metricTypeId,
      selectedMetricUnitId:
        this.state.metricUnitId === null || this.state.metricUnitId === "all"
          ? null
          : this.state.metricUnitId,
      formErrors: {},
      metricid: null,
    });
  };

  removeMetricConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Meric</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.MASTER_DATA_METRIC.REMOVE_METRIC_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_meric", id: data.metricid },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeMetric = (id) => {
    MasterServices.removeMetric(id)
      .then((res) => {
        Toast(constants.MASTER_DATA_METRIC.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggleMetric = (id, isActive) => {
    MasterServices.toggleMetric(id, { is_active: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.MASTER_DATA_METRIC.ACTIVATE.SUCCESS
            : constants.MASTER_DATA_METRIC.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_meric":
        this.removeMetric(config.id);
        break;
      case "de-activate_metric":
        this.toggleMetric(config.id, 0);
        break;
      case "activate_metric":
        this.toggleMetric(config.id, 1);
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  editMetricConfirmationHandler = (data) => {
    let typeDropdown = this.state.typeDropdown;
    typeDropdown.forEach((t) => {
      t.is_selected = t.metrictypeid === data.metrictypeid;
    });

    let typeDropdown2 = this.state.typeDropdown2;
    typeDropdown2.forEach((t) => {
      t.is_selected = t.metricunitid === data.metricunitid;
    });

    this.setState({
      documentModal: true,
      isEdit: true,
      metricTypeName: data.name,
      selectedMetricUnitId: data.metricunitid,
      selectedMetricTypeId: data.metrictypeid,
      typeDropdown: typeDropdown,
      typeDropdown2: typeDropdown2,
      metricid: data.metricid,
    });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST.REMOVE_METRIC:
        this.removeMetricConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST.STATUS:
        this.toggleMetricConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST.EDIT_METRIC:
        this.editMetricConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  getMetricTypes = () => {
    MasterServices.getTotalMetricTypes("metrictypes")
      .then((res) => {
        let response = res && res.data && res.data.data;
        let metricTypes = [];
        metricTypes.push({ metrictypeid: "all", name: "All Metric Types" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let typeDropdown = CustomCloneDeep(response);
        let totalmetricTypes = metricTypes.concat(response);
        totalmetricTypes.forEach((r) => {
          r.is_selected =
            r.metrictypeid == this.state.metricTypeId ? true : false;
        });
        typeDropdown.forEach((r) => {
          r.is_selected =
            r.metrictypeid == this.state.metricTypeId ? true : false;
        });
        this.setState({
          metricTypes: totalmetricTypes,
          typeDropdown: typeDropdown,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getMetricUnits = () => {
    MasterServices.getTotalMetricUnits("metricUnits")
      .then((res) => {
        let response =
          res && res.data && res.data.data.filter((obj) => obj.is_active === 1);
        let metricUnits = [];
        metricUnits.push({ metricunitid: "all", name: "All Metric Units" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let typeDropdown2 = CustomCloneDeep(response);
        let totalMetricUnits = metricUnits.concat(response);
        totalMetricUnits.forEach((r) => {
          r.is_selected =
            r.metricunitid === this.state.metricUnitId ? true : false;
        });
        typeDropdown2.forEach((r) => {
          r.is_selected =
            r.metricunitid === this.state.metricUnitId ? true : false;
        });

        this.setState({
          metricUnits: totalMetricUnits,
          typeDropdown2,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleTypeChange = (e) => {
    this.setState(
      {
        selectedMetricTypeId: e.metrictypeid,
      },
      () => {
        let typeDropdown = this.state.typeDropdown;
        typeDropdown.forEach((t) => {
          t.is_selected = t.metrictypeid === e.metrictypeid;
        });
        this.setState({
          typeDropdown: typeDropdown,
          formErrors: { ...this.state.formErrors, metricTypeIdError: false },
        });
        this.setState({
          isFormSubmitted: true,
        });
        this.isFormValid();
      }
    );
  };

  handleMetricUnitChange = (e) => {
    this.setState(
      {
        selectedMetricUnitId: e.metricunitid,
      },
      () => {
        let typeDropdown2 = this.state.typeDropdown2;
        typeDropdown2.forEach((t) => {
          t.is_selected = t.metricunitid === e.metricunitid;
        });
        this.setState({
          typeDropdown2: typeDropdown2,
          formErrors: { ...this.state.formErrors, metricUnitIdError: false },
        });
        this.setState({
          isFormSubmitted: true,
        });
        this.isFormValid();
      }
    );
  };

  handleTypeChangeFilter = (e) => {
    this.setState(
      {
        metricTypeId: e.metrictypeid,
      },
      () => {
        let metricTypes = this.state.metricTypes;
        metricTypes.forEach((t) => {
          t.is_selected = t.metrictypeid === e.metrictypeid;
        });
        this.setState({
          metricTypes: metricTypes,
        });
        this.getTableData();
        if (e.metrictypeid !== "all") this.handleTypeChange(e);
      }
    );
  };

  handleUnitChangeFilter = (e) => {
    this.setState(
      {
        metricUnitId: e.metricunitid,
      },
      () => {
        let metricUnits = this.state.metricUnits;
        metricUnits.forEach((t) => {
          t.is_selected = t.metricunitid === e.metricunitid;
        });
        this.setState({
          metricUnits,
        });
        this.getTableData();
        if (e.metricunitid !== "all") this.handleMetricUnitChange(e);
      }
    );
  };

  isFormValid = () => {
    let formError = false;
    let metricNameError = false;
    let metricTypeIdError = false;
    let metricUnitIdError = false;
    if (this.state.metricTypeName.trim() === "") {
      metricNameError = true;
      formError = true;
    }
    if (
      this.state.selectedMetricTypeId === null ||
      this.state.selectedMetricTypeId === ""
    ) {
      metricTypeIdError = true;
      formError = true;
    }
    if (
      this.state.selectedMetricUnitId === null ||
      this.state.selectedMetricUnitId === ""
    ) {
      metricUnitIdError = true;
      formError = true;
    }

    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        metricNameError: metricNameError,
        metricTypeIdError: metricTypeIdError,
        metricUnitIdError: metricUnitIdError,
      },
      apiError: null,
    });
    return formError;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isFormSubmitted: true,
    });
    if (!this.isFormValid()) {
      let data = {
        name: this.state.metricTypeName,
        metrictypeid: this.state.selectedMetricTypeId,
        metricunitid: this.state.selectedMetricUnitId,
      };
      MasterServices.addMetric(data)
        .then((res) => {
          this.closeDocumentModal();
          Toast(constants.MASTER_DATA_METRIC.CREATE.SUCCESS, "success");
          this.getTableData();
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  handleEditSubmit = (e, metricid) => {
    e.preventDefault();
    this.setState({
      isFormSubmitted: true,
    });
    if (!this.isFormValid()) {
      let data = {
        name: this.state.metricTypeName,
        metrictypeid: this.state.selectedMetricTypeId,
        metricunitid: this.state.selectedMetricUnitId,
      };
      MasterServices.editMetric(metricid, data)
        .then((res) => {
          this.closeDocumentModal();
          Toast(constants.MASTER_DATA_METRIC.EDIT.SUCCESS, "success");
          this.getTableData();
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        formErrors: { ...this.state.formErrors, metricNameError: false },
      },
      () => {
        this.setState({
          isFormSubmitted: true,
        });
        this.isFormValid();
      }
    );
  };

  render() {
    return (
      <>
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-6 mb-3 col-lg-3 position-relative">
            <div className="border-search search-box-shadow-2">
              <Search
                handleSearch={this.handleSearch}
                handleInput={this.handleInput}
                value={this.state.name}
                placeHolder={"Filter Metric"}
              />
            </div>
          </div>
          {this.state.metricTypes && this.state.metricTypes.length > 0 && (
            <div className="col-md-3 col-sm-6 mb-3 col-lg-3 clients_dropdown position-relative">
              <div className="custom-combobox-list">
                <CustomDropdownList
                  data={this.state.metricTypes}
                  handleChange={this.handleTypeChangeFilter}
                  placeholder={"Filter Metric Type"}
                  valueField={"metrictypeid"}
                  textField={"name"}
                />
              </div>
            </div>
          )}
          {this.state.metricUnits && this.state.metricUnits.length > 0 && (
            <div className="col-md-3 col-sm-6 mb-3 col-lg-3 clients_dropdown position-relative">
              <div className="custom-combobox-list">
                <CustomDropdownList
                  data={this.state.metricUnits}
                  handleChange={this.handleUnitChangeFilter}
                  placeholder={"Filter Metric Units"}
                  valueField={"metricunitid"}
                  textField={"name"}
                />
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end align-self-end flex-wrap ml-auto col">
            <AclAction
              type="permissions"
              entity={constants.ACL.METRIC_DATA_RESOURCE.TITLE}
              action={constants.ACL.METRIC_DATA_RESOURCE.ACTIONS.CREATE}
            >
              <div tbrentitytype={constants.ACL.ENTITY_TYPE.DIV} className="">
                <button
                  className={
                    "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                  }
                  type="button"
                  onClick={this.addMetric}
                >
                  <span className="material-icons-round icon-fs-16 mr-1">
                    add
                  </span>
                  {" " +
                    constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                      .ADD_BUTTON_TITLE}
                </button>
              </div>
            </AclAction>
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-iconcol-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>

        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                    .EDIT_METRIC_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                    .REMOVE_METRIC_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <Modal
          isOpen={this.state.documentModal}
          // toggle={this.closeDocumentModal}
          className={
            "modal-sm modal-w-header custom-modal modal-without-header primary-modal"
          }
        >
          <form
            className={"col-lg-12 col-sm-12 form-wrapper p-0"}
            onSubmit={(e) => {
              this.state.isEdit
                ? this.handleEditSubmit(e, this.state.metricid)
                : this.handleSubmit(e);
            }}
          >
            <ModalBody>
              <div className={"row"}>
                <div className="col-lg-12 col-sm-12 form-group position-relative ml-auto mr-auto">
                  <label for="companies">
                    Type <span className={"mandatory"}>* </span>
                  </label>
                  <div
                    className={`${
                      this.state.formErrors.metricTypeIdError
                        ? "is-invalid"
                        : ""
                    } custom-combobox-list`}
                  >
                    <CustomDropdownList
                      defaultValue={this.state.typeDropdown}
                      data={this.state.typeDropdown}
                      handleChange={this.handleTypeChange}
                      placeholder={"Select Metric Type"}
                      valueField={"metrictypeid"}
                      textField={"name"}
                    />
                  </div>
                  {this.state.formErrors.metricTypeIdError && (
                    <div className="invalid-tooltip d-block">
                      {constants.FORM.MANDATORY_FIELD}
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-sm-12 form-group position-relative ml-auto mr-auto">
                  <label for="metric-type">
                    Metric <span className={"mandatory"}>* </span>
                  </label>
                  <input
                    type="text"
                    name="metricTypeName"
                    className={`form-control ${
                      this.state.formErrors.metricNameError === true
                        ? "is-invalid"
                        : ""
                    } ${
                      this.state.formErrors.metricNameError === false ? "" : ""
                    }`}
                    id="metricType"
                    placeholder="Metric"
                    value={this.state.metricTypeName}
                    onChange={this.handleInputChange}
                  />
                  {this.state.formErrors.metricNameError && (
                    <div className="invalid-tooltip">
                      {constants.FORM.MANDATORY_FIELD}
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-sm-12 form-group position-relative ml-auto mr-auto">
                  <label htmlFor="metric-units">
                    Units <span className={"mandatory"}>* </span>
                  </label>
                  <div
                    className={`${
                      this.state.formErrors.metricUnitIdError
                        ? "is-invalid"
                        : ""
                    } custom-combobox-list`}
                  >
                    <CustomDropdownList
                      defaultValue={this.state.typeDropdown2}
                      id="metric-units"
                      data={this.state.typeDropdown2}
                      handleChange={this.handleMetricUnitChange}
                      placeholder={"Select Metric Unit"}
                      valueField={"metricunitid"}
                      textField={"name"}
                    />
                  </div>
                  {this.state.formErrors.metricUnitIdError && (
                    <div className="invalid-tooltip d-block">
                      {constants.FORM.MANDATORY_FIELD}
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn-outline-primary modal-btn"
                onClick={this.closeDocumentModal}
              >
                Cancel
              </Button>
              <Button className="modal-right-btn modal-btn" type="submit">
                {this.state.isEdit ? "Update" : "Save"}
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(mapStateToProps, null)(ListMetrics);
