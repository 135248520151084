import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import CustomDropdownList from "./CustomDropdownList";
import constants from "../Utils/constants";
class TemplatePicker extends Component {
  state = {
    modal: false,
    uid: this.props.uid,
    placeholder: null,
    originalValue: null,
    modifiedValue: null,
    templates: this.props.templates,
  };

  toggle = () => {
    let templates = this.state.templates;
    templates.forEach((t) => {
      t.is_selected = false;
    });
    this.setState({
      modal: !this.state.modal,
      templates: templates,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  componentDidMount = () => {
    let templates = this.state.templates;
    templates.forEach((t) => {
      t.is_selected = false;
    });
    this.setState({
      templates: templates,
    });
  };

  saveModal = () => {
    let update = null;
    update = {
      originalValue: this.state.modifiedValue,
    };
    this.setState(update, () => {
      this.props.onTemplateChangeHandler(
        this.state.uid,
        this.state.modifiedValue
      );
    });
  };

  onChangeHandler = (value) => {
    this.setState({
      modifiedValue: value,
    });
  };

  getRemainings = (template) => {
    const paramsPattern = /[^{}]+(?=})/g;
    return template.match(paramsPattern);
  };
  emptyRemainingSlugs = (replacedTemplate) => {
    let extractParams = this.getRemainings(replacedTemplate);
    if (extractParams && extractParams.length > 0) {
      extractParams.forEach((param) => {
        replacedTemplate = replacedTemplate.replace(
          "{" + param + "}",
          "&nbsp;"
        );
      });
    }
    return replacedTemplate;
  };

  parseHtml = (template) => {
    this.getRemainings(template).forEach((ind) => {
      let replacer = "";
      replacer = `<span class=''><custom class='${ind}' /></span>`;
      template = template.replace("{" + ind + "}", replacer);
    });
    let finalTemplate = this.emptyRemainingSlugs(template);
    return finalTemplate;
  };

  handleChange = (e) => {
    this.setState(
      {
        modifiedValue: e.id,
      },
      () => {
        let templates = this.state.templates;
        templates.forEach((t) => {
          t.is_selected = t.id === e.id;
        });
        this.setState({
          templates: templates,
        });
      }
    );
  };

  transform = (node) => {
    if (node.type === "tag" && node.name === "custom") {
      return <>{<p>{constants.COMPANY_DETAILS.CONTENT_PLACE_HOLDER}</p>}</>;
    }
  };

  render() {
    return (
      <>
        {!this.state.originalValue && (
          <div
            className="col-lg-12 col-sm-12 col-md-12 col-12 template-picker-chooser-btn"
            onClick={this.toggle}
          >
            Choose Template
          </div>
        )}
        <Modal
          key={`modal-${this.props.uid}`}
          isOpen={this.state.modal}
          toggle={this.closeModal}
          className={"modal-lg custom-modal primary-modal modal-without-header"}
        >
          <ModalBody className={"template-picker-modal"}>
            <div className="row">
              <form className={"col-lg-12 col-sm-12 form-wrapper"}>
                <div className={"row"}>
                  <div className="col-lg-12 col-sm-12 form-group position-relative">
                    <label htmlFor="companies">
                      Template <span className={"mandatory"}>* </span>
                    </label>
                    <div className="custom-combobox-list">
                      <CustomDropdownList
                        key={`cd-${this.props.uid}`}
                        data={this.state.templates}
                        handleChange={this.handleChange}
                        placeholder={"Select Template"}
                      />
                    </div>
                  </div>
                </div>
                {this.state.modifiedValue && (
                  <div className={"row"}>
                    <div className="col-lg-12 col-sm-12 form-group position-relative">
                      <label htmlFor="companies">Preview</label>
                      <div className="content_section">
                        {ReactHtmlParser(
                          this.parseHtml(
                            this.state.templates.find(
                              (t) => t.id === this.state.modifiedValue
                            ).content
                          ),
                          { transform: this.transform }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </ModalBody>
          {this.state.modifiedValue && (
            <ModalFooter>
              <Button
                className={"btn-outline-primary modal-btn"}
                onClick={this.closeModal}
              >
                Cancel
              </Button>{" "}
              <Button
                className={"modal-right-btn modal-btn"}
                onClick={this.saveModal}
              >
                Save
              </Button>
            </ModalFooter>
          )}
        </Modal>
      </>
    );
  }
}

export default TemplatePicker;
