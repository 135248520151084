import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import Search from "../Components/Search";
import queryString from "query-string";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import { formatRoute } from "react-router-named-routes";
import DocumentTitle from "../Utils/DocumentTitle";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import Toast from "../Utils/Toast";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import DailogNew from "../Components/DailogNew";
import { SearchServices } from "../Services/Search";

const tableHeader = [
  {
    id: "name",
    title: "Queue Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      color: "#0E0E0D",
    },
    visibility: true,
  },
  {
    id: "message_stats",
    title: "Total Jobs",
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", position: "inherit" },
    visibility: true,
  },
  {
    id: "idle_since",
    title: "Idle Since",
    headerStyle: { textAlign: "center" },
    rowStyle: { textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
];

const showActions = false;

class ListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      disableStatus: false,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      count: 0,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.SEARCH.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.SEARCH.LIST.ROUTE);
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    SearchServices.getJobsList()
      .then((res) => {
        let searchDetails = res.data.data;
        searchDetails = searchDetails.map((data) => {
          return {
            name: data.name,
            message_stats: data.message_stats,
            idle_since: ComapnyDetailsDateTimeFormatter(data.idleSince),
          };
        });
        this.setState({ rowData: searchDetails, count: 1 });
      })
      .catch((error) => {
        this.setState({ rowData: [] });
        console.log(error);
        Toast(error, "error");
      });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.SEARCH.LIST.ROUTE, {}),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.SEARCH.LIST.BREADCRUMB,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  reindexconfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Re-Index</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to Re-index the content?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "re-index" },
      dailogModalStyleType: "success-modal",
    });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "re-index":
        this.reindexContent();
        break;
      default:
        break;
    }
  };
  reindexContent = () => {
    SearchServices.postReindexContent()
      .then((res) => {
        this.getTableData();
        Toast(res.data.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  render() {
    // this.getlist()
    return (
      <>
        <aside className="main_content">
          <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
            <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 justify-content-between">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
              <button
                className={
                  "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center my-2 my-sm-0"
                }
                type="button"
                onClick={this.reindexconfirmation}
              >
                <span className="mt-text">Re-Index</span>
              </button>
            </div>
            <div className="comapnay_tbl">
              <div className="">
                <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
                  <DynamicTableComponentNew
                    columnData={tableHeader}
                    rowData={this.state.rowData}
                    pagePrevious={this.pagePrevious}
                    pageNext={this.pageNext}
                    pageSize={this.state.pageSize}
                    pageNo={this.state.pageNo}
                    handlePage={this.handlePage}
                    handleSort={this.handleSort}
                    sortColumn={this.state.sortColumn}
                    sortOrder={this.state.sortOrder}
                    count={this.state.count}
                    rowAction={this.rowAction}
                    colSpan={tableHeader.filter((h) => h.visibility).length}
                    showActions={showActions}
                    showPagination={true}
                    paginationClass={"center-pagination custom-pagination-new"}
                    handlePageSize={this.handlePageSize}
                  />
                </div>
                {/* Tooltips for icons in table, don't delete */}
                {/* {
              this.state.rowData.map((data) => {
                return (
                  <>
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP)}-${data.id}`}
                      multiline={true}
                    />
                  </>
                )
              })
            } */}
              </div>
            </div>
          </div>
        </aside>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSearch);
