import { Axios } from '../lib/axios'
export const CompanyServices = {
  getTotalCompanies,
  getTotalSegments,
  getCompanies,
  getCategories,
  getCompanyDetails,
  getCompanyContent,
  getTemplates,
  uploadImage,
  saveContent,
  addAuthor,
  removeAuthor,
  addCompany,
  editCompany,
  toggleCompany,
  removeCompany,
  getTaxonomies
}
function getTotalCompanies(url) {
  document.body.classList.add('second-loading-indicator')
  return Axios.get(url)
}
function getTotalSegments() {
  return Axios.get('segments')
}
function getCompanies(url) {
  return Axios.get(url)
}
function getCategories() {
  return Axios.get('categories?type=company&is_active=1')
}
function getCompanyDetails(company_id) {
  return Axios.get(`company/${company_id}`)
}
function getCompanyContent(company_id, segment_id) {
  return Axios.get(`company/${company_id}/segment/${segment_id}/content`)
}
function getTemplates() {
  return Axios.get('templates')
}
function uploadImage(content) {
  return Axios.post('uploadImage', content)
}
function saveContent(companyId, segmentId, content, action) {
  return Axios.post(`company/${companyId}/segment/${segmentId}/content/${action}`, content)
}
function addAuthor(companyId, segmentId, userId) {
  return Axios.post(`company/${companyId}/segment/${segmentId}/user/${userId}`, {})
}
function removeAuthor(companyId, segmentId, userId) {
  return Axios.delete(`companysegmentauthor/${userId}`)
}
function addCompany(body) {
  return Axios.post(`company`, body)
}
function editCompany(companyId, body) {
  return Axios.patch(`company/${companyId}`, body)
}
function toggleCompany(companyId, body) {
  return Axios.patch(`company/${companyId}`, body)
}
function removeCompany(companyId) {
  return Axios.delete(`company/${companyId}`)
}
function getTaxonomies() {
  return Axios.get('taxonomy')
}