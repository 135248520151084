import React, { Component } from 'react'
import { UserAnalyticLogServices } from '../Services/UserAnalyticLog'
import constants from "../Utils/constants";
import moment from "moment";
import queryString from 'query-string'
import DocumentTitle from '../Utils/DocumentTitle'
import { formatRoute } from 'react-router-named-routes'
import { activeMenuTab } from '../Store/Actions/User'
import { connect } from 'react-redux'
import { NavLink as RRNavLink } from 'react-router-dom'
import { NavLink } from 'reactstrap'
import { data } from 'jquery';
import Toast from '../Utils/Toast';
import ComapnyDetailsDateTimeFormatter from '../Utils/ComapnyDetailsDateTimeFormatter'
import CustomBreadcrumb from '../Components/CustomBreadcrumb'
import DynamicTableComponentsNew from '../Components/DynamicTableComponentsNew';
import Search from '../Components/Search'


const tableHeader = [
    { id: 'name', title: 'Client', sortable: true, visibility: true, headerClass: 'text-left name' },
    { id: 'end_date', title: 'Expiration Date', sortable: true, visibility: true, headerClass: 'text-left subscription_duration' },
    { id: 'status', title: 'Status', sortable: false, visibility: true, headerClass: 'text-center status', rowStyle: { textAlign: "center" } },
    { id: 'reports_purchased', title: 'Reports Purchased', sortable: false, visibility: true, headerClass: 'text-center reports_purchased', rowStyle: { textAlign: "center" } },
    { id: 'total_users_registered', title: 'Registered Users', sortable: false, visibility: true, headerClass: 'text-center total_users_registered', rowStyle: { textAlign: "center" } },
    { id: 'confirmed_users', title: 'Confirmed Users', sortable: false, visibility: true, headerClass: 'text-center confirmed_users', rowStyle: { textAlign: "center" } },
    { id: 'pending_users', title: 'Pending Users', sortable: false, visibility: true, headerClass: 'text-center pending_users', rowStyle: { textAlign: "center" } },
]

class Reports extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clickedValues: [],
            oneDayCount: 0,
            sevenDayCount: 0,
            MonthCount: 0,
            total_confirmed_user: 0,
            total_pending_user: 0,
            total_user: 0,
            percent_confirmed_user: 0,
            rowdata: [],
            count: 0,
            sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
            sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
            pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
            pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
            name: '',
            email: '',
            fromDate: moment().subtract(60, "days").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        }

    }
    capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        DocumentTitle(constants.APPLICATION_ROUTE.REPORTS.LIST.HEADER_TITLE)
        this.setState({
            pageNo: params.page ? parseInt(params.page) : constants.PAGINATION.DEFAULT_PAGE_NO,
            pageSize: params.page_size ? parseInt(params.page_size) : constants.PAGINATION.DEFAULT_PAGE_SIZE,
            sortColumn: "name",
            sortOrder: "asc",
            name: params.search_value ? params.search_value : '',

        }, () => {
            this.getTableData()
            this.loadUserCount();
            this.loadPendingRemindersReport();
        })
        this.props.activeMenuTab(constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE);
    }

    componentDidUpdate(prevprops, prevState) {
        if (prevState.fromDate !== this.state.fromDate || prevState.endDate !== this.state.endDate) {
            this.loadPendingRemindersReport();
            this.getTableData()
        }
    }

    // Email CHange Filteration start here

    loadPendingRemindersReport = () => {
        UserAnalyticLogServices.getPendingReminder(this.state.fromDate, this.state.endDate)
            .then((res) => {
                let response = res.data
                let converted = response.data.Converted[0].Converted
                let sent = response.data.CountSent[0].CountSent
                let conversion = Math.floor(converted / sent * 100);
                this.setState({
                    Sent: sent,
                    Converted: converted,
                    Conversion: conversion
                })
            })
            .catch((error) => {
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })

    }

    loadClientListReport = () => {
        UserAnalyticLogServices.getClientListReport()
            .then((res) => {
                let response = res && res.data && res.data.data
                let meta_data = res && res.data && res.data.meta

                this.setState({
                    total_confirmed_user: meta_data.total_pending_users,
                    total_pending_user: meta_data.total_pending_users,
                    total_user: meta_data.total_count,
                })
            })
            .catch((error) => {
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })
    }
    breadcrumbs = () => {
        let breadcrumbs = [
            {
                title: constants.APPLICATION_ROUTE.REPORTS.LIST.NAME,
                link: null,
                is_active: true
            }
        ]
        return breadcrumbs
    }
    loadUserCount = () => {
        UserAnalyticLogServices.getDayClientList()
            .then((res) => {
                let response = res && res.data
                this.setState({
                    oneDayCount: response.oneDayCount,
                    sevenDayCount: response.sevenDaysCount,
                    MonthCount: response.thirtyDayCount
                })
            })
            .catch((error) => {
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })

    }

    handlePageSize = (e) => {
        this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
            this.getTableData()
        })
    }

    handleInput = (e) => {
        this.setState({ name: e.target.value })
    }

    changeRoute = () => {
        let queryParams = ''
        if (this.state.sortOrder) {
            queryParams = '&sort_order=' +
                this.state.sortOrder + '&sort_column=' + this.state.sortColumn
        }
        if (this.state.name !== '') {
            queryParams += '&search_column=name&search_value=' + this.state.name
        }
        this.props.history.push({
            pathname: formatRoute(constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE, {}),
            search: '?page=' + this.state.pageNo + '&page_size=' + this.state.pageSize + queryParams
        })
    }

    pagePrevious = () => {
        this.setState((prevState) => ({
            pageNo: prevState.pageNo - 1
        }), () => this.getTableData())
    }

    pageNext = () => {
        this.setState((prevState) => ({
            pageNo: prevState.pageNo + 1
        }), () => this.getTableData())
    }

    handlePage = (input) => {
        this.setState({ pageNo: input }, () => this.getTableData())
    }
    handleSort = (clickedColumn, key) => {
        this.setState({ sortColumn: clickedColumn, segmentSort: false, sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc', }, () =>
            this.getTableData())
    }
    handleSearch = (e) => {
        this.setState({ pageNo: 1}, () => {
            this.getTableData()
        })
    }

    getTableData = () => {
        let queryParams = ''
        if (this.state.sortOrder) {
            queryParams = '&sort_order=' +
                this.state.sortOrder + '&sort_column=' + this.state.sortColumn
        }
        if (this.state.name !== '') {
            queryParams += '&search_column=name&search_value=' + this.state.name
        }
        this.changeRoute()
        let url = '/reports/clients'
        UserAnalyticLogServices.getClientListReport(`${url}?page=` +
            this.state.pageNo + '&page_size=' + this.state.pageSize + queryParams)
            .then((res) => {
                let clientData = res.data.data
                let meta_data = res && res.data && res.data.meta
                clientData = clientData.map((data) => {
                    let dateCaption = "", daystextStyle = "", endDateStyle
                    if (data.end_date) {
                        let current = moment().startOf('day');
                        let old_date = moment(data.end_date, "YYYY-MM-DD");
                        let month_counts = parseInt(moment.duration(old_date.diff(current)).asMonths())
                        let days_count = parseInt(moment.duration(old_date.diff(current)).asDays());
                        endDateStyle = (days_count > 0) ? "text-green" : "text-red"
                        daystextStyle = (month_counts >= 2) ? "text-green" : "text-red"
                        dateCaption = (month_counts >= 2 || month_counts <= -2) ? month_counts + " Months" : "(" + days_count + " Days)"
                    }
                    let confirmed_user_count = data.total_users_confirmed
                    let total_Reguser_count = data.total_users_registered
                    let percent_user_confirm = (total_Reguser_count > 0) ? parseInt((confirmed_user_count / total_Reguser_count) * 100) : 0
                    let confUserClass = ""
                    confUserClass = (confirmed_user_count > 0) ? '' : 'text-red'
                    return {
                        id: data.id,
                        name: <NavLink tag={RRNavLink} style={{ padding: 0, color: '#046CD9', fontWeight: 600, cursor: 'pointer' }} exact to={{ pathname: formatRoute(constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE, { id: data.id }), state: { id: data.id, name: data.name, fromDate: this.state.fromDate, endDate: this.state.endDate } }}><label className={`mb-0`}>{`${this.capitalizeFirstLetter(data.name)}`}</label></NavLink>,
                        end_date: data.end_date ? (<span className={`${endDateStyle} font-weight-semibold`}> { endDateStyle == 'text-red' ? ComapnyDetailsDateTimeFormatter(data.end_date, false,false) :  ComapnyDetailsDateTimeFormatter(data.end_date, false)}</span>) : (<span className='text-green font-weight-semibold'> {"NA"}</span>),
                        status: (dateCaption == "") ? (<span className='font-weight-semibold'> {"NA"}</span>) : (<span className={`${daystextStyle} font-weight-semibold`}> {dateCaption}</span>),
                        reports_purchased: (<span className='font-weight-semibold'> {data.no_of_reports_purchased ? data.no_of_reports_purchased : "0"}</span>),
                        total_users_registered: (<span className='font-weight-semibold'> {data.total_users_registered}</span>),
                        confirmed_users: (<span className={`${confUserClass} font-weight-semibold`}> {confirmed_user_count + "(" + Math.round(percent_user_confirm, 1) + "%)"}</span>),
                        pending_users: (<span className='font-weight-semibold'> {data.total_users_pending}</span>),
                    }

                })
                let confirmed_users = meta_data.total_confirmed_users
                let total_user = meta_data.total_registered_user
                let confirmed_user_per = (confirmed_users) / total_user * 100
                this.setState({
                    rowdata: clientData,
                    total_confirmed_user: confirmed_users,
                    total_pending_user: meta_data.total_pending_users,
                    total_user: total_user,
                    percent_confirmed_user: confirmed_user_per,
                    count: meta_data.total_count
                })
            })

            .catch((error) => {
                this.setState({ rowData: [], count: 0 });
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })
            .finally(() => document.body.classList.remove('second-loading-indicator'))
    }

    getDateDifference = (fromDate, endDate) => {
        const date1 = new Date(fromDate);
        const date2 = new Date(endDate);
        const diffTime = (date2 - date1);
        if (diffTime < 0) {
            return false
        }
        return true;
    }
    changeFromDate = (e) => {
        this.getDateDifference(e.target.value, this.state.endDate) && this.setState({ fromDate: e.target.value });
    }
    changeEndDate = (e) => {
        this.getDateDifference(this.state.fromDate, e.target.value) && this.setState({ endDate: e.target.value });
    }
    render() {

        return (
            <aside className="main_content">
                <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
                <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
                <div className="tbr-client-list">
                    <div className="row gutter-8">
                        <div className="col-xl-3">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4 col-xl-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <h5 className="font-weight-bold text-black2 mb-1">Users</h5>
                                            <div className="stats-group d-flex">
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-green">{this.state.total_confirmed_user}</h5>
                                                    <label>Confirmed</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-red">{this.state.total_pending_user}</h5>
                                                    <label>Pending</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-orange">{Math.round(this.state.percent_confirmed_user, 1)}</h5>
                                                    <label>% Confirmed</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-blue">{this.state.total_user}</h5>
                                                    <label>Total</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-4 col-xl-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <h5 className="font-weight-bold text-black2 mb-1">Pending Reminders</h5>
                                            <div className="stats-group d-flex">
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-green">{this.state.Sent}</h5>
                                                    <label>Sent</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-blue">{this.state.Converted}</h5>
                                                    <label>Converted</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-orange">{this.state.Conversion}</h5>
                                                    <label>% Conversion</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-4 col-xl-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <h5 className="font-weight-bold text-black2 mb-1">Client Logins</h5>
                                            <div className="stats-group d-flex">
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-blue">{this.state.oneDayCount}</h5>
                                                    <label>Daily</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-blue">{this.state.sevenDayCount}</h5>
                                                    <label>Weekly</label>
                                                </div>
                                                <div className="stats-block flex-grow-1 d-flex flex-column">
                                                    <h5 className="font-weight-bold text-blue">{this.state.MonthCount}</h5>
                                                    <label>Monthly</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9">
                            <div className="d-flex flex-wrap client-list-filter-block">
                                <Search
                                    handleSearch={this.handleSearch}
                                    handleInput={this.handleInput}
                                    value={this.state.name}
                                    data-testid="list-benchmark-search"
                                    placeHolder={"Search with Client Name"}
                                />
                            </div>
                            <div className="custom-table-block first-col-fixed-block sort-table-block nowrap-table-block client-list-table-block" >
                                <DynamicTableComponentsNew
                                    columnData={tableHeader}
                                    rowData={this.state.rowdata}
                                    btnClick={this.btnClick}
                                    pagePrevious={this.pagePrevious}
                                    pageNext={this.pageNext}
                                    pageSize={this.state.pageSize}
                                    pageNo={this.state.pageNo}
                                    handlePage={this.handlePage}
                                    handleSort={this.handleSort}
                                    handlePageSize={this.handlePageSize}
                                    sortColumn={this.state.sortColumn}
                                    sortOrder={this.state.sortOrder}
                                    count={this.state.count}
                                    rowAction={this.rowAction}
                                    colSpan={tableHeader.filter((h) => h.visibility).length}
                                    showPagination={true}
                                    paginationClass={"center-pagination custom-pagination-new"}
                                //showActions={showActions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </aside>)

    }
}
function mapDispatchToProps(dispatch) {
    return {
        activeMenuTab: (tab) => {
            dispatch(activeMenuTab(tab))
        },
    }
}
export default connect(null, mapDispatchToProps)(Reports)