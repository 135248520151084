import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";

class MSAL_Instance {
    constructor() {
        this.instance = new PublicClientApplication(msalConfig);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.get_SSO_ID_Token = this.get_SSO_ID_Token.bind(this);
        this.get_msal_details = this.get_msal_details.bind(this);
        this.check_sso_token = this.check_sso_token.bind(this);
        this.otherAPIs = [];
        this.isPopUpOpened = false;
    }

    async check_sso_token() {
        let token;
        if (localStorage.getItem("SSO") === "true") {
            token = await this.get_SSO_ID_Token();
        }
        return token;
    }

    async handleLogout() {
        return await this.instance.logoutPopup();
    }

    async handleLogin() {
        return await this.instance.loginPopup(loginRequest);
    }

    subscribeTokenRefresh(cb) {
        this.otherAPIs.push(cb);
    }

    onRrefreshed(token) {
        this.otherAPIs.forEach(cb => cb(token));
        this.otherAPIs = [];
    }

    async get_msal_details() {
        let instance = this.instance;
        let account = instance.getAllAccounts()[0];
        let token;
        try {
            let request = {
                ...loginRequest,
                account
            }
            token = await instance.acquireTokenSilent(request);             // gets token from cache
        } catch (e) {
            console.log(e);
            try {
                if (this.isPopUpOpened) {
                    /**
                     * return promise in case of multiple requests at once...
                     */
                    token = new Promise((resolve, reject) => {
                        this.subscribeTokenRefresh((token) => {
                            resolve(token);
                        })
                    })
                }
                else {
                    /**
                     * Token is generated from refresh token
                     */
                    token = await instance.acquireTokenPopup(loginRequest);
                    this.onRrefreshed(token);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        return token;
    }

    async get_SSO_ID_Token() {
        let token = await this.get_msal_details();
        return token && token.idToken;
    }
}

let msalInstance = new MSAL_Instance();

export {
    msalInstance
};