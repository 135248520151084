import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import DocumentTitle from "../Utils/DocumentTitle";
import $ from "jquery";
import { FileUploadServices } from "../Services/FileUpload";
import { ClientServices } from "../Services/Client";
import Toast from "../Utils/Toast";

const tableHeader = [
  {
    id: "company",
    title: "Company",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_name",
    title: "Line of Business",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory1_name",
    title: "LOB Segment Group",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory2_name",
    title: "LOB Segment",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory3_name",
    title: "LOB Subsegment",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory4_name",
    title: "LOB Subsegment4",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "lob_subcategory5_name",
    title: "LOB Subsegment5",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "geo_name",
    title: "Geography",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "geosubcategory_name",
    title: "Geo Category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "country_name",
    title: "Geo Sub-category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "vertical_name",
    title: "Vertical",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "verticalcategory_name",
    title: "Vertical Category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "verticalsubcategory_name",
    title: "Vertical Sub-category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "metrictype_name",
    title: "Metric Type",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "metric_name",
    title: "Metric",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "w80",
    rowClass: "w80",
    visibility: true,
  },
  {
    id: "metric_value",
    title: "Value",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "center" },
    headerClass: "text-center w80",
    rowStyle: { textTransform: "capitalize", textAlign: "center" },
    rowClass: "w80",
    visibility: true,
  },
];

const showActions = true;
class ListTransactionRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      tableHeaderCustom: [],
      sortColumn: null,
      sortOrder: null,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: 100,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      isOpen: false,
      segmentList: [],
      statusDropdown: [
        {
          id: "all",
          name: "All",
          value: "all",
          is_selected: true,
        },
        {
          id: "failed",
          name: "Failed",
          value: "failed",
          is_selected: false,
        },
      ],
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.TEMPLATE.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column ? params.sort_column : null,
        sortOrder: params.sort_order ? params.sort_order : null,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    ClientServices.getSegmentDetails(1)
      .then((res) => {
        let segmentsResponse = res && res.data && res.data.data;
        let segmentList = [...segmentsResponse];
        segmentList.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        segmentList = segmentList.map((s) => {
          s.is_selected = true;
          return s;
        });
        this.setState({ segmentList: segmentList });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE);
  }
  handleSegmentChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    segments.forEach((s) => {
      s.is_selected = selectedSegments.includes(s.id);
    });
    this.setState({ segmentList: segments });
  };
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    switch (config.type) {
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE,
                data,
                e
              );
            }}
            className={"dynamicTableActionButton btn btn-danger"}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            }
            data-iscapture="true"
          >
            <i className={"fa fa-trash"} />
            <CustomReactTooltip
              id={`tooltip-${Slugify(
                constants.APPLICATION_ROUTE.TEMPLATE.LIST
                  .REMOVE_TEMPLATE_TOOLTIP
              )}-${data.id}`}
              multiline={true}
            />
          </button>
        </AclAction>
      </>
    );
  };

  getTableData = () => {
    let mergedTableHeader = [];
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    let transactionId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.changeRoute();
    let url = "/transaction/" + transactionId + "/metrics";
    FileUploadServices.getMetricTransactionRecords(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let templatesData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        let tableHeaderCustom = [];
        if (templatesData.length > 0) {
          templatesData.forEach((row) => {
            if (row && row.quantmetrics && row.quantmetrics.length > 0) {
              row.quantmetrics.forEach((q, key) => {
                var index = tableHeaderCustom.findIndex(
                  (element) => element.id == q.quarteryear
                );
                index < 0 &&
                  tableHeaderCustom.push({
                    id: q.quarteryear,
                    title: q.quarteryear,
                    year: q.year,
                    sortable: false,
                    headerStyle: {
                      textTransform: "capitalize",
                      textAlign: "left",
                    },
                    rowStyle: {
                      textTransform: "capitalize",
                      textAlign: "left",
                    },
                    headerClass: "w80",
                    rowClass: "w80",
                    visibility: true,
                  });
              });
            }
          });
          tableHeaderCustom.sort((a, b) => a.year - b.year);
          mergedTableHeader = tableHeader.concat(tableHeaderCustom);
        }
        templatesData = templatesData.map((data) => {
          let metricsQ = [];
          data.quantmetrics.forEach((q) => {
            metricsQ[q.quarteryear] = q.amount;
          });
          return {
            id: data.id,
            company:
              data.quantmetrics && data.quantmetrics.length
                ? data.quantmetrics[0].company.name
                : "N/A",
            lob_name: data.lob ? data.lob.name : "N/A",
            lob_subcategory1_name: data.lob_subcategory1
              ? data.lob_subcategory1.name
              : "N/A",
            lob_subcategory2_name: data.lob_subcategory2
              ? data.lob_subcategory2.name
              : "N/A",
            lob_subcategory3_name: data.lob_subcategory3
              ? data.lob_subcategory3.name
              : "N/A",
            lob_subcategory4_name: data.lob_subcategory4
              ? data.lob_subcategory4.name
              : "N/A",
            lob_subcategory5_name: data.lob_subcategory5
              ? data.lob_subcategory5.name
              : "N/A",
            geo_name: data.geography ? data.geography.name : "N/A",
            geosubcategory_name: data.geosubcategory
              ? data.geosubcategory.name
              : "N/A",
            country_name: data.country ? data.country.name : "N/A",
            vertical_name: data.vertical ? data.vertical.name : "N/A",
            verticalcategory_name: data.verticalcategory
              ? data.verticalcategory.name
              : "N/A",
            verticalsubcategory_name: data.verticalsubcategory
              ? data.verticalsubcategory.name
              : "N/A",
            metrictype_name: data.metrictype ? data.metrictype.name : "N/A",
            metric_name: data.metric ? data.metric.name : "N/A",
            metric_value: data.metric_value ? data.metric_value : "N/A",
            ...metricsQ,
          };
        });
        this.setState(
          {
            rowData: templatesData,
            count: count,
            tableHeaderCustom: mergedTableHeader,
          },
          () => {
            $(".comapany_table").css(
              "min-width",
              $(".comapany_table").find("thead").innerWidth()
            );
          }
        );
      })
      .catch((error) => {
        this.setState({
          rowData: [],
          count: 0,
          tableHeaderCustom: mergedTableHeader,
        });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      ); //removes the loading indicator
  };

  exportList = () => {};

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TEMPLATE.EDIT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.STATUS:
        this.toggleCompanyConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE:
        this.removeTemplateConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  addTemplate = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.TEMPLATE.CREATE.ROUTE,
        {}
      ),
    });
  };

  changeRoute = () => {
    let queryParams = "";
    let transactionId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let state = this.props.match.params.state
      ? this.props.match.params.state
      : null;

    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_METRIC_TRANSACTION_DETAILS
          .ROUTE,
        { id: transactionId, state: state }
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DOWNLOAD.LIST.NAME,
        link: {
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE,
            {}
          ),
          search: "?selected_tab=metric",
        },
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.METRIC_TRANSACTION.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  handleDownload = () => {
    let segment_ids = this.state.segmentList
      .filter((f) => f.is_selected)
      .map((s) => s.id);
    FileUploadServices.downloadExcel(segment_ids)
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "standardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          let segmentList = this.state.segmentList.map((s) => {
            s.is_selected = true;
            return s;
          });
          this.setState({ segmentList: segmentList });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleReject = () => {
    FileUploadServices.rejectTransaction(this.props.match.params.id)
      .then((res) => {
        if (res.status === 200)
          Toast(
            constants.MANAGE_CONTENT_PERMISSIONS.COMPANY_METRIC.REJECTED,
            "success"
          );
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE,
            {}
          ),
          search: "?selected_tab=metric",
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleApprove = () => {
    FileUploadServices.approveTransaction(this.props.match.params.id)
      .then((res) => {
        if (res.status === 200)
          Toast(
            constants.MANAGE_CONTENT_PERMISSIONS.COMPANY_METRIC.APPROVED,
            "success"
          );
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE,
            {}
          ),
          search: "?selected_tab=metric",
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <h3 className="text-black2 font-weight-bold mb-3">
              {constants.APPLICATION_ROUTE.METRIC_TRANSACTION.LIST.HEADER_TITLE}
            </h3>
            <div className="ml-auto mb-3">
              {this.props.match.params.state === "PracticeManagerReview" && (
                <div className="">
                  <AclAction
                    type={constants.MANAGE_CONTENT_PERMISSIONS.KEY}
                    entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
                    action={
                      constants.MANAGE_CONTENT_PERMISSIONS.COMPANY_METRIC.REJECT
                    }
                  >
                    <button
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      className={
                        "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center " +
                        constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_REJECT
                          .BUTTON_CLASS
                      }
                      type="button"
                      onClick={this.handleReject}
                    >
                      {" " +
                        constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_REJECT
                          .BUTTON_TITLE}
                      <span className="material-icons-round icon-fs-16 ml-1">
                        clear
                      </span>
                    </button>
                  </AclAction>
                  <AclAction
                    type={constants.MANAGE_CONTENT_PERMISSIONS.KEY}
                    entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
                    action={
                      constants.MANAGE_CONTENT_PERMISSIONS.COMPANY_METRIC
                        .APPROVE
                    }
                  >
                    <button
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      className={
                        "btn btn-custom btn-primary ml-3 icon-text-btn title-btn d-inline-flex align-items-center " +
                        constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_APPROVE
                          .BUTTON_CLASS
                      }
                      type="button"
                      onClick={this.handleApprove}
                    >
                      {" " +
                        constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_APPROVE
                          .BUTTON_TITLE}
                      <span className="material-icons-outlined icon-fs-16 ml-1">
                        done_all
                      </span>
                    </button>
                  </AclAction>
                </div>
              )}
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-18px">
              <div className="w-100-search border-search search-box-shadow-2">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Filter Name"}
                />
              </div>
            </div>
          </div>
          <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
            <DynamicTableComponentNew
              columnData={this.state.tableHeaderCustom}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              count={this.state.count}
              rowAction={this.rowAction}
              colSpan={tableHeader.filter((h) => h.visibility).length}
              showActions={showActions}
              // className={`full-grid`}
              showPagination={true}
              paginationClass={"center-pagination custom-pagination-new"}
              handlePageSize={this.handlePageSize}
            />
          </div>
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListTransactionRecords);
