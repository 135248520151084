import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/ecosystemDetailsReference'

const ecosystemCategories = DETAILS_REF.ecosystemCategories
const ecosystemSegments = DETAILS_REF.ecosystemSegments
const ecosystemDetails = DETAILS_REF.ecosystemDetails
const categories = DETAILS_REF.categories
const ecosystemContent = DETAILS_REF.ecosystemContent
const ecosystemTemplates = DETAILS_REF.ecosystemTemplates
const ecosystemTransactionDetails = DETAILS_REF.ecosystemTransactionDetails

const ecosystemDetailsReducer = combineReducers({
  ecosystemCategories,
  ecosystemSegments,
  ecosystemDetails,
  categories,
  ecosystemContent,
  ecosystemTemplates,
  ecosystemTransactionDetails
})

export default ecosystemDetailsReducer
