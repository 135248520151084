import { company_details as DETAILS } from '../actionConstants'

const companyCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const companySegments = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const companyDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const companyContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.COMPANY_CONTENT:
      return action.payload
    default:
      return state
  }
}

const companies = (state = [], action) => {
  switch (action.type) {
    case DETAILS.COMPANIES:
      return action.payload
    default:
      return state
  }
}

const templates = (state = [], action) => {
  switch (action.type) {
    case DETAILS.TEMPLATES:
      return action.payload
    default:
      return state
  }
}

const transactionDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.TRANSACTION_DETAILS:
      return action.payload
    default:
      return state
  }
}

export {
  companyCategories,
  companySegments,
  companyDetails,
  categories,
  companyContent,
  companies,
  templates,
  transactionDetails
}
