import React, { Component } from 'react'
import constants from '../Utils/constants'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { activeMenuTab } from '../Store/Actions/User'
import CustomBreadcrumb from '../Components/CustomBreadcrumb'
import DocumentTitle from '../Utils/DocumentTitle'
import ListClients from './ListClients'
import AssignedClients from './AssignedClients'
import AclAction from '../Components/ACL/AclAction'
import isRoleAccountManager from '../Utils/isRoleAccountManager'
class ListClientTabs extends Component {

    state = {
        totalTabs: [],
        selectedTab: constants.APPLICATION_ROUTE.CLIENT.TABS[0].KEY
    }

    onContentChangeTypeHandler = (s) => {
        this.setState({
            selectedTab: s
        })
    }

    breadcrumbs = () => {
        let breadcrumbs = [
            {
                title: constants.APPLICATION_ROUTE.CLIENT.LIST.HEADER_TITLE,
                link: null,
                is_active: false
            }
        ]
        return breadcrumbs
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        let tabs = constants.APPLICATION_ROUTE.CLIENT.TABS
        tabs = tabs.filter((tab) => tab.HIDE_TAB === false)
        this.setState({
            totalTabs: tabs,
            selectedTab: params.selected_tab ? params.selected_tab : tabs[0].KEY,
            modifiedValueType: params.state ? params.state : tabs[0].STATE,
            modifiedValueRole: params.role ? params.role : tabs[0].ROLE
        })
        this.props.activeMenuTab(constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE)
    }

    render() {
        let selected = this.state.totalTabs.find((f) => f.KEY === this.state.selectedTab)
        DocumentTitle(selected && selected.TAB_TITLE ? selected.TAB_TITLE : '')
        return (
            <aside className="main_content">
                <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
                <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
                    <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
                </div>
                <div className="comapnay_tbl">
                    <div className="">
                        {
                            isRoleAccountManager(this.props.home.loginUserSuccess.role_id) ? <>

                                <AclAction type={constants.ACL.PERMISSION_KEY} entity={constants.ACL.CLIENT_RESOURCE.TITLE} action={constants.ACL.CLIENT_RESOURCE.ACTIONS.TABS}>
                                    <div className="btn-items-card-wrapper tabs-card-wrapper">
                                        <div className="btn-items-card card custom-card nav-tabs-card">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <Nav tabs tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION} className="custom-nav-tabs">
                                                        {
                                                            this.state.totalTabs.map((tab) =>
                                                                <NavItem key={tab.KEY}>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.selectedTab === tab.KEY })}
                                                                        onClick={() => { this.onContentChangeTypeHandler(tab.KEY) }}
                                                                    >
                                                                        {tab.TAB_TITLE}
                                                                    </NavLink>
                                                                </NavItem>
                                                            )
                                                        }
                                                    </Nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AclAction>
                                <TabContent activeTab={this.state.selectedTab}>
                                    {
                                        this.state.totalTabs.map((tab) =>
                                            <TabPane tabId={tab.KEY} key={tab.KEY}>
                                                {(this.state.selectedTab && this.state.selectedTab === tab.KEY && tab.KEY === 'all-clients') &&
                                                    <ListClients
                                                        {...this.props}
                                                        selectedTab={this.state.selectedTab}
                                                    />
                                                }
                                                {(this.state.selectedTab && this.state.selectedTab === tab.KEY && tab.KEY === 'assigned-clients') &&
                                                    <AssignedClients
                                                        {...this.props}
                                                        selectedTab={this.state.selectedTab}
                                                    />
                                                }
                                            </TabPane>
                                        )
                                    }
                                </TabContent>
                            </>
                                :
                                <ListClients
                                    {...this.props}
                                    pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                                    pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                                    className="last-2icons-last-2cols-fixed-block"
                                />
                        }
                    </div>
                </div>
                </div>
            </aside>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return {
        activeMenuTab: (tab) => {
            dispatch(activeMenuTab(tab))
        }
    }
}
const mapStateToProps = state => {
    return {
        home: state.home
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListClientTabs)