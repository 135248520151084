import React, { useEffect, useState } from "react";
import { parseCssToObj } from "../Utils/helper";

const CustomTextarea = (props) => {
  const [customStyle, setCustomStyle] = useState({});

  useEffect(() => {
    const getCustomStyle = async (htmlStyle) => {
      if (htmlStyle) {
        const htmlStyleObj = parseCssToObj(htmlStyle);

        if (htmlStyleObj) {
          // const fontValue = htmlStyleObj["font-size"]
          //   ? htmlStyleObj["font-size"].split("px")[0]
          //   : "24";
          setCustomStyle({
            // fontFamily: htmlStyleObj["font-family"],
          }); // Set the style in state
        } else {
          setCustomStyle({}); // Set the style in state
        }
      }
    };

    // Call getCustomStyle with the necessary arguments
    getCustomStyle(props.modifiedTitleStyle);
  }, [props]);

  return (
    <textarea
      style={{ width: "100%", ...customStyle }}
      rows={4}
      value={props.modifiedTitleValue}
      onChange={props.onTitleChangeHandler}
    />
  );
};

export default CustomTextarea;
