import React from "react";
import { ButtonToggle, FormGroup } from 'reactstrap';
import { msalInstance } from "../Auth/MSAL-Instance";
import { useHistory } from 'react-router';



/**
 * Renders a button which, when selected, will open a popup for login
 */
const SSO_LOGIN = (props) => {
    const history = useHistory();

    const loginHandler = () => {
        msalInstance.handleLogin()
            .then(res => {
                localStorage.setItem("SSO", true);
                props.handleLogin();
            })
            .catch(e => console.log(e));
    }

    return (
        <FormGroup>
            <ButtonToggle color="primary" onClick={loginHandler}>Log In using SSO</ButtonToggle>
        </FormGroup>
    );
}
export default SSO_LOGIN