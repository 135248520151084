import { home as HOME } from '../actionConstants'

const loginUserSuccess = (state = {}, action) => {
  switch (action.type) {
    case HOME.LOGIN_USER_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const loginUserFailure = (state = {}, action) => {
  switch (action.type) {
    case HOME.LOGIN_USER_FAILURE:
      return action.payload
    default:
      return state
  }
}

const segments = (state = [], action) => {
  switch (action.type) {
    case HOME.SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const activeMenuTab = (state = {}, action) => {
  switch (action.type) {
    case HOME.ACTIVE_MENU_TAB:
      return action.payload
    default:
      return state
  }
}

const getTbriUsers = (state = [], action) => {
  switch (action.type) {
    case HOME.TBRI_USERS:
      return action.payload
    default:
      return state
  }
}

const getCurrencies = (state = [], action) => {
  switch (action.type) {
    case HOME.CURRENCIES:
      return action.payload
    default:
      return state
  }
}

const userRoles = (state = [], action) => {
  switch (action.type) {
    case HOME.USER_ROLES:
      return action.payload
    default:
      return state
  }
}

const userDesignation = (state = [], action) => {
  switch (action.type) {
      case HOME.USER_DESIGNATION:
        return action.payload
      default:
        return state
    }
}

const openPreference = (state ={}, action) => {
  switch (action.type) {
    case HOME.OPEN_PREFERENCE:
      return action.payload
    default:
      return state
  }
}


export {
  loginUserSuccess,
  loginUserFailure,
  segments,
  activeMenuTab,
  getTbriUsers,
  getCurrencies,
  userRoles,
  userDesignation,
  openPreference
}
