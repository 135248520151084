import React from "react";

class AccessDenied extends React.Component {
  render() {
    return (
      <aside className="main_content access-denied">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="ml-auto mr-auto text-center access-denied-wrapper">
            <h1>
              {" "}
              <i
                className="fa fa-times-circle-o text-danger"
                aria-hidden="true"
              ></i>{" "}
              <span>You don't have permission to view this page.</span>
            </h1>
            <p>
              The page requires proper authorization for access.
              <br />
              We suggest that you to get in touch with your administrator for
              help.
            </p>
          </div>
        </div>
      </aside>
    );
  }
}

export default AccessDenied;
