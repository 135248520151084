import React, { useEffect, useState, useRef } from 'react';
import { ReportsServices } from '../../Services/Reports';
import constants from '../../Utils/constants';
import ReportsChart from '../ReportsChart';
import Toast from '../../Utils/Toast';

var data = [
    {
        date: '2022/02/01',
        Companies: 23,
        ['Market Landscapes']: 34,
        ['Market Forecasts']: 34,
        Benchmarks: 45,
        Visualisation: 34,
        Taxonomy: 20,
        Projects: 56,
        ['My Boards']: 67

    },
    {
        date: '2022/03/01',
        Companies: 23,
        ['Market Landscapes']: 34,
        ['Market Forecasts']: 34,
        Benchmarks: 45,
        Visualisation: 34,
        Taxonomy: 20,
        Projects: 56,
        ['My Boards']: 67

    },
    {
        date: '2022/04/01',
        Companies: 23,
        ['Market Landscapes']: 34,
        ['Market Forecasts']: 34,
        Benchmarks: 45,
        Visualisation: 34,
        Taxonomy: 20,
        Projects: 56,
        ['My Boards']: 67

    }
]

const seriesData = [
    {
        name: 'Companies',
        field_name: 'Companies'
    },
    {
        name: 'Market Landscapes',
        field_name: 'Market Landscapes'
    },
    {
        name: 'Market Forecasts',
        field_name: 'Market Forecasts'
    },
    {
        name: 'Benchmarks',
        field_name: 'Benchmarks'
    },
    {
        name: 'Visualisation',
        field_name: 'Visualisation'
    },
    {
        name: 'Taxonomy',
        field_name: 'Taxonomy'
    },
    {
        name: 'Projects',
        field_name: 'Projects'
    },
    {
        name: 'My Boards',
        field_name: 'My Boards'
    }
]

export default function ModuleWiseDataChart(props) {

    const [chartData, setChartData] = useState([]);
    const [series, setSeries] = useState([]);

    useEffect(() => {
        const {
            userId,
            startDate,
            endDate
        } = props;
        if (userId && startDate && endDate) {
            // change 583 with userId
          let body=  {
            user_id:userId,
            start_date:startDate,
            end_date:endDate,
            }
            ReportsServices.getModuleWiseCountData(body)
                .then((res) => {
                    const {
                        requiredData,
                        seriesArray
                    } = formatData(res.data.data);
                    setChartData(requiredData);
                     setSeries(seriesArray);
                })
                .catch((error) => {
                    Toast(error && error.data && error.data.error
                        ? error.data.error
                        : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
                })
        }
        else{
            setChartData([])
        }
    }, [props.userId, props.startDate, props.endDate])

    const formatData = (data) => {
        let requiredData = [];
        let seriesArray = [];
        data.forEach(element => {
            let category = element.moduleName;
            let date = new Date(element.date).getTime();
            let month = element.date;
            let categoryIndex = requiredData.findIndex(element => element.date === month);
            if (categoryIndex > -1) {
                let dataObj = requiredData[categoryIndex];
                dataObj[category] = element.value;
            }
            else {
                let obj = {
                    month: element.date,
                    date,
                    [category]: element.value
                }
                requiredData.push(obj);
            }
            let is_month_in_seriesArray = seriesArray.find(ele => ele.name === category);
            if (!is_month_in_seriesArray) {
                seriesArray.push({
                    name: category,
                    field_name: category
                })
            }
        });
        return {
            requiredData,
            seriesArray
        };
    }

    return (
        chartData && chartData.length ?
            <ReportsChart
                id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.MODULE_WISE_PAGE_VIEWS.ID}
                legend_id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.MODULE_WISE_PAGE_VIEWS.LEGEND_ID}
                chartData={chartData}
                series={series}
                showBullets={true}
                axis_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE}
                series_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.COLUMN}
            />
            :
            <h4 className='text-center'>No Data Available</h4>
    )
}