import { customer_study_details as DETAILS } from '../actionConstants'

const customerStudyCategories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CUSTOMER_STUDY_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const customerStudySegments = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMER_STUDY_SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const customerStudyDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMER_STUDY_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const customerStudyContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMER_STUDY_CONTENT:
      return action.payload
    default:
      return state
  }
}

const customerStudyTemplates = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CUSTOMER_STUDY_TEMPLATES:
      return action.payload
    default:
      return state
  }
}

const customerStudyTransactionDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMER_STUDY_TRANSACTION_DETAILS:
      return action.payload
    default:
      return state
  }
}

export {
  customerStudyCategories,
  customerStudySegments,
  customerStudyDetails,
  categories,
  customerStudyContent,
  customerStudyTemplates,
  customerStudyTransactionDetails
}
