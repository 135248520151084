import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export const ChartInit = (divId) => {
  // Create root and chart
  var root = am5.Root.new(divId);

  root.setThemes([am5themes_Animated.new(root)]);

  var chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panY: false,
      wheelY: "zoomX",
      layout: root.verticalLayout,
      maxTooltipDistance: 0,
    })
  );

  return { root, chart };
};

export const createXYAxis = (root, chart) => {
  // Create Y-axis
  var yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      extraTooltipPrecision: 1,
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  );

  // Create X-Axis
  var xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      baseInterval: {
        timeUnit: "day",
        count: 1,
      },
      renderer: am5xy.AxisRendererX.new(root, {}),
    })
  );

  return { xAxis, yAxis };
};

// Create series
export function createSeries(name, field, root, chart, xAxis, yAxis, data,color) {
  var series = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: field,
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {}),
    })

  );

  series.bullets.push(function () {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 5,
        fill: series.get("fill"),
      }),
    });
  });

  series.strokes.template.set("strokeWidth", 2);

  series
    .get("tooltip")
    .label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}");
  series.data.setAll(data);
  return series;
}

export function createColumnSeries(name, field, root, chart, xAxis, yAxis, data) {
  var series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: field,
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {}),
    })
  );
  series.data.setAll(data);
  }

export function makeSeries(
  name,
  fieldName,
  root,
  chart,
  xAxis,
  yAxis,
  data,
  fillOpacity,
  hidden = false,
  linechart
) {
  var series = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: fieldName,
      valueXField: "date",
    })
  );
  if(fillOpacity)
  {
    series.fills.template.setAll({
      visible: true,
      fillOpacity: 1
    });
  }

  series.data.setAll(data);
  if(linechart){
  var seriesRangeDataItem = xAxis.makeDataItem({});
  var seriesRange = series.createAxisRange(seriesRangeDataItem);
  seriesRange.fills.template.setAll({
    visible: true,
    opacity: 0.4,
    
  });
  seriesRange.fills.template.set("fillPattern", am5.LinePattern.new(root, {
    // color: am5.color(0xff0000),
    rotation: 45,
    strokeWidth: 2,
    width: 2000,
    height: 2000,
    // fill:am5.color(0xffffff),
    stroke: root.interfaceColors.get("positive"),
    fill: root.interfaceColors.get("positive"),
  }));
  seriesRange.strokes.template.set("stroke", am5.color(0xff0000));
  }
  

  series.bullets.push(function () {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 5,
        fill: series.get("fill"),
        stroke: root.interfaceColors.get("background"),
        strokeWidth: 2,
        tooltipText: "{valueX.formatDate()}:{valueY}",
        // showTooltipOn: "always",
        tooltip: am5.Tooltip.new(root, {}),
      }),
    });
  });

  if (hidden) {
    series.hide();
  } else {
    series.appear();
  }
}

export const createCursor = (root, chart, xAxis, yAxis) => {
  // Add cursor
  chart.set(
    "cursor",
    am5xy.XYCursor.new(root, {
      behavior: "zoomXY",
      xAxis: xAxis,
    })
  );

  xAxis.set(
    "tooltip",
    am5.Tooltip.new(root, {
      themeTags: ["axis"],
    })
  );

  yAxis.set(
    "tooltip",
    am5.Tooltip.new(root, {
      themeTags: ["axis"],
    })
  );
};

export const AddLegend=(chart,root)=>
{
  let legend = chart.children.push(am5.Legend.new(root, { }));
  legend.data.setAll(chart.series.values);
}
