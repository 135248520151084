
import React, { Component } from 'react'
import constants from '../Utils/constants'

class DynamicTableComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  totalPages = () => {
    if (this.props.count === 0) {
      return 1
    }
    return parseInt(Math.ceil(this.props.count / this.props.pageSize))
  }
  getPagingRange = (current, { min = 1, total = 20, length = 5 } = {}) => {
    if (length > total) length = total
    let start = current - Math.floor(length / 2)
    start = Math.max(start, min)
    start = Math.min(start, min + total - length)
    return Array.from({ length: length }, (el, i) => start + i)
  }
  entireRowAction = (row, e) => {
    if (this.props.entireRowAction && this.props.entireRowAction.title) {
      this.props.rowAction(this.props.entireRowAction.title, row, e)
    }
  }
  render() {
    return (
      <div className="comapnay_tbl col-xl-12 col-sm-offset-right-1">
        <div className="row">
          <div className="mb-3 table-responsive">
            <div className='table-responsive2'>
              <table className={`comapany_table dataTable responsive nowrap ${this.props.className ? this.props.className : ''}`} cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    {this.props.columnData.map((column, key) =>
                      (column.visibility) ?
                        <th key={key} className={`${((this.props.sortColumn === column.id) ? this.props.sortOrder : (column.sortable ? 'default' : null))} ${column.headerClass ? column.headerClass : null}`} onClick={() => { column.sortable && this.props.handleSort(column.id, key) }} style={column.headerStyle}>
                          {column.title}
                        </th> : null)}
                  </tr>
                </thead>
                <tbody>
                  {this.props.rowData.length > 0 && this.props.rowData.map((row, index) =>
                    <tr key={index} onClick={(e) => { this.entireRowAction(row, e) }}>
                      {this.props.columnData.map((val, i) =>
                      (val.visibility ?
                        <td key={`${index + '-' + i}`} style={val.rowStyle} className={val.rowClass ? val.rowClass : null}>
                          {row[val.id]? row[val.id]: '-'}
                        </td>
                        :
                        null
                      )
                      )}
                    </tr>
                  )}
                  {this.props.rowData.length === 0 &&
                    <tr>
                      <td className={`no-records`} colSpan={this.props.colSpan}>{constants.PAGINATION.NO_RECORDS_FOUND}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="pagenations">
              <ul className="pagination justify-content-end">
                <li className={`page-item ${this.props.pageNo > 1 ? '' : 'disabled'}`}  ><span className={`page-link`} onClick={() => { this.props.pageNo > 1 && this.props.pagePrevious() }}>Previous</span></li>
                {
                  this.getPagingRange(this.props.pageNo, { min: 1, total: this.totalPages(), length: 3 }).map((page) => { return isNaN(page) === false && <li key={page} className={`page-item ${(parseInt(this.props.pageNo) === page ? 'active' : '')}`} ><span className="page-link" onClick={() => { this.props.pageNo <= this.totalPages() && this.props.handlePage(page) }}>{page}</span></li> })
                }
                <li className={`page-item ${this.props.pageNo < this.totalPages() ? '' : 'disabled'}`} ><span className={`page-link`} onClick={() => { this.props.pageNo < this.totalPages() && this.props.pageNext() }}>Next</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DynamicTableComponent
