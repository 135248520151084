import React, { memo } from "react";
import { Route, Redirect } from "react-router";
import constants from "../Utils/constants";
import permissionCheck from "../lib/permissionCheck";

const PrivateRoute = ({ component: Component, acl, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") !== null ? (
          acl ? (
            permissionCheck(rest.type, rest.entity, rest.action) ? (
              <Component {...props} />
            ) : (
              <Redirect to={constants.APPLICATION_ROUTE.ACCESS_DENIED.ROUTE} />
            )
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={
              constants.APPLICATION_ROUTE.LOGIN.ROUTE +
              `?redirect_url=${encodeURI(window.location.href)}`
            }
          />
        )
      }
    />
  );
};

export default memo(PrivateRoute);
