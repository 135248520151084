import { clients as DETAILS } from '../actionConstants'

const clients = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CLIENTS:
      return action.payload
    default:
      return state
  }
}

const clientRoles = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CLIENT_ROLES:
      return action.payload
    default:
      return state
  }
}
const users = (state = [], action) => {
  switch (action.type) {
    case DETAILS.USERS:
      return action.payload
    default:
      return state
  }
}

export {
  clients,
  clientRoles,
  users
}
