/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Toast from "../../Utils/Toast";
import "./MetricCategoriesModel.css";
import { DropdownList } from "react-widgets";
import { MasterServices } from "../../Services/Master";
import constants from "../../Utils/constants";

function MetricCategoriesModel({
  categoryModal,
  fetchCategoryData,
  closeCategoryModal,
  maxLevel,
  title,
  typeId,
  categoryObj,
  editMessage,
  successMessage,
  setLevel,
  setParentFilterValue,
  setSortOrder,
  setSortColumn,
  setSearchName
}) {
  const [addCategories, setAddCategories] = useState([""]);
  const [modalLevel, setModalLevel] = useState(1);
  const [parentList, setParentList] = useState([]);
  const [parentValue, setParentValue] = useState();
  const handleCategories = (e, index) => {
    let tempCategoriesArray = [...addCategories];
    tempCategoriesArray[index] = e.target.value;
    setAddCategories(tempCategoriesArray);
  };

  const [firstTime, setFisrtTIme] = useState(true)

  const handleSubmit = () => {
    const addCategoryObj = {
      name: addCategories,
      metric_category_type_id: typeId,
      parent_id: parentValue || null,
      level: modalLevel,
    };

    const handleResponse = (res) => {
      let toastMessage = categoryObj?.id ? editMessage : successMessage;
      Toast(toastMessage, "success");
      closeCategoryModal();
      setAddCategories([""]);
      setModalLevel(1);
      setParentValue();
      setParentList([]);
      if (categoryObj?.id === undefined) {

        setParentFilterValue()
        setLevel('All')
        setSearchName("")
        setSortColumn(constants.PAGINATION.DEFAULT_REPORTS3_SORT_COLUMN)
        setSortOrder(constants.PAGINATION.DEFAULT_SORT_ORDER)
        fetchCategoryData(1, "All", undefined, 10, constants.PAGINATION.DEFAULT_SORT_ORDER, true);
      } else {
        fetchCategoryData()
      }
    };

    const handleError = (error) => {
      Toast(
        error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    };

    if (categoryObj?.id) {
      addCategoryObj.id = categoryObj.id;
      addCategoryObj.name = addCategories[0];

      MasterServices.editMetricCategory(categoryObj.id, addCategoryObj)
        .then(handleResponse)
        .catch(handleError);
    } else {
      MasterServices.addMetricCategory(addCategoryObj)
        .then(handleResponse)
        .catch(handleError);
    }
  };

  const [saveButton, setSaveButton] = useState(true);

  useEffect(() => {
    if (addCategories.filter((vl) => vl.trim() !== '').length) {
      if (modalLevel === 1) {
        setSaveButton(false);
      }
      if ((modalLevel > 1 && !parentValue) || addCategories.filter((vl) => vl.trim() === '').length) {
        setSaveButton(true);
      } else {
        setSaveButton(false);
      }
    } else {
      setSaveButton(true);
    }

  }, [addCategories, parentValue, modalLevel]);
  const fetchParentLevel = async (vl, id) => {
    let parentLevel = vl - 1;

    let parentRes = await MasterServices.getCategories(
      `metric_category?metric_category_type_id=${typeId}&level=${parentLevel}`
    );
    let parentData = parentRes.data.data;
    setParentList(parentData);
    if (parentData.length === 1) {
      setParentValue(parentData[0].id);
    } else if (!id) {
      setParentValue();
    }
    setModalLevel(vl);
  };

  useEffect(() => {
    if (categoryObj?.id && categoryModal) {
      setAddCategories([categoryObj.name]);
      setModalLevel(categoryObj.level);
      setParentValue(categoryObj.parent_id);
      setFisrtTIme(true)
      fetchParentLevel(categoryObj.level, categoryObj?.id);
    }
  }, [categoryObj, categoryModal]);



  const handleModelClose = () => {
    closeCategoryModal();
    setAddCategories([""]);
    setModalLevel(1);
    setParentValue();
    setParentList([]);
  };

  return (
    <Modal
      isOpen={categoryModal}
      toggle={handleModelClose}
      className={
        "modal-sm modal-w-header custom-modal modal-without-header primary-modal"
      }
    >
      <ModalHeader toggle={handleModelClose}>
        <span className="category-title">{`${categoryObj?.id ? "Edit" : "Add New"
          } ${title}`}</span>
      </ModalHeader>

      <ModalBody>
        <div className="segment-dropdown-div">
          <div style={{ width: "35%" }}>
            <span className="modalLevel-title">Select Level<span className={"mandatory"}>*</span></span>
            <DropdownList
              value={modalLevel}
              data={[...Array(maxLevel)].map((_, index) => index + 1)}
              style={{ width: "100%" }}
              onChange={(e) => fetchParentLevel(e)}
              disabled={categoryObj?.id ? true : false}
              className="modal-drop-down-list"
            />
          </div>
          {modalLevel > 1 && (
            <div style={{ width: "65%" }}>
              <span className="modalLevel-title">{`Parent ${title}`}<span className={"mandatory"}>*</span></span>

              <DropdownList
                data={parentList}
                style={{ width: "100%" }}
                valueField="id" // Specify which field to use as the value
                textField="name"
                value={parentValue}
                placeholder={`Select parent ${title}`}
                filter="contains"
                className="modal-drop-down-list"
                onChange={(e) => {
                  setFisrtTIme(false)
                  setParentValue(e.id)
                }}
              />
            </div>
          )}
        </div>
        <div className="category-input">
          <span>{`Enter ${title} Name`}<span style={{ color: "#bd2130" }}>*</span></span>
          {addCategories.map((item, index) => {
            return (
              <div className="category-input-div" key={index}>
                <input
                  placeholder="Type here..."
                  value={item}
                  onChange={(e) => {
                    setFisrtTIme(false)
                    handleCategories(e, index)
                  }}
                />
                {index > 0 && (
                  <img
                    src={"https://tbr-ggk.s3.us-east-2.amazonaws.com/development%20/RemoveIcon.svg"}
                    style={{ width: "20px", cursor: "pointer" }}
                    onClick={() =>
                      setAddCategories(
                        addCategories.filter((_, key) => key !== index)
                      )
                    }
                    alt="remov-icon"
                  />
                )}
              </div>
            );
          })}
        </div>
        <ModalFooter
          className={
            categoryObj?.id
              ? "update-category-model-footer"
              : "add-category-model-footer"
          }
        >
          {!categoryObj?.id && (
            <Button
              className={"btn-outline-primary modal-btn add-btn"}
              onClick={() => setAddCategories([...addCategories, ""])}
            >
              + Add More
            </Button>
          )}
          <Button
            className={"modal-right-btn modal-btn"}
            disabled={saveButton || firstTime}
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}

export default MetricCategoriesModel;
