import { special_report_details as DETAILS } from '../actionConstants'

const specialReportCategories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const specialReportSegments = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const specialReportDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const specialReportContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_CONTENT:
      return action.payload
    default:
      return state
  }
}

const specialReportTemplates = (state = [], action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_TEMPLATES:
      return action.payload
    default:
      return state
  }
}

const specialReportTransactionDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_TRANSACTION_DETAILS:
      return action.payload
    default:
      return state
  }
}

export {
  specialReportCategories,
  specialReportSegments,
  specialReportDetails,
  categories,
  specialReportContent,
  specialReportTemplates,
  specialReportTransactionDetails
}
