import React, { useState, useEffect, useCallback } from 'react';
import GreenCheckBox from "./GreenCheckBox.svg"
import CustomReactTooltip from '../../Components/CustomReactTooltip';

function CustomCheckBox(props) {
  const [activeNode, setActiveNode] = useState();

  useEffect(() => {
    if (Object.keys(props).indexOf('expandAll') !== -1) {
      let expandedArray = [];
      props.expandAll && getExpandedArray(props.nodes, expandedArray);
      props.onExpand(expandedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expandAll, props.nodes, props.disable, props])

  useEffect(() => {
    setActiveNode(props.defaultSelection);
  }, [props.defaultSelection])

  const handleChevron = (value, isOpen) => {
    let expanded = [...props.expanded]
    if (isOpen) {
      expanded.push(value);
    }
    else {
      let node_values_to_be_removed = getAllChildrenValues(value);
      expanded = expanded.filter(value => !node_values_to_be_removed.includes(value))
    }
    props.onExpand(expanded);
  }



  /**
   * @param {Integer} value Representing the value of node
   * @returns An Array of all node values to be removed from expanded array
   */
  const getAllChildrenValues = (value) => {
    let node = dfs_algo_on_nodes(props.nodes, value);
    let valuesArray = [];
    populateValuesArrayRecursively(node, valuesArray);
    return valuesArray;
  }

  /**
   * This function recursively updates the valuesArray with all the node values which can be used to remove them from expanded array
   * @param {Object} node An Object representing indiviudal node
   * @param {Array} valuesArray A list of all child nodes values which has children
   */
  const populateValuesArrayRecursively = (node = {}, valuesArray = []) => {
    if (node.children && node.children.length) {
      valuesArray.push(node.value)
      node.children.forEach(item => {
        populateValuesArrayRecursively(item, valuesArray);
      })
    }
  }

  /**
   * Performs a dfs algorithm on nodes array to find the particular node whose value is same as value provided as attribute
   * @param {Array} nodes An Array of nodes
   * @param {Integer} value An Integer representing the value of node
   * @returns A Node Object
   */
  const dfs_algo_on_nodes = (nodes = [], value) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].value === value) {
        return nodes[i];
      }
      if (nodes[i].children && nodes[i].children.length) {
        let node = dfs_algo_on_nodes(nodes[i].children, value);
        if (node) {
          return node;
        }
      }
    }
  }

  const handleChecked = (e, value) => {
    let selectedItems = [...props.checked]
    if (e.target.checked) {
      selectedItems.push(value);
    }
    else {
      let index = selectedItems.indexOf(value);
      selectedItems.splice(index, 1);
    }
    props.onCheck(selectedItems);
  }


  const handleArrow = (e, value, segment_id) => {
    e.stopPropagation();
    setActiveNode(value);
    props.handleButtonClick(value, segment_id);
  }

  /**
   * This function iterates through all its children and adds value to expanded array
   * This expanded array is used to open all nodes by default if expandAll prop is true
   * @param {Array} nodes An Array of nodes
   * @param {Array} expandedArray An Array of expanded nodes
   */
  const getExpandedArray = useCallback((nodes = [], expandedArray = []) => {
    nodes.forEach(element => {
      if (element.children && element.children.length) {
        expandedArray.push(element.value);
        getExpandedArray(element.children, expandedArray);
      }
    });
  })

  const getOrderlist = (nodes = []) => {
    return (
      <ol className="checkbox-list">
        {nodes.map((item, index) => {
          let isChecked = props.checked.includes(item.id);
          let isExpanded = props.expanded ? props.expanded.includes(item.id) : false;
          return (
            <li key={index} data-testid={`checkbox-li-${item.id}`} className={`checkbox-item ${activeNode === item.id ? 'active' : ''} ${isExpanded && !props.rule_view ? 'expanded-true' : 'expanded-false'}`}>
              <span className='d-flex align-items-center rct-text'>
                {item.children && item.children.length && !props.rule_view ?
                  !isExpanded ?
                    <span data-testid={`checkbox-chevron-${item.id}`} className="material-icons-outlined expanded-false icon" onClick={() => handleChevron(item.id, true)}>
                      chevron_right
                    </span>
                    :
                    <span data-testid={`checkbox-chevron-${item.id}`} className="material-icons-outlined expanded-true icon" onClick={() => handleChevron(item.id, false)}>
                      expand_more
                    </span>
                  :
                  <></>
                }
                {props.rule_view ? <img src={GreenCheckBox} alt="GreenCheckBox" style={{ display: 'flex', marginLeft: '4px' }} /> : props.isMetrics ? item.parent_id ? <input className={props.disabled ? "view-custom-checkbox-new no-cursor" : "custom-checkbox-new"} type='checkbox' id={item.id} data-testid={`checkbox-${item.id}`} onChange={(e) => handleChecked(e, item.id)} checked={isChecked} disabled={props.disabled} /> : null :
                  <input className={props.disabled ? "view-custom-checkbox-new no-cursor" : "custom-checkbox-new"} type='checkbox' id={item.id} data-testid={`checkbox-${item.id}`} onChange={(e) => handleChecked(e, item.id)} checked={isChecked} disabled={props.disabled} />}
                {item.label.length > 20 ?
                  <label
                    className="text rct-title mb-0"
                    key={item.id} htmlFor={item.label}
                    data-for={`tooltip-${item.id}-active`}
                    data-tip={item.label}
                  >
                    {(item.label.substring(0, 20) + '...')}
                    <CustomReactTooltip
                      id={`tooltip-${item.id}-active`}
                      multiline={true}
                    />
                  </label>
                  :
                  <label className="text rct-title mb-0" key={item.id} htmlFor={item.label}>{item.label}</label>}
                {/* {isChecked && props.showArrow && !(props.disabled) && <span className="material-icons-outlined add-icon" data-testid={`checkbox-arrow-${item.value}`} onClick={(e) => handleArrow(e, item.value, item.segment_id)}>
                  arrow_forward
                </span>} */}
              </span>
              {item.children && isExpanded ? getOrderlist(item.children) : <></>}
            </li>
          )
        })}
      </ol>
    )
  }

  return (
    <div className='visualisation'>
      {props.nodes.length ? getOrderlist(props.nodes) : `No Selected ${props.title}`}
    </div>
  )
}

export default React.memo(CustomCheckBox);