import React, { Component } from "react";
import { connect } from "react-redux";
import { customerStudyCategories } from "../Store/Actions/CustomerStudyDetails";
import TemplateParser from "../Components/TemplateParser";
import TemplatePicker from "../Components/TemplatePicker";
import CustomDateTimePicker from "../Components/CustomDateTimePicker";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import constants from "../Utils/constants";
import DailogNew from "../Components/DailogNew";
import permissionCheck from "../lib/permissionCheck";
import Notification from "../Components/Notification";
import TransactionDetailsComments from "./TransactionDetailsComments";
import decodeJWT from "../lib/decodeJWT";
import { formatRoute } from "react-router-named-routes";
import ScrollToTopBtn from "../Components/ScrollToTop";
import DocumentTitle from "../Utils/DocumentTitle";
import Collapsible from "react-collapsible";
import { parseCssToObj, styleStringToArray } from "../Utils/helper";
import CustomTitle from "../Components/CustomTitle";
import CustomTextarea from "../Components/CustomTextarea";

class CustomerStudyTransactionDetailsContent extends Component {
  state = {
    titleModal: false,
    uid: null,
    placeholder: null,
    originalTitleValue: null,
    modifiedTitleValue: null,
    modifiedTitleStyle: null,
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    dynamicStyle: {
      fontSize: "24.47px",
      fontFamily: "Carlito-Bold_39_c",
      color: "#000", // Assuming you meant text color instead of 'fill'
    },
    imgLoading: true,
    imgLoadingError: false,
  };
  componentDidMount() {
    this.tempToggleModal(true);
    setTimeout(() => {
      this.tempToggleModal(false);
    }, 180);
  }

  // method for to re-render the content
  tempToggleModal = (param) => {
    this.setState({
      titleModal: param,
    });
  };

  toggleTitleModal = (originalTitleValue, uid) => {
    this.setState({
      titleModal: !this.state.titleModal,
      originalTitleValue: originalTitleValue,
      modifiedTitleValue: originalTitleValue,
      uid: uid,
    });
  };

  closeTitleModal = () => {
    this.setState({
      titleModal: false,
    });
  };

  saveTitleModal = () => {
    this.setState(
      {
        originalTitleValue: this.state.modifiedTitleValue,
      },
      () => {
        this.props.onSectionTitleChangeHandler(
          this.state.uid,
          this.state.originalTitleValue
        );
        this.closeTitleModal();
      }
    );
  };

  onTitleChangeHandler = (e) => {
    this.setState({
      modifiedTitleValue: e.target.value,
    });
  };

  confirmDailog = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_CONTENT_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section", uid: uid },
      dailogModalStyleType: "danger-modal",
    });
  };

  confirmDailogUndo = (uid) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Undo</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .UNDO_DELETE_ACTION_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "section_recover", uid: uid },
      dailogModalStyleType: "inactive-modal",
    });
  };

  dailogModalAccept = (config) => {
    if (config.type === "section") {
      this.props.removeContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    } else if (config.type === "section_recover") {
      this.props.undoRemoveContent(config.uid);
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  handleImageLoad = () => {
    // this.setState({ imgLoading: false,imgLoadingError: false });
    setTimeout(() => {
      this.setState({ imgLoading: false , imgLoadingError: false });
    }, 1000);
  };

  handleImageError = () => {
    this.setState({ imgLoading: false, imgLoadingError: true });
  };

  breadcrumbs = () => {
    let nextStates =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .next_states
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .next_states
        : [];
    let customerStudyDetails =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails
        ? this.props.customer_study_details.customerStudyTransactionDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: nextStates.includes(
          constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_DRAFT.SLUG
        )
          ? constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS.NAME
          : constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS.NAME,
        link: formatRoute(
          nextStates.includes(
            constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_DRAFT.SLUG
          )
            ? constants.APPLICATION_ROUTE
                .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS.ROUTE
            : constants.APPLICATION_ROUTE
                .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: `${
          customerStudyDetails &&
          customerStudyDetails.customer_study &&
          customerStudyDetails.customer_study.id
            ? customerStudyDetails.customer_study.name
            : ""
        }`,
        link: null,
        is_active: true,
      },
    ];
    DocumentTitle(
      `${
        customerStudyDetails &&
        customerStudyDetails.customer_study &&
        customerStudyDetails.customer_study.id
          ? customerStudyDetails.customer_study.name
          : ""
      }`
    );
    return breadcrumbs;
  };

  render() {
    let customerStudyContent =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyContent
        ? this.props.customer_study_details.customerStudyContent
        : [];
    let categories =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyCategories
        ? this.props.customer_study_details.customerStudyCategories
        : [];
    let templates =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTemplates
        ? this.props.customer_study_details.customerStudyTemplates
        : [];
    let transactionComments =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails.comments
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .comments
        : [];
    let transactionComment =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails.comment
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .comment
        : null;
    let transactionCommentOpenFlag =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .openCommentsFlag
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .openCommentsFlag
        : false;
    let transactionId =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails.id
        ? this.props.customer_study_details.customerStudyTransactionDetails.id
        : "";
    let nextStates =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .next_states
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .next_states
        : [];
    let customerStudyDetails =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyDetails
        ? this.props.customer_study_details.customerStudyDetails
        : {};
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let nextStateRole =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .role_state &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .role_state.role &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .role_state.role.short_name
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .role_state.role.short_name
        : null;
    let nextState =
      this.props.customer_study_details &&
      this.props.customer_study_details.customerStudyTransactionDetails &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .role_state &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .role_state.state &&
      this.props.customer_study_details.customerStudyTransactionDetails
        .role_state.state.short_name
        ? this.props.customer_study_details.customerStudyTransactionDetails
            .role_state.state.short_name
        : null;

    return Object.keys(tokenDetails).length ? (
      <aside className="dash_board_content inner-sidebar-right-content">
        <div className="col-xl-12">
          {this.props.apiError !== null && (
            <Notification color={"danger"} message={this.props.apiError} />
          )}
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="company_ttl">
              <ScrollToTopBtn />
              <div className="row mb-10">
                {!this.props.currentState?.short_name?.includes("draft") && (
                  <div className="col-12 col-md-7 mb-3">
                    <div
                      className="d-flex align-items-center border"
                      style={{ minHeight: "70px" }}
                    >
                      <div className="col-md-2">
                        <img
                          src={
                            this.state.imgLoading
                              ? constants.COMPANY_DETAILS.IMAGE_LOADING
                              : this.props.setImage ??
                                constants.COMPANY_DETAILS.NO_IMAGE_FOUND_PATH
                          }
                          alt="Uploaded"
                          style={{ maxWidth: "100%", width: "50px", height: "50px" }}
                          onLoad={this.handleImageLoad}
                          onError={this.handleImageError}
                        />
                      </div>
                      <div className={"col-md-10"}>
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{
                              whiteSpace: "pre-wrap",
                              maxHeight: "60px",
                              overflowY: "auto",
                              wordBreak: "break-word",
                              color: this.props.setQuote
                                ? ""
                                : constants.QUOTE_IMAGE.NO_QUOTE_FOUND_COLOR,
                              minWidth: "420px"
                            }}
                          >
                            {this.props.setQuote
                              ? this.props.setQuote
                              : constants.QUOTE_IMAGE.NO_QUOTE_FOUND}
                          </label>
                          {this.props.isEditable && (
                            <button
                              onClick={() => this.props.onEditClicked()}
                              className="dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                            >
                              <span className="material-icons-outlined text-blue-clr icon-fs-20">
                                edit
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.currentState?.short_name?.includes("draft")
                      ? "col-12 col-md-12 d-flex align-items-center justify-content-end"
                      : "col-12 col-md-5 d-flex align-items-center justify-content-end"
                  }
                >
                  {this.props.isEditable && (
                    <button
                      className={
                        "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                      }
                      type="button"
                      onClick={this.props.resetContent}
                    >
                      {" Reset"}
                      <span className="material-icons-outlined icon-fs-16 ml-1">
                        restart_alt
                      </span>
                    </button>
                  )}
                  {nextStates.includes(
                    constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_REJECT
                      .SLUG
                  ) &&
                    permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_REJECT
                        .SLUG
                    ) &&
                    customerStudyContent.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-danger icon-text-btn title-btn d-inline-flex align-items-center mb-3 mx-3"
                        }
                        type="button"
                        onClick={() => {
                          this.props.saveContent(
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .CUSTOMER_STUDY_REJECT.SLUG
                          );
                        }}
                      >
                        {" " +
                          constants.MANAGE_CONTENT_PERMISSIONS
                            .CUSTOMER_STUDY_REJECT.BUTTON_TITLE}
                        <span className="material-icons-round icon-fs-16 ml-1">
                          close
                        </span>
                      </button>
                    )}
                  {nextStates.includes(
                    constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_DRAFT
                      .SLUG
                  ) &&
                    permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_DRAFT
                        .SLUG
                    ) &&
                    customerStudyContent.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3 mx-3"
                        }
                        type="button"
                        onClick={() => {
                          this.props.saveContent(
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .CUSTOMER_STUDY_DRAFT.SLUG
                          );
                        }}
                      >
                        {" " +
                          constants.MANAGE_CONTENT_PERMISSIONS
                            .CUSTOMER_STUDY_DRAFT.BUTTON_TITLE}
                        <span className="material-icons-outlined icon-fs-16 ml-1">
                          save
                        </span>
                      </button>
                    )}
                  {nextStates.includes(
                    constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_SUBMIT
                      .SLUG
                  ) &&
                    permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_SUBMIT
                        .SLUG
                    ) &&
                    customerStudyContent.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                        }
                        type="button"
                        onClick={() => {
                          this.props.saveContent(
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .CUSTOMER_STUDY_SUBMIT.SLUG
                          );
                        }}
                      >
                        {" " +
                          constants.MANAGE_CONTENT_PERMISSIONS
                            .CUSTOMER_STUDY_SUBMIT.BUTTON_TITLE}
                        <span className="material-icons-outlined icon-fs-16 ml-1">
                          done_all
                        </span>
                      </button>
                    )}
                  {nextStates.includes(
                    constants.MANAGE_CONTENT_PERMISSIONS
                      .CUSTOMER_STUDY_RE_SUBMIT.SLUG
                  ) &&
                    permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS
                        .CUSTOMER_STUDY_RE_SUBMIT.SLUG
                    ) &&
                    customerStudyContent.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3 ml-3"
                        }
                        type="button"
                        onClick={() => {
                          this.props.saveContent(
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .CUSTOMER_STUDY_RE_SUBMIT.SLUG
                          );
                        }}
                      >
                        {" " +
                          constants.MANAGE_CONTENT_PERMISSIONS
                            .CUSTOMER_STUDY_RE_SUBMIT.BUTTON_TITLE}
                        <span className="material-icons-outlined icon-fs-16 ml-1">
                          done_all
                        </span>
                      </button>
                    )}
                  {tokenDetails.role.short_name !==
                    constants.USER_ROLES.EDITOR.SLUG &&
                    nextStates.includes(
                      constants.MANAGE_CONTENT_PERMISSIONS
                        .CUSTOMER_STUDY_APPROVE.SLUG
                    ) &&
                    permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS
                        .CUSTOMER_STUDY_APPROVE.SLUG
                    ) &&
                    customerStudyContent.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                        }
                        type="button"
                        onClick={() => {
                          this.props.saveContent(
                            nextState ===
                              constants.MANAGE_CONTENT_PERMISSIONS
                                .CUSTOMER_STUDY_REVIEW.SLUG &&
                              nextStateRole ===
                                constants.USER_ROLES.EDITOR.SLUG &&
                              tokenDetails.role.short_name ===
                                constants.USER_ROLES.ADMIN.SLUG
                              ? constants.MANAGE_CONTENT_PERMISSIONS
                                  .CUSTOMER_STUDY_PUBLISH.SLUG
                              : constants.MANAGE_CONTENT_PERMISSIONS
                                  .CUSTOMER_STUDY_APPROVE.SLUG
                          );
                        }}
                      >
                        {" " +
                          (nextState ===
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .CUSTOMER_STUDY_REVIEW.SLUG &&
                          nextStateRole === constants.USER_ROLES.EDITOR.SLUG &&
                          tokenDetails.role.short_name ===
                            constants.USER_ROLES.ADMIN.SLUG
                            ? constants.MANAGE_CONTENT_PERMISSIONS
                                .CUSTOMER_STUDY_PUBLISH.BUTTON_TITLE
                            : constants.MANAGE_CONTENT_PERMISSIONS
                                .CUSTOMER_STUDY_APPROVE.BUTTON_TITLE)}
                        <span className="material-icons-outlined icon-fs-16 ml-1">
                          done_all
                        </span>
                      </button>
                    )}
                  {tokenDetails.role.short_name ===
                    constants.USER_ROLES.EDITOR.SLUG &&
                    nextStates.includes(
                      constants.MANAGE_CONTENT_PERMISSIONS
                        .CUSTOMER_STUDY_APPROVE.SLUG
                    ) &&
                    permissionCheck(
                      constants.MANAGE_CONTENT_PERMISSIONS.KEY,
                      constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
                      constants.MANAGE_CONTENT_PERMISSIONS
                        .CUSTOMER_STUDY_APPROVE.SLUG
                    ) &&
                    customerStudyContent.length > 0 && (
                      <button
                        className={
                          "btn btn-custom btn-success icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                        }
                        type="button"
                        onClick={() => {
                          this.props.saveContent(
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .CUSTOMER_STUDY_PUBLISH.SLUG
                          );
                        }}
                      >
                        {" " +
                          constants.MANAGE_CONTENT_PERMISSIONS
                            .CUSTOMER_STUDY_PUBLISH.BUTTON_TITLE}
                        <span className="material-icons-outlined icon-fs-16 ml-1">
                          done_all
                        </span>
                      </button>
                    )}
                </div>
              </div>
            </div>
            {this.props.showComments &&
              (transactionComments.length > 0 || this.props.writeComments) && (
                <div className="col-xl-12">
                  <TransactionDetailsComments
                    writeComments={this.props.writeComments}
                    uid={transactionId}
                    comments={transactionComments}
                    key={transactionId}
                    openCommentsFlag={transactionCommentOpenFlag}
                    comment={transactionComment}
                    onCommentChangeHandler={
                      this.props.onTransactionCommentChangeHandler
                    }
                    toggleCommentsHandler={
                      this.props.toggleCommentsHandlerTransaction
                    }
                  />
                </div>
              )}
          </div>
        </div>
        {categories.map(
          (category, index) =>
            (category.is_selected || category.type) && (
              <div className="custom-collapser custom-collapser2 company-details-collapser details-page-collapser">
                <div className="category-wrapper">
                  <Collapsible
                    open={true}
                    trigger={
                      <div className="collapse-trigger">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <CustomTitle
                              title={category.name}
                              title_html={category.name_html}
                              tag="h3"
                              className="font-weight-bold mb-0 title category_name"
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="collapse-icon-wrapper">
                              <span className="material-icons-round add icon">
                                add
                              </span>
                              <span className="material-icons-round remove icon">
                                remove
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {category.subcategories.map(
                      (sub_category, sub_index) =>
                        (sub_category.contents || sub_category.is_selected) &&
                        customerStudyContent &&
                        customerStudyContent.length > 0 &&
                        customerStudyContent.filter(
                          (segment) =>
                            segment.subcategory_id === sub_category.uid
                        ).length > 0 && (
                          <div
                            onMouseOver={() =>
                              this.props.mouseScroll(sub_category.id)
                            }
                            className={`${
                              sub_category.mode ? sub_category.mode : ""
                            } sub-category-section`}
                          >
                            <div
                              className="tbr_position inner-content-section"
                              key={sub_index}
                            >
                              <div
                                id={`${sub_category.name}-${sub_category.id}`}
                                style={{ "scroll-margin-top": "65px" }}
                                className=""
                              >
                                <div className="sub_category_heading d-flex align-items-center">
                                  <CustomTitle
                                    title={sub_category.name}
                                    title_html={sub_category.name_html}
                                    tag="h3"
                                    className="heading mb-0 sub_category_heading_title ellipsis"
                                  />
                                  {customerStudyContent.filter(
                                    (segment) =>
                                      segment.subcategory_id ===
                                        sub_category.uid && !segment.is_deleted
                                  )[0] && (
                                    <>
                                      {customerStudyContent.filter(
                                        (segment) =>
                                          segment.subcategory_id ===
                                            sub_category.uid &&
                                          !segment.is_deleted
                                      )[0].published_date && (
                                        <>
                                          <div className="d-flex align-items-center">
                                            <h5 className="font-weight-semibold text-black2 mb-0">
                                              Published
                                            </h5>
                                            <div className="custom-date-time-picker ml-10px">
                                              <CustomDateTimePicker
                                                isDisabled={
                                                  !this.props.isEditable
                                                }
                                                id={
                                                  customerStudyContent.filter(
                                                    (segment) =>
                                                      segment.subcategory_id ===
                                                        sub_category.uid &&
                                                      !segment.is_deleted
                                                  )[0].uid
                                                }
                                                date={
                                                  customerStudyContent.filter(
                                                    (segment) =>
                                                      segment.subcategory_id ===
                                                        sub_category.uid &&
                                                      !segment.is_deleted
                                                  )[0].published_date
                                                }
                                                onChangeHandler={
                                                  this.props.dateOnChangeHandler
                                                }
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {customerStudyContent
                                .filter(
                                  (segment) =>
                                    segment.subcategory_id === sub_category.uid
                                )
                                .map((sub_category1, sub_index1) => (
                                  <div
                                    key={`content-${sub_category1.uid}`}
                                    className={
                                      sub_category1.subcategory_id ===
                                      this.props.selectedSlide
                                        ? "content-section-selected"
                                        : "content-section"
                                    }
                                  >
                                    {
                                      <div
                                        className="title d-flex align-items-center justify-content-between"
                                        key={`desipte_sec-${sub_category1.uid}`}
                                      >
                                        <div
                                          className={`${
                                            sub_category1.is_deleted
                                              ? "content_deleted"
                                              : ""
                                          } text-primary mb-0 d-inline-flex align-items-center`}
                                        >
                                          {!sub_category1.is_deleted &&
                                            this.props.isEditable && (
                                              <>
                                                <span
                                                  className="material-icons manage_content_action icon-fs-16 mr-1"
                                                  onClick={() => {
                                                    this.toggleTitleModal(
                                                      sub_category1.subcategory_title,
                                                      sub_category1.uid
                                                    );
                                                  }}
                                                >
                                                  edit
                                                </span>
                                                &nbsp;
                                              </>
                                            )}
                                          <CustomTitle
                                            title={
                                              sub_category1.subcategory_title
                                                ? sub_category1.subcategory_title
                                                : constants.COMPANY_DETAILS
                                                    .TITLE_PLACE_HOLDER
                                            }
                                            title_html={
                                              sub_category1.subcategory_title_html
                                            }
                                            tag="h5"
                                            className={`${
                                              sub_category1.is_deleted
                                                ? "content_deleted"
                                                : ""
                                            } text-primary mb-0 d-inline-flex align-items-center`}
                                          />
                                        </div>
                                        {this.props.isEditable && (
                                          <>
                                            {!sub_category1.is_deleted && (
                                              <div
                                                className="del-undo-icon-wrapper d-inline-flex"
                                                onClick={() => {
                                                  this.confirmDailog(
                                                    sub_category1.uid
                                                  );
                                                }}
                                              >
                                                <span className="material-icons-outlined icon icon-fs-17">
                                                  delete_outline
                                                </span>
                                              </div>
                                            )}
                                            {sub_category1.is_deleted && (
                                              <div
                                                className="del-undo-icon-wrapper d-inline-flex"
                                                onClick={() => {
                                                  this.confirmDailogUndo(
                                                    sub_category1.uid
                                                  );
                                                }}
                                              >
                                                <span className="material-icons-outlined icon icon-fs-17">
                                                  undo
                                                </span>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    }
                                    {!sub_category1.template_id && (
                                      <div className="my-3">
                                        <TemplatePicker
                                          key={`template-picker-${sub_category1.uid}`}
                                          uid={sub_category1.uid}
                                          templates={templates}
                                          onTemplateChangeHandler={
                                            this.props.onTemplateChangeHandler
                                          }
                                        />
                                      </div>
                                    )}
                                    {sub_category1.template_id && (
                                      <div className="content_section template-content-section">
                                        <TemplateParser
                                          emptyContentMessage={
                                            constants.COMPANY_DETAILS
                                              .CONTENT_PLACE_HOLDER
                                          }
                                          isEditable={this.props.isEditable}
                                          isDeleted={sub_category1.is_deleted}
                                          key={`temp-${sub_category1.uid}`}
                                          template={
                                            sub_category1.template.content
                                          }
                                          content={sub_category1.data}
                                          uid={sub_category1.uid}
                                          onContentChangeHandler={
                                            this.props.onContentChangeHandler
                                          }
                                        />
                                      </div>
                                    )}
                                    {sub_category1.template_id &&
                                      this.props.showComments &&
                                      (sub_category1.comments.length > 0 ||
                                        this.props.writeComments) && (
                                        <TransactionDetailsComments
                                          writeComments={
                                            this.props.writeComments
                                          }
                                          uid={sub_category1.uid}
                                          comments={sub_category1.comments}
                                          key={sub_category1.uid}
                                          openCommentsFlag={
                                            sub_category1.openCommentsFlag
                                          }
                                          comment={sub_category1.comment}
                                          onCommentChangeHandler={
                                            this.props.onCommentChangeHandler
                                          }
                                          toggleCommentsHandler={
                                            this.props.toggleCommentsHandler
                                          }
                                        />
                                      )}
                                    {sub_index1 + 1 ===
                                      customerStudyContent.filter(
                                        (segment) =>
                                          segment.subcategory_id ===
                                          sub_category.uid
                                      ).length &&
                                      this.props.isEditable && (
                                        <div>
                                          <div
                                            className="add-section-btn d-inline-flex align-items-center cursor-pointer"
                                            onClick={() => {
                                              this.props.addEmptyContent(
                                                sub_category1.subcategory_id
                                              );
                                            }}
                                          >
                                            <span className="material-icons-outlined mr-1 icon-fs-18">
                                              add
                                            </span>
                                            <span>Add Sections</span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                    )}
                  </Collapsible>
                </div>
              </div>
            )
        )}

        <Modal
          isOpen={this.state.titleModal}
          toggle={this.closeTitleModal}
          className={"modal-lg custom-modal primary-modal modal-without-header"}
        >
          <ModalBody>
            <CustomTextarea
              modifiedTitleValue={this.state.modifiedTitleValue}
              modifiedTitleStyle={this.state.modifiedTitleStyle}
              onTitleChangeHandler={this.onTitleChangeHandler}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className={"btn-outline-primary modal-btn"}
              onClick={this.closeTitleModal}
            >
              Cancel
            </Button>{" "}
            <Button
              className={"modal-right-btn modal-btn"}
              onClick={this.saveTitleModal}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer_study_details: state.customer_study_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    customerStudyCategories: (payload) => {
      dispatch(customerStudyCategories(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerStudyTransactionDetailsContent);
