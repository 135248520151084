import React, { Component } from 'react';
import login_building from '../Images/login_building.png'
import login_map from '../Images/login_map.png'
import logo from '../Images/INSIGHTCENTER@2x.png'
import TBRLogo from '../Images/TBRLogo'
import constants from '../Utils/constants'
import { Container, Row, Form } from 'reactstrap'
import { UserServices } from '../Services/User'
import Notification from '../Components/Notification'
import { formatRoute } from 'react-router-named-routes'
import DocumentTitle from '../Utils/DocumentTitle'
import Toast from '../Utils/Toast'

class VerifyEmail extends Component {
  state = {
    apiError: null,
    isVerified: false,
    successMessage: null
  }
  componentDidMount = () => {
    let code = this.props.match.params.code
    if (localStorage.getItem("token")) {
      UserServices.getUserLogout().then((res) => {
        localStorage.removeItem("token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("SSO");
      })
      .catch((error) => {
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })
    }
    UserServices.verifyEmail(code)
    .then((res) => {
      this.setState({
        isVerified: true,
        successMessage: <>{constants.VERIFY_EMAIL.SUCCESS}</>
      })
      setTimeout(() =>
        this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE, { code: code }))
        , 1500)
    }).catch((error) => {
      if (error.status === 451) {
        //User is active but not set password
        this.setState({
          isVerified: true,
          successMessage: <>{constants.VERIFY_EMAIL.SUCCESS2}</>
        })
        setTimeout(() =>
          this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE, { code: code }))
          , 1500)
      }
      else if (error.status === 450) {
        //User has set password already
        this.setState({
          apiError: error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG
        })
        setTimeout(() =>
          this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {}))
          , 2000)
      }
      else {
        this.setState({
          apiError: error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG
        })
      }
    })
  }

  componentDidMount() {
    DocumentTitle('Account Verification')
  }

  render() {
    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img src={login_building} className="img-fluid" alt="login banner" title="login banner" />
                  </figure>
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <TBRLogo />
                    <span>{constants.PAGE.LOGIN.LOGO_TEXT}</span>
                  </a>
                </div>
              </aside>
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img src={login_map} className="img-fluid" alt="map Banner" />
                  </figure>
                  <Form onSubmit={this.handleSubmit} method='post'>
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {this.state.apiError &&
                      <Notification color={'danger'} message={this.state.apiError} />
                    }
                    {this.state.isVerified &&
                      <h2 className={`verify_email_success`}>
                        {this.state.successMessage}
                      </h2>
                    }
                  </Form>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    )
  }
}
export default VerifyEmail