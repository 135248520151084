import { combineReducers } from 'redux'
import * as HOME_REF from './reducerReference/homeReference'

const loginUserSuccess = HOME_REF.loginUserSuccess
const loginUserFailure = HOME_REF.loginUserFailure
const segments = HOME_REF.segments
const activeMenuTab = HOME_REF.activeMenuTab
const getTbriUsers = HOME_REF.getTbriUsers
const getCurrencies = HOME_REF.getCurrencies
const userRoles = HOME_REF.userRoles
const userDesignation = HOME_REF.userDesignation
const openPreference = HOME_REF.openPreference;

const homeReducer = combineReducers({
  loginUserSuccess,
  loginUserFailure,
  segments,
  activeMenuTab,
  getTbriUsers,
  getCurrencies,
  userRoles,
  userDesignation,
  openPreference
})

export default homeReducer
