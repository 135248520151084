import React, { memo } from "react";
import { Route, Switch, Redirect } from "react-router";
import constants from "../Utils/constants";
import NotFound from "../Pages/NotFound";
import PrivateRoute from "../Routes/PrivateRoutes";
import PublicRoute from "../Routes/PublicRoutes";
import Login from "../Pages/Login";
import VerifyEmail from "../Pages/VerifyEmail";
import ResetPassword from "../Pages/ResetPassword";
import Layout from "../Pages/Layout";
import ForgotPassword from "../Pages/ForgotPassword";
const Routes = () => {
  return (
    <Switch>
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.LOGIN.ROUTE}
        exact
        component={Login}
      />
      <PublicRoute
        restricted={false}
        path={constants.APPLICATION_ROUTE.VERIFY_EMAIL.ROUTE}
        exact
        component={VerifyEmail}
      />
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE}
        exact
        component={ResetPassword}
      />
      <PublicRoute
        restricted={false}
        path={constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE}
        exact
        component={ForgotPassword}
      />
      <PrivateRoute
        acl={false}
        restricted={false}
        path="/"
        component={Layout}
      />
      <Redirect exact from="/" to={constants.APPLICATION_ROUTE.LOGIN.ROUTE} />
      <Route path="*" exact component={NotFound} />
    </Switch>
  );
};

export default memo(Routes);
