import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { NavItem, NavLink } from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import permissionCheck from "../lib/permissionCheck";
import constants from "../Utils/constants";
import CustomReactTooltip from "../Components/CustomReactTooltip";
/**
 * Class Component for sidebar.
 *
 * @component
 * @Sidebar
 *
 */
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      parent: 0,
      parentToggle: {},
      isProfileMenu: false,
    };
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }
  // Function to toggle the sidebar
  toggle = () => {
    this.setState({ addClass: !this.state.addClass });
  };

  handleOutsideClick(e) {
    if (e && e.target && this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.toggleProfileMenu();
    }
  }

  toggleProfileMenu = () => {
    if (!this.state.isProfileMenu) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState({
      isProfileMenu: !this.state.isProfileMenu,
    });
  };

  /**
   *
   * @param {String} p represents the parent group name
   * @param {Boolean} bool if true, this method is called because of an update in redux store
   */
  toggleParent = (p, bool) => {
    this.setState({
      parent: p,
      parentToggle: {
        [p]:
          this.state.parentToggle[p] && !bool
            ? !this.state.parentToggle[p]
            : true,
      },
    });
  };

  componentDidMount = () => {
    this.MakingParentTabeActive(false);
  };

  componentDidUpdate = (prevsProps) => {
    if (this.props.home.activeMenuTab !== prevsProps.home.activeMenuTab) {
      this.MakingParentTabeActive(true);
    }
  };

  MakingParentTabeActive = (bool) => {
    let activeMenuTab =
      this.props.home && this.props.home.activeMenuTab
        ? this.props.home.activeMenuTab
        : "";
    if (
      activeMenuTab === constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME, bool);
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS.LIST.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.API_CLIENT_TRACER.LIST.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME, bool);
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
          .ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME, bool);
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
          .ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME, bool);
    } else if (
      activeMenuTab ===
        constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS
          .ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE
          .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS.ROUTE
    ) {
      this.toggleParent(
        constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP.NAME,
        bool
      );
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
          .ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME, bool);
    } else if (
      activeMenuTab ===
        constants.APPLICATION_ROUTE.CALENDAR_WEBINAR.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED.LIST.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.CALENDAR_GROUP.NAME, bool);
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
          .ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE
          .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS.ROUTE
    ) {
      this.toggleParent(
        constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME,
        bool
      );
    } else if (
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS
          .ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE
          .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS.ROUTE
    ) {
      this.toggleParent(
        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP.NAME,
        bool
      );
    } else if (
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
          .ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE
          .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS.ROUTE
    ) {
      this.toggleParent(
        constants.APPLICATION_ROUTE.MARKET_FORECAST_GROUP.NAME,
        bool
      );
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.USER_GROUP.NAME, bool);
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME, bool);
    } else if (
      activeMenuTab ===
        constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_LOB_AND_SUB_CATEGORIES
          .ARCHIVE_ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_VERTICAL_AND_CATEGORIES
          .ARCHIVE_ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY
          .ARCHIVE_ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.ARCHIVE_GROUP.NAME, bool);
    } else if (
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_LOB_AND_SUB_CATEGORIES.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_VERTICAL_AND_CATEGORIES.ROUTE ||
      activeMenuTab ===
        constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ.ROUTE
    ) {
      this.toggleParent(
        constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        bool
      );
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.REPORTS.LIST4.ROUTE ||
      activeMenuTab === constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME, bool);
    } else if (
      activeMenuTab === constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE
    ) {
      this.toggleParent(constants.APPLICATION_ROUTE.TAXONOMY_GROUP.NAME, bool);
    } else {
    }
  };

  render() {
    let asideClass = ["nav_sidebar"];
    if (this.state.addClass) {
      asideClass.push("closedSidebar");
    }
    let activeMenuTab =
      this.props.home && this.props.home.activeMenuTab
        ? this.props.home.activeMenuTab
        : "";
    let companyGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.COMPANY_RESOURCE.TITLE,
        constants.ACL.COMPANY_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );
    let clientGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.CLIENT_RESOURCE.TITLE,
        constants.ACL.CLIENT_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.CLIENT_USER_RESOURCE.TITLE,
        constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE,
        constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.ACCOUNT_MANAGERS_RESOURCE.TITLE,
        constants.ACL.ACCOUNT_MANAGERS_RESOURCE.ACTIONS.LIST
      );
    let projectGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.PROJECT_RESOURCE.TITLE,
        constants.ACL.PROJECT_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.PROJECT_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );
    let benchmarkGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.BENCHMARK_RESOURCE.TITLE,
        constants.ACL.BENCHMARK_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );
    let specialReportGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.SPECIAL_REPORT_RESOURCE.TITLE,
        constants.ACL.SPECIAL_REPORT_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );
    let marketForecastGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.MARKET_FORECAST_RESOURCE.TITLE,
        constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.MARKET_FORECAST_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );
    let marketLandscapeGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE,
        constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.MARKET_LANDSCAPE_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );

    let customerStudyGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.CUSTOMER_STUDIES_RESOURCE.TITLE,
        constants.ACL.CUSTOMER_STUDY_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );

    let ecoSystemGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.ECOSYSTEM_RESOURCE.TITLE,
        constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );

    let calendarGroupPermission =
      !permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.CALENDAR_RESOURCE.TITLE,
        constants.ACL.CALENDAR_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.CALENDAR_ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
      );

    let masterDataGroupPermission =
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.METRIC_UNIT_RESOURCE.TITLE,
        constants.ACL.METRIC_UNIT_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.METRIC_TYPE_RESOURCE.TITLE,
        constants.ACL.METRIC_TYPE_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.METRIC_DATA_RESOURCE.TITLE,
        constants.ACL.METRIC_DATA_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.LOB_RESOURCE.TITLE,
        constants.ACL.LOB_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.LOB_SUB_CATEGORY_RESOURCE.TITLE,
        constants.ACL.LOB_SUB_CATEGORY_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.VERTICAL_RESOURCE.TITLE,
        constants.ACL.VERTICAL_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.VERTICAL_CATEGORY_RESOURCE.TITLE,
        constants.ACL.VERTICAL_CATEGORY_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.GEOGRAPHY_RESOURCE.TITLE,
        constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.GEO_SUBCATEGORY_RESOURCE.TITLE,
        constants.ACL.GEO_SUBCATEGORY_RESOURCE.ACTIONS.LIST
      ) ||
      permissionCheck(
        constants.ACL.PERMISSION_KEY,
        constants.ACL.COUNTRY_RESOURCE.TITLE,
        constants.ACL.COUNTRY_RESOURCE.ACTIONS.LIST
      );
    let templateGroupPermission = permissionCheck(
      constants.ACL.PERMISSION_KEY,
      constants.ACL.TEMPLATE_RESOURCE.TITLE,
      constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST
    );
    let hrefLink = "#";

    return (
      <>
        <aside className="nav_sidebar">
          <div className="sidebar_section">
            <div
              className="togggle_circle"
              onClick={this.props.toggleSideBarHandler}
            >
              <div className="blue_circle">
                <i
                  className={`${
                    this.props.toggleSideBar
                      ? "fa fa-angle-double-left"
                      : "fa fa-angle-double-right"
                  } double_arrow`}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <ul className="parent_ul">
              <ul className="child_ul m-0">
                <li
                  data-for="dashboard"
                  data-tip="Dashboard"
                  className={`${
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.DASHBOARD.ROUTE
                      ? "active"
                      : ""
                  } nav-item p-0 mb-2`}
                >
                  <NavLink
                    className="p-0 d-inline-flex align-items-center"
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.DASHBOARD.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.DASHBOARD.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.DASHBOARD.NAME}
                    />
                    <span className="material-icons-round toggle-menu icon-fs-20 invisible">
                      chevron_right
                    </span>
                    <span className="material-icons-outlined icon-fs-20 align-middle not-dropdown">
                      {" "}
                      dashboard
                    </span>
                    <span
                      className="label_name align-middle"
                      onClick={this.props.toggleSideBarHandler}
                      title="{constants.APPLICATION_ROUTE.DASHBOARD.NAME}"
                    >
                      {constants.APPLICATION_ROUTE.DASHBOARD.NAME}
                    </span>
                  </NavLink>
                  <CustomReactTooltip
                    id="dashboard"
                    multiline={true}
                    place="right"
                    disable={this.props.toggleSideBar}
                  />
                </li>
              </ul>

              {companyGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.MANAGE_CONTENT.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS
                          .ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS
                          .ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="company"
                      data-tip="Company Research"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.MANAGE_CONTENT.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS
                            .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined pl-0 icon-fs-20 align-middle">
                        {" "}
                        business
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="company"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <NavItem
                          className={`${
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE
                              ? "active"
                              : ""
                          } child-item`}
                          data-for="company-list"
                          data-tip="List"
                        >
                          <NavLink
                            tag={RRNavLink}
                            exact
                            to={formatRoute(
                              constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
                              {}
                            )}
                            activeClassName={
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE
                                ? "active"
                                : ""
                            }
                          >
                            <span className="material-icons-outlined">
                              {" "}
                              format_list_bulleted
                            </span>
                            <span
                              className="sub_label_name"
                              onClick={this.props.toggleSideBarHandler}
                              title={
                                constants.APPLICATION_ROUTE.COMPANY.LIST.NAME
                              }
                            >
                              {constants.APPLICATION_ROUTE.COMPANY.LIST.NAME}
                            </span>
                            <CustomReactTooltip
                              id="company-list"
                              multiline={true}
                              place="right"
                              disable={this.props.toggleSideBar}
                            />
                          </NavLink>
                        </NavItem>

                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="company-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MY_DRAFTS.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MY_DRAFTS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MY_DRAFTS.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MY_DRAFTS.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MY_DRAFTS.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="company-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>

                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="company-track"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_TRACK_SUBMISSIONS.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_TRACK_SUBMISSIONS.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_TRACK_SUBMISSIONS.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="company-track"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {clientGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="clients"
                      data-tip="Clients"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST.ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined pl-0 icon-fs-20 align-middle">
                        {" "}
                        person_outline
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="clients"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CLIENT_RESOURCE.TITLE}
                          action={constants.ACL.CLIENT_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="clients-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CLIENT.LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CLIENT.LIST.NAME
                                }
                              >
                                {constants.APPLICATION_ROUTE.CLIENT.LIST.NAME}
                              </span>
                              <CustomReactTooltip
                                id="clients-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
                          action={
                            constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="clients-users"
                            data-tip="Users"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                group
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="clients-users"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
                          action={
                            constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="clients-emailbouncelist"
                            data-tip="Email Bounce List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                list_alt
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="clients-emailbouncelist"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.ACCOUNT_MANAGERS_RESOURCE.TITLE}
                          action={
                            constants.ACL.ACCOUNT_MANAGERS_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="clients-accountmanagerslist"
                            data-tip="Account Managers List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS
                                  .LIST.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS
                                  .LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                view_list
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="clients-accountmanagerslist"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
                          action={
                            constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .CLIENT_SUPPRESSION_EMAIL.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="clients-emailsuppressionlist"
                            data-tip="Email Suppression List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .CLIENT_SUPPRESSION_EMAIL.LIST.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .CLIENT_SUPPRESSION_EMAIL.LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .CLIENT_SUPPRESSION_EMAIL.LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                move_to_inbox
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .CLIENT_SUPPRESSION_EMAIL.LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .CLIENT_SUPPRESSION_EMAIL.LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="clients-emailsuppressionlist"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
                          action={
                            constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.API_CLIENT_TRACER.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-api-client-tracker"
                            data-tip="API Client Tracker"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.API_CLIENT_TRACER
                                  .LIST.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.API_CLIENT_TRACER
                                  .LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.API_CLIENT_TRACER
                                    .LIST.NAME
                                }
                              />
                              <span
                                className="material-icons-outlined"
                                style={{ rotate: "90deg" }}
                              >
                                key
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.SEARCH.LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.API_CLIENT_TRACER
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-api-client-tracker"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {projectGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="projects"
                      data-tip="Projects"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                            .ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS.ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        source
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="projects"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.PROJECT_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
                          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="projects-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CLIENT_PROJECT
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CLIENT_PROJECT
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.CLIENT_PROJECT
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="projects-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS.PROJECT_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="projects-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_PROJECT_DRAFTS.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_PROJECT_DRAFTS.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_PROJECT_DRAFTS.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="projects-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS.PROJECT_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="projects-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="projects-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {benchmarkGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="benchmarks"
                      data-tip="Benchmarks"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS.ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        speed
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="benchmarks"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.BENCHMARK_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
                          action={constants.ACL.BENCHMARK_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="benchmarks-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.BENCHMARK.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.BENCHMARK.LIST
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.BENCHMARK.LIST
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.BENCHMARK.LIST
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="benchmarks-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .BENCHMARK_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="benchmarks-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="benchmarks-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .BENCHMARK_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="benchmarks-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="benchmarks-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}
              {specialReportGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="SpecialReports"
                      data-tip="Special Reports"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST
                            .ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                            .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        summarize
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="SpecialReports"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.SPECIAL_REPORT_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.SPECIAL_REPORT_RESOURCE.TITLE}
                          action={
                            constants.ACL.SPECIAL_REPORT_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="special-report-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.SPECIAL_REPORT
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.SPECIAL_REPORT
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.SPECIAL_REPORT
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="special-report-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .SPECIAL_REPORT_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="special-report-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="special-report-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .SPECIAL_REPORT_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="special-report-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="special-report-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {marketForecastGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.MARKET_FORECAST_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST
                          .ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                          .ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="marketforecasts"
                      data-tip="Market Forecasts"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.MARKET_FORECAST_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST
                            .ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                            .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.MARKET_FORECAST_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        trending_up
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.MARKET_FORECAST_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="marketforecasts"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.MARKET_FORECAST_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.MARKET_FORECAST_RESOURCE.TITLE}
                          action={
                            constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="marketforecasts-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.MARKET_FORECAST
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.MARKET_FORECAST
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.MARKET_FORECAST
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="marketforecasts-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="marketforecasts-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="marketforecasts-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="marketforecasts-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="marketforecasts-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {marketLandscapeGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item nav-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST
                          .ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                          .ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="marketlandscape"
                      data-tip="Market Landscapes"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP
                            .NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST
                            .ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                            .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP
                            .NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        analytics
                      </span>
                      <span className="label_name">
                        {
                          constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP
                            .NAME
                        }
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="marketlandscape"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
                          action={
                            constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="marketlandscapes-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.MARKET_LANDSCAPE
                                  .LIST.ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.MARKET_LANDSCAPE
                                  .LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.MARKET_LANDSCAPE
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.MARKET_LANDSCAPE
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.MARKET_LANDSCAPE
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="marketlandscapes-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_LANDSCAPE_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="marketlandscapes-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="marketlandscapes-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_LANDSCAPE_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="marketlandscapes-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="marketlandscapes-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {customerStudyGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item nav-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST
                          .ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                          .ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="customerstudies"
                      data-tip="Customer Studies"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP
                            .NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST
                            .ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                            .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP
                            .NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        portrait
                      </span>
                      <span className="label_name">
                        {
                          constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP
                            .NAME
                        }
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="customerstudies"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CUSTOMER_STUDY_RESOURCE.TITLE}
                          action={
                            constants.ACL.CUSTOMER_STUDY_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CUSTOMER_STUDY.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="customerstudies-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CUSTOMER_STUDY.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CUSTOMER_STUDY.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CUSTOMER_STUDY
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CUSTOMER_STUDY
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.CUSTOMER_STUDY
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="customerstudies-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="customerstudies-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="customerstudies-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="customerstudies-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="customerstudies-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {ecoSystemGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item nav-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE
                          .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="ecosystem"
                      data-tip="Ecosystems"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE
                            .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        public
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="ecosystem"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.CUSTOMER_STUDIES_RESOURCE.TITLE}
                          action={
                            constants.ACL.CUSTOMER_STUDIES_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="ecosystem-list"
                            data-tip="List"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.ECOSYSTEM.LIST
                                  .ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.ECOSYSTEM.LIST
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                format_list_bulleted
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.ECOSYSTEM.LIST
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.ECOSYSTEM.LIST
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="ecosystem-list"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="ecosystem-drafts"
                            data-tip="My Drafts"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                pending_actions
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="ecosystem-drafts"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="ecosystem-tracks"
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="ecosystem-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {calendarGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.CALENDAR_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CALENDAR_WEBINAR.LIST
                          .ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED.LIST
                          .ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="calendar"
                      data-tip="Calendars"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.CALENDAR_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CALENDAR_WEBINAR.LIST
                            .ROUTE ||
                        activeMenuTab ===
                          constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED.LIST
                            .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.CALENDAR_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        date_range
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.CALENDAR_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="calendar"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.CALENDAR_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
                          action={
                            constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CALENDAR_WEBINAR.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="webinar"
                            data-tip="Webinar"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CALENDAR_WEBINAR
                                  .LIST.ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CALENDAR_WEBINAR
                                  .LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CALENDAR_WEBINAR
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                ondemand_video
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CALENDAR_WEBINAR
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.CALENDAR_WEBINAR
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="webinar"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>

                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
                          action={
                            constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED
                                .LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="published"
                            data-tip="Published"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED
                                  .LIST.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED
                                  .LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                grading
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="published"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              <ul className="child_ul m-0">
                <NavItem
                  className={`${
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE
                      ? "active"
                      : ""
                  } p-0`}
                  data-for="users"
                  data-tip="Users"
                >
                  <AclAction
                    type={constants.ACL.PERMISSION_KEY}
                    entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
                    action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.LIST}
                  >
                    <NavLink
                      className="d-inline-flex align-items-center"
                      tag={RRNavLink}
                      exact
                      to={formatRoute(
                        constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE,
                        {}
                      )}
                      activeClassName={
                        activeMenuTab ===
                        constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE
                          ? "active"
                          : ""
                      }
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={constants.APPLICATION_ROUTE.TBRI_USER.LIST.NAME}
                      />
                      <span className="material-icons-round toggle-menu icon-fs-20 invisible">
                        chevron_right
                      </span>
                      <span className="material-icons-outlined icon-fs-20 align-middle">
                        {" "}
                        manage_accounts
                      </span>
                      <span
                        className="label_name"
                        onClick={this.props.toggleSideBarHandler}
                      >
                        {constants.APPLICATION_ROUTE.USER_GROUP.NAME}
                      </span>
                      <CustomReactTooltip
                        id="users"
                        multiline={true}
                        place="right"
                        disable={this.props.toggleSideBar}
                      />
                    </NavLink>
                  </AclAction>
                </NavItem>

                <NavItem
                  className={`${
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE
                      ? "active"
                      : ""
                  } p-0`}
                  data-for="users"
                  data-tip="Taxonomy"
                >
                  <AclAction
                    type={constants.ACL.PERMISSION_KEY}
                    entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
                    action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
                  >
                    <NavLink
                      className="d-inline-flex align-items-center"
                      tag={RRNavLink}
                      exact
                      to={formatRoute(
                        constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE,
                        {}
                      )}
                      activeClassName={
                        activeMenuTab ===
                        constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE
                          ? "active"
                          : ""
                      }
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME}
                      />
                      <span className="material-icons-round toggle-menu icon-fs-20 invisible">
                        chevron_right
                      </span>
                      <span className="material-icons-outlined icon-fs-20 align-middle">
                        {" "}
                        account_tree
                      </span>
                      <span
                        className="label_name"
                        onClick={this.props.toggleSideBarHandler}
                      >
                        {constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME}
                      </span>
                      <CustomReactTooltip
                        id="taxanomy"
                        multiline={true}
                        place="right"
                        disable={this.props.toggleSideBar}
                      />
                    </NavLink>
                  </AclAction>
                </NavItem>
                <NavItem
                  className={`${
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE
                      ? "active"
                      : ""
                  } p-0`}
                  data-for="file_upload"
                  data-tip="File Upload"
                >
                  <AclAction
                    type={constants.ACL.PERMISSION_KEY}
                    entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
                    action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.LIST}
                  >
                    <NavLink
                      className="d-inline-flex align-items-center"
                      tag={RRNavLink}
                      exact
                      to={formatRoute(
                        constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE,
                        {}
                      )}
                      activeClassName={
                        activeMenuTab ===
                        constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE
                          ? "active"
                          : ""
                      }
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={constants.APPLICATION_ROUTE.DOWNLOAD.LIST.NAME}
                      />
                      <span className="material-icons-round toggle-menu icon-fs-20 invisible">
                        chevron_right
                      </span>
                      <span className="material-icons-outlined icon-fs-20 align-middle">
                        {" "}
                        file_upload
                      </span>
                      <span
                        className="label_name"
                        onClick={this.props.toggleSideBarHandler}
                      >
                        {constants.APPLICATION_ROUTE.MANAGE_FILE_UPLOADS.NAME}
                      </span>
                      <CustomReactTooltip
                        id="file_upload"
                        multiline={true}
                        place="right"
                        disable={this.props.toggleSideBar}
                      />
                    </NavLink>
                  </AclAction>
                </NavItem>
              </ul>
              <div
                className={`${
                  this.state.parentToggle[
                    constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME
                  ]
                    ? "opened"
                    : "closed"
                } parent-nav-collapsible-item nav-item`}
              >
                <li
                  className={`${
                    activeMenuTab ===
                      constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE ||
                    activeMenuTab ===
                      constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE ||
                    activeMenuTab ===
                      constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                      ? "active"
                      : ""
                  } nav-header parent-item nav-item`}
                >
                  <a
                    data-for="reports"
                    data-tip="Reports"
                    href={hrefLink}
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleParent(
                        constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME
                      );
                    }}
                    className={`${
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE ||
                      activeMenuTab ===
                        constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                        ? "active"
                        : ""
                    } d-inline-flex align-items-center ${
                      this.props.toggleSideBar ? "w-100" : "sidebar_width"
                    }`}
                  >
                    <span className="material-icons-round toggle-menu icon-fs-20">
                      {this.state.parentToggle[
                        constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME
                      ]
                        ? "expand_more"
                        : "chevron_right"}
                    </span>
                    <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                      {" "}
                      article
                    </span>
                    <span className="label_name">
                      {constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME}
                    </span>
                  </a>

                  <CustomReactTooltip
                    id="reports"
                    multiline={true}
                    place="right"
                    disable={this.props.toggleSideBar}
                  />

                  {this.state.parentToggle[
                    constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME
                  ] && (
                    <ul className="child_ul">
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-dashboard"
                        data-tip="Dashboard"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={constants.APPLICATION_ROUTE.REPORTS.LIST.NAME}
                          />
                          <span className="material-icons-outlined">
                            {" "}
                            dashboard
                          </span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS.LIST.NAME
                            }
                          >
                            {constants.APPLICATION_ROUTE.REPORTS.LIST.NAME}
                          </span>
                          <CustomReactTooltip
                            id="reports-dashboard"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-client"
                        data-tip="Client Level"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={constants.APPLICATION_ROUTE.REPORTS.LIST2.NAME}
                          />
                          <span className="material-icons-outlined">
                            {" "}
                            perm_identity
                          </span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS.LIST2.NAME
                            }
                          >
                            {constants.APPLICATION_ROUTE.REPORTS.LIST2.NAME}
                          </span>
                          <CustomReactTooltip
                            id="reports-client"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-user"
                        data-tip="User Level"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={constants.APPLICATION_ROUTE.REPORTS.LIST3.NAME}
                          />
                          <span className="material-icons-outlined">
                            {" "}
                            account_box
                          </span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS.LIST3.NAME
                            }
                          >
                            {constants.APPLICATION_ROUTE.REPORTS.LIST3.NAME}
                          </span>
                          <CustomReactTooltip
                            id="reports-user"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS.LIST4.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-client-users"
                        data-tip="Client Users"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS.LIST4.ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS.LIST4.ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={constants.APPLICATION_ROUTE.REPORTS.LIST4.NAME}
                          />
                          <span className="material-icons-outlined">group</span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS.LIST4.NAME
                            }
                          >
                            {constants.APPLICATION_ROUTE.REPORTS.LIST4.NAME}
                          </span>
                          <CustomReactTooltip
                            id="reports-client-users"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-boards"
                        data-tip="Boards"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={constants.APPLICATION_ROUTE.REPORTS.BOARDS.NAME}
                          />
                          <span className="material-icons-outlined">
                            {" "}
                            widgets
                          </span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS.BOARDS.NAME
                            }
                          >
                            {constants.APPLICATION_ROUTE.REPORTS.BOARDS.NAME}
                          </span>
                          <CustomReactTooltip
                            id="reports-boards"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS
                            .VISUALIZATION_TEMPLATES.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-visualization"
                        data-tip="Visualization"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS
                              .VISUALIZATION_TEMPLATES.ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS
                              .VISUALIZATION_TEMPLATES.ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={
                              constants.APPLICATION_ROUTE.REPORTS
                                .VISUALIZATION_TEMPLATES.NAME
                            }
                          />
                          <span className="material-icons-outlined">
                            {" "}
                            donut_small
                          </span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS
                                .VISUALIZATION_TEMPLATES.NAME
                            }
                          >
                            {
                              constants.APPLICATION_ROUTE.REPORTS
                                .VISUALIZATION_TEMPLATES.NAME
                            }
                          </span>
                          <CustomReactTooltip
                            id="reports-visualization"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeMenuTab ===
                          constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.ROUTE
                            ? "active"
                            : ""
                        }
                        data-for="reports-tbri"
                        data-tip="TBRI Boards & Visualizations"
                      >
                        <NavLink
                          tag={RRNavLink}
                          exact
                          to={formatRoute(
                            constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI
                              .ROUTE,
                            {}
                          )}
                          activeClassName={
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI
                              .ROUTE
                              ? "active"
                              : ""
                          }
                        >
                          <input
                            type="checkbox"
                            name=""
                            id={
                              constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI
                                .NAME
                            }
                          />
                          <span className="material-icons-outlined">
                            {" "}
                            space_dashboard
                          </span>
                          <span
                            className="sub_label_name"
                            onClick={this.props.toggleSideBarHandler}
                            title={
                              constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI
                                .NAME
                            }
                          >
                            {
                              constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI
                                .NAME
                            }
                          </span>
                          <CustomReactTooltip
                            id="reports-tbri"
                            multiline={true}
                            place="right"
                            disable={this.props.toggleSideBar}
                          />
                        </NavLink>
                      </NavItem>
                    </ul>
                  )}
                </li>
              </div>
              {templateGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item nav-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="Metric"
                      data-tip="Metric"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                        constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        assessment
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="Metric"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
                          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for={"metric-template"}
                            data-tip="Template"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                description
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME
                                }
                              >
                                {constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME}
                              </span>
                              <CustomReactTooltip
                                id="metric-template"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )}

              {masterDataGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item nav-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="configurations"
                      data-tip="Configurations"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                        constants.APPLICATION_ROUTE.MASTER_DATA_METRIC.LIST
                          .ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        settings
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="configurations"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.METRIC_TYPE_RESOURCE.TITLE}
                          action={
                            constants.ACL.METRIC_TYPE_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_METRIC_TYPE_AND_DATA.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-metrics"
                            data-tip="Metrics"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_METRIC_TYPE_AND_DATA.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_METRIC_TYPE_AND_DATA.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_METRIC_TYPE_AND_DATA.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                donut_small
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_METRIC_TYPE_AND_DATA.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_METRIC_TYPE_AND_DATA.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-metrics"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.LOB_RESOURCE.TITLE}
                          action={constants.ACL.LOB_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_LOB_AND_SUB_CATEGORIES.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-LOBs"
                            data-tip="LOBs"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_LOB_AND_SUB_CATEGORIES.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_LOB_AND_SUB_CATEGORIES.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_LOB_AND_SUB_CATEGORIES.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                segment
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_LOB_AND_SUB_CATEGORIES.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_LOB_AND_SUB_CATEGORIES.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-LOBs"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.VERTICAL_RESOURCE.TITLE}
                          action={constants.ACL.VERTICAL_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_VERTICAL_AND_CATEGORIES.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-verticals"
                            data-tip="Verticals"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_VERTICAL_AND_CATEGORIES.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_VERTICAL_AND_CATEGORIES.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_VERTICAL_AND_CATEGORIES.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pie_chart
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_VERTICAL_AND_CATEGORIES.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_VERTICAL_AND_CATEGORIES.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-verticals"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.GEOGRAPHY_RESOURCE.TITLE}
                          action={constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_GEOGRAPHY_REGION_COUNTRY.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-geography"
                            data-tip="Geographies"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_GEOGRAPHY_REGION_COUNTRY.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_GEOGRAPHY_REGION_COUNTRY.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                public
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-geography"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>

                        {/* changes made by Akhil */}
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.GEOGRAPHY_RESOURCE.TITLE}
                          action={constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-FAQs"
                            data-tip="Resources and FAQs"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                quiz
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-FAQs"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.GEOGRAPHY_RESOURCE.TITLE}
                          action={constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.HELP.LIST.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-Help"
                            data-tip="Help"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.HELP.LIST.ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.HELP.LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={constants.APPLICATION_ROUTE.HELP.LIST.NAME}
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                help_outline
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.HELP.LIST.NAME
                                }
                              >
                                {constants.APPLICATION_ROUTE.HELP.LIST.NAME}
                              </span>
                              <CustomReactTooltip
                                id="configurations-Help"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>

                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.EMAIL_TEMPLATE_RESOURCE.TITLE}
                          action={
                            constants.ACL.EMAIL_TEMPLATE_RESOURCE.ACTIONS.LIST
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-EmailTemplates"
                            data-tip="Email Templates"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST
                                  .ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.EMAIL_TEMPLATE
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                mail
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.EMAIL_TEMPLATE
                                    .LIST.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE.EMAIL_TEMPLATE
                                    .LIST.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-EmailTemplates"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>

                        <NavItem
                          tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                          className={`${
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.SEARCH.LIST.ROUTE
                              ? "active"
                              : ""
                          } child-item`}
                          data-for="configurations-Search"
                          data-tip="Search"
                        >
                          <NavLink
                            tag={RRNavLink}
                            exact
                            to={formatRoute(
                              constants.APPLICATION_ROUTE.SEARCH.LIST.ROUTE,
                              {}
                            )}
                            activeClassName={
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.SEARCH.LIST.ROUTE
                                ? "active"
                                : ""
                            }
                          >
                            <input
                              type="checkbox"
                              name=""
                              id={constants.APPLICATION_ROUTE.SEARCH.LIST.NAME}
                            />
                            <span className="material-icons-outlined">
                              {" "}
                              search
                            </span>
                            <span
                              className="sub_label_name"
                              onClick={this.props.toggleSideBarHandler}
                              title={
                                constants.APPLICATION_ROUTE.SEARCH.LIST.NAME
                              }
                            >
                              {constants.APPLICATION_ROUTE.SEARCH.LIST.NAME}
                            </span>
                            <CustomReactTooltip
                              id="configurations-Search"
                              multiline={true}
                              place="right"
                              disable={this.props.toggleSideBar}
                            />
                          </NavLink>
                        </NavItem>

                        <NavItem
                          tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                          className={`${
                            activeMenuTab ===
                            constants.APPLICATION_ROUTE.SEARCH_TREND.LIST.ROUTE
                              ? "active"
                              : ""
                          } child-item`}
                          data-for="configurations-search-trends"
                          data-tip="Search Trends"
                        >
                          <NavLink
                            tag={RRNavLink}
                            exact
                            to={formatRoute(
                              constants.APPLICATION_ROUTE.SEARCH_TREND.LIST
                                .ROUTE,
                              {}
                            )}
                            activeClassName={
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.SEARCH_TREND.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            }
                          >
                            <input
                              type="checkbox"
                              name=""
                              id={
                                constants.APPLICATION_ROUTE.SEARCH_TREND.LIST
                                  .NAME
                              }
                            />
                            <span className="material-icons-outlined">
                              travel_explore
                            </span>
                            <span
                              className="sub_label_name"
                              onClick={this.props.toggleSideBarHandler}
                              title={
                                constants.APPLICATION_ROUTE.SEARCH.LIST.NAME
                              }
                            >
                              {
                                constants.APPLICATION_ROUTE.SEARCH_TREND.LIST
                                  .NAME
                              }
                            </span>
                            <CustomReactTooltip
                              id="configurations-search-trends"
                              multiline={true}
                              place="right"
                              disable={this.props.toggleSideBar}
                            />
                          </NavLink>
                        </NavItem>
                      </ul>
                    )}
                  </li>
                </div>
              )}
              {/* Hide Archive List */}
              {/* {templateGroupPermission && (
                <div
                  className={`${
                    this.state.parentToggle[
                      constants.APPLICATION_ROUTE.ARCHIVE_GROUP.NAME
                    ]
                      ? "opened"
                      : "closed"
                  } parent-nav-collapsible-item nav-item`}
                >
                  <li
                    className={`${
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE
                        ? "active"
                        : ""
                    } nav-header parent-item nav-item`}
                  >
                    <a
                      data-for="Archive"
                      data-tip="Archive"
                      href={hrefLink}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleParent(
                          constants.APPLICATION_ROUTE.ARCHIVE_GROUP.NAME
                        );
                      }}
                      className={`${
                        activeMenuTab ===
                        constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE
                          ? "active"
                          : ""
                      } d-inline-flex align-items-center ${
                        this.props.toggleSideBar ? "w-100" : "sidebar_width"
                      }`}
                    >
                      <span className="material-icons-round toggle-menu icon-fs-20">
                        {this.state.parentToggle[
                          constants.APPLICATION_ROUTE.ARCHIVE_GROUP.NAME
                        ]
                          ? "expand_more"
                          : "chevron_right"}
                      </span>
                      <span className="material-icons-outlined icon-fs-20 pl-0 align-middle">
                        {" "}
                        archive
                      </span>
                      <span className="label_name">
                        {constants.APPLICATION_ROUTE.ARCHIVE_GROUP.NAME}
                      </span>
                    </a>

                    <CustomReactTooltip
                      id="Archive"
                      multiline={true}
                      place="right"
                      disable={this.props.toggleSideBar}
                    />

                    {this.state.parentToggle[
                      constants.APPLICATION_ROUTE.ARCHIVE_GROUP.NAME
                    ] && (
                      <ul className="child_ul">
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.LOB_RESOURCE.TITLE}
                          action={constants.ACL.LOB_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_LOB_AND_SUB_CATEGORIES.ARCHIVE_ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-LOBs"
                            data-tip="LOBs"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_LOB_AND_SUB_CATEGORIES.ARCHIVE_ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_LOB_AND_SUB_CATEGORIES.ARCHIVE_ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_LOB_AND_SUB_CATEGORIES.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                segment
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_LOB_AND_SUB_CATEGORIES.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_LOB_AND_SUB_CATEGORIES.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-LOBs"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.VERTICAL_RESOURCE.TITLE}
                          action={constants.ACL.VERTICAL_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_VERTICAL_AND_CATEGORIES.ARCHIVE_ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-verticals"
                            data-tip="Verticals"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_VERTICAL_AND_CATEGORIES.ARCHIVE_ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_VERTICAL_AND_CATEGORIES.ARCHIVE_ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_VERTICAL_AND_CATEGORIES.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                pie_chart
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_VERTICAL_AND_CATEGORIES.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_VERTICAL_AND_CATEGORIES.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-verticals"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.GEOGRAPHY_RESOURCE.TITLE}
                          action={constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_GEOGRAPHY_REGION_COUNTRY.ARCHIVE_ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for="configurations-geography"
                            data-tip="Geographies"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_GEOGRAPHY_REGION_COUNTRY
                                  .ARCHIVE_ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_GEOGRAPHY_REGION_COUNTRY.ARCHIVE_ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                public
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="configurations-geography"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>

                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
                          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST
                                .ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for={"metric-template"}
                            data-tip="Template"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE
                                  .LIST.ROUTE,
                                { clientId: "all" }
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE
                                  .LIST.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE
                                    .LIST.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                description
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME
                                }
                              >
                                {constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME}
                              </span>
                              <CustomReactTooltip
                                id="metric-template"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
                          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_METRIC_RULES_AND_THRESHOLDS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for={"metric-rules"}
                            data-tip="Rules and Thresholds"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_METRIC_RULES_AND_THRESHOLDS.ROUTE
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_METRIC_RULES_AND_THRESHOLDS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_METRIC_RULES_AND_THRESHOLDS.NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                data_thresholding
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_METRIC_RULES_AND_THRESHOLDS.NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_METRIC_RULES_AND_THRESHOLDS.NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="metric-rules"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                        <AclAction
                          type={constants.ACL.PERMISSION_KEY}
                          entity={
                            constants.MANAGE_CONTENT_PERMISSIONS
                              .MARKET_FORECAST_ENTITY
                          }
                          action={
                            constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG
                          }
                        >
                          <NavItem
                            tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                            className={`${
                              activeMenuTab ===
                              constants.APPLICATION_ROUTE
                                .MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS.ROUTE
                                ? "active"
                                : ""
                            } child-item`}
                            data-for={"metric-tracks"}
                            data-tip="Track Submissions"
                          >
                            <NavLink
                              tag={RRNavLink}
                              exact
                              to={formatRoute(
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS
                                  .ROUTE,
                                {}
                              )}
                              activeClassName={
                                activeMenuTab ===
                                constants.APPLICATION_ROUTE
                                  .MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS.ROUTE
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              />
                              <span className="material-icons-outlined">
                                {" "}
                                track_changes
                              </span>
                              <span
                                className="sub_label_name"
                                onClick={this.props.toggleSideBarHandler}
                                title={
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              >
                                {
                                  constants.APPLICATION_ROUTE
                                    .MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS
                                    .NAME
                                }
                              </span>
                              <CustomReactTooltip
                                id="metric-tracks"
                                multiline={true}
                                place="right"
                                disable={this.props.toggleSideBar}
                              />
                            </NavLink>
                          </NavItem>
                        </AclAction>
                      </ul>
                    )}
                  </li>
                </div>
              )} */}
            </ul>
          </div>
        </aside>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
export default connect(mapStateToProps, null)(Sidebar);
