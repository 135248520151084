import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_rangeSelector from "@amcharts/amcharts4/plugins/rangeSelector";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from "moment";
import constants from '../constants';

const fontSize = 12;
/**
 * DONT DELETE THE BWLOW LINES
 * FONT SIZE OF LABELS: categoryAxis.renderer.labels.template.fontSize = 10;
 */

// Modify chart's colors

function am4themes_customTheme(target) {
    if (target instanceof am4core.ColorSet) {
        target.list = [
            am4core.color("#72cafc"),
            am4core.color("#abf786"),
            am4core.color("#b387fa"),
            am4core.color("#fa87fa"),
            am4core.color("#f5b47a"),
            am4core.color("#fa947f"),
            am4core.color("#2a4cf7"),
            am4core.color("#f8fa7f"),
            am4core.color("#fc7c93"),

            am4core.color("#FF003B"),
            am4core.color("#B7FFE0"),
            am4core.color("#003770"),
            am4core.color("#DB7500"),
            am4core.color("#00894D"),
            am4core.color("#000000"),
            am4core.color("#707070"),
            am4core.color("#FFD700"),
            am4core.color("#911eb4"),
            am4core.color("#800000"),
            am4core.color("#469990"),
            am4core.color("#fffac8"),
            am4core.color("#f032e6"),
            am4core.color("#42d4f4"),
            am4core.color("#BC8F8F"),
            am4core.color("#00FF00"),
            am4core.color("#8FBC8F"),
            am4core.color("#C71585"),
        ];
    }
}

export default class AM_Charts {
    constructor(props) {
        this.root = null;
        this.chart = null;
        this.xAxis = null;
        this.yAxis = null;
        this.legendContainer = null;
        this.data = props.chartData;
        this.seriesType = props.seriesType;
        this.domId = props.divId;
        this.init();
    }

    // init = (divId) => {
    //     const root = am5.Root.new(divId);
    //     root.setThemes([
    //         am5themes_Animated.new(root)
    //     ]);

    //     const chart = root.container.children.push(am5xy.XYChart.new(root, {
    //         layout: root.gridLayout,
    //     }));

    //     this.chart = chart;
    //     this.root = root;
    // }

    // createDateAxis = () => {
    //     const root = this.root;
    //     const chart = this.chart;
    //     const chartData = this.data;

    //     const xRenderer = am5xy.AxisRendererX.new(root, {
    //         minGridDistance: 20
    //     });
    //     xRenderer.labels.template.set("minPosition", 0.01);
    //     xRenderer.labels.template.set("maxPosition", 0.99);

    //     const xAxis = chart.xAxes.push(
    //         am5xy.DateAxis.new(root, {
    //             baseInterval: {
    //                 timeUnit: "month",
    //                 count: 1,
    //             },
    //             // startLocation: 0.5,
    //             // endLocation: 0.5,
    //             renderer: xRenderer,
    //             tooltip: am5.Tooltip.new(root, {})
    //         })
    //     );
    //     xAxis.data.setAll(chartData);
    //     xAxis.skipEmptyPeriods = true;
    //     xAxis.get("dateFormats")["month"] = "MMM";
    //     xAxis.get("dateFormats")["year"] = "MMM YY";
    //     this.xAxis = xAxis;
    // }

    // createValueAxis = () => {
    //     const root = this.root;
    //     const chart = this.chart;
    //     const yAxis = chart.yAxes.push(
    //         am5xy.ValueAxis.new(root, {
    //             renderer: am5xy.AxisRendererY.new(root, {}),
    //         })
    //     );
    //     this.yAxis = yAxis;
    // }

    // createCategoryAxis = () => {
    //     const root = this.root;
    //     const chart = this.chart;
    //     const chartData = this.data;

    //     const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
    //         categoryField: "category",
    //         renderer: am5xy.AxisRendererX.new(root, {
    //             minGridDistance: 50
    //         }),
    //         tooltip: am5.Tooltip.new(root, {})
    //     }));
    //     xAxis.data.setAll(chartData);
    //     this.xAxis = xAxis;
    // }

    // addLegend = (legendDiv) => {
    //     let root = this.root;
    //     const chart = this.chart;
    //     let legend;
    //     let legendRoot = am5.Root.new(legendDiv);
    //     legendRoot.setThemes([
    //         am5themes_Animated.new(root)
    //     ]);
    //     legend = legendRoot.container.children.push(am5.Legend.new(legendRoot, {
    //         width: am5.percent(100),
    //         // centerX: am5.percent(50),
    //         // x: am5.percent(50),
    //         layout: legendRoot.gridLayout
    //     }));
    //     legend.data.setAll(chart.series.values);
    // }

    // chartCursor = () => {
    //     const root = this.root;
    //     const chart = this.chart;

    //     const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
    //         behavior: "none"
    //     }));
    //     cursor.lineY.set("visible", false);
    // }

    init = () => {

        const domId = this.domId;
        const chartData = this.data;

        am4core.options.autoDispose = true;
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(domId, am4charts.XYChart);
        chart.data = chartData;

        chart.maskBullets = false;
        chart.legend = new am4charts.Legend();
        chart.zoomOutButton.disabled = true;

        this.chart = chart;
    };

    createDateAxis = () => {
        const chart = this.chart;

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.minZoomCount = 1;
        dateAxis.renderer.labels.template.location = 0.5;
        dateAxis.dateFormats.setKey("month", "MMM");
        dateAxis.periodChangeDateFormats.setKey("month", "MMM YY");

        // //setting grid lines
        dateAxis.renderer.minGridDistance = 40;
        dateAxis.skipEmptyPeriods = true;
        dateAxis.groupData = true;
        // dateAxis.groupCount = 30;

        // dateAxis.gridIntervals.setAll([
        //     { timeUnit: "month", count: 1 },
        //     { timeUnit: "month", count: 3 },
        //     { timeUnit: "year", count: 1 },
        //     { timeUnit: "year", count: 2 },
        // ]);
        // dateAxis.groupIntervals.setAll([
        //     { timeUnit: "month", count: 1 },
        //     { timeUnit: "year", count: 1 },
        //     { timeUnit: "year", count: 10 }
        //   ]);
        dateAxis.renderer.labels.template.fontSize = fontSize;
        dateAxis.renderer.cellStartLocation = 0.1;
        dateAxis.renderer.cellEndLocation = 0.9;
        this.xAxis = dateAxis;
    }

    createValueAxis = () => {
        const chart = this.chart;
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.fontSize = fontSize;
        this.yAxis = valueAxis;
    }

    addLegend = (legendDomId) => {
        const chart = this.chart;

        //create a container for legend
        let legendContainer = am4core.create(legendDomId, am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);

        //move the chart legend to container
        if (chart.legend) {
            chart.legend.parent = legendContainer;
        }
        chart.legend.scrollable = true;
        chart.legend.fontSize = fontSize;
        this.legendContainer = legendContainer;
    };

    createSeries = (seriesObj) => {
        const {
            name,
            isStacked,
            field_name,
            isFill,
            showBullets
        } = seriesObj;


        const root = this.root;
        const chart = this.chart;
        const chartData = this.data;
        const xAxis = this.xAxis;
        const yAxis = this.yAxis;

        var series;
        if (this.seriesType === constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.LINE) {
            series = chart.series.push(new am4charts.LineSeries());
            if (showBullets || chartData.length == 1) {
                var bullet = series.bullets.push(new am4charts.CircleBullet());
                bullet.circle.stroke = am4core.color("#fff");
                bullet.circle.strokeWidth = 2;
            }
            series.tooltipText = "{name}: [b]{valueY}[/]";
        }
        else {
            series = chart.series.push(new am4charts.ColumnSeries());
            series.columns.template.tooltipText = "{name}: [b]{valueY}[/]";
            series.columns.template.tooltipX = am4core.percent(50);
            series.columns.template.tooltipY = am4core.percent(0);
        }
        series.dataItems.template.locations.dateX = 0.5;
        series.dataFields.valueY = field_name;
        series.yAxis = yAxis;
        series.name = name;
        series.dataFields.dateX = "date";
        // series.dateFormatter.dateFormat = "yyyy";
        series.strokeWidth = 2;
        series.sequencedInterpolation = true;
        // series.tooltip.dy = - 20;
        series.tooltip.pointerOrientation = 'vertical'
        series.cursorHoverEnabled = false;
        series.tooltip.exportable = false;

        // grouping the data should add all the values
        series.groupFields.valueY = "sum";
        if (isFill) {
            series.fillOpacity = 0.5;
        }
        if (isStacked) {
            series.stacked = true;
        }
        return series;
    };

    createCursor = () => {
        // Create series
        const chart = this.chart;
        const xAxis = this.xAxis;
        const yAxis = this.yAxis

        chart.cursor = new am4charts.XYCursor();
        // chart.cursor.xAxis = xAxis;
    }
}