import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import constants from "../../Utils/constants";
import MetricCategoryTreeView from "./MetricCategoryTreeView";
import { MasterServices } from "../../Services/Master";
import CustomCheckBox from "./CustomCheckBox";
import "./CreateMetricsRule.css";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import Toast from "../../Utils/Toast";
import { formatRoute } from "react-router-named-routes";

function EditMetricsRules(props) {
  const history = useHistory();
  const id = props.match.params.id;
  const [dataChange, setDataChange] = useState(false);

  const [lobsTree, setLobTree] = useState([]);
  const [geosTree, setGeoTree] = useState([]);
  const [verticalsTree, setVerticalTree] = useState([]);
  const [metricsTree, setMetricTree] = useState([]);
  const [lobsCheckedIds, setLobCheckedIds] = useState([]);
  const [geosCheckedIds, setGeoCheckedIds] = useState([]);
  const [verticalsCheckedIds, setVerticalCheckedIds] = useState([]);
  const [metricsCheckedIds, setMetricCheckedIds] = useState([]);
  const [lobsExpandIds, setLobExpandIds] = useState([]);
  const [geosExpandIds, setGeoExpandIds] = useState([]);
  const [verticalsExpandIds, setVerticalExpandIds] = useState([]);
  const [metricsExpandIds, setMetricExpandIds] = useState([]);
  const [ruleName, setRuleName] = useState("");

  function buildHierarchy(data, parentId = null) {
    const children = data.filter((item) => item.parent_id === parentId);

    if (children.length === 0) {
      return [];
    }

    const result = children.map((child) => {
      const childNode = {
        ...child,
        isExpand: true,
        checked: true,
        label: child.name,
        value: child.id,
        children: buildHierarchy(data, child.id),
      };
      return childNode;
    });

    return result;
  }

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE.EDIT.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  const handleUpdate = () => {
    let lobs = lobsCheckedIds;
    let geos = geosCheckedIds;
    let verticals = verticalsCheckedIds;
    let metrics = metricsCheckedIds;

    let input = {
      rule_id: id,
      rule_name: ruleName,
      lobs,
      geos,
      verticals,
      metrics,
    };

    MasterServices.editMetricRule(input)
      .then((res) => {
        Toast(
          constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.UPDATE,
          "success"
        );
        history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE,
            { id: id }
          ),
        });
      })
      .catch((e) => {
        Toast(e.data.error, "error");
      });
  };

  // Function to get parent ids for a given set of ids
  const getExpandedIds = (ids, allData) => {
    // Recursive function to get parents
    const getParents = (targetIds, currentId, result = []) => {
      const currentObject = allData.find((item) => item.id === currentId);
      if (!currentObject) {
        return result;
      }

      result.push(currentObject.id);
      return getParents(targetIds, currentObject.parent_id, result);
    };

    const parents = [];

    // Iterate through each id to get its parents
    ids.forEach((id) => {
      const parentIds = getParents(ids, id);
      parents.push(parentIds);
    });

    return parents[0]; // Return the first set of expanded ids
  };

  // Function to fetch category data
  const fetchCategoryData = async () => {
    try {
      const res = await MasterServices.getCategories(`metric_category`);
      let lobRes = res.data.data;

      // Process the category data and build trees
      let childrenTree = buildHierarchy(lobRes);
      let lobTree = childrenTree.filter(
        (item) => item.metric_category_type_id === 1
      );
      let geoTree = childrenTree.filter(
        (item) => item.metric_category_type_id === 2
      );
      let verticalTree = childrenTree.filter(
        (item) => item.metric_category_type_id === 3
      );

      // Set state with the processed data
      setLobTree(lobTree);
      setGeoTree(geoTree.filter((item) => item.name !== "global"));
      setVerticalTree(
        verticalTree.filter((item) => item.name !== "all industries")
      );

      return lobRes;
    } catch (error) {
      // Handle error
      console.error("Error fetching category data:", error);
      throw error; // Re-throw the error to indicate failure
    }
  };

  // Function to fetch metrics data
  const fetchMetricsData = async () => {
    try {
      let res = await MasterServices.getMetrics("metrics_v2");
      let lobRes = res.data.data;
      let childrenTree = buildHierarchy(lobRes);
      setMetricTree(childrenTree);
      return lobRes;
    } catch (error) {
      // Handle error
      console.error("Error fetching metrics data:", error);
      throw error; // Re-throw the error to indicate failure
    }
  };

  // Function to fetch category data by ID and perform additional operations
  const fetchCategoryDataById = async (data) => {
    try {
      const res = await MasterServices.getCategories(
        `/metric-rules/${id}/rule-view`
      );
      let lobRes = res.data.row;
      setRuleName(res.data.row.name);
      let relation_tree = res.data.row.relation_tree;
      setLobCheckedIds(relation_tree.lobIds);
      setGeoCheckedIds(relation_tree.geoIds);
      setMetricCheckedIds(relation_tree.metricIds);
      setVerticalCheckedIds(relation_tree.verticalIds);

      // Fetch category and metrics data, and await their results
      let metricsCategoryRes = await fetchCategoryData();
      let metricsRes = await fetchMetricsData();

      // Get expanded ids for different categories
      let lobExpandedIds = getExpandedIds(
        relation_tree.lobIds,
        metricsCategoryRes
      );
      let geoExpandedIds = getExpandedIds(
        relation_tree.geoIds,
        metricsCategoryRes
      );
      let verticalExpandedIds = getExpandedIds(
        relation_tree.verticalIds,
        metricsCategoryRes
      );
      let metricsExpandedIds = getExpandedIds(
        relation_tree.metricIds,
        metricsRes
      );

      // Set expanded ids in state
      setLobExpandIds(lobExpandedIds);
      setGeoExpandIds(geoExpandedIds);
      setVerticalExpandIds(verticalExpandedIds);
      setMetricExpandIds(metricsExpandedIds);
    } catch (error) {
      console.error("Error fetching category data by ID:", error);
    }
  };

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchCategoryDataById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <aside className='main_content'>
      <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
        <div className='d-flex align-items-center flex-wrap title-section breadcrumb-mb-0'>
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>
        <div className='rule-input-div'>
          <input
            placeholder='Enter rule name'
            value={ruleName}
            onChange={(e) => {
              setRuleName(e.target.value);
              setDataChange(true);
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "20px", marginTop: "16px" }}>
          <MetricCategoryTreeView
            treeData={lobsTree}
            setTreeData={setLobTree}
            checkedIds={lobsCheckedIds}
            setCheckedIds={setLobCheckedIds}
            expandedIds={lobsExpandIds}
            setExpandIds={setLobExpandIds}
            setDataChange={setDataChange}
            title="LOB's"
            IsEdit={true}
          />
          <MetricCategoryTreeView
            treeData={verticalsTree}
            setTreeData={setVerticalTree}
            checkedIds={verticalsCheckedIds}
            setCheckedIds={setVerticalCheckedIds}
            expandedIds={verticalsExpandIds}
            setExpandIds={setVerticalExpandIds}
            setDataChange={setDataChange}
            title='Verticals'
            IsEdit={true}
          />
          <MetricCategoryTreeView
            treeData={geosTree}
            setTreeData={setGeoTree}
            title='Geography'
            checkedIds={geosCheckedIds}
            setCheckedIds={setGeoCheckedIds}
            expandedIds={geosExpandIds}
            setExpandIds={setGeoExpandIds}
            setDataChange={setDataChange}
            IsEdit={true}
          />
          <MetricCategoryTreeView
            treeData={metricsTree}
            setTreeData={setMetricTree}
            title='Select Metric and Type'
            checkedIds={metricsCheckedIds}
            setCheckedIds={setMetricCheckedIds}
            expandedIds={metricsExpandIds}
            setExpandIds={setMetricExpandIds}
            isMetrics={true}
            setDataChange={setDataChange}
            IsEdit={true}
          />
        </div>
        <div className='create-btn-div'>
          <button
            className={
              "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
            }
            type='button'
            onClick={handleUpdate}
            disabled={
              !ruleName.length ||
              !lobsCheckedIds.length ||
              !metricsCheckedIds.length ||
              ruleName.trim() === "" ||
              !dataChange
            }
          >
            Save
          </button>
        </div>
      </div>
    </aside>
  );
}

export default EditMetricsRules;
