import { Axios } from "../lib/axios";
export const PreferenceServices = {
  getPreferenceDetails,
  addPreference,
};

function getPreferenceDetails(type) {
  return Axios.get(`prefereces/${type}`);
}
function addPreference(body) {
  return Axios.post(`setPreference`, body);
}
