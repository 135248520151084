import React, { Component } from "react";
import CustomReactToast from "../Components/CustomReactToast";
import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { msalInstance } from "../Auth/MSAL-Instance";
import { connect } from "react-redux";
import { openPreference } from "../Store/Actions/User";

class Layout extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    toggleSideBar: false,
    popoverOpen: false,
    is_sso_authenticated: false,
  };

  async componentDidMount() {
    // If a user closes the previous session without logging out, preventing multiple popups in sso is on us.
    // below state is used to do that.
    let sso_idToken = await msalInstance.check_sso_token();
    if (sso_idToken) {
      this.setState({
        is_sso_authenticated: true,
      });
    }
  }
  toggleSupport = () => this.setState({ popoverOpen: !this.state.popoverOpen });

  toggleSideBar = () => {
    this.setState({
      toggleSideBar: !this.state.toggleSideBar,
    });
  };

  render() {
    const is_sso_in_local_storage =
      localStorage.getItem("SSO") === "true" ? true : false;
    return (
      <div className={`wrapper ${this.state.toggleSideBar ? "open_nav" : ""}`}>
        <Header
          popoverOpen={this.state.popoverOpen}
          toggleSupport={this.toggleSupport}
          history={this.props.history}
        />
        <div
          onClick={this.toggleSideBar}
          className={`${this.state.toggleSideBar ? "body-overlay" : ""}`}
        ></div>
        <main>
          <div className="">
            <div>
              <div
                style={{
                  display: `${
                    this.props.home.openPreference === true ? "none" : "initial"
                  }`,
                }}
              >
                {![
                  "/manage-preferences",
                  "/tbr-adm/manage-preferences",
                ].includes(window?.location?.pathname) && (
                  <Sidebar
                    toggleSideBarHandler={this.toggleSideBar}
                    history={this.props.history}
                    toggleSideBar={this.state.toggleSideBar}
                  />
                )}

                {is_sso_in_local_storage && !this.state.is_sso_authenticated ? (
                  <></>
                ) : (
                  <Content />
                )}
              </div>
            </div>
            <CustomReactToast />
          </div>
        </main>
        <footer>
          <div className="footer-content d-flex align-items-center h-100 justify-content-center">
            Copyright &copy; {new Date().getFullYear()}. TBRI
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPreference: (data) => {
      dispatch(openPreference(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
