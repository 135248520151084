import React, { Component } from "react";
import constants from "../Utils/constants";
import { ClientServices } from "../Services/Client";
import { CompanyServices } from "../Services/Company";
import { MarketLandscapeServices } from "../Services/MarketLandscape";
import { UserServices } from "../Services/User";
import { formatRoute } from "react-router-named-routes";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import { connect } from "react-redux";
import { getCurrencies, segments } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import Toast from "../Utils/Toast";
import { getTbriUsers } from "../Store/Actions/User";
import DocumentTitle from "../Utils/DocumentTitle";
import AclAction from "../Components/ACL/AclAction";
import { isEmpty } from 'lodash';

const initialState = {
  marketLandscapeName: "",
  companySegments: "",
  companies: [],
  marketLandscapeCompanies: [],
  marketLandscapeAuthors: [],
  additionalSegments: [],
  marketLandscapeAdditionalSegments: [],
  description: "",
  formErrors: {},
  dailogModal: false,
  dailogModalContent: "",
  dailogModalHeader: "",
  dailogModalConfig: {},
  dailogModalStyleType: "",
  isFormSubmitted: false,
  apiError: null,
  showDeleteButton: false,
};
class ManageMarketLandscape extends Component {
  state = { ...initialState };
  async componentDidMount() {
    let marketLandscapeId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    DocumentTitle(
      marketLandscapeId === null
        ? constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.EDIT.HEADER_TITLE
    );
    await this.getSegments();
    await this.getTbriUsers();
    await this.getCompanies();
    if (marketLandscapeId) {
      this.prepopulateData(marketLandscapeId);
    }
  }
  getSegments = async () => {
    try {
      const res = await ClientServices.getSegmentDetails(0);
      let response = res?.data?.data || [];
      const segmentsWithoutER = response.filter(
        (r) => !r.name.includes("Earnings Response")
      );

      // Create a new array with updated is_selected property
      const updatedResponse = segmentsWithoutER.map((r) => ({
        ...r,
        is_selected: false,
      }));
      this.props.setSegments(updatedResponse);

      this.setState({
        additionalSegments: segmentsWithoutER.map((r) => ({
          ...r,
          is_selected: false,
        })),
      });
    } catch (error) {
      Toast(
        error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };
  getCompanies = async () => {
    let url = "companies";
    await CompanyServices.getTotalCompanies(url)
      .then((res) => {
        let companies =
          res && res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        companies.forEach((r) => {
          r.is_selected = false;
        });
        this.setState({
          companies: companies,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };
  getTbriUsers = async () => {
    await UserServices.getTbriUsers()
      .then((res) => {
        let response =
          res && res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        response = response.sort((a, b) =>
          a.first_name.toLowerCase() > b.first_name.toLowerCase()
            ? 1
            : b.first_name.toLowerCase() > a.first_name.toLowerCase()
            ? -1
            : 0
        );
        response.forEach((r) => {
          r.is_selected = false;
          r.name = `${r.first_name} ${r.last_name}`;
        });
        this.props.setTbriUsers(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  prepopulateData = (marketLandscapeId) => {
    MarketLandscapeServices.getMarketLandscapeDetails(marketLandscapeId)
      .then((res) => {
        let response = res?.data?.market_landscape;
        let admin_role_id =
          this.props.home?.userRoles && this.props.home?.userRoles.length > 0
            ? this.props.home.userRoles.filter(
                (role) => role.short_name === "admin"
              )[0].id
            : "";
        const supportedSegments = response?.supported_segments
          ? JSON.parse(response?.supported_segments)
          : [];
        this.setState(
          {
            marketLandscapeName: response?.name ? response?.name : "",
            companySegments: response?.segment_id ? response?.segment_id : null,
            marketLandscapeCompanies: response?.companies
              ? response?.companies.map((a) => a.id)
              : [],
            marketLandscapeAuthors: response?.authors
              ? response?.authors.map((a) => a.id)
              : [],
            marketLandscapeAdditionalSegments: supportedSegments,
            description: response?.description ? response?.description : "",
            isFormSubmitted: false,
            apiError: null,
            showDeleteButton:
              (res?.data?.published_count === 0 &&
                response?.created_by === this.props.home.loginUserSuccess.id) ||
              this.props.home.loginUserSuccess.role_id === admin_role_id,
          },
          () => {
            this.setAdditionalSegmentsDropdown(
              this.state.marketLandscapeAdditionalSegments
            );
            this.setSegmentsDropdown(this.state.companySegments, true);
            this.setCompaniesDropdown(this.state.marketLandscapeCompanies);
            this.setAuthorsDropdown(this.state.marketLandscapeAuthors);
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleSegmentsChange = (e) => {
    this.setSegmentsDropdown(e.id);
  };
  setSegmentsDropdown = (id, isEdit) => {
    let segments = [...this.props.home.segments];
    segments.forEach((c) => {
      c.is_selected = c.id === id;
    });
    this.props.setSegments(segments);
    this.setState(
      {
        marketLandscapeSegment: segments,
        additionalSegments: this.state.additionalSegments.filter((r) => r.id != id),
        marketLandscapeAdditionalSegments:
        this.state.marketLandscapeAdditionalSegments.filter((r) => r != id),
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleCompanyChange = (e) => {
    let selectedCompanies = e.map((a) => a.id);
    this.setCompaniesDropdown(selectedCompanies);
  };
  setCompaniesDropdown = (selectedCompanies) => {
    let companies = [...this.state.companies];
    companies.forEach((c) => {
      c.is_selected = selectedCompanies.includes(c.id);
    });
    this.setState(
      {
        companies: companies,
        marketLandscapeCompanies: selectedCompanies,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleAdditionalSegmentsChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    this.setAdditionalSegmentsDropdown(selectedSegments);
  };
  setAdditionalSegmentsDropdown = (selectedAdditionalSegments) => {
    let segmentsList = [...this.state.additionalSegments];
    segmentsList.forEach((c) => {
      c.is_selected = selectedAdditionalSegments.includes(c.id);
    });
    this.setState({
      additionalSegments: segmentsList,
      marketLandscapeAdditionalSegments: selectedAdditionalSegments,
    });
  };
  handleAuthorChange = (e) => {
    let selectedAuthors = e.map((a) => a.id);
    this.setAuthorsDropdown(selectedAuthors);
  };
  setAuthorsDropdown = (selectedAuthors) => {
    let authors = [...this.props.home.getTbriUsers];
    authors.forEach((c) => {
      c.is_selected = selectedAuthors.includes(c.id);
    });
    this.props.setTbriUsers(authors);
    this.setState(
      {
        marketLandscapeAuthors: selectedAuthors,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  isFormValid = () => {
    let formError = false;
    let marketLandscapeNameError = false;
    let segmentsError = false;
    let companiesError = false;
    let authorsError = false;
    if (isEmpty(this.state.marketLandscapeName.trim())) {
      marketLandscapeNameError = true;
      formError = true;
    }
    if (!this.props.home.segments.find((c) => c.is_selected)) {
      segmentsError = true;
      formError = true;
    }
    if (!this.state.companies.find((c) => c.is_selected)) {
      companiesError = true;
      formError = true;
    }
    if (!this.props.home.getTbriUsers.find((c) => c.is_selected)) {
      authorsError = true;
      formError = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        marketLandscapeNameError: marketLandscapeNameError,
        segmentsError: segmentsError,
        authorsError: authorsError,
        companiesError: companiesError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let marketLandscapeId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let prepareObject = {
            name: this.state.marketLandscapeName,
            segment_id: this.props.home.segments.find((t) => t.is_selected)
              ? this.props.home.segments.find((t) => t.is_selected).id
              : null,
            company_ids: this.state.marketLandscapeCompanies,
            author_ids: this.state.marketLandscapeAuthors,
            description: this.state.description,
            additional_segments: this.state.marketLandscapeAdditionalSegments,
          };
          if (marketLandscapeId === null) {
            //create
            MarketLandscapeServices.addMarketLandscape(prepareObject)
              .then((res) => {
                this.saveMarketLandscapeSuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            MarketLandscapeServices.editMarketLandscape(
              marketLandscapeId,
              prepareObject
            )
              .then((res) => {
                this.saveMarketLandscapeSuccess();
              })
              .catch((error) => {
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          }
        }
      }
    );
  };
  saveMarketLandscapeSuccess = () => {
    let marketLandscapeId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    Toast(
      marketLandscapeId === null
        ? constants.MARKET_LANDSCAPE.CREATE.SUCCESS
        : constants.MARKET_LANDSCAPE.EDIT.SUCCESS,
      "success"
    );
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE,
        {}
      ),
    });
  };
  dailogModalAccept = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    this.removeMarketLandscape();
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };
  resetForm = () => {
    let marketLandscapeId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        marketLandscapeNameError: false,
        segmentsError: false,
        authorsError: false,
        companiesError: false,
      },
      apiError: null,
    });
    if (marketLandscapeId !== null) {
      this.prepopulateData(marketLandscapeId);
    } else {
      this.setState(
        {
          ...initialState,
        },
        () => {
          this.getCompanies();
          this.getSegments();
          this.getTbriUsers();
        }
      );
    }
  };
  breadcrumbs = () => {
    let marketLandscapeId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.MARKET_LANDSCAPE_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: marketLandscapeId
          ? constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.EDIT.NAME
          : constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE
      ),
    });
  };

  removeMarketLandscapeConfirmationHandler = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.MARKET_LANDSCAPE.REMOVE_MARKET_LANDSCAPE_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_market_landscape" },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeMarketLandscape = () => {
    MarketLandscapeServices.removeMarketLandscape(this.props.match.params.id)
      .then((res) => {
        Toast(constants.MARKET_LANDSCAPE.REMOVE.SUCCESS, "success");
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE,
            {}
          ),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  render() {
    let marketLandscapeId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let segments =
      this.props.home && this.props.home.segments
        ? this.props.home.segments
        : [];
    let tbriUsers =
      this.props.home && this.props.home.getTbriUsers
        ? this.props.home.getTbriUsers
        : [];
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-2 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"row col-lg-12 col-sm-12 col-md-12 col-12 mx-0"}>
                <form className={"col-lg-12 col-sm-12 form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card border-0">
                    <div className="card-header title-cancel-header d-flex align-items-center bg-transparent">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {marketLandscapeId === null
                          ? constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <div className="ml-auto manage-delete-button">
                        {this.state.showDeleteButton ? (
                          <button
                            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                            type="button"
                            className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                            onClick={
                              this.removeMarketLandscapeConfirmationHandler
                            }
                          >
                            <span className="mt-2px">
                              Delete Market Landscape
                            </span>
                            <span className="material-icons icon-fs-16 ml-1">
                              delete
                            </span>
                          </button>
                        ) : (
                          this.props.match.params.id && (
                            <AclAction
                              type={constants.ACL.PERMISSION_KEY}
                              entity={
                                constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE
                              }
                              action={
                                constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS
                                  .DELETE
                              }
                            >
                              <button
                                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                                type="button"
                                className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                                onClick={
                                  this.removeMarketLandscapeConfirmationHandler
                                }
                              >
                                <span className="mt-2px">
                                  Delete Market Landscape
                                </span>
                                <span className="material-icons icon-fs-16 ml-1">
                                  delete
                                </span>
                              </button>
                            </AclAction>
                          )
                        )}
                      </div>
                      <span
                        className="material-icons-round text-darkblue2 ml-4 cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    {segments.length > 0 && (
                      <div className="card-body">
                        <div className="card-body-header">
                          <h5 className="mb-0 font-weight-semibold">
                            Market Landscape Report
                          </h5>
                        </div>
                        <div className="form-field-wrapper">
                          <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className={"row"}>
                                <div className="col-sm-6 form-group position-relative">
                                  <label htmlFor="companyName">
                                    Name <span className={"mandatory"}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      this.state.formErrors
                                        .marketLandscapeNameError === true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors
                                        .marketLandscapeNameError === false
                                        ? ""
                                        : ""
                                    }`}
                                    id="marketLandscapeName"
                                    placeholder="Name"
                                    value={this.state.marketLandscapeName}
                                    onChange={this.handleInputChange}
                                  />
                                  {this.state.formErrors
                                    .marketLandscapeNameError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="status">
                                    Segment{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <div
                                    className={`${
                                      this.state.formErrors.segmentsError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={segments}
                                      handleChange={this.handleSegmentsChange}
                                      placeholder={"Select Segment"}
                                    />
                                  </div>
                                  {this.state.formErrors.segmentsError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="status">
                                    Companies{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <div
                                    className={`${
                                      this.state.formErrors.companiesError
                                        ? "is-invalid"
                                        : ""
                                    } custom-multiselect`}
                                  >
                                    <CustomMultiSelect
                                      data={this.state.companies}
                                      handleChange={this.handleCompanyChange}
                                      placeholder={"Select Companies"}
                                    />
                                  </div>
                                  {this.state.formErrors.companiesError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="status">
                                    Authors{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <div
                                    className={`${
                                      this.state.formErrors.authorsError
                                        ? "is-invalid"
                                        : ""
                                    } custom-multiselect`}
                                  >
                                    <CustomMultiSelect
                                      data={tbriUsers}
                                      handleChange={this.handleAuthorChange}
                                      placeholder={"Select Authors"}
                                    />
                                  </div>
                                  {this.state.formErrors.authorsError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="status">
                                    Additional Segments{" "}
                                  </label>
                                  <div className={`custom-multiselect`}>
                                    <CustomMultiSelect
                                      data={this.state.additionalSegments}
                                      handleChange={
                                        this.handleAdditionalSegmentsChange
                                      }
                                      placeholder={"Select Additional Segments"}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 form-group position-relative">
                                  <label for="address">Description</label>
                                  <textarea
                                    className={`form-control ${
                                      this.state.formErrors.descriptionError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.descriptionError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                    id="description"
                                    placeholder="Description"
                                    value={this.state.description}
                                    onChange={this.handleInputChange}
                                  />
                                  {this.state.formErrors.descriptionError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-submit-buttons">
                          <div className={"row"}>
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className="text-right form-buttons">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary modal-btn"
                                  onClick={this.resetForm}
                                >
                                  Reset
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary modal-btn"
                                  onClick={this.handleSubmit}
                                >
                                  {this.props.match.params.id
                                    ? `Update`
                                    : `Save`}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
    setCurrencies: (currenciesList) => {
      dispatch(getCurrencies(currenciesList));
    },
    setTbriUsers: (tbriUsers) => {
      dispatch(getTbriUsers(tbriUsers));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageMarketLandscape);
