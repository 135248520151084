import React from "react";
import { msalInstance } from "../Auth/MSAL-Instance";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
const SSO_LOGOUT = (props) => {
  // const { instance } = useMsal();
  const logOutHandler = async () => {
    let isSSO = localStorage.getItem("SSO"); // will be true if logged in using SSO
    if (isSSO == "true") {
      await msalInstance
        .handleLogout()
        .then((res) => {
          localStorage.removeItem("SSO");
          props.userLogout();
        })
        .catch((e) => console.log(e));
    } else {
      props.userLogout();
    }
  };

  return (
    <li>
      <span
        onClick={logOutHandler}
        className="d-inline-flex align-items-center"
      >
        <i className="material-icons-outlined font-weight-bold">logout</i>
        Logout
      </span>
    </li>
  );
};

export default SSO_LOGOUT;
