import React, { Component } from "react";
import { connect } from "react-redux";
import Toast from "../Utils/Toast";
import Collapsible from "react-collapsible";
import constants from "../Utils/constants";
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Popover,
} from "reactstrap";

class DetailsCategories extends Component {
  constructor(props) {
    super(props);
    this.subcatref = React.createRef();
    this.formRef = React.createRef();
  }
  state = {
    showPopover: false,
    isEdit: false,
    category: [],
    popoverId: "add-edit-category-subcategory",
    isError: false,
  };
  componentDidMount() {
    document
      .querySelector(".inner-sidebar")
      .addEventListener("scroll", this.handleScroll);
    document.body.addEventListener("click", this.handleClickOutside);
  }
  componentWillUnmount() {
    document
      .querySelector(".inner-sidebar")
      .removeEventListener("scroll", this.handleScroll);
    document.body.removeEventListener("click", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    // Check if the clicked element is inside the popover
    const popover = document.querySelector(
      ".add-edit-category-subcategory-popover"
    );
    if (popover && popover.contains(event.target)) {
      return;
    }
    this.setState({
      showPopover: false,
      popoverId: this.state.popoverId,
    });
  };

  handleScroll = () => {
    this.setState({ showPopover: false, category: [] });
  };
  // This method is used to set the detailContent,categories,author,tbriUsers data based on the componentName you are passing
  // componentName for ex - MARKET_FORECAST,SPECIAL_REPORT,BENCHMARK etc..., these values get from Constants.moduleNames
  getDetailsContent = () => {
    const componentName = this.props.componentName;
    switch (componentName) {
      case constants.MODULE_NAMES.BENCHMARK:
        return {
          categories:
            this.props.benchmark_details &&
            this.props.benchmark_details.benchmarkCategories
              ? this.props.benchmark_details.benchmarkCategories
              : [],
        };
      case constants.MODULE_NAMES.SPECIAL_REPORT:
        return {
          categories:
            this.props.special_report_details &&
            this.props.special_report_details.specialReportCategories
              ? this.props.special_report_details.specialReportCategories
              : [],
        };
      case constants.MODULE_NAMES.MARKET_FORECAST:
        return {
          categories:
            this.props.market_forecast_details &&
            this.props.market_forecast_details.marketForecastCategories
              ? this.props.market_forecast_details.marketForecastCategories
              : [],
        };
      case constants.MODULE_NAMES.MARKET_LANDSCAPE:
        return {
          categories:
            this.props.market_landscape_details &&
            this.props.market_landscape_details.marketLandscapeCategories
              ? this.props.market_landscape_details.marketLandscapeCategories
              : [],
        };
      case constants.MODULE_NAMES.CUSTOMER_STUDY:
        return {
          categories:
            this.props.customer_study_details &&
            this.props.customer_study_details.customerStudyCategories
              ? this.props.customer_study_details.customerStudyCategories
              : [],
        };
      case constants.MODULE_NAMES.ECOSYSTEM:
        return {
          categories:
            this.props.ecosystem_details &&
            this.props.ecosystem_details.ecosystemCategories
              ? this.props.ecosystem_details.ecosystemCategories
              : [],
        };
      case constants.MODULE_NAMES.PROJECT:
        return {
          categories:
            this.props.project_details &&
            this.props.project_details.projectCategories
              ? this.props.project_details.projectCategories
              : [],
        };
      default:
        break;
    }
  };

  checkForDuplicates = (array) => {
    for (let i = 1; i < array.length; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (array[i] === array[j]) {
          return false;
        }
      }
    }
    return true;
  };

  handleSubmit = (event) => {
    this.setState({ isError: false });
    event.preventDefault();
    let data = [];
    if (event.target[0].value != "") {
      for (let i = 0; i < event.target.length; i++) {
        if (event.target[i].type === "text") {
          if (event.target[i].value == "") {
            this.setState({ isError: true });
            return;
          }
          event.target[i].value != "" && data.push(event.target[i].value);
        }
      }
      this.checkForDuplicates(data)
        ? this.props.addCategory(
            data,
            this.state.isEdit,
            this.state.category.name
          ) &&
          this.setState({
            showPopover: false,
            category_name: [],
          })
        : Toast("Same SubCategory names does not allowed", "error");
    } else {
      this.setState({ isError: true });
    }
  };

  addSubCategory = () => {
    let x = document.createElement("div");
    x.className = "input-wrapper";
    x.innerHTML =
      '<input type="text" class="form-control" id="subCategoryName" placeholder="Enter Subcategory Name" />';
    this.subcatref.current.appendChild(x);
  };
  changecategoryStatus = () => {
    this.setState({
      showPopover: !this.state.showPopover,
      isEdit: false,
    });
  };
  changestatesStatus = (category, id) => {
    this.setState(
      {
        isEdit: true,
        category: category,
        popoverId: id,
        showPopover: true,
      },
      () => {
        this.formRef.current[0].value = category.name;
        if (category.subcategories) {
          if (
            this.formRef.current.length - 2 <=
            category.subcategories.length
          ) {
            for (let i = 0; i < category.subcategories.length; i++) {
              this.addSubCategory();
              this.formRef.current[i + 2].value =
                category.subcategories[i].name;
            }
          }
        }
      }
    );
    // this.setState({ showPopover: true });
  };

  render() {
    let { categories } = this.getDetailsContent();
    return (
      <aside
        className={`inner-sidebar ${
          this.props.isEditable ? "add-cat-subcat" : ""
        }`}
      >
        <div className="sidebar_nav2 d-flex flex-column h-100">
          <div className="d-flex expand-collapse-icon-wrapper">
            <span
              className="material-icons-outlined expand-collapse-icon"
              onClick={() => {
                this.props.toggleInnerSidebar();
                this.setState({ showPopover: false });
              }}
            >
              keyboard_double_arrow_left
            </span>
          </div>
          <div className="opened-innersidebar-content">
            {this.props.isEditable && (
              <>
                <button
                  type="button"
                  className="btn btn-primary content-save-btn mb-2 w-auto float-none add_category d-flex align-items-center pl-1"
                  id="add-edit-category-subcategory"
                  onClick={this.changecategoryStatus}
                >
                  <span className="material-icons-outlined icon icon-fs-18 text-black2">
                    add
                  </span>
                  Add Category
                </button>
              </>
            )}
            <div className="custom-collapser mt-1 inner-sidebar-collapser">
              <div className="mb-1">
                <div className="custom-collapser-item custom-collapser-2-item">
                  {categories.map((category, index) => (
                    <Collapsible
                      key={index}
                      open={true}
                      trigger={
                        <>
                          <div className="d-flex align-items-center ml-auto collapse-icon">
                            <span className="material-icons-outlined material-icon">
                              chevron_right
                            </span>
                          </div>
                        </>
                      }
                      triggerSibling={() => (
                        <div className="d-flex align-items-center trigger-block add-remove-item  add-remove-edit-item">
                          {/* eslint-disable-next-line */}
                          <input
                            type="checkbox"
                            className="custom-checkbox-new"
                            name="Insights"
                            id="tbr_insights"
                            checked=""
                          />
                          <label className=" mb-0 title" htmlFor="tbr_insights">
                            <span className="">{category.name}</span>
                          </label>
                          {this.props.isEditable && (
                            <>
                              <span
                                className="material-icons-outlined icon icon-fs-16 text-primary cursor-pointer mr-6px"
                                id={`add-edit-category-subcategory_${category.id}`}
                                onClick={() => {
                                  this.changestatesStatus(
                                    category,
                                    `add-edit-category-subcategory_${category.id}`
                                  );
                                }}
                              >
                                edit
                              </span>
                              {!category.is_selected ? (
                                <span
                                  onClick={(e) => {
                                    if (this.props.isEditable) {
                                      this.props.toggleCheckbox(
                                        !category.is_selected,
                                        "category",
                                        category.uid
                                      );
                                    }
                                  }}
                                  className="material-icons-outlined icon icon-fs-18 text-primary cursor-pointer mr-1"
                                >
                                  add
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    if (this.props.isEditable) {
                                      this.props.toggleCheckbox(
                                        !category.is_selected,
                                        "category",
                                        category.uid
                                      );
                                    }
                                  }}
                                  className="material-icons-outlined icon icon-fs-18 remove-icon cursor-pointer mr-1"
                                >
                                  close
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    >
                      {category.subcategories.map((subcategory, index) => (
                        <div className="collapse-panel" key={index}>
                          <div className="collapse-panel-content">
                            <div
                              className={`${
                                subcategory.is_selected ? "selected" : ""
                              } item d-flex align-items-center add-remove-item`}
                            >
                              <input
                                className="custom-checkbox-new"
                                type="checkbox"
                                name="Breaking News"
                                id="tbr_insights_tbr_breaking_news"
                                checked=""
                              />
                              <label
                                className="non-selected sub-category-label mb-0 add-remove-label"
                                for="tbr_insights_tbr_breaking_news"
                              >
                                <span className="">{subcategory.name}</span>
                              </label>

                              {this.props.isEditable && (
                                <span
                                  onClick={(e) => {
                                    if (this.props.isEditable) {
                                      this.props.toggleCheckbox(
                                        !subcategory.is_selected,
                                        "sub_category",
                                        category.uid,
                                        subcategory.uid
                                      );
                                    }
                                  }}
                                  className={`${
                                    subcategory.is_selected
                                      ? "red-icon"
                                      : "blue-icon"
                                  } material-icons-outlined icon icon-fs-18 cursor-pointer mr-2px`}
                                >
                                  {subcategory.is_selected
                                    ? "playlist_remove"
                                    : "playlist_add"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Collapsible>
                  ))}
                  {this.props.isEditable && this.state.showPopover && (
                    <UncontrolledPopover
                      className="custom-popover add-edit-category-subcategory-popover"
                      placement="right"
                      trigger="legacy"
                      isOpen={this.state.showPopover}
                      target={this.state?.popoverId}
                    >
                      <PopoverBody>
                        <div className="d-flex align-items-center title-block">
                          <h5 className="mb-0 title font-weight-normal">
                            {this.state.isEdit ? "Edit" : "Add"} Category/
                            Subcategory
                          </h5>
                          <span
                            onClick={this.changecategoryStatus}
                            className="material-icons-round icon ml-auto icon-fs-18 cursor-pointer"
                          >
                            close
                          </span>
                        </div>
                        <form onSubmit={this.handleSubmit} ref={this.formRef}>
                          <div className="cat-subcat-inputs-block">
                            {this.state.isError && (
                              <div className="input-error">
                                Input can not be empty
                              </div>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              id="categoryName"
                              placeholder="Enter Category Name"
                            />
                            <button
                              type="button"
                              className="btn btn-primary content-save-btn mb-2 w-auto float-none add-sub-category d-flex align-items-center pl-1"
                              onClick={this.addSubCategory}
                            >
                              <span className="material-icons-outlined icon icon-fs-18 text-black2">
                                add
                              </span>
                              Add Sub Category
                            </button>
                            <div
                              className="sub-category-inputs"
                              ref={this.subcatref}
                            />
                          </div>
                          <div className="save-btn-wrapper d-flex">
                            <button
                              type="submit"
                              className={
                                "btn btn-custom btn-primary icon-text-btn d-inline-flex align-items-center ml-auto"
                              }
                            >
                              {this.state.isEdit ? "Update" : "Save"}
                              <span className="material-icons-outlined icon-fs-16 ml-1">
                                {this.state.isEdit ? "sync" : "save"}
                              </span>
                            </button>
                          </div>
                        </form>
                      </PopoverBody>
                    </UncontrolledPopover>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="closed-innersidebar-content d-flex flex-grow-1">
            <div className="content text-center font-weight-semibold">
              CONTENTS
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    benchmark_details: state.benchmark_details,
    special_report_details: state.special_report_details,
    market_landscape_details: state.market_landscape_details,
    market_forecast_details: state.market_forecast_details,
    customer_study_details: state.customer_study_details,
    ecosystem_details: state.ecosystem_details,
    project_details: state.project_details,
  };
};

export default connect(mapStateToProps, null)(DetailsCategories);
