import React, { Component } from "react";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";
import constants from "../../Utils/constants";
import { TemplateServices } from "../../Services/Template";
import { MetricRulesServices } from "../../Services/MetricRules";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import Search from "../../Components/Search";
import { connect } from "react-redux";
import AclAction from "../../Components/ACL/AclAction";
import DailogNew from "../../Components/DailogNew";
import { activeMenuTab } from "../../Store/Actions/User";
import { clients } from "../../Store/Actions/Client";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import Slugify from "../../Utils/Slugify";
import Toast from "../../Utils/Toast";
import DocumentTitle from "../../Utils/DocumentTitle";
import $ from "jquery";
import { FileUploadServices } from "../../Services/FileUpload";
import { ClientServices } from "../../Services/Client";
import CustomMultiSelectCheckbox from "../../Components/CustomMultiSelectCheckbox";
import isRole_Acc_mgr_or_salesAdmin from "../../Utils/isRole_Acc_mgr_or_salesAdmin";
import CustomDropdownList from "../../Components/CustomDropdownList";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import DownloadModal from "./DownloadModal";

const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "lob_name",
    title: "LOB's",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "vertical_name",
    title: "Vertical",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "geo_name",
    title: "Geography",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "metric_type_name",
    title: "Metric Type",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "metric_name",
    title: "Metric",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  // {
  //   id: "is_active",
  //   title: "Status",
  //   sortable: true,
  //   headerClass: "text-center",
  //   rowStyle: { textAlign: "center" },
  //   visibility: true,
  // },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    rowStyle: { minWidth: "80px", textAlign: "center" },
    headerClass: "text-center",
    rowClass: "",
    visibility: true,
  },
  {
    id: "",
    title: "",
    sortable: false,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];

const showActions = true;
class ListMetricsRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      isOpen: false,
      segmentList: [],
      currentPageDataCount: 0,
      disableStatus: false,
      treeData: [],
      downloadModal: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.TEMPLATE.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE);
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  handleSegmentChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    segments.forEach((s) => {
      s.is_selected = selectedSegments.includes(s.id);
    });
    this.setState({ segmentList: segments });
  };
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleTemplateConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TEMPLATE.INACTIVATE_TEMPLATE_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TEMPLATE.ACTIVATE_TEMPLATE_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          data.is_active === 1 ? "de-activate_template" : "activate_template",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };

  toggleTemplate = (templateId, isActive) => {
    MetricRulesServices.toggleMetricRule(templateId, { is_active: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.TEMPLATE.ACTIVATE.SUCCESS
            : constants.TEMPLATE.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_template":
        this.removeTemplate(config.id);
        break;
      case "de-activate_metric_rule":
        this.toggleTemplate(config.id, 0);
        break;
      case "activate_metric_rule":
        this.toggleTemplate(config.id, 1);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  getActions = (data) => {
    return (
      <>
        {/* <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.METRIC_RULE.TITLE}
          action={constants.ACL.METRIC_RULE.ACTIONS.EDIT}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE,
                data,
                e
              );
            }}
            className={"btn btn-md d-inline-flex align-items-center"}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            data-tip={constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE}
            data-iscapture='true'
          >
            <span className='material-icons-outlined text-blue-clr icon-fs-20'>
              edit
            </span>
          </button>
        </AclAction> */}
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.METRIC_RULE.TITLE}
          action={constants.ACL.METRIC_RULE.ACTIONS.MANAGE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE,
                data,
                e
              );
            }}
            className="btn btn-md d-inline-flex align-items-center"
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              visibility
            </span>
          </button>
        </AclAction>
      </>
    );
  };

  createHierarchy(data) {
    const idMap = new Map();
    const root = [];

    // Create a map of id to data object
    data.forEach((item) => {
      idMap.set(item.id, { ...item, children: [] });
    });

    // Build the tree structure
    idMap.forEach((item) => {
      if (item.parent_id === null) {
        // If parent_id is null, it is a main parent
        root.push(item);
      } else {
        // If parent_id is not null, add it as a child to its parent
        const parent = idMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    return root;
  }

  breakWords = (dataArray) =>
    dataArray.toString()?.length ? (
      dataArray.toString()?.length > 15 ? (
        <label
          className="text rct-title mb-0"
          key={dataArray}
          data-for={`tooltip-${dataArray}-active`}
          data-tip={dataArray}
        >
          {dataArray.toString().substring(0, 15) + "..."}
          <CustomReactTooltip
            id={`tooltip-${dataArray}-active`}
            multiline={true}
          />
        </label>
      ) : (
        dataArray.toString()
      )
    ) : (
      "N/A"
    );

  groupMetrics(metricsArray) {
    let metricsGroup = {};
    metricsArray.forEach((item) => {
      const parentName = item.parent_data.name;
      const childName = item.name;

      if (!metricsGroup[parentName]) {
        metricsGroup[parentName] = [];
      }

      metricsGroup[parentName].push(childName);
    });

    return metricsGroup;
  }

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = "/metric-rules/list";
    TemplateServices.getTemplates(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let templatesData = res.data.rows;
        this.setState({
          currentPageDataCount: templatesData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        templatesData = templatesData.map((data) => {
          let metric_type_name = this.groupMetrics(
            (data?.metrics || []).filter((metric) => metric.parent_id !== null)
          );
          let metric_name = (data?.metrics || []).filter(
            (metric) => metric.parent_id !== null && metric.name
          );
          return {
            id: data.id,
            name: (
              <NavLink
                tag={RRNavLink}
                style={{ padding: 0, cursor: "pointer" }}
                exact
                to={formatRoute(
                  constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE,
                  { id: data.id }
                )}
              >
                {this.breakWords(data.name)}
              </NavLink>
            ),
            lob_name: this.breakWords(data.lobs.map((i) => i.name)),
            lob_data: this.createHierarchy(data?.lobs),
            vertical: this.createHierarchy(data?.verticals),
            geo_data: this.createHierarchy(data?.geos),
            metric_data: this.createHierarchy(data?.metrics),
            vertical_name: this.breakWords(data.verticals.map((i) => i.name)),
            geo_name: this.breakWords(data.geos.map((i) => i.name)),
            metric_type_name: this.breakWords(Object.keys(metric_type_name)),
            metric_name: this.breakWords(metric_name.map((i) => i.name)),
            created_by: data?.created_by_user,
            // is_active:
            //   data.is_active === 0 || data.is_active === 1 ? (
            //     <AclAction
            //       type={constants.ACL.PERMISSION_KEY}
            //       entity={constants.ACL.METRIC_RULE.TITLE}
            //       action={constants.ACL.METRIC_RULE.ACTIONS.STATUS}
            //     >
            //       <div className='custom-control custom-switch success-toggle'>
            //         <input
            //           type='checkbox'
            //           className='custom-control-input'
            //           id={`customSwitch${data.id}`}
            //           checked={data.is_active === 1}
            //           onChange={(e) => {
            //             e.stopPropagation();
            //             this.rowAction(
            //               constants.APPLICATION_ROUTE.TEMPLATE.LIST.STATUS,
            //               data,
            //               e
            //             );
            //           }}
            //           disabled={this.state.disableStatus}
            //         />
            //         <label
            //           className='custom-control-label'
            //           for={`customSwitch${data.id}`}
            //         ></label>
            //       </div>
            //     </AclAction>
            //   ) : null,
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: templatesData, count: count }, () => {
          $(".comapany_table").css(
            "min-width",
            $(".comapany_table").find("thead").innerWidth()
          );
        });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        console.log("error", error);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() => {
        //removing second loading indicator
        document.body.classList.remove("second-loading-indicator");
      });
  };

  exportList = () => {};

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TEMPLATE.EDIT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.STATUS:
        this.toggleTemplateRulesConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  toggleTemplateRulesConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.MANAGE_METRIC_RULES_AND_THRESHOLDS
                  .INACTIVATE_METRIC_RULE_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.MANAGE_METRIC_RULES_AND_THRESHOLDS
                  .ACTIVATE_METRIC_RULE_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          data.is_active === 1
            ? "de-activate_metric_rule"
            : "activate_metric_rule",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };

  addTemplate = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.TEMPLATE.CREATE.ROUTE,
        {}
      ),
    });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE,
        {}
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  handleDownload = () => {
    let segment_ids = this.state.segmentList
      .filter((f) => f.is_selected)
      .map((s) => s.id);
    FileUploadServices.downloadExcel(segment_ids)
      .then((response) => {
        // let response = res && res.data
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "standardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          let segmentList = this.state.segmentList.map((s) => {
            s.is_selected = true;
            return s;
          });
          this.setState({ segmentList: segmentList });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleCreate = () => {
    this.props.history.push("/template/add");
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleModelClose = () => {
    this.setState({
      downloadModal: false,
    });
  };

  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          </div>
          <div
            className="d-flex  mb-18px"
            style={{ justifyContent: "space-between" }}
          >
            <div className="w-100-search border-search search-box-shadow-2">
              <Search
                handleSearch={this.handleSearch}
                handleInput={this.handleInput}
                value={this.state.name}
                placeHolder={"Search"}
              />
            </div>
            <div className="d-flex">
              <button
                className={
                  "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3 mr-3"
                }
                type="button"
                onClick={() => this.setState({ downloadModal: true })}
              >
                Download
              </button>
              <button
                className={
                  "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                }
                type="button"
                onClick={this.handleCreate}
              >
                <span className="material-icons-round icon-fs-16 mr-1">
                  add
                </span>
                {" " + "Create"}
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center flex-wrap"></div>
          <div className="">
            <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-iconcol-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
              <DynamicTableComponentNew
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={this.state.count}
                rowAction={this.rowAction}
                colSpan={tableHeader.filter((h) => h.visibility).length}
                showActions={showActions}
                showPagination={true}
                paginationClass={"center-pagination custom-pagination-new"}
                handlePageSize={this.handlePageSize}
              />
            </div>
            {/* Tooltips for icons in table, don't delete */}
            {this.state.rowData.map((data) => {
              return (
                <>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.TEMPLATE.LIST
                        .REMOVE_TEMPLATE_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                </>
              );
            })}

            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />

            {this.state.downloadModal && (
              <DownloadModal
                open={this.state.downloadModal}
                handleModelClose={this.handleModelClose}
              />
            )}
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListMetricsRules);
