import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import { TaxanomyServices } from "../Services/Taxanomy";
import TreeComponent from "../Components/TreeComponent";
import { CompanyServices } from "../Services/Company";
import { activeMenuTab } from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import HelpModal from "./HelpModal";
import Notification from "../Components/Notification";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

class ListTaxonomies extends Component {
  state = {
    finalObject: [],
    popUpModel: false,
    term_id: null,
    header: "",
    definition: "",
    emptyErrorMessage: "Defination should not be empty.",
    successMessage: "",
    apiErrors: "",
    isTrueValue: false,
  };

  handlePopUp = (id, name, definition) => {
    let termArray = id && id.split("_");
    let termId = parseInt(termArray[termArray.length - 1]);
    this.setState({
      popUpModel: !this.state.popUpModel,
      term_id: id && termId,
      header: name && name,
      definition: definition && definition,
    });
  };

  handleClosePopUp = () => {
    this.setState({
      popUpModel: false,
      successMessage: "",
    });
  };

  isDefinitionValid = (definition) => {
    const regex = new RegExp(/\s{2,}/g);
    return regex.test(definition);
  };

  handleInputChange = (e) => {
    const { value } = e.target;
    const isTrueValue = !value || this.isDefinitionValid(value);
    this.setState({
      definition: value,
      isTrueValue,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      description: this.state.definition,
    };
    TaxanomyServices.updateDefinition(this.state.term_id, body)
      .then((data) => {
        let obj = JSON.parse(data.config.data);
        this.setState({
          definition: obj && obj.description,
          successMessage: data.data.message,
        });
        this.getTaxonomiesDataApi();
        setTimeout(() => {
          this.handleClosePopUp();
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          apiErrors:
            error && error.data && error.data.error
              ? error.data.error
              : "Error, Someting went wrong!",
        });
      });
  };

  getTaxonomiesDataApi = () => {
    let finalObject = [];
    function looper(obj, parent) {
      obj.forEach((obj1) => {
        if (parent !== 0) obj1.id = parent + "_" + obj1.id;
        finalObject.push({
          id: obj1.id,
          name: obj1.name,
          parent_id: parent,
          definition: obj1.description ? obj1.description : "",
        });
        if (obj1.terms && obj1.terms.length > 0) {
          looper(obj1.terms, obj1.id);
        }
      });
    }
    CompanyServices.getTaxonomies()
      .then((res) => {
        looper(res.data, 0);
        finalObject.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        const updatedRowData = finalObject.map((obj) => {
          let dom = (
            <div className="d-flex row justify-content-between">
              <div className="col-11">{obj.definition}</div>
              {obj.definition && (
                <div className="col-1 d-flex justify-content-center align-items-center">
                  <div
                    onClick={() =>
                      this.handlePopUp(obj.id, obj.name, obj.definition)
                    }
                    className="cursor-pointer"
                  >
                    <button
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      className={
                        "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                      }
                      data-for="edit"
                      data-tip="Edit"
                      title="Edit"
                    >
                      <i
                        id="edit"
                        className="material-icons-outlined text-blue-clr icon-fs-20"
                      >
                        edit
                      </i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
          return {
            ...obj,
            definition: dom,
          };
        });
        this.setState({
          finalObject: updatedRowData,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  componentDidMount = () => {
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE);
    DocumentTitle(constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME);
    this.getTaxonomiesDataApi();
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,

        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="taxonomy-page-block">
            <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
              <h1 className="text-ligntblue2 font-weight-bold ">Taxonomy</h1>

              <div className="ml-auto">
                <HelpModal category="Taxonomy List" />
              </div>
            </div>

            <div className="taxonomy_page flex-grow-1">
              {this.state.finalObject.length > 0 && (
                <TreeComponent
                  columns={[
                    { name: "name", title: "Segments / Terms" },
                    { name: "definition", title: "TBR Definition" },
                  ]}
                  filterColumns={["name"]}
                  filteringColumnExtensions={[
                    { columnName: "definition", filteringEnabled: false },
                  ]}
                  tableColumnExtensions={[
                    { columnName: "name", width: "300", className: "asd" },
                    {
                      columnName: "definition",
                      wordWrapEnabled: true,
                      width: "900",
                    },
                  ]}
                  rows={this.state.finalObject}
                  treeColumnName={"name"}
                />
              )}
            </div>
            <Modal
              isOpen={this.state.popUpModel}
              toggle={this.handlePopUp}
              className={"modal-md modal-w-header custom-modal primary-modal"}
            >
              <ModalHeader>Edit Definition</ModalHeader>
              <form
                onSubmit={this.handleSubmit}
                className={`col-lg-12 col-sm-12 form-wrapper p-0`}
              >
                <ModalBody>
                  {this.state.successMessage.length > 0 && (
                    <Notification
                      color={"success"}
                      message={this.state.successMessage}
                    />
                  )}
                  {this.state.apiErrors.length > 0 && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiErrors}
                    />
                  )}
                  <FormGroup>
                    <Label style={{ fontSize: "16px" }} htmlFor="header">
                      {this.state.header}
                    </Label>
                    <br />
                    <textarea
                      className="textarea-definition"
                      type="text"
                      placeholder=""
                      id="definition"
                      name="definition"
                      onChange={this.handleInputChange}
                      value={this.state.definition}
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="modal-btn btn-outline-primary"
                    onClick={this.handleClosePopUp}
                    data-testid="cancelbtn"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="modal-btn"
                    color="primary"
                    type="submit"
                    role="submit"
                    disabled={this.state.isTrueValue}
                  >
                    Change Definition
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTaxonomies);
