import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { TemplateServices } from "../Services/Template";
import queryString from "query-string";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import $ from "jquery";
import { formatRoute } from "react-router-named-routes";
import { RuleServices } from "../Services/Rule";
import CustomDropdownList from "../Components/RulesCustomDropdown";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import { MasterServices } from "../Services/Master";
import _ from "lodash";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "rule",
    title: "Rule",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    rowStyle: { minWidth: "130px", textAlign: "center" },
    headerClass: "text-center",
    visibility: true,
  },
];

const showActions = true;
class ListMetricRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lob: [],
      geography: [],
      vertical: [],
      metric_type: [],
      metric_name: [],
      metrics: [],
      entireData: [],
      rowData: [],
      ruleData: [],
      filtersEnabled: false,
      filteredRuleData: [],
      count: 0,
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      isOpen: false,
      segmentList: [],
      disableStatus: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.TEMPLATE.LIST.HEADER_TITLE);
    this.getMasters();
    let queryParams = "";

    RuleServices.getRules(`metricrules` + queryParams)
      .then((res) => {
        if (res.data) {
          let tempData = res.data.data.filter(
            (item, index) => index < this.state.pageSize
          );
          this.setState({
            entireData: res.data.data,
            ruleData: tempData,
            count: res.data.meta.total_count,
          });
          this.getTableData();
        }
      })
      .catch((error) => {
        this.setState({ ruleData: [], rowData: [], count: 0 });
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });

    this.setState({
      pageNo: params.page
        ? parseInt(params.page)
        : constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      sortColumn: params.sort_column
        ? params.sort_column
        : constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: params.sort_order
        ? params.sort_order
        : constants.PAGINATION.DEFAULT_SORT_ORDER,
      // name: params.search_value ? params.search_value : ''
    });
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let lobIdArray =
      this.state.lob.filter((val) => val.is_selected === true)[0] !== undefined
        ? this.state.lob
            .filter((val) => val.is_selected === true)
            .map((data) => data.id)
        : [];
    let lobFilteredData = this.state.entireData.filter(
      (item) =>
        item.templaterecords.filter(
          (val) => val.lob && val.lob.id && lobIdArray?.includes(val.lob.id)
        ).length > 0
    );
    let geographyIdArray =
      this.state.geography.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.geography
            .filter((val) => val.is_selected === true)
            .map((data) => data.geographyid)
        : [];
    let geographyFilteredData = this.state.entireData.filter(
      (item) =>
        item.templaterecords.filter(
          (val) =>
            val.geography &&
            val.geography.geographyid &&
            geographyIdArray?.includes(val.geography.geographyid)
        ).length > 0
    );
    let verticalIdArray =
      this.state.vertical.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.vertical
            .filter((val) => val.is_selected === true)
            .map((data) => data.verticalid)
        : [];
    let verticalFilteredData = this.state.entireData.filter(
      (item) =>
        item.templaterecords.filter(
          (val) =>
            val.vertical &&
            val.vertical.verticalid &&
            verticalIdArray?.includes(val.vertical.verticalid)
        ).length > 0
    );
    let metrictypeIdArray =
      this.state.metric_type.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.metric_type
            .filter((val) => val.is_selected === true)
            .map((data) => data.metrictypeid)
        : [];
    let metrictypeFilteredData = this.state.entireData.filter(
      (item) =>
        item.templaterecords.filter(
          (val) =>
            val.metrictype &&
            val.metrictype.metrictypeid &&
            metrictypeIdArray?.includes(val.metrictype.metrictypeid)
        ).length > 0
    );
    let metricIdArray =
      this.state.metrics.filter((val) => val.is_selected === true)[0] !==
      undefined
        ? this.state.metrics
            .filter((val) => val.is_selected === true)
            .map((data) => data.metricid)
        : [];
    let metricFilteredData = this.state.entireData.filter(
      (item) =>
        item.templaterecords.filter(
          (val) =>
            val.metric &&
            val.metric.metricid &&
            metricIdArray?.includes(val.metric.metricid)
        ).length > 0
    );

    if (
      prevState.lob !== this.state.lob ||
      prevState.geography !== this.state.geography ||
      prevState.vertical !== this.state.vertical ||
      prevState.metric_type !== this.state.metric_type ||
      prevState.metrics !== this.state.metrics
    ) {
      let result = [...this.state.entireData];
      let filtersFlag = false;
      if (lobIdArray.length > 0) {
        result = _.intersection(lobFilteredData, result);
        filtersFlag = true;
      }
      if (geographyIdArray.length > 0) {
        result = _.intersection(geographyFilteredData, result);
        filtersFlag = true;
      }
      if (verticalIdArray.length > 0) {
        result = _.intersection(verticalFilteredData, result);
        filtersFlag = true;
      }
      if (metrictypeIdArray.length > 0) {
        result = _.intersection(metrictypeFilteredData, result);
        filtersFlag = true;
      }
      if (metricIdArray.length > 0) {
        result = _.intersection(metricFilteredData, result);
        filtersFlag = true;
      }
      this.setState(
        {
          ruleData: result,
          filteredRuleData: result,
          count: result.length,
          filtersEnabled: filtersFlag,
          pageNo: 1,
        },
        () => this.getTableData()
      );
    }
  }
  sortOptions = (rows) => {
    return rows.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );
  };

  handleMasterChange = (e, props) => {
    let selected = e.map((a) => a[props.valueField]);
    let selectors = [...this.state[props.selector]];
    selectors.forEach((c) => {
      c.is_selected = selected.includes(c[props.valueField]);
    });
    this.setState(
      {
        ...this.state,
        [props.selector]: selectors,
      },
      () => {
        if (props.selector === "metric_type") {
          this.setMetrics(selectors, props);
        }
      }
    );
  };
  isEditallowed = () => {
    if (this.props.home.loginUserSuccess != typeof "undefined") {
      let user_role_id = this.props.home.loginUserSuccess.role_id;
      let user_role = this.props.home.userRoles.filter((role) => {
        return role.id === user_role_id;
      });
      if (user_role.length != 0) {
        return user_role[0].is_others_content_edit_allowed;
      }
    }
    return null;
  };

  setMetrics = (selectors, props) => {
    let result = [];
    let metrics = CustomCloneDeep(this.state.metric_name);
    selectors.forEach((c) => {
      if (c.is_selected === true) {
        let filteredMetrics = metrics.filter(
          (m) => m.metrictypeid === c[props.valueField]
        );
        result.push(...filteredMetrics);
      }
    });
    this.setState({ metrics: result });
  };

  getMasters = () => {
    MasterServices.getMasters()
      .then((res) => {
        let result = res && res.data;
        this.setState({
          lob: CustomCloneDeep(
            result.lobs ? this.sortOptions(result.lobs) : []
          ),
          geography: CustomCloneDeep(
            result.geography ? this.sortOptions(result.geography) : []
          ),
          vertical: CustomCloneDeep(
            result.vertical ? this.sortOptions(result.vertical) : []
          ),
          metric_type: CustomCloneDeep(
            result.metric_type ? this.sortOptions(result.metric_type) : []
          ),
          metric_name: CustomCloneDeep(
            result.metric ? this.sortOptions(result.metric) : []
          ),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleSegmentChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    segments.forEach((s) => {
      s.is_selected = selectedSegments.includes(s.id);
    });
    this.setState({ segmentList: segments });
  };

  getData = () => {
    if (this.state.filtersEnabled) {
      return this.state.filteredRuleData;
    } else {
      return this.state.entireData;
    }
  };

  pagePrevious = () => {
    let data = this.getData();
    let tempData = data.filter(
      (item, index) =>
        index >= this.state.pageSize * (this.state.pageNo - 2) &&
        index < this.state.pageSize * (this.state.pageNo - 1)
    );
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
        ruleData: tempData,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    let data = this.getData();
    let tempData = data.filter(
      (item, index) =>
        index >= this.state.pageSize * this.state.pageNo &&
        index < this.state.pageSize * (this.state.pageNo + 1)
    );
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
        ruleData: tempData,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    let data = this.getData();
    let tempData = data.filter(
      (item, index) =>
        index >= this.state.pageSize * (input - 1) &&
        index < this.state.pageSize * input
    );
    this.setState({ ruleData: tempData, pageNo: input }, () =>
      this.getTableData()
    );
  };
  handleSort = (clickedColumn, key) => {
    let data = this.getData();
    let tempSortedData;
    if (this.state.sortOrder === "asc") {
      tempSortedData = data.sort(function (first, second) {
        return second.id - first.id;
      });
    } else if (this.state.sortOrder === "desc") {
      tempSortedData = data.sort(function (first, second) {
        return first.id - second.id;
      });
    }
    if (this.state.filtersEnabled) {
      this.setState({ filteredRuleData: tempSortedData });
    } else {
      this.setState({ entireData: tempSortedData });
    }
    let tempData = data.filter((item, index) => index < this.state.pageSize);
    this.setState(
      {
        pageNo: 1,
        ruleData: tempData,
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleTemplateConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TEMPLATE.INACTIVATE_TEMPLATE_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TEMPLATE.ACTIVATE_TEMPLATE_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          data.is_active === 1 ? "de-activate_template" : "activate_template",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  removeTemplateConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.METRIC_RULE.REMOVE_METRIC_RULE_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_template", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeTemplate = (templateId) => {
    RuleServices.removeRule(templateId)
      .then((res) => {
        Toast(constants.METRIC_RULE.REMOVE.SUCCESS, "success");
        let rulesData = this.state.ruleData.filter(
          (data) => data.id !== templateId
        );
        debugger;
        this.setState(
          {
            ruleData: rulesData,
          },
          this.getTableData
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleTemplate = (templateId, isActive) => {
    TemplateServices.toggleTemplate(templateId, { is_active: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.TEMPLATE.ACTIVATE.SUCCESS
            : constants.TEMPLATE.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_template":
        this.removeTemplate(config.id);
        break;
      case "de-activate_template":
        this.toggleTemplate(config.id, 0);
        break;
      case "activate_template":
        this.toggleTemplate(config.id, 1);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.DELETE}
        >
          <div
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              edit
            </span>
          </div>
        </AclAction>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.DELETE}
        >
          <div
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-grey icon-fs-20">
              delete
            </span>
          </div>
        </AclAction>
      </>
    );
  };

  getMetricName = (data, id) => {
    return data.templaterecords.filter((item) => item.id === parseInt(id))[0]
      .metric.name;
  };

  getTableData = () => {
    let queryParams = "";
    // if (this.state.sortOrder) {
    //   queryParams = '&sort_order=' +
    //     this.state.sortOrder + '&sort_column=' + this.state.sortColumn
    // }

    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryParams,
    });

    const getInfo = (name, data) => {
      let lob_group = [
        data.lob ? data.lob.name : null,
        data.lob_subcategory1 ? data.lob_subcategory1.name : null,
        data.lob_subcategory2 ? data.lob_subcategory2.name : null,
        data.lob_subcategory3 ? data.lob_subcategory3.name : null,
        data.lob_subcategory4 ? data.lob_subcategory4.name : null,
        data.lob_subcategory5 ? data.lob_subcategory5.name : null,
      ]
        .filter((n) => n)
        .join(" - ");
      let geo_group = [
        data.geography ? data.geography.name : null,
        data.geosubcategory ? data.geosubcategory.name : null,
        data.country ? data.country.name : null,
      ]
        .filter((n) => n)
        .join(" - ");
      let vertical_group = [
        data.vertical ? data.vertical.name : null,
        data.verticalcategory ? data.verticalcategory.name : null,
        data.verticalsubcategory ? data.verticalsubcategory.name : null,
      ]
        .filter((n) => n)
        .join(" - ");
      return (
        <>
          <span
            key={data.id}
            className={"datagrid_tooltip"}
            id={`tooltip-${data.id}`}
            data-for={`tooltip-${data.id}`}
            data-tip={`
          LOB: ${lob_group}</br>
          Geo: ${geo_group}</br>
          Vertical: ${vertical_group}`}
            data-iscapture="true"
            data-html="true"
          >
            <span style={{ cursor: "pointer" }} className="mx-1">
              {name}
            </span>
          </span>
          <CustomReactTooltip
            id={`tooltip-${data.id}`}
            multiline={true}
            place={"right"}
          />
        </>
      );
    };

    // RuleServices.getRules(`metricrules?page=` +
    //   this.state.pageNo + '&page_size=100000' + queryParams).then((res) => {
    //     let templatesData = res.data.data
    var templatesData;
    if (this.state.name === "") {
      templatesData = this.state.ruleData.map((data, index) => {
        let lhs = data.lhs;
        let rhs = data.rhs.split("");
        let newRhs = [];
        let count = 1;
        let temp = 0;
        for (let i = 0; i < rhs.length; i++) {
          if (rhs[i].toString() === "+" || rhs[i].toString() === "-") {
            newRhs.push(temp.replace(/^0+/, ""));
            newRhs.push(rhs[i]);
            count = 1;
            temp = 0;
          } else {
            temp = temp * count + rhs[i];
          }
          if (i === rhs.length - 1) {
            newRhs.push(temp.replace(/^0+/, ""));
          }
        }

        for (let i = 0; i < newRhs.length; i++) {
          if (!(newRhs[i].toString() === "+" || newRhs[i].toString() === "-")) {
            let tempVal = data.templaterecords.filter(
              (item) => item.id === parseInt(newRhs[i])
            )[0].metric.name;
            newRhs[i] = getInfo(
              tempVal,
              data.templaterecords.filter(
                (item) => item.id === parseInt(newRhs[i])
              )[0]
            );
          }
        }
        let rule = (
          <div>
            {getInfo(
              this.getMetricName(data, lhs),
              data.templaterecords.filter(
                (item) => item.id === parseInt(lhs)
              )[0]
            )}
            ={newRhs}
          </div>
        );
        return {
          id: data.id,
          rule: rule,
          created_by:
            data.created_by_user && data.created_by_user.id
              ? `${
                  data.created_by_user.first_name
                    ? data.created_by_user.first_name
                    : ""
                } ${
                  data.created_by_user.last_name
                    ? data.created_by_user.last_name
                    : ""
                }`
              : "-",
          created_at: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
          actions: this.getActions(data),
        };
      });
    } else {
      let dataToBeSearched = this.getData();
      let filteredData = dataToBeSearched.map((item) =>
        item.templaterecords.filter(
          (val) =>
            (val.metric &&
              val.metric.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.lob &&
              val.lob.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.lob_subcategory1 &&
              val.lob_subcategory1.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.lob_subcategory2 &&
              val.lob_subcategory2.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.lob_subcategory3 &&
              val.lob_subcategory3.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.lob_subcategory4 &&
              val.lob_subcategory4.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.lob_subcategory5 &&
              val.lob_subcategory5.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.vertical &&
              val.vertical.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.verticalcategory &&
              val.verticalcategory.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.verticalsubcategory &&
              val.verticalsubcategory.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.geography &&
              val.geography.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.geosubcategory &&
              val.geosubcategory.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase())) ||
            (val.country &&
              val.country.name
                .toLowerCase()
                .includes(this.state.name.toLowerCase()))
        )
      );

      let filteredSearch = [];
      for (let i = 0; i < filteredData.length; i++) {
        let temp = this.state.entireData.filter((item) =>
          item.templaterecords.includes(filteredData[i][0])
        );
        if (temp.length) {
          filteredSearch.push(temp[0]);
        }
      }

      templatesData = filteredSearch.map((data) => {
        let lhs = data.lhs;
        let rhs = data.rhs.split("");
        let newRhs = [];
        let count = 1;
        let temp = 0;
        for (let i = 0; i < rhs.length; i++) {
          if (rhs[i].toString() === "+" || rhs[i].toString() === "-") {
            newRhs.push(temp.replace(/^0+/, ""));
            newRhs.push(rhs[i]);
            count = 1;
            temp = 0;
          } else {
            temp = temp * count + rhs[i];
          }
          if (i === rhs.length - 1) {
            newRhs.push(temp.replace(/^0+/, ""));
          }
        }

        for (let i = 0; i < newRhs.length; i++) {
          if (!(newRhs[i].toString() === "+" || newRhs[i].toString() === "-")) {
            let tempVal = data.templaterecords.filter(
              (item) => item.id === parseInt(newRhs[i])
            )[0].metric.name;
            newRhs[i] = getInfo(
              tempVal,
              data.templaterecords.filter(
                (item) => item.id === parseInt(newRhs[i])
              )[0]
            );
          }
        }
        let rule = (
          <div>
            {getInfo(
              this.getMetricName(data, lhs),
              data.templaterecords.filter(
                (item) => item.id === parseInt(lhs)
              )[0]
            )}
            ={newRhs}
          </div>
        );
        return {
          id: data.id,
          rule: rule,
          created_by:
            data.created_by_user && data.created_by_user.id
              ? `${
                  data.created_by_user.first_name
                    ? data.created_by_user.first_name
                    : ""
                } ${
                  data.created_by_user.last_name
                    ? data.created_by_user.last_name
                    : ""
                }`
              : "-",
          created_at: data.created_at
            ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
            : ComapnyDetailsDateTimeFormatter(new Date(), false),
          actions: this.getActions(data),
        };
      });
    }
    this.setState({ rowData: templatesData, count: this.state.count }, () => {
      $(".comapany_table").css(
        "min-width",
        $(".comapany_table").find("thead").innerWidth()
      );
    });
    // }).catch((error) => {
    //   this.setState({ rowData: [], count: 0 })
    // })
  };

  exportList = () => {};

  handlePageSize = (e) => {
    let data = this.getData();
    let tempData = data.filter((item, index) => index < e.target.value);
    this.setState(
      { ruleData: tempData, pageSize: e.target.value, pageNo: 1 },
      () => {
        this.getTableData();
      }
    );
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.REMOVE_TEMPLATE:
        this.removeTemplateConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.TEMPLATE.LIST.EDIT_TEMPLATE:
        this.editRule(data);
        break;
      default:
        break;
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  editRule = (data) => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.METRIC_RULE.EDIT.ROUTE,
        { id: data.id }
      ),
    });
  };

  addRule = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.METRIC_RULE.CREATE.ROUTE,
        {}
      ),
    });
  };

  render() {
    return (
      <>
        <AclAction
          type="permissions"
          entity={constants.ACL.METRIC_DATA_RESOURCE.TITLE}
          action={constants.ACL.METRIC_DATA_RESOURCE.ACTIONS.CREATE}
        >
          <div
            tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
            className="row justify-content-between"
          >
            <div className="col-lg-2 mb-3 col-sm-6 position-relative">
              <div className="custom-dropdown-list">
                <CustomDropdownList
                  data={this.state.lob}
                  handleChange={this.handleMasterChange}
                  placeholder={"Line of Business"}
                  valueField={"id"}
                  textField={"name"}
                  selector="lob"
                />
              </div>
            </div>
            <div className="col-lg-2 mb-3 col-sm-6 position-relative">
              <div className="custom-dropdown-list">
                <CustomDropdownList
                  data={this.state.geography}
                  handleChange={this.handleMasterChange}
                  placeholder={"Geography"}
                  valueField={"geographyid"}
                  textField={"name"}
                  selector="geography"
                />
              </div>
            </div>
            <div className="col-lg-2 mb-3 col-sm-6 position-relative">
              <div className="custom-dropdown-list">
                <CustomDropdownList
                  data={this.state.vertical}
                  handleChange={this.handleMasterChange}
                  placeholder={"Vertical"}
                  valueField={"verticalid"}
                  textField={"name"}
                  selector="vertical"
                />
              </div>
            </div>
            <div className="col-lg-2 mb-3 col-sm-6 position-relative">
              <div className="custom-dropdown-list">
                <CustomDropdownList
                  data={this.state.metric_type}
                  handleChange={this.handleMasterChange}
                  placeholder={"Metric Type"}
                  valueField={"metrictypeid"}
                  textField={"name"}
                  selector="metric_type"
                />
              </div>
            </div>
            <div className="col-lg-2 mb-3 col-sm-6 position-relative">
              <div className="custom-dropdown-list">
                <CustomDropdownList
                  data={this.state.metrics}
                  handleChange={this.handleMasterChange}
                  placeholder={"Metric"}
                  valueField={"metricid"}
                  textField={"name"}
                  selector="metrics"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end align-self-end flex-wrap ml-auto col">
              <button
                className={
                  "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3"
                }
                type="button"
                onClick={this.addRule}
              >
                <span className="material-icons-round icon-fs-16 mr-1">
                  add
                </span>
                <span>{" " + "Add"}</span>
              </button>
            </div>
          </div>
        </AclAction>
        <div className="d-flex">
          <div className="min-width-266 border-search search-box-shadow-2 mb-3">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Search"}
            />
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-iconcol-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            // className={`full-grid`}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>
        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.TEMPLATE.LIST
                    .EDIT_TEMPLATE_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.TEMPLATE.LIST
                    .REMOVE_TEMPLATE_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListMetricRules);
