import React from "react";
import ReactPaginate from "react-paginate";
import constants from "../Utils/constants";

export default function CustomPagination(props) {
  let handlePageChange = (event) => {
    props.handlePage(event.selected + 1);
  };
  return (
    <div
      className={`${props.className} d-flex align-items-center w-100 flex-wrap pagination-block`}
    >
      {props.pageSizeShow === false ? null : (
        <div className="item-count-block d-flex align-items-center">
          <label className="mb-0 mr-2">Items per page</label>
          <div className="custom-select-new">
            <select
              value={props.pageSize}
              onChange={(e) => {
                props.handlePageSize(e);
              }}
            >
              {constants.PAGINATION.PAGE_SIZE_OPTIONS.map((field, index) => (
                <option value={field} key={index}>
                  {field}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <ReactPaginate
        nextLabel={
          <span className="material-icons-outlined">chevron_right</span>
        }
        onPageChange={handlePageChange}
        renderOnZeroPageCount={null}
        pageRangeDisplayed={5}
        pageCount={props.totalPages()}
        previousLabel={
          <span className="material-icons-outlined">chevron_left</span>
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        forcePage={props.pageNo - 1}
      />
    </div>
  );
}
