import { Axios } from '../lib/axios'
export const ProjectServices = {
  getTotalProjects,
  getProjects,
  getProjectDetails,
  getProjectContent,
  saveProjectContent,
  addProject,
  editProject,
  toggleProject,
  removeProject,
  getCategories,
  getProjectTemplates,
  saveProjectTransactionContent,
  getManageContentTransactionContent,
  copyProject
}
function getTotalProjects(url) {
  return Axios.get(url)
}
function getProjects(url) {
  return Axios.get(url)
}
function getProjectDetails(id) {
  return Axios.get(`project/${id}`)
}
function getProjectContent(id) {
  return Axios.get(`project/${id}/content`)
}
function saveProjectContent(projectId, content, action) {
  return Axios.post(`project/${projectId}/content/${action}`, content)
}
function addProject(clientId,body) {
  return Axios.post(`project`, body)
}
function editProject(projectId, body) {
  return Axios.patch(`project/${projectId}`, body)
}
function toggleProject(projectId, body) {
  return Axios.patch(`project/${projectId}/status/${body.status}`, body)
}
function removeProject(projectId) {
  return Axios.delete(`project/${projectId}`)
}
function getCategories(projectId) {
  return Axios.get(`categories?type=project`)
}
function getProjectTemplates() {
  return Axios.get('templates')
}
function saveProjectTransactionContent(transaction_id, content, action = '') {
  return Axios.post(`transaction/${transaction_id}/type/project/state/${action}`, content)
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/project`)
}
function copyProject(projectId) {
  return Axios.get(`project/${projectId}/copy`)
}