
const scrollIntoErrors =(errors)=>{
    const firstError = errors[0];
    let element = document.getElementById(firstError)
  
    if(element){
        element.scrollIntoView({
            behavior: "smooth",
            block:"center"
        })
    }
}
export default scrollIntoErrors