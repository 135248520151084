import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import CustomDropdownList from "../Components/CustomDropdownList";
import DailogNew from "../Components/DailogNew";
import constants from "../Utils/constants";
import { useSelector } from "react-redux";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Slugify from "../Utils/Slugify";
import CustomReactTooltip from "../Components/CustomReactTooltip";
export default function Authors(props) {
  const { entity, create_action, delete_action } = props;

  const defaultModalState = {
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
  };

  const [dailogModalState, setdailogModalState] = useState(defaultModalState);
  const [addAuthorModalState, setAddAuthorModalState] = useState(false);
  let tbriUsers = useSelector((store) =>
    store.home && store.home.getTbriUsers ? store.home.getTbriUsers : []
  );
  const [filterTbriUsers , setFilterTbriUsers] = useState(tbriUsers)
  const [authors, setAuthors] = useState([]);
  useEffect(() => {
    if(props?.authors){
      let authorIds = props?.authors?.map((i) => i.id)
      setFilterTbriUsers(tbriUsers.filter((i) => 
        !authorIds.includes(i.id)
      ))
    }
    setAuthors(props.authors);
  }, [props]);

  const dailogModalAccept = (config) => {
    props.removeAuthor(config.uid);
    setdailogModalState(defaultModalState);
  };

  const dailogModalDecline = () => {
    setdailogModalState(defaultModalState);
  };

  const removeAuthorConfirmation = (author) => {
    const UID = author?.company_segment_author?.id || author?.author?.id;
    let dailogModalHeader = (
      <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_AUTHOR_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    setdailogModalState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_author", uid: UID },
      dailogModalStyleType: "danger-modal",
    });
  };

  const toggleAddAuthorsModal = () => {
    setAddAuthorModalState(!addAuthorModalState);
  };

  const saveAddAuthorsModal = () => {
    setAddAuthorModalState(false);
    props.addAuthor();
  };

  return (
    <>
      <AclAction type="permissions" entity={entity} action={create_action}>
        <div
          tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
          className="ml-auto d-flex align-items-center justify-content-end title-right-block mb-0"
        >
          {!props.only_list_view && (
            <button
              className="icon-btn-primary icon-btn d-inline-flex align-items-center justify-content-center"
              type="button"
              onClick={toggleAddAuthorsModal}
            >
              <span className="material-icons-outlined icon">group_add</span>
            </button>
          )}

          {authors != undefined && authors.length > 0 && (
            <button
              className="btn dropdown-btn d-inline-flex align-items-center ml-2 pr-1"
              type="button"
              id="authors-popover"
            >
              <span>{authors != undefined ? authors.length : 0} Author(s)</span>
              <span className="material-icons arrow-drown-down ml-1 icon-fs-20">
                arrow_drop_down
              </span>
            </button>
          )}
          {authors != undefined && authors.length > 0 && (
            <UncontrolledPopover
              target="authors-popover"
              className="custom-popover authors-popover"
              placement="bottom"
              trigger="legacy"
            >
              <PopoverBody>
                <div className="authors-list">
                  {authors.map((author, index) => (
                    <div
                      className="author d-flex justify-content-between align-items-center"
                      key={author.id + "-" + index}
                    >
                      <a href={`mailto:${author.email}`}>
                        {author.first_name} {author.last_name}
                      </a>
                      <div className="d-flex">
                        {author.copied ? (
                          <>
                            <span
                              className="material-icons-outlined copy-icon icon-fs-14 ml-4 text-green cursor-pointer"
                              data-for={`tooltip-${Slugify(author.id)}-copied`}
                              data-tip={`Copied`}
                              data-iscapture="true"
                            >
                              done
                            </span>
                            <CustomReactTooltip
                              id={`tooltip-${Slugify(author.id)}-copied`}
                              multiline={true}
                            />
                          </>
                        ) : (
                          <>
                            <span
                              className="material-icons-outlined copy-icon icon-fs-14 ml-4 cursor-pointer"
                              aria-hidden="true"
                              onClick={() => {
                                props.copyhandler(
                                  JSON.parse(JSON.stringify(author))
                                );
                              }}
                              data-for={`tooltip-${Slugify(author.id)}-copy`}
                              data-tip={`Copy Email`}
                              data-iscapture="true"
                            >
                              content_copy
                            </span>
                            <CustomReactTooltip
                              id={`tooltip-${Slugify(author.id)}-copy`}
                              multiline={true}
                            />
                          </>
                        )}
                        {
                          <AclAction
                            type="permissions"
                            entity={entity}
                            action={delete_action}
                          >
                            <span
                              className="material-icons-outlined del-icon icon-fs-14 ml-4 text-red cursor-pointer"
                              tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                              onClick={() => removeAuthorConfirmation(author)}
                            >
                              delete
                            </span>
                          </AclAction>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          )}
        </div>
      </AclAction>
      <DailogNew
        isOpen={dailogModalState.dailogModal}
        accept={dailogModalAccept}
        decline={dailogModalDecline}
        header={dailogModalState.dailogModalHeader}
        content={dailogModalState.dailogModalContent}
        config={dailogModalState.dailogModalConfig}
        modalStyleType={dailogModalState.dailogModalStyleType}
      />

      <Modal
        isOpen={addAuthorModalState}
        toggle={toggleAddAuthorsModal}
        className={"custom-modal primary-modal modal-without-header"}
      >
        <ModalBody>
          <div className={"row"}>
            <form className={"col-lg-12 col-sm-12 form-wrapper"}>
              <div className={"row"}>
                <div className="col-lg-12 col-sm-12 position-relative">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <label for="companies" className="m-0">
                      <h4 className="m-0">
                        Author <span className={"mandatory"}>* </span>
                      </h4>
                    </label>
                    <span
                      className="material-icons text-grey pointer"
                      onClick={toggleAddAuthorsModal}
                    >
                      close
                    </span>
                  </div>
                  {tbriUsers && (
                    <div className="custom-combobox-list">
                      <CustomDropdownList
                        isDisabled={false}
                        data={filterTbriUsers}
                        handleChange={props.handleAuthorChange}
                        placeholder={"Select Author"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        {tbriUsers && tbriUsers.length > 0 && (
          <ModalFooter>
            <Button
              className={"btn-outline-primary modal-btn"}
              onClick={toggleAddAuthorsModal}
            >
              Cancel
            </Button>{" "}
            <Button
              className={"modal-right-btn modal-btn"}
              onClick={saveAddAuthorsModal}
              disabled={!tbriUsers.find((u) => u.is_selected === true)}
            >
              Add
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
}