import React, { Component } from "react";
import AclAction from "../Components/ACL/AclAction";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import DailogNew from "../Components/DailogNew";
import DynamicTableWithSelectAllComponentNew from "../Components/DynamicTableWithSelectAllNew";
import Search from "../Components/Search";
import { EmailBounceServices } from "../Services/EmailBounceService";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Notification from "../Components/Notification";

const tableHeader = [
  {
    id: "id",
    title: "Unique Id",
    sortable: false,
    headerStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: false,
  },
  {
    id: "company_name",
    title: "Client",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "user_name",
    title: "User",
    sortable: false,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
    },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },

  {
    id: "source",
    title: "Source",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "status",
    title: "Status",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "last_login_datetime",
    title: "Last Login At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },

  {
    id: "updated_at",
    title: "Last Verified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
];
const showActions = false;

class SuppressionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      count: 0,
      isSelectAll: false,
      selectedValues: [],
      sortColumn: constants.PAGINATION.EMAILBOUNCELIST_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: this.props.pageNo,
      pageSize: this.props.pageSize,
      name: "",
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      timeInterval: 0,
      searchType: false,
      checkBoxDisable: false,
      selectReason: "",
      email: "",
      emailError: "",
      documentModal: false,
      successMessage: "",
    };
  }

  componentDidMount() {
    this.getTableData();
  }

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                .REMOVE_CLIENT_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.CLIENT_SUPPRESSION_EMAIL.LIST
                  .ACTIVE_BUTTON_TITLE,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-tip={
              constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                .REMOVE_CLIENT_TOOLTIP
            }
            data-iscapture="true"
            disabled={IsTBRIClient(data)}
          >
            <span className="material-icons-outlined text-grey icon-fs-20">
              delete
            </span>
          </button>
        </AclAction>
      </>
    );
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }

    if (this.state.name !== "") {
      // search_column - email only currently
      queryParams += "&search_column=email&search_value=" + this.state.name;
    }

    this.props.history.push({ pathname: this.props.location.pathname });

    // get Call of Email Bounce API here
    const url =
      `/suppressionList?page=${this.state.pageNo}&page_size=${this.state.pageSize}` +
      queryParams;
    EmailBounceServices.getEmailSuppressionList(`${url}`)
      .then((res) => {
        let data = res.data.data;
        const count = res.data.meta.total_count || 0;
        const actualData =
          data && data.length
            ? data.map((obj) => {
                return {
                  ...obj,
                  updated_at: obj.LastUpdateTime
                    ? ComapnyDetailsDateTimeFormatter(obj.LastUpdateTime, false)
                    : "-",
                  last_login_datetime: obj.last_login_datetime
                    ? ComapnyDetailsDateTimeFormatter(
                        obj.last_login_datetime,
                        false
                      )
                    : "-",
                  is_active:
                    obj.is_active === false ||
                    obj.is_active === true ||
                    obj.is_active === 0 ||
                    obj.is_active === 1 ? (
                      <AclAction
                        type={constants.ACL.PERMISSION_KEY}
                        entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
                        action={
                          constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.STATUS
                        }
                      >
                        <div className="custom-control custom-switch success-toggle">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customSwitch${obj.id}`}
                            disabled={true}
                            checked={
                              obj.is_active === true || obj.is_active === 1
                            }
                          />
                          <label
                            className="custom-control-label"
                            for={`customSwitch${obj.id}`}
                          ></label>
                        </div>
                      </AclAction>
                    ) : null,
                };
              })
            : data;

        this.setState({
          ...this.state,
          rowData: actualData,
          checkBoxDisable: actualData.length === 0 ? true : false,
          count,
        });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState(
      { pageSize: e.target.value, pageNo: 1, isSelectAll: false },
      () => {
        this.getTableData();
      }
    );
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  removeUserConfirmationHandler = (e) => {
    e.preventDefault();
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Activate Users</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_EMAIL.ACTIVATE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "activate-users",
        id: this.state.selectedValues.map((val) => val.id),
      },
      dailogModalStyleType: "success-modal",
    });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    switch (config.type) {
      case "activate-users":
        this.handleActivateUsers();
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  toggleDocumentModal = () => {
    let documentModal = this.state.documentModal;
    this.setState({
      documentModal: !documentModal,
      email: "",
      emailError: "",
      successMessage: "",
    });
  };

  // specific selection of checkBox handler

  onCheckboxBtnClick = (e, row) => {
    const index = this.state.selectedValues
      .map((val) => val.id)
      .indexOf(row.id);
    if (index < 0) {
      this.state.selectedValues.push({
        id: row.id,
        email: row.email,
        reason: row.source,
      });
    } else {
      this.state.selectedValues.splice(index, 1);
    }
    this.setState({
      ...this.state,
      isSelectAll:
        this.state.rowData.length === this.state.selectedValues.length
          ? true
          : false,
      selectedValues: [...this.state.selectedValues],
    });
  };

  // selecting All OR un-selecting All checkBoxes Handler
  handleSelectAll = (e) => {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        isSelectAll: !this.state.isSelectAll,
        selectedValues: this.state.rowData.map((val) => ({
          id: val.id,
          email: val.email,
          reason: val.source,
        })),
      });
    } else {
      this.setState({
        ...this.state,
        isSelectAll: !this.state.isSelectAll,
        selectedValues: [],
      });
    }
  };

  handleAddEmail = (e) => {
    this.setState({ ...this.state, email: e.target.value });
  };

  // de-activating the users handler
  handleActivateUsers = () => {
    const body = this.state.selectedValues.map((y) => y.email);
    const reason = this.state.selectedValues.map((y) => y.reason);
    // here call Api to de-activate the selected users
    EmailBounceServices.deleteEmailInSuppression({
      selected_users: body,
    })
      .then((res) => {
        this.setState({
          ...this.state,
          selectedValues: [],
        });
        Toast(
          res?.data?.message || constants.CLIENT_EMAIL.ACTIVATE.SUCCESS,
          "success"
        );
        setTimeout(this.getTableData(), 3000);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  handleAddEmailFormSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      let body = {
        email: this.state.email,
      };
      EmailBounceServices.addEmailInSuppression(body)
        .then((data) => {
          this.setState({
            emailError: "",
            successMessage: data.data.message,
            email: "",
          });
          setTimeout(() => {
            this.toggleDocumentModal();
            this.getTableData();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            emailError:
              error && error.data && error.data.message
                ? error.data.message
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  isFormValid = () => {
    if (this.state.email === "") {
      this.setState({ emailError: constants.FORM.INVALID_EMAIL });
      return false;
    } else {
      this.setState({ emailError: "" });
      return true;
    }
  };

  render() {
    return (
      <>
        <div className={"d-flex align-items-center mb-16px"}>
          <div className="">
            <div className="min-width-266 border-search search-box-shadow-2">
              <Search
                handleSearch={this.handleSearch}
                handleInput={this.handleInput}
                value={this.state.name}
                placeHolder={"Filter Email"}
              />
            </div>
          </div>
          <div className="ml-auto">
            <AclAction
              tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
              type="permissions"
              entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
              action={constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.CREATE}
            >
              <button
                className={
                  "btn btn-custom btn-primary ml-3 icon-text-btn title-btn d-inline-flex align-items-center"
                }
                type="button"
                disabled={this.state.selectedValues.length === 0}
                onClick={(e) => this.removeUserConfirmationHandler(e)}
              >
                {
                  constants.APPLICATION_ROUTE.CLIENT_SUPPRESSION_EMAIL.LIST
                    .ACTIVE_BUTTON_TITLE
                }
              </button>
            </AclAction>
            <button
              className={
                "btn btn-custom btn-primary ml-2 icon-text-btn title-btn d-inline-flex align-items-center"
              }
              type="button"
              onClick={this.toggleDocumentModal}
            >
              +{" "}
              {
                constants.APPLICATION_ROUTE.CLIENT_SUPPRESSION_EMAIL.LIST
                  .ADD_BUTTON_TITLE
              }
            </button>
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 first-col-fixed-block first-checkbox-second-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableWithSelectAllComponentNew
            checkBoxDisable={this.state.checkBoxDisable}
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            isSelectAll={this.state.isSelectAll}
            selectedValues={this.state.selectedValues}
            handleSelectAll={this.handleSelectAll}
            handleCheckBoxButtonClick={this.onCheckboxBtnClick}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
            checkboxpermission={true}
          />
        </div>

        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST
                    .REMOVE_CLIENT_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />

        <Modal
          isOpen={this.state.documentModal}
          toggle={this.toggleDocumentModal}
          className={"modal-md modal-w-header custom-modal primary-modal"}
        >
          <ModalHeader toggle={this.toggleDocumentModal}>
            Add User Email
          </ModalHeader>
          <form
            onSubmit={this.handleAddEmailFormSubmit}
            className={`col-lg-12 col-sm-12 form-wrapper p-0`}
          >
            <ModalBody>
              {this.state.emailError && this.state.emailError.length > 0 && (
                <Notification
                  color={"danger"}
                  message={this.state.emailError}
                />
              )}

              {this.state.successMessage &&
                this.state.successMessage.length > 0 && (
                  <Notification
                    color={"success"}
                    message={this.state.successMessage}
                  />
                )}
              <FormGroup>
                <div className="clearfix">
                  <Label htmlFor="email">
                    Enter Email Address <span className={"mandatory"}>* </span>
                  </Label>
                  <Input
                    className={`${this.state.emailError ? "is-invalid" : ""}`}
                    type="email"
                    placeholder=""
                    id="email"
                    name="email"
                    onChange={this.handleAddEmail}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="modal-btn btn-outline-primary"
                onClick={this.toggleDocumentModal}
              >
                Cancel
              </Button>
              <Button color="primary" className="modal-btn" type="submit">
                Confirm
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
}

export default SuppressionList;
