import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import { TemplateServices } from "../Services/Template";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import $ from "jquery";
import { FileUploadServices } from "../Services/FileUpload";
import { ClientServices } from "../Services/Client";
import CustomMultiSelectCheckbox from "../Components/CustomMultiSelectCheckbox";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";

const tableHeader = [
  {
    id: "lob_name",
    title: "Line of Business",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "lob_subcategory1_name",
    title: "LOB Segment Group",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "lob_subcategory2_name",
    title: "LOB Segment",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "lob_subcategory3_name",
    title: "LOB Subsegment",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "lob_subcategory4_name",
    title: "LOB Subsegment4",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "lob_subcategory5_name",
    title: "LOB Subsegment5",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "geo_name",
    title: "Geography",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "geosubcategory_name",
    title: "Geo Category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "country_name",
    title: "Geo Sub-category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "vertical_name",
    title: "Vertical",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "verticalcategory_name",
    title: "Vertical Category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "verticalsubcategory_name",
    title: "Vertical Sub-category",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "metrictype_name",
    title: "Metric Type",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "metric_name",
    title: "Metric",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    headerClass: "",
    rowClass: "",
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    rowStyle: { minWidth: "80px", textAlign: "center" },
    headerClass: "text-center",
    rowClass: "",
    visibility: true,
  },
];

const showActions = true;
class ListTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      isOpen: false,
      segmentList: [],
      currentPageDataCount: 0,
      disableStatus: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    ClientServices.getSegmentDetails(1)
      .then((res) => {
        let segmentsResponse = res && res.data && res.data.data;
        let segmentList = [...segmentsResponse];
        segmentList.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        segmentList = segmentList.map((s) => {
          s.is_selected = true;
          return s;
        });
        this.setState({ segmentList: segmentList });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE);
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  handleSegmentChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    segments.forEach((s) => {
      s.is_selected = selectedSegments.includes(s.id);
    });
    this.setState({ segmentList: segments });
  };
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleTemplateConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TEMPLATE.INACTIVATE_TEMPLATE_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TEMPLATE.ACTIVATE_TEMPLATE_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          data.is_active === 1 ? "de-activate_template" : "activate_template",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  removeTemplateConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Template</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.TEMPLATE.REMOVE_TEMPLATE_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_template", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeTemplate = (templateId) => {
    TemplateServices.removeTemplate(templateId)
      .then((res) => {
        Toast(constants.TEMPLATE.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleTemplate = (templateId, isActive) => {
    TemplateServices.toggleTemplate(templateId, { is_active: isActive })
      .then((res) => {
        Toast(
          isActive
            ? constants.TEMPLATE.ACTIVATE.SUCCESS
            : constants.TEMPLATE.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_template":
        this.removeTemplate(config.id);
        break;
      case "de-activate_template":
        this.toggleTemplate(config.id, 0);
        break;
      case "activate_template":
        this.toggleTemplate(config.id, 1);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.DELETE}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.REMOVE_TEMPLATE,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.REMOVE_TEMPLATE_TOOLTIP
            }
            data-iscapture="true"
          >
            {/* <i className={'fa fa-trash'} /> */}
            <span className="material-icons-outlined text-grey icon-fs-20">
              delete
            </span>
          </button>
        </AclAction>
      </>
    );
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = "/templaterecords";
    TemplateServices.getTemplates(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let templatesData = res.data.data;
        this.setState({
          currentPageDataCount: templatesData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        templatesData = templatesData.map((data) => {
          return {
            id: data.id,
            lob_name: data.lob ? data.lob.name : "N/A",
            lob_subcategory1_name: data.lob_subcategory1
              ? data.lob_subcategory1.name
              : "N/A",
            lob_subcategory2_name: data.lob_subcategory2
              ? data.lob_subcategory2.name
              : "N/A",
            lob_subcategory3_name: data.lob_subcategory3
              ? data.lob_subcategory3.name
              : "N/A",
            lob_subcategory4_name: data.lob_subcategory4
              ? data.lob_subcategory4.name
              : "N/A",
            lob_subcategory5_name: data.lob_subcategory5
              ? data.lob_subcategory5.name
              : "N/A",
            geo_name: data.geography ? data.geography.name : "N/A",
            geosubcategory_name: data.geosubcategory
              ? data.geosubcategory.name
              : "N/A",
            country_name: data.country ? data.country.name : "N/A",
            vertical_name: data.vertical ? data.vertical.name : "N/A",
            verticalcategory_name: data.verticalcategory
              ? data.verticalcategory.name
              : "N/A",
            verticalsubcategory_name: data.verticalsubcategory
              ? data.verticalsubcategory.name
              : "N/A",
            metrictype_name: data.metrictype ? data.metrictype.name : "N/A",
            metric_name: data.metric ? data.metric.name : "N/A",
            created_by:
              data.created_by_user && data.created_by_user.id
                ? `${
                    data.created_by_user.first_name
                      ? data.created_by_user.first_name
                      : ""
                  } ${
                    data.created_by_user.last_name
                      ? data.created_by_user.last_name
                      : ""
                  }`
                : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: templatesData, count: count }, () => {
          $(".comapany_table").css(
            "min-width",
            $(".comapany_table").find("thead").innerWidth()
          );
        });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() => {
        //removing second loading indicator
        document.body.classList.remove("second-loading-indicator");
      });
  };

  exportList = () => {};

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.EDIT_TEMPLATE:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.EDIT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.STATUS:
        this.toggleCompanyConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.REMOVE_TEMPLATE:
        this.removeTemplateConfirmationHandler(data);
        break;
      default:
        break;
    }
  };

  addTemplate = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.CREATE.ROUTE,
        {}
      ),
    });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE,
        {}
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  handleDownload = () => {
    let segment_ids = this.state.segmentList
      .filter((f) => f.is_selected)
      .map((s) => s.id);
    FileUploadServices.downloadExcel(segment_ids)
      .then((response) => {
        // let response = res && res.data
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "standardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          let segmentList = this.state.segmentList.map((s) => {
            s.is_selected = true;
            return s;
          });
          this.setState({ segmentList: segmentList });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            <div className="ml-auto d-flex flex-wrap align-items-center justify-content-end title-right-block mb-0">
              <div className="min-width-266 ml-auto">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Filter Name"}
                />
              </div>
              <div className="d-flex flex-wrap ml-3">
                <AclAction
                  type="permissions"
                  entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
                  action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.CREATE}
                >
                  <aside
                    tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                    className=""
                  >
                    <ButtonDropdown
                      isOpen={this.state.isOpen}
                      toggle={this.toggle}
                      className={"mr-2"}
                    >
                      <Button
                        id="caret"
                        color="info"
                        className={
                          "btn btn-custom btn-info icon-text-btn title-btn d-inline-flex align-items-center my-2 my-sm-0"
                        }
                        onClick={this.handleDownload}
                        disabled={
                          !(
                            this.state.segmentList.filter((f) => f.is_selected)
                              .length > 0
                          )
                        }
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          download
                        </span>
                        {" Download Template"}
                      </Button>
                      <DropdownToggle
                        split
                        className={
                          "btn btn-custom btn-info icon-text-btn title-btn d-inline-flex align-items-center my-2 my-sm-0"
                        }
                        color="info"
                      />
                      <DropdownMenu className="segments-list-dropdown-menu">
                        <CustomMultiSelectCheckbox
                          className={`segments-list`}
                          data={this.state.segmentList}
                          handleChange={this.handleSegmentChange}
                          placeholder={"Select Segment"}
                        />
                      </DropdownMenu>
                    </ButtonDropdown>
                    <button
                      className={
                        "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center my-2 my-sm-0"
                      }
                      type="button"
                      onClick={this.addTemplate}
                    >
                      <span className="material-icons-round icon-fs-16 mr-1">
                        add
                      </span>
                      <span className="mt-text">
                        {" " +
                          constants.APPLICATION_ROUTE.COMPANY.LIST
                            .ADD_BUTTON_TITLE}
                      </span>
                    </button>
                  </aside>
                </AclAction>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap"></div>
          <div className="">
            <div className="custom-table-block custom-table-block-2 first-col-fixed-block last-iconcol-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
              <DynamicTableComponentNew
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={this.state.count}
                rowAction={this.rowAction}
                colSpan={tableHeader.filter((h) => h.visibility).length}
                showActions={showActions}
                // className={`full-grid`}
                showPagination={true}
                paginationClass={"center-pagination custom-pagination-new"}
                handlePageSize={this.handlePageSize}
              />
            </div>
            {/* Tooltips for icons in table, don't delete */}
            {this.state.rowData.map((data) => {
              return (
                <>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST
                        .REMOVE_TEMPLATE_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                </>
              );
            })}
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTemplates);
