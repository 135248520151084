import React from "react";

import PlayButton from "./PlayButton.svg";
import CustomReactTooltip from "../../Components/CustomReactTooltip";

const ResourcesList = React.memo((props) => {
  return (
    <div className="grid-container">
      {props.resourceList.map((item, index) => (
        <div className="grid-item">
          <img
            src={PlayButton}
            alt="play-button"
            className="play-button cursor-pointer"
            onClick={() => props.handlePlay(item)}
          />
          <img
            src={props.videoThumbnailUrl(item.video_link)}
            alt="Video Thumbnail"
            className="thumbnail cursor-pointer"
            onClick={() => props.handlePlay(item)}
          />
          {item.title.length > 20 ? (
            <>
              <span
                className="item-title"
                data-for={`tooltip-${index}-title`}
                data-tip={item.title}
              >
                {item.title.slice(0, 20) + "..."}
              </span>
              <CustomReactTooltip id={`tooltip-${index}-title`} multiline />
            </>
          ) : (
            <span className="item-title">{item.title}</span>
          )}
          <div className="d-flex g-10 align-item-center justify-content-end w-100">
            <span
              className="material-icons-outlined text-blue-clr icon-fs-20 cursor-pointer"
              data-for={`tooltip-${index}-edit-resource`}
              data-tip={"Edit Resource"}
              onClick={() => props.handleEditResource(item)}
            >
              edit
            </span>
            <span
              className="material-icons-outlined text-red icon-fs-20 cursor-pointer"
              data-for={`tooltip-${index}-delete-resource`}
              data-tip={"Delete Resource"}
              onClick={() => {
                props.setIsDelete(true);
                props.setForm(item);
              }}
            >
              delete
            </span>
            <div
              className="grid-switch"
              data-for={`tooltip-${index}-active-inactive-resource`}
              data-tip={
                item.is_active ? "Active Resource" : "Deactive Resource"
              }
            >
              <input
                type="checkbox"
                id={`grid-switch-${index}`}
                checked={item.is_active ? true : false}
                onChange={(e) => {
                  props.setActiveModal(true);
                  props.setForm(item);
                }}
              />
              <label htmlFor={`grid-switch-${index}`}>
                <span
                  style={{
                    outline: item.is_active
                      ? "2px solid #2196f3"
                      : "2px solid #ADB5BD",
                  }}
                  className={`${
                    item.is_active
                      ? "grid-slider active-grid-slider"
                      : "grid-slider deactive-grid-slider"
                  }`}
                ></span>
              </label>
            </div>
            <CustomReactTooltip
              id={`tooltip-${index}-edit-resource`}
              multiline
            />
            <CustomReactTooltip
              id={`tooltip-${index}-delete-resource`}
              multiline
            />
            <CustomReactTooltip
              id={`tooltip-${index}-active-inactive-resource`}
              multiline
            />
          </div>
        </div>
      ))}
    </div>
  );
});

export default ResourcesList;
