import React from 'react'
import { DropdownList } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'

const CustomDropdownList = (props) => {
  return (
    <DropdownList
      filter={props.filter && props.filter === 'no' ? false : true}
      data={props.data}
      valueField={props.valueField ? props.valueField : 'id'}
      textField={props.textField ? props.textField : 'name'}
      onChange={value => props.handleChange(value, props)}
      defaultValue={props.data.find(d => d.is_selected === true) ? props.data.find(d => d.is_selected === true)[props.valueField ? props.valueField : 'id'] : null}
      value={props.data.find(d => d.is_selected === true) ? props.data.find(d => d.is_selected === true)[props.valueField ? props.valueField : 'id'] : null}
      placeholder={props.placeholder ? props.placeholder : ''}
      disabled={props.isDisabled ? props.isDisabled : false}
      containerClassName={props.className?props.className: ''}
    />
  )
}

export default CustomDropdownList
