import React, { Component } from "react";
import { formatRoute } from "react-router-named-routes";
import {
  ButtonToggle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import TBRLogo from "../Images/TBRLogo";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import RegisterSSO from "./RegisterSSO";

class ResetPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    apiErrors: "",
    passwordError: false,
    confirmPasswordError: false,
    isSubmitted: false,
    successMessage: "",
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let code = this.props.match.params.code;
    if (this.isFormValid()) {
      UserServices.resetPassword(code, {
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
      })
        .then((data) => {
          this.setState({
            successMessage: constants.RESET_PASSWORD.SUCCESS,
          });
          this.redirect_to_login();
        })
        .catch((error) => {
          this.setState({
            apiErrors:
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        });
    }
  };
  isFormValid = () => {
    var re = new RegExp(constants.REG_EX.PASSWORD_REG_EX);
    let passwordError = !re.test(this.state.password);
    let confirmPasswordError = !(
      this.state.password === this.state.confirmPassword
    );
    this.setState({
      passwordError: passwordError,
      confirmPasswordError: confirmPasswordError,
      isSubmitted: true,
    });
    if (passwordError === false && confirmPasswordError === false) {
      return true;
    }
    return false;
  };
  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.isSubmitted) this.isFormValid();
      }
    );
  };

  redirect_to_login = () => {
    setTimeout(
      () =>
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        ),
      3000
    );
  };

  getCodeExpiryVerification = async () => {
    let code = this.props.match.params.code;
    try {
      await UserServices.codeVerification(code);
    } catch (error) {
      this.setState({
        apiErrors:
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.CODE_EXPIRED,
      });
      setTimeout(
        () =>
          this.props.history.push(
            formatRoute(constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE, {})
          ),
        4000
      );
    }
  };

  componentDidMount() {
    DocumentTitle(constants.RESET_PASSWORD.HEADER_TITLE);
    if (this.props.match.params.code) {
      this.getCodeExpiryVerification();
    }
  }

  render() {
    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img
                      src={login_building}
                      className="img-fluid"
                      alt="login banner"
                      title="login banner"
                    />
                  </figure>
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <img
                      style={{ width: "120px", marginLeft: "20px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo />
                    <span>{constants.PAGE.LOGIN.LOGO_TEXT}</span> */}
                  </a>
                </div>
              </aside>
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img
                      src={login_map}
                      className="img-fluid"
                      alt="map Banner"
                    />
                  </figure>
                  <Form
                    onSubmit={this.handleSubmit}
                    method="post"
                    className={`form-wrapper reset-password`}
                  >
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {this.state.apiErrors.length > 0 && (
                      <Notification
                        color={"danger"}
                        message={this.state.apiErrors}
                      />
                    )}
                    {this.state.successMessage.length > 0 && (
                      <Notification
                        color={"success"}
                        message={this.state.successMessage}
                      />
                    )}
                    <FormGroup>
                      <h1 className="reset_password_header">
                        {constants.RESET_PASSWORD.HEADER_TITLE}
                      </h1>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="password">Password</Label>
                      <Input
                        className={`${
                          this.state.passwordError ? "is-invalid" : ""
                        }`}
                        type="password"
                        placeholder="Password"
                        id="password"
                        name="password"
                        onChange={this.handleInputChange}
                      />
                      {this.state.passwordError && (
                        <div className="invalid-tooltip">
                          Passwords must contain at least 8 characters including
                          at least 1 of each of the following: uppercase letter,
                          lowercase letter, special character and number
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="confirmPassword">Confirm Password</Label>
                      <Input
                        className={`${
                          this.state.confirmPasswordError ? "is-invalid" : ""
                        }`}
                        type="password"
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={this.handleInputChange}
                      />
                      {this.state.confirmPasswordError && (
                        <div className="invalid-tooltip">
                          Passwords do not match.
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <ButtonToggle color="primary" type="submit">
                        Save
                      </ButtonToggle>
                    </FormGroup>
                    <FormGroup>
                      <h3 className="reset_password_header">or</h3>
                    </FormGroup>
                    <RegisterSSO
                      entity={constants.HTML.TAGS.BUTTON}
                      redirect_to_login={this.redirect_to_login}
                      is_from_password_creation_page={true}
                    />
                  </Form>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}
export default ResetPassword;
