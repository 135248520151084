import classnames from 'classnames'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import CustomBreadcrumb from '../Components/CustomBreadcrumb'
import { activeMenuTab } from '../Store/Actions/User'
import constants from '../Utils/constants'
import DocumentTitle from '../Utils/DocumentTitle'
import ListMetrics from './ListMetrics'
import ListMetricTypes from './ListMetricTypes'
import ListMetricUnits from './ListMetricUnits'

class ListMetricTypesAndMetrics extends Component {

  state = {
    totalTabs: [],
    selectedTab: constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.TABS[0].KEY
  }

  onContentChangeTypeHandler = (s) => {
    this.setState({
      selectedTab: s
    })
  }

  breadcrumbs = () => {
    let breadcrumbs = [
      // {
      //   title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
      //   link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
      //   is_active: false
      // },
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false
      },
      {
        title: constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.NAME,
        link: null,
        is_active: true
      }
    ]
    return breadcrumbs
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    let tabs = constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.TABS
    tabs = tabs.filter((tab) => tab.HIDE_TAB === false)
    this.setState({
      totalTabs: tabs,
      selectedTab: params.selected_tab ? params.selected_tab : tabs[0].KEY,
      modifiedValueType: params.state ? params.state : tabs[0].STATE,
      modifiedValueRole: params.role ? params.role : tabs[0].ROLE
    })
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.ROUTE)
  }

  gotoMetrics = (data) => {
    this.onContentChangeTypeHandler('metrics')
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.ROUTE, {}),
      search: '?selected_tab=metrics&metrictypeid=' + data.metrictypeid
    })
  }

  gotoMetrics2 = (data) => {
    this.onContentChangeTypeHandler('metrics')
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.ROUTE, {}),
      search: '?selected_tab=metrics&metricunitid=' + data.metricunitid
    })
  }


  render() {
    let selected = this.state.totalTabs.find((f) => f.KEY === this.state.selectedTab)
    DocumentTitle(selected && selected.TAB_TITLE ? selected.TAB_TITLE : '')
    return (
      <aside className="main_content">
        <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
        </div>
        <div className="comapnay_tbl">
          <div className="">
            <div className="btn-items-card-wrapper tabs-card-wrapper">
              <div className="btn-items-card card custom-card nav-tabs-card">
                <div className="card-body">
                  <div className="d-flex">
                    <Nav tabs className="custom-nav-tabs">
                      {
                        this.state.totalTabs.map((tab) =>
                          <NavItem key={tab.KEY}>
                            <NavLink
                              className={classnames({ active: this.state.selectedTab === tab.KEY })}
                              onClick={() => { this.onContentChangeTypeHandler(tab.KEY) }}
                            >
                              {tab.TAB_TITLE}
                            </NavLink>
                          </NavItem>
                        )
                      }
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
            <TabContent activeTab={this.state.selectedTab}>
              {
                this.state.totalTabs.map((tab) =>
                  <TabPane tabId={tab.KEY} key={tab.KEY}>
                    {(this.state.selectedTab && this.state.selectedTab === tab.KEY && tab.KEY === 'metric-types') &&
                      <ListMetricTypes
                        {...this.props}
                        selectedTab={this.state.selectedTab}
                        pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                        pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                        gotoMetrics={this.gotoMetrics}
                      />
                    }
                    {(this.state.selectedTab && this.state.selectedTab === tab.KEY && tab.KEY === 'metric-units') &&
                      <ListMetricUnits
                        {...this.props}
                        selectedTab={this.state.selectedTab}
                        pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                        pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                        gotoMetrics={this.gotoMetrics2}
                      />
                    }
                    {(this.state.selectedTab && this.state.selectedTab === tab.KEY && tab.KEY === 'metrics') &&
                      <ListMetrics
                        {...this.props}
                        selectedTab={this.state.selectedTab}
                        pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                        pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                      />
                    }
                  </TabPane>
                )
              }
            </TabContent>
          </div>
        </div>
        </div>
      </aside>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab))
    }
  }
}
export default connect(null, mapDispatchToProps)(ListMetricTypesAndMetrics)