import React, { Component } from "react";
import CompanyDetailsCategories from "../Containers/CompanyDetailsCategories";
import CompanyDetailsContent from "../Containers/CompanyDetailsContent";
import { CompanyServices } from "../Services/Company";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import { UserServices } from "../Services/User";
import { connect } from "react-redux";
import {
  categories,
  companyDetails,
  companyContent,
  companies,
  templates,
} from "../Store/Actions/CompanyDetails";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import _ from "lodash";
import queryString from "query-string";
import DailogNew from "../Components/DailogNew";
import { Prompt } from "react-router-dom";
import permissionCheck from "../lib/permissionCheck";
import { activeMenuTab } from "../Store/Actions/User";
import { getTbriUsers } from "../Store/Actions/User";
import Toast from "../Utils/Toast";
import Authors from "../Components/Authors";
import Copy from "../Utils/Copy";
import { QuoteImageServices } from "../Services/QuoteImage";
import QuoteImageDialog from "../Components/QuoteImageDialog";

class CompanyDetails extends Component {
  state = {
    masterContent: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    changesMade: false,
    innerSidebarOpen: true,
    quoteImageDailogModal: false,
    transaction_id: null,
    mapping_id: null,
    mapping_type: null,
    quoteContent: null,
    quoteImageUrl: null,
    quoteImageName: null,
  };

  toggleInnerSidebar = () => {
    this.setState({ innerSidebarOpen: !this.state.innerSidebarOpen });
  };

  detectChanges = (companyContent) => {
    let c = 0,
      e = 0,
      d = 0;
    companyContent.forEach((ea) => {
      if (ea.id && ea.id !== null) {
        if (ea.is_deleted === true) {
          d = d + 1;
        } else {
          if (this.findUpdates(ea.uid)) e = e + 1;
        }
      } else if (ea.is_deleted !== true) c = c + 1;
    });
    window.removeEventListener("beforeunload", this.onUnload);
    if (c > 0 || d > 0 || e > 0) {
      window.addEventListener("beforeunload", this.onUnload);
    }
    this.setState({
      changesMade: c > 0 || d > 0 || e > 0 ? true : false,
    });
  };

  onUnload = (ev) => {
    ev.preventDefault();
    ev.returnValue = "";
  };

  componentDidMount() {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.setState({ dailogModal: true });
      }
    });
    let params = queryString.parse(this.props.location.search);
    let companyId = this.props.match.params.id ? this.props.match.params.id : 0;
    CompanyServices.getTotalCompanies("companies")
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((com, index) => {
          com.is_selected = false;
          if (params.company && parseInt(params.company) === com.id) {
            com.is_selected = true;
            companyId = com.id;
          }
        });
        if (companyId === 0 && response[0]) {
          response[0].is_selected = true;
          companyId = response[0].id;
        }
        this.props.companies(response);
        this.handleCompanyChange({ id: companyId });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );

    CompanyServices.getTemplates()
      .then((res) => {
        let response = res && res.data;
        this.props.templates(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    CompanyServices.getCategories()
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((res) => {
          res.is_selected = true;
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              res_sub.is_selected = true;
            });
          }
        });
        this.props.categories(response);
      })
      .catch((error) => {
        this.props.categories([]);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE);
  }

  switchSegment = (segmentId) => {
    if (this.state.changesMade === false) {
      this.switchSegment2(segmentId);
    } else {
      let dailogModalHeader = (
        <>
          <h3 className="text-darkblue2 font-weight-bold">Warning</h3>
        </>
      );
      let dailogModalContent = (
        <>
          <div className="inactive-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                  .DISCARD_CHANGES_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        </>
      );
      this.setState({
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: {
          type: "switch_segment",
          id: segmentId,
        },
        dailogModalStyleType: "inactive-modal",
      });
    }
  };

  switchSegment2 = (segmentId) => {
    this.setState({
      changesMade: false,
    });
    let original_companies = [...this.props.company_details.companies];
    let companyId = 0;
    original_companies.forEach((res) => {
      if (res.is_selected === true) {
        companyId = res.id;
        res.segments.forEach((seg, index) => {
          seg.is_selected = false;
          if (segmentId === seg.id) {
            seg.is_selected = true;
          }
        });
      }
    });
    this.props.companies(original_companies);
    this.getCompanySegmentContent(segmentId);
    this.getTbriUsers();
    this.changeRoute(companyId, segmentId);
  };
  getCompanySegmentContent = (segmentId) => {
    let companies =
      this.props.company_details && this.props.company_details.companies
        ? this.props.company_details.companies
        : [];
    let companyDetails =
      companies.filter((comp) => comp.is_selected === true).length > 0
        ? companies.filter((comp) => comp.is_selected === true)[0]
        : [];

    CompanyServices.getCompanyContent(companyDetails.id, segmentId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let transactionObj = null;
        if (response && response?.length > 0) {
          transactionObj = response[0].transaction;
        }

        response.forEach((res) => {
          res.uid = Math.random();
          res.is_deleted = false;
        });
        this.props.companyContent(response);
        this.unCheckOnNoContent(this.props.company_details.companyContent);
        this.setState(
          {
            masterContent: JSON.parse(JSON.stringify(response)),
            changesMade: false,
            mapping_id: transactionObj ? transactionObj.mapping_id : "",
            mapping_type: transactionObj ? transactionObj.type : "company",
          },
          () => {
            window.removeEventListener("beforeunload", this.onUnload);
          }
        );
      })
      .catch((error) => {
        this.props.companyContent([]);
      });
  };

  unCheckOnNoContent = (content) => {
    let original_categories = [...this.props.company_details.categories];
    original_categories.forEach((res) => {
      if (res.subcategories) {
        res.subcategories.forEach((res_sub) => {
          let content_found = content.filter(
            (row) =>
              row.subcategory_id === res_sub.id && row.is_deleted === false
          ).length;
          if (content_found > 0) {
            res_sub.is_selected = true;
          } else {
            res_sub.is_selected = false;
          }
        });
        let total = res.subcategories.length;
        let total_selected = res.subcategories.filter(
          (row) => row.is_selected === true
        ).length;
        if (total_selected === total && total > 0) {
          res.is_selected = true;
        } else {
          res.is_selected = false;
        }
      }
    });

    this.props.categories(original_categories);
  };

  toggleCheckboxParent = (e, type, category_id, sub_category_id) => {
    let checkbox_selection = e.target.checked;
    if (checkbox_selection === true) {
      this.toggleCheckbox(
        checkbox_selection,
        type,
        category_id,
        sub_category_id
      );
    } else {
      this.confirmDailog({
        checkbox_selection,
        type,
        category_id,
        sub_category_id,
      });
    }
  };

  toggleCheckbox = (checkbox_selection, type, category_id, sub_category_id) => {
    let original_categories = [...this.props.company_details.categories];
    if (type === "category") {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          res.is_selected = checkbox_selection;
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              res_sub.is_selected = checkbox_selection;
            });
          }
          this.handleAddRemoveTemplateCategory(category_id, checkbox_selection);
        }
      });
    } else if (type === "sub_category") {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.id === sub_category_id) {
                res_sub.is_selected = checkbox_selection;
                this.handleAddRemoveTemplateSubCategory(
                  res_sub.id,
                  checkbox_selection,
                  res_sub.mode
                );
              }
            });
            let total = res.subcategories.length;
            let total_selected = res.subcategories.filter(
              (row) => row.is_selected === true
            ).length;
            if (total_selected === total && total > 0) {
              res.is_selected = true;
            } else {
              res.is_selected = false;
            }
          }
        }
      });
    }
    this.props.categories(original_categories);
  };

  getEmptyContent = (sub_category_id) => {
    let template_found = this.props.company_details.templates.filter(
      (row) => row.subcategory_id === sub_category_id
    );
    let emptyContent = {};
    if (template_found && template_found.length > 0) {
      emptyContent.id = null;
      emptyContent.uid = Math.random();
      emptyContent.data = "{}";
      emptyContent.subcategory_title = "";
      emptyContent.published_date = new Date();
      emptyContent.template_id = template_found[0].id;
      emptyContent.subcategory_id = sub_category_id;
      emptyContent.template = template_found[0];
      emptyContent.is_deleted = false;
      let data = {};
      let extractParams = emptyContent.template.content.match(/[^{}]+(?=})/g);
      if (extractParams && extractParams.length > 0) {
        extractParams.forEach((param) => {
          data[param] = {
            type: "empty",
            value: "",
          };
        });
      }
      emptyContent.data = JSON.stringify(data);
    }
    return emptyContent;
  };

  addEmptyContent = (sub_category_id) => {
    let original_content = [...this.props.company_details.companyContent];
    let getEmptyContent = this.getEmptyContent(sub_category_id);
    original_content.push(getEmptyContent);
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  handleAddRemoveTemplateCategory = (category_id, is_selected) => {
    let original_categories = [...this.props.company_details.categories];
    let checkbox_selection = is_selected;
    let original_content = [...this.props.company_details.companyContent];
    original_categories.forEach((res) => {
      if (res.id === category_id) {
        res.is_selected = checkbox_selection;
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (checkbox_selection === true) {
              let content_found;
              content_found = this.props.company_details.companyContent.filter(
                (row) =>
                  row.subcategory_id === res_sub.id && row.is_deleted === false
              ).length;
              if (res_sub.mode && res_sub.mode === "news") {
                content_found =
                  this.props.company_details.companyContent.filter(
                    (row) => row.subcategory_id === res_sub.id
                  ).length;
              }
              if (content_found > 0) {
                if (res_sub.mode && res_sub.mode === "news") {
                  original_content.forEach((item) => {
                    if (item.subcategory_id === res_sub.id) {
                      item.is_deleted = false;
                    }
                  });
                }
              } else {
                original_content.push(this.getEmptyContent(res_sub.id));
              }
            } else {
              original_content.forEach((item) => {
                if (item.subcategory_id === res_sub.id) item.is_deleted = true;
              });
            }
          });
        }
      }
    });
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  handleAddRemoveTemplateSubCategory = (sub_category_id, is_selected, mode) => {
    let content_found;
    if (mode && mode === "news") {
      content_found = this.props.company_details?.companyContent?.filter(
        (row) => row.subcategory_id === sub_category_id
      ).length;
    } else {
      content_found = this.props.company_details?.companyContent?.filter(
        (row) =>
          row.subcategory_id === sub_category_id && row.is_deleted === false
      ).length;
    }
    if (is_selected === true) {
      if (content_found > 0) {
        if (mode && mode === "news") {
          this.undoRemoveContent(
            this.props.company_details.companyContent.filter(
              (row) => row.subcategory_id === sub_category_id
            )[0].uid
          );
        }
      } else {
        this.addEmptyContent(sub_category_id);
      }
    } else {
      this.removeSubCategoryContent(sub_category_id);
    }
  };

  removeSubCategoryContent = (sub_category_id) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((item) => {
      if (item.subcategory_id === sub_category_id) item.is_deleted = true;
    });
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  removeContent = (uid) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((item) => {
      if (item.uid === uid) item.is_deleted = true;
    });
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  undoRemoveContent = (uid) => {
    let original_content = [...this.props.company_details.companyContent];
    original_content.forEach((item) => {
      if (item.uid === uid) item.is_deleted = false;
    });
    this.props.companyContent(original_content);
    this.unCheckOnNoContent(original_content);
    this.detectChanges(original_content);
  };

  dateOnChangeHandler = (key, val) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === key);
    original_content[index].published_date = val;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  onContentChangeHandler = (uid, placeholder, value, valueType) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    let jsonData = JSON.parse(original_content[index].data);
    jsonData[placeholder].type = valueType;
    if (valueType === "image" && value.startsWith("data:")) {
      let splitValue = value.split("|");
      jsonData[placeholder].value = splitValue[0] ? splitValue[0] : "";
      jsonData[placeholder].imageName = splitValue[1] ? splitValue[1] : "";
    } else {
      jsonData[placeholder].value = value;
    }
    let stringData = JSON.stringify(jsonData);
    original_content[index].data = stringData;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  onSectionTitleChangeHandler = (uid, value) => {
    let original_content = [...this.props.company_details.companyContent];
    let index = original_content.findIndex((row) => row.uid === uid);
    original_content[index].subcategory_title = value;
    this.props.companyContent(original_content);
    this.detectChanges(original_content);
  };

  saveContent = (type) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {type === constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
            ? constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.BUTTON_TITLE
            : constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.BUTTON_TITLE}
        </h3>
      </>
    );
    let dailogModalContent;
    let dailogModalStyleType;
    switch (type) {
      case constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG:
        this.setState({
          quoteImageDailogModal: true,
          dailogModalConfig: {
            type: type,
            id: null,
          },
          apiError: null,
        });
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.REJECT.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.REJECT.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="remove-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.REJECT.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "danger-modal";
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.SLUG:
        dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.BUTTON_TITLE}
            </h3>
          </>
        );
        dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.MANAGE_CONTENT_PERMISSIONS.APPROVE.CONFIRM_MESSAGE}
              </h2>
            </div>
          </>
        );
        dailogModalStyleType = "success-modal";
        break;
      default:
        break;
    }
    if (type !== constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG) {
      this.setState({
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: {
          type: type,
          id: null,
        },
        dailogModalStyleType: dailogModalStyleType,
      });
    }
  };

  resetContent = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Warning</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="inactive-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .RESET_CHANGES_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "reset_content",
        id: null,
      },
      dailogModalStyleType: "inactive-modal",
    });
  };

  saveContentSuccess = (action) => {
    let message = "";
    switch (action) {
      case constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SUCCESS_MESSAGE;
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG:
        message = constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SUCCESS_MESSAGE;
        break;
      default:
        break;
    }
    Toast(message, "success");
    this.setState(
      {
        changesMade: false,
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      },
      () => {
        window.removeEventListener("beforeunload", this.onUnload);
        let redirectionTo;
        if (action === constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG) {
          redirectionTo =
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.ROUTE;
        } else {
          redirectionTo = formatRoute(
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE
          );
        }
        this.props.history.push({
          pathname: redirectionTo,
        });
      }
    );
  };

  saveContent2 = (action) => {
    let selectedCompany =
      this.props.company_details.companies.filter(
        (comp) => comp.is_selected === true
      ).length > 0
        ? this.props.company_details.companies.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    let selectedSegment =
      selectedCompany.segments.filter((comp) => comp.is_selected === true)
        .length > 0
        ? selectedCompany.segments.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    let c = [];
    this.props.company_details.companyContent.forEach((ea) => {
      let save = {
        template_id: ea.template_id,
        category_id: ea.category_id ? ea.category_id : null,
        subcategory_id: ea.subcategory_id,
        data: [
          {
            content_id: ea.id,
            subcategory_title: ea.subcategory_title,
            published_date: ea.published_date,
            content: JSON.parse(ea.data),
            is_deleted: ea.is_deleted ? 1 : 0,
          },
        ],
      };
      if (ea.id && ea.id !== null) {
        if (ea.is_deleted === true) {
          c.push(save);
        } else {
          if (this.findUpdates(ea.uid)) c.push(save);
        }
      } else if (ea.is_deleted !== true) c.push(save);
    });
    CompanyServices.saveContent(
      selectedCompany.id,
      selectedSegment.id,
      { operations: { create: c } },
      action
    )
      .then(async (res) => {
        const { transaction_id } = res.data;
        if (
          this.state.quoteContent ||
          (this.state.quoteImageUrl && this.state.quoteImageName)
        ) {
          const body = {
            mapping_id: selectedCompany.id ?? this.state.mapping_id,
            transaction_id: transaction_id ?? "",
            mapping_type: this.state.mapping_type ?? "company",
            content: this.state.quoteContent ?? "",
            base64_data: this.state.quoteImageUrl ?? "",
            imageName: this.state.quoteImageName ?? "",
          };
          // API Call For adding Image and Quote and Save it to database
          await QuoteImageServices.addImageandQuote(body)
            .then((res) => {
              this.setState({
                base64_data: null,
              });
            })
            .catch((error) => {
              console.log("Error from Image and upload----", error);
            });
        }
        this.setState(
          {
            dailogModal: false,
            dailogModalHeader: "",
            dailogModalContent: "",
            dailogModalConfig: {},
            dailogModalStyleType: "",
            quoteImageDailogModal: false,
          },
          () => {
            this.saveContentSuccess(action);
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  resetContent2 = () => {
    let selectedCompany =
      this.props.company_details.companies.filter(
        (comp) => comp.is_selected === true
      ).length > 0
        ? this.props.company_details.companies.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    let selectedSegment =
      selectedCompany.segments.filter((comp) => comp.is_selected === true)
        .length > 0
        ? selectedCompany.segments.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    this.getCompanySegmentContent(selectedSegment.id);
  };

  findUpdates = (uid) => {
    let newContent = this.props.company_details.companyContent.filter(
      (comp) => comp.uid === uid
    )[0];
    let oldContent = this.state.masterContent.filter(
      (comp) => comp.uid === uid
    )[0];
    if (
      _.isEqual(newContent.subcategory_title, oldContent.subcategory_title) ===
        true &&
      _.isEqual(newContent.data, oldContent.data) === true &&
      _.isEqual(newContent.published_date, oldContent.published_date) === true
    ) {
      return false;
    }
    return true;
  };

  changeRoute = (companyId, segmentId) => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.COMPANY.MANAGE_CONTENT.ROUTE,
        { id: companyId }
      ),
      search: "segment=" + segmentId,
    });
  };

  handleCompanyChange = (value) => {
    if (this.state.changesMade === false) {
      this.handleCompanyChange2(value.id);
    } else {
      let dailogModalHeader = (
        <>
          <h3 className="text-darkblue2 font-weight-bold">Warning</h3>
        </>
      );
      let dailogModalContent = (
        <>
          <div className="inactive-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                  .DISCARD_CHANGES_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        </>
      );
      this.setState({
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: {
          type: "switch_company",
          id: value.id,
        },
        dailogModalStyleType: "inactive-modal",
      });
    }
  };

  copyHandler = (author) => {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? JSON.parse(JSON.stringify(this.props.company_details.companyDetails))
        : {};
    let segments =
      companyDetails.company &&
      companyDetails.company.id &&
      companyDetails.company.segments
        ? companyDetails.company.segments
        : [];
    let companies =
      this.props.company_details && this.props.company_details.companies
        ? this.props.company_details.companies
        : [];
    let selectedSegments =
      companies.filter((comp) => comp.is_selected === true).length > 0
        ? companies.filter((comp) => comp.is_selected === true)[0].segments
        : [];
    let selectedSegment = selectedSegments.find((s) => s.is_selected === true);
    let selectedSegmentsIndex = segments.findIndex(
      (segment) => segment.id === selectedSegment.id
    );
    if (selectedSegmentsIndex >= 0) {
      let selectedSegmentAuthors = segments[selectedSegmentsIndex]
        ? segments[selectedSegmentsIndex]?.authors
        : [];
      let authorIndex = selectedSegmentAuthors.findIndex(
        (a) => a.id === author.id
      );
      companyDetails.company.segments[selectedSegmentsIndex].authors[
        authorIndex
      ].copied = true;
      this.props.companyDetails(companyDetails);
      Copy(author.email);
      setTimeout(() => {
        let cd = {
          company: JSON.parse(JSON.stringify(companyDetails.company)),
        };
        cd.company.segments[selectedSegmentsIndex].authors[
          authorIndex
        ].copied = false;
        this.props.companyDetails(cd);
      }, 2000);
    }
  };

  handleCompanyChange2 = async (id) => {
    let params = queryString.parse(this.props.location.search);
    let original_companies = [...this.props.company_details.companies];
    let segments = [];
    let segmentId = 0;
    original_companies.forEach((res) => {
      if (parseInt(res.id) === parseInt(id)) {
        res.is_selected = true;
        segments = res.segments;
      } else {
        res.is_selected = false;
      }
    });
    this.props.companies(original_companies);
    if (segments.length > 0) {
      segments = segments.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      if (params.segment && parseInt(params.segment) > 0) {
        if (
          segments.findIndex((seg) => seg.id === parseInt(params.segment)) >= 0
        ) {
          segmentId = parseInt(params.segment);
        }
      } else {
        segmentId = segments[0].id;
      }
    }
    await this.getCompanyDetails(id);
    this.getTbriUsers();
    this.switchSegment2(segmentId);
    this.changeRoute(id, segmentId);
  };

  getCompanyDetails = async (companyId) => {
    await CompanyServices.getCompanyDetails(companyId)
      .then((res) => {
        let response = res && res.data;
        this.props.companyDetails(response);
      })
      .catch((error) => {
        this.props.companyDetails({});
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getTbriUsers = () => {
    UserServices.getTbriUsers()
      .then((res) => {
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.first_name.toLowerCase() > b.first_name.toLowerCase()
            ? 1
            : b.first_name.toLowerCase() > a.first_name.toLowerCase()
            ? -1
            : 0
        );
        response.forEach((r) => {
          r.is_selected = false;
          r.name = `${r.first_name} ${r.last_name}`;
        });
        this.props.setTbriUsers(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleAuthorChange = (e) => {
    let getTbriUsers = [...this.props.home.getTbriUsers];
    getTbriUsers.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setTbriUsers(getTbriUsers);
  };

  addAuthor = () => {
    let getTbriUsers = [...this.props.home.getTbriUsers];
    let user = {
      id: getTbriUsers.find((u) => u.is_selected === true)
        ? getTbriUsers.find((u) => u.is_selected === true).id
        : null,
    };
    let selectedCompany =
      this.props.company_details.companies.filter(
        (comp) => comp.is_selected === true
      ).length > 0
        ? this.props.company_details.companies.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    let selectedSegment =
      selectedCompany.segments.filter((comp) => comp.is_selected === true)
        .length > 0
        ? selectedCompany.segments.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    CompanyServices.addAuthor(selectedCompany.id, selectedSegment.id, user.id)
      .then((response) => {
        this.getCompanyDetails(selectedCompany.id);
        this.getTbriUsers();
        Toast(
          constants.MANAGE_CONTENT_TRANSACTION_DETAILS
            .ADD_AUTHOR_SUCCESS_MESSAGE,
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  removeAuthor = (id) => {
    let selectedCompany =
      this.props.company_details.companies.filter(
        (comp) => comp.is_selected === true
      ).length > 0
        ? this.props.company_details.companies.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    let selectedSegment =
      selectedCompany.segments.filter((comp) => comp.is_selected === true)
        .length > 0
        ? selectedCompany.segments.filter(
            (comp) => comp.is_selected === true
          )[0]
        : {};
    CompanyServices.removeAuthor(selectedCompany.id, selectedSegment.id, id)
      .then((response) => {
        this.getCompanyDetails(selectedCompany.id);
        this.getTbriUsers();
        Toast(
          constants.MANAGE_CONTENT_TRANSACTION_DETAILS
            .DELETE_AUTHOR_SUCCESS_MESSAGE,
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  confirmDailog = (config) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              constants.MANAGE_CONTENT_TRANSACTION_DETAILS
                .DELETE_CONTENT_CONFIRM_MESSAGE
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: config,
      dailogModalStyleType: "danger-modal",
    });
  };

  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  quoteImageDailogModalAccept = (config) => {
    this.setState(
      {
        quoteImageDailogModal: false,
      },
      () => {
        this.matchEvent(config);
      }
    );
  };

  matchEvent = (config) => {
    switch (config.type) {
      case "category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "sub_category":
        this.toggleCheckbox(
          config.checkbox_selection,
          config.type,
          config.category_id,
          config.sub_category_id
        );
        break;
      case "switch_segment":
        this.setState({ changesMade: false }, () => {
          this.switchSegment2(config.id);
        });
        break;
      case "switch_company":
        this.setState({ changesMade: false }, () => {
          this.handleCompanyChange2(config.id);
        });
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG:
        this.saveContent2(constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG);
        break;
      case constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG:
        this.saveContent2(constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG);
        break;
      case "reset_content":
        this.resetContent2();
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  quoteImageDailogModalDecline = () => {
    this.setState({
      quoteContent: null,
      quoteImageUrl: null,
      quoteImageName: null,
      base64_data: null,
      quoteImageDailogModal: false,
      config: {},
    });
  };

  handleQuoteContent = (e) => {
    this.setState({
      quoteContent: e.target.value,
    });
  };
  handleQuoteImage = (base64Url, imageName) => {
    let filteredBase64Url = base64Url.split(";base64,")[1];
    this.setState({
      quoteImageUrl: filteredBase64Url,
      quoteImageName: imageName,
    });
  };

  componentWillUnmount = () => {
    this.props.companies([]);
    this.props.companyDetails({});
    this.props.companyContent({});
    this.props.categories([]);
    this.props.templates([]);
  };

  render() {
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails
        : [];
    let selectedSegments =
      companyDetails.company &&
      companyDetails.company.id &&
      companyDetails.company.segments
        ? companyDetails.company.segments
        : [];
    let selectedSegmentAuthors = selectedSegments[0] ? selectedSegments[0] : [];
    let companies =
      this.props.company_details && this.props.company_details.companies
        ? this.props.company_details.companies
        : [];
    let segments =
      companies.filter((comp) => comp.is_selected === true).length > 0
        ? companies.filter((comp) => comp.is_selected === true)[0].segments
        : [];
    let segmentAuthors = [];
    let selectedSegment = segments.find((s) => s.is_selected === true);
    if (selectedSegment) {
      let a = selectedSegments
        ? selectedSegments.find((s) => s.id === selectedSegment.id)
        : [];
      segmentAuthors = a && a.authors ? a.authors : [];
    }
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: `${
          companyDetails.company != undefined &&
          companyDetails.company.name != undefined
            ? companyDetails.company.name
            : ""
        }`,
        link: null,
        is_active: true,
      },
    ];
    const isEditEnabled =
      permissionCheck(
        constants.MANAGE_CONTENT_PERMISSIONS.KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
      ) ||
      permissionCheck(
        constants.MANAGE_CONTENT_PERMISSIONS.KEY,
        constants.MANAGE_CONTENT_PERMISSIONS.ENTITY,
        constants.MANAGE_CONTENT_PERMISSIONS.SUBMIT.SLUG
      );
    return (
      <div
        className={`${
          this.state.innerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center companies-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={breadcrumbs} />
            </div>
            <div className="ml-auto d-flex align-items-center">
              <Authors
                entity={constants.ACL.COMPANY_SEGMENT_AUTHOR_RESOURCE.TITLE}
                create_action={
                  constants.ACL.COMPANY_SEGMENT_AUTHOR_RESOURCE.ACTIONS.CREATE
                }
                delete_action={
                  constants.ACL.COMPANY_SEGMENT_AUTHOR_RESOURCE.ACTIONS.DELETE
                }
                authors_type={constants.AUTHORS_TYPES.COMPANY_DETAILS}
                addAuthor={this.addAuthor}
                removeAuthor={this.removeAuthor}
                authors={segmentAuthors}
                handleAuthorChange={this.handleAuthorChange}
                copyhandler={this.copyHandler}
              />
            </div>
          </div>
          <div className="company_details">
            <Prompt
              when={this.state.changesMade}
              message={(location) => `Changes you made may not be saved.`}
            />
            <CompanyDetailsCategories
              addEmptyContent={this.addEmptyContent}
              handleAddRemoveTemplateSubCategory={
                this.handleAddRemoveTemplateSubCategory
              }
              handleAddRemoveTemplateCategory={
                this.handleAddRemoveTemplateCategory
              }
              toggleCheckbox={this.toggleCheckboxParent}
              isEditable={isEditEnabled}
              toggleInnerSidebar={this.toggleInnerSidebar}
            />
            <CompanyDetailsContent
              switchSegmentHandler={this.switchSegment}
              addEmptyContent={this.addEmptyContent}
              removeContent={this.removeContent}
              dateOnChangeHandler={this.dateOnChangeHandler}
              onContentChangeHandler={this.onContentChangeHandler}
              onSectionTitleChangeHandler={this.onSectionTitleChangeHandler}
              saveContent={this.saveContent}
              handleCompanyChange={this.handleCompanyChange}
              changesMade={this.state.changesMade}
              undoRemoveContent={this.undoRemoveContent}
              resetContent={this.resetContent}
              isEditable={isEditEnabled}
              handleAuthorChange={this.handleAuthorChange}
              addAuthor={this.addAuthor}
              removeAuthor={this.removeAuthor}
            />
            <QuoteImageDialog
              isOpen={this.state.quoteImageDailogModal}
              header={constants.QUOTE_IMAGE.MODAL_HEADER.ADDING_QUOTE}
              config={this.state.dailogModalConfig}
              accept={this.quoteImageDailogModalAccept}
              decline={this.quoteImageDailogModalDecline}
              state={this.state}
              handleTitleChange={this.handleQuoteContent}
              onChangeHandlerImage={this.handleQuoteImage}
              saveButtonName={constants.QUOTE_IMAGE.UPLOAD_BUTTON.CREATE}
              handleQuoteImageRemove={(e) => {
                this.setState({
                  image_delete: e,
                });
              }}
            />
            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
    home: state.home,
    client: state.client,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companyDetails: (payload) => {
      dispatch(companyDetails(payload));
    },
    companyContent: (payload) => {
      dispatch(companyContent(payload));
    },
    companies: (payload) => {
      dispatch(companies(payload));
    },
    templates: (payload) => {
      dispatch(templates(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setTbriUsers: (tbriUsers) => {
      dispatch(getTbriUsers(tbriUsers));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
