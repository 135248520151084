import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReactTooltip from "react-tooltip";
import CustomTextarea from "./CustomTextarea";
import CustomImageUploader from "./CustomImageUploader";

const QuoteImageDialog = (props) => {
  const { isOpen } = props;
  useEffect(() => {
    if (!isOpen) {
      document.querySelector("body").classList.remove("modal-open");
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={props.decline}
      className={`modal-w-header modal-md custom-modal primary-modal`}
    >
      <ModalHeader toggle={props.decline}>{props.header}</ModalHeader>
      <ModalBody>
        <div className={"row"}>
          <div className="col-sm-12 form-group position-relative">
            <label htmlFor="upload-quote">Your Quote</label>
            <CustomTextarea
              modifiedTitleValue={props.state.quoteContent}
              onTitleChangeHandler={props.handleTitleChange}
            />
          </div>
          <div className="col-sm-12 form-group position-relative">
            <label htmlFor="upload-image">Upload Image</label>
            <span
              data-tip=""
              data-for="upload-image"
              style={{ color: "#1E81E8" }}
              className="material-icons-round icon-fs-16 ml-2 cursor-pointer valign-middle"
            >
              info
            </span>
            <ReactTooltip id="upload-image" place="right" effect="solid">
              Only PNG, JPG files are allowed
            </ReactTooltip>
            <div className="border col-sm-12" style={{ height: "109px" }}>
              <div style={{ marginTop: "20px" }}>
                <CustomImageUploader
                  value={props.state.quoteImageUrl}
                  onChangeHandlerImage={props.onChangeHandlerImage}
                  quoteImageName={props.state.quoteImageName}
                  handleQuoteImageRemove={props.handleQuoteImageRemove}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <Button
          className={"btn-outline-primary modal-btn"}
          onClick={props.decline}
        >
          Cancel
        </Button>{" "}
        <Button
          className={"modal-right-btn modal-btn"}
          onClick={() => props.accept(props.config)}
        >
          {props.saveButtonName}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default QuoteImageDialog;
