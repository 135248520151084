import React, { useEffect, useState, useRef } from 'react';
import { ReportsServices } from '../../Services/Reports';
import constants from '../../Utils/constants';
import ReportsChart from '../ReportsChart';
import Toast from '../../Utils/Toast';

const seriesdata = [
    {
        name: 'Viewed',
        field_name: 'visited'

    },
    {
        name: 'Created',
        field_name: 'created'
    }
];
export default function BoardsChart(props) {
    const [chartData, setChartData] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    
    useEffect(() => {
        const {
            userId,
            startDate,
            endDate,
            isRoleMember,
            isPublished
        } = props
        isRoleMember?setSeriesData([{name: 'Viewed',field_name: 'visited'}]):setSeriesData([{name: 'Viewed',field_name: 'visited'},{name: 'Created',field_name: 'created'}])
        if (userId && startDate && endDate) {
            let body={
                user_id:userId,
                start_date:startDate,
                end_date:endDate,
                page_type: constants.GA.PAGE_TYPE.BOARD,
                isRoleMember: isRoleMember,
                is_published: isPublished
            }
            ReportsServices.getModuleViewandCreateCount(body)
                .then((res) => {
                    let data = res.data && res.data.data
                    data.sort(function(a, b) {
                        var keyA = new Date(a.date),
                          keyB = new Date(b.date);
                        // Compare the 2 dates
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                      });
                    setChartData(data);
                })
                .catch((error) => {
                    Toast(error && error.data && error.data.error
                        ? error.data.error
                        : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
                })
        }
        else
        {
            setChartData([])
        }
    }, [props.startDate, props.endDate, props.userId])

    return (
        chartData && chartData.length ?
            <ReportsChart
                id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.BOARDS_CHART.ID}
                legend_id={constants.APPLICATION_ROUTE.REPORTS.CHARTS.BOARDS_CHART.LEGEND_ID}
                chartData={chartData}
                series={seriesdata}
                axis_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.AXIS_TYPES.DATE}
                isStacked={false}
                isFill={false}
                showBullets={true}
                series_type={constants.APPLICATION_ROUTE.REPORTS.CHARTS.SERIES_TYPES.LINE}
            />
            :
            <h4 className='text-center'>No Data Available</h4>
    )
}