import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import constants from "../Utils/constants";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import Search from "../Components/Search";
import { connect } from "react-redux";
import decodeJWT from "../lib/decodeJWT";
const tableHeader = [
  {
    id: "file_name",
    title: "File",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "status",
    title: "Current Status",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;
class ListMetricManageContentTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: null,
      sortOrder: null,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column ? params.sort_column : null,
        sortOrder: params.sort_order ? params.sort_order : null,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  extractCategories = (categories = {}) => {
    let subcategories = [];
    Object.keys(categories).forEach((i) => {
      subcategories.push(...categories[i]);
    });
    return subcategories.join(", ");
  };
  getNextAction = (user, latest_role, latest_state, data) => {
    let loggedInUserId =
      this.props.home &&
      this.props.home.loginUserSuccess &&
      this.props.home.loginUserSuccess.id
        ? this.props.home.loginUserSuccess.id
        : null;
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let loggedInRole =
      tokenDetails && tokenDetails.role && tokenDetails.role.name
        ? tokenDetails.role.name
        : null;
    let nextAction = (
      <button
        onClick={(e) => {
          this.rowAction("Manage", data, e);
        }}
        className={
          "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
        }
      >
        {(latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG ||
          latest_state.toLowerCase() ===
            constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG) && (
          <>
            <span className="material-icons-outlined icon-fs-14 mr-1">
              visibility
            </span>{" "}
            View
          </>
        )}
        {latest_state.toLowerCase() !==
          constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG &&
          latest_state.toLowerCase() !==
            constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG && (
            <>
              <span className="material-icons-outlined icon-fs-14 mr-1">
                settings
              </span>{" "}
              Manage
            </>
          )}
      </button>
    );
    if (user !== null && loggedInUserId !== null && loggedInRole !== null) {
      if (user === loggedInUserId) {
        if (
          latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.REJECTED.SLUG
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
              }
            >
              <span className="material-icons-outlined icon-fs-14 mr-1">
                edit
              </span>{" "}
              {constants.MANAGE_CONTENT_PERMISSIONS.RE_SUBMIT.BUTTON_TITLE}
            </button>
          );
        }
        if (
          latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.DRAFT.SLUG
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
              }
            >
              <span className="material-icons-outlined icon-fs-14 mr-1">
                edit
              </span>{" "}
              Edit
            </button>
          );
        }
      } else {
        if (
          latest_state.toLowerCase() ===
          constants.MANAGE_CONTENT_PERMISSIONS.REJECTED.SLUG
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
              }
            >
              <span className="material-icons-outlined icon-fs-14 mr-1">
                visibility
              </span>{" "}
              View
            </button>
          );
        }
      }
      if (
        latest_state.toLowerCase() ===
        constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.SLUG
      ) {
        if (
          user !== loggedInUserId &&
          ((latest_role !== null && loggedInRole === latest_role) ||
            (latest_role !== null &&
              constants.USER_ROLES.ADMIN.SLUG === loggedInRole.toLowerCase()) ||
            (latest_role === null &&
              (loggedInRole === constants.USER_ROLES.SENIOR_ANALYST.NAME ||
                loggedInRole === constants.USER_ROLES.PRACTICE_MANAGER.NAME ||
                loggedInRole === constants.USER_ROLES.ADMIN.NAME)))
        ) {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
              }
            >
              <span className="material-icons-outlined icon-fs-14 mr-1">
                description
              </span>{" "}
              {constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE}
            </button>
          );
        } else {
          nextAction = (
            <button
              onClick={(e) => {
                this.rowAction("Manage", data, e);
              }}
              className={
                "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
              }
            >
              {loggedInRole === constants.USER_ROLES.ADMIN.NAME ? (
                <>
                  <span className="material-icons-outlined icon-fs-14 mr-1">
                    description
                  </span>{" "}
                  {constants.MANAGE_CONTENT_PERMISSIONS.REVIEW.BUTTON_TITLE}
                </>
              ) : (
                <>
                  <span className="material-icons-outlined icon-fs-14 mr-1">
                    visibility
                  </span>
                  {" View"}
                </>
              )}
            </button>
          );
        }
      }
    } else {
      nextAction = (
        <button
          onClick={(e) => {
            this.rowAction("Manage", data, e);
          }}
          className={
            "dynamicTableActionButton btn btn-primary btn-md d-inline-flex align-items-center"
          }
        >
          {(latest_state.toLowerCase() ===
            constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG ||
            latest_state.toLowerCase() ===
              constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG) && (
            <>
              <span className="material-icons-outlined icon-fs-14 mr-1">
                visibility
              </span>{" "}
              View
            </>
          )}
          {latest_state.toLowerCase() !==
            constants.MANAGE_CONTENT_PERMISSIONS.APPROVED.SLUG &&
            latest_state.toLowerCase() !==
              constants.MANAGE_CONTENT_PERMISSIONS.PUBLISHED.SLUG && (
              <>
                <span className="material-icons-outlined icon-fs-14 mr-1">
                  settings
                </span>{" "}
                Manage
              </>
            )}
        </button>
      );
    }
    return nextAction;
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=market_forecast_name&search_value=" + this.state.name;
    }
    if (this.props.type) {
      queryParams += "&state=" + this.props.type;
    }
    if (this.props.role && this.props.role !== null) {
      queryParams += "&role=" + this.props.role;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
    this.setState({ rowData: [], count: 0 });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.MANAGE_CONTENT_MY_DRAFTS.LIST.VIEW_DETAILS:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_FORECAST_TRANSACTION_DETAILS.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case "Manage":
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_FORECAST_TRANSACTION_DETAILS.ROUTE,
            { id: data.transaction_id }
          ),
        });
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div className={""}>
        <div className="d-flex align-items-center mb-16px">
          <div className="min-width-266 border-search search-box-shadow-2">
            <Search
              handleSearch={this.handleSearch}
              handleInput={this.handleInput}
              value={this.state.name}
              placeHolder={"Filter Name"}
            />
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 content-trasactions-table-block first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

export default connect(
  mapStateToProps,
  null
)(ListMetricManageContentTransactions);
