
import React, { Component } from 'react'
import constants from '../Utils/constants'
import InfiniteScroll from "react-infinite-scroll-component"
class DynamicTableComponentWithScroll extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    entireRowAction = (row, e) => {
        if (this.props.entireRowAction && this.props.entireRowAction.title) {
            this.props.rowAction(this.props.entireRowAction.title, row, e)
        }
    }


    render() {
        return (
            <div className="responsive-table mt-2 logintablescroll" id="scroll"  >
                <InfiniteScroll
                    dataLength={this.props.rowData.length}
                    next={this.props.fetchMore}
                    hasMore={this.props.hasMore}
                    loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                    inverse={false}
                    endMessage={this.props.rowData.length > 0 ? <h5 style={{ "textAlign": "center" }}>End of Login Details</h5> : ''}
                    scrollableTarget={"scroll"}
                >
                    <table className="h-100 w-100 normal-table normal-table-2 login-details-table">
                        <thead>
                            <tr style={{ maxHeight: '50px' }}>
                                {this.props.columnData.map((column, key) =>
                                    (column.visibility) ?
                                        <th key={key} className={`${((this.props.sortColumn === column.id) ? this.props.sortOrder : (column.sortable ? 'default' : null))} ${column.headerClass ? column.headerClass : null}`} onClick={() => { column.sortable && this.props.handleSort(column.id, key) }} style={column.headerStyle}>
                                            <div className="th-content">{column.title}</div>
                                        </th> : null)}
                            </tr>
                        </thead>
                        <tbody >

                            {this.props.rowData.length > 0 && this.props.rowData.map((row, index) =>
                                <tr key={index} onClick={(e) => { this.entireRowAction(row, e) }}>
                                    {this.props.columnData.map((val, i) =>
                                    (val.visibility ?
                                        <td key={`${index + '-' + i}`} style={val.rowStyle} className={val.id == 'message' ? row[val.id] === 'Successful Login' || row[val.id] === 'Success' ? 'text-green' : row[val.id] === 'Registration Completed' || row[val.id] === 'Welcome Mail Sent' ? null : 'text-red' : null}>
                                            {row[val.id] ? row[val.id] : '-'}
                                        </td>
                                        :
                                        null
                                    )
                                    )}
                                </tr>
                            )}

                            {this.props.rowData.length === 0 &&
                                <tr>
                                    <td className={`no-records text-center`} colSpan={this.props.colSpan}>{constants.PAGINATION.NO_RECORDS_FOUND}</td>
                                </tr>
                            }

                        </tbody>

                    </table>
                </InfiniteScroll>
            </div>

        )
    }
}

export default DynamicTableComponentWithScroll
