import React, { Component } from "react";
import { connect } from "react-redux";
import { categories, companyContent } from "../Store/Actions/CompanyDetails";
import Slugify from "../Utils/Slugify";
import Collapsible from "react-collapsible";
class TransactionDetailsCategories extends Component {
  render() {
    return (
      this.props.company_details &&
      this.props.company_details.categories &&
      this.props.company_details.categories.length > 0 && (
        <aside className="inner-sidebar">
          <div className="sidebar_nav2 d-flex flex-column h-100">
            <div className="d-flex expand-collapse-icon-wrapper">
              <span
                className="material-icons-outlined expand-collapse-icon"
                onClick={this.props.toggleInnerSidebar}
              >
                keyboard_double_arrow_left
              </span>
            </div>
            <div className="opened-innersidebar-content">
              <div className="custom-collapser mt-1 inner-sidebar-collapser with-no-checkbox">
                {this.props.company_details.categories.map(
                  (category, index) => (
                    <div className="mb-1" key={category.id}>
                      {category.subcategories &&
                        category.subcategories.filter(
                          (r) => r.is_selected === true
                        ).length > 0 && (
                          <div className="custom-collapser-item">
                            <Collapsible
                              open={true}
                              trigger={
                                <>
                                  <div className="d-flex align-items-center ml-auto collapse-icon">
                                    <span className="material-icons-outlined material-icon">
                                      chevron_right
                                    </span>
                                  </div>
                                </>
                              }
                              triggerSibling={() => (
                                <div className="d-flex align-items-center trigger-block">
                                  <input
                                    type="checkbox"
                                    name={category.name}
                                    id={Slugify(category.name)}
                                    checked={category.is_selected}
                                  />
                                  <h5
                                    className="mb-0 title"
                                    htmlFor={Slugify(category.name)}
                                    title={category.name}
                                  >
                                    <span className="ellipsis">
                                      {category.name}
                                    </span>
                                  </h5>
                                </div>
                              )}
                            >
                              {category.subcategories.map(
                                (sub_category, index_sub_cat) =>
                                  sub_category.is_selected === true && (
                                    <div
                                      className="collapse-panel"
                                      onClick={() =>
                                        this.props.handleClickScroll(
                                          sub_category.name,
                                          sub_category.id
                                        )
                                      }
                                    >
                                      <div
                                        className="collapse-panel-content"
                                        key={category.id}
                                      >
                                        <div
                                          className="item d-flex align-items-center"
                                          key={sub_category.id}
                                        >
                                          <input
                                            key={sub_category.id}
                                            type="checkbox"
                                            name={sub_category.name}
                                            id={
                                              Slugify(category.name) +
                                              "_" +
                                              Slugify(sub_category.name)
                                            }
                                            checked={sub_category.is_selected}
                                          />
                                          <label
                                            style={
                                              this.props.selectedSlide ===
                                              sub_category.id
                                                ? { color: "#1084C5" }
                                                : {}
                                            }
                                            className={`
                                            ${
                                              category.is_selected
                                                ? "selected"
                                                : "non-selected"
                                            } 
                                            sub-category-label mb-0`}
                                            title={sub_category.name}
                                          >
                                            <span className="ellipsis">
                                              {sub_category.name}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                            </Collapsible>
                          </div>
                        )}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="closed-innersidebar-content d-flex flex-grow-1">
              <div className="content text-center font-weight-semibold">
                CONTENTS
              </div>
            </div>
          </div>
        </aside>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    companyContent: (payload) => {
      dispatch(companyContent(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionDetailsCategories);
