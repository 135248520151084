import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { DropdownList } from "react-widgets";
import { useHistory } from "react-router-dom";

import CustomReactTooltip from "../../Components/CustomReactTooltip";
import { ResourceAndFAQServices } from "./ResourceAndFAQServices";
import Toast from "../../Utils/Toast";
import constants from "../../Utils/constants";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import { useMemo } from "react";
import ResourcesList from "./ResourcesList";

const YOUTUBE_URL_REGEX =
  /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})(&.*)?$/;
const validateInput = (input) => {
  const error = {};
  let isValid = true;
  if (!input.title) {
    error.title = "Title is required";
    isValid = false;
  }
  if (!input.video_link) {
    error.video_link = "Youtube url is required";
    isValid = false;
  }
  if (input.video_link && !YOUTUBE_URL_REGEX.test(input.video_link)) {
    error.video_link = "Invalid Youtube URL";
    isValid = false;
  }
  return { error, isValid };
};

const ResourceModal = ({
  isOpen,
  toggle,
  form,
  setForm,
  error,
  onSubmit,
  setError,
  reports,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} className="modal-lg custom-modal">
    <ModalHeader toggle={toggle}>
      <span className="category-title">
        {form?.id ? "Edit" : "Add"} Resource
      </span>
    </ModalHeader>
    <ModalBody className="d-flex flex-column" style={{ padding: "0px" }}>
      <div className="form-group">
        <label>
          Title<span className="mandatory">*</span>
        </label>
        <input
          placeholder="Enter Title"
          onChange={(e) => {
            const value = e.target?.value;
            if (value !== undefined) {
              setForm((prev) => ({ ...prev, title: value }));
              setError((prev) => ({ ...prev, title: "" }));
            }
          }}
          value={form?.title || ""}
          className="form-control"
        />
        {error.title && <span className="error-text">{error.title}</span>}
      </div>
      <div className="form-group">
        <label>Category/ Report Type</label>

        <DropdownList
          className="report-dropdown-list"
          data={reports}
          value={form?.category_type || ""}
          onChange={(e) => {
            if (e !== undefined) {
              setForm((prev) => ({ ...prev, category_type: e }));
              setError((prev) => ({ ...prev, category_type: "" }));
            }
          }}
          placeholder="Select Category Type"
        />
      </div>
      <div className="form-group">
        <label>
          Youtube URL<span className="mandatory">*</span>
        </label>
        <input
          placeholder="Enter Youtube url"
          onChange={(e) => {
            const value = e.target?.value;
            if (value !== undefined) {
              setForm((prev) => ({ ...prev, video_link: value }));
              setError((prev) => ({ ...prev, video_link: "" }));
            }
          }}
          value={form?.video_link || ""}
          className="form-control"
        />
        {error.video_link && (
          <span className="error-text">{error.video_link}</span>
        )}
      </div>
      <div className="form-group">
        <label>
          Select Priority<span className="mandatory">*</span>
        </label>
        <input
          type="number"
          onChange={(e) => {
            const value = e.target?.value;
            if (value !== undefined) {
              setForm((prev) => ({ ...prev, order: value }));
            }
          }}
          value={form?.order || ""}
          className="form-control"
        />
      </div>
      <ModalFooter>
        <div className="d-flex justify-content-end g-15">
          <Button className="btn-outline-primary" onClick={toggle}>
            Cancel
          </Button>
          <Button className="btn-primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </ModalBody>
  </Modal>
);

const VideoModal = ({ isOpen, toggle, videoId, title, data }) => {
  function getYouTubeEmbedUrl(videoId) {
    const baseUrl = `https://www.youtube.com/embed/${videoId}`;
    const params = ["autoplay=1", "rel=0", "modestbranding=1", "showinfo=0"];
    return `${baseUrl}?${params.join("&")}`;
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg custom-modal">
      <ModalHeader toggle={toggle}>
        {title?.length > 45 ? (
          <>
            <span
              className="category-title"
              data-for={`tooltip-${title}-title`}
              data-tip={title}
            >
              {title.slice(0, 45) + "..."}
            </span>
            <CustomReactTooltip id={`tooltip-${title}-title`} multiline />
          </>
        ) : (
          <span className="category-title">{title}</span>
        )}
      </ModalHeader>
      <ModalBody
        className="d-flex justify-content-center flex-d"
        style={{ padding: "0px" }}
      >
        {videoId && (
          <iframe
            width="520"
            height="315"
            src={getYouTubeEmbedUrl(videoId)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="youtube-player"
          ></iframe>
        )}
        <div className="d-flex" style={{ marginTop: "10px" }}>
          <span style={{ fontWeight: "700", fontSize: "14px" }}>
            Published Date:
            <span style={{ fontWeight: "400", fontSize: "14px" }}>
              {" "}
              {ComapnyDetailsDateTimeFormatter(data.createdAt, false)}
            </span>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};
export const ActiveDeactiveDeleteResourceModal = ({
  isOpen,
  toggle,
  form,
  isDelete,
  onSubmit,
  label,
}) => {
  const actionType = isDelete
    ? "Delete"
    : form.is_active
    ? "Deactivate"
    : "Activate";
  const actionColor = isDelete || form.is_active ? "#FF312A" : "#007f4d";
  const backgroundColor = isDelete || form.is_active ? "#FFF2F2" : "#b7ffe0";

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-sm custom-modal">
      <ModalHeader toggle={toggle}>
        <span className="category-title">
          {actionType} {label}
        </span>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        <div
          className="d-flex justify-content-center active-modal-body-div"
          style={{
            background: backgroundColor,
            color: actionColor,
          }}
        >
          Are you sure you want to
          <br />
          {actionType.toLowerCase()} {label}?
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button className="btn-outline-primary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          className="btn-primary"
          style={{
            background: actionColor,
            border: "transparent",
          }}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const NoDataAvailable = ({ text }) => {
  return (
    <span className="d-flex justify-content-center align-item-center h-100 f-24">
      No {text} Available
    </span>
  );
};

export const Resource = (props) => {
  const history = useHistory();
  const [activeModal, setActiveModal] = useState(false);
  const initialFields = {
    title: "",
    category_type: "",
    video_link: "",
    order: 100,
  };
  const [error, setError] = useState({});
  const [playData, setPlayData] = useState({});
  const [isIframeOpen, setIframeOpen] = useState(false);
  const [videoId, setVideoId] = useState();
  const [form, setForm] = useState(initialFields);
  const [resourceList, setResourceList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [paginationState, setPaginationState] = useState({
    pageNo: 1,
    count: 0,
  });
  const reports = [
    "Company Research",
    "Benchmarks",
    "Market Forecasts",
    "Market Landscapes",
    "Special Reports",
    "Customer Studies",
    "Ecosystems",
  ];

  const handleModelClose = useCallback(() => {
    props.setOpenResourceModel(false);
    setError({});
    setForm(initialFields);
  }, [props]);

  const onSubmit = useCallback(async () => {
    const { error, isValid } = validateInput(form);
    setError(error);
    if (isValid) {
      try {
        if (form?.id) {
          let { title, video_link, category_type, order } = form;
          let input = { title, video_link, category_type, order };
          await ResourceAndFAQServices.updateResource(input, form.id);
          Toast("Resource Updated Successfully", "success");
        } else {
          await ResourceAndFAQServices.addResource(form);
          Toast("Resource Added Successfully", "success");
        }
        await fetchResources();
        handleModelClose();
      } catch (error) {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      }
    }
  }, [form, handleModelClose]);

  function getYouTubeVideoId(url) {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  }

  const handlePlay = useCallback((data) => {
    setVideoId(getYouTubeVideoId(data.video_link));
    setPlayData(data);
    setIframeOpen(true);
  }, []);
  const handleCloseActiveModal = useCallback(() => {
    setActiveModal(false);
    setForm(initialFields);
    setIsDelete(false);
  }, []);

  const handleIframeModelClose = useCallback(() => {
    setIframeOpen(false);
    setPlayData({});
    setVideoId();
  }, []);

  const handleEditResource = useCallback((item) => {
    setForm(item);
    props.setOpenResourceModel(true);
  }, []);

  const videoThumbnailUrl = useCallback((url) => {
    const videoId = getYouTubeVideoId(url);
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  }, []);

  const handleActive = async () => {
    let input = { is_active: form.is_active == 1 ? 0 : 1 };
    await ResourceAndFAQServices.updateResourceStatus(input, form.id);
    Toast("Resource Status Updated Successfully", "success");
    setActiveModal(false); //
    setIsDelete(false);
    await fetchResources();
  };

  const handleDelete = async () => {
    await ResourceAndFAQServices.deleteResource(form.id);
    Toast("Resource Deleted Successfully", "success");
    setForm(initialFields);
    setActiveModal(false); //
    setIsDelete(false);
    await fetchResources();
  };

  const fetchResources = async (pageNumber) => {
    let pageNo = pageNumber || 1;
    // Set default values if parameters are not provided
    let queryParams = "";

    if (props.search !== "") {
      queryParams += "&title=" + props.search;
    }

    // Update the browser history
    history.push({
      pathname: history.location.pathname,
      search: `?activeTab=Resources` + "&page=" + pageNo + queryParams,
    });

    let url = `/faq-resources/resources?limit=999`;

    if (pageNo) {
      url = url + `&page=${pageNo}`;
    }
    if (props.search) {
      url = url + `&name=${props.search}`;
    }
    if (props.status !== "" && props.status !== "all") {
      let status = props.status === "active" ? 1 : 0;
      url = url + `&status=${status}`;
    }
    if (props.order !== "") {
      let order = props.order === "Old to New" ? "asc" : "desc";
      url = url + `&order=${order}&sort=created_at`;
    } else {
      url = url + `&sort=order`;
    }
    let res = await ResourceAndFAQServices.getResources(url);
    setResourceList(res.data.data.data);
    setPaginationState({
      pageNo: res.data.data.currentPage,
      count: res.data.data.total,
    });
  };

  const memoizedResourceList = useMemo(() => resourceList, [resourceList]);

  useEffect(() => {
    fetchResources();
  }, [props.search, props.status, props.order]);

  return (
    <div
      className="d-flex justify-content-space-between flex-d h-100"
      style={{ marginTop: "15px", overflowY: "scroll" }}
    >
      {resourceList?.length ? (
        <ResourcesList
          resourceList={memoizedResourceList}
          setActiveModal={setActiveModal}
          setForm={setForm}
          setIsDelete={setIsDelete}
          handlePlay={handlePlay}
          handleEditResource={handleEditResource}
          videoThumbnailUrl={videoThumbnailUrl}
        />
      ) : (
        <NoDataAvailable text="Resources" />
      )}

      <ResourceModal
        isOpen={props.openResourceModel}
        toggle={handleModelClose}
        form={form}
        setForm={setForm}
        error={error}
        onSubmit={onSubmit}
        setError={setError}
        reports={reports}
      />
      <VideoModal
        isOpen={isIframeOpen}
        toggle={handleIframeModelClose}
        videoId={videoId}
        title={playData?.title}
        data={playData}
      />
      <ActiveDeactiveDeleteResourceModal
        isOpen={activeModal || isDelete}
        toggle={handleCloseActiveModal}
        form={form}
        isDelete={isDelete}
        onSubmit={activeModal ? handleActive : handleDelete}
        label="Resource"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
