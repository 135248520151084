/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import DynamicCategoryComponent from './DynamicCategoryComponent'
import constants from '../../Utils/constants'


export default function ListGeos(props) {



    return (
        <DynamicCategoryComponent
            title={constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.NAME}
            typeId={2}
            maxLevel={4}
            successMessage={constants.MASTER_DATA_GEOGRAPHY.CREATE.SUCCESS}
            editMessage={constants.MASTER_DATA_GEOGRAPHY.EDIT.SUCCESS}
            inActiveMessage={constants.MASTER_DATA_GEOGRAPHY.IN_ACTIVATE.SUCCESS}
            activeMessage={constants.MASTER_DATA_GEOGRAPHY.ACTIVATE.SUCCESS}
            inActivateConfirmMessage={constants.MASTER_DATA_GEOGRAPHY.INACTIVATE_GEOGRAPHY_CONFIRM_MESSAGE}
            activateConfirmMessage={constants.MASTER_DATA_GEOGRAPHY.ACTIVATE_GEOGRAPHY_CONFIRM_MESSAGE}
            {...props}

        />

    )
}
