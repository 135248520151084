import React, { Component } from "react";
import { connect } from "react-redux";
import {
  marketForecastCategories,
  marketForecastContent,
} from "../Store/Actions/MarketForecastDetails";
import Collapsible from "react-collapsible";
import Toast from "../Utils/Toast";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

class MarketForecastDetailsCategories extends Component {
  constructor(props) {
    super(props);
    this.subcatref = React.createRef();
    this.formRef = React.createRef();
  }
  state = {
    showPopover: false,
    isEdit: false,
    category: [],
  };

  componentDidMount() {}

  render() {
    let categories =
      this.props.market_forecast_details &&
      this.props.market_forecast_details.marketForecastCategories
        ? this.props.market_forecast_details.marketForecastCategories
        : [];
    function checkForDuplicates(array) {
      for (let i = 1; i < array.length; i++) {
        for (let j = i + 1; j < array.length; j++) {
          if (array[i] === array[j]) {
            return false;
          }
        }
      }
      return true;
    }
    let handleSubmit = (event) => {
      event.preventDefault();
      let data = [];
      if (event.target[0].value != "") {
        for (let i = 0; i < event.target.length; i++) {
          if (event.target[i].type === "text") {
            event.target[i].value != "" && data.push(event.target[i].value);
          }
        }
        checkForDuplicates(data)
          ? this.props.addCategory(
              data,
              this.state.isEdit,
              this.state.category_name
            ) &&
            this.setState({
              showPopover: false,
              category_name: "",
            })
          : Toast("Same SubCategory names doesnot allowed", "error");
      }
    };
    let addSubCategory = () => {
      let x = document.createElement("div");
      x.className = "input-wrapper";
      x.innerHTML =
        '<input type="text" className="form-control" id="subCategoryName" placeholder="Enter Subcategory Name" />';
      this.subcatref.current.appendChild(x);
    };
    let changecategoryStatus = () => {
      this.setState({
        showPopover: !this.state.showPopover,
        isEdit: false,
      });
    };
    let changestatesStatus = (category) => {
      this.setState(
        {
          showPopover: true,
          isEdit: true,
          category_name: category.name,
        },
        () => {
          this.formRef.current[0].value = category.name;
          if (category.subcategories) {
            if (
              this.formRef.current.length - 2 <=
              category.subcategories.length
            ) {
              for (let i = 0; i < category.subcategories.length; i++) {
                addSubCategory();
                this.formRef.current[i + 2].value =
                  category.subcategories[i].name;
              }
            }
          }
        }
      );
    };
    return (
      <aside
        className={`inner-sidebar ${
          this.props.isEditable ? "add-cat-subcat" : ""
        }`}
      >
        <div className="sidebar_nav2 d-flex flex-column h-100">
          <div className="d-flex expand-collapse-icon-wrapper">
            <span
              className="material-icons-outlined expand-collapse-icon"
              onClick={() => {
                this.props.toggleInnerSidebar();
                this.setState({ showPopover: false });
              }}
            >
              keyboard_double_arrow_left
            </span>
          </div>
          <div className="opened-innersidebar-content">
            {this.props.isEditable && (
              <>
                <button
                  type="button"
                  className="btn btn-primary content-save-btn mb-2 w-auto float-none add_category d-flex align-items-center pl-1"
                  id="add-edit-category-subcategory"
                  onClick={changecategoryStatus}
                >
                  <span className="material-icons-outlined icon icon-fs-18 text-black2">
                    add
                  </span>
                  Add Category
                </button>
              </>
            )}
            <div className="custom-collapser mt-1 inner-sidebar-collapser">
              <div className="mb-1">
                <div className="custom-collapser-item custom-collapser-2-item">
                  {categories.map((category) => (
                    <Collapsible
                      open={true}
                      trigger={
                        <>
                          <div className="d-flex align-items-center ml-auto collapse-icon">
                            <span className="material-icons-outlined material-icon">
                              chevron_right
                            </span>
                          </div>
                        </>
                      }
                      triggerSibling={() => (
                        <div className="d-flex align-items-center trigger-block add-remove-item  add-remove-edit-item">
                          <input
                            type="checkbox"
                            className="custom-checkbox-new"
                            name="Insights"
                            id="tbr_insights"
                            checked=""
                          />
                          <label className=" mb-0 title" for="tbr_insights">
                            <span className="">{category.name}</span>
                          </label>
                          {this.props.isEditable && (
                            <>
                              <span
                                className="material-icons-outlined icon icon-fs-16 text-primary cursor-pointer mr-6px"
                                id="add-edit-category-subcategory"
                                onClick={() => {
                                  changestatesStatus(category);
                                }}
                              >
                                edit
                              </span>
                              {!category.is_selected ? (
                                <span
                                  onClick={(e) => {
                                    if (this.props.isEditable) {
                                      this.props.toggleCheckbox(
                                        !category.is_selected,
                                        "category",
                                        category.uid
                                      );
                                    }
                                  }}
                                  className="material-icons-outlined icon icon-fs-18 text-primary cursor-pointer mr-1"
                                >
                                  add
                                </span>
                              ) : (
                                <span
                                  onClick={(e) => {
                                    if (this.props.isEditable) {
                                      this.props.toggleCheckbox(
                                        !category.is_selected,
                                        "category",
                                        category.uid
                                      );
                                    }
                                  }}
                                  className="material-icons-outlined icon icon-fs-18 remove-icon cursor-pointer mr-1"
                                >
                                  close
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    >
                      {category.subcategories.map((subcategory) => (
                        <div className="collapse-panel">
                          <div className="collapse-panel-content">
                            <div
                              className={`${
                                subcategory.is_selected ? "selected" : ""
                              } item d-flex align-items-center add-remove-item`}
                            >
                              <input
                                className="custom-checkbox-new"
                                type="checkbox"
                                name="Breaking News"
                                id="tbr_insights_tbr_breaking_news"
                                checked=""
                              />
                              <label
                                className="non-selected sub-category-label mb-0 add-remove-label"
                                for="tbr_insights_tbr_breaking_news"
                              >
                                <span className="">{subcategory.name}</span>
                              </label>

                              {this.props.isEditable && (
                                <span
                                  onClick={(e) => {
                                    if (this.props.isEditable) {
                                      this.props.toggleCheckbox(
                                        !subcategory.is_selected,
                                        "sub_category",
                                        category.uid,
                                        subcategory.uid
                                      );
                                    }
                                  }}
                                  className={`${
                                    subcategory.is_selected
                                      ? "red-icon"
                                      : "blue-icon"
                                  } material-icons-outlined icon icon-fs-18 cursor-pointer mr-2px`}
                                >
                                  {subcategory.is_selected
                                    ? "playlist_remove"
                                    : "playlist_add"}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Collapsible>
                  ))}
                  {this.props.isEditable && (
                    <UncontrolledPopover
                      placement="right"
                      target="add-edit-category-subcategory"
                      isOpen={this.state.showPopover}
                      trigger="legacy"
                      className="custom-popover add-edit-category-subcategory-popover"
                    >
                      <PopoverBody>
                        <div className="d-flex align-items-center title-block">
                          <h5 className="mb-0 title font-weight-normal">
                            {this.state.isEdit ? "Edit" : "Add"} Category/
                            Subcategory
                          </h5>
                          <span
                            onClick={changecategoryStatus}
                            className="material-icons-round icon ml-auto icon-fs-18 cursor-pointer"
                          >
                            close
                          </span>
                        </div>
                        <form onSubmit={handleSubmit} ref={this.formRef}>
                          <div className="cat-subcat-inputs-block">
                            <input
                              type="text"
                              className="form-control"
                              id="categoryName"
                              placeholder="Enter Category Name"
                            />
                            <button
                              type="button"
                              className="btn btn-primary content-save-btn mb-2 w-auto float-none add-sub-category d-flex align-items-center pl-1"
                              onClick={addSubCategory}
                            >
                              <span className="material-icons-outlined icon icon-fs-18 text-black2">
                                add
                              </span>
                              Add Sub Category
                            </button>
                            <div
                              className="sub-category-inputs"
                              ref={this.subcatref}
                            ></div>
                          </div>
                          <div className="save-btn-wrapper d-flex">
                            <button
                              type="submit"
                              className={
                                "btn btn-custom btn-primary icon-text-btn d-inline-flex align-items-center ml-auto"
                              }
                            >
                              {this.state.isEdit ? "Update" : "Save"}
                              <span className="material-icons-outlined icon-fs-16 ml-1">
                                {this.state.isEdit ? "sync" : "save"}
                              </span>
                            </button>
                          </div>
                        </form>
                      </PopoverBody>
                    </UncontrolledPopover>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="closed-innersidebar-content d-flex flex-grow-1">
            <div className="content text-center font-weight-semibold">
              CONTENTS
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    market_forecast_details: state.market_forecast_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    marketForecastCategories: (payload) => {
      dispatch(marketForecastCategories(payload));
    },
    marketForecastContent: (payload) => {
      dispatch(marketForecastContent(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketForecastDetailsCategories);
