import React, { Component } from "react";
import constants from "../Utils/constants";
import { EmailTemplateServices } from "../Services/EmailTemplate";
import Toast from "../Utils/Toast";
import { formatRoute } from "react-router-named-routes";
import Notification from "../Components/Notification";
import JoditEditorContainer from "../Components/JoditEditorContainer";

const initialState = {
  emailTemplateName: "",
  subjectPart: "",
  HtmlPart: "",
  initialValue: "",
  resetCount: 0, // tiny mce doesnot re render easily so changing its key when the content resets solves the re rendering issue
  formErrors: {},
  loading: true,
};

class ManageEmailTemplate extends Component {
  state = { ...initialState };

  componentDidMount() {
    let emailTemplateKey = this.props.match.params.templateKey
      ? this.props.match.params.templateKey
      : null;
    if (emailTemplateKey) {
      this.getData(emailTemplateKey);
    }
  }

  getData = (emailTemplateKey) => {
    EmailTemplateServices.getEmailTemplateById(emailTemplateKey)
      .then((res) => {
        let response = res.data.templateData.Template;
        // tiny mce doesnot re render easily so changing its key when the content resets solves the re rendering issue
        let resetCount = this.state.resetCount;
        this.setState(
          {
            emailTemplateName: response.TemplateName
              ? response.TemplateName
              : "",
            subjectPart: response.SubjectPart ? response.SubjectPart : "",
            HtmlPart: response.HtmlPart ? response.HtmlPart : "",
            initialValue: response.HtmlPart ? response.HtmlPart : "",
            isFormSubmitted: false,
            apiError: null,
            resetCount: resetCount + 1,
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  onChangeHandler = (e) => {
    this.setState({
      ...this.state,
      HtmlPart: e,
    });
  };

  isFormValid = () => {
    let formError = false;
    let emailTemplateNameError = false;
    let subjectPartError = false;
    let HtmlPartError = false;
    let placeHoldersChanged = false;
    if (
      this.state.emailTemplateName === "" ||
      this.state.emailTemplateName === null
    ) {
      emailTemplateNameError = true;
      formError = true;
    }
    if (this.state.subjectPart === "" || this.state.subjectPart === null) {
      subjectPartError = true;
      formError = true;
    }
    if (this.state.HtmlPart === "" || this.state.HtmlPart === null) {
      HtmlPartError = true;
      formError = true;
    }
    if (
      this.arePlaceHoldersChanged(this.state.HtmlPart, this.state.initialValue)
    ) {
      HtmlPartError = true;
      formError = true;
      placeHoldersChanged = true;
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        emailTemplateNameError: emailTemplateNameError,
        subjectPartError: subjectPartError,
        HtmlPartError: HtmlPartError,
        placeHoldersChanged: placeHoldersChanged,
      },
      apiError: null,
    });
    return formError;
  };

  resetForm = () => {
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        emailTemplateNameError: false,
        subjectPartError: false,
        HtmlPartError: false,
        placeHoldersChanged: false,
      },
      apiError: null,
    });
    let emailTemplateKey = this.props.match.params.templateKey
      ? this.props.match.params.templateKey
      : null;
    if (emailTemplateKey !== null) {
      this.getData(emailTemplateKey);
    }
  };

  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let emailTemplateKey = this.props.match.params.templateKey
            ? this.props.match.params.templateKey
            : null;
          let postTemplateData = {
            templateName: this.state.emailTemplateName,
            subject: this.state.subjectPart,
            html: this.state.HtmlPart,
          };
          if (emailTemplateKey !== null) {
            EmailTemplateServices.updateEmailTemplate(
              emailTemplateKey,
              postTemplateData
            )
              .then((res) => {
                this.saveEmailTemplateSuccess();
              })
              .catch((error) => {
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          }
        }
      }
    );
  };

  saveEmailTemplateSuccess = () => {
    Toast(
      constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.SUCCESS.UPDATE_EMAIL_TEMPLATE,
      "success"
    );
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.ROUTE,
        {}
      ),
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.EDIT.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  arePlaceHoldersChanged = (changedContent, originalContent) => {
    let placeholdersChanged = false;
    let regex = /\{\{\w+\}\}/gm;
    let placeHolders = originalContent.match(regex);
    let newPlaceHolders = changedContent.match(regex);
    if (!placeHolders || placeHolders.length !== newPlaceHolders.length) {
      placeholdersChanged = true;
    } else {
      for (let val of placeHolders) {
        if (!changedContent.includes(val)) {
          placeholdersChanged = true;
          break;
        }
      }
    }
    return placeholdersChanged;
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.ROUTE
      ),
    });
  };
  render() {
    return this.state.loading ? (
      <>
        <span className="custom-download-loader" />
      </>
    ) : (
      <div>
        <aside className="main_content">
          <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
            <div className="manage-client">
              <div className="">
                <div className={"add-edit-form-card"}>
                  <form className={"form-wrapper"}>
                    <div className="card">
                      <div className="card-header title-cancel-header d-flex align-items-center">
                        <h3 className="text-darkblue2 mb-0 font-weight-bold">
                          {
                            constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.EDIT
                              .HEADER_TITLE
                          }
                        </h3>
                        <span
                          className="material-icons-round text-darkblue2 ml-auto cursor-pointer"
                          onClick={this.changeRoute}
                        >
                          close
                        </span>
                      </div>
                      <div className="card-body">
                        <div className="card-body-header">
                          <h5 className="mb-0 font-weight-semibold">
                            Email Template Report
                          </h5>
                        </div>
                        <div className="form-field-wrapper">
                          <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className={"row"}>
                                <div className="col-sm-12 form-group position-relative">
                                  <label htmlFor="emailTemplateName">
                                    Template Name{" "}
                                    <span className={"mandatory"}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      this.state.formErrors
                                        .emailTemplateNameError === true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors
                                        .emailTemplateNameError === false
                                        ? ""
                                        : ""
                                    }`}
                                    id="emailTemplateName"
                                    placeholder="Templete Name"
                                    value={this.state.emailTemplateName}
                                    disabled={true}
                                  />
                                  {this.state.formErrors
                                    .emailTemplateNameError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-12 form-group position-relative">
                                  <label for="subjectPart">
                                    Subject{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      this.state.formErrors.subjectPartError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.subjectPartError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                    id="subjectPart"
                                    placeholder="Subject Head"
                                    value={this.state.subjectPart}
                                    onChange={this.handleInputChange}
                                  />
                                  {this.state.formErrors.subjectPartError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-12 form-group position-relative html-editor-block">
                                  <label for="HtmlPart">
                                    HTML <span className={"mandatory"}>* </span>
                                  </label>
                                  <Notification
                                    color={"warning"}
                                    className="emailPlaceholder"
                                    message={
                                      "Placeholders are manditory and can't be modified, only can be rearranged in the content"
                                    }
                                  />
                                  <JoditEditorContainer
                                    height="700px"
                                    value={this.state.HtmlPart || ""}
                                    onChangeHandler={this.onChangeHandler}
                                    className={`form-control emailTemplate ${
                                      this.state.formErrors.HtmlPartError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.HtmlPartError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                  />
                                  {this.state.formErrors.HtmlPartError && (
                                    <div className="invalid-tooltip d-block">
                                      {this.state.formErrors.placeHoldersChanged
                                        ? constants.FORM.PLACEHOLDERS_CHANGED
                                        : constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-submit-buttons">
                          <div className={"row"}>
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className="text-right form-buttons">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary modal-btn"
                                  onClick={this.resetForm}
                                >
                                  Reset
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary modal-btn"
                                  onClick={this.handleSubmit}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

export default ManageEmailTemplate;
