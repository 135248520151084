import React, { Component } from "react";
import { ReportsServices } from "../Services/Reports";
import { ClientServices } from "../Services/Client";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import DocumentTitle from "../Utils/DocumentTitle";
import { formatRoute } from "react-router-named-routes";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import { clients, users } from "../Store/Actions/Client";
import moment from "moment";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import Dailog from "../Components/Dailog";
import isRoleAccountManager from "../Utils/isRoleAccountManager";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import CustomDatePicker from "../Components/CustomDatePicker";
import DynamicTableComponentWithChevron from "../Components/DynamicTableComponentWithChevron";
import Collapsible from "react-collapsible";

const tableHeader1 = [
  { id: "board_name", title: "Board Name", sortable: true, visibility: true },
  { id: "visits", title: "Visits", sortable: true, visibility: true },
  { id: "timespent", title: "Time Spent", sortable: true, visibility: true },
  { id: "downloadCount", title: "Downloads", sortable: true, visibility: true },
  { id: "minScroll", title: "Min Scroll(%)", sortable: true, visibility: true },
  { id: "maxScroll", title: "Max Scroll(%)", sortable: true, visibility: true },
  { id: "commentsCount", title: "Comments", sortable: true, visibility: true },
  { id: "sharedCount", title: "Shares", sortable: true, visibility: true },
];

const tableHeader2 = [
  {
    id: "template_name",
    title: "Visualization Name",
    sortable: true,
    visibility: true,
  },
  { id: "visits", title: "Visits", sortable: true, visibility: true },
  { id: "timespent", title: "Time Spent", sortable: true, visibility: true },
  { id: "downloadCount", title: "Downloads", sortable: true, visibility: true },
  { id: "minScroll", title: "Min Scroll(%)", sortable: true, visibility: true },
  { id: "maxScroll", title: "Max Scroll(%)", sortable: true, visibility: true },
];
const innerColumn = [
  { id: "username", title: "User", sortable: true, visibility: true },
  { id: "timestamp", title: "Time Stamp", sortable: true, visibility: true },
  { id: "scroll", title: "Scroll(%)", sortable: true, visibility: true },
  { id: "timespent", title: "Time spent", sortable: true, visibility: true },
];

class ReportsTbriPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      currentData: [],
      userData: [],
      columnData: [],
      sortColumn: null,
      sortOrder: null,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      count: 0,
      start_date: "2021-11-1",
      end_date: "2021-12-1",
      userId: null,
      clientId: null,
      clientName: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      disableStatus: false,
      selectReason: "",
      AssignedClients: [],
      showActions: false,
      isRoleAccountManager: false,
      totalTabs: [],
      selectedTab: constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.TABS[0].KEY,
      isRoleMember: false,
      boardsVisitedData: {},
      chartsVisitedData: {},
      boardsCreatedData: {},
      chartsCreatedData: {},
      boardInnerSidebarOpen: false,
      clickedValues: [],
    };
  }
  componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.REPORTS.LIST3.HEADER_TITLE);
    this.setState(
      {
        userId:
          this.props.location.state && this.props.location.state.id
            ? this.props.location.state.id
            : null,
        clientId:
          this.props.location.state && this.props.location.state.clientId
            ? this.props.location.state.clientId
            : null,
        start_date:
          this.props.location.state && this.props.location.state.fromDate
            ? this.props.location.state.fromDate
            : moment().subtract(60, "days").format("YYYY-MM-DD"),
        end_date:
          this.props.location.state && this.props.location.state.endDate
            ? this.props.location.state.endDate
            : moment().format("YYYY-MM-DD"),
        totalTabs: constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.TABS,
      },
      () => {
        this.getTableData();
        this.getBoardVisited();
        this.getChartVisited();
        this.getBoardCreated();
        this.getChartCreated();
      }
    );
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.ROUTE
    );
  }
  getUserDetails() {
    ClientServices.getClientUserDetails(this.state.userId)
      .then((res) => {
        this.setState({
          userData: res.data,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  getAssignedClients() {
    ClientServices.getAssignedClients("/getAssignedClients")
      .then((res) => {
        let clientData = res.data.data;
        clientData = clientData.map((data) => {
          return {
            id: data.id,
            name: data.name,
          };
        });
        let index = clientData.find(
          (element) => element.id === this.state.clientId
        );
        if (index) {
          this.setState({
            showActions: true,
          });
        } else {
          this.setState({
            showActions: false,
          });
        }
        this.setState({ AssignedClients: clientData });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  resendWelcomeEmailConfirmation = () => {
    let dailogModalHeader = (
      <>
        <div className="icon-box success">
          <i className="fa fa-envelope" aria-hidden="true"></i>
        </div>
        Resend Verification Link
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: "Are you sure want to Resend Verification Link?",
      dailogModalConfig: { type: "resend_welcomemail" },
    });
  };
  resendWelcomeEmail = () => {
    let arr = [];
    arr.push({ id: this.state.userData.id });
    ClientServices.resendEmailVerification(arr)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <div className="icon-box success">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </div>
            Success
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: "Verification mail has been sent.",
          dailogModalConfig: { type: false, id: null },
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getBoardVisited() {
    const userId = this.state.userId;
    let body = {
      user_id: userId,
      event_name: constants.GA.EVENTS.PAGE_VISIT,
      page_type: constants.GA.PAGE_TYPE.BOARD,
      is_published: 2, // 2 for Tbri User Boards
    };
    ReportsServices.getEventSpecificLogData(body).then((res) => {
      this.setState(
        {
          boardsVisitedData: res?.data,
        },
        () => {}
      );
    });
  }
  getChartVisited() {
    const userId = this.state.userId;
    let body = {
      user_id: null,
      event_name: constants.GA.EVENTS.PAGE_VISIT,
      page_type: constants.GA.PAGE_TYPE.DATA_VISUALIZATION,
      is_published: 2,
    };
    ReportsServices.getEventSpecificLogData(body).then((res) => {
      this.setState(
        {
          chartsVisitedData: res?.data,
        },
        () => {}
      );
    });
  }
  getBoardCreated() {
    let body = {
      user_id: this.state.userId,
      is_published: 2,
    };
    ReportsServices.getBoardCreatedData(body).then((res) => {
      this.setState(
        {
          boardsCreatedData: res?.data,
        },
        () => {}
      );
    });
  }
  getChartCreated() {
    let body = {
      user_id: this.state.userId,
      is_published: 2,
    };
    ReportsServices.getTotalChartDaysWise(body).then((res) => {
      this.setState(
        {
          chartsCreatedData: res?.data,
        },
        () => {}
      );
    });
  }
  sendResetPasswordLink() {
    UserServices.sendverificationmailForPasswordSetting(
      this.state.userData.email
    )
      .then((data) => {
        Toast(data.data.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  timeconversion(number) {
    if (!isNaN(number)) {
      let hours = number / 3600 > 0 ? Math.floor(number / 3600) : 0;
      let minutes =
        (number % 3600) / 60 > 0 ? Math.floor((number % 3600) / 60) : 0;
      let seconds = (number % 3600) % 60 > 0 ? (number % 3600) % 60 : 0;
      let hour_result =
        hours === 0 ? `` : hours > 1 ? `${hours} Hours` : `${hours} Hour`;
      let min_result =
        minutes === 0
          ? ``
          : minutes > 1
          ? `${minutes} Minutes`
          : `${minutes} Minute`;
      let sec_result =
        seconds === 0
          ? ``
          : seconds > 1
          ? `${seconds} Seconds`
          : `${seconds} Second`;
      return `${hour_result} ${min_result} ${sec_result}`.trim();
    }
    return "-";
  }

  getTbriBoardsData(body) {
    ReportsServices.groupByBoardData(body)
      .then((res) => {
        let BoardData =
          res.data.data && res.data.data.length ? res.data.data : [];
        BoardData = BoardData.map((data, index) => {
          return {
            id: data.id,
            board_name: data.name,
            visits: data.visitCount ?? 0,
            maxScroll: data.maxScroll ?? 0,
            minScroll: data.minScroll ?? 0,
            downloadCount: data.downloadCount ?? 0,
            commentsCount: data.commentsCount,
            sharedCount: data.sharedCount,
            timespent: data.timeSpentCount
              ? this.timeconversion(data.timeSpentCount)
              : 0,
            timespent_sort: data.timeSpentCount,
          };
        });
        this.setState(
          {
            rowData: BoardData,
            currentData: BoardData.slice(0, this.state.pageSize),
            count: BoardData.length,
            sortColumn:
              constants.PAGINATION.DEFAULT_REPORTS_GROUP_BY_BOARDS_SORT_COLUMN,
            sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
          },
          () => {
            this.changeRoute();
            this.sortTableData();
          }
        );
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  getTbriTemplatesData(body) {
    ReportsServices.getGroupByTemplateData(body)
      .then((res) => {
        let TemplateData =
          res.data.data && res.data.data.length ? res.data.data : [];
        TemplateData = TemplateData.map((data, index) => {
          return {
            id: data.id,
            template_name: (
              <NavLink className="d-flex align-items-center">
                <span>{data.chartName}</span>
                <div className="tooltip1">
                  <div className="tooltip_icon ml-1 d-flex align-items-center">
                    <span className="material-icons-outlined icon-fs-14">
                      info
                    </span>
                  </div>
                  <div className="tooltip_info text-center">
                    <div className="selected-items-block element-block">
                      <h6 className="text-grey block-title font-weight-normal mb-0">
                        Selected Items
                      </h6>
                      <div className="p-2 selected-items-wrapper">
                        Revenue is {data.axis ? data.axis.label : "-"}
                        {data.chart_json &&
                          data.chart_json.map((item, index) => {
                            let parentId = item.value;
                            // let isSegmentSelected = category.find((item) => item.is_selected).name === "Segments";
                            return (
                              <div
                                className="custom-collapser plus-minus-collapser mb-3 mt-1"
                                key={index}
                              >
                                <Collapsible
                                  open={true}
                                  triggerTagName={"div"}
                                  triggerClassName={"collapse-trigger"}
                                  triggerOpenedClassName={"collapse-trigger"}
                                  trigger={
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h6 className="font-weight-semibold text-black2 mb-0">
                                        {item.label}
                                      </h6>
                                      <span className="material-icon"></span>
                                    </div>
                                  }
                                >
                                  {item.children && item.children.length ? (
                                    item.children !== undefined &&
                                    item.children.map((item, index) => {
                                      return (
                                        <div
                                          className="collapse-panel-content"
                                          key={index}
                                        >
                                          <div className="d-flex flex-wrap align-items-center selected-items-list">
                                            <div className="selected-item d-flex align-items-center companies key={item.value}">
                                              <span>{item.label}</span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <span className="mx-5px">
                                      No Items Selected
                                    </span>
                                  )}
                                </Collapsible>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            ),
            visits: data.visitCount ?? 0,
            maxScroll: data.maxScroll ?? 0,
            minScroll: data.minScroll ?? 0,
            downloadCount: data.downloadCount ?? 0,
            timespent: data.timeSpentCount
              ? this.timeconversion(data.timeSpentCount)
              : 0,
            timespent_sort: data.timeSpentCount,
          };
        });
        this.setState(
          {
            rowData: TemplateData,
            currentData: TemplateData.slice(0, this.state.pageSize),
            count: TemplateData.length,
            sortColumn:
              constants.PAGINATION.DEFAULT_REPORTS_GROUP_BY_BOARDS_SORT_COLUMN,
            sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
          },
          () => {
            this.changeRoute();
            this.sortTableData();
          }
        );
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  getTableData() {
    const userId = this.state.userId;
    this.changeRoute();
    let body = {};
    switch (this.state.selectedTab) {
      case "tbri-boards":
        body = {
          user_id: userId,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          page_type: constants.GA.PAGE_TYPE.BOARD,
          is_published: 2,
        };
        this.getTbriBoardsData(body);
        break;
      case "tbri-template":
        body = {
          user_id: userId,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          page_type: constants.GA.PAGE_TYPE.DATA_VISUALIZATION,
          is_published: 2,
        };
        this.getTbriTemplatesData(body);
        break;
      default:
    }
  }
  getBoardDateFormatter(dateObj) {
    let momentobj = new moment(dateObj);
    let day = momentobj.format("D");
    let month = momentobj.format("MMMM");
    let year = momentobj.format("YYYY");
    let hour = momentobj.format("hh");
    let min = momentobj.format("mm");
    let ampm = momentobj.format("a");

    return `${day} ${month} ${year},${hour}:${min}${ampm}`;
  }
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      let start = (this.state.pageNo - 1) * this.state.pageSize;
      let end = start + this.state.pageSize;
      end = end > this.state.count ? this.state.count : end;
      this.setState({
        currentData: this.state.rowData.slice(start, end),
      });
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => {
        let start = (this.state.pageNo - 1) * this.state.pageSize;
        let end = start + this.state.pageSize;
        end = end > this.state.count ? this.state.count : end;
        this.setState({
          currentData: this.state.rowData.slice(start, end),
          clickedValues: [],
        });
      }
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => {
        let start = (this.state.pageNo - 1) * this.state.pageSize;
        let end = start + this.state.pageSize;
        end = end > this.state.count ? this.state.count : end;
        this.setState({
          currentData: this.state.rowData.slice(start, end),
          clickedValues: [],
        });
      }
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => {
      let start = (this.state.pageNo - 1) * this.state.pageSize;
      let end = start + this.state.pageSize;
      end = end > this.state.count ? this.state.count : end;
      this.setState({
        currentData: this.state.rowData.slice(start, end),
        clickedValues: [],
      });
    });
  };
  getDateDifference = (fromDate, endDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(endDate);
    const diffTime = date2 - date1;
    if (diffTime < 0) {
      return false;
    }
    return true;
  };

  changeFromDate = (e, value) => {
    if (value !== null) {
      let bool =
        e === "start"
          ? this.getDateDifference(value, this.state.end_date)
          : this.getDateDifference(this.state.start_date, value);
      if (bool && e === "start")
        this.setState(
          { start_date: moment(value).format("YYYY-MM-DD"), pageNo: 1 },
          () => {
            this.getBoardCreated();
            this.getBoardVisited();
            this.getChartVisited();
            this.getTableData();
          }
        );
      else if (bool) {
        this.setState(
          { end_date: moment(value).format("YYYY-MM-DD"), pageNo: 1 },
          () => {
            this.getTableData();
            this.getBoardCreated();
            this.getBoardVisited();
            this.getChartVisited();
          }
        );
      }
    }
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true)
      : "all";
    let clientName = clientId.name;
    clientId = clientId.id;
    if (this.state.isRoleAccountManager) {
      let clientData = this.state.AssignedClients;
      let index = clientData.find((element) => element.id === clientId);
      if (index) {
        this.setState({
          showActions: true,
        });
      } else {
        this.setState({
          showActions: false,
        });
      }
    }
    this.setState(
      {
        clientId: clientId,
        clientName: clientName,
        pageNo: 1,
        count: 0,
      },
      () => {
        this.getUsersList();
      }
    );
  };
  handleUserChange = (e) => {
    let users = [...this.props.client.users];
    let selectedUser = users[0];
    let userId, isRoleMember;
    users.forEach((c) => {
      if (c.id === e.id) {
        c.is_selected = true;
        selectedUser = c;
      } else {
        c.is_selected = false;
      }
    });
    this.props.setUsers(users);
    if (selectedUser) {
      userId = selectedUser.id;
      isRoleMember = selectedUser.isRoleMember;
    }
    this.setState(
      {
        userId: userId,
        pageNo: 1,
        isRoleMember,
      },
      () => {
        this.getUserDetails();
        this.getTableData();
        this.getBoardVisited();
        this.getChartVisited();
        if (!isRoleMember) {
          this.getBoardCreated();
        }
      }
    );
  };
  handleAccordianClicks = (e, row) => {
    const index = this.state.clickedValues.indexOf(row.id);
    let values = this.state.clickedValues;
    if (index < 0) {
      this.handleInnerData(row);
      values.push(row.id);
    } else {
      values.splice(index, 1);
    }
    this.setState({
      clickedValues: values,
    });
  };
  getUsersList() {
    let url = this.state.clientId
      ? `/client/${this.state.clientId}/null/users`
      : ""; // 3 is for all actve and inactive users for status as route is modified for users in client module
    ClientServices.getClientUsers(url)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let totalUsers = response;
        if (totalUsers.length != 0) {
          totalUsers = totalUsers.map((r) => {
            return {
              id: r.id,
              name: r.first_name + " " + r.last_name,
              is_selected: r.id === this.state.userId ? true : false,
              isRoleMember:
                r.role && r.role.short_name
                  ? r.role.short_name === "member"
                  : 0,
            };
          });

          totalUsers = totalUsers.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          );
          let selectedUser = totalUsers.find((c) => c.is_selected === true)
            ? totalUsers.find((c) => c.is_selected === true)
            : totalUsers[0];
          let userid = selectedUser.id;
          let isRoleMember = selectedUser.isRoleMember;
          if (userid === totalUsers[0]["id"]) {
            totalUsers[0].is_selected = true;
          }
          this.setState(
            {
              userId: userid,
              pageNo: 1,
              isRoleMember,
            },
            () => {
              this.getUserDetails();
              this.getTableData();
              this.getBoardVisited();
              if (!isRoleMember) {
                this.getBoardCreated();
              }
            }
          );
          this.props.setUsers(totalUsers);
        } else {
          totalUsers = [];
          totalUsers.push({
            id: "na",
            name: "No Users Found",
            is_selected: true,
          });
          this.setState({
            userId: null,
            userData: [],
            rowData: [],
            pageNo: 1,
          });
          this.props.setUsers(totalUsers);
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  dateformatChanger = (input) => {
    let dateobj = new Date(input);
    let momentobj = new moment(dateobj);
    let old_date = momentobj.format("MM-DD-YYYY");
    return old_date;
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        pageNo: 1,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => {
        this.sortTableData();
      }
    );
  };
  sortTableData = () => {
    let sortOrder = this.state.sortOrder;
    let sortColumn = this.state.sortColumn;
    let totalData = this.state.rowData;
    let bool = false;
    sortColumn = sortColumn === "timespent" ? "timespent_sort" : sortColumn;
    totalData.sort((a, b) => {
      const aLocale = this.convertType(a[sortColumn]);
      const bLocale = this.convertType(b[sortColumn]);
      if (aLocale === bLocale) {
        return 0;
      } else if (aLocale === undefined || aLocale > bLocale) {
        bool = true;
        return 1;
      } else if (bLocale === undefined || aLocale < bLocale) {
        bool = true;
        return -1;
      }
    });
    if (bool) {
      totalData = sortOrder === "desc" ? totalData.reverse() : totalData;
    }
    this.setState({
      rowData: totalData,
      currentData: totalData.slice(0, this.state.pageSize),
      pageNo: 1,
    });
  };
  convertType = (data) => {
    switch (this.state.sortColumn) {
      case "timestamp":
        return new Date(data).getTime();
      case "board_name":
        return data;
      case "template_name":
        return data;
      default:
        return Number(data);
    }
  };
  emailValidationHandler = () => {
    ClientServices.getSingleUserBounceValidation(
      this.state.userData.email,
      this.state.userData.id
    )
      .then((res) => {
        if (res.data.status.toLowerCase().trim() === "invalid") {
          let dailogModalHeader = (
            <>
              <div className="icon-box danger">
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              User Invalid
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent:
              constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE,
            dailogModalConfig: { type: false, id: null },
          });
        } else if (
          res.data.status.toLowerCase() === "catch_all" ||
          res.data.status.toLowerCase().trim() === "catchall"
        ) {
          let dailogModalHeader = (
            <>
              <div className="icon-box warning">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </div>
              Verify Email
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: "Please Verify User Email Manually",
            dailogModalConfig: { type: false, id: null },
          });
        } else {
          let dailogModalHeader = (
            <>
              <div className="icon-box success">
                <i className="fa fa-check" aria-hidden="true"></i>
              </div>
              Success
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: "User Email is Valid",
            dailogModalConfig: { type: false, id: null },
          });
        }
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  removeUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <div className="icon-box danger">
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        Remove
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: constants.CLIENT_USER.REMOVE_USER_CONFIRM_MESSAGE,
      dailogModalConfig: { type: "remove_user", id: data },
    });
  };
  sendResetPasswordLinkConfirmation = () => {
    let dailogModalHeader = (
      <>
        <div className="icon-box success">
          <i className=" fa fa-unlock-alt" aria-hidden="true"></i>
        </div>
        Reset Password Link
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: "Are you sure want to send reset password link?",
      dailogModalConfig: { type: "reset_password" },
    });
  };
  emailValidationConfirmation = () => {
    let dailogModalHeader = (
      <>
        <div className="icon-box success">
          <i className="fa fa-envelope" aria-hidden="true"></i>
        </div>
        User Email Validation
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: "Are you sure want to validate user?",
      dailogModalConfig: { type: "email_validation" },
    });
  };
  removeClientUser = (clientUserId) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.removeClientUser(arr)
      .then((res) => {
        Toast(constants.CLIENT_USER.REMOVE.SUCCESS, "success");
        this.getUsersList();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_user":
        this.removeClientUser(config.id);
        break;
      case "reset_password":
        this.sendResetPasswordLink();
        break;
      case "email_validation":
        this.emailValidationHandler();
        break;
      case "resend_welcomemail":
        this.resendWelcomeEmail();
        break;
      case "de-activate_user":
        this.toggleClientUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleClientUser(config.id, 1);
        break;
      default:
        break;
    }
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.selectedTab) {
      queryParams = "&selected_tab=" + this.state.selectedTab;
    }
    if (this.state.sortOrder) {
      queryParams +=
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.ROUTE
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };
  toggleClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <div
          className={
            data.is_active === 1 ? "icon-box danger" : "icon-box success"
          }
        >
          <i
            className={data.is_active === 1 ? "fa fa-times" : "fa fa-check"}
            aria-hidden="true"
          ></i>
        </div>
        {data.is_active === 1 ? "De-Activation" : "Activation"}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent:
        data.is_active === 1 ? (
          <div>
            {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
            <div className="mt-3">
              <select onChange={this.handleReasonchange}>
                <option value="1" selected>
                  User Requested
                </option>
                <option value="2">Email bounced</option>
                <option value="3">ClearOut Invalid</option>
              </select>
            </div>
          </div>
        ) : (
          constants.CLIENT_USER.ACTIVATE_USER_CONFIRM_MESSAGE
        ),
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_user" : "activate_user",
        id: this.state.userId,
      },
    });
  };
  handleReasonchange = (e) => {
    let value = e.target.value;
    let reason;
    switch (value) {
      case "1":
        reason = "User Requested";
        break;
      case "2":
        reason = "Email bounced";
        break;
      case "3":
        reason = "ClearOut Invalid";
        break;
    }
    this.setState({
      selectReason: reason,
    });
  };
  toggleClientUser = (clientUserId, isActive) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.toggleClientUser(isActive, {
      userIds: arr,
      reason: isActive === 1 ? "" : this.state.selectReason,
    })
      .then((res) => {
        Toast(
          isActive
            ? constants.CLIENT_USER.ACTIVATE.SUCCESS
            : constants.CLIENT_USER.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getUserDetails();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  onContentChangeTypeHandler = (s) => {
    this.setState(
      {
        selectedTab: s,
        clickedValues: [],
        rowData: [],
        currentData: [],
        pageNo: 1,
        count: 0,
      },
      () => {
        this.getTableData();
      }
    );
  };
  handleInnerData = (row) => {
    if (!row.children) {
      let body = {};
      switch (this.state.selectedTab) {
        case "tbri-boards":
          body = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            board_id: row.id,
            is_published: 2,
          };
          this.getTbriReportsBoardData(body);
          break;
        case "tbri-template":
          body = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            template_id: row.id,
            is_published: 2,
          };
          this.getTbriReportsTemplateAccordionData(body);
          break;
        default:
          break;
      }
    }
  };

  getTbriReportsTemplateAccordionData = (body) => {
    ReportsServices.getTemplateCreatedByAccordianData(body)
      .then((res) => {
        let TemplateData =
          res.data.data && res.data.data.length ? res.data.data : [];
        TemplateData = TemplateData.map((data, index) => {
          return {
            username: data.userName,
            timestamp: ComapnyDetailsDateTimeFormatter(
              moment(data.timeStamp / 1000),
              false
            ),
            scroll: data.scroll ?? 0,
            timespent: this.timeconversion(data.timeOnPage),
          };
        });
        let data = this.state.rowData;
        let index = data.findIndex((obj) => obj.id === body.template_id);
        data[index].children = TemplateData;
        this.setState({ rowData: data });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getTbriReportsBoardData = (body) => {
    ReportsServices.boardAccordianData(body)
      .then((res) => {
        let BoardData =
          res.data.data && res.data.data.length ? res.data.data : [];
        BoardData = BoardData.map((data, index) => {
          return {
            timestamp: ComapnyDetailsDateTimeFormatter(
              moment(data.timeStamp / 1000),
              false
            ),
            scroll: data.scroll ? data.scroll : 0,
            timespent: this.timeconversion(data.timeOnPage),
            username: data.userName,
          };
        });
        let data = this.state.rowData;
        let index = data.findIndex((obj) => obj.id === body.board_id);
        data[index].children = BoardData;
        this.setState({ rowData: data });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    let users =
      this.props.client && this.props.client.users
        ? this.props.client.users
        : [];
    return (
      <>
        <aside className="main_content">
          <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            <div className="tbr-client-list">
              <div className="row gutter-8">
                <div className="col-xl-3 col-md-4">
                  <h1 className="text-ligntblue2 font-weight-bold client-list-h1">
                    TBR Reports
                  </h1>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            {this.state.selectedTab === "tbri-boards"
                              ? `#Boards Last Visited`
                              : `#Visualizations Last Visited`}
                          </h5>
                          <div className="stats-group d-flex">
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.selectedTab === "tbri-boards"
                                  ? this.state.boardsVisitedData?.sevenDaysCount
                                  : this.state.chartsVisitedData
                                      ?.sevenDaysCount}
                              </h5>
                              <label>7 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.selectedTab === "tbri-boards"
                                  ? this.state.boardsVisitedData
                                      ?.thirtyDaysCount
                                  : this.state.chartsVisitedData
                                      ?.thirtyDaysCount}
                              </h5>
                              <label>30 days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.selectedTab === "tbri-boards"
                                  ? this.state.boardsVisitedData?.sixtyDaysCount
                                  : this.state.chartsVisitedData
                                      ?.sixtyDaysCount}
                              </h5>
                              <label>60 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.selectedTab === "tbri-boards"
                                  ? this.state.boardsVisitedData?.nintyDaysCount
                                  : this.state.chartsVisitedData
                                      ?.nintyDaysCount}
                              </h5>
                              <label>90 Days</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.state.isRoleMember ? (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                        <div className="card custom-card">
                          <div className="card-body">
                            <h5 className="font-weight-bold text-black2 mb-1">
                              {this.state.selectedTab === "tbri-boards"
                                ? `#Boards Last Created`
                                : `#Visualizations Last Created`}
                            </h5>
                            <div className="stats-group d-flex">
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.selectedTab === "tbri-boards"
                                    ? this.state.boardsCreatedData
                                        ?.sevenDaysCount
                                    : this.state.chartsCreatedData
                                        ?.sevenDaysCount}
                                </h5>
                                <label>7 Days</label>
                              </div>
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.selectedTab === "tbri-boards"
                                    ? this.state.boardsCreatedData
                                        ?.thirtyDaysCount
                                    : this.state.chartsCreatedData
                                        ?.thirtyDaysCount}
                                </h5>
                                <label>30 days</label>
                              </div>
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.selectedTab === "tbri-boards"
                                    ? this.state.boardsCreatedData
                                        ?.sixtyDaysCount
                                    : this.state.chartsCreatedData
                                        ?.sixtyDaysCount}
                                </h5>
                                <label>60 Days</label>
                              </div>
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.selectedTab === "tbri-boards"
                                    ? this.state.boardsCreatedData
                                        ?.nintyDaysCount
                                    : this.state.chartsCreatedData
                                        ?.nintyDaysCount}
                                </h5>
                                <label>90 Days</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="col-xl-9 col-md-8">
                  <div className="reports-right-block">
                    <div className="d-flex row flex-wrap client-list-filter-block mt-1">
                      <div className="col clients_dropdown">
                        <div className="d-flex">
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="start"
                            date={this.state.start_date}
                            onChangeHandler={this.changeFromDate}
                            min={
                              this.state.userData &&
                              this.state.userData?.created_at
                                ? moment(
                                    this.state.userData?.created_at
                                  ).format("YYYY-MM-DD")
                                : null
                            }
                            max={this.state.end_date}
                          />
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="end"
                            date={this.state.end_date}
                            onChangeHandler={this.changeFromDate}
                            min={this.state.start_date}
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 pt-1">
                    <div className="btn-items-card-wrapper tabs-card-wrapper">
                      <div className="btn-items-card card custom-card nav-tabs-card">
                        <div className="card-body">
                          <div className="d-flex">
                            <Nav
                              tabs
                              tbrentitytype={
                                constants.ACL.ENTITY_TYPE.NAVIGATION
                              }
                              className="custom-nav-tabs reports-nav-tabs"
                            >
                              {this.state.totalTabs.map((tab) => (
                                <NavItem key={tab.KEY}>
                                  <NavLink
                                    className={classnames({
                                      active:
                                        this.state.selectedTab === tab.KEY,
                                    })}
                                    onClick={() => {
                                      this.onContentChangeTypeHandler(tab.KEY);
                                    }}
                                  >
                                    {tab.TAB_TITLE}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TabContent activeTab={this.state.selectedTab}>
                      {this.state.totalTabs.map((tab) => (
                        <TabPane tabId={tab.KEY} key={tab.KEY}>
                          {/* <div className="custom-table-block user-detail-table nowrap-table-block sort-table-block center-th-align"> */}
                          <div className="custom-table-block first-col-fixed-block sort-table-block detail-panel-table-block nowrap-table-block client-list-table-block">
                            {this.state.selectedTab &&
                              this.state.selectedTab === tab.KEY && (
                                <div className="visualization-chevron_table">
                                  <DynamicTableComponentWithChevron
                                    columnData={
                                      this.state.selectedTab === "tbri-boards"
                                        ? tableHeader1
                                        : tableHeader2
                                    }
                                    rowData={this.state.currentData}
                                    pagePrevious={this.pagePrevious}
                                    pageNext={this.pageNext}
                                    pageSize={this.state.pageSize}
                                    pageNo={this.state.pageNo}
                                    handlePage={this.handlePage}
                                    handlePageSize={this.handlePageSize}
                                    count={this.state.count}
                                    handleSort={this.handleSort}
                                    sortColumn={this.state.sortColumn}
                                    sortOrder={this.state.sortOrder}
                                    handleInnerData={this.handleInnerData}
                                    colSpan={9}
                                    clickedValues={this.state.clickedValues}
                                    handleAccordianClicks={
                                      this.handleAccordianClicks
                                    }
                                    innerColumn={innerColumn}
                                    // showPagination={true}
                                    paginationClass={
                                      "center-pagination custom-pagination-new"
                                    }
                                  />
                                </div>
                              )}
                          </div>
                        </TabPane>
                      ))}
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <Dailog
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setUsers: (allUsers) => {
      dispatch(users(allUsers));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
    user: state.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsTbriPage);
