import React, { useState, useEffect } from "react";
import { openPreference } from "../Store/Actions/User";
import { connect } from "react-redux";
import PreferenceCenterSidebar from "../Containers/PreferenceCenterSidebar";
import PreferenceCenterContent from "../Containers/PreferenceCenterContent";
import { PreferenceServices } from "../Services/PreferenceServices";
import Toast from "../Utils/Toast";
import constants from "../Utils/constants";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

const notificationTabs = [
  {
    name: "Companies",
    slug: "company",
    icon: "business",
    is_selected: true,
    is_checked: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Projects",
    slug: "project",
    icon: "source",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Benchmarks",
    slug: "benchmark",
    icon: "speed",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Special Reports",
    slug: "special_report",
    icon: "summarize",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Market Forecasts",
    slug: "market_forecast",
    icon: "trending_up",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Market Landscapes",
    slug: "market_landscape",
    icon: "analytics",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Customer Studies",
    slug: "customer_study",
    icon: "portrait",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  {
    name: "Ecosystems",
    slug: "ecosystem",
    icon: "public",
    is_selected: false,
    checkBoxes: [
      {
        name: "Content Submitted for review",
        slug: "submit",
        isSelected: false,
      },
      {
        name: "Content Re-Submitted for review",
        slug: "resubmit",
        isSelected: false,
      },
      { name: "Content approved", slug: "approve", isSelected: false },
      { name: "Content rejected", slug: "reject", isSelected: false },
      {
        name: "Content review to published",
        slug: "published",
        isSelected: false,
      },
    ],
  },
  // {
  //   id: 7,
  //   name: "My Boards",
  //   icon: "leaderboard",
  //   is_selected: false,
  //   checkBoxes: [
  //     {
  //       id: 11,
  //       name: "Content Submitted for review",
  //       slug: "",
  //       isSelected: false,
  //     },
  //     { id: 12, name: "Content approved", slug: "", isSelected: false },
  //     { id: 13, name: "Content rejected", slug: "", isSelected: false },
  //     {
  //       id: 14,
  //       name: "Project content review to published",
  //       slug: "",
  //       isSelected: false,
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   name: "Account Settings",
  //   icon: "admin_panel_settings",
  //   is_selected: false,
  //   checkBoxes: [
  //     {
  //       id: 11,
  //       name: "Content Submitted for review",
  //       slug: "",
  //       isSelected: false,
  //     },
  //     { id: 12, name: "Content approved", slug: "", isSelected: false },
  //     { id: 13, name: "Content rejected", slug: "", isSelected: false },
  //     {
  //       id: 14,
  //       name: "Project content review to published",
  //       slug: "",
  //       isSelected: false,
  //     },
  //   ],
  // },
];

const PreferenceCenter = (props) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(document.location.search);

  const [notificationItems, setNotificationItems] = useState([]);
  const [selectedBox, setSelectedBox] = useState("company");
  const [frequencyType, setFrequencyType] = useState("immediate");
  const [frequency, setFrequency] = useState(0); //1-daily, 7-weekly, 0-none
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

  useEffect(() => {
    if (searchParams.get("unsubscribe") === "true") {
      setShowUnsubscribeModal(true);
    }
  }, []);

  // get initial data
  useEffect(() => {
    getPreferenceData();
  }, [props.home.openPreference]);

  // get preferece data
  const getPreferenceData = () => {
    PreferenceServices.getPreferenceDetails("all")
      .then((res) => {
        const result = res.data.data;
        if (result?.preferences?.length) {
          result.preferences.forEach((preference) => {
            const tab = notificationTabs.find(
              (t) => t.slug === preference.relation
            );
            if (tab) {
              tab.checkBoxes.forEach((checkBox) => {
                switch (checkBox.slug) {
                  case "submit":
                    checkBox.isSelected = preference.submit === 1;
                    break;
                  case "resubmit":
                    checkBox.isSelected = preference.resubmit === 1;
                    break;
                  case "approve":
                    checkBox.isSelected = preference.approve === 1;
                    break;
                  case "reject":
                    checkBox.isSelected = preference.reject === 1;
                    break;
                  case "published":
                    checkBox.isSelected = preference.published === 1;
                    break;
                  default:
                    break;
                }
              });
            }
          });
        }
        setNotificationItems(notificationTabs);
        setFrequencyType(result.type);
        setFrequency(result.frequency);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  // select all checkbox at a time
  const selectAllCheckBoxes = (type) => {
    const array = [];
    let notificationObj = notificationItems.find((item) => item.slug === type);
    let objectId = notificationObj.slug;
    let newCheckBoxes = notificationObj.checkBoxes;
    notificationObj.is_checked = !notificationObj.is_checked;
    newCheckBoxes.map((item) =>
      notificationObj.is_checked
        ? (item.isSelected = true)
        : (item.isSelected = false)
    );
    notificationItems.forEach((item, index) => {
      if (item.slug === objectId) array.push(notificationObj);
      else array.push(notificationItems[index]);
    });
    setNotificationItems(array);
  };

  // store data on save feature
  const saveUserPreferences = () => {
    const packet = {
      type: frequencyType,
      frequency: frequencyType === "immediate" ? 0 : frequency,
      preferences: convertToPreferences(),
    };
    updatePreferences(packet);
  };

  const updatePreferences = (packet) => {
    PreferenceServices.addPreference(packet)
      .then((res) => {
        Toast(res.data.message, "success");
        getPreferenceData();
        hideUnsubscribeModal();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  function convertToPreferences() {
    const preferences = notificationTabs.map((tab) => {
      let object = {
        relation: tab.slug,
        enabled: 1,
      };
      tab.checkBoxes.map((d) => (object[d.slug] = d.isSelected));
      return object;
    });

    return preferences;
  }

  const resetPreferences = () => {
    const packet = {
      type: "immediate",
      frequency: 0,
      preferences: notificationTabs.map((tab) => {
        let object = {
          relation: tab.slug,
          enabled: 1,
        };
        tab.checkBoxes.map((d) => (object[d.slug] = false));
        return object;
      }),
    };
    updatePreferences(packet);
  };

  const clearTheNotifications = () => {
    getPreferenceData();
  };

  // select one checkbox at a time
  const handleCheckbox = (contentSlug, subContentSlug) => {
    const array = [];
    let selectAll = false;
    let notificationObj = notificationItems.find(
      (item) => item.slug === contentSlug
    );
    let newCheckBoxes = notificationObj.checkBoxes;
    let count = 0;
    newCheckBoxes.map((item) => {
      if (item.slug === subContentSlug) {
        item.isSelected = !item.isSelected;
      }
      if (item.isSelected) count++;
      else count--;

      if (count === 5) {
        selectAll = true;
      }
    });
    if (selectAll) notificationObj.is_checked = !notificationObj.is_checked;
    else notificationObj.is_checked = false;

    notificationItems.forEach((item, index) => {
      if (item.slug === contentSlug) array.push(notificationObj);
      else array.push(notificationItems[index]);
    });
    setNotificationItems(array);
  };

  // highlight the left menu items on scroll
  const mouseScroll = (slug) => {
    let modifiedNotificationList = [];
    notificationItems.map((item) => {
      if (slug === item.slug) {
        item.is_selected = true;
        modifiedNotificationList.push(item);
      } else {
        item.is_selected = false;
        modifiedNotificationList.push(item);
      }
    });
    setNotificationItems(modifiedNotificationList);
    setSelectedBox(slug);
  };

  // select the sidebar item makes blue
  const selectedNotificationItem = (slug, id, name) => {
    let modifiedNotificationList = [];
    let elementID = `${name}-${id}`;
    let element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    notificationItems.map((item) => {
      if (slug === item.slug) {
        item.is_selected = true;
        modifiedNotificationList.push(item);
      } else {
        item.is_selected = false;
        modifiedNotificationList.push(item);
      }
    });
    setNotificationItems(modifiedNotificationList);
    setSelectedBox(slug);
  };

  const hideUnsubscribeModal = () => {
    setShowUnsubscribeModal(false);
    history.push(window.location.pathname);
  };

  return (
    <div className="preference-container">
      <PreferenceCenterSidebar
        notificationItems={notificationItems}
        selectedNotificationItem={selectedNotificationItem}
      />
      <PreferenceCenterContent
        notificationItems={notificationItems}
        selectAllCheckBoxes={selectAllCheckBoxes}
        handleCheckbox={handleCheckbox}
        selectedBox={selectedBox}
        frequencyType={frequencyType}
        setFrequencyType={setFrequencyType}
        frequency={frequency}
        setFrequency={setFrequency}
        saveUserPreferences={saveUserPreferences}
        mouseScroll={mouseScroll}
        clearTheNotifications={clearTheNotifications}
      />

      {/* unsubscribe modal */}
      <Modal
        isOpen={showUnsubscribeModal}
        className={"modal-sm modal-w-header custom-modal primary-modal"}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ModalHeader>Warning</ModalHeader>
          <span
            onClick={hideUnsubscribeModal}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
        <ModalBody>
          <div className="pref-modal-content">
            <div
              style={{
                backgroundColor: "#fff3cd",
                border: "1px solid #ffeeba",
                borderRadius: "0.25rem",
                padding: "0.55rem 1.15rem",
                maxWidth: "100%",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  maxWidth: "100%",
                  marginBottom: "0px",
                  color: "#856404",
                }}
              >
                Are you sure you want to Unsubscribe?
              </p>
            </div>
          </div>
        </ModalBody>
        <div className=" d-flex justify-content-center">
          <ModalFooter>
            <Button
              className="modal-btn btn-outline-primary"
              onClick={hideUnsubscribeModal}
            >
              Cancel
            </Button>
            <Button
              onClick={resetPreferences}
              className="modal-btn"
              type="submit"
              style={{
                backgroundColor: "#856404",
                color: "white",
                border: "1px solid #856404",
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPreference: (data) => {
      dispatch(openPreference(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceCenter);
