import { market_forecast_details as DETAILS } from '../actionConstants'

const marketForecastCategories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.MARKET_FORECAST_CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const marketForecastSegments = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_FORECAST_SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const marketForecastDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_FORECAST_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const marketForecastContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_FORECAST_CONTENT:
      return action.payload
    default:
      return state
  }
}

const marketForecastTemplates = (state = [], action) => {
  switch (action.type) {
    case DETAILS.MARKET_FORECAST_TEMPLATES:
      return action.payload
    default:
      return state
  }
}

const marketForecastTransactionDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_FORECAST_TRANSACTION_DETAILS:
      return action.payload
    default:
      return state
  }
}

export {
  marketForecastCategories,
  marketForecastSegments,
  marketForecastDetails,
  categories,
  marketForecastContent,
  marketForecastTemplates,
  marketForecastTransactionDetails
}
