import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  NavLink,
} from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomPPTUploader from "../Components/CustomPPTUploader";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import DailogNew from "../Components/DailogNew";
import DynamicTableWithSelectAllComponentNew from "../Components/DynamicTableWithSelectAllNew";
import Notification from "../Components/Notification";
import Search from "../Components/Search";
import permissionCheck from "../lib/permissionCheck";
import { ClientServices } from "../Services/Client";
import { clientRoles, clients } from "../Store/Actions/Client";
import { activeMenuTab } from "../Store/Actions/User";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import IsTBRIClient from "../Utils/IsTBRIClient";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import ListClientUploadedUserStatus from "./ListClientUploadedUserStatus.jsx";
import ListUserSelectedStatus from "./ListUserSelectedStatus";
import decodeJWT from "../lib/decodeJWT";
import { UserServices } from "../Services/User";
import Copy from "../Utils/Copy";

const tableHeader = [
  {
    id: "first_name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "role",
    title: "Role",
    sortable: false,
    headerStyle: { textAlign: "left", minWidth: "80px" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "client",
    title: "Client",
    sortable: false,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "80px",
    },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "last_login_at",
    title: "Last Login At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", whiteSpace: "nowrap" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    visibility: true,
  },
  {
    id: "updated_by",
    title: "Updated By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", whiteSpace: "nowrap" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Verified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", whiteSpace: "nowrap" },
    visibility: true,
  },
  {
    id: "inactiveReason",
    title: "Inactive Reason",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", whiteSpace: "nowrap" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerClass: "text-center",
    rowStyle: { minWidth: "95px", whiteSpace: "nowrap", textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;
class AssignedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [
        { id: 3, name: "All Status", is_selected: true },
        { id: 1, name: "Active", is_selected: false },
        { id: 0, name: "InActive", is_selected: false },
        { id: 2, name: "Pending", is_selected: false },
      ],
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      clientId: null,
      statusId: 3,
      documentModal: false,
      isFormSubmitted: false,
      formErrors: {},
      apiError: null,
      uploaded: false,
      totalUploadedRowsCount: 0,
      uploadedApiResponse: [],
      isSelectAll: false,
      selectedValues: [],
      check_send: false,
      bulkUsersResult: [],
      bulkModal: false,
      selectReason: "User Requested",
      currentPageDataCount: 0,
      isExistingUsers: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.HEADER_TITLE);
    let clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : null;
    this.setState(
      {
        clientId: clientId,
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE
    );
    ClientServices.getAssignedClients("/getAssignedClients")
      .then((res) => {
        let clients = [];
        clients.push({ id: "all", name: "All Clients" });
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        let totalClients = clients.concat(response);
        totalClients.forEach((r) => {
          r.is_selected = String(r.id) === clientId ? true : false;
        });
        let clientid = totalClients.find((c) => c.is_selected === true)
          ? totalClients.find((c) => c.is_selected === true).id
          : "all";
        this.setState({
          clientId: clientid,
          statusId: 3,
        });
        this.props.setClients(totalClients);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
        selectedValues: [],
        isSelectAll: false,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
        selectedValues: [],
        isSelectAll: false,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState(
      { pageNo: input, selectedValues: [], isSelectAll: false },
      () => this.getTableData()
    );
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
            <div className="custom-select-new custom-select-dropdown box-shadow-none d-inline-block mt-3">
              <select onChange={this.handleReasonchange}>
                <option value="1" selected>
                  User Requested
                </option>
                <option value="2">Email bounced</option>
                <option value="3">ClearOut Invalid</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_USER.ACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_user" : "activate_user",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  handleReasonchange = (e) => {
    let value = e.target.value;
    let reason;
    switch (value) {
      case "1":
        reason = "User Requested";
        break;
      case "2":
        reason = "Email bounced";
        break;
      case "3":
        reason = "ClearOut Invalid";
        break;
    }
    this.setState({
      selectReason: reason,
    });
  };
  removeClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove User</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_USER.REMOVE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_user", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeBulkClientUserConfirmationHandler = (e) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Users</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_USER.REMOVE_BULK_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_bulk_user" },
      dailogModalStyleType: "danger-modal",
    });
  };
  bulkUserEmailBounceValidationConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          User Email Validation
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to validate user(s)?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "bulk_user_email_bounce_validation" },
      dailogModalStyleType: "success-modal",
    });
  };

  clientUserBounceValidationConfirmationHandler = (data) => {
    ClientServices.getSingleUserBounceValidation(data.email, data.id)
      .then((res) => {
        if (res.data.status.toLowerCase().trim() === "invalid") {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">User Invalid</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: "de-activate_user", id: data.id },
            dailogModalStyleType: "danger-modal",
            selectReason: "clear Out",
          });
        } else if (
          res.data.status.toLowerCase() === "catch_all" ||
          res.data.status.toLowerCase().trim() === "catchall"
        ) {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Verify Email</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="inactive-body">
                <h2 className="font-weight-semibold mb-0">
                  Please Verify User Email Manually
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "inactive-modal",
          });
        } else {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Success</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  User Email is Valid
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "success-modal",
          });
        }
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  removeClientUser = (clientUserId) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.removeClientUser(arr)
      .then((res) => {
        Toast(constants.CLIENT_USER.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleClientUser = (clientUserId, isActive) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.toggleClientUser(isActive, {
      userIds: arr,
      reason: isActive === 1 ? "" : this.state.selectReason,
    })
      .then((res) => {
        Toast(
          isActive
            ? constants.CLIENT_USER.ACTIVATE.SUCCESS
            : constants.CLIENT_USER.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      isExistingUsers: false,
    });
    switch (config.type) {
      case "remove_user":
        this.removeClientUser(config.id, 0);
        break;
      case "de-activate_user":
        this.toggleClientUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleClientUser(config.id, 1);
        break;
      case "resend_bulk_welcomemails":
        this.resendBulkWelcomeEmail();
        break;
      case "de-activate_bulk_user":
        this.toggleBulkUsers();
        break;
      case "activate_bulk_user":
        this.toggleBulkUsers();
        break;
      case "remove_bulk_user":
        this.deleteBulkUsers();
        break;

      case "bulk_user_email_bounce_validation":
        this.bulkEmailValidation();
        break;
      case "reset_password":
        this.sendResetPasswordLink(config.data);
        break;

      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      isExistingUsers: false,
    });
  };

  onCheckboxBtnClick = (e, row) => {
    const index = this.state.selectedValues
      .map((val) => val.id)
      .indexOf(row.id);
    if (index < 0) {
      this.state.selectedValues.push({ id: row.id });
    } else {
      this.state.selectedValues.splice(index, 1);
    }
    this.setState({
      ...this.state,
      isSelectAll:
        this.state.rowData.length === this.state.selectedValues.length
          ? true
          : false,
      selectedValues: [...this.state.selectedValues],
    });
  };
  handleSelectAll = (e) => {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        isSelectAll: !this.state.isSelectAll,
        selectedValues: this.state.rowData.map((val) => ({
          id: val.id,
        })),
      });
    } else {
      this.setState({
        ...this.state,
        isSelectAll: !this.state.isSelectAll,
        selectedValues: [],
      });
    }
  };
  sendResetPasswordLinkConfirmation = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Reset Password Link</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to send reset password link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_password", data: data },
      dailogModalStyleType: "success-modal",
    });
  };
  getActions = (data) => {
    return (
      <>
        {data.is_active === 1 && (
          <>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
              action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST}
            >
              <button
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .VALIDATE_USER_TOOL_TIP
                )}-${data.id}`}
                onClick={(e) => {
                  this.rowAction(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST.VALIDATE_USERS,
                    data,
                    e
                  );
                }}
                className={
                  "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                }
                data-tip={
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .VALIDATE_USER_TOOL_TIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-outlined text-blue-clr icon-fs-20">
                  email
                </span>
              </button>
            </AclAction>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
              action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST}
            >
              <button
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                className={
                  "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                }
                onClick={(e) => this.rowAction("reset_password", data, e)}
                data-for={"tool-tip-resetpassword"}
                data-tip={"Send Reset Password Link"}
              >
                <span className="material-icons-outlined text-blue-clr icon-fs-20">
                  {" "}
                  lock_reset
                </span>
              </button>
            </AclAction>
          </>
        )}
        {data.is_active === 2 && (
          <>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
              action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.STATUS}
            >
              <button
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .RESEND_EMAIL_VERIFICATION_TOOLTIP
                )}-${data.id}`}
                onClick={(e) => {
                  this.rowAction(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                      .COPY_EMAIL_VERIFICATION_TOOLTIP,
                    data,
                    e
                  );
                }}
                className={
                  "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                }
                data-tip={
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .COPY_EMAIL_VERIFICATION_TOOLTIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-round text-blue-clr icon-fs-20">
                  add_link
                </span>
              </button>
            </AclAction>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
              action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.STATUS}
            >
              <button
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .RESEND_EMAIL_VERIFICATION_TOOLTIP
                )}-${data.id}`}
                onClick={(e) => {
                  this.rowAction(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                      .RESEND_EMAIL_VERIFICATION,
                    data,
                    e
                  );
                }}
                className={`dynamicTableActionButton btn btn-md d-inline-flex align-items-center`}
                data-tip={
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .RESEND_EMAIL_VERIFICATION_TOOLTIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-outlined text-blue-clr icon-fs-20">
                  send
                </span>
              </button>
            </AclAction>
          </>
        )}
        {data.is_active === 0 && (
          <AclAction
            type={constants.ACL.PERMISSION_KEY}
            entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
            action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST}
          >
            <button
              tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
              onClick={(e) => this.rowAction("reset_password", data, e)}
              data-for={"tool-tip-resetpassword"}
              data-tip={"Send Reset Password Link"}
            >
              <span className="material-icons-outlined text-blue-clr icon-fs-20">
                {" "}
                lock_reset
              </span>
            </button>
          </AclAction>
        )}
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.changeRoute();
    let url = `/getAssignedUsers/${this.state.clientId}/${this.state.statusId}?page=${this.state.pageNo}&page_size=${this.state.pageSize}${queryParams}`;
    UserServices.getAssignedUsers(url)
      .then((res) => {
        let clientData = res.data.data;
        this.setState({
          currentPageDataCount: clientData.length,
        });
        let usercolor = ["#606B77", "#046CD9", "#FF5A5A"];
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        let checkContentPageAccess = permissionCheck(
          constants.ACL.PERMISSION_KEY,
          constants.ACL.CLIENT_USER_RESOURCE.TITLE,
          constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.EDIT
        );
        clientData = clientData.map((data) => {
          return {
            check_send: data.is_active === 2 ? true : false,
            id: data.id,
            first_name: (
              <NavLink
                tag={RRNavLink}
                style={{
                  padding: 0,
                  color: usercolor[data.is_active],
                  cursor: "pointer",
                }}
                exact
                to={formatRoute(
                  constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE,
                  { clientId: data.client.id, id: data.id }
                )}
              >
                <label className={`mb-0`}>{`${
                  data.first_name ? data.first_name : ""
                } ${data.last_name ? data.last_name : ""}`}</label>
              </NavLink>
            ),
            email: (
              <span style={{ color: usercolor[data.is_active] }}>
                {data.email}
              </span>
            ),
            role: data.role.name,
            client: data.client.name,
            created_by:
              data.created_by_user && data.created_by_user.id
                ? `${
                    data.created_by_user.first_name
                      ? data.created_by_user.first_name
                      : ""
                  } ${
                    data.created_by_user.last_name
                      ? data.created_by_user.last_name
                      : ""
                  }`
                : "-",
            updated_by:
              data.updated_by_user && data.updated_by_user.id
                ? `${
                    data.updated_by_user.first_name
                      ? data.updated_by_user.first_name
                      : ""
                  } ${
                    data.updated_by_user.last_name
                      ? data.updated_by_user.last_name
                      : ""
                  }`
                : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            last_login_at: data.last_login_datetime
              ? ComapnyDetailsDateTimeFormatter(data.last_login_datetime, false)
              : "-",
            inactiveReason: data.inactive_reason ? data.inactive_reason : "-",
            is_active:
              data.is_active === 0 || data.is_active === 1 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
                  action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.STATUS}
                >
                  <div className="custom-control custom-switch success-toggle">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customSwitch${data.id}`}
                      checked={data.is_active === 1}
                      onChange={(e) => {
                        e.stopPropagation();
                        this.rowAction(
                          constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS,
                          data,
                          e
                        );
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`customSwitch${data.id}`}
                    ></label>
                  </div>
                </AclAction>
              ) : null,
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: clientData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState(
      { pageSize: e.target.value, pageNo: 1, isSelectAll: false },
      () => {
        this.getTableData();
      }
    );
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  resendBulkWelcomeEmail = (e) => {
    ClientServices.resendEmailVerification(this.state.selectedValues)
      .then((res) => {
        this.setState({
          isSelectAll: false,
          bulkUsersResult: res,
          bulkModal: true,
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  deleteBulkUsers = () => {
    ClientServices.removeClientUser(this.state.selectedValues)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                Deleted User(s) Successfully
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
        });
        this.setState({
          isSelectAll: false,
          selectedValues: [],
        });
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleBulkUsers = (e) => {
    let isActive = this.state.statusId == 0 ? 1 : 0;
    ClientServices.toggleClientUser(isActive, {
      userIds: this.state.selectedValues,
    })
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                {isActive
                  ? constants.CLIENT_USER.BULK_ACTIVATE.SUCCESS
                  : constants.CLIENT_USER.BULK_IN_ACTIVATE.SUCCESS}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
          isSelectAll: false,
          selectedValues: [],
        });

        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggleBulkUsersConfirmation = (e) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {this.state.statusId === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {this.state.statusId === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              Are you sure want to De-Activate user(s)?
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              Are you sure want to Activate user(s)?
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          this.state.statusId === 1
            ? "de-activate_bulk_user"
            : "activate_bulk_user",
      },
      dailogModalStyleType:
        this.state.statusId === 1 ? "danger-modal" : "success-modal",
    });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.VALIDATE_USERS:
        this.clientUserBounceValidationConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE,
            { clientId: data.client.id, id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS:
        this.toggleClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER:
        this.removeClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .RESEND_EMAIL_VERIFICATION:
        this.resendEmailVerification(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .COPY_EMAIL_VERIFICATION_TOOLTIP:
        this.copyEmailVerificationLink(data);
        break;
      case "reset_password":
        this.sendResetPasswordLinkConfirmation(data);
        break;
      default:
        break;
    }
  };

  copyEmailVerificationLink = (user) => {
    ClientServices.copyEmailVerification({ user_id: user.id })
      .then((res) => {
        const link = res.data.verify_url;
        Copy(link);
        Toast("Copied!", "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  sendResetPasswordLink(data) {
    let body = { email: data.email, is_client: true };
    UserServices.sendverificationmailForPasswordSetting(body)
      .then((data) => {
        Toast(data.data.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  addClient = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.ROUTE,
        { clientId: this.state.clientId }
      ),
    });
  };
  resendEmailVerification = (data) => {
    let arr = [];
    arr.push({ id: data.id });
    ClientServices.resendEmailVerification(arr)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                Verification mail has been sent
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true).id
      : "all";
    this.setState(
      {
        clientId: clientId,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };
  handleStatusChange = (e) => {
    this.state.status.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    let statusId = this.state.status.find((c) => c.is_selected === true).id;
    this.setState(
      {
        statusId: statusId,
        isSelectAll: false,
        selectedValues: [],
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    if (this.props.selectedTab && this.props.selectedTab !== null) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
        { clientId: this.state.clientId }
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT.LIST.NAME,
        link: constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  handleClientUserTemplateDownload = () => {
    ClientServices.getClientUserStandardTemplate()
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "ClientUsersStandardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleClientUserTemplateUpload = () => {
    this.setState({
      documentModal: true,
      isExistingUsers: false,
    });
  };

  closeDocumentModal = () => {
    this.setState({
      documentModal: false,
      doc: {},
      formErrors: {},
      isFormSubmitted: false,
      uploaded: false,
      totalUploadedRowsCount: 0,
      uploadedApiResponse: [],
      bulkModal: false,
      isExistingUsers: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.isFormValid()) {
      if (
        this.state.doc &&
        this.state.doc.content &&
        this.state.doc.content.length
      ) {
        this.setState(
          {
            isFormSubmitted: true,
          },
          () => {
            //make an api call here

            ClientServices.uploadBulkClientUsers({
              content: this.state.doc.content,
              isExistingUsers: this.state.isExistingUsers,
            })
              .then((res) => {
                this.setState({
                  uploaded: true,
                  totalUploadedRowsCount: this.state.doc.content.length,
                  uploadedApiResponse: res.data.errors ? res.data.errors : [],
                });
                this.getTableData();
              })
              .catch((error) => {
                this.setState({
                  isFormSubmitted: false,
                });
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          }
        );
      } else {
        Toast("No data available in the file.", "warning");
      }
    }
  };

  onChangeHandlerFile = (name, clientUsers) => {
    let content = [];
    if (clientUsers) {
      clientUsers.forEach((clientUser) => {
        if (
          clientUser["First Name"] &&
          clientUser["Last Name"] &&
          clientUser.Email &&
          clientUser.Role
        ) {
          let obj = {
            first_name: clientUser["First Name"],
            last_name: clientUser["Last Name"],
            client_name: clientUser["Client"],
            email: clientUser.Email,
            mobile: clientUser["Contact No."],
            role_name: clientUser.Role,
            address: clientUser.Address,
          };
          content.push(obj);
        }
      });
    }
    this.setState({
      doc: { name, content },
    });
  };
  closebulkModal = () => {
    this.setState({
      bulkUsersResult: [],
      bulkModal: false,
      selectedValues: [],
      isSelectAll: false,
    });
  };

  isFormValid = () => {
    let formError = false;
    let docError = false;
    if (!this.state.doc || (this.state.doc && !this.state.doc.content)) {
      docError = true;
      formError = true;
    }
    this.setState({
      formErrors: {
        docError: docError,
      },
      apiError: null,
    });
    return formError;
  };

  bulkEmailValidation = () => {
    ClientServices.bulkEmailValidation(this.state.selectedValues)
      .then(() => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                User(s) Email Validated
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
          isSelectAll: false,
          selectedValues: [],
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  resendBulkWelcomeEmailConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Resend Welcome Mails
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to Resend Welcome Mails?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "resend_bulk_welcomemails" },
      dailogModalStyleType: "success-modal",
    });
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    let dummyData = [
      {
        first_name: "Akhil",
        last_name: "Karankoti",
        role_name: "Lead",
        email: "abc@gmail.com",
        mobile: 670,
        address: "abc abc abc",
        client_name: "XYZ",
        comment: "Invalid Client Name",
      },
      {
        first_name: "Virat",
        last_name: "Kohli",
        role_name: "Lead",
        email: "abc@gmail.com",
        mobile: 670,
        address: "abc abc abc",
        client_name: "XYZ",
        comment: "Invalid Client Name",
      },
    ];
    return (
      <>
        <div className="row align-items-center flex-wrap-reverse">
          <div className="col-12 col-xl-8">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="custom-combobox-list">
                  <CustomDropdownList
                    data={clients}
                    handleChange={this.handleClientChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="w-100-search border-search search-box-shadow-2">
                  <Search
                    handleSearch={this.handleSearch}
                    handleInput={this.handleInput}
                    value={this.state.name}
                    placeHolder={"Filter Name / Email"}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="custom-combobox-list">
                  <CustomDropdownList
                    data={this.state.status}
                    handleChange={this.handleStatusChange}
                    placeholder={"Status"}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.statusId != 3 && (
            <div className="col-xl-auto ml-auto">
              <div className="d-flex justify-content-end align-items-center">
                {this.state.statusId == 0 ? (
                  <button
                    className={
                      "btn btn-custom btn-primary icon-text-btn title-btn mr-2 mb-18px white-space-nowrap"
                    }
                    type="button"
                    disabled={this.state.selectedValues.length === 0}
                    onClick={() => this.toggleBulkUsersConfirmation()}
                  >
                    {" "}
                    Mark as Active
                  </button>
                ) : this.state.statusId == 1 ? (
                  <>
                    <button
                      className={
                        "btn btn-custom btn-primary icon-text-btn title-btn mr-2 mb-18px white-space-nowrap"
                      }
                      type="button"
                      disabled={this.state.selectedValues.length === 0}
                      onClick={() => this.toggleBulkUsersConfirmation()}
                      style={{ marginRight: 5 }}
                    >
                      {" "}
                      Mark as In-Active
                    </button>

                    <AclAction
                      type={constants.ACL.PERMISSION_KEY}
                      entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
                      action={constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST}
                    >
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn mb-18px white-space-nowrap"
                        }
                        type="button"
                        disabled={this.state.selectedValues.length === 0}
                        onClick={(e) =>
                          this.bulkUserEmailBounceValidationConfirmation(e)
                        }
                      >
                        {" "}
                        User Email Bounce Validation
                      </button>
                    </AclAction>
                  </>
                ) : this.state.statusId == 2 ? (
                  <button
                    className={
                      "btn btn-custom btn-primary icon-text-btn title-btn mb-18px white-space-nowrap"
                    }
                    type="button"
                    disabled={this.state.selectedValues.length === 0}
                    onClick={(e) => this.resendBulkWelcomeEmailConfirmation(e)}
                  >
                    {" "}
                    Resend Welcome Email
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div className="col-xl-auto ml-auto">
            <div className="d-flex align-items-center flex-wrap justify-content-end">
              <div className="ml-auto">
                <aside className="d-flex justify-content-end">
                  {permissionCheck(
                    constants.ACL.PERMISSION_KEY,
                    constants.ACL.CLIENT_USER_RESOURCE.TITLE,
                    constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.DOWNLOAD
                  ) && (
                    <>
                      <button
                        className={
                          "btn btn-custom btn-info icon-text-btn title-btn mr-2 d-inline-flex align-items-center mb-3 text-nowrap"
                        }
                        type="button"
                        onClick={this.handleClientUserTemplateDownload}
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          download
                        </span>
                        {" " +
                          constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                            .MULTIPLE_USERS_TEMPLATE}
                      </button>
                    </>
                  )}
                  <button
                    className={
                      "btn btn-custom btn-primary icon-text-btn title-btn mr-2 d-inline-flex align-items-center mb-3 text-nowrap"
                    }
                    type="button"
                    onClick={this.handleClientUserTemplateUpload}
                  >
                    <span className="material-icons-round icon-fs-16 mr-1">
                      upload
                    </span>
                    {" " +
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .MULTIPLE_USERS}
                  </button>
                  <button
                    className={
                      "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3 text-nowrap"
                    }
                    type="button"
                    onClick={this.addClient}
                  >
                    <span className="material-icons-round icon-fs-16 mr-1">
                      add
                    </span>
                    {" " +
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .ADD_BUTTON_TITLE}
                  </button>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <div className="comapnay_tbl">
          <div className="colored-th-table-block custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
            <DynamicTableWithSelectAllComponentNew
              columnData={tableHeader}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              count={this.state.count}
              rowAction={this.rowAction}
              colSpan={tableHeader.filter((h) => h.visibility).length}
              showActions={showActions}
              isSelectAll={this.state.isSelectAll}
              selectedValues={this.state.selectedValues}
              handleSelectAll={this.handleSelectAll}
              handleCheckBoxButtonClick={this.onCheckboxBtnClick}
              statusId={this.state.statusId}
              showPagination={true}
              paginationClass={"center-pagination custom-pagination-new"}
              handlePageSize={this.handlePageSize}
              checkboxpermission={true}
            />
          </div>
        </div>

        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .VALIDATE_USER_TOOL_TIP
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={"tool-tip-resetpassword"}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .RESEND_EMAIL_VERIFICATION_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}

        <Modal
          isOpen={this.state.documentModal}
          toggle={this.closeDocumentModal}
          className={`modal-md modal-w-header custom-modal primary-modal ${
            this.state.uploaded ? "uploaded_Status_table" : ""
          }`}
        >
          {!this.state.uploaded ? (
            <form
              className={`col-lg-12 col-sm-12 form-wrapper p-0`}
              onSubmit={this.handleSubmit}
            >
              <ModalHeader toggle={this.closeDocumentModal}>
                Upload Users
              </ModalHeader>
              <ModalBody
                className={`${
                  this.state.isFormSubmitted && !this.state.formErrors.docError
                    ? "upload-loading-indicator"
                    : ""
                }`}
              >
                <div className="container-fluid">
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                </div>
                {this.state.documentModal && (
                  <div className={"row"}>
                    <div className="col-sm-12 form-group position-relative">
                      <label htmlFor="description">
                        Document <span className={"mandatory"}>* </span>
                      </label>
                      <div>
                        <input
                          type="checkbox"
                          className="custom-checkbox-new"
                          checked={this.state.isExistingUsers}
                          onChange={(e) => {
                            this.setState({
                              isExistingUsers: !this.state.isExistingUsers,
                            });
                          }}
                        />
                        <label className="existing-user">
                          Add existing users
                        </label>
                      </div>
                      <CustomPPTUploader
                        value={this.state.doc}
                        onChangeHandler={this.onChangeHandlerFile}
                        type={"excel"}
                        convertToJson={true}
                        isExistingUsers={true}
                      />
                      {this.state.formErrors.docError && (
                        <div className="error-msg clearfix">
                          <div className="empty-files">
                            {constants.FORM.MANDATORY_FIELD_FILE_UPLOAD}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 form-group position-relative">
                      <div className="file-restriction-message text-center">
                        Note: Only Excel files are allowed
                      </div>
                    </div>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  className="modal-btn btn-outline-primary"
                  onClick={this.closeDocumentModal}
                >
                  Cancel
                </Button>
                <Button className="modal-btn" color="primary" type="submit">
                  Upload
                </Button>{" "}
              </ModalFooter>
            </form>
          ) : (
            <>
              <ModalHeader toggle={this.closeDocumentModal}>
                Upload Users Status
              </ModalHeader>
              <ModalBody>
                <ListClientUploadedUserStatus
                  closeDocumentModal={this.closeDocumentModal}
                  totalUploadedRowsCount={this.state.totalUploadedRowsCount}
                  data={this.state.uploadedApiResponse}
                />
              </ModalBody>
            </>
          )}
        </Modal>
        {this.state.bulkModal ? (
          <Modal
            isOpen={this.state.bulkModal}
            toggle={this.closebulkModal}
            className={`modal-md modal-w-header custom-modal primary-modal`}
          >
            <ModalHeader toggle={this.closeDocumentModal}>
              Upload Users Status
            </ModalHeader>
            <ModalBody>
              <ListUserSelectedStatus
                closebulkModal={this.closebulkModal}
                data={this.state.bulkUsersResult.data.errors}
                count={this.state.selectedValues.length}
                type={"Resent Welcome Mails"}
              />
            </ModalBody>
          </Modal>
        ) : (
          ""
        )}

        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedUsers);
