import React from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomReactTooltip from "../Components/CustomReactTooltip";

export default class HelpModal extends React.Component {
  constructor() {
    super();
    this.state = {
      boardModal: false,
    };
  }
  setModelIsOpen = () => {
    this.setState({
      boardModal: true,
    });
  };
  closeBoardModal = () => {
    this.setState({
      boardModal: false,
    });
  };

  render() {
    return (
      <>
        <button
          className="icon-btn-primary icon-btn d-inline-flex align-items-center justify-content-center"
          data-for="help"
          data-tip="Help"
          onClick={this.setModelIsOpen}
        >
          <span className="material-icons-outlined icon">help_outline</span>
          <CustomReactTooltip id="help" multiline={true} />
        </button>
        <Modal
          isOpen={this.state.boardModal}
          toggle={this.closeBoardModal}
          // onOpened={this.loadData}
          className={"modal-lg modal-w-header custom-modal"}
        >
          <ModalHeader toggle={this.closeBoardModal}>
            Email Bounce List - Help
          </ModalHeader>
          <ModalBody>
            <div className="">
              <div className="row-item">
                <Label className="title-label">
                  <strong>Status</strong>
                </Label>
                <p style={{ fontSize: "0.9rem" }}>
                  After the successful email verification, every email address
                  status is primarily categorized as either Invalid, Unknown or
                  CatchAll
                </p>
              </div>
            </div>
            <table className="table table-striped help-emailbounce">
              <thead>
                <tr>
                  <th scope="col">Value</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Invalid</td>
                  <td>
                    Specified email address has been verified as a bad recipient
                    address that does not exist or is not accepting mail
                  </td>
                </tr>
                <tr>
                  <td>Catch All</td>
                  <td>
                    Specified email address accepts all emails and then simply
                    discards the mail or bounces it back to the sender
                  </td>
                </tr>
                <tr>
                  <td>Unknown</td>
                  <td>
                    Specified email address returns an unknown result as it was
                    unable to get a response from the recipient's mail server
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              className="modal-btn"
              color="secondary"
              onClick={this.closeBoardModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
