import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/benchmarkDetailsReference'

const benchmarkCategories = DETAILS_REF.benchmarkCategories
const benchmarkSegments = DETAILS_REF.benchmarkSegments
const benchmarkDetails = DETAILS_REF.benchmarkDetails
const categories = DETAILS_REF.categories
const benchmarkContent = DETAILS_REF.benchmarkContent
const benchmarkTemplates = DETAILS_REF.benchmarkTemplates
const benchmarkTransactionDetails = DETAILS_REF.benchmarkTransactionDetails

const benchmarkDetailsReducer = combineReducers({
  benchmarkCategories,
  benchmarkSegments,
  benchmarkDetails,
  categories,
  benchmarkContent,
  benchmarkTemplates,
  benchmarkTransactionDetails
})

export default benchmarkDetailsReducer
