import React, { Component } from "react";
import constants from "../Utils/constants";
import DynamicTableComponent from "./DynamicTableComponents";
import CustomPagination from "./Pagination";
const scrollTable = {
  maxHeight: "466px",
  overflowY: "scroll",
};
class DynamicTableComponentWithChevron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedData: {
        1: ["data1"],
        2: ["data1"],
        3: ["data1"],
        4: ["data1"],
      },
    };
  }
  totalPages = () => {
    if (this.props.count === 0) {
      return 1;
    }
    return parseInt(Math.ceil(this.props.count / this.props.pageSize));
  };
  getPagingRange = (current, { min = 1, total = 20, length = 5 } = {}) => {
    if (length > total) length = total;
    let start = current - Math.floor(length / 2);
    start = Math.max(start, min);
    start = Math.min(start, min + total - length);
    return Array.from({ length: length }, (el, i) => start + i);
  };
  entireRowAction = (row, e) => {
    if (this.props.entireRowAction && this.props.entireRowAction.title) {
      this.props.rowAction(this.props.entireRowAction.title, row, e);
    }
  };

  handlePageSize = (e) => {
    this.props.handlePageSize(e);
  };
  render() {
    return (
      <>
        <div
          className="responsive-table scroll-table-wrapper"
          style={this.props.pageSize == 25 ? scrollTable : {}}
        >
          <table
            className={` dataTable responsive ${
              this.props.className ? this.props.className : ""
            }`}
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr>
                {this.props.rowData.length > 0 && <th></th>}
                {this.props.columnData.map((column, key) =>
                  column.visibility ? (
                    <th
                      key={key}
                      className={`${
                        this.props.sortColumn === column.id
                          ? this.props.sortOrder
                          : column.sortable
                          ? "default"
                          : null
                      } ${column.headerClass ? column.headerClass : null}  ${
                        column.sortable ? "sort-column" : ""
                      }`}
                      onClick={() => {
                        column.sortable &&
                          this.props.handleSort(column.id, key);
                      }}
                      style={column.headerStyle}
                    >
                      <div className="th-content">{column.title}</div>
                    </th>
                  ) : null
                )}
              </tr>
            </thead>
            <tbody>
              {this.props.rowData.length > 0 &&
                this.props.rowData.map((row, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        onClick={(e) => {
                          this.entireRowAction(row, e);
                        }}
                        className={`${
                          this.props.clickedValues.includes(row.id)
                            ? "row-expand"
                            : "row-collapse"
                        }`}
                      >
                        <td key={index - 0}>
                          <span
                            className="material-icons-outlined expand-collapse-icon"
                            onClick={(e) =>
                              this.props.handleAccordianClicks(e, row)
                            }
                          >
                            {this.props.clickedValues.includes(row.id)
                              ? "arrow_drop_down"
                              : "arrow_right"}
                          </span>
                        </td>
                        {this.props.columnData.map((val, i) =>
                          val.visibility ? (
                            <td
                              key={`${index + "-" + i + 1}`}
                              style={val.rowStyle}
                              className={val.rowClass ? val.rowClass : null}
                            >
                              {row[val.id] || row[val.id] === 0
                                ? row[val.id]
                                : "-"}
                            </td>
                          ) : null
                        )}
                      </tr>
                      {this.props.clickedValues.length > 0 &&
                        this.props.clickedValues.includes(row.id) &&
                        row?.children && (
                          <>
                            <tr>
                              <td colSpan={7} className="pl-5">
                                <table className="w-100 accordion-table">
                                  <thead>
                                    <tr>
                                      {this.props.innerColumn?.map(
                                        (column, key) =>
                                          column.visibility ? (
                                            <th
                                              key={key}
                                              className={`${
                                                this.props.sortColumn ===
                                                column.id
                                                  ? this.props.sortOrder
                                                  : column.sortable
                                                  ? "default"
                                                  : null
                                              } ${
                                                column.headerClass
                                                  ? column.headerClass
                                                  : null
                                              }  ${
                                                column.sortable
                                                  ? "sort-column"
                                                  : ""
                                              }`}
                                              onClick={() => {
                                                column.sortable &&
                                                  this.props.handleSort(
                                                    column.id,
                                                    key
                                                  );
                                              }}
                                              style={column.headerStyle}
                                            >
                                              {column.title}
                                            </th>
                                          ) : null
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.clickedValues.length > 0 &&
                                      row?.children &&
                                      row?.children.length > 0 &&
                                      row.children?.map((data, index) => {
                                        return (
                                          <tr key={index}>
                                            {this.props.innerColumn.map(
                                              (val, i) => (
                                                <td
                                                  key={`${index + "-" + i + 1}`}
                                                  style={val.rowStyle}
                                                  className={
                                                    val.rowClass
                                                      ? val.rowClass
                                                      : null
                                                  }
                                                >
                                                  {data[val.id] ||
                                                  data[val.id] === 0
                                                    ? data[val.id]
                                                    : "-"}
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        );
                                      })}
                                    {row?.children.length === 0 && (
                                      <tr>
                                        <td
                                          className={`no-records`}
                                          colSpan={this.props.colSpan}
                                        >
                                          {
                                            constants.PAGINATION
                                              .NO_RECORDS_FOUND
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </>
                        )}
                    </>
                  );
                })}
              {this.props.rowData.length === 0 && (
                <tr>
                  <td className={`no-records`} colSpan={this.props.colSpan}>
                    {constants.PAGINATION.NO_RECORDS_FOUND}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CustomPagination
          handlePage={this.props.handlePage}
          totalPages={this.totalPages}
          handlePageSize={this.handlePageSize}
          pageSize={this.props.pageSize}
          pageNo={this.props.pageNo}
          className={`${this.props.paginationClass}`}
        />
      </>
    );
  }
}

export default DynamicTableComponentWithChevron;
