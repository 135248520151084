import React from 'react'
import ReactTooltip from 'react-tooltip'
const CustomReactTooltip = (props) => {
  ReactTooltip.rebuild()
  return (
    <ReactTooltip   {...props} effect="solid" className={`tooltip-custom-theme ml-1`} />
  )
}

export default CustomReactTooltip
