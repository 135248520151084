import { Axios } from "../../lib/axios";
export const ResourceAndFAQServices = {
  getResources,
  addResource,
  updateResource,
  updateResourceStatus,
  deleteResource,
  getFaqs,
  addFaq,
  updateFaq,
  updateFaqStatus,
  deleteFaq,
};

function getResources(url) {
  return Axios.get(url);
}

function addResource(input) {
  return Axios.post("/faq-resources/resources", input);
}

function updateResource(input, id) {
  return Axios.put(`/faq-resources/resources/${id}`, input);
}

function updateResourceStatus(input, id) {
  return Axios.patch(`/faq-resources/resources/${id}/status`, input);
}

function deleteResource(id) {
  return Axios.delete(`/faq-resources/resources/${id}`);
}

function getFaqs(url) {
  return Axios.get(url);
}

function addFaq(body) {
  return Axios.post("/faq-resources/faqs", body);
}

function updateFaq(input, id) {
  return Axios.put(`/faq-resources/faqs/${id}`, input);
}

function updateFaqStatus(input, id) {
  return Axios.patch(`/faq-resources/faqs/${id}/status`, input);
}

function deleteFaq(id) {
  return Axios.delete(`/faq-resources/faqs/${id}`);
}
