import React, { Component } from "react";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import Notification from "../Components/Notification";
import { connect } from "react-redux";
import { getCurrencies, segments } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import Toast from "../Utils/Toast";
import { getTbriUsers } from "../Store/Actions/User";
import DocumentTitle from "../Utils/DocumentTitle";
import TemplateRecordPicker from "./TemplateRecordPicker";
import { RuleServices } from "../Services/Rule";

const initialState = {
  selectedMetrics: [],
  currentEditingMetric: null,
  formErrors: {},
  dailogModal: false,
  dailogModalContent: "",
  dailogModalHeader: "",
  dailogModalConfig: {},
  dailogModalStyleType: "",
  isFormSubmitted: false,
  apiError: null,
  operatorsDropdown: [
    {
      id: "select",
      name: "Select",
      value: "select",
      is_selected: false,
    },
    {
      id: "Plus",
      name: "+",
      value: "+",
      is_selected: true,
    },
    {
      id: "Minus",
      name: "-",
      value: "-",
      is_selected: false,
    },
  ],
  formule: [
    {
      uid: Math.random(),
      type: "node",
      position: "LHS",
      value: null,
      details: {},
    },
    {
      uid: Math.random(),
      type: "node",
      position: "RHS",
      value: null,
      details: {},
    },
    {
      uid: Math.random(),
      type: "operator",
      position: "RHS",
      value: null,
      details: {},
    },
    {
      uid: Math.random(),
      type: "node",
      position: "RHS",
      value: null,
      details: {},
    },
  ],
  documentModal: false,
};

class ManageRule extends Component {
  state = {
    selectedMetrics: [],
    currentEditingMetric: null,
    formErrors: {},
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    isFormSubmitted: false,
    apiError: null,
    operatorsDropdown: [
      {
        id: "select",
        name: "Select",
        value: "select",
        is_selected: false,
      },
      {
        id: "Plus",
        name: "+",
        value: "+",
        is_selected: true,
      },
      {
        id: "Minus",
        name: "-",
        value: "-",
        is_selected: false,
      },
    ],
    formule: [
      {
        uid: Math.random(),
        type: "node",
        position: "LHS",
        value: null,
        details: {},
      },
      {
        uid: Math.random(),
        type: "node",
        position: "RHS",
        value: null,
        details: {},
      },
      {
        uid: Math.random(),
        type: "operator",
        position: "RHS",
        value: null,
        details: {},
      },
      {
        uid: Math.random(),
        type: "node",
        position: "RHS",
        value: null,
        details: {},
      },
    ],
    documentModal: false,
  };
  async componentDidMount() {
    let ruleId = this.props.match.params.id ? this.props.match.params.id : null;
    if (ruleId) this.prepopulateData(ruleId);
    DocumentTitle(
      ruleId === null
        ? constants.APPLICATION_ROUTE.METRIC_RULE.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.METRIC_RULE.EDIT.HEADER_TITLE
    );
  }

  getFormattedData = (data) => {
    return data.map((data) => {
      return {
        id: data.id,
        lob_name: data.lob ? data.lob.name : "N/A",
        lob_subcategory1_name: data.lob_subcategory1
          ? data.lob_subcategory1.name
          : "N/A",
        lob_subcategory2_name: data.lob_subcategory2
          ? data.lob_subcategory2.name
          : "N/A",
        lob_subcategory3_name: data.lob_subcategory3
          ? data.lob_subcategory3.name
          : "N/A",
        lob_subcategory4_name: data.lob_subcategory4
          ? data.lob_subcategory4.name
          : "N/A",
        lob_subcategory5_name: data.lob_subcategory5
          ? data.lob_subcategory5.name
          : "N/A",
        geo_name: data.geography ? data.geography.name : "N/A",
        geosubcategory_name: data.geosubcategory
          ? data.geosubcategory.name
          : "N/A",
        country_name: data.country ? data.country.name : "N/A",
        vertical_name: data.vertical ? data.vertical.name : "N/A",
        verticalcategory_name: data.verticalcategory
          ? data.verticalcategory.name
          : "N/A",
        verticalsubcategory_name: data.verticalsubcategory
          ? data.verticalsubcategory.name
          : "N/A",
        metrictype_name: data.metrictype ? data.metrictype.name : "N/A",
        metric_name: data.metric ? data.metric.name : "N/A",
        lob_group: [
          data.lob ? data.lob.name : null,
          data.lob_subcategory1 ? data.lob_subcategory1.name : null,
          data.lob_subcategory2 ? data.lob_subcategory2.name : null,
          data.lob_subcategory3 ? data.lob_subcategory3.name : null,
          data.lob_subcategory4 ? data.lob_subcategory4.name : null,
          data.lob_subcategory5 ? data.lob_subcategory5.name : null,
        ]
          .filter((n) => n)
          .join(" - "),
        geo_group: [
          data.geography ? data.geography.name : null,
          data.geosubcategory ? data.geosubcategory.name : null,
          data.country ? data.country.name : null,
        ]
          .filter((n) => n)
          .join(" - "),
        vertical_group: [
          data.vertical ? data.vertical.name : null,
          data.verticalcategory ? data.verticalcategory.name : null,
          data.verticalsubcategory ? data.verticalsubcategory.name : null,
        ]
          .filter((n) => n)
          .join(" - "),
      };
    });
  };

  prepopulateData = (ruleId) => {
    RuleServices.getRuleById(ruleId)
      .then((res) => {
        let newRhs = [];
        let lhs = res.data.lhs;
        let rhs = res.data.rhs.split("");
        let count = 1;
        let temp = 0;
        let newFormula = [];
        let newLhs = this.getFormattedData(
          res.data.templaterecords.filter((item) => item.id === parseInt(lhs))
        );
        this.state.selectedMetrics.push(parseInt(newLhs[0].id));
        newFormula.push({
          uid: Math.random(),
          type: "node",
          position: "LHS",
          value: newLhs[0].id,
          details: newLhs[0],
        });
        for (let i = 0; i < rhs.length; i++) {
          if (rhs[i].toString() === "+" || rhs[i].toString() === "-") {
            newRhs.push(temp.replace(/^0+/, ""));
            newRhs.push(rhs[i]);
            count = 1;
            temp = 0;
          } else {
            temp = temp * count + rhs[i];
          }
          if (i === rhs.length - 1) {
            newRhs.push(temp.replace(/^0+/, ""));
          }
        }
        for (let i = 0; i < newRhs.length; i++) {
          if (!(newRhs[i].toString() === "+" || newRhs[i].toString() === "-")) {
            this.state.selectedMetrics.push(parseInt(newRhs[i]));
            let tempData = this.getFormattedData(
              res.data.templaterecords.filter(
                (item) => item.id === parseInt(newRhs[i])
              ),
              newRhs[i]
            );
            newFormula.push({
              uid: Math.random(),
              type: "node",
              position: "RHS",
              value: tempData[0].id,
              details: tempData[0],
            });
          } else {
            newFormula.push({
              uid: Math.random(),
              type: "operator",
              position: "RHS",
              value: newRhs[i],
              details: {},
            });
          }
        }
        this.setState({ formule: newFormula });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  isFormValid = () => {
    let operatorValidator = this.state.formule
      .filter((f) => f.type === "operator")
      .map((val) => val.value !== null && val.value !== "select");
    if (operatorValidator.includes(false)) {
      Toast("Select an Operator", "error");
      return true;
    }
    let lhsValidator, rhsValidator;
    this.state.formule
      .filter((f) => f.position === "LHS")
      .map((f, i) =>
        f.value !== null ? (lhsValidator = false) : (lhsValidator = true)
      );
    this.state.formule
      .filter((f) => f.position === "RHS")
      .map((f, i) =>
        f.value !== null ? (rhsValidator = false) : (rhsValidator = true)
      );
    if (lhsValidator === true || rhsValidator === true) {
      Toast("Please select metrics for all nodes", "error");
      return true;
    }
    return false;
  };
  updateRuleById = () => {
    let ruleId = this.props.match.params.id ? this.props.match.params.id : null;
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let LHSNodes = this.state.formule.filter((f) => f.position === "LHS");
          let RHSNodes = this.state.formule.filter((f) => f.position === "RHS");
          let LHSValue = LHSNodes[0] ? LHSNodes[0].value : null;
          let RHSValue = RHSNodes.map((r, key) => r.value).join("");
          let prepareObject = {
            lhs: LHSValue,
            rhs: RHSValue,
          };
          RuleServices.updateRule(ruleId, prepareObject)
            .then((res) => {
              if (res.status === 200) {
                Toast(constants.METRIC_RULE.EDIT.SUCCESS, "success");
                this.props.history.push({ pathname: "/rules-thresholds" });
              }
            })
            .catch((error) => {
              Toast(error, "error");
            });
        }
      }
    );
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let LHSNodes = this.state.formule.filter((f) => f.position === "LHS");
          let RHSNodes = this.state.formule.filter((f) => f.position === "RHS");
          let LHSValue = LHSNodes[0] ? LHSNodes[0].value : null;
          let RHSValue = RHSNodes.map((r) => r.value).join("");
          let prepareObject = {
            lhs: LHSValue,
            rhs: RHSValue,
          };
          RuleServices.addRule(prepareObject)
            .then((res) => {
              if (res.status === 200) {
                Toast(constants.METRIC_RULE.CREATE.SUCCESS, "success");
                this.props.history.push({ pathname: "/rules-thresholds" });
              }
            })
            .catch((error) => {
              Toast(error, "error here");
            });
        }
      }
    );
  };

  saveRuleSuccess = () => {
    let ruleId = this.props.match.params.id ? this.props.match.params.id : null;
    Toast(
      ruleId === null
        ? constants.METRIC_RULE.CREATE.SUCCESS
        : constants.METRIC_RULE.EDIT.SUCCESS,
      "success"
    );
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.METRIC_RULE.LIST.ROUTE,
        {}
      ),
    });
  };

  dailogModalAccept = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  resetForm = () => {
    let ruleId = this.props.match.params.id ? this.props.match.params.id : null;
    if (ruleId !== null) {
      this.prepopulateData(ruleId);
    } else {
      this.setState({
        selectedMetrics: [],
        formErrors: {},
        dailogModal: false,
        dailogModalContent: "",
        dailogModalHeader: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
        isFormSubmitted: false,
        apiError: null,
        operatorsDropdown: [
          {
            id: "select",
            name: "Select",
            value: "select",
            is_selected: false,
          },
          {
            id: "Plus",
            name: "+",
            value: "+",
            is_selected: true,
          },
          {
            id: "Minus",
            name: "-",
            value: "-",
            is_selected: false,
          },
        ],
        formule: [
          {
            uid: Math.random(),
            type: "node",
            position: "LHS",
            value: null,
            details: {},
          },
          {
            uid: Math.random(),
            type: "node",
            position: "RHS",
            value: null,
            details: {},
          },
          {
            uid: Math.random(),
            type: "operator",
            position: "RHS",
            value: null,
            details: {},
          },
          {
            uid: Math.random(),
            type: "node",
            position: "RHS",
            value: null,
            details: {},
          },
        ],
        documentModal: false,
      });
    }
  };

  populateTemplateRecord = (uid, tempData) => {
    this.setState({
      selectedUid: uid,
    });
    this.addTemplateRecord(tempData);
  };

  selectTemplateRecord = (uid) => {
    this.setState({
      documentModal: true,
      selectedUid: uid,
    });
  };

  closeDocumentModal = () => {
    this.setState({ currentEditingMetric: null });
    this.setState({
      documentModal: false,
      selectedUid: null,
    });
  };

  breadcrumbs = () => {
    let ruleId = this.props.match.params.id ? this.props.match.params.id : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TEMPLATE_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title:
          constants.APPLICATION_ROUTE.MANAGE_METRIC_RULES_AND_THRESHOLDS.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.MANAGE_METRIC_RULES_AND_THRESHOLDS.ROUTE,
          {}
        ),
        is_active: false,
      },
      {
        title: ruleId
          ? constants.APPLICATION_ROUTE.METRIC_RULE.EDIT.NAME
          : constants.APPLICATION_ROUTE.METRIC_RULE.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  addTemplateRecord = (data) => {
    if (this.state.selectedMetrics.includes(data.id)) {
      Toast("Row is already selected", "error");
    } else {
      if (this.state.currentEditingMetric !== null) {
        const index = this.state.selectedMetrics.indexOf(
          this.state.currentEditingMetric
        );
        if (index > -1) {
          this.state.selectedMetrics.splice(index, 1);
        }
        this.setState({ currentEditingMetric: null });
      }
      let existing = this.state.formule;
      let index = existing.findIndex((i) => i.uid === this.state.selectedUid);
      if (index >= 0) {
        existing[index].value = data.id;
        existing[index].details = data;
      }
      this.state.selectedMetrics.push(data.id);
      this.setState(
        {
          formule: existing,
        },
        () => {
          this.closeDocumentModal();
        }
      );
    }
  };

  addMore = () => {
    let existing = this.state.formule;
    existing.push({
      uid: Math.random(),
      type: "operator",
      position: "RHS",
      value: null,
      details: {},
    });
    existing.push({
      uid: Math.random(),
      type: "node",
      position: "RHS",
      value: null,
      details: {},
    });
    this.setState({
      formule: existing,
    });
  };

  handleOperatorDropdown = (e, uid) => {
    let existing = this.state.formule;
    let index = existing.findIndex((i) => i.uid === uid);
    if (index >= 0) {
      existing[index].value = e.target.value ? e.target.value : null;
    }
    this.setState({
      formule: existing,
    });
  };

  deleteMetric = (e, id, userId) => {
    e.stopPropagation();
    if (this.state.formule.filter((f) => f.position === "RHS").length > 3) {
      let temp = this.state.formule
        .filter((f) => f.position === "RHS")
        .find((x) => x.uid === userId);
      const index = this.state.formule.indexOf(temp);
      let previousFormula = this.state.formule;
      if (index > -1) {
        if (index === 1) {
          previousFormula.splice(index, 2);
        } else {
          previousFormula.splice(index - 1, 2);
        }
        this.setState({
          formula: previousFormula,
        });
      }

      const idIndex = this.state.selectedMetrics.indexOf(id);
      if (idIndex > -1) {
        this.state.selectedMetrics.splice(idIndex, 1);
      }
    } else {
      Toast("Two Nodes are required", "error");
    }
  };

  editMetric = (id, uid) => {
    this.setState({ currentEditingMetric: id });
    this.setState({
      documentModal: true,
      selectedUid: uid,
    });
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.MANAGE_METRIC_RULES_AND_THRESHOLDS.ROUTE
      ),
    });
  };

  render() {
    let ruleId = this.props.match.params.id ? this.props.match.params.id : null;
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"add-edit-form-card"}>
                <form className={"form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card border-0">
                    <div className="card-header title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {ruleId === null
                          ? constants.APPLICATION_ROUTE.METRIC_RULE.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.METRIC_RULE.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <span
                        className="material-icons-round text-darkblue2 ml-auto cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <div className="form-field-wrapper manage-rules">
                        <div className="container-fluid">
                          <div className="row align-items-center">
                            <div className="col-sm-4 col-md-3 col-lg-2 p-1 m-auto text-center">
                              {this.state.formule
                                ?.filter((f) => f.position === "LHS")
                                .map((f, key) => (
                                  <React.Fragment key={key}>
                                    {f.value !== null && (
                                      <div className={`card`}>
                                        <div className="card-header d-flex justify-content-between">
                                          <div>
                                            <h2>{f.details.metric_name}</h2>
                                            <h5>{f.details.metrictype_name}</h5>
                                          </div>
                                          <div>
                                            <span
                                              className="material-icons-outlined icon-fs-19 cursor-pointer mx-2"
                                              onClick={() =>
                                                this.editMetric(
                                                  f.details.id,
                                                  f.uid
                                                )
                                              }
                                            >
                                              edit
                                            </span>
                                          </div>
                                        </div>
                                        <div className="card-body vcenter">
                                          <div className="row-details">
                                            <p>
                                              <b>LOB:</b> {f.details.lob_group}
                                            </p>
                                            <p>
                                              <b>Geo:</b> {f.details.geo_group}
                                            </p>
                                            <p>
                                              <b>Vertical:</b>{" "}
                                              {f.details.vertical_group}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {f.value === null && (
                                      <div
                                        className={`card m-auto cursor-pointer`}
                                        onClick={() =>
                                          this.selectTemplateRecord(f.uid)
                                        }
                                      >
                                        <div className="card-header">
                                          <h2>Add Metric Data</h2>
                                        </div>
                                        <div className="card-body vcenter">
                                          <span>
                                            <span className="material-icons-round icon-fs-28 vcenterAlign">
                                              add_circle
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                            </div>
                            <div className="col-sm-1 text-center vcenter p-2">
                              <span className="m-auto operator-text vcenterAlign">
                                =
                              </span>
                            </div>
                            <div className="col-sm-7 col-md-8 col-lg-9 p-1 text-center vcenter p-2">
                              <div className="row align-items-center">
                                {this.state.formule
                                  ?.filter((f) => f.position === "RHS")
                                  .map((f, key) => (
                                    <React.Fragment key={key}>
                                      {f.type === "node" && (
                                        <div className="col-sm-8 col-md-4 col-lg-3 p-1 p-2">
                                          {f.value !== null && (
                                            <div className={`card`}>
                                              <div className="card-header d-flex justify-content-between">
                                                <div>
                                                  <h2>
                                                    {f.details.metric_name}
                                                  </h2>
                                                  <h5>
                                                    {f.details.metrictype_name}
                                                  </h5>
                                                </div>
                                                <div>
                                                  <span
                                                    className="material-icons-outlined icon-fs-19 cursor-pointer mx-2"
                                                    onClick={() =>
                                                      this.editMetric(
                                                        f.details.id,
                                                        f.uid
                                                      )
                                                    }
                                                  >
                                                    edit
                                                  </span>
                                                  <span
                                                    className="material-icons-outlined icon-fs-19 cursor-pointer"
                                                    onClick={(e) =>
                                                      this.deleteMetric(
                                                        e,
                                                        f.details.id,
                                                        f.uid
                                                      )
                                                    }
                                                  >
                                                    delete
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="card-body vcenter">
                                                <div className="row-details">
                                                  <p>
                                                    <b>LOB:</b>{" "}
                                                    {f.details.lob_group}
                                                  </p>
                                                  <p>
                                                    <b>Geo:</b>{" "}
                                                    {f.details.geo_group}
                                                  </p>
                                                  <p>
                                                    <b>Vertical:</b>{" "}
                                                    {f.details.vertical_group}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {f.value === null && (
                                            <div
                                              className={`card cursor-pointer`}
                                              onClick={() =>
                                                this.selectTemplateRecord(f.uid)
                                              }
                                            >
                                              <div className="card-header d-flex justify-content-between">
                                                <h2>Add Metric Data</h2>
                                                {key > 2 ? (
                                                  <div>
                                                    <span
                                                      className="material-icons-outlined icon-fs-19 cursor-pointer"
                                                      onClick={(e) =>
                                                        this.deleteMetric(
                                                          e,
                                                          f.details.id,
                                                          f.uid
                                                        )
                                                      }
                                                    >
                                                      delete
                                                    </span>
                                                  </div>
                                                ) : null}
                                              </div>

                                              <div className="card-body vcenter">
                                                <span>
                                                  <span className="material-icons-round icon-fs-28 vcenterAlign">
                                                    add_circle
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {f.type === "operator" && (
                                        <div className="col-sm-4 col-md-2 col-lg-1 p-1 px-2 text-center vcenter mt-auto mb-auto">
                                          <div className="custom-select-new custom-select-dropdown custom-select-dropdown-sm metric-rule-dropdown">
                                            <select
                                              className=""
                                              onChange={(e) =>
                                                this.handleOperatorDropdown(
                                                  e,
                                                  f.uid
                                                )
                                              }
                                            >
                                              {this.state.operatorsDropdown.map(
                                                (o, key) => (
                                                  <option
                                                    key={key}
                                                    value={o.value}
                                                    key={`${f.uid}-${o.id}`}
                                                    selected={
                                                      f.value === o.value
                                                    }
                                                  >
                                                    {o.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  ))}
                                <div className="col-sm-1 text-center vcenter mt-auto mb-auto">
                                  <button
                                    type="button"
                                    className="btn btn-primary d-flex align-items-center p-1"
                                    onClick={this.addMore}
                                  >
                                    <span className="material-icons-round icon-fs-22">
                                      add
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-submit-buttons mt-5">
                        <div className={"row"}>
                          <div className="col-sm-12">
                            <div className="text-center form-buttons">
                              <button
                                type="button"
                                className="btn btn-outline-primary modal-btn"
                                onClick={this.resetForm}
                              >
                                Reset
                              </button>
                              {ruleId === null ? (
                                <button
                                  type="button"
                                  className="btn btn-primary modal-btn"
                                  onClick={this.handleSubmit}
                                >
                                  Save
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary modal-btn"
                                  onClick={this.updateRuleById}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"col-sm-12"}></div>
                </form>
              </div>
            </div>
          </div>
          <TemplateRecordPicker
            documentModal={this.state.documentModal}
            closeDocumentModal={this.closeDocumentModal}
            title={"Geo Sub-category"}
            location={this.props.location}
            addTemplateRecord={this.addTemplateRecord}
          />
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
    setCurrencies: (currenciesList) => {
      dispatch(getCurrencies(currenciesList));
    },
    setTbriUsers: (tbriUsers) => {
      dispatch(getTbriUsers(tbriUsers));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRule);
