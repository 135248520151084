import React, { Component } from "react";
import constants from "../../Utils/constants";
import { TemplateServices } from "../../Services/Template";
import { connect } from "react-redux";
import { activeMenuTab } from "../../Store/Actions/User";
import Toast from "../../Utils/Toast";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";

const tableHeader = [
  {
    id: "level_1",
    title: "Level 1",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_2",
    title: "Level 2",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_3",
    title: "Level 3",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_4",
    title: "Level 4",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_5",
    title: "Level 5",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_6",
    title: "Level 6",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_7",
    title: "Level 7",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_8",
    title: "Level 8",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_9",
    title: "Level 9",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "level_10",
    title: "Level 10",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "geo_1",
    title: "Geo 1",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "geo_2",
    title: "Geo 2",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "geo_3",
    title: "Geo 3",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "geo_4",
    title: "Geo 4",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "industry_1",
    title: "Industry 1",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "industry_2",
    title: "Industry 2",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "industry_3",
    title: "Industry 3",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "metric_type",
    title: "Metric Type",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "metric",
    title: "Metric",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
];

const showActions = true;
class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      name: "",
      segmentList: [],
      selectedTab: [],
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
    };
  }
  componentDidMount() {
    let metricRuleId = this.props.match.params.id
      ? this.props.match.params.id
      : null;

    this.getTableData(metricRuleId);
  }

  onContentChangeTypeHandler = (s) => {
    this.setState({
      selectedTab: s,
    });
  };

  pagePrevious = () => {
    let metricRuleId = this.props.match.params.id
      ? this.props.match.params.id
      : null;

    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData(metricRuleId)
    );
  };
  pageNext = () => {
    let metricRuleId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData(metricRuleId)
    );
  };
  handlePage = (input) => {
    let metricRuleId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.setState({ pageNo: input }, () => this.getTableData(metricRuleId));
  };
  handlePageSize = (e) => {
    let metricRuleId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData(metricRuleId);
    });
  };
  getTableData = (metricRuleId) => {
    let url =
      `metric-rules/table-view/${metricRuleId}?page=` +
      this.state.pageNo +
      "&page_size=" +
      this.state.pageSize;
    TemplateServices.getTemplates(`${url}`)
      .then((res) => {
        let templatesData = res.data.table_data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        templatesData = templatesData.map((data) => {
          return {
            level_1: data.lob_level_1,
            level_2: data.lob_level_2,
            level_3: data.lob_level_3,
            level_4: data.lob_level_4,
            level_5: data.lob_level_5,
            level_6: data.lob_level_6,
            level_7: data.lob_level_7,
            level_8: data.lob_level_8,
            level_9: data.lob_level_9,
            level_10: data.lob_level_10,
            geo_1: data.geo_level_1,
            geo_2: data.geo_level_2,
            geo_3: data.geo_level_3,
            geo_4: data.geo_level_4,
            industry_1: data.vertical_level_1,
            industry_2: data.vertical_level_2,
            industry_3: data.vertical_level_3,
            metric_type: data.metric_level_1,
            metric: data.metric_level_2,
          };
        });
        this.setState({ rowData: templatesData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        console.log("error", error);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() => {
        //removing second loading indicator
        document.body.classList.remove("second-loading-indicator");
      });
  };

  render() {
    return (
      <DynamicTableComponentNew
        columnData={tableHeader}
        rowData={this.state.rowData}
        showActions={showActions}
        pagePrevious={this.pagePrevious}
        pageNext={this.pageNext}
        pageSize={this.state.pageSize}
        pageNo={this.state.pageNo}
        handlePage={this.handlePage}
        count={this.state.count}
        showPagination={true}
        handlePageSize={this.handlePageSize}
        paginationClass={"center-pagination custom-pagination-new"}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
};

export default connect(null, mapDispatchToProps)(TableView);
