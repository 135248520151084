import { customer_study_details as DETAILS } from '../Reducers/actionConstants'

const customerStudyCategories = (payload) => {
  return {
    type: DETAILS.CUSTOMER_STUDY_CATEGORIES,
    payload,
  }
}

const customerStudySegments = (payload) => {
  return {
    type: DETAILS.CUSTOMER_STUDY_SEGMENTS,
    payload,
  }
}

const customerStudyDetails = (payload) => {
  return {
    type: DETAILS.CUSTOMER_STUDY_DETAILS,
    payload,
  }
}

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  }
}

const customerStudyContent = (payload) => {
  return {
    type: DETAILS.CUSTOMER_STUDY_CONTENT,
    payload,
  }
}

const customerStudyTemplates = (payload) => {
  return {
    type: DETAILS.CUSTOMER_STUDY_TEMPLATES,
    payload,
  }
}

const customerStudyTransactionDetails = (payload) => {
  return {
    type: DETAILS.CUSTOMER_STUDY_TRANSACTION_DETAILS,
    payload,
  }
}

export {
  customerStudyCategories,
  customerStudySegments,
  customerStudyDetails,
  categories,
  customerStudyContent,
  customerStudyTemplates,
  customerStudyTransactionDetails
}
