import React, { useEffect, useState } from 'react'
import { MasterServices } from '../../Services/Master';
import MetricCategoryTreeView from './MetricCategoryTreeView';

function RuleView(props) {

  let id = props.match.params.id

  const [lobsTree, setLobTree] = useState([])
  const [geosTree, setGeoTree] = useState([])
  const [verticalsTree, setVerticalTree] = useState([])
  const [metricsTree, setMetricTree] = useState({})
  const [lobsCheckedIds, setLobCheckedIds] = useState([])
  const [geosCheckedIds, setGeoCheckedIds] = useState([])
  const [verticalsCheckedIds, setVerticalCheckedIds] = useState([])
  const [metricsCheckedIds, setMetricCheckedIds] = useState([])
  const [lobsExpandIds, setLobExpandIds] = useState([])
  const [geosExpandIds, setGeoExpandIds] = useState([])
  const [verticalsExpandIds, setVerticalExpandIds] = useState([])
  const [metricsExpandIds, setMetricExpandIds] = useState([])

  function buildHierarchy(data) {
    const idToNodeMap = new Map();

    // Create a mapping of id to node
    data.forEach((item) => {
      const newNode = {
        ...item,
        children: [],
        isExpand: true,
        checked: true,
        label: item.name,
        value: item.id,

      };
      idToNodeMap.set(item.id, newNode);
    });


    // Build the tree structure
    const rootNodes = [];
    idToNodeMap.forEach((node) => {
      const { parent_id } = node;
      if (parent_id === null) {
        // Node is a root node
        rootNodes.push(node);
      } else {
        // Node has a parent, add it as a child to the parent
        const parentNode = idToNodeMap.get(parent_id);

        if (parentNode) {
          parentNode.children.push(node);
        } else {
          rootNodes.push(node);
        }
      }
    });


    return rootNodes
  }

  function groupMetrics(metricsArray) {

    let metricsGroup = {}
    metricsArray.forEach(item => {
      const parentName = item.parent_data.name;
      const childName = item.name;

      if (!metricsGroup[parentName]) {
        metricsGroup[parentName] = [];
      }

      metricsGroup[parentName].push(childName);
    });

    return metricsGroup
  }

  const fetchCategoryData = () => {


    // Fetch categories using MasterServices
    MasterServices.getCategories(
      `/metric-rules/${id}/rule-view`
    ).then((res) => {
      // Process the response data
      let lobRes = res.data.row;
      props.setName(lobRes.name)
      let relation_tree = res.data.row.relation_tree

      let lobChildrenTree = buildHierarchy(lobRes.lobs)
      let geoChildrenTree = buildHierarchy(lobRes.geos)
      let verticalChildrenTree = buildHierarchy(lobRes.verticals)
      let metricsChildrenTree = groupMetrics(lobRes.metrics.filter((i) => i.parent_data))

      setLobTree(lobChildrenTree)
      setGeoTree(geoChildrenTree)
      setVerticalTree(verticalChildrenTree)
      setMetricTree(metricsChildrenTree)
      setLobCheckedIds(relation_tree.lobIds)
      setGeoCheckedIds(relation_tree.geoIds)
      setMetricCheckedIds(relation_tree.metricIds)
      setVerticalCheckedIds(relation_tree.verticalIds)
      setLobExpandIds(relation_tree.lobIds)
      setGeoExpandIds(relation_tree.geoIds)
      setMetricExpandIds(relation_tree.metricIds)
      setVerticalExpandIds(relation_tree.verticalIds)

    });
  };

  useEffect(() => {
    fetchCategoryData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ display: 'flex' }} className='rule-view-main-div'>

      <MetricCategoryTreeView treeData={lobsTree} setTreeData={setLobTree} checkedIds={lobsCheckedIds} setCheckedIds={setLobCheckedIds} expandedIds={lobsExpandIds} setExpandIds={setLobExpandIds} rule_view={true} title="LOB's" />
      <MetricCategoryTreeView treeData={verticalsTree} setTreeData={setVerticalTree} checkedIds={verticalsCheckedIds} setCheckedIds={setVerticalCheckedIds} expandedIds={verticalsExpandIds} setExpandIds={setVerticalExpandIds} rule_view={true} title="Verticals" />
      <MetricCategoryTreeView treeData={geosTree} setTreeData={setGeoTree} title="Geography" checkedIds={geosCheckedIds} setCheckedIds={setGeoCheckedIds} expandedIds={geosExpandIds} setExpandIds={setGeoExpandIds} rule_view={true} />
      <MetricCategoryTreeView treeData={metricsTree} setTreeData={setMetricTree} title="Metric and Type" checkedIds={metricsCheckedIds} setCheckedIds={setMetricCheckedIds} expandedIds={metricsExpandIds} setExpandIds={setMetricExpandIds} rule_view={true} metrics={true} />
    </div>
  )
}

export default RuleView