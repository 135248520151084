import * as XLSX from "xlsx";

export function downloadExcelFile(filetitle, filename, data, columns) {
  // Create a worksheet from the filtered data
  const ws = XLSX.utils.json_to_sheet(data, {
    header: columns.map((col) => col.header),
  });
  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, filetitle);
  // Generate the XLSX file and trigger the download
  XLSX.writeFile(wb, `${filename}.xlsx`);
}
