import React, { useCallback, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import { ActiveDeactiveDeleteResourceModal, NoDataAvailable } from "./Resource";
import { useHistory } from "react-router-dom";
import { ResourceAndFAQServices } from "./ResourceAndFAQServices";
import Toast from "../../Utils/Toast";
import constants from "../../Utils/constants";
import FaqsList from "./FaqsList";
import { useMemo } from "react";

const validateInput = (input) => {
  const error = {};
  let isValid = true;
  if (!input.question) {
    error.question = "Question is required";
    isValid = false;
  }
  if (!input.answer) {
    error.answer = "Answer is required";
    isValid = false;
  }

  return { error, isValid };
};

const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number.toString();
};

const FaqModal = ({
  isOpen,
  toggle,
  form,
  setForm,
  error,
  onSubmit,
  setError,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} className="modal-lg custom-modal">
    <ModalHeader toggle={toggle}>
      <span className="category-title">{form?.id ? "Edit" : "Add"} FAQ</span>
    </ModalHeader>
    <ModalBody className="d-flex flex-column" style={{ padding: "0px" }}>
      <div className="form-group">
        <label>
          Question<span className="mandatory">*</span>
        </label>
        <input
          placeholder="Enter Question"
          onChange={(e) => {
            const value = e.target?.value;
            if (value !== undefined) {
              setForm((prev) => ({ ...prev, question: value }));
              setError((prev) => ({ ...prev, question: "" }));
            }
          }}
          value={form?.question || ""}
          className="form-control"
        />
        {error.question && <span className="error-text">{error.question}</span>}
      </div>
      <div className="form-group">
        <label>
          Answer<span className="mandatory">*</span>
        </label>
        <textarea
          placeholder="Enter Answer"
          onChange={(e) => {
            const value = e.target?.value;
            if (value !== undefined) {
              setForm((prev) => ({ ...prev, answer: value }));
              setError((prev) => ({ ...prev, answer: "" }));
            }
          }}
          value={form?.answer || ""}
          className="form-control"
          style={{ height: "80px" }}
        />
        {error.answer && <span className="error-text">{error.answer}</span>}
      </div>
      <div className="form-group">
        <label>
          Select Priority<span className="mandatory">*</span>
        </label>
        <input
          type="number"
          onChange={(e) => {
            const value = e.target?.value;
            if (value !== undefined) {
              setForm((prev) => ({ ...prev, order: value }));
            }
          }}
          value={form?.order || ""}
          className="form-control"
        />
      </div>
      <ModalFooter>
        <div className="d-flex justify-content-end g-15">
          <Button className="btn-outline-primary" onClick={toggle}>
            Cancel
          </Button>
          <Button className="btn-primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </ModalBody>
  </Modal>
);


export const Faq = (props) => {
  const initialFields = {
    question: "",
    answer: "",
    is_active: 1,
    order: 100,
  };
  const history = useHistory();
  const [error, setError] = useState({});
  const [activeModal, setActiveModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [collapseId, setCollapseId] = useState(0);

  const [form, setForm] = useState(initialFields);
  const [faqList, setFaqList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageNo: 1,
    count: 0,
  });

  const handleModelClose = useCallback(() => {
    props.setOpenFaqModel(false);
    setError({});
    setForm(initialFields);
  }, [props]);

  const onSubmit = useCallback(async () => {
    const { error, isValid } = validateInput(form);
    setError(error);
    if (isValid) {
      try {
        if (form?.id) {
          let { question, answer, order } = form;
          let input = { question, answer, order };
          await ResourceAndFAQServices.updateFaq(input, form.id);
          Toast("Faq Updated Successfully", "success");
        } else {
          await ResourceAndFAQServices.addFaq(form);
          Toast("Faq Added Successfully", "success");
        }
        await fetchFAQ();
        handleModelClose();
      } catch (error) {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      }
    }
  }, [form, handleModelClose]);

  const handleActive = async () => {
    let input = { is_active: form.is_active == 1 ? 0 : 1 };
    await ResourceAndFAQServices.updateFaqStatus(input, form.id);
    Toast("Faq Status Updated Successfully", "success");
    setActiveModal(false); //
    setIsDelete(false);
    await fetchFAQ();
  };

  const handleDelete = async () => {
    await ResourceAndFAQServices.deleteFaq(form.id);
    Toast("Faq Deleted Successfully", "success");
    setForm(initialFields);
    setActiveModal(false); //
    setIsDelete(false);
    await fetchFAQ();
  };

  const handleEditFaq = useCallback((item) => {
    setForm(item);
    props.setOpenFaqModel(true);
  }, []);

  const handleCloseActiveModal = useCallback((data) => {
    setActiveModal(false);
    setForm(initialFields);
    setIsDelete(false);
  }, []);

  const fetchFAQ = async (pageNumber) => {
    let pageNo = pageNumber || 1;
    // Set default values if parameters are not provided
    let queryParams = "";

    if (props.search !== "") {
      queryParams += "&question=" + props.search;
    }

    // Update the browser history
    history.push({
      pathname: history.location.pathname,
      search: `?activeTab=FAQs` + "&page=" + pageNo + queryParams,
    });

    let url = `/faq-resources/faqs?limit=999`;
    if (pageNo) {
      url = url + `&page=${pageNo}&sort=order`;
    }

    if (props.search) {
      url = url + `&question=${props.search}`;
    }
    let res = await ResourceAndFAQServices.getFaqs(url);
    setFaqList(res.data.data);
    setPaginationState({
      pageNo: res.data.currentPage,
      count: res.data.total,
    });
  };

  useEffect(() => {
    fetchFAQ();
  }, [props.search]);

  const memoizedFaqListList = useMemo(() => faqList, [faqList]);

  return (
    <div
      className="d-flex justify-content-space-between flex-d h-100"
      style={{ marginTop: "15px" }}
    >
      {faqList.length > 0 ? (
        <FaqsList
          faqList={memoizedFaqListList}
          collapseId={collapseId}
          setCollapseId={setCollapseId}
          handleEditFaq={handleEditFaq}
          setIsDelete={setIsDelete}
          setForm={setForm}
          setActiveModal={setActiveModal}
        />
      ) : (
        <NoDataAvailable text="FAQs" />
      )}
      <FaqModal
        isOpen={props.openFaqModel}
        toggle={handleModelClose}
        form={form}
        setForm={setForm}
        error={error}
        onSubmit={onSubmit}
        setError={setError}
      />
      <ActiveDeactiveDeleteResourceModal
        isOpen={activeModal || isDelete}
        toggle={handleCloseActiveModal}
        form={form}
        isDelete={isDelete}
        onSubmit={activeModal ? handleActive : handleDelete}
        label="FAQ"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
