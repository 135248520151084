import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "react-collapser/dist/default-styles.css";
import ReactPlayer from "react-player";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import Notification from "../Components/Notification";
import { HelpServices } from "../Services/HelpServices";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import JoditEditorContainer from "../Components/JoditEditorContainer";

const HelpDetails = forwardRef((props, ref) => {
  const [isModalOpen, setModal] = useState(false);
  const [isEditFlag, setEditFlag] = useState(false);
  const [helpId, setHelpId] = useState(0);
  const selectedHelpCategory = props.selectedCategory.item;
  const [helpFormValues, setHelpFormValues] = useState({
    category: selectedHelpCategory,
    title: "",
    text: "",
    video: null,
    isVideoUploded: false,
    initialText: "",
  });

  useEffect(
    () => {
      setHelpFormValues({
        ...helpFormValues,
        category: selectedHelpCategory,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedHelpCategory]
  );

  const [formState, setFormState] = useState({
    categoryError: false,
    titleError: false,
    textError: false,
    videoError: false,
    isFormSubmitted: false,
    apiError: "",
  });

  const [dailogState, setDailogState] = useState({
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
  });

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.MASTER_DATA_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.HELP.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  const toggle = () => {
    setModal(!isModalOpen);
    if (isModalOpen) {
      setEditFlag(false);
      setHelpFormValues({
        category: selectedHelpCategory,
        title: "",
        text: "",
        video: "",
        isVideoUploded: false,
      });
      setFormState({
        ...formState,
        categoryError: false,
        titleError: false,
        textError: false,
        videoError: false,
        isFormSubmitted: false,
        apiError: "",
      });
    }
  };

  useImperativeHandle(ref, () => ({
    showtoggle() {
      setModal(!isModalOpen);
      if (isModalOpen) {
        setEditFlag(false);
        setHelpFormValues({
          category: selectedHelpCategory,
          title: "",
          text: "",
          video: "",
          isVideoUploded: false,
        });
        setFormState({
          ...formState,
          categoryError: false,
          titleError: false,
          textError: false,
          videoError: false,
          isFormSubmitted: false,
          apiError: "",
        });
      }
    },
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      if (isEditFlag) {
        const body = {
          category: helpFormValues.category,
          title: helpFormValues.title,
          text: helpFormValues.text,
          video: helpFormValues.video,
          isVideoUploded: helpFormValues.isVideoUploded,
        };

        HelpServices.updateHelp(helpId, body)
          .then((_) => {
            setHelpFormValues({
              category: selectedHelpCategory,
              title: "",
              text: "",
              video: "",
              isVideoUploded: false,
            });
            setFormState({
              ...formState,
              categoryError: false,
              titleError: false,
              textError: false,
              videoError: false,
              isFormSubmitted: false,
              apiError: "",
            });
            setEditFlag(false);
            Toast(
              constants.APPLICATION_ROUTE.HELP.SUCCESS.UPDATE_HELP,
              "success"
            );
            toggle();
            setTimeout(() => props.getHelpData(), 2000);
          })
          .catch((error) => {
            setFormState({
              ...formState,
              apiError:
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
            });
          });
      } else {
        let body = {
          category: helpFormValues.category,
          title: helpFormValues.title,
          text: helpFormValues.text,
          video: helpFormValues.video,
          isVideoUploded: helpFormValues.isVideoUploded,
        };

        HelpServices.addHelp(body)
          .then((_) => {
            setHelpFormValues({
              category: selectedHelpCategory,
              title: "",
              text: "",
              video: "",
              isVideoUploded: false,
            });
            setFormState({
              ...formState,
              categoryError: false,
              titleError: false,
              textError: false,
              videoError: false,
              isFormSubmitted: false,
              apiError: "",
            });
            Toast(constants.APPLICATION_ROUTE.HELP.SUCCESS.ADD_HELP, "success");
            toggle();
            setTimeout(() => props.getHelpData(), 2000);
          })
          .catch((error) => {
            setFormState({
              ...formState,
              apiError:
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
            });
          });
      }
    }
  };

  const isFormValid = () => {
    let formError = false;
    let titleError = false;
    let categoryError = false;
    let textError = false;

    if (!helpFormValues.category) {
      categoryError = true;
      formError = true;
    }
    if (!helpFormValues.title) {
      titleError = true;
      formError = true;
    }
    if (!helpFormValues.text) {
      textError = true;
      formError = true;
    }
    setFormState({
      ...formState,
      titleError,
      textError,
      categoryError,
    });
    return formError;
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setHelpFormValues({
      ...helpFormValues,
      [e.target.id]: e.target.value,
    });
  };

  const onChangeHandler = (e) => {
    setHelpFormValues({
      ...helpFormValues,
      text: e,
    });
  };

  const handleEditHelpPage = (e, helpObj) => {
    e.preventDefault();
    setHelpId(helpObj.id);
    setHelpFormValues({
      ...helpFormValues,
      ...helpObj,
      initialText: helpObj.text,
      video: helpObj.video,
    });
    setEditFlag(true);
    toggle();
  };

  const openDeleteHelpDialog = (e, id) => {
    e.stopPropagation();
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Delete Help</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.APPLICATION_ROUTE.HELP.SUCCESS.WARNING_HELP}
          </h2>
        </div>
      </>
    );
    setDailogState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "delete_HELP", id: id },
      dailogModalStyleType: "danger-modal",
    });
    setHelpId(id);
  };

  const dailogModalDecline = () => {
    setDailogState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };

  const handleDeleteHelp = (config) => {
    HelpServices.removeHelp(config.id)
      .then((_) => {
        setDailogState({
          dailogModal: false,
          dailogModalHeader: "",
          dailogModalContent: "",
          dailogModalConfig: {},
          dailogModalStyleType: "",
        });
        Toast(constants.APPLICATION_ROUTE.HELP.SUCCESS.DELETE_HELP, "success");
        setTimeout(() => props.getHelpData(), 2000);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const onFileChange = (files) => {
    try {
      let reader = new FileReader();
      if (files && files.length) {
        reader.readAsDataURL(files[0]);
        reader.onloadend = (e) => {
          setHelpFormValues({
            ...helpFormValues,
            video: e.target.result,
            isVideoUploded: true,
          });
          setFormState({
            ...formState,
            videoError: false,
          });
        };
      }
    } catch (e) {
      throw e;
    }
  };

  return (
    <aside className="dash_board_content inner-sidebar-right-content faq_content">
      <div className="help-right-content">
        <div className="card help-card">
          <div className="card-body">
            <div className="d-flex flex-wrap justify-content-between align-items-center title-block">
              <h2 className="font-weight-bold mb-0 text-black2 font-family-source-sans">
                {props.selectedCategory.item} Help
              </h2>
              <div className="faqs">
                <AclAction
                  tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                  type="permissions"
                  entity={constants.ACL.HELP_PAGE_RESOURCE.TITLE}
                  action={constants.ACL.HELP_PAGE_RESOURCE.ACTIONS.CREATE}
                >
                  <aside className="d-flex justify-content-end">
                    {!props.helpData.filter(
                      (val) => val.category === props.selectedCategory.item
                    ).length >= 1 && (
                      <button
                        className={
                          props.helpData.filter(
                            (val) =>
                              val.category === props.selectedCategory.item
                          ).length >= 1
                            ? "btn btn-custom btn-secondary icon-text-btn title-btn d-inline-flex align-items-center mr-2"
                            : "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mr-2"
                        }
                        type="button"
                        onClick={toggle}
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          add
                        </span>
                        {" " +
                          constants.APPLICATION_ROUTE.HELP.LIST
                            .ADD_BUTTON_TITLE}
                      </button>
                    )}
                    <button
                      className={
                        "btn btn-md d-inline-flex align-items-center px-2"
                      }
                      type="button"
                      disabled={props.helpData.length === 0}
                      onClick={(e) => handleEditHelpPage(e, props.helpData[0])}
                    >
                      <span className="material-icons-outlined text-blue-clr icon-fs-20">
                        edit
                      </span>
                    </button>
                    <button
                      className={
                        "btn btn-md d-inline-flex align-items-center px-2"
                      }
                      type="button"
                      disabled={props.helpData.length === 0}
                      onClick={(e) =>
                        openDeleteHelpDialog(e, props.helpData[0].id)
                      }
                    >
                      <span className="material-icons-outlined text-red icon-fs-20">
                        delete
                      </span>
                    </button>
                  </aside>
                </AclAction>
              </div>
            </div>

            <div className="">
              {props.helpData.length === 0 && (
                <strong>
                  <h3>No Help Data</h3>
                </strong>
              )}
              {props.helpData.length > 0 &&
                props.helpData.map((element) => (
                  <ul key={element.id} className="help-details-content-ul">
                    <li>
                      <label>
                        <strong>Title:</strong>{" "}
                      </label>{" "}
                      <div>{element.title}</div>
                    </li>
                    {element.text ? (
                      <li>
                        <label>
                          <strong>Description:</strong>{" "}
                        </label>
                        <div
                          className="helpDescription"
                          dangerouslySetInnerHTML={{ __html: element.text }}
                        />
                      </li>
                    ) : (
                      ""
                    )}
                    {element.video ? (
                      <li>
                        <label>
                          <strong>Video:</strong>{" "}
                        </label>
                        <div className="player-wrapper">
                          <ReactPlayer
                            className="react-player"
                            controls
                            width="100%"
                            height="100%"
                            url={element.video}
                          />
                        </div>
                      </li>
                    ) : (
                      ""
                    )}

                    <br />
                  </ul>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        className={"modal-lg modal-w-header custom-modal primary-modal"}
      >
        <ModalHeader toggle={toggle}>
          {isEditFlag
            ? constants.APPLICATION_ROUTE.HELP.HEADER.EDIT_TITLE
            : constants.APPLICATION_ROUTE.HELP.HEADER.ADD_TITLE}
        </ModalHeader>
        {formState.apiError && (
          <Notification color={"danger"} message={formState.apiError} />
        )}
        <ModalBody>
          <Form className={"form-wrapper"}>
            <div className="row">
              <div className="col-sm-12 form-group position-relative">
                <FormGroup>
                  <Label htmlFor="category">
                    Category <span className={"mandatory"}>* </span>
                  </Label>
                  <Input
                    className={`${formState.categoryError ? "is-invalid" : ""}`}
                    type="text"
                    placeholder="Category"
                    id="category"
                    name="category"
                    onChange={(e) => handleInputChange(e)}
                    value={helpFormValues.category}
                    disabled={true}
                  />
                  {formState.categoryError && (
                    <div className="invalid-tooltip">
                      Category is a required field
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="title">
                    Title <span className={"mandatory"}>* </span>
                  </Label>
                  <Input
                    className={`${formState.titleError ? "is-invalid" : ""}`}
                    type="text"
                    placeholder="Help Title"
                    id="title"
                    name="title"
                    value={helpFormValues.title}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {formState.titleError && (
                    <div className="invalid-tooltip">
                      Title is a required field
                    </div>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="text">
                    Information <span className={"mandatory"}>* </span>
                  </Label>
                  <JoditEditorContainer
                    className={
                      formState.textError
                        ? "tox-notifications-container tox-tinymce"
                        : "tox-notifications-container"
                    }
                    height="calc(100vh - 480px)"
                    value={helpFormValues.text}
                    onChangeHandler={onChangeHandler}
                  />

                  {formState.textError && (
                    <div className="htmlEditor-validate">
                      Information is a required field
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="video">Upload Video</Label>
                  <input
                    className="d-block"
                    accept="video/*"
                    type="file"
                    id="video"
                    name="video"
                    onChange={(e) => onFileChange(e.target.files)}
                  />

                  {formState.videoError && (
                    <div className="invalid-tooltip">
                      Video is a required field
                    </div>
                  )}
                </FormGroup>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            className={"btn-outline-primary modal-btn"}
            type="button"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            className={"modal-right-btn modal-btn"}
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {isEditFlag ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
      <DailogNew
        id={helpId}
        isOpen={dailogState.dailogModal}
        accept={handleDeleteHelp}
        decline={dailogModalDecline}
        header={dailogState.dailogModalHeader}
        content={dailogState.dailogModalContent}
        config={dailogState.dailogModalConfig}
        modalStyleType={dailogState.dailogModalStyleType}
      />
    </aside>
  );
});
export default HelpDetails;
