import { Axios } from '../lib/axios';
export const EmailBounceServices = {
  getEmailBounceList,
  updateUsersAsInActive,
  getEmailSuppressionList,
  addEmailInSuppression,
  deleteEmailInSuppression
}
function getEmailBounceList(url) {
  return Axios.get(url);
}

function updateUsersAsInActive(body){
  return Axios.put(`/emailBounce/status/inActive`, body)
}

function getEmailSuppressionList(url) {
  return Axios.get(url);
}

function addEmailInSuppression(body){
  return Axios.post(`/suppressionEmail`, body)
}

function deleteEmailInSuppression(body){
  return Axios.put(`/deleteSuppressionEmail`, body)
}