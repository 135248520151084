import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import Notification from "../Components/Notification";
import profile_image from "../Images/profile-image.png";
import decodeJWT from "../lib/decodeJWT";
import { UserServices } from "../Services/User";
import {
  loginUserFailure,
  loginUserSuccess,
  openPreference,
} from "../Store/Actions/User";
import constants from "../Utils/constants";
import DateTimeFormatter from "../Utils/DateTimeFormatter";
import Toast from "../Utils/Toast";
import RegisterSSO from "./RegisterSSO";
import SetPassword from "../Components/SetPassword";
import Logout from "./LogOut_using_SSO";
import ToggleSwitch from "../Components/ToggleSwitch";
import ellipsisName from "../Utils/AddEllipsisToName";
import CustomReactTooltip from "../Components/CustomReactTooltip";

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      loginSuccesData: this.props.home.loginUserSuccess,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  state = {
    loginSuccesData: null,
    isProfileMenu: false,
    documentModal: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    apiErrors: "",
    passwordError: false,
    newPasswordError: false,
    confirmPasswordError: false,
    isSubmitted: false,
    successMessage: "",
    isNewPasswordCurrentPasswordEqualError: false,
  };

  handleOutsideClick(e) {
    if (e && e.target && this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.toggleProfileMenu();
    }
  }

  toggleProfileMenu = () => {
    if (!this.state.isProfileMenu) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState({
      isProfileMenu: !this.state.isProfileMenu,
    });
  };

  userLogout = () => {
    if (localStorage.getItem("token") !== null) {
      UserServices.getUserLogout()
        .then((res) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          this.props.history.push(
            formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
          );
          this.props.loginSuccess({});
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      this.props.history.push(
        formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
      );
    }
  };

  handleChangePasswordFormSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      let body = {
        password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmPassword,
      };
      if (localStorage.getItem("token") !== null) {
        UserServices.changePassword(body)
          .then((data) => {
            this.setState({
              apiErrors: "",
              successMessage: data.data.message,
            });
            setTimeout(() => {
              this.userLogout();
            }, 1500);
          })
          .catch((error) => {
            this.setState({
              apiErrors:
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
            });
          });
      } else {
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        );
      }
    }
  };

  toggleDocumentModal = () => {
    let documentModal = this.state.documentModal;
    if (!documentModal && localStorage.getItem("token") == null) {
      Toast(constants.TOKEN.NOT_FOUND, "error");
      setTimeout(() => {
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        );
      }, 1500);
    } else {
      this.setState({
        documentModal: !documentModal,
        currentPasswordError: false,
        newPasswordError: false,
        confirmPasswordError: false,
        isNewPasswordCurrentPasswordEqualError: false,
      });
    }
  };

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.isSubmitted) this.isFormValid();
      }
    );
  };

  isFormValid = () => {
    var re = new RegExp(constants.REG_EX.PASSWORD_REG_EX);
    let currentPasswordError = this.state.currentPassword === "";
    let newPasswordError = !re.test(this.state.newPassword);
    let isNewPasswordCurrentPasswordEqualError =
      this.state.newPassword === this.state.currentPassword &&
      this.state.newPassword &&
      this.state.currentPassword;
    let confirmPasswordError =
      !(this.state.newPassword === this.state.confirmPassword) ||
      !re.test(this.state.confirmPassword);
    this.setState({
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      isNewPasswordCurrentPasswordEqualError,
      isSubmitted: true,
    });
    if (
      currentPasswordError === false &&
      newPasswordError === false &&
      confirmPasswordError === false &&
      isNewPasswordCurrentPasswordEqualError === false
    ) {
      return true;
    }
    return false;
  };

  // navitage to the Preference center
  navigateToPreferenceCenter = () => {
    this.props.history.push(
      formatRoute(constants.APPLICATION_ROUTE.MANAGE_PREFERENCES.ROUTE, {})
    );
    this.toggleProfileMenu();
  };

  getFirstLetters(str) {
    const firstLetters = str
      .split(" ")
      .map((word) => word[0])
      .join("");

    return firstLetters;
  }

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    return (
      <>
        <header>
          <div className="header-content-wrapper h-100">
            <div className="header-content h-100 d-flex align-items-center">
              <nav className="navbar navbar-expand-lg navbar-light p-0">
                <div className="logo d-flex align-items-center">
                  <a
                    className="navbar-brand m-0 nav_logo"
                    onClick={() => {
                      this.props.openPreference(false);
                    }}
                    href={formatRoute(
                      process.env.PUBLIC_URL +
                        constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
                      {}
                    )}
                  >
                    <img
                      style={{ width: "80px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/TBR_2color_no_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo /> */}
                  </a>
                  <a
                    className="navbar-brand d-md-none header-logo"
                    href={formatRoute(
                      process.env.PUBLIC_URL +
                        constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
                      {}
                    )}
                  >
                    <img
                      style={{ width: "80px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/TBR_2color_no_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo /> */}
                  </a>
                </div>
                {tokenDetails.role.short_name === "admin" && (
                  <div>
                    <ToggleSwitch data={this.state.loginSuccesData} />
                  </div>
                )}

                <div className="site_name ml-md-auto">
                  <span>IC-ADMIN</span>
                </div>
                <div className="ml-auto">
                  <div className="d-flex align-items-center">
                    <Modal
                      isOpen={this.state.documentModal}
                      toggle={this.toggleDocumentModal}
                      className={
                        "modal-md modal-w-header custom-modal primary-modal"
                      }
                    >
                      <ModalHeader toggle={this.toggleDocumentModal}>
                        {constants.CHANGE_PASSWORD.HEADER_TITLE}
                      </ModalHeader>
                      <form
                        onSubmit={this.handleChangePasswordFormSubmit}
                        className={`col-lg-12 col-sm-12 form-wrapper p-0`}
                      >
                        <ModalBody>
                          {this.state.apiErrors &&
                            this.state.apiErrors.length > 0 && (
                              <Notification
                                color={"danger"}
                                message={this.state.apiErrors}
                              />
                            )}

                          {this.state.successMessage &&
                            this.state.successMessage.length > 0 && (
                              <Notification
                                color={"success"}
                                message={this.state.successMessage}
                              />
                            )}
                          <FormGroup>
                            <div className="clearfix">
                              <Label htmlFor="currentPassword">
                                Current Password{" "}
                                <span className={"mandatory"}>* </span>
                              </Label>
                              <Input
                                className={`${
                                  this.state.currentPasswordError
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="password"
                                placeholder=""
                                id="currentPassword"
                                name="currentPassword"
                                onChange={this.handleInputChange}
                              />
                              {/* {this.state.currentPasswordError && (
                                <div className="invalid-tooltip">
                                  Current Password is a required field
                                </div>
                              )} */}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="clearfix">
                              <Label htmlFor="newPassword">
                                New Password{" "}
                                <span className={"mandatory"}>* </span>
                              </Label>
                              <Input
                                className={`${
                                  this.state.newPasswordError
                                    ? "is-invalid"
                                    : this.state
                                        .isNewPasswordCurrentPasswordEqualError
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="password"
                                placeholder=""
                                id="newPassword"
                                name="newPassword"
                                onChange={this.handleInputChange}
                              />
                              {this.state.newPasswordError ? (
                                <div className="invalid-tooltip">
                                  Passwords must contain at least 8 characters
                                  including at least 1 of each of the following:
                                  uppercase letter, lowercase letter, special
                                  character and number.
                                </div>
                              ) : this.state
                                  .isNewPasswordCurrentPasswordEqualError ? (
                                <div className="invalid-tooltip">
                                  Current Password and New Password should be
                                  different{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="clearfix">
                              <Label htmlFor="confirmPassword">
                                Confirm Password{" "}
                                <span className={"mandatory"}>* </span>
                              </Label>
                              <Input
                                className={`${
                                  this.state.confirmPasswordError
                                    ? "is-invalid"
                                    : ""
                                }`}
                                type="password"
                                placeholder=""
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={this.handleInputChange}
                              />
                              {this.state.confirmPasswordError && (
                                <div className="invalid-tooltip">
                                  Password does not match new password above.
                                </div>
                              )}
                            </div>
                          </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            className="modal-btn btn-outline-primary"
                            onClick={this.toggleDocumentModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            className="modal-btn"
                            type="submit"
                          >
                            Change Password
                          </Button>{" "}
                        </ModalFooter>
                      </form>
                    </Modal>

                    <div className="login_sec">
                      <ul className="navbar-nav justify-content-end align-items-center">
                        <li className="mr-3">
                          <Button
                            id="PopoverLegacy"
                            style={{
                              padding: 0,
                              border: "none",
                              background: "none",
                              boxShadow: "none",
                            }}
                            type="button"
                            className="d-inline-flex"
                          >
                            <span className="material-icons text-ligntblue2">
                              mail
                            </span>
                          </Button>
                          <UncontrolledPopover
                            placement="bottom"
                            target="PopoverLegacy"
                            trigger="legacy"
                            className="custom-popover"
                          >
                            <PopoverHeader>Email Support</PopoverHeader>
                            <PopoverBody>
                              Any queries/help, please contact us <br />
                              <a
                                style={{ color: "#0645AD" }}
                                href="mailto:icsupport@tbri.com"
                              >
                                icsupport@tbri.com
                              </a>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </li>
                        <li
                          onClick={this.toggleProfileMenu}
                          className={`user-details ${
                            this.state.isProfileMenu ? "active" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="profile_circle">
                              {this.getFirstLetters(
                                `${this.props.home.loginUserSuccess.first_name} ${this.props.home.loginUserSuccess.last_name}`
                              )}
                            </span>
                            <div className="user-details-block d-none d-md-flex flex-column align-items-start">
                              <span>{`${ellipsisName(
                                this.props.home.loginUserSuccess.first_name
                              )}`}</span>
                              {tokenDetails.role.name === "Admin" ? (
                                <span className="user-role">
                                  {this.props.home.userDesignation &&
                                  this.props.home.userDesignation.length > 0
                                    ? this.props.home.userDesignation
                                    : this.props.home.loginUserSuccess
                                        .designation !== null
                                    ? this.props.home.loginUserSuccess
                                        .designation
                                    : tokenDetails.role.name}
                                </span>
                              ) : (
                                <span className="user-role">
                                  {this.props.home.loginUserSuccess
                                    .designation !== null
                                    ? this.props.home.loginUserSuccess
                                        .designation
                                    : tokenDetails.role.name}
                                </span>
                              )}
                            </div>
                            <span className="material-icons arrow-drown-down">
                              arrow_drop_down
                            </span>
                          </div>
                        </li>
                        <li className="d-none">
                          <span className="search_open">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div
          className={`login_sec user-popover ${
            this.state.isProfileMenu ? "active" : ""
          }`}
        >
          <ul
            ref={(node) => {
              this.node = node;
            }}
          >
            <li>
              <div className="userProfile">
                <img src={profile_image} alt="Profile" />
                <h4>{`${this.props.home.loginUserSuccess.first_name} ${this.props.home.loginUserSuccess.last_name}`}</h4>
                <div
                  data-for="displayNameUser"
                  data-tip={
                    this.props.home.loginUserSuccess.display_name || "N/A"
                  }
                >
                  <CustomReactTooltip id="displayNameUser" />
                  Display Name:{" "}
                  {ellipsisName(
                    this.props.home.loginUserSuccess.display_name
                  ) || "N/A"}
                </div>
                {tokenDetails && tokenDetails.role && (
                  <span className="user-profile-role">
                    {tokenDetails.role.name === "Admin"
                      ? this.props.home.userDesignation &&
                        this.props.home.userDesignation.length > 0
                        ? this.props.home.userDesignation
                        : this.props.home.loginUserSuccess.designation !== null
                        ? this.props.home.loginUserSuccess.designation
                        : tokenDetails.role.name
                      : this.props.home.loginUserSuccess.designation !== null
                      ? this.props.home.loginUserSuccess.designation
                      : tokenDetails.role.name}
                  </span>
                )}
                <p>
                  Member Since{" "}
                  {DateTimeFormatter(
                    this.props.home.loginUserSuccess.created_at
                  )}
                </p>
              </div>
            </li>
            {tokenDetails &&
            tokenDetails.user &&
            !tokenDetails.user.is_password_set ? (
              <SetPassword />
            ) : (
              <li>
                <span
                  onClick={this.toggleDocumentModal}
                  className="d-inline-flex align-items-center"
                >
                  <i className="material-icons-outlined">lock_reset</i>
                  Change Password
                </span>
              </li>
            )}
            {/* <RegisterSSO
              entity={constants.HTML.TAGS.LIST_ITEM}
              is_sso_registered={
                tokenDetails &&
                tokenDetails.user &&
                tokenDetails.user.is_sso_registered
              }
            /> */}
            <li>
              <span
                onClick={this.navigateToPreferenceCenter}
                className="d-inline-flex align-items-center"
              >
                <i className="material-icons-outlined">settings</i>
                Preferences Center
              </span>
            </li>
            <Logout userLogout={this.userLogout} />
            <Modal
              isOpen={this.state.documentModal}
              toggle={this.toggleDocumentModal}
              className={"modal-md modal-w-header custom-modal primary-modal"}
            >
              <ModalHeader toggle={this.toggleDocumentModal}>
                {constants.CHANGE_PASSWORD.HEADER_TITLE}
              </ModalHeader>
              <form
                onSubmit={this.handleChangePasswordFormSubmit}
                className={`col-lg-12 col-sm-12 form-wrapper p-0`}
              >
                <ModalBody>
                  {this.state.apiErrors && this.state.apiErrors.length > 0 && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiErrors}
                    />
                  )}

                  {this.state.successMessage &&
                    this.state.successMessage.length > 0 && (
                      <Notification
                        color={"success"}
                        message={this.state.successMessage}
                      />
                    )}
                  <FormGroup>
                    <div className="clearfix">
                      <Label htmlFor="currentPassword">
                        Current Password <span className={"mandatory"}>* </span>
                      </Label>
                      <Input
                        className={`${
                          this.state.currentPasswordError ? "is-invalid" : ""
                        }`}
                        type="password"
                        placeholder=""
                        id="currentPassword"
                        name="currentPassword"
                        onChange={this.handleInputChange}
                      />
                      {this.state.currentPasswordError && (
                        <div className="invalid-tooltip">
                          Current Password is a required field
                        </div>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="clearfix">
                      <Label htmlFor="newPassword">
                        New Password <span className={"mandatory"}>* </span>
                      </Label>
                      <Input
                        className={`${
                          this.state.newPasswordError
                            ? "is-invalid"
                            : this.state.isNewPasswordCurrentPasswordEqualError
                            ? "is-invalid"
                            : ""
                        }`}
                        type="password"
                        placeholder=""
                        id="newPassword"
                        name="newPassword"
                        onChange={this.handleInputChange}
                      />
                      {this.state.newPasswordError ? (
                        <div className="invalid-tooltip">
                          Passwords must contain at least 8 characters including
                          at least 1 of each of the following: uppercase letter,
                          lowercase letter, special character and number.
                        </div>
                      ) : this.state.isNewPasswordCurrentPasswordEqualError ? (
                        <div className="invalid-tooltip">
                          Current Password and New Password should be different{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="clearfix">
                      <Label htmlFor="confirmPassword">
                        Confirm Password <span className={"mandatory"}>* </span>
                      </Label>
                      <Input
                        className={`${
                          this.state.confirmPasswordError ? "is-invalid" : ""
                        }`}
                        type="password"
                        placeholder=""
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={this.handleInputChange}
                      />
                      {this.state.confirmPasswordError && (
                        <div className="invalid-tooltip">
                          Password does not match new password above.
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="modal-btn btn-outline-primary"
                    onClick={this.toggleDocumentModal}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" className="modal-btn" type="submit">
                    Change Password
                  </Button>{" "}
                </ModalFooter>
              </form>
            </Modal>
          </ul>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openPreference: (data) => {
      dispatch(openPreference(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
