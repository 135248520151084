import { Axios } from '../lib/axios'
export const MarketForecastServices = {
  getTotalMarketForecasts,
  getMarketForecasts,
  getMarketForecastDetails,
  getMarketForecastContent,
  saveMarketForecastContent,
  addAuthor,
  removeAuthor,
  addMarketForecast,
  editMarketForecast,
  toggleMarketForecast,
  removeMarketForecast,
  getCategories,
  getMarketForecastTemplates,
  saveMarketForecastTransactionContent,
  getManageContentTransactionContent
}
function getTotalMarketForecasts(url) {
  return Axios.get(url)
}
function getMarketForecasts(url) {
  return Axios.get(url)
}
function getMarketForecastDetails(id) {
  return Axios.get(`marketforecast/${id}`)
}
function getMarketForecastContent(id) {
  return Axios.get(`marketforecast/${id}/content`)
}
function saveMarketForecastContent(marketForecastId, content, action) {
  return Axios.post(`marketforecast/${marketForecastId}/content/${action}`, content)
}
function addAuthor(userId, body) {
  return Axios.post(`author/${userId}`, body)
}
function removeAuthor(marketForecastId, id) {
  return Axios.delete(`author/${id}`)
}
function addMarketForecast(body) {
  return Axios.post(`marketforecast`, body)
}
function editMarketForecast(marketForecastId, body) {
  return Axios.patch(`marketforecast/${marketForecastId}`, body)
}
function toggleMarketForecast(marketForecastId, body) {
  return Axios.patch(`marketforecast/${marketForecastId}/status/${body.is_active}`)
}
function removeMarketForecast(marketForecastId) {
  return Axios.delete(`marketforecast/${marketForecastId}`)
}
function getCategories(marketForecastId) {
  return Axios.get(`categories?type=market_forecast`)
}
function getMarketForecastTemplates() {
  return Axios.get('templates')
}
function saveMarketForecastTransactionContent(transaction_id, content, action = '') {
  return Axios.post(`transaction/${transaction_id}/type/market_forecast/state/${action}`, content)
}
function getManageContentTransactionContent(transaction_id) {
  return Axios.get(`transaction/${transaction_id}/type/market_forecast`)
}
