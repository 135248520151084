import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/companyDetailsReference'

const companyCategories = DETAILS_REF.companyCategories
const companySegments = DETAILS_REF.companySegments
const companyDetails = DETAILS_REF.companyDetails
const categories = DETAILS_REF.categories
const companyContent = DETAILS_REF.companyContent
const companies = DETAILS_REF.companies
const templates = DETAILS_REF.templates
const transactionDetails = DETAILS_REF.transactionDetails

const companyDetailsReducer = combineReducers({
  companyCategories,
  companySegments,
  companyDetails,
  categories,
  companyContent,
  companies,
  templates,
  transactionDetails
})

export default companyDetailsReducer
