import { Axios } from '../lib/axios'
export const ClientServices = {
  getClients,
  getClientDetails,
  getSegmentDetails,
  addClient,
  editClient,
  toggleClient,
  removeClient,
  getClientUsers,
  getClientUserDetails,
  addClientUser,
  editClientUser,
  toggleClientUser,
  removeClientUser,
  getClientUserRoles,
  getAllClients,
  getClientSpecificUsers,
  resendEmailVerification,
  getClientUserStandardTemplate,
  uploadBulkClientUsers,
  getSingleUserBounceValidation,
  sendBulkEmailVerification,
  bulkEmailValidation,
  getAllAccountManagers,
  assignClients,
  getAssignedClients,
  bulkUsersResetPassword,
  copyEmailVerification
}
function getClients(url) {
  return Axios.get(url)
}
function getClientDetails(clientId) {
  return Axios.get(`client/${clientId}`)
}
function getSegmentDetails(is_ER_allowed) {
  return Axios.get(`lobs?includeCompanies=1&is_ER_allowed=${is_ER_allowed}`)
}
function addClient(body) {
  return Axios.post(`client`, body)
}
function editClient(clientId, body) {
  return Axios.patch(`client/${clientId}`, body)
}
function toggleClient(clientId, body) {
  return Axios.patch(`client/${clientId}`, body)
}
function removeClient(clientId) {
  return Axios.delete(`client/${clientId}`)
}
function getAllClients() {
  return Axios.get('/clients')
}
function getClientUserRoles() {
  return Axios.get(`/roles?type=tbr`)
}
function getClientUsers(url) {
  return Axios.get(url)
}
function getClientSpecificUsers(url) {
  return Axios.get(url)
}
function getClientUserDetails(userId) {
  return Axios.get(`clientuser/${userId}`)
}
function addClientUser(clientId, body) {
  return Axios.post(`client/${clientId}/user`, body)
}
function editClientUser(clientUserId, body) {
  return Axios.patch(`clientuser/${clientUserId}`, body)
}
function toggleClientUser(status, body) {
  return Axios.patch(`user/status/${status}`, body)
}
function removeClientUser(body) {
  return Axios.delete(`clientuser`, { data: body })
}
function resendEmailVerification(body) {
  return Axios.post('resendverificationmail', body)
}
function copyEmailVerification(body) {
  return Axios.post('copyVerificationLink', body)
}


function getClientUserStandardTemplate() {
  let url = 'clients/excel/template?type=userstandardtemplate&extension=xlsx'
  return Axios.get(url, { responseType: 'arraybuffer' })
}

function uploadBulkClientUsers(body) {
  return Axios.post(`clientuser/upload`, body)
}

function getSingleUserBounceValidation(email, id) {
  return Axios.get(`/verifyemail/single?email=${email}&id=${id}`)
}
function sendBulkEmailVerification(body) {
  return Axios.post(`/bulkEmailVerification`, body)
}
function bulkEmailValidation(body) {
  return Axios.post('/bulkEmailValidation', body)
}
function getAllAccountManagers(url) {
  return Axios.get(url)
}
function assignClients(body) {
  return Axios.post('/assignClients', body)
}
function getAssignedClients(url) {
  return Axios.get(url)
}
function bulkUsersResetPassword(body){
  return Axios.post('/sendBulkResetPasswordEmail',body)
}