import React, { useEffect, useState, useCallback} from 'react'
import { useDispatch } from 'react-redux'
import { FaqServices } from '../Services/FaqServices'
import { activeMenuTab } from '../Store/Actions/User'
import constants from '../Utils/constants'
import Toast from '../Utils/Toast'
import FAQ_Categories from '../Containers/FAQ_Categories'
import FaqsDetail from '../Containers/FAQs';
import DocumentTitle from '../Utils/DocumentTitle'

function Faqs(props) {
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [category_details,setcategory_details]=useState({
        categorystate : "all",
        selectedCategory: {}
    })

    useEffect(() => {
        DocumentTitle(constants.APPLICATION_ROUTE.FAQS.HEADER.TITLE)
        dispatch(activeMenuTab(constants.APPLICATION_ROUTE.FAQS.LIST.ROUTE));
        getFaqs();
    }, [dispatch])


    const categoryClicked = (id) => {
        let selectedCategory = categories.find(category => category.id === id);
        setcategory_details({
            categorystate : category_details.categorystate,
            selectedCategory: selectedCategory
        })

    }

    const getFaqs = () => {
        FaqServices.getFaqs()
            .then((res) => {
                let data = res.data.data;
                let active_categories = [];
                let inactive_categories = [];
                data.map((category) => {
                    if(category.is_active==1){
                        active_categories.push(category)
                    }
                    else{
                        inactive_categories.push(category)
                    }
                })
                data = [...active_categories, ...inactive_categories];
               
                if (data.length) {
                    setCategories(data);

                    // check wether tha selectedCategory is in the response data.
                    // persisting the selected category
                    let selectedFAQCategory = data.find((category) => category_details.selectedCategory.id === category.id);
                    if (selectedFAQCategory) {
                        setcategory_details({
                            categorystate : selectedFAQCategory.is_active,
                            selectedCategory: selectedFAQCategory
                        })
                    }
                    else {
                        setcategory_details({
                            categorystate : "all",
                            selectedCategory: data[0]
                        })                      
                    }
                } else {
                    setCategories([]);
                    setcategory_details({
                        categorystate : "all",
                        selectedCategory: {}
                    }) 
                }
            })
            .catch((error) => {
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })
    }
    
    return (
        <div className={`main_content faq-page`}>
            <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
            <div className="d-flex">
                <div className="faq-left-block">
                    <FAQ_Categories
                        selectedCategory={category_details.selectedCategory}
                        categories={categories}
                        categoryClicked={categoryClicked}
                        getFaqs={getFaqs}
                        categorystate={category_details.categorystate}
                        setcategorystate={setcategory_details}
                    />
                </div>
                <div className="faq-right-block">
                    <FaqsDetail
                        selectedCategory={category_details.selectedCategory}
                        setSelectedCategory={setcategory_details}
                        getFaqs={getFaqs}
                        categorystate={category_details.categorystate}
                        categories={categories}
                        setCategories={setCategories}
                    />
                </div>
            </div>
            </div>
        </div>
    )
}

export default Faqs;