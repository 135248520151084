import React, { Component } from "react";
import ListEcosystemManageContentTransaction from './ListEcosystemManageContentTransaction'
import constants from "../../Utils/constants";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import decodeJWT from "../../lib/decodeJWT";
import { connect } from "react-redux";
import { activeMenuTab } from "../../Store/Actions/User";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import DocumentTitle from "../../Utils/DocumentTitle";

class ListEcosystemTrackSubmission extends Component {
  state = {
    totalTabs: [],
    selectedTab: constants.TRANSACTIONS_LIST_TABS.SENIOR_ANALYST[0].KEY,
    modifiedValueType: constants.TRANSACTIONS_LIST_TABS.SENIOR_ANALYST[0].STATE,
    modifiedValueRole: constants.TRANSACTIONS_LIST_TABS.SENIOR_ANALYST[0].ROLE,
  };

  onContentChangeTypeHandler = (s, e, r) => {
    this.setState({
      selectedTab: s,
      modifiedValueType: e,
      modifiedValueRole: r,
    });
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let tabs = [];
    switch (tokenDetails.role?.short_name) {
      case constants.USER_ROLES.RESEARCH_ANALYST.SLUG:
        tabs = constants.TRANSACTIONS_LIST_TABS.RESEARCH_ANALYST;
        break;
      case constants.USER_ROLES.ANALYST.SLUG:
        tabs = constants.TRANSACTIONS_LIST_TABS.ANALYST;
        break;
      case constants.USER_ROLES.SENIOR_ANALYST.SLUG:
        tabs = constants.TRANSACTIONS_LIST_TABS.SENIOR_ANALYST;
        break;
      case constants.USER_ROLES.PRACTICE_MANAGER.SLUG:
        tabs = constants.TRANSACTIONS_LIST_TABS.PRACTICE_MANAGER;
        break;
      case constants.USER_ROLES.EDITOR.SLUG:
        tabs = constants.TRANSACTIONS_LIST_TABS.EDITOR;
        break;
      case constants.USER_ROLES.ADMIN.SLUG:
        tabs = constants.TRANSACTIONS_LIST_TABS.ADMIN;
        break;
      default:
        break;
    }
    tabs = tabs.filter((tab) => tab.HIDE_TAB === false);
    if (tabs.length > 0) {
      this.setState({
        totalTabs: tabs,
        selectedTab: params.selected_tab ? params.selected_tab : tabs[0].KEY,
        modifiedValueType: params.state ? params.state : tabs[0].STATE,
        modifiedValueRole: params.role ? params.role : tabs[0].ROLE,
      });
    }
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE
        .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE
    );
  }

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.ECOSYSTEM_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title:
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  render() {
    let selected = this.state.totalTabs.find(
      (f) => f.KEY === this.state.selectedTab
    );
    DocumentTitle(selected && selected.TAB_TITLE ? selected.TAB_TITLE : "");
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
          <div className="comapnay_tbl">
            <div className="">
              <div className="btn-items-card-wrapper tabs-card-wrapper">
                <div className="btn-items-card card custom-card nav-tabs-card">
                  <div className="card-body">
                    <div className="d-flex">
                      <h3 className="font-weight-semibold mb-0 text-black2 title">
                        Status
                      </h3>
                      <Nav tabs className="custom-nav-tabs">
                        {this.state.totalTabs.map((tab) => (
                          <NavItem key={tab.KEY}>
                            <NavLink
                              className={classnames({
                                active: this.state.selectedTab === tab.KEY,
                              })}
                              onClick={() => {
                                this.onContentChangeTypeHandler(
                                  tab.KEY,
                                  tab.STATE,
                                  tab.ROLE
                                );
                              }}
                            >
                              {tab.TAB_TITLE}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>

              <TabContent activeTab={this.state.selectedTab}>
                {this.state.totalTabs.map((tab) => (
                  <TabPane tabId={tab.KEY} key={tab.KEY}>
                    {this.state.selectedTab &&
                      this.state.selectedTab === tab.KEY && (
                        <ListEcosystemManageContentTransaction
                          {...this.props}
                          type={this.state.modifiedValueType}
                          role={this.state.modifiedValueRole}
                          selectedTab={this.state.selectedTab}
                          pageNo={constants.PAGINATION.DEFAULT_PAGE_NO}
                          pageSize={constants.PAGINATION.DEFAULT_PAGE_SIZE}
                        />
                      )}
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
export default connect(
  null,
  mapDispatchToProps
)(ListEcosystemTrackSubmission);
