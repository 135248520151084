import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import "./ResourceAndFaq.scss";
import constants from "../../Utils/constants";
import { Button, Dropdown } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import Search from "../../Components/Search";
import Resource from "./Resource";
import Faq from "./Faq";
import { activeMenuTab } from "../../Store/Actions/User";
import { useDispatch } from "react-redux";
import { DropdownList } from "react-widgets";

export const ResourceAndFaq = (props) => {
  const [activeTab, setActiveTab] = useState("");
  const [openFaqModel, setOpenFaqModel] = useState(false);
  const [openResourceModel, setOpenResourceModel] = useState(false);
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("");

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
    history.push({
      pathname: location.pathname,
      search: `?activeTab=${tab}`,
    });
    setSearch("");
    setTempSearch("");
    setStatus("");
    setOrder("");
  }, []);

  useEffect(() => {
    const params = queryString.parse(location.search);
    const tab =
      params.activeTab || constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ.TAB[0];

    setActiveTab(tab);
    history.push({
      pathname: location.pathname,
      search: `?activeTab=${tab}`,
    });
    dispatch(activeMenuTab(constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ.ROUTE));
  }, []);

  const handleSearch = (e) => {
    setSearch(tempSearch);
  };
  const handleInput = (e) => {
    setTempSearch(e.target.value);
  };

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white br_8 main_content_height d-flex p-20 flex-d">
        <div className="div-1">
          <div className="d-flex g-15">
            {constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ.TAB.map((item) => (
              <button
                key={item}
                className={`tab-btn ${
                  activeTab === item ? "active-tab" : "not-active"
                }`}
                onClick={() => handleTabChange(item)}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="d-flex g-15">
            <Search
              placeHolder={`Search ${activeTab}`}
              handleSearch={handleSearch}
              handleInput={handleInput}
              value={tempSearch}
            />
            {activeTab === "Resources" && (
              <>
                <DropdownList
                  className="dropdown-list"
                  data={["all", "active", "deactive"]}
                  placeholder="Status"
                  onChange={(e) => setStatus(e)}
                  style={{ width: "140px" }}
                  value={status}
                />
                <DropdownList
                  className="dropdown-list"
                  data={["New to Old", "Old to New"]}
                  placeholder="Sort by"
                  onChange={(e) => setOrder(e)}
                  style={{ width: "140px" }}
                  value={order}
                />
              </>
            )}
            <div
              className="add-resource-btn d-inline-flex align-items-center cursor-pointer"
              onClick={() => {
                if (activeTab === "Resources") setOpenResourceModel(true);
                if (activeTab === "FAQs") setOpenFaqModel(true);
              }}
            >
              <span className="material-icons-outlined mr-1 icon-fs-18">
                add
              </span>
              <span>Add {activeTab}</span>
            </div>
          </div>
        </div>

        {activeTab === "Resources" && (
          <Resource
            openResourceModel={openResourceModel}
            setOpenResourceModel={setOpenResourceModel}
            search={search}
            status={status}
            order={order}
          />
        )}
        {activeTab === "FAQs" && (
          <Faq
            openFaqModel={openFaqModel}
            setOpenFaqModel={setOpenFaqModel}
            search={search}
          />
        )}
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceAndFaq);
